import React, { useContext } from "react";
import { OrdersContext } from "./OrdersContext";
import PreServiceInspectionTab from "./Innertab/PreServiceInspectionTab";
import ServiceTab from "./Innertab/ServiceTab";
import PostServiceReviewTab from "./Innertab/PostServiceReviewTab";
import PaymentDetailstab from "./Innertab/PaymentDetailstab";
import FeedBackTab from "./Innertab/FeedBackTab";
import PastOrdersTab from "./Innertab/PastOrdersTab";

const OrderWindowRight = () => {
  const {
    innertab,
    toggleInnerTab,
    setViewInnerOrder,
    mappedOrderData,
    setPreViewOnly,
    getBookingsFn,
    setPreFormWindow,
  } = useContext(OrdersContext);

  const backList = () => {
    setViewInnerOrder(false);
    setPreViewOnly(true);
    getBookingsFn();
    toggleInnerTab(1);
    setPreFormWindow([]);
  };

  return (
    <div className="main-block w-100">
      <div className="row m-0">
        <div className="order-left-block">
          <div className="backToList-block">
            <button className="backtoList" onClick={backList}>
              <i className="fal fa-long-arrow-left"></i> Back to List
            </button>
          </div>
          <div className="left-block">
            <div className="col-12 card-small">
              <div>
                <h4>
                  Booking Id :{" "}
                  <span className="text-primary">
                    {mappedOrderData.order_unique_id}
                  </span>
                </h4>
              </div>
              <div className="group-block">
                <label>Scheduled time</label>
                <p>
                  <span>{mappedOrderData.service_time}</span>
                </p>
              </div>
              <div className="group-block">
                <label>Total Duration</label>
                <p>
                  {mappedOrderData &&
                    mappedOrderData.orderServices[0].total_time}
                </p>
              </div>
              {mappedOrderData.customer.app_user === 1 && (
                <span className="custom-tag">
                  <i className="fas fa-calendar-alt mr-1"></i>Booked Via App
                </span>
              )}
            </div>

            <div className="col-12 card-small">
              <h4>Vehicle Details</h4>
              <div className="group-block">
                <label>Manufacturer</label>
                <p>
                  <span>
                    {
                      mappedOrderData.customerVehicle.vehicleModel
                        .vehicleManufacture.manufacturer
                    }
                  </span>
                </p>
              </div>
              <div className="group-block">
                <label>Model</label>
                <p>
                  <span>
                    {mappedOrderData.customerVehicle.vehicleModel.model}
                  </span>
                </p>
              </div>
              <div className="group-block">
                <label>Color</label>
                <p>
                  <span>{mappedOrderData.customerVehicle.color}</span>
                </p>
              </div>
              <div className="group-block">
                <label>Year</label>
                <p>
                  <span>
                    {mappedOrderData.customerVehicle.manufactured_year}
                  </span>
                </p>
              </div>
            </div>

            <div className="col-12 card-small">
              <h4>Customer Details</h4>
              <div className="group-block">
                <label>Contact Number</label>
                <p>
                  <span>
                    {mappedOrderData.customer.phone_extension}{" "}
                    {mappedOrderData.customer.phone_number}
                  </span>
                </p>
              </div>
              <div className="group-block">
                <label>Name</label>
                <p>
                  <span>{mappedOrderData.customer.customer_name}</span>
                </p>
              </div>
              <div className="group-block">
                <label>App User</label>
                <p>
                  <span>
                    {mappedOrderData.customer.app_user === 1 ? "Yes" : "No"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="order-right-block">
          <div className="tab-custom">
            <div className="inner-tab-main">
              <div className="inner-tab v-tab d-flex flex-column ">
                <div className="tab-ul">
                  <ul
                    className="nav nav-tabs flex-direction-column"
                    id=""
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className={
                          innertab === 1 ? " nav-link active" : "nav-link"
                        }
                        id="nav-personal-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-personal"
                        aria-selected="true"
                        onClick={() => toggleInnerTab(1)}
                        href
                      >
                        <span>Pre-Service Inspection</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          innertab === 2 ? " nav-link active" : "nav-link"
                        }
                        id="nav-privileges-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-privileges"
                        aria-selected="false"
                        onClick={() => toggleInnerTab(2)}
                        href
                      >
                        <span>Service</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          innertab === 3 ? " nav-link active" : "nav-link"
                        }
                        id="nav-work-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-work"
                        aria-selected="false"
                        onClick={() => toggleInnerTab(3)}
                        href
                      >
                        <span>Post-Service Review</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          innertab === 4 ? " nav-link active" : "nav-link"
                        }
                        id="nav-work-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-work"
                        aria-selected="false"
                        onClick={() => toggleInnerTab(4)}
                        href
                      >
                        <span>Payment Details</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          innertab === 5 ? " nav-link active" : "nav-link"
                        }
                        id="nav-work-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-work"
                        aria-selected="false"
                        onClick={() => toggleInnerTab(5)}
                        href
                      >
                        <span>Feedback</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={
                          innertab === 6 ? " nav-link active" : "nav-link"
                        }
                        id="nav-work-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-work"
                        aria-selected="false"
                        onClick={() => toggleInnerTab(6)}
                        href
                      >
                        <span>Past Orders</span>
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content staff-tab-content" id="">
                  {innertab === 1 ? <PreServiceInspectionTab /> : ""}
                  {innertab === 2 ? <ServiceTab /> : ""}
                  {innertab === 3 ? <PostServiceReviewTab /> : ""}
                  {innertab === 4 ? <PaymentDetailstab /> : ""}
                  {innertab === 5 ? <FeedBackTab /> : ""}
                  {innertab === 6 ? <PastOrdersTab /> : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderWindowRight;
