import React, { useState, useContext, useEffect } from "react";
import { BookingsContext } from "../BookingsContext";
import BookingsFilterWrapper from "./BookingsFilterWrapper";
// import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "react-calendar/dist/Calendar.css";
import dateFormat, { masks } from "dateformat";

const BookingsContentTitle = (props) => {
  // let dateValues ={
  //   start_date: new Date(),
  //   end_date: new Date()
  // }
  const {
    filteredManufacture,
    setMappedBookingsData,
    setEditMode,
    setViewReadOnly,
    setFilteredManufacture,
    setRowActive,
    setError,
    setShowBookingButton,
  } = useContext(BookingsContext);

  const handleAddClick = () => {
    setMappedBookingsData({});
    props.handleAddBookings();
    setFilteredManufacture(null);
    setEditMode(false);
    setViewReadOnly(false);
    setRowActive(false);
    setShowBookingButton(true);
    const allTableRows = document.querySelectorAll(".rdt_TableRow");
    allTableRows.forEach((rowElement) => {
      rowElement.classList.remove("current-selected-row");
    });
    setError(false);
  };

  useEffect(() => {
    console.log(filteredManufacture);
  }, [filteredManufacture]);

  return (
    <div className="content-title-action">
      <div className="title-action">
        <div className="title-action-left">
          <h5 className="head">Bookings</h5>
          <div className="action-block">
            <button
              className="btn add-new"
              id="addBtn"
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title="Add New"
              onClick={handleAddClick}
            >
              <span>
                <i className="fal fa-plus"></i> Add New
              </span>
            </button>
          </div>
        </div>
        <div className="title-action-right d-flex">
          <BookingsFilterWrapper />
        </div>
      </div>
    </div>
  );
};

export default BookingsContentTitle;
