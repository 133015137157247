import styled from "styled-components/macro";
export const CardHeadingLabel = styled.h6`
  font-size: 16px;
  font-weight: 600;
  color: #000000;
  margin: 0;
  /* text-transform: capitalize; */
  text-align: left;
`;
export const FormLeftLabel = styled.h6`
  font-family: "Inter Regular";
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin: 0;
  text-align: left;

  text-align: left;
  &.text-modifier {
    text-align: right !important;
    max-width: 175px !important;
    min-width: 175px !important;
  }
  .heading-style {
    max-height: 180px !important;
    min-height: 29px !important;
  }
`;

export const FormRightLabel = styled.h6`
  font-family: "Inter Regular";
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  margin: 0;
  text-transform: capitalize;
  text-align: left;
`;
