import React, { useContext } from "react";
import { VariantContext } from "../VariantContext";

function ConfirmModal(props) {
  const {
    mappedServiceData,
    table,
    SetMappedServiceData,
    SetEditMode,
    SetViewReadOnly,
    setIsUpdateForm,
    SetFormChange
  } = useContext(VariantContext);

  const closeConfirm = (e) => {
    props.VariantFormClose(false);
    SetViewReadOnly(false);
    SetEditMode(false);
    setIsUpdateForm(false);
    SetMappedServiceData({});
  };

  const setConfirmModal = (e) => {
    SetFormChange(false);
    props.setConfirmModal(false);
  };

  return (
    <div className="action-modal">
      <div className="d-flex justify-content-end">
        <button className="btn close-btn" onClick={setConfirmModal}>
          <i className="fal fa-times"></i>
        </button>
      </div>
      <h4>Are you sure you want to Exit?</h4>
      <div className="d-flex justify-content-center pt-3">
        <button className="btn mr-3 btn-cancel " onClick={setConfirmModal}>
          Cancel
        </button>
        <button class="btn btn-danger" onClick={closeConfirm}>
          Confirm<i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  );
}

export default ConfirmModal;
