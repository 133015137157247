import React, { useState, createContext, useEffect } from "react";
import {
  createService,
  deleteService,
  getService,
  getServicesList,
} from "../../../../api/services/sap/service-management";
import {
  getLookupDataAPI,
  getVehicleVariantsAPI,
} from "../../../../api/services/common/commonAPIs";
import { toast } from "react-toastify";

export const ServiceContext = createContext("");

export const ServiceProvider = (props) => {
  const [editMode, SetEditMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [mappedServiceData, SetMappedServiceData] = useState({});
  const [review, SetReview] = useState(false);
  const [servicePic, setServicePic] = useState([]);
  const [table, SetTable] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const [vehicleVariants, setVehicleVariants] = useState([]);
  const [viewReadOnly, SetViewReadOnly] = useState(false);
  const [formChange, SetFormChange] = useState(false);

  //Pic state for add and edit
  const [pic, setPic] = useState([]);
  const [editpic, setEditPic] = useState([]);
  const [addonImages, setAddonImages] = useState([]);
  const [addImageSubmit, setAddImageSubmit] = useState(false);
  const [editImageSubmit, setEditImageSubmit] = useState(false);
  const [cancelFile, setCancelFile] = useState({});
  const [loader, setLoader] = useState(true);
  const [filterValue, setFilterValue] = useState("");
  const [viewservice, SetViewService] = useState(false);
  const [rowActive, SetRowActive] = useState(false);

  const updateOrCreateService = (formData, id, { onSuccess, onFailure }) => {
    const data = createService({ service_id: id, ...formData });
    data
      .then((res) => {
        let index = undefined;
        let newTable = [];
        let existingRecord = table.filter((item, i) => {
          const value = item.id === res.data.service_id;
          if (value) index = i;
          return value;
        });
        if (existingRecord.length) {
          newTable = table.map((item, i) => {
            if (i === index) {
              item = {
                id: res.data.service_id,
                service_name: res.data.service_name,
                vehicle_type_id: res.data.vehicle_type_id,
                is_new: res.data.is_new,
                status: res.data.status,
                isActive: res.data.is_active,
              };
            }
            return item;
          });
        } else {
          newTable = [
            {
              id: res.data.service_id,
              service_name: res.data.service_name,
              vehicle_type_id: res.data.vehicle_type_id,
              is_new: res.data.is_new,
              status: res.data.status,
              isActive: res.data.is_active,
            },
            ...table,
          ];
        }
        SetTable(newTable);
        getServicesList(newTable);
        onSuccess();
        SetRowActive(true);
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "bottom-right",
        });
      });
  };
  const deleteServiceAction = (id, onSuccess, onFailure) => {
    const data = deleteService(id);
    data
      .then((res) => {
        let newTableData = table.filter((item) => item.id !== parseInt(res.data.id));
        SetTable([...newTableData]);
        onSuccess();
      })
      .catch((err) => {
        onFailure();
      });
  };
  const handleServiceDelete = (id) => {
    let newList = table.filter((item) => item.id !== id);
    SetTable(newList);
  };
  const saveVehicleVariants = (vehicleTypeId) => {
    getVehicleVariantsAPI({
      status: 1,
      vehicle_type_id: vehicleTypeId,
    })
      .then((res) => {
        setVehicleVariants(res.data);
        //  SetRowActive(true);
      })
      .catch((err) => {
        toast.error("Something went wrong while fetching vehicle variants!", {
          position: "bottom-right",
        });
      });
  };

  const setEditData = (id, openEditForm) => {
    const req = getService({ service_id: id });
    req
      .then((res) => {
        SetMappedServiceData(res.data);
        saveVehicleVariants(res.data.vehicle_type_id);
        openEditForm(true);
        setIsUpdateForm(true);
        // SetRowActive(true);
      })
      .catch((err) => {
        toast.error("Something went wrong while fetching selected service!", {
          position: "bottom-right",
        });
      });
  };

  let initialStateValues = {
    deleteServiceAction,
    editMode,
    handleServiceDelete,
    isUpdateForm,
    mappedServiceData,
    review,
    saveVehicleVariants,
    servicePic,
    setEditData,
    SetEditMode,
    setIsUpdateForm,
    SetMappedServiceData,
    SetReview,
    setServicePic,
    SetTable,
    setVehicleTypes,
    setVehicleVariants,
    SetViewReadOnly,
    table,
    updateOrCreateService,
    vehicleTypes,
    viewReadOnly,
    vehicleVariants,
    formChange,
    SetFormChange,
    addImageSubmit,
    setAddImageSubmit,
    pic,
    setPic,
    cancelFile,
    setCancelFile,
    setAddMode,
    addMode,
    loader,
    setLoader,
    SetViewService,
    viewservice,
    getServicesList,
    rowActive,
    SetRowActive,
  };
  useEffect(() => {
    getLookupDataAPI({ type: "vehicle_type" })
      .then((res) => setVehicleTypes(res.data))
      .catch((err) => {
        toast.error("Something went wrong when fetching vehicle type list!", {
          position: "bottom-right",
        });
      });
    getServicesList()
      .then((res) => {
        SetRowActive(true);
        SetTable(
          res.data.map((e) => ({
            id: e.service_id,
            is_new: e.is_new,
            vehicle_type_id: e.vehicle_type_id,
            service_name: e.service_name,
            is_active: e.is_active,
            status: e.status,
          }))
        );
      })
      .catch((err) => {
        toast.error("Something went wrong when fetching service list!", {
          position: "bottom-right",
        });
      });
  }, []);

  return (
    <ServiceContext.Provider value={initialStateValues}>
      {props.children}
    </ServiceContext.Provider>
  );
};
