import React from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
type Props = {
  isOpen?: boolean;
  onClose?: () => void;
  onSucess?: () => void;
  children?: React.ReactNode;
};

const CustomPopup = ({ isOpen, onClose, children, onSucess }: Props) => {
  return (
    <Wrapper>
      <Modal className="custom-modal" show={isOpen} onHide={onClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center pt-3">
            <button className="btn mr-3 btn-cancel" onClick={onClose}>
              Cancel
            </button>
            <button className="btn mr-3 btn-primary" onClick={onSucess}>
              Continue
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};

export default CustomPopup;
const Wrapper = styled.div`
  .sc-nZgfj {
    text-align: center;
    padding: 0 30px;
  }
  .modal-body {
    margin: 0 auto;
  }
`;
