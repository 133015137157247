import { sapAxios } from "../../axios";

const getProductList = async (params?: number | null) => {
    console.log("paramsss",params)
    const axiosInst = sapAxios(); //sup Axios change it to
    const url = `/service-admin/product-list`; // url
    try {
      const res = await axiosInst({
        method: "GET",
        url,
      });
      console.log(res);
      if (res.status) {
        return res.data;
      }
      throw new Error(res.statusText);
    } catch (err) {
      console.log(err);
      return err;
    }
  };


  const getProductDetails = async (
    product_id?: string | number | undefined
  ) => {
    console.log("paramsss", product_id);
    const axiosInst = sapAxios(); //sup Axios change it to
    const url = `/service-admin/product-details/${product_id}`; // url
    try {
      const res = await axiosInst({
        method: "GET",
        url,
      });
      console.log(res);
      if (res.status) {
        return res.data;
      }
      throw new Error(res.statusText);
    } catch (err) {
      console.log(err);
      return err;
    }
  };
  const createProduct = async (data?: any) => {
     console.log("ddddss", data);
    const axiosInst = sapAxios(); //sup Axios change it to
    const url = `/service-admin/add-product`; // url
    try {
      const res = await axiosInst({
        method: "POST",
        url,
        data
      });
      console.log(res);
      if (res.status) {
        return res.data;
      }
      throw new Error(res.statusText);
    } catch (err) {
      console.log(err);
      return err;
    }
  };
  const getProductDropdown = async (params?: number | null) => {
    console.log("paramsss",params)
    const axiosInst = sapAxios(); //sup Axios change it to
    const url = `/service-admin/product/dropdown`; // url
    try {
      const res = await axiosInst({
        method: "GET",
        url,
      });
      console.log(res);
      if (res.status) {
        return res.data;
      }
      throw new Error(res.statusText);
    } catch (err) {
      console.log(err);
      return err;
    }
  };
  const getProductMeasurement = async (measurement_id?: string | number) => {
    console.log("measure_id", measurement_id )
    const axiosInst = sapAxios(); //sup Axios change it to
    const url = `/service-admin/product/dropdown/${measurement_id}`; // url
    try {
      const res = await axiosInst({
        method: "GET",
        url,
      });
      console.log(res);
      if (res.status) {
        return res.data;
      }
      throw new Error(res.statusText);
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  
  
  export { getProductList, getProductDetails, createProduct, getProductDropdown, getProductMeasurement };