import React, { useContext, useState, Fragment, useEffect } from "react";
import { ProfileContext } from "../ProfileContext";
import { Scrollbars } from "react-custom-scrollbars-2";
import styled from "styled-components";
import uploadImageFile from "../../../../../assets/img/icon/upload/photos.svg";
import DatePicker from "react-date-picker";
import { toast } from "react-toastify";
import {
  createOrUpdatePermissions,
  listPermissions,
} from "../../../../../api/services/sap/profile-management";

const LicenseTab = () => {
  const {
    innertab,
    addImageSubmit,
    sapLicense,
    setSapLicense,
    handleLicenseShow,
    userProfile,
    setUserProfile,
    licenseviewonly,
    setLicenseViewOnly,
  } = useContext(ProfileContext);
  const [licenseData, setLicenseData] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null); // Updated state to store the uploaded image
  const [uploadedFileName, setUploadedFileName] = useState(""); // State to store the uploaded file name
  const [expiryDate, setExpiryDate] = useState();
  const [formsubmit, setFormSubmit] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await listPermissions();
        setLicenseData(data);
        console.log(data, "dataasas");
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  const handleImageUpload = (e) => {
    const files = e.target.files; // Get all selected files
    const fileNames = []; // Array to store file names
    const imageUrls = []; // Array to store image URLs

    // Iterate through each selected file
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileName = file.name; // Get the file name
      const imageUrl = URL.createObjectURL(file); // Create URL for the image

      // Push file name and image URL to respective arrays
      fileNames.push(fileName);
      imageUrls.push(imageUrl);
    }

    // Set state with the arrays of file names and image URLs
    setUploadedFileName(fileNames.join(", ")); // Join file names with comma if multiple
    setUploadedImage({ fileNames: fileNames, imageUrls: imageUrls }); // Store the array of image URLs in state
  };

  // Function to submit form with image data
  const licenseFormSubmitHandler = (e) => {
    e.preventDefault();
    setFormSubmit(true);
    setSapLicense({ ...sapLicense, uploadedImage: uploadedImage }); // Update sapLicense with uploaded image data
    setUserProfile({ ...userProfile, sap_license: sapLicense }); // Update userProfile with sapLicense
    const updatedSapData = createOrUpdatePermissions({
      sap_license: sapLicense,
    });
    toast.success("Details Updated Successfully", {
      position: "top-right",
    });
    updatedSapData.then((res) => {
      // console.log(res, "res of submit");
      const data = listPermissions();
      data.then((res) => {
        // console.log(res, "res of list data submit");
      });
    });
  };
  // console.log(userProfile, "userProfileuserProfile");
  //check file extension
  function getExtension(filename) {
    return filename.split(".").pop();
  }


  const clearUploadedImage = () => {
    setUploadedImage(null); // Clear the uploaded image
    setUploadedFileName(""); // Clear the uploaded file name
  };
  console.log(sapLicense, "sapLicense");
  return (
    <LicenseWrapper
      className={innertab === 4 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <div className="main-tab-block">
        <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
          <div className="inner-block tab-form-block">
            <Fragment>
              <form className="" onSubmit={licenseFormSubmitHandler}>
                <p className="mb-3 text-info">Service Center License Details</p>

                <div>
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fal fa-calendar"></i>Expiry Date
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <DatePicker
                        minDate={new Date()}
                        format="dd/MM/y"
                        calendarIcon={null}
                        clearIcon={null}
                        className="form-control"
                        value={expiryDate} // Bind the value to the expiry date state
                        onChange={(e) => {
                          const year = e.getFullYear();
                          const month = String(e.getMonth() + 1).padStart(
                            2,
                            "0"
                          );
                          const day = String(e.getDate()).padStart(2, "0");
                          const selectedDate = `${year}-${month}-${day}`;
                          console.log("newDate", selectedDate);

                          setExpiryDate(selectedDate); // Update the expiry date state
                          setSapLicense({ ...sapLicense, expiry_date: selectedDate }); // Update sapLicense with expiry_date
                        }}
                        disabled={licenseviewonly}
                      />
                    </div>
                    {/* <div> */}
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="far fa-file"></i>Document
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <button
                      type="button"
                      className="btn-image"
                      onClick={() => {
                        const fileInput =
                          document.getElementById("licenseImageInput");
                        fileInput.click();
                      }}
                    >
                      <img
                        src={uploadImageFile}
                        alt="Upload"
                        className="upload-icon"
                      />{" "}
                      {/* Use the image as the button icon */}
                      {uploadedFileName ? uploadedFileName : "Upload"}
                    </button>
                    <input
                      type="file"
                      id="licenseImageInput"
                      multiple
                      hidden
                      disabled={licenseviewonly}
                      onChange={handleImageUpload}
                    />
                  </div>
                  {/* </div> */}
                </div>

                {!licenseviewonly && (
                  <div className="col-12 text-end p-0 mt-3">
                    <button className="btn btn-update" type="submit">
                      <i className="fal fa-save mr-2"></i>Save
                    </button>
                  </div>
                )}
              </form>
            </Fragment>
          </div>
        </Scrollbars>
      </div>
    </LicenseWrapper>
  );
};

export default LicenseTab;

const LicenseWrapper = styled.div`
  .addbtn {
    border: 1px solid #c7bebe;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #ecebeb;
    line-height: inherit;
  }

  .btn:active {
    border-color: unset;
  }
  .upload-icon {
    height: 20px;
  }
  .uploaded-file-name {
    cursor: pointer;
  }
  .btn-image {
    margin-left: 10px;
    width: 380px;
    min-width: 150px;
    border-color: grey;
    border-width: thin;
  }
  .input-block {
    width: calc(100% - 75%);
  }
  .inner-block.tab-form-block {
    width: 95%;
  }
  .input-group-text {
    margin-left: 10px;
  }
`;
