
import React from "react";
import { toast } from "react-toastify";
export const SocialMediaValidation = (values, field_value, isOnChange, field) => {
  let errors = {};
  let regWebsite = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/;
  console.log("gstValidation************************************",values, field_value, isOnChange, field)


  if (isOnChange) {
    
    if (field === "website" && isOnChange) {
      console.log("eyyeyeyeye")
      if (field_value && !regWebsite.test(field_value)) {
        errors.website = "Invalid website url";
        console.log('errors2', errors.website )
      }
      
    }
    if (field === "whatsapp" && isOnChange) {
        console.log("enteredddd")
      
        if (field_value && !regWebsite.test(field_value)) {
          errors.whatsapp = "Invalid whatsapp url";
          console.log('errors3', errors.whatsapp )
        }
        
      }
      if (field === "facebook" && isOnChange) {
      
        if (field_value && !regWebsite.test(field_value)) {
          errors.facebook = "Invalid facebook url";
          console.log('errors2', errors.facebook)
        }
        
      }
      if (field === "googleMap" && isOnChange) {
      
        if (field_value && !regWebsite.test(field_value)) {
          errors.googleMap = "Invalid googleMap url";
          console.log('errors2', errors.googleMap )
        }
        
      }
      if (field === "Gsuite" && isOnChange) {
      
        if (field_value && !regWebsite.test(field_value)) {
          errors.Gsuite = "Invalid Gsuite url";
        }
        
      }
      if (field === "instagram" && isOnChange) {
      
        if (field_value && !regWebsite.test(field_value)) {
          errors.instagram = "Invalid instagram url";
        }
        
      }
  }

  //Validation for OnSubmit
  else{
    if ((values.website) && !regWebsite.test(values.website)) {
        errors.website = "This is not a valid website format!";
      }else{
        errors.website = "";
      }
      if ((values.whatsapp) && !regWebsite.test(values.whatsapp)) {
        errors.whatsapp = "This is not a valid whatsapp format!";
      }else{
        errors.whatsapp = "";
      }
      if ((values.facebook) && !regWebsite.test(values.facebook)) {
        errors.facebook = "This is not a valid facebook format!";
      }else{
        errors.facebook = "";
      }   
      if ((values.googleMap) && !regWebsite.test(values.googleMap)) {
        errors.googleMap = "This is not a valid googleMap format!";
      }else{
        errors.googleMap = "";
      }
      if ((values.instagram) && !regWebsite.test(values.instagram)) {
        errors.Gsuite = "This is not a valid Gsuite format!";
      }else{
        errors.Gsuite = "";
      }
      if ((values.instagram) && !regWebsite.test(values.instagram)) {
        errors.instagram = "This is not a valid instagram format!";
      }else{
        errors.instagram = "";
      }
  }
 
  return errors;
};

export default SocialMediaValidation;
