import React from "react";
import Layout from "../../Layout/Layout";
import styled from "styled-components";
import ContentHeader from "../../Layout/ContentHeader";
import ContentTitleWithButton from "../components/Layouts/ContentTitleWithButton";
import CustomOfferCouponCard from "../../../CustomOfferCouponCard";
import CouponDetailsPopup from "../../../CustomOfferCouponCard/CouponDetailsPopup";
import WindowRightLayout from "../components/Layouts/WindowRightLayout";
import CouponForm from "./components/CouponForm";

type Props = {};
export interface FormData {
  title: string;
  description: string;
  image: {};
  couponCode: string;
  startDate: Date | null;
  endDate: Date | null;
  offerType: { offer_id: number; offer_name: string };
}
const initialData = {
  title: "",
  description: "",
  image: "",
  couponCode: "",
  startDate: null,
  endDate: null,
  offerType: { offer_id: 0, offer_name: "" },
};
const dummyData = {
  title: "70 OFF",
  description: "Special discount for all the new customers",
  image: "",
  couponCode: "X410-17GT-OL57",
  startDate: null,
  endDate: null,
  offerType: { offer_id: 1, offer_name: "New Year Discount" },
};

const SupCouponsPage = (props: Props) => {
  const [rightView, setRightView] = React.useState(false);
  const [showPopup, setShowPopup] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [isAddNew, setIsAddNew] = React.useState<boolean>(false);
  const [formData, setFormData] = React.useState<FormData>(initialData);
  React.useEffect(() => {
    setFormData(initialData);
  }, []);
  const handleAddOffers = () => {
    setFormData(initialData);
    setIsAddNew(true);
    setRightView(true);
    setIsEdit(true);
  };
  const handleBack = () => {
    setFormData(initialData);
    setRightView(false);
    setIsAddNew(false);
  };
  const handleCloseView = () => {
    if (isAddNew) {
      setFormData(initialData);
      setRightView(false);
      setIsAddNew(false);
    } else {
      setIsEdit(false);
      setFormData(initialData);
    }
  };

  const handleClosePopup = (e: any) => {
    e.stopPropagation();
    setShowPopup(!showPopup);
  };
  const handleViewClick = (e: any) => {
    setRightView(true);
    setIsEdit(false);
    setFormData(dummyData);
  };
  const onEditClick = () => {
    setIsEdit(true);
    setIsAddNew(false);
  };
  return (
    <>
      <Layout>
        <Wrapper className="content-block">
          <section className="main-section">
            <div className="container-fluid p-0">
              <ContentHeader />
            </div>
            <div className="col-12 p-0">
              <ContentTitleWithButton
                handleAddClick={handleAddOffers}
                buttonTitle={"Add New Coupon"}
                heading={"Coupons"}
              />
              <div className="content-window">
                <div
                  className={`${
                    rightView ? "right-view-enabled" : ""
                  } content-window-left`}
                >
                  <div className="content-table-block">
                    <div className="content-table ">
                      <div className="row gap-0 m-0">
                        {Array.from({ length: 10 }, (_, i) => i).map(
                          (item, i) => {
                            return (
                              <div className="mt-1 mb-2 col-sm-6 col-md-4 col-lg-4 col-xl-3">
                                <CustomOfferCouponCard
                                  handleShowClick={handleClosePopup}
                                  handleViewClick={handleViewClick}
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {rightView && (
                  <WindowRightLayout
                    isExpand={rightView}
                    heading={isAddNew?"Add New Coupon":isEdit?"Edit Coupon":"View Coupon"}
                    backToolTip="Back to offers"
                    handleCloseView={handleBack}
                    childrenRight={
                      isEdit ? (
                        <></>
                      ) : (
                        <button
                          onClick={onEditClick}
                          className="btn btn-action btn-edit"
                          style={{
                            color: "#2e7d32",
                            background: "#f1ffe0",
                            border: "1px solid #f1ffe0",
                          }}
                          rel="tooltip"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Edit"
                          aria-describedby="tooltip55424"
                        >
                          <i className="fal fa-pen"> </i>
                        </button>
                      )
                    }
                  >
                    <CouponForm
                      handleCloseView={handleCloseView}
                      isEdit={isEdit}
                      formData={formData}
                      setFormData={setFormData}
                    />
                  </WindowRightLayout>
                )}
              </div>
            </div>
          </section>
          <CouponDetailsPopup open={showPopup} handleClose={handleClosePopup} />
        </Wrapper>
      </Layout>
    </>
  );
};

export default SupCouponsPage;
const Wrapper = styled.div`
  .content-window {
    display: flex;
    gap: 15px;
  }
  .right-view-enabled {
    width: unset;
    height: unset !important;
    flex-basis: 25%;
    .row {
      display: block;
      height: 100vh;
    }

    .col-xl-3 {
      max-width: 100%;
    }
  }
  .content-window-left {
    overflow: auto;
    height: calc(100vh - 100px);
  }
`;
