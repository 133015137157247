import React, { useState } from "react";
import Layout from "../../Layout/Layout";
import styled from "styled-components";
import ContentHeader from "../../Layout/ContentHeader";
import CustomFileUpload from "../components/CustomFileUpload";
import InputLayout from "../components/Layouts/InputLayout";
import CustomColorPicker from "../components/CustomColorPicker";
import ContentTitleWithButton from "../components/Layouts/ContentTitleWithButton";
import {
  createOrUpdateSubscriptionBanner,
  getSubscriptionBanner,
} from "../../../../api/services/sup/subscription-management";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import PaletteIcon from "@mui/icons-material/Palette";
import { GetSubscriptionBannerModel } from "../../../../api/services/sup/models/GetSubscriptionBannerModel";
import { toast } from "react-toastify";

const SubscribtionBanner = () => {
  const [mappedData, setMappedData] = useState<GetSubscriptionBannerModel>();
  const [btnBg, setBtnBg] = useState("#000");
  const [bannerId, setBannerId] = useState<number | undefined>(undefined);
  const [btnText, setBtnText] = useState("#eeeeee");
  const [imagePrevUrl, setImagePrevUrl] = useState<string>("");
  const [isEdit, setIsEdit] = useState(false);
  const [image, setImage] = useState<Blob | undefined>(undefined);
  const [isRemovedViewImage, setIsRemovedViewImage] =
    React.useState<boolean>(false);
  const [images, setImages] = useState<
    (
      | {
          base64: string;
          type: "image";
        }
      | { videoSrc: string; type: "video" }
    )[]
  >([]);

  const [pic, setPic] = useState<
    (
      | {
          base64: string;
          type: "image";
        }
      | {
          videoSrc: string;
          type: "video";
        }
    )[]
  >([]);

  const handleEditView = () => {
    setIsEdit(!isEdit);
  };

  const handleCancelEditClick = () => {
    setBannerId(mappedData?.id);
    setBtnBg(mappedData?.buttonColor ?? "");
    setBtnText(mappedData?.buttonTextColor ?? "");
    setImagePrevUrl(mappedData?.imageUrl ?? "");
    setIsRemovedViewImage(false);
    setImage(undefined);
    setImages([]);
    setIsEdit(!isEdit);
  };

  const handleRemoveDefaultImage = () => {
    setIsRemovedViewImage(true);
    setImagePrevUrl("");
    setImage(undefined);
  };

  const handleSetImage = (e: any, file: Blob) => {
    setImage(file);
  };
  console.log("mappedData?", mappedData);

  //saveClick
  const handleSaveBanner = () => {
    const payload = {
      buttonColor: btnBg,
      buttonTextColor: btnText,
      subscriptionId: bannerId ?? undefined,
    };
    let formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      if (value !== undefined) {
        formData.append(key, String(value));
      }
    }
    formData.append("image", image!);
    createOrUpdateSubscriptionBanner(formData)
      .then((res) => {
        console.log(res, "banner data");
        setMappedData(res?.data);
        setBannerId(res?.data?.id);
        setBtnBg(res?.data?.buttonColor);
        setBtnText(res?.data?.buttonTextColor);
        setImagePrevUrl(res?.data?.imageUrl);
        setIsEdit(false);
        toast.success("Successfully Updated Subscription banner", {
          position: "top-right",
        });
      })
      .catch((err) => {
        toast.error("Something went wrong", {
          position: "top-right",
        });
        console.log(err, "banner err");
      });
  };
  //get banner details
  const getBannerDetails = () => {
    getSubscriptionBanner()
      .then((res) => {
        console.log(res, "banner details");
        if (res) {
          setMappedData(res?.data);
          setBannerId(res?.data?.id);
          setBtnBg(res?.data?.buttonColor);
          setBtnText(res?.data?.buttonTextColor);
          setImagePrevUrl(res?.data?.imageUrl);
        }
      })
      .catch((err) => console.log(err));
  };

  function disableSave() {
    if (!btnBg || !btnText || (!imagePrevUrl && image === undefined)) {
      return true;
    } else return false;
  }

  React.useEffect(() => {
    getBannerDetails();
  }, []);

  return (
    <>
      <Layout>
        <Wrapper className="content-block">
          <section className="main-section">
            <div className="container-fluid p-0">
              <ContentHeader />
            </div>
            <div className="col-12 p-0">
              <ContentTitleWithButton heading={"Subscription Banner"} />
              <div className="card-main-wrapper">
                <div className="edit-wrapper">
                  {!isEdit ? (
                    <button
                      onClick={handleEditView}
                      className="btn btn-action btn-edit"
                      data-for="edit"
                      data-tip="Edit Service Center"
                      title="Edit"
                    >
                      <i className="fal fa-pen"></i>
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={handleCancelEditClick}
                        className="btn btn-action btn-edit"
                        data-for="edit"
                        data-tip="Edit Service Center"
                        title="Cancel"
                      >
                        Cancel
                      </button>

                      <div>
                        <button
                          onClick={handleSaveBanner}
                          className="btn btn-update"
                          type="button"
                          disabled={disableSave()}
                        >
                          <i className="fal fa-save mr-2"></i>Save
                        </button>
                      </div>
                    </>
                  )}
                </div>
                <div className="card-wrapper">
                  <div className="left-section">
                    <CustomFileUpload
                      onImageConfirmClick={(e, file) => {
                        handleSetImage(e, file);
                      }}
                      showImage={isRemovedViewImage ? undefined : imagePrevUrl}
                      handleRemoveDefaultImage={handleRemoveDefaultImage}
                      handleRemoveFile={handleRemoveDefaultImage}
                      viewonly={!isEdit}
                      setImage={setImages}
                      image={images}
                    />
                  </div>
                  <div className="right-section">
                    <div className="row">
                      <div className="col-xl-6 col-12">
                        <InputLayout
                          isImportant
                          title="Button Color"
                          icon={<PaletteIcon />}
                        >
                          <CustomColorPicker
                            onChange={setBtnBg}
                            color={btnBg}
                            disabled={!isEdit}
                          />
                        </InputLayout>
                      </div>
                      <div className="col-xl-6 col-12">
                        <InputLayout
                          isImportant
                          title="Button Text Color"
                          icon={<FormatColorTextIcon />}
                        >
                          <CustomColorPicker
                            onChange={setBtnText}
                            color={btnText}
                            disabled={!isEdit}
                          />
                        </InputLayout>
                      </div>
                      <div className="btn-wrapper">
                        <span>Button Preview</span>
                        <BtnWrapper
                          style={{ background: btnBg, color: btnText }}
                        >
                          Subscription
                        </BtnWrapper>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Wrapper>
      </Layout>
    </>
  );
};

export default SubscribtionBanner;
const Wrapper = styled.div`
  .edit-wrapper {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    padding-bottom: 0;
    gap: 5px;
    margin-right: 10px;
    min-height: 30px;
    max-height: 30px;
    button {
      padding: 0.375rem 0.75rem;
    }
  }
  .btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding-top: 15px;
    span {
      font-size: 18px;
    }
  }
  .content-window {
    display: flex;
    gap: 15px;
    width: 100%;
  }
  .show {
    width: 300px;
  }
  .table-wrapper {
    padding: 10px 20px;
  }
  .card-main-wrapper {
    background: #ffff;
    width: calc(100% - 50px);
    margin: 20px;
    border-radius: 8px;
  }
  .card-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0px 20px 10px;
    background: #ffff;
    .left-section {
      width: 25%;
    }
    .right-section {
      width: 75%;
    }
  }
`;
const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  border-radius: 30px;
  width: 200px;
`;
