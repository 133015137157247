import ServiceRow from "./ServiceRow";
import React, { useState, useContext, Fragment, useEffect } from "react";
import { ServiceContext } from "./ServiceContext";
import { v4 as uuidv4 } from "uuid";
import "../../Layout/Ui/css/Pagination.css";
import ReactPaginate from "react-paginate";
import noDataImage from "../../../../assets/img/icon/upload/empty-box.svg";
import DataTable from "react-data-table-component";
import ServiceModal from "./Ui/ServiceModal";
import NavToggle from "./Ui/NavToggle";
import ReactTooltip from "react-tooltip-rc";
import styled from "styled-components";
const WindowLeft = (props) => {
  const {
    table,
    setEditData,
    SetTable,
    mappedServiceData,
    pic,
    SetEditMode,
    setAddMode,
    filterValue,
    setFilterValue,
    vehicleTypes,
    SetViewReadOnly,
    SetMappedServiceData,
    SetViewService,
    viewservice,
    SetRowActive,
  } = useContext(ServiceContext);
  // const [viewservice, SetViewService] = useState(false);
  const [deletemodal, SetDeleteModal] = useState(false);
  const [openEditForm, setOpenEditForm] = useState(false);
  // const [activeStatus, setActiveStatus] = useState(table.is_active);

  const [rowId, setRowId] = useState();
  const [rowName, setRowName] = useState();
  const [rowToggle, setRowToggle] = useState();
  const [rowToggleId, setRowToggleId] = useState();
  const [activeStatus, setActiveStatus] = useState(rowToggle);
  let toggleClassCheck = viewservice ? " view" : "";

  const handleViewService = () => {
    SetViewService(!viewservice);
    SetRowActive(true);
  };
  const paginationComponentOptions = {
    rowsPerPageText: false,
    selectAllRowsItem: true,
    noRowsPerPage: true,
  };

  //With React
  const handleDelete = (e, serviceId, serviceName) => {
    SetDeleteModal(true);
    e.stopPropagation();

    setRowId(serviceId);
    setRowName(serviceName);
  };
  const setEditForm = (e, rowData) => {
    const closestTableRow = e?.target?.closest(".rdt_TableRow");
    if (closestTableRow) {
      // Remove the class from all elements with class "rdt_TableRow"
      const allTableRows = document.querySelectorAll(".rdt_TableRow");
      allTableRows.forEach((rowElement) => {
        rowElement.classList.remove("current-selected-row");
      });
      // Append a new class to the closest parent element
      closestTableRow.classList.add("current-selected-row");
    }
    setEditData(rowData.id, setOpenEditForm);
    SetMappedServiceData(rowData);
    SetRowActive(true);
  };

  const effectFn = () => {
    if (table.map((e) => e.id === rowToggleId) && activeStatus !== rowToggle) {
      setActiveStatus(rowToggle);
      SetRowActive(true);
    }
    if (openEditForm) {
      handleViewService();
      props.serviceformClose(true);
      SetViewReadOnly(true);
      SetEditMode(false);
      setAddMode(false);
      setOpenEditForm(false);
      SetRowActive(true);
    }
  };

  const toggleActive = (e, serviceToggleId, serviceToggle) => {
    e.stopPropagation();
    setRowToggleId(serviceToggleId);
    setRowToggle(serviceToggle);
    SetRowActive(true);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.service_name,
      sortable: true,
      cell: (row) => (
        <Namewrapper style={{ display: "flex", maxWidth: "300px" }}>
          <ReactTooltip id="name" place="top" type="info" effect="float" />
          <div
            className="namewrapper"
            data-for="name"
            data-tip={row.service_name}
          >
            {row.service_name}
          </div>
          <div>
            {row.status === 0 && (
              <span class="badge draft ml-2" data-tag="allowRowEvents">
                Draft Saved
              </span>
            )}
          </div>
          {row.status === 1 && (
            <span class="badge  ml-2 pending" data-tag="allowRowEvents">
              Pending Review
            </span>
          )}
        </Namewrapper>
      ),
    },
    {
      name: "Vehichle Type",
      selector: (row) => row.vehicle_type_id,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">
          {vehicleTypes
            .filter((e) => e.value === row.vehicle_type_id)
            .map((e) => e.title)}
        </div>
      ),
    },
    {
      name: "Status",
      selector: (row) => row.is_active,
      cell: (row) => {
        return (
          <div data-tag="allowRowEvents">
            {row.isActive === 1 || row.is_active === 1 ? "Active" : "Inactive"}
          </div>
        );
      },
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => (
        <div
          class="btn-group actions toggle-delete-actions"
          role="group"
          aria-label="Basic example"
        >
          <button type="button" class="btn">
            <span
              data-for="delete"
              data-tip="Delete service"
              className="action-delete"
              onClick={(e) => {
                handleDelete(e, row.id, row.service_name, row.vehicle_type_id);
              }}
            >
              <i className="fal fa-trash"></i>
            </span>
          </button>
          <ReactTooltip id="delete" place="top" type="info" effect="float" />

          <button
            disabled={
              (row.is_new === 1 && row.status === 2) ||
              row.status === 2 ||
              (row.is_new !== 1 && row.status !== -1)
                ? false
                : true
            }
            type="button"
            class="btn"
          >
            <span
              data-for="toggleStatus"
              data-tip="Change status"
              className=""
              mappedId={row.id}
              rowis_active={row.is_active}
              onClick={(e) => toggleActive(e, row.id, row.is_active)}
            >
              {/* {row.is_active} is_active */}
              <NavToggle
                status={row.status}
                id={row.id}
                name={row.service_name}
                mappedServiceData={mappedServiceData}
                isActive={row.is_active || row.isActive}
                setActiveStatus={setActiveStatus}
                mappedRowData={row}
                SetRowActive={SetRowActive}
              />
            </span>
          </button>
          <ReactTooltip
            id="toggleStatus"
            place="top"
            type="info"
            effect="float"
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    effectFn();
  }, [openEditForm, table]);

  return (
    <Fragment>
      <div className="content-window-left">
        <div className="content-table-block">
          <div className="content-table table-responsive single-column-view">
            <WrapperLeft>
              <DataTable
                onRowClicked={(row, e) => setEditForm(e, row)}
                columns={columns}
                data={table}
                pagination
                highlightOnHover={true}
                pointerOnHover={true}
                paginationComponentOptions={paginationComponentOptions}
              />
            </WrapperLeft>
          </div>
        </div>
      </div>
      {deletemodal && (
        <ServiceModal
          SetDeleteModal={SetDeleteModal}
          id={rowId}
          name={rowName}
        />
      )}
    </Fragment>
  );
};

export default WindowLeft;

const WrapperLeft = styled.div`
  .kjHanM {
    margin: 0px 5px;
  }
  .ixjXmq {
    white-space: nowrap;
  }
  .DfDUN {
    width: 30px;
  }
  [data-column-id="1"] {
    min-width: 500px;
    text-align: left;
  }
`;

const Namewrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  .namewrapper {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.__web-inspector-hide-shortcut__ {
    visibility: visible !important;
  }
  &.sc-bStcSt,
  &.kIqerf {
    min-width: 141px;
    max-width: 267px;
  }
`;
