import React from "react";
import { useEffect } from "react";
import { useState, useContext } from "react";
import {
  changeActiveStatus,
  changeServiceCenterActiveStatus,
  getServiceCenterStatus,
} from "../../../../api/services/sap/service-management";
import "./css/NavToggle.css";
import { ServiceContext } from "../../Pages/Service/ServiceContext";
import { toast } from "react-toastify";

// import classNames from 'classnames;'

function NavToggle(props) {
  const [isSelected, setIsSelected] = useState(false);

  let changeservicetoggleblock = isSelected ? " change-toggle-block" : "";
  let changeservicetogglespan = isSelected ? " change-toggle-span" : "";

  const toggleStatus = () => {
    const response = changeServiceCenterActiveStatus({
      service_admin_id: getServiceCenterId(),
      type: isSelected === true ? "inactive" : "active",
    });
    response.then((res) => {
      let status = res.status == 1 ? true : false;
      setIsSelected(status);
      //  setIsSelected(status);
      props.setActiveStatus(status);
    });
  };

  const getServiceCenterId = () => {
    return parseInt(localStorage.getItem("serviceCenterId"));
  };

  useEffect(() => {
    let data = {
      service_admin_id: getServiceCenterId(),
    };
    const response = getServiceCenterStatus(data);
    response.then((res) => {
      let status = res.status == 1 ? true : false;
      setIsSelected(status);
      props.setActiveStatus(status);
    });
  }, []);

  return (
    <div
      onClick={toggleStatus}
      className={`toggle-block  ${changeservicetoggleblock}`}
    >
      <span className={`toggle-span ${changeservicetogglespan}`}></span>
    </div>
  );
}

export default NavToggle;
