import React, { useState, useContext, useEffect } from "react";
import { Fragment } from "react";
import { ServiceContext } from "./ServiceContext";
import ServiceModal from "./Ui/ServiceModal";
import NavToggle from "./Ui/NavToggle";
import DataTable from "react-data-table-component";
import placeholderImg from "../../../../assets/img/user/3.png";

// import DataTable from "react-data-table-component";
function ServiceRow(props) {
  const [openEditForm, setOpenEditForm] = useState(false);
  const [deletemodal, SetDeleteModal] = useState(false);
  const [activeStatus, setActiveStatus] = useState(props.isActive);
  const [selectedRowId, setSelectedRowId] = useState(false);

  const {
    table,
    setEditData,
    SetTable,
    mappedServiceData,
    SetMappedServiceData,
    SetViewReadOnly,
    setAddMode,
    vehicleTypes,
    SetEditMode,
    viewReadOnly,
    handleServiceDelete,
    review,
    SetReview,
    editMode,
    isSelected,
    setIsSelected,
    pic,
    setPic,
    SetRowActive,
  } = useContext(ServiceContext);
  //Popup delete
  const handleDelete = (e) => {
    SetDeleteModal(true);
    e.stopPropagation();
  };
  const setEditForm = () => {
    setEditData(props.id, setOpenEditForm);
    setPic([]);
    setSelectedRowId(props.id);
    setEditForm(true);
    //  SetRowActive(true);
  };
  const effectFn = () => {
    if (
      props.id === mappedServiceData.service_id &&
      activeStatus !== mappedServiceData.is_active
    ) {
      setActiveStatus(mappedServiceData.is_active);
    }
    if (openEditForm) {
      props.handleViewService();
      props.serviceformClose(true);
      SetViewReadOnly(true);
      // SetEditMode(false);
      SetRowActive(true);
      setOpenEditForm(false);
    }
  };
 
  useEffect(effectFn, [openEditForm, table]);
  const toggleActive = (e) => {
    e.stopPropagation();
  };
  const vehicleType = vehicleTypes.filter(
    (e) => e.value === props.vehicleSelect
  );

  return (
    <Fragment>
      <tr
        onClick={setEditForm}
        key={props.id}
        className={`trow single-row ${
          Object.keys(mappedServiceData).length &&
          mappedServiceData.service_id === props.id
            ? "activeRow"
            : "test1"
        }`}
      >
        <td click="view">
          {props.name}
          {props.status === -1 && (
            <span class="badge rejected ml-2">Rejected</span>
          )}
          {props.status === 0 && (
            <span class="badge draft ml-2">Draft Saved</span>
          )}
          {props.status === 1 && (
            <span class="badge  ml-2 pending">Pending Review</span>
          )}
        </td>
        <td click="view">
          {Boolean(vehicleType.length) && vehicleType[0].title}
        </td>
        <td click="view">{activeStatus ? "Active" : "Inactive"}</td>
        <td>
          <div className="actions toggle-delete-actions">
            <span className="action-delete" onClick={handleDelete}>
              <i className="fal fa-trash"></i>
            </span>
            <span className="" onClick={toggleActive}>
              <NavToggle
                status={props.status}
                id={props.id}
                name={props.name}
                mappedServiceData={mappedServiceData}
                isActive={activeStatus}
                setActiveStatus={setActiveStatus}
              />
            </span>
          </div>
        </td>
      </tr>
      {/* <DataTable data={table}  pagination /> */}
      {deletemodal && (
        <ServiceModal SetDeleteModal={SetDeleteModal} id={props.id} />
      )}
    </Fragment>
  );
}

export default ServiceRow;
