import React from "react";
import styled from "styled-components";
import Select from "react-select";

type Option = {
  label: string;
  value: string | number;
};

type Props = {
  disabled?: boolean;
  className?: string;
  data?: Option[];
  value?: Option | null;
  onChange?: (option: Option | null) => void;
};

const CustomDropDown = ({
  disabled,
  className,
  data,
  value,
  onChange,
}: Props) => {
  return (
    <Wrapper>
      <Select
        isDisabled={disabled}
        className={className}
        options={data}
        value={value}
        onChange={(option) => onChange?.(option)}
        classNamePrefix={"dropdown"}
      />
    </Wrapper>
  );
};

export default CustomDropDown;
const Wrapper = styled.div`
  .css-13cymwt-control {
    background-color: #e8e8e8;
    /* width: 100px !important; */
  }
  .info-style {
  }
  .heading-style .sl-style {
    width: 100px !important;
  }
`;
