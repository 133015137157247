import React, { useContext, useState, Fragment, } from "react";
import { ProfileContext } from "../ProfileContext";
import { Scrollbars } from "react-custom-scrollbars-2";
import { BankValidation } from "../Ui/BankValidation";
import { toast } from "react-toastify";
import uploadImageFile from "../../../../../assets/img/icon/upload/photos.svg";
import {
  createOrUpdateBankDetails,
  getBankList,
} from "../../../../../api/services/sap/profile-management";
import qrImage from "../../../../../assets/img/qr-image/1.webp";
import upiImage from "../../../../../assets/img/qr-image/2.jpg";
const BankDetailstab = () => {
  const {
    innertab,
    userProfile,
    setUserProfile,
    bankviewonly,
    setBankViewOnly,
    bankformData,
    setBankFormData,
    formErrors,
    SetFormErrors,
    error,
    setError,
    sapLogo,
    handleShow,
    addImageSubmit,
    infoviewonly,
    profileConfigData,
    setProfileConfigData,
  } = useContext(ProfileContext);
  

  const [formsubmit, setFormSubmit] = useState(false);
  const [paymentForm, setPaymentForm] = useState([]);
  

  //ON change Validate
  const onChangeValidate = (field, value) => {
    const errors = BankValidation(bankformData, value, true, field, profileConfigData);
    if (Object.keys(errors).length !== 0) {
      setError(true);
      if (Object.keys(formErrors).length !== 0) {
        const updatedErrorObject = { ...formErrors };
        for (const [key, value] of Object.entries(errors)) {
          updatedErrorObject[key] = value?.replace(/[_\\]/g, ' '); 
        }
        SetFormErrors(updatedErrorObject);
      } else {
        const formattedErrors = {};
        for (const [key, value] of Object.entries(errors)) {
          formattedErrors[key] = value?.replace(/[_\\]/g, ' '); 
        }
        SetFormErrors(formattedErrors);
      }
    } else {
      if (Object.keys(formErrors).length !== 0) {
        const updatedErrorObject = { ...formErrors };
        SetFormErrors(updatedErrorObject);
      } else {
        setError(false);
      }
    }
  };

  let payableAmt =
    (paymentForm &&
      paymentForm.rate_total &&
      parseInt(paymentForm.rate_total)) +
    (paymentForm && paymentForm.additional_charges
      ? parseInt(paymentForm.additional_charges)
      : 0) -
    (paymentForm && paymentForm.paid_amount
      ? parseInt(paymentForm.paid_amount)
      : 0) -
    (paymentForm && paymentForm.additional_discount
      ? parseInt(paymentForm.additional_discount)
      : 0);

  const userid = localStorage.getItem("userid");
  //preFormSubmitHandler
  const bankFormSubmitHandler = (e) => { 
    e.preventDefault();
    setFormSubmit(true);
    let errors = BankValidation(bankformData, null, false, null, profileConfigData);
    if (Object.keys(errors).length !== 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      const bankData = createOrUpdateBankDetails({
        ...bankformData,
        userid: userid,
      });
      setBankViewOnly(true);
      toast.success("Details Updated Successfully", {
        position: "top-right",
      });
      bankData.then((res) => {
        const data = getBankList();
        data.then((res) => {
          setBankFormData(res);
          setBankViewOnly(true);
          
        });
      });
    }
  };

  const showSaveButton = () => {
    const condition =
      (Object.keys(bankformData).filter(
        (value) => bankformData[value] === undefined
      ).length &&
        !bankviewonly) ||
      (!Object.keys(bankformData).filter(
        (value) => bankformData[value] === undefined
      ).length &&
        !bankviewonly) ||
      (!bankviewonly && Object.keys(bankformData).length);

    return condition;
  };
const defaultBankType =
  profileConfigData &&
  profileConfigData?.bank_type &&
  profileConfigData?.bank_type?.bank_number_type_id_pk
    ? profileConfigData?.bank_type
    : { bank_number_type_id_pk: 2, title: "IBAN" };


  

  return (
    <div
      className={innertab === 2 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div className="main-tab-block">
          <div className="inner-block tab-form-block">
            <Fragment>
              <div className=""></div>
              <form className="" onSubmit={bankFormSubmitHandler}>
                <p className=" mb-3 text-start text-info">
                  Service Center Bank Details
                </p>
                <div className="form-box">
                  {/* Account Holder Name */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-user"></i>Account Holder Name
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        readOnly={bankviewonly ? true : false}
                        // readOnly={
                        //   bankviewonly && bankformData == null
                        //     ? false
                        //     : bankviewonly && bankformData !== null
                        //     ? false
                        //     : ""
                        // }
                        type="text"
                        className="form-control"
                        placeholder="Account holder name"
                        onChange={(e) => {
                          onChangeValidate(
                            "account_holder_name",
                            e.target.value
                          );
                          setBankFormData({
                            ...bankformData,
                            account_holder_name: e.target.value,
                          });
                        }}
                        value={
                          bankformData && bankformData.account_holder_name
                            ? bankformData.account_holder_name
                            : ""
                        }
                        onBlur={(e) => {
                          if (!bankviewonly) {
                            onChangeValidate(
                              "account_holder_name",
                              e.target.value
                            );
                          }
                        }}
                      />
                      {!bankviewonly &&
                      error &&
                      formErrors.account_holder_name ? (
                        <span className="form-error">
                          {formErrors.account_holder_name}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* Bank Name */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-university"></i>Bank Name
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        readOnly={bankviewonly ? true : false}
                        // readOnly={
                        //   bankviewonly && bankformData == null
                        //     ? false
                        //     : bankviewonly && bankformData !== null
                        //     ?  false
                        //     : ""
                        // }
                        type="text"
                        className="form-control"
                        placeholder="Bank name"
                        onChange={(e) => {
                          onChangeValidate("bank_name", e.target.value);
                          setBankFormData({
                            ...bankformData,
                            bank_name: e.target.value,
                          });
                        }}
                        value={
                          bankformData && bankformData.bank_name
                            ? bankformData.bank_name
                            : ""
                        }
                        onBlur={(e) => {
                          if (!bankviewonly) {
                            onChangeValidate("bank_name", e.target.value);
                          }
                        }}
                      />
                      {!bankviewonly && error && formErrors.bank_name ? (
                        <span className="form-error">
                          {formErrors.bank_name}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* Branch Name */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-code-branch"></i>Branch Name
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        readOnly={bankviewonly ? true : false}
                        // readOnly={
                        //   bankviewonly && bankformData == null
                        //     ? false
                        //     : bankviewonly && bankformData !== null
                        //     ?  false
                        //     : ""
                        // }
                        type="text"
                        className="form-control"
                        placeholder="Branch name"
                        onChange={(e) => {
                          onChangeValidate("branch_name", e.target.value);
                          setBankFormData({
                            ...bankformData,
                            branch_name: e.target.value,
                          });
                        }}
                        value={
                          bankformData && bankformData.branch_name
                            ? bankformData.branch_name
                            : ""
                        }
                        onBlur={(e) => {
                          if (!bankviewonly) {
                            onChangeValidate("branch_name", e.target.value);
                          }
                        }}
                      />
                      {!bankviewonly && error && formErrors.branch_name ? (
                        <span className="form-error">
                          {formErrors.branch_name}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* Account Number */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-sort-numeric-down"></i>
                        {defaultBankType?.title}
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <input
                        type="text"
                        readOnly={bankviewonly ? true : false}
                        className="form-control"
                        maxLength="15"
                        placeholder={
                          defaultBankType.bank_number_type_id_pk === 1
                            ? "Account number"
                            : "IBAN number"
                        }
                        onChange={(e) => {
                          onChangeValidate("account_number", e.target.value);
                          setBankFormData({
                            ...bankformData,
                            account_number: e.target.value.replace(
                              /[^0-9]/g,
                              ""
                            ),
                          });
                        }}
                        value={
                          bankformData && bankformData.account_number
                            ? bankformData.account_number
                            : ""
                        }
                        onBlur={(e) => {
                          if (!bankviewonly) {
                            onChangeValidate("account_number", e.target.value);
                          }
                        }}
                      />
                      {!bankviewonly && error && formErrors.account_number ? (
                        <span className="form-error">
                          {formErrors.account_number}
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  {/* IFSC Code */}
                  {profileConfigData?.bank_type?.bank_number_type_id_pk ===
                  1 ? (
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="fal fa-keyboard"></i>IFSC Code
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <div className="input-block">
                        <input
                          readOnly={bankviewonly ? true : false}
                          // readOnly={
                          //   bankviewonly && bankformData == null
                          //     ? false
                          //     : bankviewonly && bankformData !== null
                          //     ?  false
                          //     : ""
                          // }
                          type="text"
                          className="form-control"
                          placeholder="IFSC code"
                          onChange={(e) => {
                            onChangeValidate("ifsc_code", e.target.value);
                            setBankFormData({
                              ...bankformData,
                              ifsc_code: e.target.value,
                            });
                          }}
                          value={
                            bankformData && bankformData.ifsc_code
                              ? bankformData.ifsc_code
                              : ""
                          }
                          onBlur={(e) => {
                            if (!bankviewonly) {
                              onChangeValidate("ifsc_code", e.target.value);
                            }
                          }}
                        />
                        {!bankviewonly && error && formErrors.ifsc_code ? (
                          <span className="form-error">
                            {formErrors.ifsc_code}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/* QR Uploader */}
                  {profileConfigData?.bank_type?.bank_number_type_id_pk ===
                  1 ? (
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i class="fal fa-keyboard"></i>QR Code Upload
                        <sup className="theme-text">*</sup>
                      </span>
                    </div>
                    <div className="input-block">
                      <div className="input-group input-group-custom img-block">
                        <div className="small-img-block">
                         
                          <div className="small-block">
                            <button
                              disabled={infoviewonly ? true : false}
                              type="button"
                              className="btn-image"
                              onClick={handleShow}
                            >
                              <div className="upload-section justify-content-center">
                                <div className="upload-img">
                                  <img src={uploadImageFile} alt="loading..." />
                                </div>
                              </div>
                              Upload QR
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                   ) : (
                    ""
                  )}
                </div>
              
                {showSaveButton() && (
                  <div class="col-12 text-end p-0 mt-3">
                    <button class="btn btn-update" type="submit">
                      <i class="fal fa-save mr-2"></i>Save
                    </button>
                  </div>
                )}
              </form>
            </Fragment>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default BankDetailstab;
