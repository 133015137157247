import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import images from "./assets/img/CERAMIC-ULTRA.png";
import styled from "styled-components";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { ProductListModel } from "../../api/services/sap/vap/models/productListModel";
import configData from "../../config.json";

interface Props {
  item: ProductListModel;
  onclick?: (event: any) => void;
  isSelected: boolean;
  className?: string | undefined;
}

export default function RightViewProductCard({
  item,
  onclick,
  isSelected,
  className,
}: Props) {
  console.log("cardViewwwwww", item?.type, item);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log(event, "eventevent");
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    // <div>

    <StyledCard
      className={`custom-product-card ${className} ${
        isSelected ? "selected" : "not-selected"
      }`}
      sx={{
        // maxWidth: 255,
        width: 251,
        marginLeft: 1,
        position: "relative",
        display: "flex",
        height: 82,
        marginTop: 1,
      }}
      onClick={onclick}
    >
      <CardMedia
        className={`custom-image-card ${!item?.thumbnail && "static-image"}`}
        component="img"
        image={
          item.thumbnail
            ? `${configData.SERVER_URL}/${item.thumbnail}`
            : "/static/media/photos.012563c49cb4503fc4028085b7283e02.svg"
        }
        alt="thumbnail"
      />
      <CardContent className="custom-card-content">
        {<Typography gutterBottom variant="h5" component="div"></Typography>}
        <Typography
          className="custom-card-item-name"
          variant="body2"
          color="text.secondary"
        >
          {item?.product_name}
        </Typography>
        <div className="custom-brand-name">
          <span className="brand-text">
            {item?.brand?.brand_name}, 
            {item?.type?.title}
          </span>

          {/* <div className="custom-purchase-from">{item.stock}</div> */}
        </div>
        <div className="custom-price-tag">$ {item?.price_per_unit}</div>
      </CardContent>
      <CardActions></CardActions>
    </StyledCard>

    // </div>
  );
}

const StyledCard = styled(Card)`
  .custom-product-card {
    position: relative !important;
    width: calc(100% - 66px);
  }
  &.selected {
    border: 1px solid blue !important;
    background-color: hsl(208.24deg 100% 93.33%);
  }
  .not-selected {
  }

  .custom-price-tag {
    color: rgb(78, 167, 61, 87%);
    text-align: left;
    font-size: 13px;
    font-weight: 700;
  }

  .custom-card-item-name {
    text-align: left;
    font-weight: 700;
  }

  .custom-image-card { 
    width: 66px;
  }
  .static-image {
    width: 60px;
    height: 52px;
    margin-block: auto;
    
  }
  .custom-purchase-from {
    color: rgb(255, 160, 73);
    font-size: 11px;
  }

  .custom-brand-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    margin-top: 5px;
    /* font-size: 10px; */
  }

  .brand-text {
    color: #6d6666;
    margin-right: 22px;
    font-size: 11px;
    white-space: nowrap;
    width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  .custom-new-item-tag {
    background-color: #58c558;
    color: white;
    width: 38% !important;
    font-size: 10px !important;
    height: 18px;
    position: absolute !important;
    top: 20px;
    left: 0;
  }
  .custom-left-item-tag {
    background-color: #f80b0b;
    color: white;
    width: 38% !important;
    font-size: 10px !important;
    height: 18px;
    position: absolute !important;
    top: 20px;
    left: 0;
  }
  .custom-card-content {
    padding: 0px;
  }
  .custom-price-tag {
    margin-top: 5px;
  }
  .custom-dot-view {
    /* font-size: 10px; */
    min-width: 33px;
    font-weight: 800;
    color: black;
  }
`;
