import React from "react";
import styled from "styled-components";
import Select, { GetOptionLabel, GetOptionValue } from "react-select";

type Option = {
  label: string;
  value: string | number;
};

type Props = {
  disabled?: boolean;
  className?: string;
  data?: Option[];
  value?: Option | null;
  onChange?: (option: Option | null) => void;
  getOptionLabel?: GetOptionLabel<Option>;
  getOptionValue?: GetOptionValue<Option>;
};

const CustomDropDown = ({
  disabled,
  className,
  data,
  value,
  onChange,
  getOptionLabel,
  getOptionValue,
}: Props) => {
  return (
    <Wrapper className={`${disabled ? "disabled" : ""} ${className}`}>
      <Select
        isDisabled={disabled}
        options={data}
        value={value}
        onChange={(option) => onChange?.(option)}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        classNamePrefix={'dropdown'}
      />
    </Wrapper>
  );
};

export default CustomDropDown;
const Wrapper = styled.div`
  &.disabled {
    .css-1xc3v61-indicatorContainer {
      display: none;
    }
  }
  .css-13cymwt-control {
    background-color: #e8e8e8;
    /* width: 100px !important; */
  }
  .info-style {
  }
  .heading-style .sl-style {
    width: 100px !important;
  }
  .css-t3ipsp-control {
    background-color: #e8e8e8;
    border: none !important;
    box-shadow: none;
  }
`;
