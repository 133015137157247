import React, { useState } from "react";
import ProfileBlock from "./ProfileBlock";
const CustomerContentHeader = () => {
  const [addprofile, SetAddProfile] = useState(false);
  const profileBlock = () => {
    SetAddProfile(!addprofile);
  };
  return (
    <div className="content-header-main">
      <div className="content-header">
        <div className="content-top">
          <div className="content-top-left d-flex align-items-center">
            <div className="content-details">
              <h4>Downtown Moto</h4>
              <p>Balaramapuram</p>
            </div>
          </div>
          <div className="content-top-right">
            <button className="btn btn-faq">FAQS</button>
            <button className="btn btn-notification">
              <i className="fal fa-bell"></i>
            </button>
            <button className="btn btn-user" onClick={profileBlock}>
              <i className="fal fa-user"></i>
            </button>
          </div>
          {addprofile && <ProfileBlock />}
        </div>
      </div>
    </div>
  );
};

export default CustomerContentHeader;
