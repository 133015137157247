import React, { useState, useContext, Fragment, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { OrdersContext } from "../OrdersContext";
import uploadImageFile from "../../../../../assets/img/icon/upload/photos.svg";
import PostServiceImageModal from "./PostServiceImageModal";
import img1 from "../../../../../assets/img/carbody/1.png";
import img2 from "../../../../../assets/img/carbody/2.png";
import img3 from "../../../../../assets/img/carbody/3.png";
import img4 from "../../../../../assets/img/carbody/4.png";
import configData from "../../../../../config.json";
import {
  saveOrderInspection,
  getOrderInspection,
} from "../../../../../api/services/sap/order-management";

import ReactTooltip from "react-tooltip-rc";
import styled from "styled-components";
import { handleDownloadInvoice } from "../../../../../utils/common";
let initialstate = {
  sap_post_checknotes: "",
};
const PostServiceReviewTab = () => {
  const {
    innertab,
    SetinnerTab,
    mappedOrderData,
    postFormData,
    setPostFormData,
    postservicepic,
    setPostServicePic,
    postaddonImages,
    setPostAddonImages,
    postAddImageSubmit,
    setPostAddImageSubmit,
    postModal,
    setPostModal,
    handleClosePostModal,
    handleShowPostModal,
    postviewonly,
    setPostViewOnly,
  } = useContext(OrdersContext);
  //Form state
  const [postformvalues, setPostFormValues] = useState([]);
  const [checkedFormvalues, setCheckedFormvalues] = useState();
  const [checkedNotes, setCheckedNotes] = useState(initialstate);
  const [carouselView, setCarouselView] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [editView, setEditView] = useState(false);

  useEffect(() => {
    setPostViewOnly(false);
    const inspectionType = "post";
    const orderId = mappedOrderData.order_id_pk;
    getOrderInspection(orderId, inspectionType)
      .then((res) => {
        console.log(res, "res PostgetOrderInspection");
        if (res) {
          setPostFormValues(res);
          setPostViewOnly(true);
          setEditView(false);
        }
      })
      .catch((error) => {
        console.error("Error occurred during getOrderInspection:", error);
        setPostViewOnly(false);
        setEditView(true);
      });
  }, []);

  //image note handler
  const imageNoteHandler = (value, dataindex, type) => {
    if (type === "add") {
      let newVar = postservicepic.map((e, index) => {
        if (index === dataindex) {
          e.description = value;
        }
        return e;
      });
      setPostServicePic(newVar);
    }
    if (type === "edit") {
      let newVar = postformvalues.orderInspectionComment.map(
        (predata, index) => {
          if (index === dataindex) {
            predata.description = value;
          }
          return predata;
        }
      );

      setPostFormValues({ ...postformvalues, orderInspectionComment: newVar });
      // setPreServicePic({...preservicepic,...newVar});
    }
  };

  // //image note handler
  // const imageNoteHandler = (value, index) => {
  //   let newVar = postservicepic.filter((e) => {
  //     if (e.index === index) {
  //       e.imageNote = value;
  //     }
  //     return e;
  //   });
  //   setPostServicePic(newVar);
  // };

  const postFormSubmitHandler = (e) => {
    e.preventDefault();
    // setPostFormData((prev) => [
    //   ...prev,
    //   {
    //     ...postformvalues,
    //     postservicepic,
    //     oreder_id: mappedOrderData.order_id,
    //   },
    // ]);

    // let finalArray = postservicepic && preformvalues.orderInspectionComment?
    //   [...preformvalues.orderInspectionComment,...postservicepic]:postservicepic?[...postservicepic]:preformvalues.orderInspectionComment?[...preformvalues.orderInspectionComment]:[];
    //   console.log(finalArray,"finalArray second");
    // const preFormData = saveOrderInspection({
    //   ...preformvalues,
    //   orderInspectionComment: finalArray ,
    //   type: "pre",
    //   order_id_fk: mappedOrderData.order_id_pk,
    //   order_inspection_id_pk: preformvalues.order_inspection_id_pk,
    // });
    // preFormData.then((res) => {
    //   // console.log(res, "preimg");
    //   setPreFormValues(res);
    //   setPreServicePic([]);
    // });
    setPostViewOnly(true);
    let finalArray =
      postservicepic && postformvalues.orderInspectionComment
        ? [...postformvalues.orderInspectionComment, ...postservicepic]
        : postservicepic
        ? [...postservicepic]
        : postformvalues.orderInspectionComment
        ? [...postformvalues.orderInspectionComment]
        : [];
    const postFormData = saveOrderInspection({
      ...postformvalues,
      orderInspectionComment: finalArray,
      type: "post",
      order_id_fk: mappedOrderData.order_id_pk,
      order_inspection_id_pk: postformvalues.order_inspection_id_pk,
    });
    postFormData.then((res) => {
      setPostFormValues(res);
      setPostServicePic([]);
      // setCarouselView(true);
    });
    setCarouselView(false);
    setEditView(false);
  };
  // console.log(postFormData, "postFormDatapostFormData");
  //Checkbox handler
  const handleCheckHandler = (e) => {
    const value = e.target.value;
    const checked = e.target.checked;
    console.log(value, checked, "Checkbox Test");
    if (checked) {
      setCheckedValues([...checkedValues, value]);
    } else {
      setCheckedValues(checkedValues.filter((e) => e !== value));
    }
  };
  //checkFormSubmitHandler handler
  const checkFormSubmitHandler = (e) => {
    e.preventDefault();
    setCheckedFormvalues((pre) => [
      { ...pre, ...checkedValues, ...checkedNotes },
    ]);
    // setCheckedFormvalues([...checkedFormvalues, ...checkedValues,])
    console.log(checkedValues, "checkedValues Test");
    console.log(checkedNotes, "checkedNotes Test");
  };
  console.log(checkedFormvalues, "checkedFormvalues Test");

  console.log(postFormData, "postFormDatapostFormData");

  console.log(postservicepic, "postservicepic");

  const preformHandleRemove = (index) => {
    // console.log(index, "image id");
    const deleteRow = postformvalues.orderInspectionComment.filter(
      (v, i) => i !== index
    );
    setPostFormValues({ ...postformvalues, orderInspectionComment: deleteRow });
  };

  //Disable Save
  function disableSave() {
    const valid =
      postservicepic.some((item) => item.description === "") ||
      postformvalues.orderInspectionComment?.some(
        (item) => item.description === ""
      );
    return valid;
  }

  return (
    <div
      className={innertab === 3 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      {" "}
      {!carouselView ? (
        <div className="inner-block">
          <Fragment>
            <div className="container">
              <div className="row justify-content-end">
                <div className="col-12">
                  <div className="head-title">
                    {postviewonly && (
                      <h6>
                        Kindly check the vehicle to look for spots, patches, or
                        any belongings
                      </h6>
                    )}
                    {!editView &&
                      postformvalues &&
                      Object.keys(postformvalues).length > 0 && (
                        <div className="text-end mt-3">
                          <button
                            className="btn btn-update"
                            onClick={(e) => {
                              setPostViewOnly(false);
                              setEditView(true);
                            }}
                          >
                            <i className="fal fa-edit mr-2"></i>Edit
                          </button>
                          {/* <button
                            className="btn btn-update ml-2"
                            onClick={(e) => {
                              setCarouselView(true);
                            }}
                          >
                            Review
                          </button> */}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>

            <form className="" onSubmit={postFormSubmitHandler}>
              {/* preservice pic block */}
              <div className="form-box">
                <div className="input-group input-group-custom img-block">
                  <div className="small-img-sub-block">
                    <RowWrapper className="row-img row col-12">
                      {/* file from db */}
                      {postformvalues && postformvalues.orderInspectionComment
                        ? postformvalues.orderInspectionComment.map(
                            (data, index) => {
                              return (
                                <div className="row row-wrapper" key={index}>
                                  <div className="col-md-2 col-img-list">
                                    <div className="img-list">
                                      <div className="choosed-file-block">
                                        <div className="pdf-block">
                                          <img
                                            src={`${configData.SERVER_URL}/uploads/order-inspection-images/${data.image_url}`}
                                            className="img-fluid"
                                            alt=""
                                          />

                                          {/* <a
                                            href=
                                            download
                                            target="_blank"
                                          ></a> */}
                                        </div>
                                      </div>
                                      <DownloadWrapper>
                                        <a
                                          type="button"
                                          class="btn btn-outline-dark"
                                          href
                                          onClick={() => {
                                            handleDownloadInvoice(
                                              `${configData.SERVER_URL}/uploads/order-inspection-images/${data.image_url}`
                                            );
                                          }}
                                        >
                                          <i class="fal fa-arrow-to-bottom"></i>
                                        </a>
                                      </DownloadWrapper>
                                    </div>
                                  </div>
                                  <div className="col-md-10 driscription-wrapper">
                                    {postservicepic.length > 1 ||
                                    !postviewonly ? (
                                      <div
                                        className="text-end"
                                        style={{
                                          position: "absolute",
                                          top: "-4%",
                                          right: "-1%",
                                        }}
                                      >
                                        <button
                                          disabled={postviewonly ? true : false}
                                          type="button"
                                          className="btn "
                                          onClick={() =>
                                            preformHandleRemove(index)
                                          }
                                        >
                                          <i class="fal fa-times text-danger"></i>
                                        </button>
                                      </div>
                                    ) : (
                                      ""
                                    )}

                                    <div className="form-box">
                                      <div className="mb-3">
                                        <textarea
                                          readOnly={postviewonly ? true : false}
                                          type="text"
                                          className="form-control note-textarea"
                                          placeholder="Description *"
                                          onChange={(e) => {
                                            imageNoteHandler(
                                              e.target.value,
                                              index,
                                              "edit"
                                            );
                                          }}
                                          value={data.description}
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            }
                          )
                        : ""}

                      {/* for BASE64  */}
                      {postAddImageSubmit && postservicepic
                        ? postservicepic.map((photo, index) => {
                            return (
                              <div className="row row-wrapper">
                                <div
                                  className="col-md-2 col-img-list"
                                  key={index}
                                >
                                  <div className="img-list">
                                    <div className="choosed-file-block">
                                      <div className="pdf-block">
                                        <img
                                          src={photo.inspection_image.base64}
                                          className="img-fluid"
                                          alt="serviceImage"
                                        />
                                      </div>
                                    </div>

                                    {postservicepic.length > 0 ||
                                    postviewonly ? (
                                      <DownloadWrapper>
                                        <a
                                          type="button"
                                          class="btn btn-outline-dark"
                                          href={photo.inspection_image.base64}
                                          rel="noreferrer"
                                          target="_blank"
                                          download
                                        >
                                          <i class="fal fa-arrow-to-bottom"></i>
                                        </a>
                                      </DownloadWrapper>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                <div className="col-md-10 driscription-wrapper">
                                  {postservicepic.length >= 1 &&
                                  !postviewonly ? (
                                    <div
                                      className="text-end"
                                      style={{
                                        position: "absolute",
                                        top: "-4%",
                                        right: "-1%",
                                      }}
                                    >
                                      <button
                                        disabled={postviewonly ? true : false}
                                        className="btn"
                                        type="button"
                                        onClick={() => {
                                          setPostServicePic(
                                            postservicepic.filter(
                                              (e) =>
                                                e.inspection_image !==
                                                photo.inspection_image
                                            )
                                          );
                                          setPostViewOnly(false);
                                        }}
                                      >
                                        <i className="fal fa-times text-danger"></i>
                                      </button>
                                    </div>
                                  ) : (
                                    ""
                                  )}

                                  <div className="form-box">
                                    <div className="mb-3">
                                      <textarea
                                        readOnly={postviewonly ? true : false}
                                        type="text"
                                        placeholder="Description *"
                                        className="form-control note-textarea"
                                        onChange={(e) => {
                                          imageNoteHandler(
                                            e.target.value,
                                            index,
                                            "add"
                                          );
                                        }}
                                        value={photo.description}
                                        // onChange={(e) => {
                                        //   imageNoteHandler(e.target.value, index);
                                        // }}
                                        // value={photo.imageNote}
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </RowWrapper>
                    {editView && (
                      <UploadWrapper>
                        <div className="small-block">
                          <button
                            disabled={postviewonly ? true : false}
                            type="button"
                            className="btn-image default-image"
                            onClick={handleShowPostModal}
                          >
                            <div className="upload-section justify-content-center">
                              <div className="upload-img">
                                <img src={uploadImageFile} alt="loading..." />
                              </div>
                            </div>
                            {postservicepic.length === 0
                              ? "Upload"
                              : "Add more"}
                          </button>
                        </div>
                      </UploadWrapper>
                    )}
                  </div>
                </div>
              </div>
              <p className=" mb-3 text-start text-primary">
                Add photos or comments of anything you find
              </p>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fal fa-paste"></i>SAP Notes
                  </span>
                </div>
                <div className="input-block">
                  <textarea
                    readOnly={
                      !editView && Object.keys(postformvalues).length > 0
                        ? true
                        : false
                    }
                    class="form-control"
                    placeholder="Notes from SAP"
                    onChange={(e) => {
                      setPostFormValues({
                        ...postformvalues,
                        sap_note: e.target.value,
                      });
                    }}
                    value={
                      postformvalues && postformvalues.sap_note
                        ? postformvalues.sap_note
                        : ""
                    }
                  ></textarea>
                  {/* <span className="form-error">Address is required!</span> */}
                </div>
              </div>
              {editView ? (
                <div className="col-12 text-end p-0 mt-3">
                  <button
                    class="btn btn-update"
                    type="submit"
                    disabled={disableSave()}
                  >
                    <i class="fal fa-save mr-2"></i>Save
                  </button>
                </div>
              ) : (
                ""
              )}
            </form>
          </Fragment>
        </div>
      ) : (
        <div className="inner-block">
          <Fragment>
            <div className="d-flex align-items-center justify-content-center flex-column">
              <div className="w-100 pt-3 pb-3 text-start">
                <button
                  title="Close Window"
                  onClick={(e) => setCarouselView(false)}
                  class="btn btn-action btn-back"
                  id="backBtn"
                  rel="tooltip"
                  data-for="backTooltip"
                  data-tip="Back to add notes"
                >
                  <i class="fal fa-long-arrow-left"></i>
                </button>
                <ReactTooltip
                  id="backTooltip"
                  place="left"
                  type="info"
                  effect="float"
                />
              </div>
              <div className="w-100">
                <form className="" onSubmit={checkFormSubmitHandler}>
                  <div className="vehichle-inner-block four-wheeler-inner-block">
                    <div className="vehiche-details-block">
                      <div className="vehichle-details">
                        <h2 class="mb-0">KL01F3333</h2>
                        <div className="vehiche-contents">
                          <h6 class="mb-0">Mahindra thar</h6>
                          <p class="mb-0"> 2021 black</p>
                        </div>
                      </div>
                      {/* <div className="close-sub-block">
                    <span>
                      <i class="fal fa-times"></i>
                    </span>
                  </div> */}
                    </div>
                    <div className="">
                      <p class="mb-0">Review</p>
                    </div>
                    <div className="status-main-block">
                      <div className="status-block d-flex justify-content-between align-items-center">
                        <div className="payment-status">
                          <p>
                            PAYMENT STATUS: <button class="btn">UPDATE</button>
                          </p>
                        </div>
                        <div className="delivery-status">
                          <p>
                            DELIVERY STATUS: <span> Not ready</span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* Carousel Start */}
                    <div className="col-12 p-0 task-block">
                      <div
                        id="vehichle-controls"
                        class="carousel slide carousel-fade"
                        data-ride="carousel"
                        data-interval="false"
                      >
                        <div className="carousel-inner task-carousel">
                          <Carousel
                            fade
                            prevIcon={
                              <span
                                id="ndata-carousel"
                                aria-hidden="true"
                                className="carousel-control-prev-icon"
                              >
                                <i class="fal fa-chevron-left"></i>
                              </span>
                            }
                            nextIcon={
                              <span
                                id="ndata-carousel"
                                aria-hidden="true"
                                className="carousel-control-next-icon"
                              >
                                <i class="fal fa-chevron-right"></i>
                              </span>
                            }
                            interval={null}
                          >
                            {/* Carousel1 */}
                            <Carousel.Item>
                              <div className="row">
                                <div className="col-md-5">
                                  <div
                                    class="vehichle-sketch"
                                    id="vehichle-controls"
                                    data-ride="carousel"
                                  >
                                    <img src={img1} class="img-fluid" alt="" />
                                  </div>
                                </div>
                                <div className="col-md-7">
                                  <div className="row m-0">
                                    <div className="col-12 task-title">
                                      <h5>Exterior Right</h5>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            id="check-id"
                                            onChange={handleCheckHandler}
                                            name="PassengerWindow"
                                            value="PassengerWindow"
                                          />
                                          <label class="text-secondary">
                                            Passenger Window
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            id="check-id"
                                            onChange={handleCheckHandler}
                                            name="FrontWindow"
                                            value="FrontWindow"
                                          />
                                          <label class="text-secondary">
                                            Front Window
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            id="check-id"
                                            onChange={handleCheckHandler}
                                            name="FrontWheel"
                                            value="FrontWheel"
                                          />
                                          <label class="text-secondary">
                                            Front Wheel
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            id="check-id"
                                            onChange={handleCheckHandler}
                                            name="OverallExteriorRight"
                                            value="DoorsOverallExteriorRight"
                                          />
                                          <label class="text-secondary">
                                            Doors and Overall Exterior
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            id="check-id"
                                            onChange={handleCheckHandler}
                                            name=" FrontWheelWell"
                                            value="FrontWheelWell"
                                          />
                                          <label class="text-secondary">
                                            Front Wheel Well
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            id="check-id"
                                            onChange={handleCheckHandler}
                                            name=" BackWheelWell"
                                            value="BackWheelWell"
                                          />
                                          <label class="text-secondary">
                                            Back Wheel Well
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            id="check-id"
                                            onChange={handleCheckHandler}
                                            name=" FrontTyreFlap"
                                            value="FrontTyreFlap"
                                          />
                                          <label class="text-secondary">
                                            Front Tyre Flap
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            id="check-id"
                                            onChange={handleCheckHandler}
                                            name=" BackTyreFlap"
                                            value="BackTyreFlap"
                                          />
                                          <label class="text-secondary">
                                            Back Tyre Flap
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Carousel.Item>
                            {/* Carousel2 */}
                            <Carousel.Item>
                              <div className="row">
                                <div className="col-md-5">
                                  <div
                                    class="vehichle-sketch"
                                    id="vehichle-controls"
                                    data-ride="carousel"
                                  >
                                    <img src={img2} class="img-fluid" alt="" />
                                  </div>
                                </div>
                                <div className="col-md-7">
                                  <div className="row m-0">
                                    <div className="col-12 task-title">
                                      <h5>Exterior Left</h5>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            onChange={handleCheckHandler}
                                            name="TailLights"
                                            value="TailLights"
                                            href=""
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="serviceReminderblock"
                                          />
                                          <label class="text-secondary">
                                            Tail Lights
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            onChange={handleCheckHandler}
                                            name="RearBumper"
                                            value="RearBumper"
                                            href=""
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="serviceReminderblock"
                                          />
                                          <label class="text-secondary">
                                            Rear Bumper
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            onChange={handleCheckHandler}
                                            name="DickyDoor"
                                            value="DickyDoor"
                                            href=""
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="serviceReminderblock"
                                          />
                                          <label class="text-secondary">
                                            Dicky Door
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            onChange={handleCheckHandler}
                                            name="InsideDicky"
                                            value="InsideDicky"
                                            href=""
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="serviceReminderblock"
                                          />
                                          <label class="text-secondary">
                                            Inside Dicky
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Carousel.Item>
                            {/* Carousel3 */}
                            <Carousel.Item>
                              <div className="row">
                                <div className="col-md-5">
                                  <div
                                    class="vehichle-sketch"
                                    id="vehichle-controls"
                                    data-ride="carousel"
                                  >
                                    <img src={img3} class="img-fluid" alt="" />
                                  </div>
                                </div>
                                <div className="col-md-7">
                                  <div className="row m-0">
                                    <div className="col-12 task-title">
                                      <h5>Front</h5>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            onChange={handleCheckHandler}
                                            name="Windshield"
                                            value="Windshield"
                                            href=""
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="serviceReminderblock"
                                          />
                                          <label class="text-secondary">
                                            Windshield
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            onChange={handleCheckHandler}
                                            name="Headlights"
                                            value="Headlights"
                                            href=""
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="serviceReminderblock"
                                          />
                                          <label class="text-secondary">
                                            Headlights
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            onChange={handleCheckHandler}
                                            name="FogLamps"
                                            value="FogLamps"
                                            href=""
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="serviceReminderblock"
                                          />
                                          <label class="text-secondary">
                                            Fog Lamps
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            onChange={handleCheckHandler}
                                            name="NumberPlate"
                                            value="NumberPlate"
                                            href=""
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="serviceReminderblock"
                                          />
                                          <label class="text-secondary">
                                            Number Plate
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            onChange={handleCheckHandler}
                                            name="FrontBumper"
                                            value="FrontBumper"
                                            href=""
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="serviceReminderblock"
                                          />
                                          <label class="text-secondary">
                                            Front Bumper
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            onChange={handleCheckHandler}
                                            name="Bonnet"
                                            value="Bonnet"
                                            href=""
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="serviceReminderblock"
                                          />
                                          <label class="text-secondary">
                                            Bonnet
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            onChange={handleCheckHandler}
                                            name="Wipers"
                                            value="Wipers"
                                            href=""
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="serviceReminderblock"
                                          />
                                          <label class="text-secondary">
                                            Wipers
                                          </label>
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <div className="checkbox-custom form-check">
                                          <input
                                            type="checkbox"
                                            class="check-main"
                                            onChange={handleCheckHandler}
                                            name="FillWaterinWipers"
                                            value="FillWaterinWipers"
                                            href=""
                                            role="button"
                                            aria-expanded="true"
                                            aria-controls="serviceReminderblock"
                                          />
                                          <label class="text-secondary">
                                            Fill Water in Wipers
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Carousel.Item>
                            <Carousel.Item>
                              <div className="row">
                                <div className="col-md-5">
                                  <div
                                    class="vehichle-sketch"
                                    id="vehichle-controls"
                                    data-ride="carousel"
                                  >
                                    <img src={img4} class="img-fluid" alt="" />
                                  </div>
                                </div>
                                <div className="col-md-7">
                                  <div className="row m-0">
                                    <div className="col-12 task-title">
                                      <h5>Back</h5>
                                    </div>
                                    <div className="col-md-6"></div>
                                  </div>
                                </div>
                              </div>
                            </Carousel.Item>
                          </Carousel>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-end p-0 mt-3">
                    <div className="w-100 mb-3">
                      <textarea
                        // readOnly={viewonly ? true : false}
                        class="form-control"
                        placeholder="Notes from SAP"
                        onChange={(e) => {
                          if (e.target.value && e.target.value.length > 0) {
                            setCheckedNotes({
                              ...checkedNotes,
                              sap_post_checknotes: e.target.value,
                            });
                          } else {
                            setCheckedNotes({
                              ...checkedNotes,
                              sap_post_checknotes: e.target.value,
                            });
                          }
                        }}
                        value={checkedNotes.sap_post_checknotes}
                      ></textarea>
                      {/* <span className="form-error">Address is required!</span> */}
                    </div>
                    <button class="btn btn-update" type="submit">
                      <i class="fal fa-save mr-2"></i>Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </Fragment>
        </div>
      )}
      <PostServiceImageModal />
    </div>
  );
};

export default PostServiceReviewTab;

const DownloadWrapper = styled.div`
  position: absolute;
  background: transparent !important;
  height: 30px !important;
  width: 30px !important;
  right: 0px !important;
  top: 0px !important;
  .fal {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn {
    background: rgb(255 255 255 / 70%) !important;
    width: 30px !important;
    height: 30px !important;
    :hover {
      color: #0056b3;
    }
  }
`;
const RowWrapper = styled.div`
  &.row-img {
    padding-right: unset !important;
    padding-left: unset !important;
    margin-right: unset !important;
    margin-left: unset !important;
  }
  .row-wrapper {
    padding-right: unset !important;
    margin-left: unset !important;
    padding-left: unset !important;
  }
  .driscription-wrapper {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .col-img-list {
    padding: 0px 0px 10px 0px !important;
  }
`;

const UploadWrapper = styled.div`
  .default-image {
    width: 150px !important;
  }
`;
