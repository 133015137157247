import React from "react";

function SortDropdownContent() {
  return (
    <div className="dropdown-menu d-block" aria-labelledby="dropdownMenuButton">
      <a className="dropdown-item" href="">
        Recent
      </a>
      <a className="dropdown-item" href="">
        Name
      </a>
      <a className="dropdown-item" href="">
        Price low
      </a>
      <a className="dropdown-item" href="">
        Price high
      </a>
      <a className="dropdown-item" href="">
        oldest
      </a>
    </div>
  );
}

export default SortDropdownContent;
