import React, { createContext, useEffect, useState, ReactNode } from "react";
import { VendorListModel } from "../../../../../api/services/vap/models/VendorListModel";
import { SaveChangesModel } from "../model/SaveChangesModel";

// type ValueProps = {
//   // Define the actual type for initialStateValues
// };

export const VendorContext = createContext<any | undefined>(undefined);

type ContextProviderProps = {
  children: ReactNode;
};

export const VendorProvider: React.FC<ContextProviderProps> = ({
  children,
}) => {
  const [documentsData, setDocumentsData] = useState([]);
  const [saveChangesData, setSaveChangesData] = useState<SaveChangesModel[]>(
    []
  );
  const initialStateValues = {
    documentsData,
    setDocumentsData,
    saveChangesData,
    setSaveChangesData,
  }; // Replace this with the actual initial state values

  return (
    <VendorContext.Provider value={initialStateValues}>
      {children}
    </VendorContext.Provider>
  );
};
