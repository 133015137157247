import React, { useState, useContext } from "react";
import { BookingsContext } from "./BookingsContext";
import BookingsForm from "./BookingsForm";
import { Scrollbars } from "react-custom-scrollbars-2";
import ReactTooltip from "react-tooltip-rc";
import CancelModal from "./Ui/CancelModal";
import styled from "styled-components";

const BookingsWindowRight = (props) => {
  const [cancelModal, setCancelModal] = useState(false);
  const {
    mappedBookingsData,
    setMappedBookingsData,
    viewReadOnly,
    setViewReadOnly,
    editMode,
    setEditMode,
    setRowActive,
    setLgShow,
    setShowQuoteModal,
    setShowBookingButton,
  } = useContext(BookingsContext);
  const windowClose = () => {
    props.bookingformClose(false);
    setViewReadOnly(false);
    setEditMode(false);
    setMappedBookingsData({});
    setRowActive(false);
    const allTableRows = document.querySelectorAll(".rdt_TableRow");
    allTableRows.forEach((rowElement) => {
      rowElement.classList.remove("current-selected-row");
    });
  };
  const handleEdit = () => {
    setEditMode(true);
    setViewReadOnly(false);
    setShowBookingButton(true);
  };
  const cancelBooking = (e) => {
    e.preventDefault();
    setCancelModal(true);
  };

  const quoteClicks = () => {
    setLgShow(true);
    setShowQuoteModal(true);
    setShowBookingButton(false);
  };

  return (
    <div className="content-window-right">
      <div className="content-right-block">
        <div className="card-right">
          <div className="header-block">
            <div className="header-sec">
              <div className="content-right-top">
                <button
                  onClick={windowClose}
                  className="btn btn-action btn-back"
                  rel="tooltip"
                  data-for="backAction"
                  data-tip="Back to Bookings"
                  data-toggle="tooltip"
                  data-placement="top"
                >
                  <i className="fal fa-long-arrow-left mr-2"></i>
                  {editMode || viewReadOnly
                    ? ` ${mappedBookingsData?.customer?.customer_name}`
                    : "Add Booking"}
                </button>

                <ReactTooltip
                  id="backAction"
                  place="bottom"
                  type="info"
                  effect="float"
                />
                {/* <h4>
                  {editMode
                    ? `Edit ${mappedBookingsData.customer.customer_name}`
                    : viewReadOnly
                    ? `View ${mappedBookingsData.customer.customer_name}`
                    : "Add Bookings"}
                </h4> */}

                {/* <button onClick={handleEdit}>edit</button> */}
              </div>

              {viewReadOnly && (
                <div className="action-block-right">
                  {mappedBookingsData.orderStatus != null &&
                  mappedBookingsData.orderStatus.title !== "Cancelled" &&
                  !mappedBookingsData?.orderCancel ? (
                    <button
                      className="btn btn-action btn-cancel"
                      rel="tooltip"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Quotes"
                      onClick={quoteClicks}
                      style={{
                        color: "#4036f4",
                        background: "#e9e7fd",
                        border: "solid 1px #fff3f2",
                        width: "112px",
                      }}
                    >
                      Quote{" "}
                    </button>
                  ) : (
                    ""
                  )}
                  {mappedBookingsData.orderStatus != null &&
                  mappedBookingsData.orderStatus.title !== "Cancelled" &&
                  !mappedBookingsData?.orderCancel ? (
                    <button
                      className="btn btn-action btn-cancel"
                      rel="tooltip"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Delete"
                      onClick={cancelBooking}
                      style={{
                        color: "#f44336",
                        background: "#fff3f2",
                        border: "solid 1px #fff3f2",
                      }}
                    >
                      Cancel Booking{" "}
                    </button>
                  ) : (
                    ""
                  )}
                  {mappedBookingsData.orderStatus != null &&
                  mappedBookingsData.orderStatus.title !== "Cancelled" &&
                  !mappedBookingsData?.orderCancel ? (
                    <button
                      onClick={handleEdit}
                      className="btn btn-action btn-edit"
                      style={{
                        color: "#2e7d32",
                        background: "#f1ffe0",
                        border: "1px solid #f1ffe0",
                      }}
                      rel="tooltip"
                      data-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-original-title="Edit"
                      aria-describedby="tooltip55424"
                    >
                      <i className="fal fa-pen"> </i>
                    </button>
                  ) : (
                    ""
                  )}
                  {mappedBookingsData?.orderCancel && (
                    <TagSpan>{mappedBookingsData?.orderStatus?.title}</TagSpan>
                  )}
                </div>
              )}
              {/* {viewReadOnly && <h1>{cancellded != undefined ? cancellded : "Salman"}</h1>} */}
            </div>
          </div>
        </div>
        <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
          <div className="name-block">
            <div className="sub-block">
              <div className="block-name">
                {/* {mappedServiceData.name && <h2>{mappedServiceData.name}</h2>} */}
              </div>
            </div>
          </div>
          <div className="row-right">
            <BookingsForm
              bookingformClose={props.bookingformClose}
              SetAddBookings={props.handleAddBookings}
              addBookings={props.addBookings}
            />
            {!props.handleAddBookings && (
              <div className="col-md-6">
                <div className="col-12 inner-header p-0 mb-5">
                  <div className="block-header main-title p-0">
                    <h6>
                      <span className="header-title">Activity History</span>
                    </h6>
                  </div>
                </div>
                <ul className="list-group track-ul">
                  <li className="active booked">
                    <div className="card card-box">
                      <div className="card-body">
                        <div className="info mb-3">
                          <h5 className="card-title mb-0">
                            Special Title Treatment
                          </h5>
                          <div className="date">
                            <span className="">03/08/22</span>
                            <span className="">01:00 PM</span>
                          </div>
                        </div>
                        <p className="card-text">
                          With supporting text below as a natural lead-in to
                          additional content.
                        </p>
                        <div className="track-img-block d-flex">
                          <div className="track-img-sub">
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/logo.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="card-action">
                            <button className="btn">
                              <i className="fal fa-check"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="active car">
                    <div className="card card-box">
                      <div className="card-body">
                        <div className="info mb-3">
                          <h5 className="card-title mb-0">
                            Special Title Treatment
                          </h5>
                          <div className="date">
                            <span className="">03/08/22</span>
                            <span className="">01:00 PM</span>
                          </div>
                        </div>
                        <p className="card-text">
                          With supporting text below as a natural lead-in to
                          additional content.
                        </p>
                        <div className="track-img-block d-flex">
                          <div className="track-img-sub">
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/logo.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="card-action">
                            <button className="btn">
                              <i className="fal fa-check"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="active booked">
                    <div className="card card-box">
                      <div className="card-body">
                        <div className="info mb-3">
                          <h5 className="card-title mb-0">
                            Special Title Treatment
                          </h5>
                          <div className="date">
                            <span className="">03/08/22</span>
                            <span className="">01:00 PM</span>
                          </div>
                        </div>
                        <p className="card-text">
                          With supporting text below as a natural lead-in to
                          additional content.
                        </p>
                        <div className="track-img-block d-flex">
                          <div className="track-img-sub">
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/logo.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="card-action">
                            <button className="btn">
                              <i className="fal fa-check"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="active car">
                    <div className="card card-box">
                      <div className="card-body">
                        <div className="info mb-3">
                          <h5 className="card-title mb-0">
                            Special Title Treatment
                          </h5>
                          <div className="date">
                            <span className="">03/08/22</span>
                            <span className="">01:00 PM</span>
                          </div>
                        </div>
                        <p className="card-text">
                          With supporting text below as a natural lead-in to
                          additional content.
                        </p>
                        <div className="track-img-block d-flex">
                          <div className="track-img-sub">
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/logo.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="card-action">
                            <button className="btn">
                              <i className="fal fa-check"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="active booked">
                    <div className="card card-box">
                      <div className="card-body">
                        <div className="info mb-3">
                          <h5 className="card-title mb-0">
                            Special Title Treatment
                          </h5>
                          <div className="date">
                            <span className="">03/08/22</span>
                            <span className="">01:00 PM</span>
                          </div>
                        </div>
                        <p className="card-text">
                          With supporting text below as a natural lead-in to
                          additional content.
                        </p>
                        <div className="track-img-block d-flex">
                          <div className="track-img-sub">
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/logo.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="card-action">
                            <button className="btn">
                              <i className="fal fa-check"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="active car">
                    <div className="card card-box">
                      <div className="card-body">
                        <div className="info mb-3">
                          <h5 className="card-title mb-0">
                            Special Title Treatment
                          </h5>
                          <div className="date">
                            <span className="">03/08/22</span>
                            <span className="">01:00 PM</span>
                          </div>
                        </div>
                        <p className="card-text">
                          With supporting text below as a natural lead-in to
                          additional content.
                        </p>
                        <div className="track-img-block d-flex">
                          <div className="track-img-sub">
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/logo.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="card-action">
                            <button className="btn">
                              <i className="fal fa-check"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li className="active booked">
                    <div className="card card-box">
                      <div className="card-body">
                        <div className="info mb-3">
                          <h5 className="card-title mb-0">
                            Special Title Treatment
                          </h5>
                          <div className="date">
                            <span className="">03/08/22</span>
                            <span className="">01:00 PM</span>
                          </div>
                        </div>
                        <p className="card-text">
                          With supporting text below as a natural lead-in to
                          additional content.
                        </p>
                        <div className="track-img-block d-flex">
                          <div className="track-img-sub">
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/logo.png"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <div className="track-img">
                              <img
                                src="../public/assets/img/logo/dummy.webp"
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="card-action">
                            <button className="btn">
                              <i className="fal fa-check"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
      {cancelModal && (
        <CancelModal
          bookingformClose={props.bookingformClose}
          mappedData={mappedBookingsData.customer_name}
          setCancelModal={setCancelModal}
          // setViewReadOnly={true}
        />
      )}
    </div>
  );
};

export default BookingsWindowRight;
const TagSpan = styled.span`
  color: #ff0000 !important;
  background: #ff000014;
  border-radius: 3px;
  padding: 3px 6px !important;
`;
