import React, { Fragment, useContext } from "react";
import { StaffContext } from "../StaffContext";
import { Scrollbars } from "react-custom-scrollbars-2";

const StaffExpenseHistoryTab = () => {
  const {
    staffTable,
    setStaffTable,
    pic,
    setPic,
    mappedStaff,
    setMappedStaff,
    innertab,
    SetinnerTab,
  } = useContext(StaffContext);
  return (
    <div
      className={innertab === 4 ? " show active" : "tab-pane fade"}
      id="nav-work"
      role="tabpanel"
      aria-labelledby="nav-work-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div className="inner-form-block">
          <Fragment>
            {/* <div className="row m-0">
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fal fa-user-tag"></i> Designation
                </span>
              </div>
              <input
                value={e.designation}
                readOnly
                type="text"
                className="form-control"
              />
            </div>
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fal fa-money-bill-alt"></i> Salary
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder=""
                aria-label="Username"
                aria-describedby="basic-addon1"
                value={e.salary}
                readOnly
              />
            </div>
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fal fa-calendar"></i> Date of Joining
                </span>
              </div>
              <input
                type="text"
                value={e.joindate}
                readOnly
                className="form-control"
              />
            </div>
          </div> */}

            <div className="col-12 p-0">
              <div id="accordion">
                <div className="custom-box">
                  <div
                    className="card-header collapsed"
                    // id="headingThree"
                    // data-toggle="collapse"
                    // data-target="#collapseThree"
                    // aria-expanded="false"
                    // aria-controls="collapseThree"
                  >
                    <h6 className="mb-0">Expense History</h6>
                  </div>
                  <div
                  // id="collapseThree"
                  // className="collapse"
                  // aria-labelledby="headingThree"
                  // data-parent="#accordion"
                  >
                    <div className="card-body p-0">
                      <div className="col-12 inner-content-table-block p-0">
                        <div className="content-table table-responsive">
                          <table className="table bg-white custom-table inner-tbl">
                            <thead>
                              <tr>
                                <th scope="col" className="text-left">
                                  Date
                                </th>
                                <th scope="col">Tansaction ID</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Type of expense</th>
                                <th scope="col">Remarks</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>04/03/2022</td>
                                <td>3423767867</td>
                                <td>₹ 10000 </td>
                                <td>Salary</td>
                                <td>Verified</td>
                              </tr>
                              <tr>
                                <td>04/03/2022</td>
                                <td>3423767867</td>
                                <td>₹ 10000 </td>
                                <td>Salary</td>
                                <td>Verified</td>
                              </tr>
                              <tr>
                                <td>04/03/2022</td>
                                <td>3423767867</td>
                                <td>₹ 10000 </td>
                                <td>Salary</td>
                                <td>Verified</td>
                              </tr>
                              <tr>
                                <td>04/03/2022</td>
                                <td>3423767867</td>
                                <td>₹ 10000 </td>
                                <td>Salary</td>
                                <td>Verified</td>
                              </tr>
                              <tr>
                                <td>04/03/2022</td>
                                <td>3423767867</td>
                                <td>₹ 10000 </td>
                                <td>Salary</td>
                                <td>Verified</td>
                              </tr>
                              <tr>
                                <td>04/03/2022</td>
                                <td>3423767867</td>
                                <td>₹ 10000 </td>
                                <td>Salary</td>
                                <td>Verified</td>
                              </tr>
                              <tr>
                                <td>04/03/2022</td>
                                <td>3423767867</td>
                                <td>₹ 10000 </td>
                                <td>Salary</td>
                                <td>Verified</td>
                              </tr>
                              <tr>
                                <td>04/03/2022</td>
                                <td>3423767867</td>
                                <td>₹ 10000 </td>
                                <td>Salary</td>
                                <td>Verified</td>
                              </tr>
                              <tr>
                                <td>04/03/2022</td>
                                <td>3423767867</td>
                                <td>₹ 10000 </td>
                                <td>Salary</td>
                                <td>Verified</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </Scrollbars>
    </div>
  );
};

export default StaffExpenseHistoryTab;
