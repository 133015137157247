import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import ReactTooltip from "react-tooltip-rc";
import styled from "styled-components";

type Props = {
  children?: React.ReactNode;
  childrenRight?: React.ReactNode;
  isExpand?: boolean;
  heading?: string;
  backToolTip?: string;
  handleCloseView?: () => void;
};

const WindowRightLayout = ({
  children,
  isExpand,
  heading,
  backToolTip,
  handleCloseView,
  childrenRight,
}: Props) => {
  return (
    <>
      <Wrapper className={`content-window-right ${isExpand ? "expand" : ""}`}>
        {/* <div className={"content-window-right" + props.text}> */}
        <div className="content-right-block">
          <div className="card-right">
            <div className="header-block">
              <div className="header-sec">
                <div className="content-right-top">
                  <button
                    onClick={handleCloseView}
                    className="btn btn-action btn-back"
                    title="Close Window"
                    data-for="close"
                    data-tip={backToolTip}
                  >
                    <i className="fal fa-long-arrow-left"></i>
                  </button>
                  <ReactTooltip
                    id="close"
                    place="bottom"
                    type="info"
                    effect="float"
                  />
                  <h4>{heading}</h4>
                </div>
                <div className="action-block-right">
                  <ReactTooltip
                    id="delete"
                    place="bottom"
                    type="info"
                    effect="float"
                  />
                  <ReactTooltip
                    id="edit"
                    place="bottom"
                    type="info"
                    effect="float"
                  />
                </div>
                {childrenRight}
              </div>
            </div>
          </div>
          <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
            <div className="row m-0 row-right">{children}</div>
          </Scrollbars>
        </div>
      </Wrapper>
    </>
  );
};

export default WindowRightLayout;
const Wrapper = styled.div`
  &.expand {
    width: calc(100% - 300px);
    padding: 0 20px 0 0;
    transform: scale(1);
    display: block;
  }
`;
