import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { Login, Dashboard, Clients, Vendors, Accounts, Settings} from "./super-admin/pages";

// import Service from './components/Service-admin/Pages/Service'
// import { Login } from "./Service-admin";
import Login from "../src/components/ServiceAdmin/Login/Login";
import Staff from "./components/ServiceAdmin/Pages/Staff/Staff";
import Bookings from "./components/ServiceAdmin/Pages/Bookings/Bookings";
import Orders from "./components/ServiceAdmin/Pages/Order/Orders";
import Customer from "./components/ServiceAdmin/Pages/Customer/Customer";

// import  Vendor  from "./components/Service-admin/Pages/vendors/Vendor";
// import  Bookings  from "./components/Service-admin/Pages/bookings/Bookings";

import Service from "../src/components/ServiceAdmin/Pages/Service/Service";

import "./App.css";
import { ServiceProvider } from "../../client/src/components/ServiceAdmin/Pages/Service/ServiceContext";
import LoginSup from "./components/SuperAdmin/Login/LoginSup";
import Dashboard from "./components/SuperAdmin/Pages/Dashboard/Dashboard";
import SuperUser from "./components/SuperAdmin/Pages/User/SuperUser";
import Approvals from "./components/SuperAdmin/Pages/Approval/Approvals";
import BookingsSup from "./components/SuperAdmin/Pages/Bookings/BookingsSup";
import VendorSup from "./components/SuperAdmin/Pages/vendors/VendorSup";
import AuthGuardSAP from "./components/Routes/Guards/AuthGuardSAP";
import { ToastContainer, toast, Flip, Slide, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthGuardSUP from "./components/Routes/Guards/AuthGuardSUP";
import RedirectIfAuthenticatedGuardSAP from "./components/Routes/Guards/RedirectIfAuthenticatedGuardSAP";
import RedirectIfAuthenticatedGuardSUP from "./components/Routes/Guards/RedirectIfAuthenticatedGuardSUP";
import Variants from "./components/SuperAdmin/Pages/Variants/Variants";
import Profile from "./components/ServiceAdmin/Pages/Profile/Profile";
import VendorRegistration from "./pages/VendorAdmin/registration";
import SapRegistration from "./pages/ServiceAdmin/registration";
import { GlobalStyle } from "./styles/global-styles";
// import Centre from './components/SuperAdmin/Pages/Centre/CenterForm'
// import VendorLandingPage from "./pages/VendorAdmin/pages/LandingPage";
// import VendorRegistration from "./pages/VendorAdmin/pages/registration";
import Products from "./components/ServiceAdmin/Pages/Products/Products";
import SupFeaturePage from "./components/SuperAdmin/Pages/Featured";
import SubscribtionBanner from "./components/SuperAdmin/Pages/SubscribtionBanner";
import SapOfferPage from "./components/ServiceAdmin/Pages/Offers";
import SupCouponsPage  from "./components/SuperAdmin/Pages/coupons/SupCouponsPage";
import SapFeaturePage from "./components/ServiceAdmin/Pages/Featured";
import SupOfferPage from "./components/SuperAdmin/Pages/Offers";
function App() {
  return (
    <div className="App theme-light">
      {/* <Login /> */}
      {/* <Dashboard/> */}
      <Router>
        <Routes>
          <Route element={<RedirectIfAuthenticatedGuardSUP />}>
            <Route path="/sup" element={<LoginSup />} />
          </Route>
          <Route element={<AuthGuardSUP />}>
            <Route path="/sup/dashboard" element={<Dashboard />} />
            <Route path="/sup/user" element={<SuperUser />} />
            <Route path="/sup/services" element={<Approvals />} />
            <Route path="/sup/variants" element={<Variants />} />
            <Route path="/sup/bookings" element={<BookingsSup />} />
            <Route path="/sup/vendors" element={<VendorSup />} />

            <Route path="/sup/app/featured" element={<SupFeaturePage />} />

            <Route
              path="/sup/app/offer-management"
              element={<SupOfferPage />}
            />
            <Route path="/sup/app/coupons" element={<SupCouponsPage />} />
            <Route
              path="/sup/app/subscriptions"
              element={<SubscribtionBanner />}
            />
            {/* <Route path="/sup/Centre"  element={<Centre/>}/> */}
          </Route>

          <Route element={<RedirectIfAuthenticatedGuardSAP />}>
            <Route path="/" element={<Login />} />
          </Route>
          <Route element={<AuthGuardSAP />}>
            <Route path="/service" element={<Service />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/staff" element={<Staff />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/customer" element={<Customer />} />
            <Route path="/product" element={<Products />} />
            <Route path="/app/offers" element={<SapOfferPage />} />
            <Route path="/app/featured" element={<SapFeaturePage />} />
          </Route>
          {/* <Route path="/vendor-registration" element={<VendorLandingPage />} /> */}
          <Route path="/vendor-registration" element={<VendorRegistration />} />
          <Route path="/service-center-onboard" element={<SapRegistration />} />
        </Routes>
      </Router>
      <ToastContainer draggable={false} transition={Slide} autoClose={1900} />
      <GlobalStyle />
    </div>
  );
}

export default App;
