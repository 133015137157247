import React, { useState, useContext, useEffect } from "react";
import DataTable from "react-data-table-component";
import dateFormat, { masks } from "dateformat";
import { OrdersContext } from "./OrdersContext";
import OrderDeleteModal from "./Ui/OrderDeleteModal";
import Kanban from "./Kanban/Kanban";
import {
  getDataByVehicleNumber,
  vehicleNumberSuggestion,
  serviceListByVehicleType,
  getVehicleModel,
  getManufacturerLIst,
  getBookingsList,
  createOrUpdateBooking,
  viewMappedBooking
} from "../../../../api/services/sap/bookings-management";
import styled from "styled-components";

const OrderWindowLeft = () => {
  const {
    deleteId,
    setDeleteId,
    orderTable,
    tableKanban,
    setTableKanban,
    filterValue,
    setFilterValue,
    viewHandler
  } = useContext(OrdersContext);

  const [deleteModal, setDeleteModal] = useState(false);
  const [showBackButton, setShowBackButton] = useState(false);
  const [showDeliveryList, setShowDeliveryList] = useState(false)

  const columns = [
    {
      name: "No",
      selector: (row, index) => index+1,
      sortable: true,
    },
    {
      name: "Customer Name",
      selector: (row) => row.customer.customer_name,
      sortable: true,
      cell: (row) => (
        <div>
          {row.customer.customer_name}
        </div>
      ),
      allowOverflow: false,
    },
    {
      name: "Vehicle Number",
      selector: (row) => row.customerVehicle.vehicle_registration_number,
      sortable: true,
    },
    {
      name: "Vehicle",
      selector: (row) => row.customerVehicle.vehicleModel.vehicleManufacture.manufacturer,
      sortable: true,
      cell: (row) => (
        <div className="text-left" data-tag="allowRowEvents">
          <span className="pl-1 pr-1">{row.customerVehicle.vehicleModel.vehicleManufacture.manufacturer}</span>
          <span className="pl-1 pr-1">{row.customerVehicle.vehicleModel.model}</span>
          <span className="pl-1 pr-1">({row.customerVehicle.color})</span>
        </div>
      ),
    },
    // {
    //   name: "Vehicle Model",
    //   selector: (row) => row.customerVehicle.vehicle.model,
    //   sortable: true,
    // },
    // {
    //   name: "Vehicle Color",
    //   selector: (row) => row.customerVehicle.color,
    //   sortable: true,
    // },
    {
      name: "Services Ordered",
      selector: (row) => row.orderServices,
      sortable: true,
      cell: (row) => (
        <div className="text-left">
          {row.orderServices.map((item) => {
            let x = item.service.service_name + ',';
            return <span className="ordered">{item.service.service_name}<span className="coma">, </span></span>;
          })}
        </div>
      ),
      allowOverflow: false,
    },
    {
      name: "Order Status",
      selector: (row) => row.orderStatus.title,
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (row) => row.order_completed,
      cell: (row) => (
        <div className="text-left">
          {row.balance_amount == row.grand_total ? (
            <span>Not Paid</span>
          ) : row.balance_amount == 0 ? (
            <span>Payment Completed</span>
          ) : (
            <span>Partially Paid</span>
          )}
        </div>
      ),
      sortable: true,
    },    
  ];

  //PAGINATION OPTIONS
  const paginationComponentOptions = {
    rowsPerPageText: false,
    rangeSeparatorText: "of",
    selectAllRowsItem: false,
    selectAllRowsItemText: "Todos",
    noRowsPerPage: true,
  };
  //Datatable sort icon
  const sortIcon = <i class="fal fa-sort"></i>;
  //custom datatable styles
  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  const handleDeleteModal = (e, rowid) => {
    setDeleteModal(true);
    setDeleteId(rowid);
    e.stopPropagation();
  };
  const handleDeleteCancel = () => {
    setDeleteModal(false);
  };
  
 const deliveredData = orderTable && orderTable.filter(
    (tValue) => tValue.orderStatus.title === "Delivered"
  );

  const deliveredHandler = () => {
    setFilterValue(deliveredData);
    setTableKanban(false);
    setShowDeliveryList(true);
    setShowBackButton(true);
  };

  const fullTableHandler = () => {
    setTableKanban(false);
    setFilterValue(orderTable);
    setShowBackButton(false);
    setShowDeliveryList(false);
  };
const kanbanView = () =>{
  setTableKanban(true);
  setShowBackButton(false);
}
  const handleClose = () => {
    setTableKanban(true);
    setShowBackButton(false);
  };

useEffect(() => {
  if(showDeliveryList) {
    setFilterValue(deliveredData)
  }
}, [orderTable, deliveredData]);

  return (
    
    <div className="content-window-left">
      <div className="content-table-block">
        <div className="content-table table-responsive">
          <div className="list-order-block">
            <div className="list-order">
            <div>
            {showBackButton && (
                <button
                  onClick={handleClose}
                  className="btn btn-action btn-back"
                  rel="tooltip"
                  data-for="backAction"
                  data-toggle="tooltip"
                  data-placement="top"
                >
                  <i className="fal fa-long-arrow-left mr-2"></i>
                </button>
            )}
            
              <button className="btn delivered-orders-btn" onClick={deliveredData.length ? deliveredHandler : null}>Delivered Orders({deliveredData.length && deliveredData.length})</button>
            </div>
              <div className="date-block">
                <h4>Today’s Orders</h4>
                <h6>{dateFormat(new Date(), "fullDate")}</h6>
              </div>
              <div className="toggle-kanTable">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={kanbanView}
                  >
                    <i class="fal fa-th"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={fullTableHandler}
                  >
                    <i class="fal fa-list"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          {tableKanban ? (
            <Kanban />
          ) : (
            <WrapperLeft>
            <DataTable
              columns={columns}
              data={filterValue}
              highlightOnHover={true}
              pointerOnHover={true}
              pagination
              paginationComponentOptions={paginationComponentOptions}
              sortIcon={sortIcon}
              customStyles={customStyles}
              onRowClicked={(event) => viewHandler(event.order_id_pk)}
            />
            </WrapperLeft>
          )}
        </div>
        {deleteModal && (
          <OrderDeleteModal
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            handleDeleteCancel={handleDeleteCancel}
            order_id={deleteId}
          />
        )}
      </div>
    </div>
  );
};

export default OrderWindowLeft;
const WrapperLeft = styled.div`
.kjHanM {
  margin: 0px 5px;
}
.DfDUN {
  width:30px;
}
`;
