import React from "react";

function SortDropdownContent() {
  return (
    <div class="dropdown-menu d-block" aria-labelledby="dropdownMenuButton">
      <a class="dropdown-item" href="">
        Name
      </a>
      <a class="dropdown-item" href="">
        Phone Number
      </a>
    </div>
  );
}

export default SortDropdownContent;
