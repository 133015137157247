import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import images from "./assets/img/CERAMIC-ULTRA.png";
import { Container } from "react-bootstrap";
import AddAndMinusButton from "../AddAndMinusButton";
import { SaveButton } from "../SaveButton";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
interface Props {
  item: {
    id: number;
    name: string;
    brand: string;
    stock: string;
    price: number;
    tag: string;
    config?: string;
    currentStock?: number;
  };
}
export default function MuiUpdateStockModal({ item }: Props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <Containerwrapper> */}
      <Button variant="outlined" onClick={handleClickOpen}>
       Update Stock
      </Button>
      <StyledBootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {/* <CloseRoundedIcon className="close-button"
        onClick={handleClose} /> */}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
            
        >
          <CloseIcon />
        </IconButton>
        <StyledCard>
          <div className="custom-product-card">
            <div style={{ display: "flex" }}>
              <CardMedia
                className="custom-image-card"
                component="img"
                image={images}
                alt="Product Image"
              />
            </div>
            <CardContent className="custom-card-content">
              {
                <Typography
                  gutterBottom
                  variant="h5"
                  component="div"
                ></Typography>
              }
              <Typography
                className="custom-card-item-name"
                variant="body2"
                color="text.secondary"
              >
                {item.name}
              </Typography>
              <div className="custom-brand-name">
                <span className="brand-text">
                  {item.brand}
                  {item.config}
                </span>

                {/* <div className="custom-purchase-from">{item.stock}</div> */}
              </div>
              <div className="custom-price-tag">Rs {item.price}</div>
            </CardContent>
          </div>
          <div className="added-text">
            <div className="new-brand-text">
              Current stock :<div>{item.currentStock}</div>
            </div>
            <div className="new-button-text">
              Upadate stock
              <AddAndMinusButton />
            </div>
            <div className="new-brand-text">
              Current stock :<div>{item.currentStock}</div>
            </div>
          </div>
        </StyledCard>
        <div className="save-button">
          <SaveButton />
        </div>
      </StyledBootstrapDialog>
    </div>
  );
}

const StyledBootstrapDialog = styled(BootstrapDialog)`
  .MuiDialog-paperWidthSm {
    max-width: 350px !important;
    min-width: 350px !important;
    align-items: center !important;
    border-radius: 10px;
    border-color: blue;
    border-width: 3px;
    max-height: 400px;
    min-height: 427px;
    justify-content: center;
    /* margin-top: 60px; */
  }
  .save-button {
    margin-top: 20px;
  }
  .main-div {
    border: 2px solid blue;
    padding: 10px;
  }
  .close-button {
    position: absolute;
    right: -10px;
    top: -13px;
    font-size: 20px !important;
    color: #0083ff !important;
    font-weight: lighter;
    border: none;
    width: 25px;
    height: 25px;
    background: #e5f0ff !important ;
    border-radius: 20px !important;
  }
`;

const StyledCard = styled(Card)`
  &.MuiPaper-root {
    max-width: 250px;
    min-width: 250px;
    max-height: 250px;
    min-height: 250px;
    background-color: #e2e0e0;
  }
  //   &.MuiPaper-root-MuiDialog-paper,
  //   &.MuiDialog-paperScrollPaper,
  //   &.MuiDialog-paper {
  //     max-width: 600px !important;
  //     min-width: 400px !important;
  //     align-items: center !important;
  //   }
  .custom-product-card {
    display: flex;
  }
  .added-text {
    margin-top: 10px;
    margin-left: 20px;
  }
  .new-button-text {
    display: flex;
    font-size: 12px;
    margin: 10px;
    gap: 60px;
  }
  .custom-price-tag {
    color: rgb(78, 167, 61, 87%);
    text-align: left;
    font-size: 13px;
    font-weight: 700;
  }

  .custom-card-item-name {
    text-align: left;
    font-weight: 700;
  }

  .custom-image-card {
    /* border: 2px solid #ccc; */
    /* margin: 10px; */
    /* border: 2px dotted #ccc; */
    /* box-sizing: border-box; */
    width: 66px;
    height: 82px;

    /* padding: :10px; */
    /* position: relative; */
  }

  .custom-purchase-from {
    color: rgb(255, 160, 73);
    font-size: 11px;
  }

  .custom-brand-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    margin-top: 5px;
    /* font-size: 10px; */
  }

  .brand-text {
    color: #212020;
    margin-right: 8px;
    font-size: 12px;
  }
  .new-brand-text {
    font-size: 12px;
    margin: 10px;
    display: flex;
    gap: 70px;
  }
  .custom-new-item-tag {
    background-color: #58c558;
    color: white;
    width: 38% !important;
    font-size: 10px !important;
    height: 18px;
    position: absolute !important;
    top: 20px;
    left: 0;
  }
  .custom-left-item-tag {
    background-color: #f80b0b;
    color: white;
    width: 38% !important;
    font-size: 10px !important;
    height: 18px;
    position: absolute !important;
    top: 20px;
    left: 0;
  }
  .custom-card-content {
    padding: 0px;
  }
  .custom-price-tag {
    margin-top: 5px;
  }
  .custom-dot-view {
    /* font-size: 10px; */
    min-width: 33px;
    font-weight: 800;
    color: black;
  }
`;
