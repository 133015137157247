import { supAxios } from "../../axios";

const getFeaturedList = async (params?: number | null) => {
  console.log("paramsss", params);
  const axiosInst = supAxios();
  const url = `/featured/list`;
  try {
    const res = await axiosInst({
      method: "GET",
      url,
    });
    console.log(res);
    if (res.status) {
      return res.data;
    }
    throw new Error(res.statusText);
  } catch (err) {
    console.log(err);
    return err;
  }
};
const getSingleFeature = async (featuredId: number) => {
  const axiosInst = supAxios();
  const url = `/featured?featuredId=${featuredId}`;
  try {
    const res = await axiosInst({
      method: "GET",
      url,
    });
    console.log(res);
    if (res.status) {
      return res.data;
    }
    throw new Error(res.statusText);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const createOrUpdateFeature = async (data: FormData) => {
  const axiosInst = supAxios();
  const url = `/featured`;
  try {
    const res = await axiosInst({
      method: "PUT",
      url,
      data,
    });
    console.log(res);
    if (res.status) {
      return res;
    }
    throw new Error(res.statusText);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const deleteFeature = async (id: number) => {
  const axiosInst = supAxios();
  try {
    const res = await axiosInst({
      method: "DELETE",
      url: `/featured?featuredId=${id}`,
    });
    if (res.status) {
      return res;
    }
    return Promise.reject(res);
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export {
  getFeaturedList,
  getSingleFeature,
  createOrUpdateFeature,
  deleteFeature,
};
