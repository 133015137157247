import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import TickIcon from "./assets/img/tick.svg";
// import { ReactComponent as Added } from './assets/img/icon/added.svg';

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // to handle onClick functions
  children?: React.ReactNode; // make the component able to receive children elements
  buttonType?: "primary" | "secondary" | "icon"; // two styling options (you can create as many as you want)
  disabled?: boolean; // make the button disabled or not
  rounded?: boolean; // make the button disabled or not
  type?: "submit" | "button";
  variant?: "contained" | "outlined" | "text";
  color?: "success" | "warning";
};

const SupRejectButton = ({
  onClick,
  children,
  variant,
  color,
  type,
  rounded,
  disabled,
}: ButtonProps) => {
  return (
    <Wrapper className={`button-wrapper ${rounded ? "rounded" : ""}`}>
      <Button
        onClick={onClick}
        disabled={disabled}
        variant={variant}
        color={color}
        type={type}
      >
        {/* <img src={TickIcon} width={20} /> */}
        <div className="btn-sub">
          <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.645 7.84375L7.85303 12.6357"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.6466 12.6388L7.85059 7.8418"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.584 1H5.915C2.894 1 1 3.139 1 6.166V14.334C1 17.361 2.885 19.5 5.915 19.5H14.583C17.614 19.5 19.5 17.361 19.5 14.334V6.166C19.5 3.139 17.614 1 14.584 1Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          {rounded ? "Rejected" : "Reject"}
        </div>
        <div className="btn-sub">
          {rounded ? (
            " "
          ) : (
            <svg
              width="9"
              height="6"
              viewBox="0 0 9 6"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L4.5 4.5L1 1"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          )}
        </div>
      </Button>
    </Wrapper>
  );
};

export default SupRejectButton;
const Wrapper = styled.div`
  padding: 2px 2px;
  width: fit-content;
  max-width: 100%;
  &.button-wrapper {
    .MuiButtonBase-root {
      font-size: 12px;
      font-weight: 400;
      color: rgb(255 255 255);
      max-height: 36px;
      border: 1px solid #f67979;
      text-transform: uppercase !important;
      letter-spacing: 1px !important;
      background: #f67979 !important;
      min-width: 156px !important;
      max-width: 170px !important;
      border-radius: 5px !important;
      box-shadow: none !important;
      padding: 7px 10px !important;
      line-height: 1;
      align-items: center;
      -webkit-transition: border-radius 0.25s;
      transition: border-radius 0.25s;
      justify-content: space-between;
      /* position: relative;
      left: 20px; */
      .btn-sub {
        svg {
          width: 22px;
          margin: 0 10px 0 0;
          path {
            /* fill: #009970; */
          }
          &:nth-child(2) {
            margin: 0px 10px 0px 10px;
          }
        }
      }

      &:hover {
        background: rgb(223 106 106) !important;
      }
    }
    &.rounded {
      .MuiButtonBase-root {
        background: transparent !important;
        color: #f67979;
        border-radius: 20px !important;
        margin: 0 0px 0 0;
        svg {
          /* margin: 0 0 10px 0; */
          path {
            stroke: rgb(246, 121, 121);
          }
        }
      }
    }
  }

  //Media Query ---->
  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 11px;
    a {
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 12px;
    a {
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 13px;
  }
  @media (max-width: 1365px) {
    &.button-wrapper {
      .MuiButtonBase-root {
        min-width: 132px !important;
        max-width: 132px !important;
        font-size: 11px !important;
        svg {
          width: 16px !important;
        }
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1365px) {
    font-size: 13px;
    a {
    }
  }
  @media (min-width: 1366px) and (max-width: 4000px) {
    font-size: 14px;
  }
`;
