import React, { useState } from "react";
import Layout from "../../Layout/Layout";
import ContentHeader from "../../Layout/ContentHeader";
import ContentTitle from "../User/Ui/SuperUserContentTitle";
import SuperUserWindowLeft from "../User/SuperUserWindowLeft";
import SuperUserWindowRight from "../User/SuperUserWindowRight";
import SuperInnerLoader from "../User/Ui/SuperInnerLoader";
import { SuperProvider } from "./SuperContext";



const SuperUser = () => {
  //Add Service Click
  const [addservice, Setaddservice] = useState(false);
  const handleAddService = () => {
    Setaddservice(true);
  }
  let toggleClassCheck = addservice ? " change" : "";

  let dummy = addservice ? " add" : " ";
  let dumy = addservice ? " view" : " ";
  return (
    <Layout>
      <div className="content-block">
        <SuperInnerLoader />
        <section class="main-section">
          <div class="container-fluid p-0">
            <ContentHeader />
            <div class="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-services"
                role="tabpanel"
                aria-labelledby="nav-home-tab"
              >
                <div class="col-12 p-0">
                  <SuperProvider>
                  <ContentTitle handleAdd={handleAddService} />
                    <div className="content-section" view-content="service">
                      {/* <div className={"content-window" + toggleClassCheck   }> */}
                      <div className={`content-window ${toggleClassCheck} `}>
                        <SuperUserWindowLeft serviceformClose ={Setaddservice} />
                        {addservice && (
                          <SuperUserWindowRight addservice={addservice}
                            text={dummy} test ={dumy}
                            serviceformClose ={Setaddservice}
                          />
                        )}
                      </div>
                    </div>
                    </SuperProvider>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default SuperUser;