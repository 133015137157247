import React, { useState, useContext } from "react";
// import FileBase64 from 'react-file-base64';
import { Button, Modal, Nav, Container, Row, Col } from "react-bootstrap";
import { BookingsContext } from "./BookingsContext";

const BookingsFileUploadModal = (props) => {
  const { pic, setPic } = useContext(BookingsContext);
  const [ spic, ssetPic ] = useState([]);
  const ImageChange = (e) => {
    //Get Files
    const selectedFiles = e.target.files;
    //Form an  Array
    const fileArray = Array.from(selectedFiles);
    // console.log(FileBase64.encode(URL.createObjectURL(fileArray)));
    //Array of URL's for Individual files
    // const imgArray = fileArray.map((file)=> {
    //   return (
    //     FileBase64.encode(URL.createObjectURL(file))
    //     )
    //   })
      // console.log(FileBase64("00000"));
    //Setting the State
    setPic(fileArray);
  };
  // console.log(pic);
  const ImageSubmit = (e) => {
    props.setShow(false);
    e.preventDefault();
  };

  return (
    <Modal
      className="file-modal"
      show={props.showfilemodal}
      onHide={props.hidefilemodal}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Upload Service images
        </Modal.Title>
      </Modal.Header>
      <div className="upload-modal-nav">
        <Nav variant="tabs" defaultActiveKey="#home">
          {/* <Nav.Item>
            <Nav.Link eventKey="#homes">Guidelines</Nav.Link>
          </Nav.Item> */}
          <Nav.Item className="modal-nav-item">
            <Nav.Link eventKey="#home" className="active">
              Upload Photos
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </div>
      <Modal.Body>
        <Container className="p-1">
          <Row className="w-100">
            <Col xs={6}>
            {/* <FileBase64
        multiple={ true }
        onDone={ssetPic} /> */}
              <div className="form-group">
                <div className="custom-file">
                  <input
                    onChange={ImageChange}
                    type="file"
                    multiple
                    accept="image/jpeg, image/png, image/webp"
                    className="custom-file-input form-control-sm"
                    id="imageUpload"
                  />
                  <label
                    className="custom-file-label col-form-label-sm"
                    for="imageUpload"
                  >
                    <div className="text-center">
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 700 700"
                        style={{enableBackground: "new 0 0 700 700", width: "55px"}}
                      >
                        <path d="M555.3,248.4L401.6,94.9c-1.6-1.6-3.9-2.6-6.2-2.6H238.1c-54.1,0-98,44-98,98v318.2c0,54.1,44,98,98,98h221.7 c54.1,0,98-44,98-98v-254C557.9,252.3,557,250.1,555.3,248.4L555.3,248.4z M404.1,122.2L528,245.9h-84.3 c-21.8,0-39.6-17.7-39.6-39.4L404.1,122.2z M459.9,589.2H238.1c-44.4,0-80.5-36.1-80.5-80.5V190.4c0-44.4,36.1-80.5,80.5-80.5h148.5 v96.7c0,31.4,25.6,56.9,57.1,56.9h96.7v245.2C540.4,553,504.3,589.2,459.9,589.2L459.9,589.2z"
                        />
                        <path d="M282,328.4H416c4.8,0,8.8-3.9,8.8-8.8s-3.9-8.8-8.8-8.8H282c-4.8,0-8.8,3.9-8.8,8.8C273.3,324.4,277.2,328.4,282,328.4 L282,328.4z"
                        />
                        <path d="M355.2,347.2c-1.6-1.6-3.9-2.6-6.2-2.6c-2.3,0-4.6,0.9-6.2,2.6l-68.2,68.5c-3.4,3.4-3.4,9,0,12.4c1.7,1.7,3.9,2.6,6.2,2.6 c2.2,0,4.5-0.9,6.2-2.6l53.3-53.5v136.1c0,4.8,3.9,8.8,8.8,8.8s8.8-3.9,8.8-8.8V374.5L411,428c3.4,3.4,8.9,3.4,12.4,0 c3.4-3.4,3.4-9,0-12.4L355.2,347.2z"
                        />
                      </svg>
                      <i className="fal fa-arrow-up-to-line"></i>
                      <h5>
                        <span>Click to upload</span> or drag and drop
                      </h5>
                      <h6>Maximum file size 10 MB.</h6>
                    </div>
                  </label>
                </div>
              </div>

              <Row>
                <div className="col-12">
                  {pic &&
                    pic.map((photo, index) => {
                      // console.log(pic);
                      return (
                        <div className="img-list" key={photo}>
                          <img
                            src={URL.createObjectURL(photo)}
                            className="img-fluid"
                            alt=""
                            height="100"
                            width="100"
                          />
                          <div className="img-list-block">
                            <div className="image-details">
                              <p>{photo.name}</p>
                              <h6>{photo.type}</h6>
                            </div>
                            <button
                              className="btn"
                              onClick={() =>
                                setPic(pic.filter((e) => e !== photo))
                              }
                            >
                              <i className="fal fa-trash"></i>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </Row>
            </Col>
            <Col xs={6}>
              <Row className="guidelines-info w-100">
                <Col md={4}>
                  <h5>Dimensions: </h5>
                  <p>1024 x 768px</p>
                </Col>
                <Col md={4}>
                  <h5>Format:</h5>
                  <p>PNG or JPG</p>
                </Col>
                <Col md={4}>
                  <h5>File Size:</h5>
                  <p>Below 10 MB</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.hidefilemodal}>
          Close
        </Button>
        <Button variant="primary" onClick={ImageSubmit}>
          Continue
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BookingsFileUploadModal;
