import { commonAxios } from "../../axios";

const getLookupDataAPI = async (data) => {
  const axiosInst = commonAxios();
  try {
    const res = await axiosInst({
      method: "POST",
      url: "get-lookup-data",
      data,
    });
    if (res.status) {
      return res;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};

const getVehicleVariantsAPI = async (data) => {
  const axiosInst = commonAxios();
  try {
    const res = await axiosInst({
      method: "POST",
      url: "get-vehicle-variants",
      data,
    });
    if (res.status) {
      return res;
    }
    return Promise.reject(res);
  } catch (err) {
    return Promise.reject(err);
  }
};
const getCountryList = async (data) => {
  const axiosInst = commonAxios();
  let regionQuery = '';
    if (data?.region == true ) {
        regionQuery = "?region=true";
    }
  //   console.log("yyyy", region);
  try {
    let url = "/get-country-list" + regionQuery;
    const res = await axiosInst({
      method: "GET",
      url: url,
      data,
    });

    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {
    console.error(err);
  }
};
const getCurrencyList = async (data) => {
  const axiosInst = commonAxios();
  try {
    const res = await axiosInst({
      method: "GET",
      url: `/get-currency-list`,
      data,
    });
    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};
const getPhoneEextensions = async (data) => {
  const axiosInst = commonAxios();
  try {
    const res = await axiosInst({
      method: "GET",
      url: `/get-phone-extensions`,
      data,
    });
    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

const getCountryRegion = async (data) => {
  const axiosInst = commonAxios();
  try {
    const res = await axiosInst({
      method: "GET",
      url: `/get-country-list?region=true`,
      data,
    });
    if (res.status) {
      return res.data;
    }
    return Promise.reject(res);
  } catch (err) {}
};

export {
  getLookupDataAPI,
  getVehicleVariantsAPI,
  getCountryList,
  getCurrencyList,
  getPhoneEextensions,
  getCountryRegion,
};
