import React, { useContext } from "react";
import { ServiceContext } from "../ServiceContext";
import "./css/ServiceModal.css";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import deleteFile from "../../../../../assets/img/icon/upload/recycle-bin.svg";
import styled from "styled-components";
function ServiceModal(props) {
  const {
    deleteServiceAction,
    table,
    SetTable,
    mappedServiceData,
    SetMappedServiceData,
    SetViewReadOnly,
    handleServiceDelete,
    viewservice,
    SetViewService,
  } = useContext(ServiceContext);

  const deleteConfirm = (e) => {
    const onSuccess = () => {
      toast.success("Deleted Successfully", {
        className: "error-toast",
        position: "bottom-right",
      });
      props.SetDeleteModal(false);
      if (props.SetIsDeleted) {
        props.SetIsDeleted(false);
          props.serviceFormClose(false);
      }
    };

    const onFailure = () => {
      toast.error("Request Failed", {
        className: "error-toast",
        position: "bottom-right",
      });
    };
    deleteServiceAction(
      props.id ? props.id : mappedServiceData.service_id,
      onSuccess,
      onFailure
    );
   
    // alert("hai");
  };
  console.log(props.test, "props.test");
  console.log(mappedServiceData.service_id, "vehTypId");
  return (
    <Wrapper>
      <Modal
        className="custom-modal"
        show={props.SetDeleteModal}
        onHide={() => props.SetDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>WARNING ....</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="modal-info-img">
            <img src={deleteFile} className="delete-file" alt="" />
          </div>
          <DeleteNamewrapper>
            <h5 className="delete-name-wrapper">
              <span
                style={{
                  whiteSpace: "nowrap", // Prevent line breaks
                  width: "450px", // Set width
                  overflow: "hidden", // Hide overflow content
                  textOverflow: "ellipsis", // Display ellipsis for overflow
                  textAlign: "center",             // Align text to the left
                  display: "inline-block", // Ensure block-level styling for width to take effect
                  alignItems: "center",
                }}
              >
                Are you sure you want to delete <span className="names">"{props.name !== undefined ? props.name : ""}" ?</span>
              </span>{" "}
            
              
            </h5>
          </DeleteNamewrapper>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center pt-3">
            <button
              className="btn mr-3 btn-cancel"
              onClick={() => props.SetDeleteModal(false)}
            >
              Cancel
            </button>
            <button className="btn mr-3 btn-danger" onClick={deleteConfirm}>
              Continue
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
}

export default ServiceModal;
const Wrapper = styled.div `
.sc-nZgfj heZLUo{
  text-align: center;
  padding: 0 30px;
}

`
const DeleteNamewrapper = styled.div`
  .delete-name-wrapper {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    text-align: left !important;
  }
  .names{
    position: relative;
overflow-wrap: break-word;
white-space: pre-wrap;
  }
`;
