// import {ContentHeader,FilterWrapper,ContentTitle,WindowLeft,WindowRight,InnerLoader} from './Ui';
import Layout from "../../Layout/Layout";
import BookingsContentHeader from "./Ui/BookingsContentHeader";
import BookingsContentTitle from "./Ui/BookingsContentTitle";
import BookingsWindowLeft from "./BookingsWindowLeft";
import BookingsWindowRight from "./BookingsWindowRight";
import BookingsInnerLoader from "./Ui/BookingsInnerLoader";
import React, { useState } from "react";
import { BookingsProvider } from "./BookingsContext";

const Bookings = () => {
  const [addBookings, SetAddBookings] = useState(false);
  const handleAddBookings = () => SetAddBookings(!addBookings);
  let toggleClassCheck = addBookings ? " change" : "";

  return (
    <Layout>
      <BookingsProvider>
        <div className="content-block">
          <BookingsInnerLoader />
          <section className="main-section">
            <div className="container-fluid p-0">
              <BookingsContentHeader />
              <div className="col-12 p-0 page-window">
                <BookingsContentTitle handleAddBookings={handleAddBookings} />
                <div className="content-section" view-content="service">
                  <div className={`content-window ${toggleClassCheck} `}>
                    <BookingsWindowLeft
                      handleAddBookings={handleAddBookings}
                      bookingformClose={SetAddBookings}
                    />
                    {addBookings && (
                      <BookingsWindowRight
                        bookingformClose={SetAddBookings}
                        addBookings={addBookings}
                        handleAddBookings={handleAddBookings}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </BookingsProvider>
    </Layout>
  );
};

export default Bookings;
