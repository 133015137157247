import React, { useContext } from "react";
import { CustomerContext } from "./CustomerContext";
import { useState } from "react";
import {
  getCustomerList,
  getEditCustomer,
} from "../../../../api/services/sap/customer-management";
import styled from "styled-components";
import { usePhoneExtension } from "../../../hooks/usePhoneExtension";
import Select from "react-select";

const PersonalDetails = () => {
  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [emailError, setEmailError] = useState("");

  const {
    innertab,
    mappedOrderData,
    editMode,
    setEditMode,
    setMappedOrderData,
    setOrderTable,
  } = useContext(CustomerContext);

  const phoneExtension = usePhoneExtension();
  const editCustomer = () => {
    setEditMode(true);
  };

  const handleCustomerSubmit = (e) => {
    e.preventDefault();
    if (!mappedOrderData?.customer_name?.trim()) {
      setNameError("Name is required");
      return;
    } else {
      setNameError("");
    }

    if (!mappedOrderData?.phone_number?.trim()) {
      setPhoneError("Contact number is required");
      return;
    } else if (!/^\d+$/.test(mappedOrderData?.phone_number?.trim())) {
      setPhoneError("Contact number  should only contain numbers");
      return;
    } else if (mappedOrderData.phone_number?.trim().length !== 10) {
      setPhoneError("Contact number  should be 10 digits");
      return;
    } else {
      setPhoneError("");
    }

    if (
      mappedOrderData?.email?.trim() &&
      !isValidEmail(mappedOrderData?.email?.trim())
    ) {
      setEmailError("Enter a valid email");
      return;
    } else {
      setEmailError("");
    }
    setEditMode(false);
    const data = getEditCustomer(mappedOrderData);
    data.then((res) => {
      const data = getCustomerList();
      data.then((res) => {
        let customerList = res.data.map((item) => {
          return item;
        });
        setOrderTable(customerList);
      });
    });
  };

  const handleNameChange = (e) => {
    setMappedOrderData({
      ...mappedOrderData,
      customer_name: e.target.value,
    });
    setNameError("");
  };

  const handlePhoneChange = (e) => {
    const phoneValue = e.target.value;
    if (/^\d+$/.test(phoneValue) || phoneValue === "") {
      setMappedOrderData({
        ...mappedOrderData,
        phone_number: phoneValue,
      });
    }
    setPhoneError("");
  };

  const handleTRnumber = (e) => {
    setMappedOrderData({
      ...mappedOrderData,
      tr_number: e.target.value,
    });
  };

  const handleEmailChange = (e) => {
    setMappedOrderData({
      ...mappedOrderData,
      email: e.target.value,
    });
    setEmailError("");
  };

  const handleNameBlur = () => {
    if (!mappedOrderData.customer_name.trim()) {
      setNameError("Name is required");
    }
  };

  const handlePhoneBlur = () => {
    if (!mappedOrderData.phone_number.trim()) {
      setPhoneError("Contact number  is required");
    } else if (!/^\d+$/.test(mappedOrderData.phone_number.trim())) {
      setPhoneError("Contact number should only contain numbers");
    } else if (mappedOrderData.phone_number.trim().length !== 10) {
      setPhoneError("Contact number should be 10 digits");
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div
      className={innertab === 1 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <ContentWrapper className="content-wrapper">
        <div
          className="inner-form-block customer-inner-form-block"
          style={{ position: "relative" }}
        >
          <div className="block-form">
            <div className="edit-customer-block">
              {editMode ? (
                ""
              ) : (
                <button type="button" className="btn" onClick={editCustomer}>
                  <span>
                    <i className="fal fa-pen"></i>
                  </span>
                </button>
              )}
            </div>
            <div className="row m-0">
              <form onSubmit={handleCustomerSubmit}>
                <DivWrapper className="custom-styles input-group input-group-custom">
                  <div className="inside-div">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fal fa-user"></i>Name
                      </span>
                    </div>
                    <input
                      type="text"
                      onChange={handleNameChange}
                      onBlur={handleNameBlur}
                      className="form-control"
                      placeholder=""
                      aria-label="Name"
                      aria-describedby="basic-addon1"
                      value={mappedOrderData.customer_name}
                      readOnly={editMode ? false : true}
                      maxLength={100}
                    />
                  </div>
                  {nameError && (
                    <div className="error-message">{nameError}</div>
                  )}
                </DivWrapper>
                <DivWrapper className="custom-styles input-group input-group-custom">
                  <div className="inside-div">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fal fa-phone"></i>Contact Number
                      </span>
                    </div>
                    <PhoneWrapper className="phone-wrapper" editMode={editMode}>
                      <Select
                        className={`phone-extension ${
                          editMode ? "" : "view-mode"
                        }`}
                        options={phoneExtension}
                        classNamePrefix={'phone-extension'}
                        value={
                          mappedOrderData.phone_extension
                            ? {
                                label:
                                  phoneExtension.find(
                                    (option) =>
                                      option.value ===
                                      mappedOrderData.phone_extension
                                  )?.value ?? "",
                                value:
                                  phoneExtension.find(
                                    (option) =>
                                      option.value ===
                                      mappedOrderData.phone_extension
                                  )?.value ?? "",
                              }
                            : phoneExtension.find(
                                (option) =>
                                  option.value ===
                                  mappedOrderData.phone_extension
                              )
                        }
                        onChange={(selectedOption) => {
                          setMappedOrderData({
                            ...mappedOrderData,
                            phone_extension: selectedOption.value,
                          });
                        }}
                        isDisabled={editMode ? false : true}
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        aria-label="Phone Number"
                        aria-describedby="basic-addon1"
                        value={mappedOrderData.phone_number}
                        onChange={handlePhoneChange}
                        onBlur={handlePhoneBlur}
                        readOnly={editMode ? false : true}
                        maxLength={10}
                      />
                    </PhoneWrapper>
                  </div>
                  {phoneError && (
                    <div className="error-message">{phoneError}</div>
                  )}
                </DivWrapper>
                <DivWrapper className="custom-styles input-group input-group-custom">
                  <div className="inside-div">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fal fa-envelope"></i>Email
                      </span>
                    </div>

                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      aria-label="Email"
                      aria-describedby="basic-addon1"
                      value={mappedOrderData.email}
                      onChange={handleEmailChange}
                      readOnly={editMode ? false : true}
                    />
                  </div>
                  {emailError && (
                    <div className="error-message">{emailError}</div>
                  )}
                </DivWrapper>

                <DivWrapper className="custom-styles input-group input-group-custom">
                  <div className="inside-div">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fal fa-map-marker-alt"></i>Address
                      </span>
                    </div>
                    <textarea
                      className="form-control"
                      value={mappedOrderData.customer_address}
                      readOnly={editMode ? false : true}
                      onChange={(e) =>
                        setMappedOrderData({
                          ...mappedOrderData,
                          customer_address: e.target.value,
                        })
                      }
                      maxLength={250}
                    ></textarea>
                  </div>
                </DivWrapper>
                <DivWrapper className="custom-styles input-group input-group-custom">
                  <div className="inside-div">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fal fa-user"></i>TR number
                      </span>
                    </div>
                    <input
                      type="text"
                      onChange={handleTRnumber}
                      // onBlur={handleNameBlur}
                      className="form-control"
                      placeholder=""
                      aria-label="Name"
                      aria-describedby="basic-addon1"
                      // value={trNumber}
                      value={mappedOrderData.tr_number}
                      readOnly={editMode ? false : true}
                      maxLength={15}
                    />
                  </div>
                </DivWrapper>
                {editMode && (
                  <button className="btn btn-update" type="submit">
                    Submit
                  </button>
                )}
              </form>
            </div>
          </div>
        </div>
      </ContentWrapper>
    </div>
  );
};

export default PersonalDetails;

const ContentWrapper = styled.div`
  .block-form {
    padding: 50px 25px;
  }
  .edit-customer-block {
    top: 25px;
  }
  .error-message {
    color: red;
    font-size: 12px;
    display: flex;
    padding-left: 160px;
  }
  .inside-div {
    .css-1xc3v61-indicatorContainer {
      color: #666666 !important;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #e8e8e8 inset !important;
  }
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #e8e8e8 inset !important;
  }
`;
const DivWrapper = styled.div`
  .inside-div {
    display: flex;
    width: 100%;
  }
  .input-group {
    display: block !important;
  }
`;
const PhoneWrapper = styled.div`
  display: flex;
  padding: 0 0 0 10px;
  width: 100%;
  .phone-extension {
    max-width: 100px;
    margin-left: -13px;
  }
  .phone-extension {
    > div:nth-of-type(2) {
      min-width: calc(100% + 100px);
      max-width: calc(100% + 100px);
    }
  }
  .phone-extension > div {
    background: #e8e8e8;
    min-height: 38px;
  }

  .form-control {
    margin-left: 5px;
  }

  .phone-extension > div {
    background: #e8e8e8;
    min-height: 38px;
    margin-left: 3px;
    width: 100px;
  }

  .css-1dimb5e-singleValue {
    text-overflow: unset;
    width: max-content;
  }
  .css-1nmdiq5-menu {
    text-align: left;
  }
  ${(props) =>
    props.editMode
      ? `
      .css-1fdsijx-ValueContainer {
        padding: 2px 8px;
      }
    `
      : `
      .css-1fdsijx-ValueContainer {
        padding: 0px 8px;
      }
    `}
  .view-mode {
    .phone-extension__indicators {
      display: none;
    }
  }
`;
