import React, { useState } from "react";
import styled from "styled-components";
import SimpleReactValidator from "simple-react-validator";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

type OptionType = {
  value?: string;
  id?: number;
};
type Props = {
  label?: String;
  validations?: string;
  validator?: SimpleReactValidator;
  error?: string | null;
  value?: string | number;
  options?: OptionType[];
  //  options?: dropDownModel;
  datas?: { id?: number; name?: string }[];
  // datas?: dropDownModel;
  onSelect?: (e: any) => void;
  onChange?: (event: SelectChangeEvent<string | number>, val?: any) => void;
  names?: { id?: number; name?: string }[];
  // names?: dropDownModel;
  name?: string;
  hideStar?: boolean;
  defaultValue?: string | number | undefined;
  disabled?: boolean;
  unit?: string;
};

const MuiPlaceholderDropDown = ({
  label,
  validations,
  validator,
  error,
  value,
  options,
  datas,
  onSelect,
  onChange,
  names,
  hideStar,
  defaultValue,
  disabled,
  name,
  unit,
}: Props) => {
  const simpleValidator = React.useRef(validator);
  const [dropdownValidate, setDropDownValidate] = useState(false);
  const [selectvalue, setSelectValue] = React.useState<number | string>(
    value ?? ""
  );
  const [optionsArray, setOptionsArray] = React.useState<
    { id?: number; name?: string }[]
  >(datas ? datas : options ? options : names!);

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    const selectedOption = optionsArray.find(
      (item) => item.id === event.target.value
    );
    onChange?.(event, selectedOption);
    setSelectValue(event.target.value);
  };

  React.useEffect(() => {
    setOptionsArray(datas ? datas : options ? options : names!);
  }, [datas, options, names]);

  console.log("selectttt", selectvalue);
  console.log("value", value);
  const handleBlur = (e: any) => {
    e.preventDefault();
    if (!(e?.target?.value ? true : false) && validations)
      simpleValidator?.current?.showMessageFor(
        label?.toString()! ? label?.toString()! : "field"
      );
    setDropDownValidate(!dropdownValidate);
  };
  React.useEffect(() => {
    if (value !== selectvalue) {
      setSelectValue(value ?? "");
    }
    return () => {};
  }, [value]);
  return (
    <StyledDropDownWrapper className="StyledDropDownWrapper">
      <InputLabel id="custom-select-label">{label}</InputLabel>

      <FormControl className="FormControl" sx={{ m: 3, width: 370, mt: 2 }}>
        <FieldLabel>
          {`${label ? label : ""} `}
          <div className="red-star">{validations && !hideStar ? "*" : ""}</div>
        </FieldLabel>
        <Select
          className="styled-FormControl"
          value={selectvalue}
          // defaultValue={defaultValue}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder=""
          inputProps={{ "aria-label": "Without label" }}
          disabled={disabled}
        >
          <MenuItem selected={selectvalue ? false : true} disabled value="">
            <label>{unit}</label>
          </MenuItem>
          {optionsArray &&
            optionsArray.map((item, i) => (
              <MenuItem key={i} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Errortext>
        {simpleValidator?.current?.message(
          name?.toString()! ? name?.toString()! : "field",
          value?.toString() && value?.toString(),
          validations ?? ""
        )}
      </Errortext>
    </StyledDropDownWrapper>
  );
};

export default MuiPlaceholderDropDown;
const Errortext = styled.span`
  display: flex;
  font-size: 14px;
  color: #e91344;
  letter-spacing: 0.5px;
  padding-top: 8px !important;
  margin-left: -10px !important;
`;
const FieldLabel = styled.div`
  display: flex;
  text-align: left;
  margin: 0 0 10px 0;
  color: #a09d9d;
  font-size: 14px;
  .red-star {
    color: red;
    font-weight: bold;
  }
`;
const StyledDropDownWrapper = styled.div`
  label.MuiFormLabel-root {
    margin: 0 16px 0px 16px;
  }

  .srv-validation-message {
    margin: 0px 0px 10px 16px !important;
  }
  .styled-FormControl {
    border-radius: 7px;
    background: #f0f0f0 !important;
    height: 40px !important;
    text-align: left !important;
    border: none !important;
  }

  .MuiFormControl-root {
    margin: 2px !important;
    .MuiInputBase-root {
      .MuiSelect-select {
        label {
          margin: 0px;
          font-family: "Outfit Regular";
          font-weight: 400;
          color: #a09d9d;
          font-size: 14px;
        }
      }
    }
  }
  .MuiSvgIcon-root.MuiSelect-icon {
    background: #d9d9d9 !important;
    margin-top: -8px !important;
    margin-right: -6px !important;
    border-bottom-right-radius: 7px !important;
    border-top-right-radius: 7px !important;
    width: 40px;
    height: 40px;
  }

  .MuiSvgIcon-root {
    &.MuiSelect-iconOpen {
      background: #d9d9d9 !important;
      margin-top: -9px !important;
      margin-right: -6px !important;
      width: 40px;
      height: 40px;
      border-top-left-radius: 7px !important;
      border-bottom-left-radius: 7px !important;
      margin-top: -8px !important;
      margin-right: -6px !important;
      border-bottom-right-radius: 0px !important;
      border-top-right-radius: 0px !important;
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: none !important;
    border: none !important;
  }
`;
