import React, { useState } from "react";
import styled from "styled-components";
import RoundedWrapperCard from "../../../../../components/RoundedWrapperCard";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { MuiStyledTextField } from "../../../../../components/MuiStyledTextField";
import { FileUploadButton } from "../../../../../components/FileUploadButton";
import PhoneNumberField from "../../../../../components/PhoneNumberField";
import { CardHeadingLabel } from "../../../../../components/label";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import { Button } from "@mui/material";
import AddIcon from "./assets/icons/AddIcon.svg";
import CoOwnerComponent from "./components/CoOwnerComponent";
import SimpleReactValidator from "simple-react-validator";
import { vendorDetails } from "../../../../../api/services/vap/onboardingManagement";
import { log } from "console";
import { logDOM } from "@testing-library/react";
import { toast } from "react-toastify";
import mainLoader from "../../../../../assets/img/loader/loader.gif";

type Props = {
  email?: string;
  phone?: string | number;
  countryValue?: string;
  onNextClick?: () => void;
};
type payloadDataType = {
  company_name?: string;
  website?: string;
  official_phone_extension?: string;
  official_phone?: string | undefined;
  official_email?: string;
  account_holder_name?: string;
  account_number?: number | string;
  bank_name?: string;
  iban?: number;
  trading_license: File | null;
  e_commerce_license: File | null;
  service_agreement: File | null;
};
type ownerDataType = {
  user_type?: number;
  name?: string;
  resident_of_uae?: string;
  phone?: number | null;
  national_ID?: File | null;
  passport?: File | null;
  visa?: File | null;
};

const VendorDocumentation = ({
  email,
  phone,
  countryValue,
  onNextClick,
}: Props) => {
  console.log(email, "email..");
  const [value, setValue] = useState<string | undefined>(undefined);
  const [submitClick, setSubmitClick] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [bankDetails, setBankDetails] = React.useState({
    account_holdername: "",
  });
  const [payloadData, setPayloadData] = React.useState<payloadDataType>({
    company_name: "",
    website: "",
    official_phone_extension: "",
    official_phone: "",
    official_email: "",
    account_holder_name: "",
    account_number: undefined,
    bank_name: "",
    iban: undefined,
    trading_license: null,
    e_commerce_license: null,
    service_agreement: null,
  });
  const [primaryData, setPrimaryData] = React.useState<ownerDataType>({
    user_type: 1,
    name: "",
    resident_of_uae: "",
    phone: null,
    national_ID: null,
    passport: null,
    visa: null,
  });

  const [CoOwnerData, setCoOwnerData] = React.useState<{} | null>();
  const [coOwnerList, setCoOwnerList] = React.useState<Array<ownerDataType>>(
    []
  );
  const [isCoOwnerAdd, setIsCoOwnerAdd] = React.useState(false);
  const [selectedModel, setSelectedModel] = React.useState("B2B");
  const [isTradingLicenseValid, setTradingLicenseValid] = React.useState(false);
  const [isEcommercelicenseValid, setEcommercelicenseValid] =
    React.useState(false);
  const [isServiceAgreementUpload, setServiceAgreementUpload] =
    React.useState(false);
  const [isNationalIdUpload, setNationalIdUpload] = React.useState(false);
  const [isPassportUpload, setPassportUpload] = React.useState(false);
  const [isVisaUpload, setVisaUpload] = React.useState(false);
  const [isSubmitClicked, setIsSubmitClicked] = React.useState(false);
  const [residentError, setResidentError] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    setPrimaryData({
      ...primaryData,
      resident_of_uae: event.target.value,
      national_ID: null,
    });
    setValue(event.target.value);
    // Update the error state
    setResidentError(false);
  };
  const handleBankChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBankDetails({ ...bankDetails, account_holdername: event.target.value });
  };
  let defaultformData: any = {
    company_name: payloadData.company_name!,
    url: payloadData.website!,
    official_phone: payloadData.official_phone!,
    official_email: payloadData.official_email!,
    account_holder_name: payloadData.account_holder_name!,
    account_number: payloadData.account_number!,
    bank_name: payloadData.bank_name!,
    iban: payloadData.iban!,
    email: email!,
    phone: phone!,
    country: countryValue!,
    b2b_model: selectedModel === "B2B" ? true : false,
    b2c_model: selectedModel === "B2C" ? true : false,
    // userDetails: [...[primaryData], ...coOwnerList],
    // trading_license: payloadData.trading_license
  };
  // console.log([...[primaryData], ...coOwnerList], "[...[primaryData], ...coOwnerList]");
  console.log(...coOwnerList, "...coOwnerList");

  function handleSubmitClick() {
        // Validate the form
    setSubmitClick(!submitClick);
    setIsSubmit(true);
    setIsCheckboxSelected(selectedModels.length > 0);

    if (value === undefined) {
      setResidentError(true);
    } else {
      setResidentError(false);
    }
    const errorMessages = simpleValidator?.current?.getErrorMessages();

    // Check if all values in errorMessages are null
    const tradingLicenseFile = payloadData?.trading_license;

    if (tradingLicenseFile !== undefined && tradingLicenseFile !== null) {
      // Assuming isFileValid checks for the validity of the trading license file
      const isTradingLicenseFileValid = isFileValid(tradingLicenseFile);
      setTradingLicenseValid(isTradingLicenseFileValid);
    } else {
      console.error("Trading license file is null or undefined");
      setTradingLicenseValid(false); // Set to false if the file is not valid
    }
    console.log(
      "Simple React Validator Current State:",
      simpleValidator.current
    );
    const hasValidationErrors = Object.values(errorMessages ?? {}).some(
      (value) => value !== null
    );

    if (hasValidationErrors) {
    
      for (const key in simpleValidator?.current.errorMessages!) {
        if (simpleValidator?.current.errorMessages!.hasOwnProperty(key)) {
          // formData.append(key, defaultformData[key]);
          simpleValidator?.current?.showMessageFor(key);
          console.log(
            simpleValidator?.current?.showMessageFor(key),
            "simpleValidator?.curren key"
          );
        }
      }
      console.log("No validation errors, enable the submit button");

      // Proceed with form submission logic
    } else {
      console.log(payloadData, "payload");
      let formData = new FormData();

      for (const key in defaultformData!) {
        if (defaultformData!.hasOwnProperty(key)) {
          formData.append(key, defaultformData[key]);
        }
      }
      [...[primaryData], ...coOwnerList].forEach((user: any, index: number) => {
        console.log(user, "user...");
        formData.append(`userDetails[${index}][user_type]`, user.user_type);
        formData.append(`userDetails[${index}][name]`, user.name);
        formData.append(
          `userDetails[${index}][resident_of_uae]`,
          user.resident_of_uae
        );
        formData.append(`userDetails[${index}][phone]`, user.phone??"");
        formData.append(`userDetails[${index}][national_ID]`, user.national_ID);
        formData.append(`userDetails[${index}][passport]`, user.passport);
        formData.append(`userDetails[${index}][visa]`, user.visa);
      });
      formData.append("trading_license", payloadData?.trading_license!);
      formData.append("e_commerce_license", payloadData?.e_commerce_license!);
      formData.append("service_agreement", payloadData?.service_agreement!);
      for (const pair of formData.entries()) {
        console.log(pair[0] + ": " + pair[1], "formData123");
      }

      setIsLoading(true);
      vendorDetails(formData)
        .then((res) => {
          if (res) {
            setIsLoading(false);
            if (res.status) {
              onNextClick?.();
            } else {
              toast.error("Enter all required fields", {
                position: "top-right",
              });
            }

            // Assuming onNextClick is a function passed as a prop
          }
        })
        .catch((err) => {
          console.log(err, "error");
          setIsLoading(false);
        });
    
    }
  }


  const handleTradingLicenseUpload = (file: File | null) => {
    if (file !== null && file instanceof File) {
      // Perform validation logic here
      const isValid = isFileValid(file);

      // Update the state based on validation result
      setPayloadData({ ...payloadData, trading_license: file });
      setTradingLicenseValid(isValid);

      // Reset submitClick to false when a valid file is uploaded
      setSubmitClick(false);

      // Continue with other handling logic if needed
    } else {
      // Handle the case where file is null or not an instance of File
      console.error("Invalid file selected");
      // You might want to set trading license as invalid in this case too
      setTradingLicenseValid(false);
    }
  };

  const removeHandleTradingLicenseUpload = () => {
    setPayloadData({ ...payloadData, trading_license: null });
    setTradingLicenseValid(false);
  };

  const isFileValid = (file: File) => {
    // Add your file validation logic here
    // For example, check file type, size, etc.
    return file.type === "application/pdf" && file.size > 0; // Adjust the conditions as needed
  };

  // const handleTradingLicenseUpload = (file: File) => {
  //   console.log("Selected file trdlcs:", file);
  //   setPayloadData({ ...payloadData, trading_license: file });
  // };
  const handleEcommerceLicenseUpload = (file: File | null) => {
    if (file !== null && file instanceof File) {
      // Perform validation logic here
      const isValid = isFileValid(file);

      // Update the state based on validation result
      setPayloadData({ ...payloadData, e_commerce_license: file });
      setEcommercelicenseValid(isValid);

      // Reset submitClick to false when a valid file is uploaded
      setSubmitClick(false);

      // Continue with other handling logic if needed
    } else {
      // Handle the case where file is null or not an instance of File
      console.error("Invalid file selected");
      // You might want to set trading license as invalid in this case too
      setEcommercelicenseValid(false);
    }
  };

  const removeHandleEcommerceLicenseUpload = () => {
    setPayloadData({ ...payloadData, e_commerce_license: null });
    setEcommercelicenseValid(false);
  };

  const handleServiceAgreementUpload = (file: File | null) => {
    if (file !== null && file instanceof File) {
      // Perform validation logic here
      const isValid = isFileValid(file);

      // Update the state based on validation result
      setPayloadData({ ...payloadData, service_agreement: file });
      setServiceAgreementUpload(isValid);

      // Reset submitClick to false when a valid file is uploaded
      setSubmitClick(false);

      // Continue with other handling logic if needed
    } else {
      // Handle the case where file is null or not an instance of File
      console.error("Invalid file selected");
      // You might want to set trading license as invalid in this case too
      setServiceAgreementUpload(false);
    }
  };
  const removeHandleServiceAgreementUpload = () => {
    setPayloadData({ ...payloadData, service_agreement: null });
    setServiceAgreementUpload(false);
  };
  const handleNationalIdUpload = (file: File | null) => {
    if (file !== null && file instanceof File) {
      // Perform validation logic here
      const isValid = isFileValid(file);

      // Update the state based on validation result
      setPrimaryData({ ...primaryData, national_ID: file });
      setNationalIdUpload(isValid);

      // Reset submitClick to false when a valid file is uploaded
      setSubmitClick(false);

      // Continue with other handling logic if needed
    } else {
      // Handle the case where file is null or not an instance of File
      console.error("Invalid file selected");
      // You might want to set trading license as invalid in this case too
      setNationalIdUpload(false);
    }
  };

  const removeHandleNationalIdUpload = () => {
    setPrimaryData({ ...primaryData, national_ID: null });
    setNationalIdUpload(false);
  };

  const handlePassportUpload = (file: File | null) => {
    if (file !== null && file instanceof File) {
      // Perform validation logic here
      const isValid = isFileValid(file);

      // Update the state based on validation result
      setPrimaryData({ ...primaryData, passport: file });
      setPassportUpload(isValid);

      // Reset submitClick to false when a valid file is uploaded
      setSubmitClick(false);

      // Continue with other handling logic if needed
    } else {
      // Handle the case where file is null or not an instance of File
      console.error("Invalid file selected");
      // You might want to set trading license as invalid in this case too
      setPassportUpload(false);
    }
  };
  const removeHandlePassportUpload = () => {
    setPrimaryData({ ...primaryData, passport: null });
    setPassportUpload(false);
  };

  const handlevisaUpload = (file: File | null) => {
    if (file !== null && file instanceof File) {
      // Perform validation logic here
      const isValid = isFileValid(file);

      // Update the state based on validation result
      setPrimaryData({ ...primaryData, visa: file });
      setVisaUpload(isValid);

      // Reset submitClick to false when a valid file is uploaded
      setSubmitClick(false);

      // Continue with other handling logic if needed
    } else {
      // Handle the case where file is null or not an instance of File
      console.error("Invalid file selected");
      // You might want to set trading license as invalid in this case too
      setVisaUpload(false);
    }
  };
  const removeHandlevisaUpload = () => {
    setPrimaryData({ ...primaryData, visa: null });
    setVisaUpload(false);
  };

  const ownerDefaultData: ownerDataType = {
    user_type: 1,
    name: "",
    resident_of_uae: "0",
    phone: undefined,
  };

  const coOwnerDefaultData: ownerDataType = {
    user_type: 2,
    name: "",
    resident_of_uae: "",
    phone: null,
    national_ID: null,
    passport: null,
    visa: null,
  };

  const handleAddCoOwner = () => {
    setIsCoOwnerAdd(true);
    setCoOwnerList((prevCoOwners) => [...prevCoOwners!, coOwnerDefaultData]);
  };

  const [selectedModels, setSelectedModels] = React.useState<string[]>([]);
  const [isCheckboxSelected, setIsCheckboxSelected] = React.useState(true);

  const handleModelChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const updatedSelectedModels = checked
      ? [...selectedModels, event.target.name]
      : selectedModels.filter((model) => model !== event.target.name);

    setSelectedModels(updatedSelectedModels);
    setIsCheckboxSelected(updatedSelectedModels.length > 0);
  };

  const handleChangeOfficialContact = (
    value: string,
    data: any,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    console.log(value, data, formattedValue, "values");

    setPayloadData({ ...payloadData, official_phone: formattedValue });
  };
  console.log(coOwnerList, "CoOwnerList ");
  const handleRemoveCoOwner = (removedIndex: number) => {
    // Remove the co-owner at the specified index
    const updatedCoOwners = coOwnerList.filter(
      (_, index) => index !== removedIndex
    );
    setCoOwnerList(updatedCoOwners);
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-block">
          <img className="img-fluid" src={mainLoader} alt="loader" />
        </div>
      ) : (
        <StyledWrapper>
          {/* Business Details */}
          <StyledSubWrapper className="StyledSubWrapper">
            <CardHeadingLabel className="CardHeadingLabel">
              Business Details
            </CardHeadingLabel>
            <RoundedWrapperCard className="RoundedWrapperCard">
              <StyledGridWrapper container spacing={2}>
                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="Business/Company Trade Name"
                    name="Business/Company Trade Name"
                    placeholder="Enter your business name"
                    className="background-gray"
                    value={payloadData?.company_name}
                    validations={"required"}
                    validator={simpleValidator.current}
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        company_name: e.target.value,
                      })
                    }
                  />
                </Grid>

                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="Official Websites (if any)"
                    placeholder="Enter your website URL here"
                    className="background-gray"
                    value={payloadData?.website}
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        website: e.target.value,
                      })
                    }
                    name="Official Website "
                    validations={"url"}
                    validator={simpleValidator.current}
                    hideStar={true}
                  />
                </Grid>
                <Grid item xs={6} className="styled-PhoneNumberField">
                  <PhoneNumberField
                    label="Official Contact Number"
                    placeholder="Enter your company’s official mobile number"
                    validations="required"
                    value={payloadData.official_phone}
                    onChange={handleChangeOfficialContact}
            
                    className="styled-PhoneNumberField"
                    validator={simpleValidator.current}
                    name="Official Contact Number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="Official E-mail ID"
                    placeholder="Enter your company’s official e-mail ID"
                    className="background-gray"
                    value={payloadData.official_email}
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        official_email: e.target.value,
                      })
                    }
                    validations={"required|email"}
                    validator={simpleValidator.current}
                    name="email"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FieldLabel>
                    Upload a copy of <span>your Trading License</span>{" "}
                    <span className="validField">*</span>
                  </FieldLabel>
                  <UploadFieldWrapper>
                    <FileUploadButton
                      onFileUpload={handleTradingLicenseUpload}
                      onRemoveClick={removeHandleTradingLicenseUpload}
                      validations={"required"}
                      validator={simpleValidator.current}
                      fieldName="Trading License"
                      defaultValue={payloadData.trading_license}
                      isSubmit={submitClick}
                    />
                  
                  </UploadFieldWrapper>
                </Grid>
                <Grid item xs={6}>
                  <FieldLabel>
                    Upload a copy of <span>your E-commerce License </span>(if
                    any)
                  </FieldLabel>
                  <UploadFieldWrapper>
                    <FileUploadButton
                      onFileUpload={handleEcommerceLicenseUpload}
                      onRemoveClick={removeHandleEcommerceLicenseUpload}
                    />

                  </UploadFieldWrapper>
                </Grid>
                <Grid item xs={6}>
                  <FieldLabel>
                    Upload a copy of <span>Service Agreement/MoA/AoA </span>{" "}
                    <span className="validField">*</span>
                  </FieldLabel>
                  <UploadFieldWrapper>
                    <FileUploadButton
                      onFileUpload={handleServiceAgreementUpload}
                      onRemoveClick={removeHandleServiceAgreementUpload}
                      validations={"required"}
                      validator={simpleValidator.current}
                      fieldName="Service agreement/moa/aoa"
                      defaultValue={payloadData.service_agreement}
                      isSubmit={submitClick}
                    />
                   
                  </UploadFieldWrapper>


                </Grid>
                <Grid item xs={6}>
                  <FieldLabel>
                    Select your business model
                    <span className="validField"> *</span>
                  </FieldLabel>
                  <MultipleCheckboxWrapper className="MultipleCheckboxWrapper">
                    <FormControlLabel
                      control={
                        <Checkbox
                         
                          onChange={handleModelChange}
                          name="B2B"
                        />
                      }
                      label="Business-To-Business (B2B/Wholesale)"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          
                          onChange={handleModelChange}
                          name="B2C"
                        />
                      }
                      label="Business-To-Consumer (B2C/Retail)"
                    />
                     {!isCheckboxSelected && isSubmit && (
                  <ErrorMessage>
                    Please select at least one business model.
                  </ErrorMessage>
                )}
                  </MultipleCheckboxWrapper>
                </Grid>
              </StyledGridWrapper>
            </RoundedWrapperCard>
          </StyledSubWrapper>
          <StyledSubWrapper className="StyledSubWrapper">
            <CardHeadingLabel className="CardHeadingLabel">
              Owner(s) Details
            </CardHeadingLabel>
            <RoundedWrapperCard className="RoundedWrapperCard">
              <StyledGridWrapper container spacing={2}>
                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="Primary Owner Name"
                    placeholder="Enter your name"
                    className="background-gray"
                    validations="required"
                    value={primaryData.name}
                    onChange={(e) =>
                      setPrimaryData({ ...primaryData, name: e.target.value })
                    }
                    validator={simpleValidator.current}
                    name="Primary Owner Name"
                  />
                </Grid>
                <Grid item xs={6} className="styled-PhoneNumberField">
                  <PhoneNumberField
                    label="Primary Owner Contact Number"
                    placeholder="Enter your contact number"
                    value={primaryData?.phone?.toString() ?? ''}
                    onChange={(e) => 
                      setPrimaryData({ ...primaryData, phone: parseInt(e) })                     
                    }
                    validator={simpleValidator.current}
                    name="Primary Owner Contact Number"
                    validations="required"
                  />
                </Grid>

                <Grid item xs={6}>
                  <RadioGroupWrapper>
                    <FormControl>
                      <FieldLabel>
                        Are you a resident of United Arab Emirates (UAE)?{" "}
                        <span className="validField">*</span>
                      </FieldLabel>
                      <RadioButtonWrapper className="RadioButtonWrapper">
                        <RadioGroup
                          aria-labelledby="demo-controlled-radio-buttons-group"
                          name="controlled-radio-buttons-group"
                          value={value}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Yes, I am a resident of UAE"
                          />
                          <FormControlLabel
                            value="0"
                            control={<Radio />}
                            label="No, I do not reside in UAE"
                          />
                        </RadioGroup>
                        {residentError && isSubmit && (
                      <ErrorMessage>
                        Please select whether you are a resident of UAE.
                      </ErrorMessage>
                    )}
                      </RadioButtonWrapper>
                    </FormControl>
                  </RadioGroupWrapper>
                </Grid>

                <Grid item xs={6}>
                  <FieldLabel>
                    Upload your <span>Emirates National ID </span>(Applicable
                    only if you are a resident of UAE)
                  </FieldLabel>
                  <UploadFieldWrapper>
                    <FileUploadButton
                      onFileUpload={handleNationalIdUpload}
                      onRemoveClick={removeHandleNationalIdUpload}
                      validations={"required"}
                  validator={
                    value === "1" ? simpleValidator.current : undefined
                  }
                      fieldName=" Emirates National Id"
                      defaultValue={primaryData.national_ID}
                      isSubmit={submitClick}

                    />

                   
                  </UploadFieldWrapper>
                </Grid>
                <Grid item xs={6}>
                  <FieldLabel>
                    Upload a copy of <span>your passport </span>for
                    documentation purposes <span className="validField">*</span>
                  </FieldLabel>
                  <UploadFieldWrapper>
                    <FileUploadButton
                      onFileUpload={handlePassportUpload}
                      onRemoveClick={removeHandlePassportUpload}
                      validations={"required"}
                      validator={simpleValidator.current}
                      fieldName="Passport"
                      defaultValue={primaryData.passport}
                      isSubmit={submitClick}
                    />
                   
                  </UploadFieldWrapper>
                </Grid>
                <Grid item xs={6}>
                  <FieldLabel>
                    Upload a copy of<span> your visa</span> for documentation
                    purposes
                  </FieldLabel>
                  <UploadFieldWrapper>
                    <FileUploadButton
                      onFileUpload={handlevisaUpload}
                      onRemoveClick={removeHandlevisaUpload}
                     
                    />

                    {/* {submitClick && value === "1" && !isVisaUpload && (
                      <span className="validation-error">
                        Please upload a valid document
                      </span>
                    )} */}
                  </UploadFieldWrapper>
                  {/* <UploadFieldWrapper>
                <FileUploadButton onFileUpload={handlevisaUpload} />
              </UploadFieldWrapper> */}
                </Grid>
              </StyledGridWrapper>
            </RoundedWrapperCard>
          </StyledSubWrapper>

          <StyledSubWrapper>
            {coOwnerList && coOwnerList.length > 0
              ? coOwnerList?.map((data: ownerDataType, index) => {
                  return (
                    <>
                      <CardHeadingLabel className="CardHeadingLabel">
                        Co-Owner({index + 1}) Details
                      </CardHeadingLabel>
                      <CoOwnerComponent
                        isCoOwnerAdd={isCoOwnerAdd}
                        data={data}
                        index={index}
                        CoOwnerData={CoOwnerData}
                        setCoOwnerData={setCoOwnerData}
                        coOwnerList={coOwnerList}
                        setCoOwnerList={setCoOwnerList}
                        onRemoveClick={(removedIndex: number) =>
                          handleRemoveCoOwner(removedIndex)
                        }
                        isVisaUpload={isVisaUpload}
                        isNationalIdUpload={isNationalIdUpload}
                        isPassportUpload={isPassportUpload}
                      />
                    </>
                  );
                })
              : ""}
          </StyledSubWrapper>
          <SubmitBtnWrapper>
            <Button
              component="span"
              startIcon={<img src={AddIcon} alt="ADD" />}
              style={{
                background: "transparent",
                color: "#01579B",
                border: "1px solid #01579B",
                fontSize: "12px",
                width: "183px",
                height: "35px",
                marginBottom: "20px",
              }}
              onClick={handleAddCoOwner}
            >
              ADD A CO-OWNER
            </Button>
          </SubmitBtnWrapper>
          {/* Banking Details */}
          <StyledSubWrapper className="StyledSubWrapper">
            <CardHeadingLabel className="CardHeadingLabel">
              Banking Details
            </CardHeadingLabel>
            <RoundedWrapperCard className="RoundedWrapperCard">
              <StyledGridWrapper container spacing={2}>
                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="Account Holder Name"
                    placeholder="Enter name of company or person bank account belongs to"
                    className="background-gray"
                    validations="required"
                    value={payloadData.account_holder_name}
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        account_holder_name: e.target.value,
                      })
                    }
                    validator={simpleValidator.current}
                    name="Account Holder Name"
                    // onChange={handleBankChange}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="IBAN Number"
                    placeholder="Enter IBAN number"
                    className="background-gray"
                    validations="required|numeric|min:5|max:34"
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        iban: parseInt(e.target.value) || undefined,
                      })
                    }
                    validator={simpleValidator.current}
                    value={payloadData?.iban?.toString() ?? ""}
                    name="IBAN Number"
                  />
                </Grid>

                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="Account Number"
                    placeholder="Enter your bank account number"
                    className="background-gray"
                    validations="required|numeric|min:8|max:20"
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        account_number: parseInt(e.target.value) || undefined,
                      })
                    }
                    validator={simpleValidator.current}
                    value={payloadData?.account_number?.toString() ?? ""}
                    name="Account Number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="Bank Name"
                    placeholder="Enter name of bank where the bank account is in"
                    className="background-gray"
                    validations="required"
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        bank_name: e.target.value,
                      })
                    }
                    validator={simpleValidator.current}
                    value={payloadData?.bank_name}
                    name="Bank Name"
                  />
                </Grid>
              </StyledGridWrapper>
            </RoundedWrapperCard>
          </StyledSubWrapper>
          <SubmitBtnWrapper className="SubmitBtnWrapper">
            <StyledButton onClick={handleSubmitClick} variant="contained">
              Submit
            </StyledButton>
          </SubmitBtnWrapper>
        </StyledWrapper>
      )}
    </>
  );
};

export default VendorDocumentation;
const StyledWrapper = styled.div`
  display: "block";
`;
const RadioGroupWrapper = styled.div`
  display: "block";
  text-align: left;
`;
const RadioButtonWrapper = styled.div`
  padding: 0 20px;
  .MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: 600;
  }
  label {
    margin-bottom: 0;
  }
  .MuiButtonBase-root {
    padding: 3px 5px 3px 0;
  }
`;
const StyledSubWrapper = styled.div`
  display: "block";
  width: 100%;
  padding: 0 0 30px 0;
  .CardHeadingLabel {
    margin: 15px 0px 15px;
  }
  .RoundedWrapperCard {
    margin: 0px 25px 0px 25px;
  }
`;
const StyledTitleWrapper = styled.div`
  display: "block";
`;
const StyledGridWrapper = styled(Grid)`
  /* display: "block"; */
  .styled-PhoneNumberField {
    .FieldWrapper {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73;
      input {
        background: #e8e8e8 !important;
      }
    }
  }
  .background-gray {
    div {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73 !important;
    }
    .react-tel-input {
      background: #e8e8e8;
    }
  }
  .textfield-wrapper {
    max-width: 100% !important;
  }
`;
const FieldLabel = styled.div`
  text-align: left;
  margin: 0 0 10px 0;
  color: #a09d9d;
  font-size: 14px;
  span {
    font-weight: 600;
    color: #000000;
  }
  .validField {
    color: #ff0000;
  }
`;

const UploadFieldWrapper = styled.div`
  padding: 5px 15px;
  color: #e91344 !important;
  text-align: left;
  font-size: 13px;
`;

const MultipleCheckboxWrapper = styled.div`
  padding: 0 20px;
  text-align: left;
  display: flex;
  flex-direction: column;
  .MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: 600;
  }
  label {
    margin-bottom: 0;
  }
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 3px 5px 3px 0;
  }
`;
const SubmitBtnWrapper = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  width: 100%;
  text-align: right;
  padding: 5px 24px;
  button {
    max-width: 180px !important;
    color: rgb(255, 255, 255) !important;
    font-size: 12px !important;
    border: none !important;
    padding: 10px 10px !important;
    border-radius: 6px !important;
    text-transform: uppercase !important;
    background: #01579b !important;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 0px !important;
  width: 100%;
  max-width: 200px !important;
  color: #ffffff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-family: "Outfit Regular" !important;
  border: none !important;
  padding: 10px 0px !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
  background: #6e93f7 !important;
`;
const ErrorMessage = styled.div`
  display: block;
  font-size: 13px;
  color: #e91344;
  margin-top: 5px;
`;
