import React, { useState, useContext, useEffect, useRef } from "react";
import { BookingsContext } from "./BookingsContext";
import "../../Layout/Ui/css/Pagination.css";
import DataTable from "react-data-table-component";
import dateFormat from "dateformat";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { viewMappedBooking } from "../../../../api/services/sap/bookings-management";
import styled from "styled-components";

const BookingsWindowLeft = (props) => {
  const {
    BookingsTable,
    setMappedBookingsData,
    setViewBookings,
    filterValue,
    setFilterValue,
    bookDate,
    setBookDate,
    filterBookingshandler,
    setViewReadOnly,
    setRowActive,
    setShowBookingButton,
    setError,
    currentDateHandler,
    selectBookDate,
    setSelectBookDate,
    chooseDate,
    nextDay,
    prevDay,
  } = useContext(BookingsContext);
  const [calendar, setCalendar] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    const clickedElement = event.target;
    if (
      ref.current &&
      clickedElement?.contains(document.querySelector(".popup-content"))
    ) {
      setCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setFilterValue(
      BookingsTable &&
        BookingsTable.filter((item) => {
          return dateFormat(new Date(item.service_date), "yyyy-mm-dd").includes(
            dateFormat(new Date(), "yyyy-mm-dd")
          );
        })
    );
  }, [BookingsTable]);

  const paginationComponentOptions = {
    rowsPerPageText: false,
    // rangeSeparatorText: "",
    selectAllRowsItem: true,
    selectAllRowsItemText: "",
    noRowsPerPage: true,
  };

  let startDate = dateFormat(new Date(bookDate[0]), "fullDate");
  let endDate = dateFormat(new Date(bookDate[1]), "fullDate");
  const columns = [
    {
      name: "Customer Name",
      selector: (row) => row.customer.customer_name,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.customer.phone_number,
      sortable: true,
    },
    {
      name: "Vehicle Number",
      selector: (row) =>
        row.customerVehicle.vehicle_registration_number.toUpperCase(),
      sortable: true,
      style: {
        textTransform: "uppercase",
      },
    },
    {
      name: "Date",
      selector: (row) => dateFormat(new Date(row.service_date), "yyyy-mm-dd"),
      sortable: true,
    },
    {
      name: "Time",
      selector: (row) => row.service_time,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => "booked",
      sortable: true,
      cell: (row) => {
        const colors = {
          Open: "#6100FF",
          Rejected: "#FF0000",
          Rescheduled: "#FFA500",
          "In Progress": "#FF6B00",
          Pending: "#FF0000",
          Completed: "#0094FF",
          "Ready for Delivery": "#2d9b57",
          Delivered: "#0DBC52",
          Cancelled: "#FF0000",
        };

        return (
          <div>
            {row.orderStatus !== undefined &&
              (row.orderStatus.title === "Open" ? (
                <span
                  className="badge"
                  style={{ background: colors[row?.orderStatus?.title] }}
                >
                  Booked
                </span>
              ) : (
                <span
                  className="badge"
                  style={{ background: colors[row?.orderStatus?.title] }}
                >
                  {row.orderStatus.title}
                </span>
              ))}
          </div>
        );
      },
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const dateRangeFilterhandler = (e) => {
    setSelectBookDate(e);
  };
  const calendarOk = () => {
    if (
      dateFormat(selectBookDate?.[0], "yyyy-mm-dd") ===
      dateFormat(selectBookDate?.[1], "yyyy-mm-dd")
    ) {
      setFilterValue(
        BookingsTable.filter((item) =>
          dateFormat(item.service_date, "yyyy-mm-dd").includes(
            dateFormat(selectBookDate?.[0], "yyyy-mm-dd")
          )
        )
      );
    } else {
      setFilterValue(
        BookingsTable.filter(
          (item) =>
            dateFormat(item.service_date, "yyyy-mm-dd") >=
              dateFormat(selectBookDate?.[0], "yyyy-mm-dd") &&
            dateFormat(item.service_date, "yyyy-mm-dd") <=
              dateFormat(selectBookDate?.[1], "yyyy-mm-dd")
        )
      );
    }
    setBookDate(selectBookDate);

    setCalendar(false);
  };

  const viewCalendar = () => {
    setCalendar(true);
  };
  const cancelCalendar = () => {
    setSelectBookDate([
      dateFormat(new Date(), "yyyy-mm-dd"),
      dateFormat(new Date(), "yyyy-mm-dd"),
    ]);
    setBookDate([
      dateFormat(new Date(), "yyyy-mm-dd"),
      dateFormat(new Date(), "yyyy-mm-dd"),
    ]);
    filterBookingshandler(dateFormat(new Date(), "yyyy-mm-dd"));
    setCalendar(false);
  };

  const viewData = (data, e) => {
    const closestTableRow = e.target.closest(".rdt_TableRow");
    console.log(closestTableRow, "reach--3");
    setError(false);
    if (closestTableRow) {
      // Remove the class from all elements with class "rdt_TableRow"
      const allTableRows = document.querySelectorAll(".rdt_TableRow");
      allTableRows.forEach((rowElement) => {
        rowElement.classList.remove("current-selected-row");
      });
      console.log(allTableRows, "reach--4");
      // Append a new class to the closest parent element
      closestTableRow.classList.add("current-selected-row");
    }
    const viewBookingData = viewMappedBooking(data.order_id_pk);
    viewBookingData.then((response) => {
      setMappedBookingsData(response);
    });
    setViewReadOnly(true);
    props.bookingformClose(true);
    setRowActive(true);
    setShowBookingButton(false);
  };

  return (
    <div className="content-window-left">
      <div className="content-table-block">
        <div className="content-table table-responsive single-column-view">
          <div className="date-block-main">
            <button
              className="btn btn-none"
              id=""
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title="Add New"
              onClick={viewCalendar}
            >
              <span>
                <i className="fal fa-calendar"></i>
              </span>
            </button>
            {startDate !== endDate ? (
              <button onClick={currentDateHandler}>Today</button>
            ) : (
              ""
            )}

            <div className="date-block">
              <div className="date-control">
                <div
                  className={`date-control-sub ${
                    startDate === endDate ? "" : "disabled-block"
                  }`}
                >
                  <div
                    className="date-control-left"
                    onClick={() => chooseDate(prevDay)}
                  >
                    <i class="fal fa-caret-left"></i>
                  </div>
                  <div
                    className="date-control-right"
                    onClick={() => chooseDate(nextDay)}
                  >
                    <i class="fal fa-caret-right"></i>
                  </div>
                </div>
              </div>
              <div className="date">
                {startDate === endDate ? (
                  <h6>{startDate}</h6>
                ) : (
                  <h6>
                    {startDate} - {endDate}
                  </h6>
                )}
              </div>
            </div>
          </div>

          <WrapperLeft>
            <DataTable
              onRowClicked={(row, e) => {
                viewData(row, e);
              }}
              columns={columns}
              data={filterValue}
              pagination
              paginationComponentOptions={paginationComponentOptions}
            />
          </WrapperLeft>
          <div className="ref-element">
            {calendar && (
              <div ref={ref} className="popup-main">
                <div className="popup-sub">
                  <div className="popup-content">
                    <div className="popup-header"></div>
                    <div className="popup" id="popupDiv">
                      <Calendar
                        selectRange={true}
                        goToRangeStartOnSelect={true}
                        onChange={(e) => dateRangeFilterhandler(e)}
                        value={selectBookDate}
                      />
                    </div>
                    <div className="popup-footer">
                      <button
                        className="btn btn-light"
                        onClick={cancelCalendar}
                      >
                        Cancel
                      </button>
                      <button className="btn btn-primary" onClick={calendarOk}>
                        Ok
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingsWindowLeft;
const WrapperLeft = styled.div`
  .kjHanM {
    margin: 0px 5px;
  }
  .DfDUN {
    width: 30px;
  }
  .rdt_TableCol {
    min-width: 150px;
  }
  .rdt_TableCell {
    min-width: 150px;
  }
`;
