import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  id?: string;
  className?: string;
  disabled?: boolean;
};

export const SaveButton = ({ onClick, id, className, disabled }: ButtonProps) => {
  return (
    <Wrapper className={className}>
      <Button onClick={onClick} id={id} disabled={disabled}>
        Save
      </Button>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  padding: 2px 2px;

  .MuiButtonBase-root {
    font-size: 12px;
    font-weight: 400;
    text-transform: none !important;
    letter-spacing: 1px !important;
    background: #0277bd !important;
    color: white !important;
    max-height: 28px;
    min-width: 86px !important;
    max-width: 150px !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    padding: 6px 20px !important;
    /* font-family:'Inter'!important; */
  }
`;
