import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { approveOrRejectService } from '../../../../../api/services/sup/service-management';
import { ApprovalContext } from '../ApprovalContext';
import '../Ui/css/RejectModal.css'
import "../../../../../assets/css/tags.css";

 const RejectModal = (props) => {
  const { table, SetTable } = useContext(ApprovalContext);
  const [error, setError] = useState('');
  const [rejectedReason, setRejectedReason] = useState('');

  const rejectService = () => {
    const req = approveOrRejectService({
      type: 'reject',
      service_id_pk: props.id,
      rejected_reason: rejectedReason,
    });
    req.then((res) => {
      let newTable = table.map(e => (e.id === res.data.service_id_pk) ? (e.status = res.data.status, e) : e);
      SetTable(newTable);
      props.setRejectModal(false);
      props.handleCloseView();
      toast.success("Service Rejected Successfully", {
        position: "bottom-right",
      });
    }).catch((e) => {
      setError(e.data ? e.data[0].rejected_reason : '');
    });
  }

  const onCancel = () => {
    setError('');
    setRejectedReason('');
    props.setRejectModal(false);
  }

  return (
 <div className="action-modal reject-modal p-2">
      <div className="d-flex justify-content-end">
        <button className="btn close-btn" onClick={() => {props.setRejectModal(false)}}>
          <i className="fal fa-times"></i>
        </button>
      </div>
      <h4 className='m-1' >Are you sure to reject this service?</h4>
      <textarea
        className='form-control'
        rows={5}
        placeholder='If yes, state the rejection reason.'
        onChange={(e) => {setRejectedReason(e.target.value)}}
      ></textarea>
      <div className="input-block">
      {error && (
        <span className="form-error">{error}</span>
      )}
      </div>
      
      <div className="d-flex justify-content-center pt-3">
        <button className="btn mr-3 btn-cancel " onClick={onCancel}>
          Cancel
        </button>
        <button class="btn btn-danger" onClick={rejectService}>
        Reject<i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  )
}

export default RejectModal;
