import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { BookingsContext } from "../BookingsContext";
import deleteFile from "../../../../../assets/img/icon/upload/recycle-bin.svg";

const CancelModal = (props) => {
  const [cancelled, setCancelled] = useState("");
  const [reason, setReason] = useState("");
  // const [cancelData, setCancelData] = useState([{}]);
  let id = props.mappedData;
  const {
    BookingsTable,
    SetBookingsTable,
    mappedBookingsData,
    setMappedBookingsData,
    setViewReadOnly,
    setEditMode,
    cancelData,
    setCancelData,
  } = useContext(BookingsContext);
  const cancelSubmit = (e) => {
    e.preventDefault();
    setCancelData([{ cancelled, reason }]);
    props.setCancelModal(false);
    console.log(cancelled);
    console.log(cancelData, "cancelData");
    props.bookingformClose(false);
    setViewReadOnly(false);
    setEditMode(false);
    setMappedBookingsData({});
    // BookingsTable.forEach(booking => {
    //     if(booking.customer_name == props.mappedData){
    //         booking['cancelData'] = {
    //             'reason':reason,
    //             'cancelled':cancelled
    //         };
    //     }

    // });

    // BookingsTable.map((booking) => {
    //   if (booking.customer_name == props.mappedData) {
    //     booking["cancelData"] = {
    //       reason: reason,
    //       cancelled: cancelled,
    //     };
    //   }
    // });

    BookingsTable.map((booking) => {
      if (booking.customer_name == props.mappedData) {
        booking["cancelData"] = {
          reason: reason,
          cancelled: cancelled,
        };
      }
    });

    console.log(BookingsTable, "BookingsTable");
    console.log(mappedBookingsData.cancelData.reason, " reason for cancelData");
  };

  //   console.log(BookingsTable, "BookingsTable");
  return (
    <div className="modal-main" data-id={id}>
      <div className="custom-modal" data-id={id}>
        {/* <h1>{mappedBookingsData.customer_name}</h1> */}
        <div className="modal-dialog modal-dialog-centered">


          <div className="modal-content">
            <div class="modal-header"><button type="button" class="btn-close" onClick={() => props.setCancelModal(false)} aria-label="Close"></button></div>
            <div className="modal-body">
              <div className="modal-info-img">
                <img src={deleteFile} className="delete-file" alt="" />
              </div>
              <h5>Are you sure to cancel this booking?</h5>
              <form onSubmit={cancelSubmit}>
                <div className="cancelled-block">
                  <div className="input-group input-group-custom">
                    <label htmlFor="" className="mb-0">
                      Cancelled By
                    </label>
                    <div className="col-12 d-flex">
                      <div className="radio-title mr-4">
                        <input
                          type="radio"
                          value="Service Admin"
                          name="vehicleType"
                          onChange={(e) => setCancelled(e.target.value)}
                          checked={cancelled === "Service Admin"}
                        />
                        Service Admin
                      </div>
                      <div className="radio-title mr-4">
                        <input
                          type="radio"
                          value="Customer"
                          name="vehicleType"
                          onChange={(e) => setCancelled(e.target.value)}
                          checked={cancelled === "Customer"}
                        />
                        Customer
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-group input-group-custom reason-group">
                  {/* 
                  <label htmlFor="" className="mb-0 mt-3 ml-3">
              Reason
            </label> */}
                  <div className="col-12">
                    <div
                      className="form-group"
                    >
                      <textarea
                        placeholder="Reason for cancellation"
                        className="form-control"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="modal-footer"><button class="btn btn-light" onClick={() => props.setCancelModal(false)}>Close</button>
                
                <button class="btn btn-danger" disabled={cancelled.length && reason.length ? false : true }>Confirm</button></div>
                {/* <button
              className="btn btn-light"
              onClick={() => props.setCancelModal(false)}
            >
              Close
            </button>
            <button className="btn btn-danger">Confirm</button> */}
              </form>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelModal;
