import axios from "axios";
import sapAuthToken from "../utils/sapAuthToken";
import supAuthToken from "../utils/supAuthToken";
const // apiServerUrl = "http://motorque.codingo.co:8000";
  apiServerUrl = "http://motorquedev.codingo.co:8000";
// apiServerUrl = "http://localhost:8000";
const sapAxios = () => {
  const sapAxios = axios.create({
    baseURL: `${apiServerUrl}/service-admin`,
    timeout: 10000,
    headers: { Authorization: "Bearer " + sapAuthToken() },
  });

  sapAxios.interceptors.request.use(
    (req) => {
      return req;
    },
    (err) => {
      console.log("req-err", err);
      return Promise.reject(err);
    }
  );
  sapAxios.interceptors.response.use(
    (res) => {
      if (res.data.message === "Please authenticate.") {
        localStorage.removeItem("isSAPAuthenticated");
        localStorage.removeItem("sapAuthToken");
        window.location.reload();
      }
      return res.data;
    },
    (err) => {
      console.log("res-err", err);
      return Promise.reject(err);
    }
  );

  return sapAxios;
};

const supAxios = () => {
  const supAxios = axios.create({
    baseURL: `${apiServerUrl}/super-admin`,
    timeout: 10000,
    headers: { Authorization: "Bearer " + supAuthToken() },
  });

  supAxios.interceptors.request.use(
    (req) => {
      return req;
    },
    (err) => {
      console.log("req-err", err);
      return Promise.reject(err);
    }
  );
  supAxios.interceptors.response.use(
    (res) => {
      if (res.data.message === "Please authenticate.") {
        localStorage.removeItem("isSUPAuthenticated");
        localStorage.removeItem("supAuthToken");
        window.location.reload();
      }
      return res.data;
    },
    (err) => {
      console.log("res-err", err);
      return Promise.reject(err);
    }
  );

  return supAxios;
};

const commonAxios = () => {
  let token = "";
  if (supAuthToken()) {
    token = supAuthToken();
  }
  if (sapAuthToken()) {
    token = sapAuthToken();
  }
  const commonAxios = axios.create({
    baseURL: `${apiServerUrl}/common`,
    timeout: 10000,
    headers: { Authorization: `Bearer ${token}` },
  });

  commonAxios.interceptors.request.use(
    (req) => {
      return req;
    },
    (err) => {
      console.log("req-err", err);
      return Promise.reject(err);
    }
  );
  commonAxios.interceptors.response.use(
    (res) => {
      if (res.data.message === "Please authenticate.") {
        localStorage.removeItem("isSUPAuthenticated");
        localStorage.removeItem("supAuthToken");
        window.location.reload();
      }
      return res.data;
    },
    (err) => {
      console.log("res-err", err);
      return Promise.reject(err);
    }
  );

  return commonAxios;
};

const vapAxios = () => {
  const supAxios = axios.create({
    baseURL: `${apiServerUrl}`,
    // timeout: 10000,
    // headers: { 'Content-Type':'multipart/form-data' },
  });

  supAxios.interceptors.request.use(
    (req) => {
      return req;
    },
    (err) => {
      console.log("req-err", err);
      return Promise.reject(err);
    }
  );
  supAxios.interceptors.response.use(
    (res) => {
      if (res.data.message === "Please authenticate.") {
        localStorage.removeItem("isSUPAuthenticated");
        localStorage.removeItem("supAuthToken");
        window.location.reload();
      }
      return Promise.resolve(res);
    },
    (err) => {
      console.log("res-err", err);
      return Promise.reject(err);
    }
  );

  return supAxios;
};

export { sapAxios, supAxios, commonAxios, vapAxios };
