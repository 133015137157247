import { supAxios } from "../../axios";


const getServiceAdminList = async (id = undefined) => {
    const axiosInst = supAxios();
    const url = id ? ('sap/profile/' + id) : 'sap/profile';
    try {
        const res = await axiosInst({
            method: 'GET',
            url,
        });
        console.log(res);
        if (res.status) {
            return res.data;
        }
        throw new Error(res.message);
    } catch (err) {
        console.log(err);
        return err;
    }
}

const deleteProfile = async (id = undefined) => {
    const axiosInst = supAxios();
    const url = id ? ('sap/profile/delete/' + id) : 'sap/profile/delete';
    try {
        const res = await axiosInst({
            method: 'GET',
            url,
        });
        console.log(res);
        if (res.status) {
            return res.data;
        }
        throw new Error(res.message);
    } catch (err) {
        console.log(err);
        return err;
    }
}

const createProfile = async (data, id = undefined) => {
    const axiosInst = supAxios();
    const newData = ( id ? ({
        service_admin_id_pk: id,
        ...data,
    }) : ({ ...data }) );
    try {
        const res = await axiosInst({
            method: 'POST',
            url: 'sap/create-profile',
            data: newData,
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

export { getServiceAdminList, deleteProfile, createProfile };