import React, { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  children?: React.ReactNode;
  isImportant?: boolean;
  title?: string;
  className?: string;
  icon?: ReactNode;
};

const InputLayout = ({
  children,
  isImportant,
  title,
  className,
  icon,
}: Props) => {
  return (
    <Wrapper className={className}>
      <div className="input-group input-group-custom">
        <div className="input-group-prepend">
          <span className="input-group-text">
            <span className="input-group-icon">
              {icon ? icon : <i className="fal fa-user"></i>}
            </span>
            {title}
            {isImportant && <sup className="theme-text">*</sup>}
          </span>
        </div>
        <div className="input-block">{children}</div>
      </div>
    </Wrapper>
  );
};

export default InputLayout;
const Wrapper = styled.div`
  .input-group-icon {
    margin: 0 8px 0 0;
  }
`;
