import { AxiosResponse } from "axios";
import { supAxios } from "../../axios";
const axiosInst = supAxios();

/**
 * API to create/update subscription banner
 * @param data payload
 * @returns createOrUpdateSubscriptionBanner  Success
 * @throws Error
 */
const createOrUpdateSubscriptionBanner = async (data: any): Promise<any> => {
  const url = "subscription"; // createOrUpdateSubscriptionBanner url
  try {
    const res = await axiosInst({
      method: "PUT",
      url,
      data,
    });
    if (res.status) {
      return res;
    }
    throw new Error(res.statusText);
  } catch (err) {
    console.log(err);
    return err;
  }
};

/**
 * API to get details of subscription banner
 * @returns details of subscription banner Success
 * @throws Error
 */
const getSubscriptionBanner = async (): Promise<any> => {
  const url = `subscription`; // url
  try {
    const res: AxiosResponse<any> = await axiosInst({
      method: "GET",
      url,
    });
    if (res.status) {
      return res;
    }
    throw new Error(res.statusText);
  } catch (err) {
    console.log(err);
    return err;
  }
};

export { createOrUpdateSubscriptionBanner, getSubscriptionBanner };
