import React, { useState, useContext, useEffect, Fragment } from "react";
import { OrdersContext } from "../OrdersContext";
import uploadImageFile from "../../../../../assets/img/icon/upload/photos.svg";
import PreServiceImageModal from "./PreServiceImageModal";
import configData from "../../../../../config.json";
import {
  saveOrderInspection,
  getOrderInspection,
} from "../../../../../api/services/sap/order-management";
import styled from "styled-components";

const PreServiceInspectionTab = () => {
  //Form state
  const [formsubmit, setFormSubmit] = useState(false);
  const [preform, setPreForm] = useState([]);
  const [error, setError] = useState(false);
  const [editView, setEditView] = useState(false);
  const [preview, setPreview] = useState(true);
  const [isValid, setIsValid] = useState(true);
  const [testWindow, setTestWindow] = useState([]);
  const {
    innertab,
    preModal,
    setPreServicePic,
    handleShow,
    mappedOrderData,
    previewonly,
    setPreViewOnly,
    preFormWindow,
    setPreFormWindow,
    preInitial,
    preformvalues,
    setPreFormValues,
    needImage,
    setNeedImage,
  } = useContext(OrdersContext);

  useEffect(() => {
    // setPreFormValues({
    //   ...preformvalues,
    //   customer_note: "",
    //   sap_note: "",
    //   orderInspectionComment: [],
    //   order_inspection_id_pk: "",
    // });
    setPreViewOnly(false);
    const data = getOrderInspection(mappedOrderData.order_id_pk, "pre");
    data
      .then((res) => {
        if (res) {
          setPreFormValues(res);
          if (Object.keys(res.orderInspectionComment).length) {
            setPreFormWindow([]);
          }
          setPreViewOnly(true);
          setEditView(false);
        }
      })
      .catch((_err) => {
        setPreFormWindow([preInitial]);
        setPreViewOnly(false);
        setEditView(true);
      });
  }, []);

  const ErrorHandling = (value, dataindex) => {
    if (value === "") {
      setError(true);
    }
  };
  //image note handler
  const imageNoteHandler = (value, dataIndex, type) => {
    if (type === "edit") {
      let newVar = preformvalues.orderInspectionComment.map(
        (predata, index) => {
          if (index === dataIndex) {
            predata.description = value;
          }
          return predata;
        }
      );

      setPreFormValues({ ...preformvalues, orderInspectionComment: newVar });
    }
    if (type === "add") {
      let description = preFormWindow.map((desc, index) => {
        if (index === dataIndex) {
          desc.description = value;
          desc.index = dataIndex;
          desc.error = "success";
        }
        return desc;
      });
      setPreFormWindow(description);
    }
  };

  //preFormSubmitHandler
  const preFormSubmitHandler = (e) => {
    e.preventDefault();
    setFormSubmit(true);
    setPreViewOnly(true);
    let finalArray =
      preFormWindow && preformvalues.orderInspectionComment
        ? [...preformvalues.orderInspectionComment, ...preFormWindow]
        : preFormWindow
        ? [...preFormWindow]
        : preformvalues.orderInspectionComment
        ? [...preformvalues.orderInspectionComment]
        : [];
    const preFormData = saveOrderInspection({
      ...preformvalues,
      orderInspectionComment: finalArray,
      type: "pre",
      order_id_fk: mappedOrderData.order_id_pk,
      order_inspection_id_pk: preformvalues.order_inspection_id_pk,
    });

    preFormData.then((res) => {
      console.log(res, "...............res pre");

      setPreFormValues(res);
      setPreServicePic([]);
      setPreFormWindow([]);
      setEditView(false);
    });
  };

  const handleaddclick = () => {
    setPreFormWindow([...preFormWindow, preInitial]);
  };
  const handleremove = (index) => {
    let list =
      preFormWindow?.length && preFormWindow.filter((v, i) => i !== index);

    setPreFormWindow(list ?? []);
  };

  console.log(preformvalues, "chakachaka");
  // const Imageremove = (index) => {
  //   const updatedPreFormWindow = preFormWindow.map((item, i) => {
  //     if (i === index) {
  //       return {
  //         ...item,
  //         inspection_image: "",
  //       };
  //     }
  //     return item;
  //   });

  //   setPreFormWindow(updatedPreFormWindow);
  // };
  // const PreFormValueImageremove = (index) => {
  //   const newOrderInspectionComment = preformvalues.orderInspectionComment.map(
  //     (item, i) => {
  //       if (i === index) {
  //         return { ...item, image_url: "" };
  //       }
  //       return item;
  //     }
  //   );
  //   setPreFormValues({
  //     ...preformvalues,
  //     orderInspectionComment: newOrderInspectionComment,
  //   });
  // };

  const preformHandleRemove = (index) => {
    // console.log(index, "image id");
    const deleteRow = preformvalues.orderInspectionComment.filter(
      (v, i) => i !== index
    );
    console.log(deleteRow, "deleteRow...........");
    setPreFormValues({ ...preformvalues, orderInspectionComment: deleteRow });
    if (deleteRow?.length === 0) {
      setPreFormWindow([
        ...preFormWindow,
        {
          description: "",
          index: "",
          inspection_image: "",
          error: "empty",
        },
      ]);
    }
  };
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(false);
  useEffect(() => {
    setIsSaveButtonDisabled(
      !preformvalues.orderInspectionComment?.some(
        (comment) =>
          comment.description &&
          comment?.description.trim()?.length > 0 &&
          !error
      )
    );
  }, [preformvalues]);

  useEffect(() => {
    const hasEmptyInspectionImage = preFormWindow?.some(
      (item) => item.inspection_image === ""
    );
    const hasEmptyDescription = preFormWindow?.some(
      (item) => item.description === ""
    );
    const hasEmptyInspectionImageInpreformvalues =
      preformvalues.orderInspectionComment?.some(
        (item) => item.inspection_image === ""
      );
    const hasEmptyDescriptionInpreformvalues =
      preformvalues.orderInspectionComment?.some(
        (item) => item.description === ""
      );
    if (
      hasEmptyInspectionImageInpreformvalues &&
      hasEmptyDescriptionInpreformvalues
    ) {
      setIsValid(true);
    } else if (
      hasEmptyInspectionImageInpreformvalues &&
      !hasEmptyDescriptionInpreformvalues
    ) {
      setIsValid(true);
    } else if (
      !hasEmptyInspectionImageInpreformvalues &&
      hasEmptyDescriptionInpreformvalues
    ) {
      setIsValid(true);
    } else if (hasEmptyInspectionImage && hasEmptyDescription) {
      setIsValid(true);
    } else if (hasEmptyInspectionImage && !hasEmptyDescription) {
      setIsValid(true);
    } else if (!hasEmptyInspectionImage && hasEmptyDescription) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [preFormWindow, preModal, preformvalues]);

  return (
    <Wrapper
      className={innertab === 1 ? "show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <div className="inner-block order-inner-block">
        <Fragment>
          <div className="head-title">
            <h6>
              Kindly check vehicle to look for spots, patches or any belongings
            </h6>
            {!editView &&
            preformvalues &&
            Object.keys(preformvalues)?.length > 0 ? (
              <div class="col-12 text-end p-0 mt-3">
                <button
                  class="btn btn-update"
                  onClick={(_e) => {
                    if (preformvalues?.orderInspectionComment?.length == 0) {
                      setPreFormWindow([
                        ...preFormWindow,
                        {
                          description: "",
                          index: "",
                          inspection_image: "",
                          error: "empty",
                        },
                      ]);
                    }
                    setPreViewOnly(false);
                    setPreview(false);
                    setEditView(true);
                  }}
                >
                  <i class="fal fa-pen mr-2"></i>Edit
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
          <form onSubmit={preFormSubmitHandler}>
            {/* preservice pic block */}
            <div className="form-box">
              <div>
                {preformvalues?.orderInspectionComment?.map((data, index) => {
                  return (
                    <Fragment>
                      <DescriptionWrapper>
                        <div className="col-12 p-0">
                          <div className="input-list-row">
                            <div className="col-main-list">
                              <div class="col-md-2 p-0 text-left">
                                {/* {data.inspection_image != "" && ( */}
                                {data.image_url === "" ||
                                data.image_url === null ? (
                                  <div className="small-block col-12 p-0">
                                    <button
                                      disabled={
                                        !editView ||
                                        (!editView &&
                                          preformvalues &&
                                          Object?.keys(preformvalues)?.length >
                                            0)
                                          ? true
                                          : false
                                      }
                                      type="button"
                                      className="btn-image"
                                      onClick={handleShow}
                                    >
                                      <div className="upload-section justify-content-center">
                                        <div className="upload-img">
                                          <img
                                            src={uploadImageFile}
                                            alt="loading..."
                                          />
                                        </div>
                                      </div>
                                      {data.image_url === "" ||
                                      (data.image_url == null && editView)
                                        ? "Upload"
                                        : "No Image"}
                                    </button>
                                  </div>
                                ) : (
                                  <ImageUploadWrapper>
                                    <div
                                      className="col-12 col-img-list img-150"
                                      key={index}
                                    >
                                      <div className="img-list">
                                        <div className="choosed-file-block img-150">
                                          <div className="pdf-block imgBlock">
                                            <img
                                              src={`${configData.SERVER_URL}/uploads/order-inspection-images/${data.image_url}`}
                                              className="img-fluid"
                                              alt=""
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <button
                                      disabled={!editView}
                                      className={
                                        editView ? "close-btn" : "read-btn"
                                      }
                                      onClick={() => {
                                        PreFormValueImageremove(index);
                                      }}
                                    >
                                      <i class="fal fa-times"></i>
                                    </button> */}
                                  </ImageUploadWrapper>
                                )}

                                {/* )} */}
                              </div>
                              <div class="col-md-10 text-wrapper">
                                <div class="form-group m-0">
                                  <textarea
                                    readOnly={!editView ? true : false}
                                    type="text"
                                    className="form-control img-text"
                                    placeholder="Description*"
                                    onBlur={(e) => {
                                      ErrorHandling(e.target.value, index);
                                    }}
                                    onChange={(e) => {
                                      imageNoteHandler(
                                        e.target.value,
                                        index,
                                        "edit"
                                      );
                                      ErrorHandling(e.target.value, index);
                                    }}
                                    value={data?.description}
                                  ></textarea>
                                </div>
                                {editView &&
                                  data?.description?.length === 0 && (
                                    <span className="validation-label">
                                      This field cannot be empty
                                    </span>
                                  )}
                              </div>
                            </div>

                            {/* {editView && Object.keys(preformvalues).length > 0 ? ( */}
                            {editView && index !== 0 ? (
                              <div className="button-remove-content">
                                <span
                                  onClick={() => preformHandleRemove(index)}
                                >
                                  <span>
                                    <i class="fal fa-times"></i>
                                  </span>
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </DescriptionWrapper>
                    </Fragment>
                  );
                })}
              </div>

              {/* for BASE64 */}
              <div>
                {preFormWindow &&
                  preFormWindow.map((data, index) => {
                    return (
                      <Fragment>
                        <DescriptionWrapper>
                          <div className="col-12 p-0">
                            <div className="input-list-row">
                              <div className="col-main-list">
                                <div class="col-md-2 text-left p-0">
                                  {data.inspection_image == "" && (
                                    <div className="small-block col-12 p-0">
                                      <button
                                        // disabled={previewonly ? true : false}
                                        type="button"
                                        className="btn-image"
                                        onClick={handleShow}
                                      >
                                        <div className="upload-section justify-content-center">
                                          <div className="upload-img">
                                            <img
                                              src={uploadImageFile}
                                              alt="loading..."
                                            />
                                          </div>
                                        </div>
                                        Upload
                                      </button>
                                    </div>
                                  )}

                                  {/* for BASE64 */}
                                  {data.inspection_image != "" && (
                                    <ImageUploadWrapper>
                                      <div
                                        className="col-12 col-img-list img-150 p-0 image-loaded"
                                        key={index}
                                      >
                                        <div className="img-list">
                                          <div className="choosed-file-block img-150">
                                            <div className="pdf-block">
                                              <img
                                                src={
                                                  data.inspection_image.base64
                                                }
                                                className="img-fluid"
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      {/* <button
                                        className="close-btn"
                                        onClick={() => {
                                          Imageremove(index);
                                        }}
                                      >
                                        <i class="fal fa-times"></i>
                                      </button> */}
                                    </ImageUploadWrapper>
                                  )}
                                </div>
                                {/* textarea */}
                                <div class="col-md-10 text-wrapper">
                                  <div class="form-group m-0">
                                    {/* <label for="" class="form-text text-start text-info">Description <sup className="theme-text">*</sup></label> */}
                                    <textarea
                                      // readOnly={!editView ? true : false}
                                      type="text"
                                      placeholder="Descripton*"
                                      className="form-control img-text"
                                      onBlur={(e) => {
                                        ErrorHandling(e.target.value, index);
                                      }}
                                      value={data.description}
                                      onChange={(e) => {
                                        imageNoteHandler(
                                          e.target.value,
                                          index,
                                          "add"
                                        );
                                        ErrorHandling(e.target.value, index);
                                      }}
                                    ></textarea>
                                  </div>
                                  {error === true &&
                                    editView &&
                                    data?.description === "" && (
                                      <span className="validation-label">
                                        This field is required
                                      </span>
                                    )}
                                </div>
                              </div>
                              {editView &&
                                (preformvalues?.orderInspectionComment?.length >
                                  0 ||
                                  index !== 0) && (
                                  <div className="button-remove-content">
                                    <span onClick={() => handleremove(index)}>
                                      <span>
                                        <i className="fal fa-times"></i>
                                      </span>
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                        </DescriptionWrapper>
                      </Fragment>
                    );
                  })}
              </div>

              {editView &&
              !preFormWindow.find((data) => data.error === "empty") &&
              !preFormWindow.find((data) => data.description === "") &&
              !preFormWindow.find((data) => data.inspection_image === "") ? (
                <div class="form-group button-add mt-3 text-right">
                  <button
                    type="button"
                    className="btn"
                    onClick={handleaddclick}
                  >
                    <i className="fal fa-plus"></i> Add more
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
            <p className=" mb-3 text-start text-primary">
              Add photos or comments of anything you find
            </p>
            {/* SAP Notes */}
            <div class="input-group input-group-custom">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-pen"></i>SAP Notes
                </span>
              </div>
              <div className="input-block">
                <textarea
                  readOnly={
                    !editView && Object?.keys(preformvalues)?.length > 0
                      ? true
                      : false
                  }
                  class="form-control"
                  placeholder="Notes from SAP"
                  onChange={(e) => {
                    setPreFormValues({
                      ...preformvalues,
                      sap_note: e.target.value,
                    });
                  }}
                  value={
                    preformvalues && preformvalues.sap_note
                      ? preformvalues.sap_note
                      : ""
                  }
                ></textarea>
                {/* <span className="form-error">Address is required!</span> */}
              </div>
            </div>
            {/* Customer Notes */}
            <div class="input-group input-group-custom">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fal fa-pen"></i>Customer Notes
                </span>
              </div>
              <div className="input-block">
                <textarea
                  readOnly={
                    !editView && Object?.keys(preformvalues)?.length > 0
                      ? true
                      : false
                  }
                  class="form-control"
                  placeholder="Notes from Customer"
                  onChange={(e) => {
                    setPreFormValues({
                      ...preformvalues,
                      customer_note: e.target.value,
                    });
                  }}
                  value={
                    preformvalues && preformvalues.customer_note
                      ? preformvalues.customer_note
                      : ""
                  }
                ></textarea>
                {/* <span className="form-error">Address is required!</span> */}
              </div>
            </div>
            {/* {previewonly ? */}

            {editView ? (
              <div class="col-12 text-end p-0 mt-3">
                <button class="btn btn-update" disabled={isValid} type="submit">
                  <i class="fal fa-save mr-2"></i>Save
                </button>
              </div>
            ) : (
              ""
            )}
          </form>
          <PreServiceImageModal />
        </Fragment>
      </div>
    </Wrapper>
  );
};

export default PreServiceInspectionTab;
const Wrapper = styled.div`
  .button-remove-content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: -8px;
    border: 1px solid #c7bebe;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: #ecebeb;
    line-height: initial;
    color: #dc3545;
    cursor: pointer;
  }

  .fal {
    font-size: 14px;
    margin-left: 1px;
  }
`;
const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  .text-wrapper {
    padding-left: 10px !important;
    padding-right: 0px !important;
  }
  .validation-label {
    position: absolute;
    color: #f44336;
    font-size: 12px;
    left: 0px;
    padding-left: 10px;
  }
`;
const ImageUploadWrapper = styled.div`
  .image-loaded {
    position: relative;
  }
  .close-btn {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 20px !important;
    color: #ffffff !important;
    font-weight: lighter;
    border: 1px solid red; //none
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(71, 71, 71, 0.149);
  }
  .read-btn {
    display: none;
  }
`;
