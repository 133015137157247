import React from "react";
// import  '../../../assets/css/bootstrap.min.css';
import "../../../assets/fontawesome/css/fontawesome-all.css";
import "../../../assets/css/admin-custom.css";
import { Scrollbars } from "react-custom-scrollbars-2";
import LogoColor from "./assets/img/motorque-logo.svg";
import "./Ui/css/NavToggle.css";
import { NavLink, useLocation } from "react-router-dom";
import DiscountIcon from "@mui/icons-material/Discount";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import { ReactComponent as ProductIcon } from "./assets/img/productIcon.svg";
import styled from "styled-components";
import { List } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";

const Sidebar = () => {
  const location = useLocation();
  const [expandedIndex, setExpandedIndex] = React.useState(undefined);

  const handleClick = (index) => {
    setExpandedIndex(expandedIndex !== index ? index : undefined);
  };
  React.useEffect(() => {
    const currentItemIndex = menuItems.findIndex((item) =>
      item?.subMenus?.some((subitem) => subitem.path === location.pathname)
    );
    setExpandedIndex(currentItemIndex);
  }, [location.pathname]);
  const menuItems = [
    // {
    //   path: "/dashboard",
    //   name: "Dashboard",
    //   icon: <i class="fal fa-user"></i>,
    // },
    {
      path: "/service",
      name: "Service",
      icon: <i class="fal fa-taxi"></i>,
    },
    {
      path: "/staff",
      name: "Staff",
      icon: <i className="fal fa-users"></i>,
    },
    {
      path: "/bookings",
      name: "Bookings",
      icon: <i className="fal fa-calendar"></i>,
    },
    {
      path: "/orders",
      name: "Orders",
      icon: <i class="fal fa-tasks"></i>,
    },
    {
      path: "/customer",
      name: "Customer",
      icon: <i class="fal fa-user"></i>,
    },
    {
      path: "/product",
      name: "Inventory",
      icon: <ProductIcon />,
    },
    {
      path: "",
      name: "App",
      icon: <AppSettingsAltIcon />,
      subMenus: [
        {
          name: "Offers",
          path: "/app/offers",
          icon: <DiscountIcon />,
        },
        {
          name: "Featured",
          path: "/app/featured",
          icon:  <NewReleasesIcon />,
        },
      ],
    },
  ];

  return (
    <div className="sidebar-block admin">
      <div className="menu-side">
        <div className="sidebar-min-head text-center">
          <div className="sidebar-logo">
            <span className="logo">
              <img
                className="img-fluid"
                src={LogoColor}
                style={{ height: "120px", objectFit: "cover" }}
                alt="Motorque logo"
              />
            </span>
          </div>
        </div>
        <Scrollbars style={{ height: `calc(100vh - 107px)` }}>
          <div style={{ height: "100%" }} className="menu-scroll">
            <ul className="list-sidebar bg-defoult menu-list">
              {menuItems.map((item, index) => {
                if (!item?.subMenus) {
                  return (
                    <li>
                      <NavLink
                        to={item.path}
                        key={index}
                        className="link "
                        activeClassName="active"
                      >
                        <div className="nav-label">
                          <span className="mr-1 icon-span">{item.icon}</span>
                          <span className="span-name">{item.name}</span>
                        </div>
                      </NavLink>
                    </li>
                  );
                } else {
                  return (
                    <StyledList
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                        padding: 0,
                      }}
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      key={item?.name}
                      className={`styled-list ${
                        expandedIndex === index ? "expanded" : ""
                      }`}
                    >
                      <ListItemButton onClick={() => handleClick(index)}>
                        <ListItemIcon>{item?.icon}</ListItemIcon>
                        <ListItemText primary={item?.name} />
                        {expandedIndex === index ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={expandedIndex === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {item?.subMenus?.map((subitem, i) => {
                            return (
                              <ListItemButton
                                className={
                                  subitem?.path === location?.pathname
                                    ? "active-navlink"
                                    : ""
                                }
                                sx={{ pl: 4 }}
                                key={subitem?.name}
                              >
                                <NavLink
                                  to={subitem?.path}
                                  key={subitem?.name}
                                  className="link "
                                  activeClassName="active"
                                >
                                  <div className="nav-label">
                                    <span className="mr-1 icon-span">
                                      {subitem?.icon}
                                    </span>
                                    <span className="span-name">
                                      {subitem?.name}
                                    </span>
                                  </div>
                                </NavLink>
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </Collapse>
                    </StyledList>
                  );
                }
              })}
            </ul>
          </div>
        </Scrollbars>
        <div className="bottom-action">
          <button class="btn btn-faq">FAQS</button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
const StyledList = styled(List)`
  width: 100% !important;
  border-top: 1px solid #084b7f;
  border-bottom: 1px solid #084b7f;
  background: #01579b !important;
  color: #ffffff;
  margin: 2px 0px;
  &.expanded {
    padding: 0px 0px 8px 0px !important;
  }
  &.styled-list {
    > .MuiListItemButton-root {
      padding-left: 28px;
      padding-right: 20px;
      .MuiListItemIcon-root {
        min-width: 30px;
        margin-right: 0.25rem !important;
      }
    }
  }
  .MuiListItemButton-root {
    color: #ffffff;
    a {
      color: #ffffff;
      .span-name {
        font-size: 14px;
        padding: 0 0 0 8px;
      }
    }
  }
  .MuiList-root {
    width: 100% !important;
  }
  .MuiSvgIcon-root {
    color: #ffffff;
  }
  .MuiCollapse-wrapperInner {
    .MuiList-root {
      .MuiListItemButton-root {
        padding: 0 8px 0 42px;
        &.active-navlink {
          a {
            border-radius: 6px;
            background: #0277bd;
          }
        }
      }
    }
  }
`;
