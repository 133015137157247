import React, {
  useState,
  useContext,
  useEffect,
  Fragment,
  useRef,
} from "react";
import { OrdersContext } from "../OrdersContext";
import {
  Dropdown,
  DropdownButton,
  Accordion,
  Button,
  useAccordionButton,
  Card,
  Stack,
  Row,
  Col,
} from "react-bootstrap";
import dateFormat from "dateformat";
import Multiselect from "multiselect-react-dropdown";
import ReactTooltip from "react-tooltip-rc";
import TimePicker from "react-time-picker";
import img from "../../../../../assets/img/icon/user.png";
import {
  assignStaffs,
  orderStatusUpdate,
  trackServiceTime,
  saveEstimatedTime,
} from "../../../../../api/services/sap/order-management";
import styled from "styled-components";
// import AssigneePopup from "../../../../AssigneePopup/index";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const ServiceTab = () => {
  const {
    innertab,
    mappedOrderData,
    setStartTime,
    setPauseTime,
    staffTable,
    viewHandler,
    preformvalues,
    setPreFormValues,
    viewId,
  } = useContext(OrdersContext);

  const [dropValue, setDropValue] = useState("");
  const [mappedServicesData, setMappedServicesData] = useState([]);
  const [mappedStaffData, setMappedStaffData] = useState([]);
  const [staffDetails, setStaffDetails] = useState([...staffTable]);
  const [showAllAssignees, setShowAllAssignees] = useState(false);
  const [show, setShow] = useState(false);
  const [hours, setHours] = useState("");
  const [minutes, setMinutes] = useState("");
  const [estimatedTimes, setEstimatedTimes] = useState(
    mappedServicesData.map(() => ({ hours: "", minutes: "" }))
  );

  const statusChange = (e) => {
    let statusValue = e.target.getAttribute("statusValue");
    let statusData = {
      order_id_pk: mappedOrderData.order_id_pk,
      status: statusValue,
    };
    const statusUpdateData = orderStatusUpdate(statusData);
    statusUpdateData.then((response) => {
      viewHandler(mappedOrderData.order_id_pk);
    });
  };

  // const staffDetails = [...staffTable];

  useEffect(() => {
    const mappedServices =
      mappedOrderData &&
      mappedOrderData.orderServices.map((item) => ({ ...item }));
    const mappedStaff = mappedServices.map((e) => [...e.orderServicesStaff]);
    setMappedServicesData(mappedServices);
    setMappedStaffData(mappedStaff);
    setEstimatedTimes(
      mappedServices.map((item) => {
        const [hours, minutes] = item.estimated_time
          ? item.estimated_time.split(":")
          : ["", ""];
        return { hours, minutes };
      })
    );
  }, [mappedOrderData]);

  const multiselectRef = useRef([]);

  const handleSelectStaff = (index, element, serviceId, value) => {
    let staffAdd = {
      order_service_id_fk: serviceId,
      staff_id_fk: element,
      deleted: value,
    };
    const staffAddData = assignStaffs(staffAdd);

    staffAddData.then((response) => {
      viewHandler(mappedOrderData.order_id_pk);
      //commented::this might be used later
      // if (mappedStaffData[index].length === 1 && value === "1") {
      //   setMappedStaffData({ ...mappedStaffData, ...response });
      // }
    });
  };

  const handleCloseStaff = (index, staffId, serviceId) => {
    // Call handleSelectStaff with the appropriate parameters
    handleSelectStaff(index, staffId, serviceId, "1");
  };

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey, () =>
      console.log("")
    );
    return (
      <div className="block-click" onClick={decoratedOnClick}>
        {children}
      </div>
    );
  };

  const trackTiming = (timeType, order_service) => {
    let timeAdd = {
      order_service_id_fk: order_service,
      type: timeType,
    };
    const timeData = trackServiceTime(timeAdd);
    timeData.then((response) => {
      viewHandler(mappedOrderData.order_id_pk);
    });
  };

  const handleEstimatedTimeChange = (index, hours, minutes) => {
    setEstimatedTimes((prevEstimatedTimes) => {
      const newEstimatedTimes = [...prevEstimatedTimes];
      let adjustedHours = parseInt(hours) || 0;
      let adjustedMinutes = parseInt(minutes) || 0;

      if (adjustedMinutes >= 60) {
        const additionalHours = Math.floor(adjustedMinutes / 60);
        adjustedHours += additionalHours;
        adjustedMinutes = adjustedMinutes % 60;
      }

      const paddedHours = adjustedHours.toString().padStart(2, "0");
      const paddedMinutes = adjustedMinutes.toString().padStart(2, "0");
      newEstimatedTimes[index] = { hours: paddedHours, minutes: paddedMinutes };
      return newEstimatedTimes;
    });
  };

  const estimatedTimeHandler = (orderServiceId, index, hours, minutes) => {
    let adjustedHours = parseInt(hours) || 0;
    let adjustedMinutes = parseInt(minutes) || 0;

    if (adjustedMinutes >= 60) {
      const additionalHours = Math.floor(adjustedMinutes / 60);
      adjustedHours += additionalHours;
      adjustedMinutes = adjustedMinutes % 60;
    }

    const paddedHours = adjustedHours.toString().padStart(2, "0");
    const paddedMinutes = adjustedMinutes.toString().padStart(2, "0");
    const adjustedTime = `${paddedHours}:${paddedMinutes}`;

    const estimatedTime = {
      order_service_id_pk: orderServiceId,
      estimated_time: adjustedTime,
    };

    saveEstimatedTime(estimatedTime)
      .then((response) => {
        setMappedServicesData((prevMappedServicesData) => {
          const newMappedServicesData = [...prevMappedServicesData];
          newMappedServicesData[index].estimated_time = adjustedTime;
          return newMappedServicesData;
        });
      })
      .catch((error) => {
        // Handle error
      });
  };

  const handleHoursChange = (e, index, item) => {
    const value = e.target.value;
    if (value === "" || (value >= 0 && value < 100)) {
      const paddedValue = value === "" ? "" : String(value).padStart(2, "0"); // Ensure value is a string and pad with leading zeros
      setHours(paddedValue);
      handleEstimatedTimeChange(
        index,
        paddedValue,
        estimatedTimes[index].minutes
      );
      estimatedTimeHandler(
        item.order_service_id_pk,
        index,
        paddedValue,
        estimatedTimes[index].minutes
      ); // Pass padded values to estimatedTimeHandler
    }
  };

  const handleMinutesChange = (e, index, item) => {
    const value = e.target.value;
    if (value === "" || (value >= 0 && value < 60)) {
      const paddedValue = value === "" ? "" : String(value).padStart(2, "0"); // Ensure value is a string and pad with leading zeros
      setMinutes(paddedValue);
      handleEstimatedTimeChange(
        index,
        estimatedTimes[index].hours,
        paddedValue
      );
      estimatedTimeHandler(
        item.order_service_id_pk,
        index,
        estimatedTimes[index].hours,
        paddedValue
      ); // Pass padded values to estimatedTimeHandler
    }
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const AssigneeView = () => {
    handleShow();
  };

  return (
    <div
      className={innertab === 2 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <div className="inner-block">
        <Fragment>
          <div className="inner-stack-header" direction="horizontal" gap={2}>
            <div className="stack-card">
              <Row>
                <Col xs={12}>
                  {mappedOrderData && mappedOrderData.comments ? (
                    <textarea class="form-control textarea-custom" disabled>
                      {mappedOrderData.comments}
                    </textarea>
                  ) : (
                    ""
                  )}
                  {preformvalues && preformvalues.customer_note ? (
                    <textarea class="form-control textarea-custom" disabled>
                      {preformvalues.customer_note}
                    </textarea>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>
            </div>
          </div>
          <div className="text-end">
            <div className="status-change-block">
              <DropdownButton
                title={
                  dropValue ? dropValue : mappedOrderData.orderStatus.title
                }
                id="dropdown-menu-align-end"
              >
                <Dropdown.Item
                  eventKey="1"
                  statusValue="4"
                  onClick={statusChange}
                >
                  In Progress
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  statusValue="5"
                  onClick={statusChange}
                >
                  Pending
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  statusValue="6"
                  onClick={statusChange}
                >
                  Completed
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="5"
                  statusValue="8"
                  onClick={statusChange}
                >
                  Delivered
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="4"
                  statusValue="7"
                  onClick={statusChange}
                >
                  Ready for Delivery
                </Dropdown.Item>
              </DropdownButton>
            </div>
          </div>
          <div className="accoBlock">
            <Accordion defaultActiveKey="0">
              <div className="row w-100 m-0 header-row">
                <div className="col-md-3"></div>
                <div className="col-md-3 col-text text-left p-0">Assignee</div>
                <div className="col-md-3 col-text">Estimated Time</div>
                <div className="col-md-3 col-text">Actions</div>
              </div>

              {mappedServicesData &&
                mappedServicesData.map((item, index) => {
                  console.log(item,"mappppp")
                  return (
                    <Card >
                      <Card.Header>
                        <div className="row w-100 m-0">
                          <div className="col-md-3 p-0">
                            <CustomToggle eventKey={index}>
                              {item.service.service_name}
                            </CustomToggle>
                          </div>

                          <div className="col-md-3 p-0">
                            <CircleWrapper>
                              <div className="circle-main">
                                <div className="staff-assign">
                                  {mappedStaffData &&
                                    mappedStaffData[index]
                                      .slice(
                                        0,
                                        showAllAssignees ? undefined : 2
                                      )
                                      .map((b_item) => (
                                        <>
                                          <div
                                            className="staff-circle"
                                            rel="tooltip"
                                            data-for="staffTooltip"
                                            data-tip={b_item.name}
                                          >
                                            <ReactTooltip
                                              id="staffTooltip"
                                              place="top"
                                              type="info"
                                              effect="float"
                                            />
                                            {/* circle close */}
                                            {item.status != "Finished" && (
                                              <span
                                                className="delete-span"
                                                onClick={(e) =>
                                                  handleSelectStaff(
                                                    index,
                                                    b_item.staff_id_fk,
                                                    item.order_service_id_pk,
                                                    "1"
                                                  )
                                                }
                                              >
                                                <i className="fal fa-times"></i>
                                              </span>
                                            )}
                                            {b_item.name &&
                                              b_item.name.substring(0, 1)}
                                          </div>
                                        </>
                                      ))}
                                  {mappedStaffData[index].length > 2 &&
                                    !showAllAssignees && (
                                      <div
                                        className="staff-circle"
                                        rel="tooltip"
                                        data-for="staffTooltip"
                                        data-tip={item.name}
                                        onClick={AssigneeView}
                                      >
                                        {"+"}
                                        {mappedStaffData[index].length - 2}
                                      </div>
                                    )}
                                  <div>
                                    {" "}
                                    <>
                                      <Modal
                                        show={show}
                                        onHide={handleClose}
                                        backdrop="static"
                                        keyboard={false}
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>ASSIGNEE</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <StaffModalWrapper>
                                            {mappedStaffData &&
                                              mappedStaffData[index].map(
                                                (staff) => (
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      margin: "30px",
                                                      textTransform:
                                                        "uppercase",
                                                    }}
                                                    key={staff.id}
                                                  >
                                                    <p>{staff.name}</p>
                                                    <Button
                                                      // variant="danger"
                                                      onClick={(e) =>
                                                        handleCloseStaff(
                                                          index,
                                                          staff.staff_id_fk,
                                                          item.order_service_id_pk
                                                        )
                                                      }
                                                      className="ml-auto"
                                                    >
                                                      <i class="fal fa-times"></i>
                                                    </Button>
                                                  </div>
                                                )
                                              )}
                                          </StaffModalWrapper>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button
                                            variant="secondary"
                                            onClick={handleClose}
                                          >
                                            Close
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    </>
                                  </div>
                                </div>
                                <Multiwrapper style={{ marginTop: "10px" }}>
                                  <div className="staff-list">
                                    <Multiselect
                                      options={
                                        staffDetails.filter(
                                          (itemA) =>
                                            !item?.orderServicesStaff.some(
                                              (itemB) =>
                                                itemB.staff_id_fk === itemA.id
                                            )
                                        ) ?? []
                                      }
                                      selectedValues={mappedStaffData[index]}
                                      displayValue="name"
                                      value="id"
                                      hideSelectedList
                                      avoidHighlightFirstOption
                                      closeOnSelect
                                      onSelect={(e) => {
                                        handleSelectStaff(
                                          index,
                                          Object.assign({}, ...e).id,
                                          item.order_service_id_pk,
                                          "0"
                                        );
                                      }}
                                      closeIcon="circle"
                                      isObject={true}
                                      ref={multiselectRef}
                                      name="test-name"
                                      placeholder=""
                                      className={
                                        mappedStaffData[index].map(
                                          (item) => item.length === 0
                                        )
                                          ? `placeholder-icon`
                                          : ""
                                      }
                                    />
                                  </div>
                                </Multiwrapper>
                              </div>
                            </CircleWrapper>
                          </div>

                          <div className="col-md-3 p-0">
                            <div className="estimated-time">
                              <TimeWrapper>
                                <input
                                  className="time"
                                  style={{
                                    textAlign: "right",
                                    paddingLeft: "5px",
                                  }}
                                  type="number"
                                  placeholder="Hours"
                                  value={estimatedTimes[index].hours}
                                  onChange={(e) =>
                                    handleHoursChange(e, index, item)
                                  }
                                  disabled={item.status == "Finished"}
                                />
                                <span style={{ paddingLeft: "5px" }}>:</span>
                                <input
                                  className="time"
                                  type="number"
                                  placeholder="Minutes"
                                  value={estimatedTimes[index].minutes}
                                  onChange={(e) =>
                                    handleMinutesChange(e, index, item)
                                  }
                                  disabled={item.status == "Finished"}
                                />
                              </TimeWrapper>
                            </div>
                          </div>
                          <div className="col-md-3 p-0">
                            <div className="button-block">
                              {Object.keys(mappedStaffData[index]).length !==
                                0 &&
                                item.status == null && (
                                  <Button
                                    className="ml-1 mr-1"
                                    variant="primary"
                                    size="sm"
                                    onClick={(e) => {
                                      trackTiming(
                                        "Started",
                                        item.order_service_id_pk
                                      );
                                    }}
                                  >
                                    Start
                                  </Button>
                                )}
                              {item.status == "Started" ? (
                                <>
                                  <Button
                                    className="ml-1 mr-1"
                                    variant="warning"
                                    size="sm"
                                    onClick={(e) => {
                                      trackTiming(
                                        "Paused",
                                        item.order_service_id_pk
                                      );
                                    }}
                                  >
                                    Pause
                                  </Button>
                                  <Button
                                    className="ml-1 mr-1"
                                    variant="success"
                                    size="sm"
                                    onClick={(e) => {
                                      trackTiming(
                                        "Finished",
                                        item.order_service_id_pk
                                      );
                                    }}
                                  >
                                    Finish
                                  </Button>
                                </>
                              ) : item.status == "Paused" ? (
                                <Button
                                  className="ml-1 mr-1"
                                  variant="success"
                                  size="sm"
                                  onClick={(e) => {
                                    trackTiming(
                                      "Resumed",
                                      item.order_service_id_pk
                                    );
                                  }}
                                >
                                  Resume
                                </Button>
                              ) : item.status == "Resumed" ? (
                                <>
                                  <Button
                                    className="ml-1 mr-1"
                                    variant="warning"
                                    size="sm"
                                    onClick={(e) => {
                                      trackTiming(
                                        "Paused",
                                        item.order_service_id_pk
                                      );
                                    }}
                                  >
                                    Pause
                                  </Button>
                                  <Button
                                    className="ml-1 mr-1"
                                    variant="success"
                                    size="sm"
                                    onClick={(e) => {
                                      trackTiming(
                                        "Finished",
                                        item.order_service_id_pk
                                      );
                                    }}
                                  >
                                    Finish
                                  </Button>
                                </>
                              ) : (
                                item.status == "Finished" && ""
                              )}
                              {item.status == "Finished" && (
                                <span class="badge bg-success p-2">
                                  Finished
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </Card.Header>
                      <Accordion.Collapse eventKey={index}>
                        <Card.Body>
                          <div className="progressList-card">
                            <ul className="progess-ul">
                              {item.orderServiceTracking.map((timeList) => (
                                <>
                                  {timeList.type == "Paused" ||
                                  timeList.type == "Finished" ? (
                                    <>
                                      <li>
                                        {item.orderServiceTracking.length == 1
                                          ? "Started"
                                          : "Resumed"}{" "}
                                        at{" "}
                                        {dateFormat(
                                          timeList.start_time,
                                          "h:MM tt"
                                        )}{" "}
                                        on{" "}
                                        {dateFormat(
                                          timeList.start_time,
                                          "mmmm dS"
                                        )}{" "}
                                        by {timeList.startedBy}
                                      </li>{" "}
                                      <li>
                                        {timeList.type} at{" "}
                                        {dateFormat(
                                          timeList.end_time,
                                          "h:MM tt"
                                        )}{" "}
                                        by {timeList.endedBy}
                                      </li>
                                    </>
                                  ) : (
                                    <li>
                                      {" "}
                                      {timeList.type} at{" "}
                                      {dateFormat(
                                        timeList.start_time,
                                        "h:MM tt"
                                      )}{" "}
                                      by {timeList.startedBy}
                                    </li>
                                  )}
                                </>
                              ))}
                            </ul>
                          </div>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
            </Accordion>
          </div>
        </Fragment>
      </div>
    </div>
  );
};

export default ServiceTab;
const Multiwrapper = styled.div`
  .placeholder-icon {
    input {
      background-image: url(${img}) !important;
      background-size: 20px !important;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
`;
const CircleWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  .staff-circle:nth-child(4) {
    background: #c92b4b;
    margin-top: 10px;
  }
  /* .staff-circle:nth-child(5) {
    background: #b0bc47;
  } */
  .staff-assign {
    margin-top: 10px;
  }
  .search-wrapper .searchBox {
    caret-color: transparent;
  }
`;
const StaffModalWrapper = styled.div`
  display: block;
`;

const TimeWrapper = styled.div`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .time {
    border: unset;
    max-width: 50px;
    margin-left: 4px;
  }
`;
