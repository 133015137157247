import React  from 'react';
const InnerLoader = () => {
    return(
        <div className="inner-loader">
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            </div>
    )
}

export default InnerLoader;