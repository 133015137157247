import React, { useEffect } from "react";
import PhoneNumberField from "../../../../../PhoneNumberField";
import { AdornmentTextField } from "../../../../../AdornmentTextField";
import CustomDropDown from "../../../../../CustomDropDown";
import { Button } from "@mui/material";
import RoundedWrapperCard from "../../../../../RoundedWrapperCard";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { MuiStyledTextField } from "../../../../../MuiStyledTextField";
import { FileUploadButton } from "../../../../../FileUploadButton";
import { CardHeadingLabel } from "../../../../../label";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "./assets/icons/AddIcon.svg";
import styled from "styled-components";

type Props = {
  onNextClick?: () => void;
  dataStored: any;
};

type ownerData = {
  vendor_user_id_pk?: number;
  user_type?: number;
  name: string;
  phone: string;
  phone_extension: string;
  resident_of_uae?: boolean;
};

const RegisterVendor = ({ onNextClick, dataStored }: Props) => {
  //useStates
  const [filteredOwnerData, setFilteredOwnerData] = React.useState<ownerData>({
    vendor_user_id_pk: undefined,
    user_type: undefined,
    name: "",
    phone: "",
    phone_extension: "",
    resident_of_uae: undefined,
  });
  const [filteredCoOwnerData, setFilteredCoOwnerData] = React.useState<
    Array<ownerData>
  >([]);
  const inputRefs = React.useRef<HTMLInputElement[]>([]);
  const [credentials, setCredentials] = React.useState({
    phone: undefined,
    email: "",
    phoneOtp: "",
    emailOtp: "",
  });
  const [CoOwnerData, setCoOwnerData] = React.useState<{} | null>();
  const [CoOwnerList, setCoOwner] = React.useState<Array<any>>([
    {
      id: 1,
      coOwnername: "Co-owner1",
    },
  ]);
  const [values, setValues] = React.useState<string[] | undefined>(
    new Array(4).fill("")
  );
  const handleChange = (index: number, value: string) => {
    const newValues = [...values!];
    if (values) {
      newValues[index] = value;
      setValues(newValues);
      const otp = newValues.join("");
      if (otp.length === 4) {
        // onChange?.(otp);
        // dispatch(actions.changeLoginOtp(otp));
        return;
      }
      if (value && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && values && !values[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };
  function handleSubmitClick() {
    // setOtpView(true);
    onNextClick?.();
  }

  const handlePhoneChange = (e: any) => {
    console.log(e, "handlePhoneChange");

    // setCredentials((prevState) => ({ ...prevState, phone: e }));
  };
  const handleEmailChange = (e: any) => {
    console.log(e, "handleEmailChange");
    setCredentials((prevState) => ({ ...prevState, email: e.target.value }));
  };
  const handlePhoneOtpChange = (e: any) => {
    console.log(e, "handleEmailChange");
    setCredentials((prevState) => ({ ...prevState, phoneOtp: e.target.value }));
  };
  const handleEmailOtpChange = (e: any) => {
    console.log(e, "handleEmailChange");
    setCredentials((prevState) => ({ ...prevState, emailOtp: e.target.value }));
  };

  useEffect(() => {
    //filter owner data from mapped data
    let tempOwner =
      dataStored?.owners?.filter((item: any) => {
        return item.user_type === 1;
      })?.[0] ?? {};
    setFilteredOwnerData(tempOwner);
    //filter Co-owner data from mapped data
    let tempCoOwner =
      dataStored?.owners?.filter((item: any) => {
        return item.user_type === 2;
      }) ?? [];
    setFilteredCoOwnerData(tempCoOwner);
    console.log(tempOwner, "tempOwner");
    console.log(tempCoOwner, "tempCoOwner");
  }, []);

  console.log(filteredOwnerData, "filteredOwnerData");
  console.log(filteredCoOwnerData, "filteredCoOwnerData");
  console.log(
    filteredOwnerData?.name ? filteredOwnerData.name : "DATA not founds",
    "filteredOwnerData1"
  );
  return (
    <>
      <StyledWrapper>
        {/* Registration Details */}
        <StyledSubWrapper className="StyledSubWrapper">
          <CardHeadingLabel className="CardHeadingLabel">
            Registration Details
          </CardHeadingLabel>
          <RoundedWrapperCard className="RoundedWrapperCard sup-view">
            <StyledGridWrapper
              container
              spacing={2}
              sx={{
                padding: "18px 16px 8px 16px",
              }}
            >
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <MuiStyledTextField
                  validations="required"
                  label="Country of Operation "
                  disabled={true}
                  value={dataStored?.country ?? "No data available"}
                  // placeholder="Enter your business name"
                  className="sup"
                  // validations="required"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className="">
                <PhoneNumberField
                  validations="required"
                  label="Mobile Number"
                  placeholder="Enter your company’s official mobile number"
                  disabled
                  value={dataStored?.phone ?? "No data available"}
                />
              </Grid>
              {/* <Grid item xs={6}>
              <MuiStyledTextField
                label="Official Website (if any)"
                placeholder="Enter your website URL here"
                className="background-gray"
              />
            </Grid> */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="styled-PhoneNumberField"
              >
                <MuiStyledTextField
                  validations="required"
                  label="E-mail ID"
                  disabled={true}
                  value={dataStored?.email ?? "No data available"}
                  // placeholder="Enter your business name"
                  className="sup"
                />
              </Grid>
            </StyledGridWrapper>
          </RoundedWrapperCard>
        </StyledSubWrapper>
        {/* Business Details */}
        <StyledSubWrapper className="StyledSubWrapper">
          <CardHeadingLabel className="CardHeadingLabel">
            Business Details
          </CardHeadingLabel>
          <RoundedWrapperCard className="RoundedWrapperCard sup-view">
            <StyledGridWrapper
              container
              spacing={2}
              sx={{
                padding: "18px 16px 8px 16px",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="styled-PhoneNumberField"
              >
                <MuiStyledTextField
                  validations="required"
                  label="Business/Company Trade Name "
                  disabled={true}
                  value={dataStored?.company_name ?? "No data available"}
                  // placeholder="Enter your business name"
                  className="sup"
                  // validations="required"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="styled-PhoneNumberField"
              >
                <MuiStyledTextField
                  label="Official Website (if any)"
                  disabled={true}
                  value={dataStored?.url ?? "No data available"}
                  // placeholder="Enter your business name"
                  className="sup"
                  // validations="required"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className="">
                <PhoneNumberField
                  validations="required"
                  label="Official Contact Number"
                  placeholder="Enter official mobile number"
                  disabled
                  value={dataStored?.official_phone ?? "No data available"}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="styled-PhoneNumberField"
              >
                <MuiStyledTextField
                  validations="required"
                  label="Official E-mail ID"
                  disabled={true}
                  value={dataStored?.official_email ?? "No data available"}
                  // placeholder="Enter your business name"
                  className="sup"
                  // validations="required"
                />
              </Grid>

              <Grid item xs={12}>
                <RadioGroupWrapper>
                  <FormControl>
                    <FieldLabel>
                      Bussiness Model
                      <span style={{ color: "red" }}>*</span>
                    </FieldLabel>
                    <RadioButtonWrapper className="RadioButtonWrapper">
                      <FieldLabel className="field-value">
                        Bussiness-To-Bussiness(B2B/Wholesale)
                      </FieldLabel>
                    </RadioButtonWrapper>
                  </FormControl>
                </RadioGroupWrapper>
              </Grid>
            </StyledGridWrapper>
          </RoundedWrapperCard>
        </StyledSubWrapper>
        {/* Banking Details */}
        <StyledSubWrapper className="StyledSubWrapper">
          <CardHeadingLabel className="CardHeadingLabel">
            Banking Details
          </CardHeadingLabel>
          <RoundedWrapperCard className="RoundedWrapperCard sup-view">
            <StyledGridWrapper
              container
              spacing={2}
              sx={{
                padding: "18px 16px 8px 16px",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="styled-PhoneNumberField"
              >
                <MuiStyledTextField
                  validations="required"
                  label="Account Holder Name"
                  disabled={true}
                  value={
                    dataStored?.banking?.account_holder_name ??
                    "No data available"
                  }
                  // placeholder="Enter your business name"
                  className="sup"
                  // validations="required"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="styled-PhoneNumberField"
              >
                <MuiStyledTextField
                  validations="required"
                  label="IBAN Number"
                  disabled={true}
                  value={dataStored?.banking?.iban ?? "No data available"}
                  // placeholder="Enter your business name"
                  className="sup"
                  // validations="required"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="styled-PhoneNumberField"
              >
                <MuiStyledTextField
                  validations="required"
                  label="Account Number"
                  disabled={true}
                  value={
                    dataStored?.banking?.account_number ?? "No data available"
                  }
                  // placeholder="Enter your business name"
                  className="sup"
                  // validations="required"
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="styled-PhoneNumberField"
              >
                <MuiStyledTextField
                  validations="required"
                  label="Bank Name"
                  disabled={true}
                  value={dataStored?.banking?.bank_name ?? "No data available"}
                  // placeholder="Enter your business name"
                  className="sup"
                  // validations="required"
                />
              </Grid>
            </StyledGridWrapper>
          </RoundedWrapperCard>
        </StyledSubWrapper>
        {/* Owner Details */}
        <StyledSubWrapper className="StyledSubWrapper">
          <CardHeadingLabel className="CardHeadingLabel">
            Owner(s) Details
          </CardHeadingLabel>
          <RoundedWrapperCard className="RoundedWrapperCard sup-view">
            <StyledGridWrapper
              container
              spacing={2}
              sx={{
                padding: "18px 16px 8px 16px",
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                className="styled-PhoneNumberField"
              >
                <MuiStyledTextField
                  validations="required"
                  label="Primary Owner Name"
                  disabled={true}
                  value={
                    filteredOwnerData?.name
                      ? filteredOwnerData.name
                      : "No data available"
                  }
                  // placeholder="Enter your business name"
                  className="sup"
                  // validations="required"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} className=" ">
                <PhoneNumberField
                  validations="required"
                  label="Contact Number"
                  disabled={true}
                  value={
                    filteredOwnerData?.phone_extension
                      ? filteredOwnerData.phone_extension
                      : +filteredOwnerData?.phone
                      ? filteredOwnerData.phone
                      : "No data available"
                  }
                  // placeholder="Enter your business name"
                  className="sup"
                  // validations="required"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={6} lg={6}>
                <RadioGroupWrapper>
                  <FormControl>
                    <FieldLabel>
                      Resident of United Arab Emirates (UAE)
                      <span style={{ color: "red" }}>*</span>
                    </FieldLabel>
                    <RadioButtonWrapper className="RadioButtonWrapper">
                      <FieldLabel className="field-value">
                        {filteredOwnerData?.resident_of_uae
                          ? "Yes, owner is a resident of UAE"
                          : "No, I do not reside in UAE"}
                      </FieldLabel>
                    </RadioButtonWrapper>
                  </FormControl>
                </RadioGroupWrapper>
              </Grid>
            </StyledGridWrapper>
          </RoundedWrapperCard>
        </StyledSubWrapper>
        {filteredCoOwnerData &&
          filteredCoOwnerData.length > 0 &&
          filteredCoOwnerData?.map((data, index) => {
            console.log(data, "dataaaaaaaaaa");
            return (
              <StyledSubWrapper className="StyledSubWrapper">
                <CardHeadingLabel className="CardHeadingLabel">
                  Co-owner {`( ${index + 1} )`} Details
                </CardHeadingLabel>
                <RoundedWrapperCard className="RoundedWrapperCard sup-view">
                  <StyledGridWrapper
                    container
                    spacing={2}
                    sx={{
                      padding: "18px 16px 8px 16px",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={6}
                      className="styled-PhoneNumberField"
                    >
                      <MuiStyledTextField
                        validations="required"
                        label="Co-owner Name"
                        disabled={true}
                        value={data?.name ? data.name : "No data available"}
                        // placeholder="Enter your business name"
                        className="sup"
                        // validations="required"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className=" ">
                      <PhoneNumberField
                        validations="required"
                        label="Contact Number"
                        disabled={true}
                        value={
                          data?.phone_extension
                            ? data.phone_extension
                            : +data?.phone
                            ? data.phone
                            : "No data available"
                        }
                        placeholder="Contact Number"
                        className="sup"
                        // validations="required"
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <RadioGroupWrapper>
                        <FormControl>
                          <FieldLabel>
                            Resident of United Arab Emirates (UAE)
                            <span style={{ color: "red" }}>*</span>
                          </FieldLabel>
                          <RadioButtonWrapper className="RadioButtonWrapper">
                            <FieldLabel className="field-value">
                              {data.resident_of_uae
                                ? "Yes, owner is a resident of UAE"
                                : "No, I do not reside in UAE"}
                            </FieldLabel>
                          </RadioButtonWrapper>
                        </FormControl>
                      </RadioGroupWrapper>
                    </Grid>
                  </StyledGridWrapper>
                </RoundedWrapperCard>
              </StyledSubWrapper>
            );
          })}
        {/* 
        <SubmitBtnWrapper className="SubmitBtnWrapper">
          <StyledButton onClick={handleSubmitClick} variant="contained">
            proceed to next step
          </StyledButton>
        </SubmitBtnWrapper> */}
      </StyledWrapper>
    </>
  );
};

export default RegisterVendor;

//new
const StyledWrapper = styled.div`
  display: "block";
  @media (max-width: 992px) {
    .RoundedWrapperCard {
      margin: 0 !important;
    }
  }
`;
const RadioGroupWrapper = styled.div`
  display: "block";
  text-align: left;
`;
const RadioButtonWrapper = styled.div`
  padding: 0 20px;
  .MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: 600;
  }
  label {
    margin-bottom: 0;
  }
  .MuiButtonBase-root {
    padding: 3px 5px 3px 0;
  }
`;
const StyledSubWrapper = styled.div`
  display: "block";
  width: 100%;
  padding: 0 0 30px 0;
  .CardHeadingLabel {
    margin: 15px 0px 15px;
  }
  .RoundedWrapperCard {
    margin: 0px 25px 0px 25px;
    &.sup-view {
      background: #f2f9ff;
      max-height: 380px;
      padding: 10px 10px 10px 10px !important;
    }
  }
`;
const StyledTitleWrapper = styled.div`
  display: "block";
`;
const StyledGridWrapper = styled(Grid)`
  /* display: "block"; */
  .MuiGrid-root.MuiGrid-item {
    padding-top: 0px !important;
  }
  .styled-PhoneNumberField {
    .FieldWrapper {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73;
      input {
        background: #e8e8e8 !important;
      }
    }
  }
  .background-gray {
    div {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73 !important;
    }
    .react-tel-input {
      background: #e8e8e8;
    }
  }
  .textfield-wrapper {
    max-width: 100% !important;
  }
`;
const FieldLabel = styled.div`
  text-align: left;
  margin: 0 0 10px 0;
  color: #a09d9d;
  font-size: 14px;
  &.field-value {
    font-weight: 500;
    font-size: 14px !important;
    color: black !important;
    display: inline-block;
  }
  span {
    font-weight: 600;
    color: #000000;
  }
  .validField {
    color: #ff0000;
  }
`;

const UploadFieldWrapper = styled.div`
  padding: 5px 15px;
`;

const MultipleCheckboxWrapper = styled.div`
  padding: 0 20px;
  text-align: left;
  .MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: 600;
  }
  label {
    margin-bottom: 0;
  }
  .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
    padding: 3px 5px 3px 0;
  }
`;
const SubmitBtnWrapper = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* width: 100%; */
  text-align: right;
  margin: 0px 25px;
  button {
    max-width: 180px !important;
    color: rgb(255, 255, 255) !important;
    font-size: 12px !important;
    border: none !important;
    padding: 10px 10px !important;
    border-radius: 6px !important;
    text-transform: uppercase !important;
    background: #01579b !important;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 0px !important;
  width: 100%;
  max-width: 200px !important;
  color: #ffffff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-family: "Outfit Regular" !important;
  border: none !important;
  padding: 10px 0px !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
  background: #6e93f7 !important;
`;
