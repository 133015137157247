import React from "react";
import ReactTooltip from "react-tooltip-rc";

const ProfileContentTitle = () => {
  return (
    <div class="content-title-action">
      <div class="title-action">
        <div class="title-action-left">
          <div className="text-start">
            {/* <a
              className={"link"}
              id="backtoMain"
              rel="tooltip"
              data-for="backtoMain"
              data-tip="Back to dashboard"
              href="/"
            >
              <i class="fal fa-arrow-left"></i>
              <ReactTooltip
                id="backtoMain"
                place="right"
                type="info"
                effect="float"
              />
            </a> */}
          </div>
          <h5 class="head">Profile</h5>
          {/* <div class="action-block">
            <button
              className="btn add-new "
              id="addBtn"
              rel="tooltip"
              data-for="addTooltip"
              data-tip="Add New"
             
            >
              <span>
                <i class="fal fa-plus"></i> Add New
              </span>
            </button>
            <ReactTooltip
              id="addTooltip"
              place="bottom"
              type="info"
              effect="float"
            />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ProfileContentTitle;
