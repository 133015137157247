import { sapAxios } from "../../axios";

const axiosInst = sapAxios();

export const loginAPI = async (data) => {
    try {
        const res = await axiosInst({
            method: 'POST',
            url: 'login',
            data
        });
        if (res.status) {
            localStorage.setItem('serviceCenterId',res.data.user.profileDetail.service_admin_id_pk);
            localStorage.setItem('serviceCenterName',res.data.user.profileDetail.service_center_name);
            localStorage.setItem('username',res.data.user.username);
            localStorage.setItem('userid',res.data.user.user_id_pk);
            localStorage.setItem('sapAuthToken', res.data.token);
            localStorage.setItem('isSAPAuthenticated', true);
        }

        if(res.status) return res;
        return Promise.reject(res);
    } catch (err) {
        return Promise.reject(err);
    }
}
