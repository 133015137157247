import React, { Fragment, useContext, useEffect } from "react";
import { StaffContext } from "../StaffContext";
import configData from "../../../../../config.json";
import { Scrollbars } from "react-custom-scrollbars-2";
import placeholderImg from "../../../../../assets/img/user/3.png";
import femaleuserImg from "../../../../../assets/img/user/female-profile-.jpg";
import defaultImg from "../../../../../assets/img/user/default-avatar-icon.jpg";
import Select from "react-select";
import DatePicker from "react-date-picker";
import { getLookupDataAPI } from "../../../../../api/services/common/commonAPIs";
import styled from "styled-components";
import { usePhoneExtension } from "../../../../hooks/usePhoneExtension";

const StaffPersonalDetailsTab = (props) => {
  const phoneExtension = usePhoneExtension();

  const {
    mappedStaff,
    innertab,
    bloodGroups,
    setBloodGroups,
    idproofType,
    setIdproofType,
    relationType,
    setRelationType,
    gender,
    setGender,
  } = useContext(StaffContext);

  let BloodOptions = [{}];
  let RelationOptions = [{}];
  let GenderOption = [{}];

  BloodOptions = bloodGroups.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  RelationOptions = relationType.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  GenderOption = gender.map((item) => ({
    label: item.title,
    value: item.value,
  }));
  // let a = Array.from(props.pic);

  const sideEffect = () => {
    const bloodGroup = getLookupDataAPI({ type: "blood_group" });
    const idproof = getLookupDataAPI({ type: "idproof_type" });
    const relationType = getLookupDataAPI({ type: "relation_type" });
    const gender = getLookupDataAPI({ type: "gender" });

    bloodGroup
      .then((res) => {
        setBloodGroups(res.data);
      })
      .catch((e) => {});

    idproof
      .then((res) => {
        setIdproofType(res.data);
      })
      .catch((e) => {});

    relationType
      .then((res) => {
        setRelationType(res.data);
      })
      .catch((e) => {});

    gender
      .then((res) => {
        setGender(res.data);
      })
      .catch((e) => {});
  };
  useEffect(sideEffect, []);
  //check id proof extension
  function getExtension(filename) {
    return filename.split(".").pop();
  }

  const PhoneExtensionOption = phoneExtension.map((item) => ({
    label: item.value,
    value: item.value,
  }));
  let PhoneExtensionOne = PhoneExtensionOption.find(
    (x) => x.value === mappedStaff?.phone_extension
  );

  let EmergencyPhoneExtension = PhoneExtensionOption.find(
    (x) => x.value === mappedStaff?.staffEmergencyDetail?.phone_extension
  );

  return (
    <MainWrapper
      className={innertab === 1 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div className="inner-form-block">
          <Fragment>
            <WrapperImage>
              <div className="name-block pt-0">
                <div className="sub-block">
                  <div className="img-block">
                    {mappedStaff?.pic ? (
                      mappedStaff?.pic.map((photo, index) => {
                        return (
                          <div className="small-img mr-2" key={index}>
                            <img
                              src={photo.base64}
                              className="img-fluid"
                              alt=""
                              height="100"
                              width="100"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <div className="small-img mr-2">
                        <img
                          src={
                            mappedStaff?.profile_image
                              ? `${configData.SERVER_URL}${mappedStaff?.profile_image}`
                              : mappedStaff?.gender === 1
                              ? placeholderImg
                              : mappedStaff?.gender === 2
                              ? femaleuserImg
                              : defaultImg
                          }
                          className="img-fluid"
                          alt=""
                          height="100"
                          width="100"
                          style={{ objectFit: "contain" }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="idproof-block">
                    <div className="block-name">
                      <Staffname className="staff-name">
                        {mappedStaff?.name}
                      </Staffname>

                      <DesignationIdWrapper style={{ display: "flex" }}>
                        <div className="emp-id">
                          <p>{mappedStaff?.emp_id}</p>
                        </div>
                        <h6 className="name-designation">
                          {mappedStaff?.designation}
                        </h6>
                      </DesignationIdWrapper>
                      {/* <h1>{mappedStaff?.id}</h1> */}
                    </div>
                    <div className="id-top">
                      {mappedStaff?.staffIdProof &&
                      mappedStaff?.staffIdProof.id_proof ? (
                        <div className="small-block">
                          <Fragment className="row-img row">
                            <div class="img-list-col">
                              <div className="img-list">
                                {getExtension(
                                  mappedStaff?.staffIdProof.id_proof
                                ).toLowerCase() === "pdf" ? (
                                  <div className="pdf-block ">
                                    <span className="pdf-icon">
                                      <i class="fal fa-file-pdf"></i>
                                    </span>
                                    <a
                                      href={`${configData.SERVER_URL}${mappedStaff?.staffIdProof.id_proof}`}
                                      download
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <span>
                                        {
                                          mappedStaff?.staffIdProof?.idProof
                                            ?.title
                                        }
                                      </span>
                                    </a>
                                  </div>
                                ) : getExtension(
                                    mappedStaff?.staffIdProof.id_proof
                                  ).toLowerCase() === "docx" ? (
                                  <a
                                    href={`${configData.SERVER_URL}${mappedStaff?.staffIdProof.id_proof}`}
                                    download
                                    target="_blank"
                                  >
                                    <div className="pdf-block ">
                                      <span className="pdf-icon">
                                        <i class="fal fa-file-word word"></i>
                                      </span>
                                      <p>
                                        {
                                          mappedStaff?.staffIdProof?.idProof
                                            ?.title
                                        }
                                      </p>
                                    </div>
                                  </a>
                                ) : getExtension(
                                    mappedStaff?.staffIdProof.id_proof
                                  ).toLowerCase() === "doc" ? (
                                  <a
                                    href={`${configData.SERVER_URL}${mappedStaff?.staffIdProof.id_proof}`}
                                    download
                                    target="_blank"
                                  >
                                    <div className="pdf-block ">
                                      <span className="pdf-icon">
                                        <i class="fal fa-word word"></i>
                                      </span>
                                      <p>
                                        {
                                          mappedStaff?.staffIdProof?.idProof
                                            ?.title
                                        }
                                      </p>
                                    </div>
                                  </a>
                                ) : (
                                  <span className="img-proof">
                                    <img
                                      src={`${configData.SERVER_URL}${mappedStaff?.staffIdProof.id_proof}`}
                                      className="img-fluid"
                                      alt=""
                                    />
                                    <a
                                      href={`${configData.SERVER_URL}${mappedStaff?.staffIdProof.id_proof}`}
                                      download
                                      target="_blank"
                                    >
                                      <p>
                                        {
                                          mappedStaff?.staffIdProof?.idProof
                                            ?.title
                                        }
                                      </p>
                                    </a>
                                  </span>
                                )}
                              </div>
                            </div>
                          </Fragment>
                        </div>
                      ) : (
                        <div className="img-list">
                          <div className="pdf-block ">
                            <span className="pdf-icon">
                              <i class="fal fa-file-pdf"></i>
                            </span>
                            <p>No data uploaded</p>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </WrapperImage>
            <div className="row m-0">
              <PersonalNameInput className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-user"></i>Name
                  </span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={mappedStaff?.name}
                  readOnly
                />
              </PersonalNameInput>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-envelope"></i>Email
                  </span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={mappedStaff?.email}
                  readOnly
                />
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-phone"></i>Phone Number
                  </span>
                </div>
                <PhoneWrapper className="phone-wrapper">
                  <Select
                    className="phone-extension"
                    value={PhoneExtensionOne}
                    isDisabled={true}
                  />
                  <input
                    type="number"
                    className="form-control"
                    placeholder=""
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={mappedStaff?.phone}
                    readOnly
                  />
                </PhoneWrapper>
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-map-marker-alt"></i>Address
                  </span>
                </div>
                <textarea
                  className="form-control"
                  value={mappedStaff?.address}
                  disabled
                  readOnly
                ></textarea>
              </div>
              <div class="input-group input-group-custom">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fal fa-tint"></i>Gender
                    {/* <sup className="theme-text">*</sup> */}
                  </span>
                </div>
                <div className="input-block">
                  <Select
                    // defaultValue={mappedStaff?.gender}
                    defaultValue={GenderOption.find(
                      (x) => x.value === mappedStaff?.gender
                    )}
                    isDisabled={true}
                  />
                </div>
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-calendar"></i>Date of Birth
                    {/* <sup className="theme-text">*</sup> */}
                  </span>
                </div>
                <div className="input-block">
                  <DatePicker
                    // maxDate={new Date()}
                    format="dd/MM/y"
                    disabled={true}
                    calendarIcon={null}
                    clearIcon={null}
                    className="form-control"
                    value={mappedStaff?.date_of_birth}
                  />
                  {/* <span className="form-error">Date of Joining is required!</span> */}
                </div>
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-tint"></i>Blood Group
                  </span>
                </div>
                <div className="input-block">
                  <Select
                    // defaultValue={mappedStaff?.gender}
                    defaultValue={BloodOptions.find(
                      (x) => x.value === mappedStaff?.blood_group_id
                    )}
                    isDisabled={true}
                  />
                </div>
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-money-bill-alt"></i>Salary
                  </span>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={mappedStaff?.salary}
                  readOnly
                />
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-calendar"></i>Date of Joining
                    {/* <sup className="theme-text">*</sup> */}
                  </span>
                </div>
                <DatePicker
                  // maxDate={new Date()}
                  format="dd/MM/y"
                  disabled={true}
                  calendarIcon={null}
                  clearIcon={null}
                  className="form-control"
                  value={mappedStaff?.date_of_joining}
                />
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-calendar"></i>Id proof expiry
                    {/* <sup className="theme-text">*</sup> */}
                  </span>
                </div>
                <DatePicker
                  // maxDate={new Date()}
                  format="dd/MM/y"
                  disabled={true}
                  calendarIcon={null}
                  clearIcon={null}
                  className="form-control"
                  value={mappedStaff?.staffIdProof?.expiry_date}
                />
              </div>
              <div class="col-12 block-header main-title">
                <h6>
                  <span className="header-title">
                    Emergency Contact Details
                  </span>
                </h6>
              </div>

              <EmergencyNameInput className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-user"></i>Name
                  </span>
                </div>
                <input
                  type="email"
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={
                    mappedStaff?.staffEmergencyDetail &&
                    mappedStaff?.staffEmergencyDetail.name
                  }
                  readonly
                  disabled
                />
              </EmergencyNameInput>

              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-phone"></i>Phone Number
                  </span>
                </div>
                <PhoneWrapper className="phone-wrapper">
                  <Select
                    className="phone-extension"
                    defaultValue={EmergencyPhoneExtension}
                    isDisabled={true}
                    value={EmergencyPhoneExtension}
                  />
                  <input
                    type="email"
                    className="form-control"
                    placeholder=""
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    value={
                      mappedStaff?.staffEmergencyDetail &&
                      mappedStaff?.staffEmergencyDetail.phone
                    }
                    readonly
                    disabled
                  />
                </PhoneWrapper>
              </div>
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-users"></i>Relation
                  </span>
                </div>
                <div className="input-block">
                  <Select
                    // defaultValue={mappedStaff?.gender}
                    defaultValue={RelationOptions.find((x) =>
                      x.value ===
                      (mappedStaff?.staffEmergencyDetail &&
                        mappedStaff?.staffEmergencyDetail.relation_type)
                        ? mappedStaff?.staffEmergencyDetail.relation_type
                        : 0
                    )}
                    isDisabled={true}
                  />
                </div>
              </div>
              <div class="input-group input-group-custom">
                <div class="input-group-prepend">
                  <span class="input-group-text">
                    <i class="fal fa-map-marker-alt"></i>Emergency Address
                    {/* <sup className="theme-text">*</sup> */}
                  </span>
                </div>
                <div className="input-block">
                  <textarea
                    readonly
                    class="form-control"
                    value={
                      mappedStaff?.staffEmergencyDetail &&
                      mappedStaff?.staffEmergencyDetail.address
                    }
                    disabled
                  ></textarea>
                  {/* <span className="form-error">Address is required!</span> */}
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </Scrollbars>
    </MainWrapper>
  );
};

export default StaffPersonalDetailsTab;

const MainWrapper = styled.div`
  .css-16xfy0z-control {
    background-color: #e8e8e8;
  }
  .css-1xc3v61-indicatorContainer {
    display: none;
  }
  .css-1fdsijx-ValueContainer {
    font-size: 14px;
  }
  .css-olqui2-singleValue {
    color: #252525;
  }
  textarea {
    resize: none;
    height: max-content;
  }
`;
const Staffname = styled.div`
  &.staff-name {
    white-space: nowrap !important;
    width: 250px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    text-align: left !important;
    border: 1px solid transparent !important;
  }
`;

const PersonalNameInput = styled.div`
  .form-control {
    white-space: nowrap !important;
    width: 250px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    text-align: left !important;
  }
`;
const EmergencyNameInput = styled.div`
  .form-control {
    white-space: nowrap !important;
    width: 250px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    text-align: left !important;
  }
`;

const DesignationIdWrapper = styled.div`
  .emp-id {
    white-space: nowrap !important;
    width: 135px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    text-align: left !important;
    border: 1px solid transparent !important;
  }
  .name-designation {
    white-space: nowrap !important;
    width: 135px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    text-align: left !important;
  }
`;
const WrapperImage = styled.div`
  &.sub-block &.img-block &.small-img &.img {
    object-fit: contain;
  }
`;
const PhoneWrapper = styled.div`
  display: flex;
  width: calc(100% - 159px);
  max-width: calc(100% - 159px);

  .form-control {
    margin-left: 5px;
  }
  .css-3iigni-container {
    width: 150px;
  }
  .css-1fdsijx-ValueContainer {
    padding: 0px 8px;
  }
  .css-16xfy0z-control {
    min-height: 38px;
  }
`;
