import React, { useEffect, useRef, useState } from "react";
import PhoneNumberField from "../../../../../components/PhoneNumberField";
import { AdornmentTextField } from "../../../../../components/AdornmentTextField";
import CustomDropDown from "../../../../../components/CustomDropDown";
import styled from "styled-components";
import { Button, SelectChangeEvent } from "@mui/material";
// import {
//   sendOTPforPhone,
//   verifyPhoneOtp,
//   sendOTPforEmail,
//   verifyEmailOtp,
// } from "../../../../../api/services/vap/onboardingManagement";
// import { ResponseModel } from "../../../../../api/services/vap/models/responseModels";
import SimpleReactValidator from "simple-react-validator";

type Props = {
  onNextClick?: () => void;
  setCredentialsData: React.Dispatch<React.SetStateAction<CredentialData>>;
  credentialsData: CredentialData;
  setCountryValue: React.Dispatch<React.SetStateAction<countryValue>>;
  countryValue: countryValue;
  value?: string | number;
};
type CredentialData = {
  phone: undefined | string;
  email: string;
};
type countryValue = {
  // id: undefined,
  name: string;
};
type Credential = {
  phone: undefined | string;
  email: string;
  phoneOtp: string;
  emailOtp: string;
};
const RegisterServiceCenter = ({
  onNextClick,
  setCredentialsData,
  credentialsData,
  setCountryValue,
}: Props) => {
  const inputRefs = React.useRef<HTMLInputElement[]>([]);
  const [otpView, setOtpView] = React.useState<boolean>(false);
  const [verifyOtp, setVerifyOtp] = React.useState<boolean>(false);
  const [sendOtpPhone, setSendOtpPhone] = React.useState<boolean>(false);
  const [sendOtpEmail, setSendOtpEmail] = React.useState<boolean>(false);
  const [verifyEmail, setVerifyEmail] = React.useState<boolean>(false);
  const [verifyPhone, setVerifyPhone] = React.useState<boolean>(false);
  const [otpStatusMessage, setOtpStatusMessage] = React.useState<string | null>(
    null
  );
  const [otpEmailStatusMessage, setOtpEmailStatusMessage] = React.useState<
    string | null
  >(null);
  const [otpError, setOtpError] = React.useState<string>("");
  const [otpEmailError, setOtpEmailError] = React.useState<string>("");
  const [resentOtp, setResentOtp] = React.useState<string | null>(null);

  const [showResendButton, setShowResendButton] =
    React.useState<boolean>(false);
  const [showResendEmailButton, setShowResendEmailButton] =
    React.useState<boolean>(false);
  const [isOtpSent, setIsOtpSent] = React.useState(false);
  const [phoneChanged, setPhoneChanged] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);

  const handleAwaitResendOtp = () => {
    // if (!verifyPhone) {
    const buttonTimeoutId = setTimeout(() => {
      setShowResendButton(true);
    }, 3000);

    return () => {
      clearTimeout(buttonTimeoutId);
      setShowTimer(false);
      // setShowResendButton(false);
    };
    // }
  };
  const [showResetTimer, setShowResetTimer] = useState(false);
  const [initialValue, setInitialValue] = useState(1000);
  const [initialEmailValue, setInitialEamilValue] = useState(1000);
  const intervalRef = useRef<null | NodeJS.Timeout>(null);
  const intervalEmailRef = useRef<null | NodeJS.Timeout>(null);
  const startTimer = () => {
    intervalRef.current = setInterval(() => {
      setInitialValue((prevValue) => {
        if (prevValue <= 1) {
          clearInterval(intervalRef.current!);
          return 0;
        }
        return prevValue - 1;
      });
    });
  };
  const startEmailTimer = () => {
    intervalEmailRef.current = setInterval(() => {
      setInitialEamilValue((prevValue) => {
        if (prevValue <= 1) {
          clearInterval(intervalEmailRef.current!);
          return 0;
        }
        return prevValue - 1;
      });
    });
  };
  const resetTimer = () => {
    handleSendPhoneOtpClick();
    setInitialValue(500);
    startTimer();
  };
  const resetEmailTimer = () => {
    handleSendEmailOtpClick();
    setInitialEamilValue(500);
    startEmailTimer();
  };

  useEffect(() => {
    startTimer();
    return () => {
      clearInterval(intervalRef.current!);
    };
  }, []);
  useEffect(() => {
    startEmailTimer();
    return () => {
      clearInterval(intervalEmailRef.current!);
    };
  }, []);
  const handleAwaitResendEmailOtp = () => {
    // if (!verifyEmail) {
    const buttonTimeoutId = setTimeout(() => {
      setShowResendEmailButton(true);
    }, 3000);

    return () => {
      clearTimeout(buttonTimeoutId);
      setShowResendEmailButton(false);
    };
    // }
  };

  const [credentials, setCredentials] = React.useState<Credential>({
    phone: "",
    email: "",
    phoneOtp: "",
    emailOtp: "",
  });
  const [countryVal, setCountryVal] = React.useState({
    id: undefined,
    name: "",
  });

  const [values, setValues] = React.useState<string[] | undefined>(
    new Array(4).fill("")
  );

  //React Validator
  const simpleValidator = React.useRef(new SimpleReactValidator());

  const handleChange = (index: number, value: string) => {
    const newValues = [...values!];
    if (values) {
      newValues[index] = value;
      setValues(newValues);
      const otp = newValues.join("");
      if (otp.length === 4) {
        // onChange?.(otp);
        // dispatch(actions.changeLoginOtp(otp));
        return;
      }
      if (value && index < 3) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Backspace" && values && !values[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };
  function handleSubmitClick() {
    // setOtpView(true);
    onNextClick?.();
  }
  function handleVerifyClick() {
    setOtpView(false);
    setVerifyOtp(true);
  }
  const handlePhoneChange = (
    value: string,
    data: any,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    console.log(event, "handlePhoneChange");

    setCredentials({ ...credentials, phone: value });
    setPhoneChanged(true);
    setCredentialsData({ ...credentialsData!, phone: value });
    setSendOtpPhone(false);
    setOtpStatusMessage("");
    setShowResendButton(false);
  };

  const [validEmail, setValidEmail] = React.useState(false);
  const validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let email = e.target.value;
    let isValidEmail = validRegex.test(email);
    console.log(email, "emailss");

    setCredentials((prevState) => ({
      ...prevState,
      email: email,
      isValidEmail: isValidEmail,
    }));
    setCredentialsData({ ...credentialsData!, email: email });
    setEmailChanged(true);
    setSendOtpEmail(false);
    setOtpEmailStatusMessage("");
    setShowResendEmailButton(false);
  };
  useEffect(() => {
    if (simpleValidator.current?.fieldValid("Email")) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  }, [credentialsData]);

  // console.log(credentialsData, "credentialsDataaa");
  const handlePhoneOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(e, "handleEmailChange");
    setCredentials((prevState) => ({
      ...prevState,
      phoneOtp:
        e.target.value?.length <= 6 ? e.target.value : credentials.phoneOtp,
    }));
  };
  const handleEmailOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e, "handleEmailChange");
    setCredentials((prevState) => ({
      ...prevState,
      emailOtp:
        e.target.value?.length <= 6 ? e.target.value : credentials.emailOtp,
    }));
  };
  const [showTimer, setShowTimer] = useState(false);
  const [showTimerEmail, setShowEmailTimer] = useState(false);
  function handleSendPhoneOtpClick() {
    console.log("handleSendPhoneOtpClick");
    // handleAwaitResendOtp();
    setOtpError("");
    setShowResetTimer(true);
    setOtpStatusMessage("");
    setVerifyPhone(false);
    setShowTimer(true);
    setPhoneChanged(false);
    // setShowEmailTimer(false);
    // handleAwaitResendOtp();
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      phoneOtp: "",
    }));
    // commented for SAP registration layout
    // sendOTPforPhone({
    //   phone_number: credentials.phone,
    // })
    //   .then((res) => {
    //     if (res) {
    //       setSendOtpPhone(true);
    //       handleAwaitResendOtp();
    //       setSendOtpPhone(true);
    //       // setIsOtpSent(true);
    //       setOtpStatusMessage("OTP sent");
    //       // setCredentials({ ...credentials, phoneOtp: res.data.otp });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err, "response send phone otp");

    //     //  setOtpStatusMessage("Error sending OTP");
    //   });
    // // setCredentials({ ...credentials, phone: undefined });
    setSendOtpPhone(true);
    handleAwaitResendOtp();
    setSendOtpPhone(true);
    setOtpStatusMessage("OTP sent");
  }

  console.log(
    showResendButton,
    !verifyPhone,
    sendOtpPhone,
    "showResendButton && !verifyPhone && sendOtpPhone"
  );

  function handleSendEmailOtpClick() {
    setOtpEmailError("");
    setOtpEmailStatusMessage("");
    setVerifyEmail(false);
    setShowEmailTimer(true);
    setEmailChanged(false);

    // Check if the email is valid before sending OTP
    if (simpleValidator.current.fieldValid("Email")) {
      setCredentials((prevCredentials) => ({
        ...prevCredentials,
        emailOtp: "",
      }));
      // commented for SAP registration 
      // sendOTPforEmail({
      //   email: credentials.email,
      // })
      //   .then((res) => {
      //     if (res) {
      //       console.log(res, "datas");
      //       handleAwaitResendEmailOtp();
      //       setSendOtpEmail(true);
      //       setOtpEmailStatusMessage("OTP sent");
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err, "response send email otp");
      //   });
      // success of commented above code
        handleAwaitResendEmailOtp();
        setSendOtpEmail(true);
        setOtpEmailStatusMessage("OTP sent");
      // end success of commented above code
    } else {
      // Handle invalid email case (show error message, etc.)
      setOtpEmailError("Invalid email address");
    }
  }

  function handleVerifyPhoneClick() {
    // commented for SAP registration
    // verifyPhoneOtp({
    //   phone_number: credentials.phone,
    //   otp: credentials.phoneOtp,
    // })
    //   .then((res) => {
    //     //write success code here
    //     console.log(res, "verifyOtp:success");
    //     if (res?.status === true) {
    //       setVerifyPhone(true);
    //       setShowResendButton(false);
    //       setOtpStatusMessage("");
    //       setShowTimer(false);
    //       if (otpError) {
    //         setOtpError("");
    //       }
    //     } else {
    //       console.log(res.message, "status false");
    //       // setOtpError("Incorrect OTP, try again");
    //       // setResentOtp("");
    //       // setOtpStatusMessage("One Time Password verified");
    //     }
    //   })
    //   .catch((err) => {
    //     //write failure code here
    //     console.log(err, "verifyOtp:error");
    //     setOtpError("Incorrect OTP,try again");
    //     setResentOtp("");
    //     setShowResendButton(true);
    //     // handleAwaitResendOtp()
    //   });
    // success of above success of commented code
    setVerifyPhone(true);
    setShowResendButton(false);
    setOtpStatusMessage("");
    setShowTimer(false);
    if (otpError) {
      setOtpError("");
    }
    // end success of above commented code
  }
  function handleVerifyEmailClick() {
    // commented for SAP registration
    // verifyEmailOtp({
    //   email: credentials.email,
    //   otp: credentials.emailOtp,
    // })
    //   .then((res) => {
    //     console.log({ res }, "verifyOtp:success");
    //     if (res.status === true) {
    //       setVerifyEmail(true);
    //       setShowResendEmailButton(false);
    //       setShowEmailTimer(false);
    //       setOtpStatusMessage("");
    //       setOtpEmailStatusMessage("");
    //       if (otpEmailError) {
    //         setOtpEmailError("");
    //       }
    //     } else {
    //       console.log(res.message, "status false");
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err, "response verify email otp");
    //     setOtpEmailError("Incorrect OTP,try again");
    //     setResentOtp("");
    //     setShowResendEmailButton(true);
    //   });
    // success of above success of commented code
      setVerifyEmail(true);
      setShowResendEmailButton(false);
      setShowEmailTimer(false);
      setOtpStatusMessage("");
      setOtpEmailStatusMessage("");
      if (otpEmailError) {
        setOtpEmailError("");
      }
    // end success of above commented code
  }
  function handleOnDropDownSelect(
    e: SelectChangeEvent<string | number>,
    val?: any
  ) {
    console.log(e);
    setCountryVal(val);
    setCountryValue(val);
  }

  return (
    <>
      <StyledFiledWrapper className="StyledFiledWrapper">
        {!otpView ? (
          <>
            <CustomDropDown
              // onSelect={handleOnDropDownSelect}
              value={countryVal.id}
              label="Choose Country"
              onChange={handleOnDropDownSelect}
              name="Country"
              validations="required"
              validator={simpleValidator.current}
            />
            <div className="AdornmentTextField-wrapper">
              <PhoneNumberField
                placeholder={"Enter your mobile number"}
                showAdornment={true}
                disabled={verifyOtp}
                onChange={handlePhoneChange}
                value={credentials?.phone}
                disableSendOtp={
                  !credentials?.phone?.toString().length ? false : true
                }
                onSendClick={handleSendPhoneOtpClick}
                sendOtp={sendOtpPhone}
                sendOtpOnly={true}
                className="styled-PhoneNumberField"
                validations={"required "}
                validator={simpleValidator.current}
                name="Phone Number"
                // hideInnerMsg={otpStatusMessage && !otpError ? true : false}
              />
              {otpStatusMessage && !otpError ? (
                <MessageWrapper>{otpStatusMessage}</MessageWrapper>
              ) : (
                ""
              )}
              {showTimer && (
                <>
                  {initialValue <= 0 && (
                    <button
                      onClick={() => resetTimer()}
                      className="underline-blue-button"
                    >
                      Resend OTP
                    </button>
                  )}
                  <TimerWrapper>
                    {initialValue !== 0 &&
                      "otp resend in " +
                        (Math.floor(initialValue / 60) +
                          ":" +
                          ((initialValue % 60).toString().length < 2
                            ? `0${initialValue % 60}`
                            : initialValue % 60))}
                  </TimerWrapper>
                </>
              )}
            </div>
            {sendOtpPhone && (
              <div className="AdornmentTextField-wrapper">
                <AdornmentTextField
                  placeholder={"Enter One Time Password"}
                  showAdornment={true}
                  disabled={verifyOtp}
                  disableVerifybtn={
                    credentials?.phoneOtp.length < 6 ? true : false
                  }
                  onChange={handlePhoneOtpChange}
                  onCheckClick={handleVerifyPhoneClick}
                  verifyOtp={verifyPhone}
                  value={credentials?.phoneOtp}
                  sendOtp={true}
                  name={"Otp"}
                  validations={"required|numeric|max:6"}
                  validator={simpleValidator.current}
                />
                {otpStatusMessage && !otpError ? (
                  ""
                ) : otpError ? (
                  <ErrorMessageWrapper>{otpError}</ErrorMessageWrapper>
                ) : (
                  ""
                )}
              </div>
            )}

            {/* email field */}

            <div className="AdornmentTextField-wrapper">
              <AdornmentTextField
                placeholder={"Enter your email ID"}
                showAdornment={true}
                disabled={verifyOtp}
                onChange={handleEmailChange}
                value={credentials?.email}
                onSendClick={handleSendEmailOtpClick}
                sendOtp={sendOtpEmail}
                sendOtpOnly={true}
                name="Email"
                fieldName="Email"
                validations={"required|email"}
                validator={simpleValidator.current}
                disableVerifybtn={true}
                checkValue={credentials}
                disableSendOtpbtn={!validEmail}
              />
              {otpEmailStatusMessage && !otpEmailError ? (
                <MessageWrapper>{otpEmailStatusMessage}</MessageWrapper>
              ) : (
                ""
              )}
              {showTimerEmail && (
                <>
                  {initialEmailValue <= 0 && (
                    <button
                      onClick={() => resetEmailTimer()}
                      className="underline-blue-button"
                    >
                      Resend OTP
                    </button>
                  )}
                  <TimerWrapper>
                    {initialEmailValue !== 0 &&
                      "otp resend in " +
                        (Math.floor(initialEmailValue / 60) +
                          ":" +
                          ((initialEmailValue % 60).toString().length < 2
                            ? `0${initialEmailValue % 60}`
                            : initialEmailValue % 60))}
                  </TimerWrapper>
                </>
              )}
            </div>
            {sendOtpEmail && (
              <div className="AdornmentTextField-wrapper">
                <AdornmentTextField
                  placeholder={"Enter One Time Password"}
                  showAdornment={true}
                  disabled={verifyOtp}
                  disableVerifybtn={
                    credentials?.emailOtp.length < 6 ? true : false
                  }
                  onChange={handleEmailOtpChange}
                  verifyOtp={verifyEmail}
                  name={"emailOtp"}
                  value={credentials?.emailOtp}
                  onCheckClick={handleVerifyEmailClick}
                  onSendClick={handleSendEmailOtpClick}
                  sendOtp={true}
                  validations={"required"}
                  validator={simpleValidator.current}
                />
                {otpEmailStatusMessage && !otpEmailError ? (
                  ""
                ) : otpEmailError ? (
                  <ErrorMessageWrapper>{otpEmailError}</ErrorMessageWrapper>
                ) : (
                  ""
                )}
              </div>
            )}
            {verifyEmail &&
              verifyPhone &&
              countryVal.id &&
              credentials.email &&
              credentials.phone && (
                <SubmitBtnWrapper className="SubmitBtnWrapper">
                  {!phoneChanged && !emailChanged ? (
                    <StyledButton
                      onClick={handleSubmitClick}
                      variant="contained"
                    >
                      Proceed to Next Step
                    </StyledButton>
                  ) : null}
                </SubmitBtnWrapper>
              )}
          </>
        ) : (
          // <OtpBlock className="otp-block">
          //   <StyledImageWrapper className="StyledImageWrapper">
          //     <StyledImage src={shieldIcon} />
          //   </StyledImageWrapper>
          //   <h4>Enter OTP Code</h4>
          //   <OtpFieldWrapper className="OtpFieldWrapper">
          //     <StyledInputWrapper style={{ display: "flex", gap: "10px" }}>
          //       {Array.from(Array(4).keys())?.map((index) => (
          //         <StyledInput
          //           key={index}
          //           type="text"
          //           id={`otpField${index}`}
          //           pattern="[0-9]*"
          //           inputMode="numeric"
          //           maxLength={1}
          //           value={values && values[index]}
          //           onChange={(event) =>
          //             handleChange(index, event.target.value)
          //           }
          //           onKeyDown={(event) => handleKeyDown(index, event)}
          //           ref={(el) => (inputRefs.current[index] = el!)}
          //         />
          //       ))}
          //     </StyledInputWrapper>
          //     <StyledBtnWrapper>
          //       <StyledButton onClick={handleVerifyClick} variant="contained">
          //         Verify OTP
          //       </StyledButton>
          //     </StyledBtnWrapper>
          //   </OtpFieldWrapper>
          // </OtpBlock>

          <></>
        )}
      </StyledFiledWrapper>
    </>
  );
};

export default RegisterServiceCenter;
const StyledImageWrapper = styled.div``;
const StyledFiledWrapper = styled.div`
  padding: 20px 0 0 0;

  .styled-PhoneNumberField {
    .FieldWrapper {
      background: #e8e8e8 !important;
    }
    input {
      background: #e8e8e8;
    }
  }

  .underline-blue-button {
    text-decoration: underline;
    color: blue;
    border: none;
    background-color: #f5f5f5;
    padding-left: 4px;
    padding-top: 4px;
    margin-left: 24px;
  }

  .AdornmentTextField-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    .MuiTextField-root {
      width: 92% !important;
      div {
        background: #e8e8e8 !important;
      }
    }
  }
  width: 100%;
  text-align: left;
  /* max-width: 400px; */
  align-items: center;
  position: relative;
`;
const StyledBtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const SubmitBtnWrapper = styled.div`
  margin-right: 16px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  text-align: right;
  padding: 5px 0 5px 0;
  button {
    max-width: 180px !important;
    color: rgb(255, 255, 255) !important;
    font-size: 12px !important;
    border: none !important;
    padding: 10px 10px !important;
    border-radius: 6px !important;
    text-transform: uppercase !important;
    background: #01579b !important;
  }
`;
const OtpBlock = styled.div`
  text-align: center;
  background: #fff;
  padding: 30px 65px;
  border-radius: 12px;
  row-gap: 20px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
`;
const StyledInput = styled.input`
  width: 48px;
  height: 60px;
  border: 2px solid #ddd;
  border-radius: 10px;
  background: #ffffff;
  color: #000000;
  padding: 15px;
  border: "none";
  font-size: 20px !important;
`;
const StyledInputWrapper = styled.div`
  display: "flex";
  gap: "10px";
  align-items: center;
  justify-content: center;
`;
const StyledImage = styled.img`
  background: #4070f4;
  padding: 10px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
`;
const OtpFieldWrapper = styled.div`
  text-align: left;
  color: #000000;
  padding: 25px 0 0 0;
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const StyledButton = styled(Button)`
  margin-top: 0px !important;
  width: 100%;
  max-width: 200px !important;
  color: #ffffff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-family: "Outfit Regular" !important;
  border: none !important;
  padding: 10px 0px !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
  background: #6e93f7 !important;
`;

const MessageWrapper = styled.div`
  color: #00b383;
  font-size: 14px;
  margin-top: 5px;
`;

const ErrorMessageWrapper = styled.div`
  color: #e91344;
  font-size: 14px;
  margin-top: 5px;
`;

const TimerWrapper = styled.div`
  color: #7d7b7b;
  font-size: 10px;
  margin-top: 5px;
  padding: 30px;
`;
