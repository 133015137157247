import React, { useState } from "react";
import styled from "styled-components";
import InputLayout from "../../../../SuperAdmin/Pages/components/Layouts/InputLayout";
import CustomFileUpload from "../../../../SuperAdmin/Pages/components/CustomFileUpload";
import { FeatureFormData } from "../index";
import CustomDropDown from "../../../components/CustomDropDown";

type SapFeatureFormProps = {
  formData: FeatureFormData;
  setFormData: React.Dispatch<React.SetStateAction<FeatureFormData>>;
  isEdit?: boolean;
  handleCancelClick: () => void;
};
const dummyTextOptions = [
  { label: "Top Left", value: 1 },
  { label: "Top Center", value: 2 },
  { label: "Top Right", value: 3 },
  { label: "Center Left", value: 4 },
  { label: "Center Center", value: 5 },
  { label: "Center Right", value: 6 },
  { label: "Bottom Left", value: 7 },
  { label: "Bottom Center", value: 8 },
  { label: "Bottom Right", value: 9 },
];
const featurePositionOptions = [
  { label: "1", value: 1 },
  { label: "2", value: 2 },
  { label: "3", value: 3 },
  { label: "4", value: 4 },
  { label: "5", value: 5 },
  { label: "6", value: 6 },
  { label: "7", value: 7 },
  { label: "8", value: 8 },
  { label: "9", value: 9 },
];

const SapFeatureForm = ({
  formData,
  setFormData,
  isEdit,
  handleCancelClick,
}: SapFeatureFormProps) => {
  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };
  function urlToFile(url: string): Promise<File> {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const filename = url.substring(url.lastIndexOf("/") + 1);
          const file = new File([blob], filename, { type: blob.type });
          resolve(file);
        })
        .catch((error) => {
          console.error("Error converting URL to File:", error);
          reject(new Error("Error converting URL to File"));
        });
    });
  }
  const fileToDataUri = (file: File) => {
    return new Promise<{
      base64: string;
      type: string;
    }>((res) => {
      const reader = new FileReader();
      const { type, name, size } = file;
      reader.addEventListener("load", () => {
        res({
          base64: reader.result as string,
          type,
        });
      });
      reader.readAsDataURL(file);
    });
  };
  const [media, setMedia] = useState<
  (
    | {
        base64: string;
        type: "image";
      }
    | { videoSrc: string; type: "video" }
  )[]
  >([]);

  React.useEffect(() => {
    setFormData({
      ...formData,
      image: media[0]!,
    });
  }, [media]);

  return (
    <>
      <Wrapper className="col-12">
        <form className="w-100" id="add-offer">
          <InputLayout
            isImportant
            title="Media Upload"
            icon={<i className="fal fa-images"></i>}
          >
            <CustomFileUpload
              setImage={setMedia}
              viewonly={!isEdit}
              allowVideo
            />
          </InputLayout>
          <InputLayout
            isImportant
            title="Title"
            icon={<i className="fal fa-keyboard"></i>}
          >
            <input
              type="text"
              name="title"
              className="form-control"
              value={formData.title}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </InputLayout>
          <InputLayout
            isImportant
            title="Text Position"
            icon={
              <>
                <i className="far fa-th"></i>
              </>
            }
          >
            <CustomDropDown
              data={dummyTextOptions}
              disabled={!isEdit}
              value={dummyTextOptions.find(
                (x) => x.value === formData?.textPosition
              )}
            />
          </InputLayout>
          <InputLayout
            isImportant
            title="Featured Position"
            icon={
              <>
                <i className="far fa-th"></i>
              </>
            }
          >
            <CustomDropDown
              data={featurePositionOptions}
              disabled={!isEdit}
              value={featurePositionOptions.find(
                (x) => x.value === formData?.imgPosition
              )}
            />
          </InputLayout>
        </form>
        {isEdit ? (
          <ButtonWrapper>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary ml-3"
              type="button"
              onClick={() => {}}
            >
              Save
            </button>
          </ButtonWrapper>
        ) : (
          ""
        )}
      </Wrapper>
    </>
  );
};

export default SapFeatureForm;

const Wrapper = styled.div`
  /* .row-img {
    display: flex !important;
    position: absolute;
  } */
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
