import React, { useState, useContext, useEffect, useRef } from "react";
import { BookingsContext } from "./BookingsContext";
import BookingsFileUploadModal from "./BookingsFileUploadModal";
import DatePicker from "react-date-picker";
import Multiselect from "multiselect-react-dropdown";
import dateFormat from "dateformat";
import ReactTooltip from "react-tooltip-rc";
import { Validation } from "./Ui/Validation";
import { toast } from "react-toastify";
import AutoSuggest from "react-autosuggest";
import Select from "react-select";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import img from "../../../../assets/img/motorque/mtlogo.svg";
import "./Ui/css/QuoteModal.css";
import {
  getDataByVehicleNumber,
  vehicleNumberSuggestion,
  serviceListByVehicleType,
  getVehicleModel,
  getManufacturerLIst,
  getBookingsList,
  createOrUpdateBooking,
  getVehicleServices,
} from "../../../../api/services/sap/bookings-management";
import styled from "styled-components";
import { Table } from "react-bootstrap";
import { useCountryRegion } from "../../../hooks/useCountryRegion";
import { usePhoneExtension } from "../../../hooks/usePhoneExtension";
import CustomYearPicker from "../../../CustomYearPicker/index";
import CustomTimePicker from "../../../CustomTimePicker";
import DropdownIcon from "../../Layout/assets/icon/dropdownIcon";

const CustomArrow = () => {
  return (
    <div className="custom-arrow-container">
      <div className="vertical-line"></div>
      <DropdownIcon />
    </div>
  );
};
let initialValue = {
  vehicle_number: "",
  customer_name: "",
  phone_number: "",
  phone_extension: "+971",
  date: dateFormat(new Date(), "yyyy-mm-dd"),
  time: "",

  vehicle_manufacturer: "",
  vehicle_manufacture_year: null,
  vehicle_model: "",
  model_id: "",
  vehicle_color: "",
  cancelBook: [],

  service_booked: "",
  coupon: "",
  additional_charges: "",
  discount: "",
  total: "",
  remarks: "",

  select_service: "",
  total_rate: "",
  grand_total: "",
  pending_payment: "",
  comments: "",
  partiallyPaidPrice: "",
  payment_status: "",
  oryment_type: "",
  pader_payment_id: "",
  order_id: "",
  customer_id: "",
  customer_vehicle_id_fk: "",
  vehicleType: "",
};
const errorFields = {
  vehicle_number: "",
  customer_name: "",
  phone_number: "",
  vehicleType: "",
  vehicle_model: "",
  service_date: "",
  service_time: "",
  service: "",
  payment_type: "",
};

const BookingsForm = (props) => {
  const multiselectRef = useRef();
  const {
    mappedBookingsData,
    viewReadOnly,
    editMode,
    SetFormChange,
    serviceOptions,
    setServiceOptions,
    setEditMode,
    setViewReadOnly,
    filteredManufacture,
    setFilteredManufacture,
    showList,
    setShowList,
    configData,
    error,
    setError,
    lgShow,
    setLgShow,
    showQuoteModal,
    showBookingButton,
    setShowBookingButton,
    chooseDate,
  } = useContext(BookingsContext);
  const [formBookings, setFormBookings] = useState(initialValue);
  const [selectedCountry, setSelectedCountry] = useState("UAE");
  const [selectRegion, setSelectRegion] = useState("AUH");
  const [model, setModel] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [vehicleType, isvehicleType] = useState("");
  const [payMethod, setPayMethod] = useState("");
  const [couponBtn, setCouponBtn] = useState("Apply Coupon");
  const [servicePrice, setServicePrice] = useState("");
  const [couponAmount, setCouponAmount] = useState("");
  const [couponDisplay, setCouponDisplay] = useState(false);
  const [selectService, setSelectService] = useState([]);
  const [removedService, setRemovedService] = useState([]);
  const [formErrors, SetFormErrors] = useState(errorFields);
  const [isVehicleRes, setIsVehicleRes] = useState(false);
  const [discountType, setDiscountType] = useState();
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [editBookingsId, setEditBookingsId] = useState("");
  const [manufacturer, setManufacturer] = useState([]);
  const [manufacturerModels, setManufacturerModels] = useState("");
  const countryRegion = useCountryRegion({ region: true });
  const phoneExtension = usePhoneExtension();
  const [multiOpen, setMultiOpen] = useState(true);

  const couponClick = (e) => {
    e.preventDefault();
  };
  let couponCode = "coupon";
  let couponValue = 50;
  const onChangeValidate = (field) => {
    const errors = Validation(formBookings, true, field);
    if (Object.keys(errors).length !== 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      setError(false);
      SetFormErrors(errorFields);
    }
  };
  const BookingsSubmitHandler = (e) => {
    e.preventDefault();
    let regionId = null;
    if (selectedCountry === "UAE") {
      regionId = filteredRegions?.id ? filteredRegions.id : null;
    }
    const formData = {
      ...formBookings,
      phone_extension: formBookings.phone_extension,
      service_date: formBookings.date
        ? dateFormat(formBookings.date, "yyyy-mm-dd")
        : dateFormat(new Date(), "yyyy-mm-dd"),
      service_time: formBookings.time
        ? formBookings.time
        : dateFormat(new Date(), "HH:MM"),
      coupon_id_fk: "",
      coupon_discount: "",
      balance_amount: pendingPay,
      comments: formBookings.comments,
      actual_amount: totalPrice,
      paid_amount: formBookings.partiallyPaidPrice,
      additional_discount: formBookings.discount ?? 0,
      additional_charges: formBookings.additional_charges ?? 0,
      grand_total: pendingPay,
      payment_type: payMethod,
      discount_type: discountType,
      country_id: filteredCountries?.country_id_pk,
      ...(selectedCountry === "UAE" && { region_id: regionId }),
      service: selectService.map((item) => ({
        price: item.price,
        service_id: item.service_id_pk,
        order_service_id: item.order_service_id,
      })),
      removedService: removedService.map((removed) => ({
        price: removed.price,
        service_id: removed.service_id_pk,
        order_service_id: removed.order_service_id,
      })),
    };
    let errors = Validation(formData, null, false);
    if (Object.keys(errors).length !== 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      resetFormErrors();
      const create = createOrUpdateBooking({ ...formData });
      create
        .then((res) => {
          const getBookedDates = getBookingsList();
          getBookedDates &&
            getBookedDates
              .then((bookedDates) => {
                const editedDayBookings = bookedDates.filter((booking) => {
                  return booking?.date?.isSame(formData.service_date, "day");
                });
              })
              .catch((err) => {
                console.error("Error fetching booked dates:", err);
              });
          SetFormChange(false);
          props.bookingformClose(false);
          if (formData.order_id) {
            toast.success("Booking Updated Successfully", {
              position: "bottom-right",
            });
          } else {
            toast.success("Booking Created Successfully", {
              position: "bottom-right",
            });
          }
          chooseDate(formBookings.date);
          setShowList(!showList);
        })
        .catch((err) => {
          if (err.message) {
            toast.error(err.message, {
              position: "bottom-right",
            });
          } else if (err.data.errors) {
            toast.error(err.data.errors[0].msg, {
              position: "bottom-right",
            });
          } else {
            toast.error("Something went wrong!", {
              position: "bottom-right",
            });
          }
        });
    }
  };

  const resetFormErrors = () => {
    setError(false);
    SetFormErrors(errorFields);
  };

  useEffect(() => {
    let vehicleType =
      mappedBookingsData?.customerVehicle?.vehicleModel?.vehicleVariant
        ?.vehicle_type_id_fk;
    handleGetModel(vehicleType ?? 1);
  }, [mappedBookingsData]);

  const handleGetModel = (vehicleTypeID) => {
    const data = getVehicleModel(vehicleTypeID);
    data.then((response) => {
      const manufacturers =
        response &&
        response
          .map((item) => ({
            label: item.manufacturer,
            value: item.manufacturer_id,
          }))
          .filter(
            (item1, index, all) =>
              all.indexOf(all.find((item2) => item2.value === item1.value)) ===
                index ?? false
          );

      const manufacturerList =
        response &&
        response.map((item) => ({
          ...item,
          label: item.model,
          value: item.model,
        }));
      setManufacturer(manufacturers);
      setManufacturerModels(manufacturerList);
      setModel(manufacturerList);
    });
  };

  const handleManufaturerChange = (manufacturerId) => {
    setFilteredManufacture((prev) => undefined);

    setModel(
      manufacturerModels.filter(
        (item) => item.manufacturer_id == manufacturerId
      )
    );
  };
  useEffect(() => {
    fillFormData();
    return () => {
      setFormBookings(initialValue);
      isvehicleType([]);
      setPayMethod("");
      setServicePrice([]);
      setSelectService([]);
    };
  }, [props.addBookings, mappedBookingsData, editMode]);

  const fillFormData = () => {
    if (!Object.keys(mappedBookingsData).length) {
      return;
    }
    let {
      partiallyPaid,
      vehicleType,
      servicePrice,
      orderServices,
      orderPayments,
      customer,
      id,
      customerVehicle,
      selectServices,
      ...rest
    } = mappedBookingsData;
    let payment = [];
    payment =
      orderPayments &&
      orderPayments.map((payment) => {
        return payment;
      });
    //advance paid amount
    const filteredAdvanceAmt =
      orderPayments?.length &&
      orderPayments?.filter((item) => {
        return item?.type === "bookings";
      })?.[0];
    setFormBookings({
      ...formBookings,
      order_id: mappedBookingsData.order_id_pk,
      vehicle_number:
        customerVehicle && customerVehicle.vehicle_registration_number,
      customer_name: customer && customer.customer_name,
      phone_number: customer && customer.phone_number,
      additional_charges:
        mappedBookingsData.additional_charges === 0
          ? null
          : mappedBookingsData.additional_charges,
      balance_amount: mappedBookingsData?.balance_amount ?? null,
      discount:
        mappedBookingsData.additional_discount === 0
          ? null
          : mappedBookingsData.additional_discount,
      phone_extension: mappedBookingsData.customer.phone_extension,
      comments: mappedBookingsData.comments,
      date: new Date(Date.parse(mappedBookingsData.service_date)),
      time: mappedBookingsData.service_time,
      vehicle_color: customerVehicle && customerVehicle.color,
      // vehicleType: customerVehicle && customerVehicle.vehicle.vehicle_type,
      vehicleType:
        customerVehicle &&
        customerVehicle.vehicleModel.vehicleVariant.vehicle_type_id_fk,
      vehicle_manufacture_year:
        (customerVehicle && customerVehicle.manufactured_year) ?? null,
      vehicle_manufacturer: customerVehicle && customerVehicle.model_id_fk,
      vehicle_model: customerVehicle && customerVehicle.model_id_fk,
      model_id: customerVehicle && customerVehicle.model_id_fk,
      partiallyPaidPrice: filteredAdvanceAmt?.paid_amount ?? null,
      customer_id: customer && customer.customer_id_pk,
      customer_vehicle_id_fk:
        customerVehicle && customerVehicle.customer_vehicle_id_pk,
      order_payment_id:
        Object.keys(payment).length && payment[0].order_payment_id_pk,
      payment_type: Object.keys(payment).length && payment[0].payment_type,
      discount_type: mappedBookingsData?.discount_type ?? null,
      region_code: mappedBookingsData.customerVehicle.region_code,
      region_id: mappedBookingsData.customerVehicle.region_id_fk,
      country_id: mappedBookingsData.customerVehicle.country_id_fk,
      country_code: mappedBookingsData.customerVehicle.country_code,
      vehicle_model: mappedBookingsData.customerVehicle.vehicleModel.model,
    });
    setEditBookingsId(mappedBookingsData.customer_name);
    isvehicleType(vehicleType ? vehicleType : []);
    setServicePrice(servicePrice ? servicePrice : "");
    setPayMethod(Object.keys(payment).length && payment[0].payment_type);
    setSelectService(
      orderServices
        ? orderServices.map((item, index) => ({
            price: item.actual_price,
            order_service_id: item.order_service_id_pk,
            label: item.service.service_name,
            service_id_pk: item.service.service_id_pk,
            servicePriceDetails: [
              {
                price: item.actual_price,
              },
            ],
          }))
        : []
    );
    if (Object.keys(mappedBookingsData).length !== 0) {
      getModelData(
        mappedBookingsData.customerVehicle.vehicleModel.vehicle_model_id_pk,
        mappedBookingsData.customerVehicle.vehicleModel.vehicleVariant
          .vehicle_type_id_fk,
        mappedBookingsData.customerVehicle?.vehicleModel?.vehicleVariant
          .variant_id_pk
      );
    }
  };

  let a = props.addBookings;
  const handleSelectService = (selectedList, selectedItem) => {
    setSelectService(selectedList);
    setMultiOpen(false);
  };

  const handleClickOutside = (event) => {
    const clickedElement = event.target;
    if (
      multiselectRef.current &&
      clickedElement?.contains(document.querySelector(".searchBox"))
    ) {
      setMultiOpen(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const removeService = (e, serviceId) => {
    let filteredService = selectService.filter(
      (el) => el.service_id_pk !== serviceId
    );
    let removedService = selectService.filter(
      (el) => el.service_id_pk === serviceId
    );

    setRemovedService(removedService);
    setSelectService(filteredService);
    if (filteredService === 0) {
      multiselectRef.current.resetSelectedValues();
    }
  };

  const uncheckService = (selectedList, removedItem) => {
    setMultiOpen(false);
    const filteredService = selectedList.filter(
      (item) => item.service_id_pk !== removedItem.service_id_pk
    );
    const removedService = [removedItem];

    setRemovedService((prevRemovedService) => [
      ...prevRemovedService,
      ...removedService,
    ]);
    setSelectService(filteredService);

    if (filteredService.length === 0) {
      multiselectRef.current.resetSelectedValues();
    }
  };

  let totalPrice = selectService?.reduce(
    (total, selectService) => parseFloat(selectService.price) + total,
    0
  );
  const uaeCountry = countryRegion?.find(
    (country) => country.country_code === "UAE"
  );

  const filteredCountries = countryRegion?.find((country) =>
    country.country_code.toLowerCase().includes(selectedCountry.toLowerCase())
  );

  const filteredRegions = countryRegion
    ?.map((country) =>
      country.regions?.find(
        (region) => region.code?.toLowerCase() === selectRegion.toLowerCase()
      )
    )
    ?.find((region) => region !== undefined);

  let options = [];

  if (uaeCountry) {
    options = uaeCountry.regions.map((region) => ({
      value: region.code,
      label: `${region.name} (${region.code})`,
    }));
  }

  let grandTotal =
    parseFloat(totalPrice) +
    (formBookings.additional_charges
      ? parseFloat(formBookings.additional_charges)
      : 0);

  // Tax amount
  const taxRate = configData?.serviceadmin?.tax_percentage ?? 0; // 5%
  // const tax = ;
  let tax = parseFloat(((grandTotal * taxRate) / 100).toFixed(2));
  if (discountType === "1" && formBookings.discount) {
    let discountAmount = (grandTotal * parseFloat(formBookings.discount)) / 100;
    grandTotal -= discountAmount;
  } else if (discountType !== "1" && formBookings.discount) {
    grandTotal -= parseFloat(formBookings.discount);
  }
  grandTotal = parseFloat(grandTotal.toFixed(3));

  // grand total
  const taxless = grandTotal;
  grandTotal += tax;

  let pendingPay =
    parseInt(grandTotal) -
    parseInt(
      formBookings.partiallyPaidPrice ? formBookings.partiallyPaidPrice : 0
    );

  const handleKeyPress = (e) => {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };

  const [vehicleList, setVehicleList] = useState([]);
  const getVehicleData = (formData) => {
    const data = vehicleNumberSuggestion({ vehicle_number: formData });
    data.then((res) => {
      setVehicleList(res == undefined ? [] : res);
    });
  };
  const currentDate = new Date();

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const formatPrice = (price, moneyformat) => {
    if (moneyformat) {
      const formattedPrice = Number(price).toFixed(2);
      return formattedPrice;
    } else {
      const formattedPrice = Number(price).toFixed(2);
      return `${configData?.serviceadmin?.currency_code} ${formattedPrice}`;
    }
  };

  const lowerCasedVehicles = vehicleList.map((vehicle) => {
    return {
      name: vehicle.vehicle_registration_number.toUpperCase(),
    };
  });

  function getSuggestions(value) {
    return (
      vehicleList &&
      lowerCasedVehicles.filter((vehicle) =>
        vehicle.name.includes(value.trim().toUpperCase())
      )
    );
  }

  const getCustomerData = (formData) => {
    const data = getDataByVehicleNumber({ vehicle_number: formData });
    data.then((res) => {
      if (res) {
        setIsVehicleRes(true);
      }
      setFormBookings({
        customer_name: res.customer.customer_name,
        customer_id: res.customer.customer_id_pk,
        phone_number: res.customer.phone_number,
        phone_extension: res.customer.phone_extension,
        vehicle_number: res.vehicle_registration_number,
        vehicle_manufacturer: res.vehicleModel.manufacturer,
        manufacturer_id: res.vehicleModel.manufacturer,
        vehicle_model: res.vehicleModel.model,
        model_id: res.vehicleModel.vehicle_model_id_pk,
        vehicle_color: res.color,
        vehicle_manufacture_year: res.manufactured_year ?? null,
        vehicleType: res.vehicleModel.vehicle_type_id,
        customer_vehicle_id_fk: res.customer_vehicle_id_pk,
        select_service: res.select_service,
      });
      if (res.vehicleModel.vehicle_type_id === 1) isvehicleType("Two Wheeler");
      else if (res.vehicleModel.vehicle_type_id === 2) {
        isvehicleType("Four Wheeler");
      } else {
        isvehicleType("");
      }
      getModelData(
        res.vehicleModel.vehicle_model_id_pk,
        res.vehicleModel.vehicle_type_id,
        res.vehicleModel.variant_id_fk
      );
    });
  };
  let vehicleTypeId = null;
  const getModelData = (mid, type, variant_id) => {
    if (vehicleType == "Four Wheeler") {
      vehicleTypeId = 2;
    } else if (vehicleType == "Two Wheeler") {
      vehicleTypeId = 1;
    } else {
      vehicleTypeId = 3;
    }
    const data = getVehicleModel(type);
    data
      .then((response) => {
        let anew = response.find((x) => x.vehicle_model_id_pk == mid);
        let manufacturers =
          response &&
          response
            .map((item) => ({
              label: item.manufacturer,
              value: item.manufacturer_id,
            }))
            .filter(
              (item1, index, all) =>
                all.indexOf(
                  all.find((item2) => item2.value === item1.value)
                ) === index ?? false
            );
        setManufacturer(manufacturers);
        setFilteredManufacture((prev) => ({
          ...anew,
          label: anew.model, //+ ", " + anew.manufacturer,
        }));
        // const modelObject = Object.assign({}, ...response);
        setModel(
          response
            .map((item) => ({
              ...item,
              label: item.model, // + ", " + item.manufacturer,
              model_id: item.vehicle_id,
            }))
            .filter((item1) => item1.manufacturer_id == anew.manufacturer_id)
        );

        setFormBookings((prevBooking) => ({
          ...prevBooking,
        }));
        const serviceData = getVehicleServices(variant_id);
        serviceData
          .then((response) => {
            const activeServices = response.filter(
              (item) => item.is_active === 1
            );
            setServiceOptions(
              activeServices.map((item) => ({
                ...item,
                label: item.service_name,
                price: item.price,
              }))
            );
          })
          .catch((handleError) => {
            console.log(handleError.message);
          });
      })
      .catch((handleError) => {
        toast.warning(handleError.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };
  const backToView = () => {
    setViewReadOnly(true);
    setEditMode(false);
    setShowBookingButton(false);
    props?.bookingformClose(false);
  };
  const QuoteClick = () => {
    setLgShow(true);
  };
  const handlePhoneNumberChange = (e) => {
    let inputphonenumber = e.target.value;

    // Prevent negative values
    if (inputphonenumber < 0) {
      inputphonenumber = "";
    } else {
      if (inputphonenumber.length <= 10) {
        setFormBookings({
          ...formBookings,

          phone_number: inputphonenumber,
        });
        SetFormErrors({
          ...formErrors,
          phone_number: "Phone number must be 10 digits.",
        });
      } else {
        SetFormErrors({
          ...formErrors,
          phone_number: "",
        });
      }
    }
  };
  let suggestionSelected = formBookings?.customer_vehicle_id_fk ? true : false;

  let selectedManufacturer =
    manufacturer?.length > 0 && filteredManufacture?.manufacturer_id
      ? manufacturer.find(
          (item) => item.value == filteredManufacture.manufacturer_id
        )
      : manufacturer?.length > 0 && formBookings?.manufacturer_id
      ? manufacturer.find((item) => item.value == formBookings.manufacturer_id)
      : manufacturer?.length > 0 &&
        mappedBookingsData.customerVehicle?.vehicleModel?.manufacturer_id_fk
      ? manufacturer.find(
          (item) =>
            item.value ==
            mappedBookingsData.customerVehicle?.vehicleModel?.manufacturer_id_fk
        )
      : {};
  useEffect(() => {
    if (viewReadOnly && mappedBookingsData?.discount_type_id_fk) {
      setDiscountType(mappedBookingsData.discount_type_id_fk);
    }
  }, [mappedBookingsData?.discount_type_id_fk]);

  let selecteddiscountType =
    viewReadOnly && mappedBookingsData?.discount_type_id_fk
      ? mappedBookingsData.discount_type_id_fk
      : discountType
      ? discountType
      : null;

  return (
    <FormWrapper className="" onSubmit={BookingsSubmitHandler}>
      <div className={viewReadOnly ? "col-12 view-mode" : "col-12"}>
        {/*Vehicle Number Block */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-sort-numeric-down"></i>Vehicle Number
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </span>
          </div>
          <div className="input-block">
            {!viewReadOnly && !editMode ? (
              <div style={{ display: "flex" }}>
                <DropdownWrapper>
                  <Select
                    isDisabled={viewReadOnly || editMode}
                    className="country-code"
                    classNamePrefix={"dropdown"}
                    options={countryRegion?.map((country) => ({
                      value: country.country_code,
                      label: `${country.country_name} (${country.country_code})`,
                    }))}
                    value={{ value: selectedCountry, label: selectedCountry }}
                    onChange={(selectedOption) => {
                      setSelectedCountry(selectedOption.value);
                    }}
                  />
                </DropdownWrapper>
                {selectedCountry === "UAE" && (
                  <DropdownWrapper>
                    <Select
                      isDisabled={viewReadOnly || editMode}
                      className="country-code"
                      classNamePrefix={"dropdown"}
                      options={options}
                      value={{ value: selectRegion, label: selectRegion }}
                      onChange={(selectedOption) =>
                        setSelectRegion(selectedOption.value)
                      }
                    />
                  </DropdownWrapper>
                )}
                <SuggestionWrapper>
                  <AutoSuggest
                    className="form-control"
                    suggestions={suggestions}
                    onSuggestionsClearRequested={() => setSuggestions([])}
                    onSuggestionsFetchRequested={({ value }) => {
                      let newList = suggestions.map((element) => {
                        return element;
                      });
                      setSuggestions(newList);
                      setValue(value);
                      setSuggestions(getSuggestions(value));
                    }}
                    onSuggestionSelected={(_, { suggestionValue }) => {
                      getCustomerData(suggestionValue);
                      SetFormErrors((prevErrors) => ({
                        ...prevErrors,
                        customer_name: "",
                        phone_number: "",
                        vehicleType: "",
                        manufacturer_id: "",
                        // Set vehicle_number to an empty string
                      }));
                    }}
                    getSuggestionValue={(suggestion) => suggestion.name}
                    renderSuggestion={(suggestion) => (
                      <span style={{ textTransform: "uppercase" }}>
                        {suggestion.name}
                      </span>
                    )}
                    inputProps={{
                      id: value === "" ? "empty-input" : "",
                      value: value,
                      maxLength: 13,
                      placeholder: "Enter Vehicle Number",
                      onChange: (_, { newValue, method }) => {
                        getVehicleData(newValue);
                        setValue(newValue);
                        setFormBookings({
                          ...formBookings,
                          vehicle_number: newValue,
                        });

                        SetFormErrors({ ...formErrors, vehicle_number: "" });
                      },
                      style: {
                        textTransform: "uppercase",
                        backgroundColor: "#E8E8E8",
                      },
                    }}
                    highlightFirstSuggestion={true}
                  />
                </SuggestionWrapper>
              </div>
            ) : (
              <VehicleNumberWrapper style={{ display: "flex" }}>
                <DropdownWrapper>
                  <Select
                    isDisabled={viewReadOnly || editMode}
                    className={`country-code ${
                      editMode ? "disable-select" : ""
                    }`}
                    classNamePrefix={"dropdown"}
                    options={countryRegion.map((country) => ({
                      value: country?.country_id_pk,
                      label: `${country.country_name} (${country.country_code})`,
                    }))}
                    value={{
                      value: formBookings.country_id,
                      label: formBookings.country_code,
                    }}
                    onChange={(selectedOption) => {
                      setSelectedCountry(selectedOption.value);
                    }}
                  />
                </DropdownWrapper>
                {formBookings.country_code === "UAE" && (
                  <DropdownWrapper>
                    <Select
                      isDisabled={viewReadOnly || editMode}
                      className={`country-code ${
                        editMode ? "disable-select" : ""
                      }`}
                      classNamePrefix={"dropdown"}
                      options={options}
                      value={{
                        value: formBookings.region_id,
                        label: formBookings.region_code,
                      }}
                      onChange={(selectedOption) =>
                        setSelectRegion(selectedOption.value)
                      }
                    />
                  </DropdownWrapper>
                )}
                <input
                  readOnly={viewReadOnly || editMode}
                  type="text"
                  className={`form-control drop-downForm ${
                    viewReadOnly || editMode ? "disable-input" : ""
                  }`}
                  value={formBookings.vehicle_number}
                  pattern="[A-Za-z0-9]{1,10}"
                  onChange={(e) => {
                    const inputvehiclenum = e.target.value;
                    if (inputvehiclenum.length <= 10) {
                      setFormBookings({
                        ...formBookings,
                        vehicle_number: inputvehiclenum,
                      });
                    }

                    SetFormErrors({ ...formErrors, vehicle_number: "" });
                  }}
                />
              </VehicleNumberWrapper>
            )}
            {error && formErrors.vehicle_number ? (
              <span className="form-error">{formErrors.vehicle_number}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Customer Name Block */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text required-field-icon">
              <i class="fal fa-user"></i>Customer Name
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </span>
          </div>
          <div className="input-block">
            <input
              readOnly={viewReadOnly || editMode}
              type="text"
              className={`form-control ${
                viewReadOnly || editMode ? "disable-input" : ""
              }`}
              value={formBookings.customer_name}
              onChange={(e) => {
                setFormBookings({
                  ...formBookings,
                  customer_name: e.target.value,
                });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                  // Clear the error when there's a value entered
                  SetFormErrors({ ...formErrors, customer_name: "" });
                } else {
                  SetFormChange(false);
                }

                // onChangeValidate(e.target.value);
              }}
              maxLength={100}
            />
            {error && formErrors.customer_name ? (
              <span className="form-error">{formErrors.customer_name}</span>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* Phone Number Block */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-phone"></i>Phone Number
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </span>
          </div>
          <div className="input-block">
            <div className="row m-0">
              <PhoneWrapper className="phone-wrapper">
                <Select
                  className={`phone-extension ${
                    editMode ? "disable-select" : ""
                  }`}
                  classNamePrefix={"dropdown"}
                  isDisabled={viewReadOnly || editMode}
                  options={phoneExtension}
                  styles={{
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: "white",
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      backgroundColor: "white",
                    }),
                  }}
                  value={
                    formBookings.phone_extension
                      ? {
                          label:
                            phoneExtension.find(
                              (option) =>
                                option.value === formBookings.phone_extension
                            )?.value ?? "",
                          value:
                            phoneExtension.find(
                              (option) =>
                                option.value === formBookings.phone_extension
                            )?.value ?? "",
                        }
                      : {
                          label: "+971",
                          value: "+971",
                        }
                  }
                  onChange={(selectedOption) => {
                    setFormBookings({
                      ...formBookings,
                      phone_extension: selectedOption.value,
                    });
                  }}
                />

                <input
                  readOnly={viewReadOnly || editMode}
                  type="number"
                  className={`form-control ${
                    viewReadOnly || editMode ? "disable-input" : ""
                  }`}
                  value={formBookings.phone_number}
                  onChange={(e) => {
                    handlePhoneNumberChange(e);
                  }}
                />
              </PhoneWrapper>
              {error && formErrors.phone_number ? (
                <span className="form-error">{formErrors.phone_number}</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {mappedBookingsData.orderCancel && mappedBookingsData.orderCancel ? (
          <div className="">
            <div className="col-12 block-header main-title mt-4">
              <h6>
                <span className="header-title">Reason for Cancelling</span>
              </h6>
            </div>
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i class="fal fa-car"></i>Cancelled by
                </span>
              </div>
              <h6>
                {/* Need change here */}
                {mappedBookingsData.orderCancel.cancelled_by == "sap"
                  ? "Service Admin"
                  : "Customer"}
              </h6>
            </div>
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i class="fal fa-car"></i>Reason
                </span>
              </div>
              <h6>
                {mappedBookingsData.orderCancel
                  ? mappedBookingsData.orderCancel.reason
                  : ""}
              </h6>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="col-12 block-header main-title mt-4">
          <h6>
            <span className="header-title">vehicle Details</span>
          </h6>
        </div>
        {/* vehicle Type Block */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-car"></i>Vehicle Type
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </span>
          </div>
          <VehicleWrapper
            className={error && formErrors.vehicleType ? "" : "vehicle-wrapper"}
          >
            <div className="ratio-wrapper">
              <div
                className={`radio-title mr-4 ${
                  viewReadOnly || editMode || suggestionSelected
                    ? "pointer-events-data"
                    : null
                }`}
              >
                <input
                  disabled={viewReadOnly}
                  type="radio"
                  id="twowheeler"
                  value="Two Wheeler"
                  name="vehicleType"
                  onChange={(e) => {
                    SetFormErrors({ ...formErrors, vehicleType: "" });
                    setFilteredManufacture((prev) => undefined);
                    isvehicleType(e.target.value);
                    setFormBookings({
                      ...formBookings,
                      manufacturer_id: "",
                      vehicle_model: "",
                      vehicleType: e.target.value === "Two Wheeler" ? 1 : 2,
                    });
                    setSelectService([]);
                    handleGetModel(e.target.value === "Two Wheeler" ? 1 : 2);
                  }}
                  checked={
                    formBookings.vehicleType && formBookings.vehicleType === 1
                      ? true
                      : vehicleType === "Two Wheeler"
                      ? true
                      : false
                  }
                />
                <LabelWrapper>
                  <label className="label-radio" htmlFor="twowheeler">
                    Two Wheeler
                  </label>
                </LabelWrapper>
              </div>
              <div
                className={`radio-title mr-4  ${
                  viewReadOnly || editMode || suggestionSelected
                    ? "pointer-events-data"
                    : null
                }`}
              >
                <input
                  disabled={viewReadOnly}
                  type="radio"
                  id="fourwheeler"
                  value="Four Wheeler"
                  name="vehicleType"
                  onChange={(e) => {
                    SetFormErrors({ ...formErrors, vehicleType: "" });
                    setFilteredManufacture((prev) => undefined);
                    isvehicleType(e.target.value);
                    setFormBookings({
                      ...formBookings,
                      manufacturer_id: "",
                      vehicle_model: "",
                      vehicleType: e.target.value === "Four Wheeler" ? 2 : 1,
                    });
                    setFilteredManufacture((prev) => undefined);
                    setSelectService([]);
                    handleGetModel(e.target.value === "Two Wheeler" ? 1 : 2);
                  }}
                  checked={
                    formBookings.vehicleType && formBookings.vehicleType === 2
                      ? true
                      : vehicleType === "Four Wheeler"
                      ? true
                      : false
                  }
                />
                <LabelWrapper>
                  <label className="label-radio" htmlFor="fourwheeler">
                    Four Wheeler
                  </label>
                </LabelWrapper>
              </div>
            </div>
            <div className="input-block">
              {error && formErrors.vehicleType ? (
                <span className="form-error">{formErrors.vehicleType}</span>
              ) : (
                ""
              )}
            </div>
          </VehicleWrapper>
        </div>
        {/* Manufactured  Block*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-sitemap"></i>Manufacturer
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <Containerdrop>
              <Select
                isDisabled={
                  viewReadOnly ||
                  editMode ||
                  suggestionSelected ||
                  !(formBookings.vehicleType || vehicleType.length)
                }
                className={`form-select p-0 ${
                  viewReadOnly ||
                  editMode ||
                  suggestionSelected ||
                  !(formBookings.vehicleType || vehicleType.length)
                    ? "disable-select"
                    : ""
                }`}
                classNamePrefix={"dropdown"}
                options={manufacturer}
                value={selectedManufacturer}
                defaultValue={selectedManufacturer}
                onChange={(e) => {
                  handleManufaturerChange(e.value);
                  setFilteredManufacture((prev) => undefined);
                  setFormBookings({
                    ...formBookings,
                    vehicle_model: "",
                    manufacturer_id: e.value,
                  });
                  setFilteredManufacture((prev) => undefined);
                  setSelectService([]);
                  SetFormErrors({
                    ...formErrors,
                    vehicle_manufacturer: "",
                    vehicle_model: "",
                  });
                }}
              />
            </Containerdrop>
            {error &&
            formErrors.vehicle_manufacturer &&
            selectService.length === 0 ? (
              <span className="form-error">
                {formErrors.vehicle_manufacturer}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Manufactured  Block*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-sitemap"></i>Model
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <Containerdrop>
              <Select
                isDisabled={
                  viewReadOnly ||
                  editMode ||
                  suggestionSelected ||
                  !(formBookings.manufacturer_id ?? false)
                }
                className={`form-select p-0 ${
                  viewReadOnly ||
                  editMode ||
                  suggestionSelected ||
                  !(formBookings.manufacturer_id ?? false)
                    ? "disable-select"
                    : ""
                }`}
                classNamePrefix={"dropdown"}
                options={model}
                value={filteredManufacture ? filteredManufacture : {}}
                onChange={(e) => {
                  setFormBookings({
                    ...formBookings,
                    vehicle_model: e.model,
                    vehicle_model_id_pk: e.vehicle_model_id_pk,
                    model_id: e.vehicle_model_id_pk,
                    variant_id_fk: e.variant_id_fk,
                  });
                  getModelData(
                    e.vehicle_model_id_pk,
                    formBookings.vehicleType,
                    e.variant_id_fk
                  );
                  SetFormErrors({
                    ...formErrors,
                    vehicle_model: "",
                  });
                }}
                getOptionLabel={(option) => option.model}
                getOptionValue={(option) => option.vehicle_model_id_pk}
              />
            </Containerdrop>
            {error && formErrors.vehicle_model && selectService.length === 0 ? (
              <span className="form-error">{formErrors.vehicle_model}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Model Block*/}
        {/* vehicle_manufacturer Block*/}
        <div style={{ display: "flex", gap: "30px" }}>
          <div
            className="input-group input-group-custom"
            style={{ flexWrap: "unset" }}
          >
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i class="fal fa-calendar-check"></i>Manufactured Year
              </span>
            </div>
            <div className="input-block">
              <CustomYearPicker
                readOnly={viewReadOnly}
                value={formBookings.vehicle_manufacture_year}
                onChange={(e) => {
                  setFormBookings({
                    ...formBookings,
                    vehicle_manufacture_year: e,
                  });
                }}
              />
            </div>
          </div>

          {/* vehicle_color Block*/}
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i class="fal fa-palette"></i>Color
              </span>
            </div>
            <input
              disabled={viewReadOnly}
              type="text"
              className={`form-control ${viewReadOnly ? "disable-input" : ""}`}
              value={formBookings.vehicle_color}
              maxLength={100}
              onChange={(e) =>
                setFormBookings({
                  ...formBookings,
                  vehicle_color: e.target.value,
                })
              }
            />
          </div>
        </div>
        <div className="col-12 block-header main-title mt-4">
          <h6>
            <span className="header-title">Service and Payment Details</span>
          </h6>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fal fa-calendar"></i>Date
                  {!viewReadOnly && <sup className="theme-text">*</sup>}
                </span>
              </div>

              <DatePicker
                disabled={viewReadOnly}
                selected={
                  formBookings.date ? new Date(formBookings.date) : new Date()
                }
                calendarIcon={null}
                clearIcon={null}
                format={"dd-MM-yyyy"}
                className={`form-control ${
                  viewReadOnly ? "disable-date" : ""
                } `}
                onChange={(e) =>
                  setFormBookings({
                    ...formBookings,
                    date: e,
                  })
                }
                value={
                  formBookings.date
                    ? formBookings.date
                    : dateFormat(new Date(), "yyyy-mm-dd")
                }
              />
              <div className="input-block">
                {error && formErrors.service_date && !formBookings.date ? (
                  <span className="form-error">{formErrors.service_date}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div
              className="input-group input-group-custom"
              style={{ flexWrap: "unset" }}
            >
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fal fa-clock"></i>Time
                  {!viewReadOnly && <sup className="theme-text">*</sup>}
                </span>
              </div>

              <CustomTimePicker
                disabled={viewReadOnly}
                maxDetail="second"
                format="HH:mm"
                clearIcon={null}
                clockIcon={null}
                disableClock={true}
                className={`form-control ${
                  viewReadOnly ? "disable-date" : ""
                } `}
                onChange={(e) => {
                  setFormBookings({
                    ...formBookings,
                    time: e,
                  });
                }}
                initialValue={
                  formBookings.time
                    ? formBookings.time
                    : dateFormat(new Date(), "HH:MM")
                }
              />
            </div>
            <div className="input-block">
              {error && formErrors.service_time && !formBookings.time ? (
                <span className="form-error">{formErrors.service_time}</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        {/* Select Service Block */}
        <div className="input-group input-group-custom align-start">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-hand-pointer"></i>
              {viewReadOnly ? "Selected Services" : "Select Service"}
              {!viewReadOnly && <sup className="theme-text">*</sup>}
            </span>
          </div>
          <div className="input-block">
            {!viewReadOnly && (
              <MultiWrapper>
                <Multiselect
                  className={!multiOpen ? "multiSelect-optionContainer" : ""}
                  options={serviceOptions}
                  avoidHighlightFirstOption
                  onSelect={handleSelectService}
                  selectedValues={selectService}
                  placeholder={
                    selectService.length === 0
                      ? "Choose Service"
                      : `${selectService.length} ${
                          selectService.length > 1 ? "Services" : "Service"
                        } Selected`
                  }
                  isObject
                  closeOnSelect
                  disable={formBookings.vehicle_model ? false : true}
                  disabled={viewReadOnly}
                  displayValue="label"
                  hideSelectedList
                  ref={multiselectRef}
                  showCheckbox
                  onRemove={(selectedList, removedItem) =>
                    uncheckService(selectedList, removedItem)
                  }
                  showArrow={!formBookings.vehicle_model ? false : true}
                  customArrow={<CustomArrow />}
                />
                {/* <img src={DropdownIcon} /> */}
              </MultiWrapper>
            )}
            {error && formErrors.service && selectService.length === 0 ? (
              <span className="form-error">{formErrors.service}</span>
            ) : (
              ""
            )}
            <div className="service-list">
              {Boolean(selectService.length) &&
                selectService.map((item) => {
                  return (
                    <div
                      className="col-main-list d-flex select-service-block"
                      data-id={item.service_id_pk}
                      key={item.service_id_pk}
                    >
                      <div className="form-group col-list-row">
                        <input
                          readOnly={viewReadOnly}
                          name="variant_name"
                          value={item.label}
                          type="text"
                          className={`form-control ${
                            viewReadOnly ? "disable-input" : ""
                          }`}
                          placeholder=""
                        />
                      </div>
                      <div className="form-group col-list-row">
                        <input
                          readOnly={viewReadOnly}
                          name="variant_name"
                          type="text"
                          className={`form-control ${
                            viewReadOnly ? "disable-input" : ""
                          }`}
                          placeholder="Price"
                          value={
                            item.price
                              ? item.price
                              : item.servicePriceDetails &&
                                item.servicePriceDetails.length &&
                                item.servicePriceDetails[0].price
                          }
                        />
                      </div>
                      <div className="cursor-pointer">
                        <span
                          onClick={(e) => removeService(e, item.service_id_pk)}
                          rel="tooltip"
                          data-for="removeService"
                          data-tip="Remove Service"
                          data-toggle="tooltip"
                          data-id={item.service_id_pk}
                        >
                          <i class="fal fa-times"></i>
                        </span>
                        <ReactTooltip
                          id="removeService"
                          place="left"
                          type="info"
                          effect="float"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>

          {/* Total Price*/}
          {Boolean(selectService.length) && (
            <div className="select-service-block w-100">
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text text-center">
                    <i class="fal fa-rupee-sign"></i>Total Price
                  </span>
                </div>
                <div className="input-block coupon-input">
                  <input
                    type="text"
                    disabled
                    className={`form-control ${
                      viewReadOnly ? "disable-input" : ""
                    }`}
                    value={totalPrice}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {/* Coupons*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-receipt"></i>Coupon Code
            </span>
          </div>

          <CouponWrapper className="input-block coupon-input">
            <input
              readOnly={viewReadOnly}
              type="text"
              className={`form-control ${viewReadOnly ? "disable-input" : ""}`}
              value={formBookings.coupon}
              maxLength={80}
              onChange={(e) =>
                setFormBookings({ ...formBookings, coupon: e.target.value })
              }
            />
          </CouponWrapper>

          {viewReadOnly ? (
            ""
          ) : (
            <ButtonWrapper className="ml-4" onClick={couponClick}>
              <button className="btn btn-primary coupon-label">
                {couponBtn}
              </button>
            </ButtonWrapper>
          )}

          {couponDisplay && (
            <div className="w-100 text-center">
              <h6 className="text-success"> {couponAmount}</h6>
            </div>
          )}
        </div>
        {/* Addditional Charges*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-hand-holding-usd"></i>Additional Charges
            </span>
          </div>
          <input
            onKeyPress={handleKeyPress}
            readOnly={viewReadOnly}
            type="number"
            className={`form-control ${viewReadOnly ? "disable-input" : ""}`}
            value={formBookings.additional_charges}
            onChange={(e) => {
              const inputAdditionalcharge = e.target.value;
              if (inputAdditionalcharge.length <= 10) {
                setFormBookings({
                  ...formBookings,
                  additional_charges: inputAdditionalcharge,
                });
              }
            }}
            min={0}
          />
        </div>
        {/* Discounts*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="far fa-percentage"></i>Additional Discounts
            </span>
          </div>
          <div className="radio-title mr-4">
            <input
              id="percentage"
              disabled={viewReadOnly}
              type="radio"
              value="1"
              name="discountType"
              onChange={() => setDiscountType("1")}
              checked={selecteddiscountType == 1 ? true : false}
            />
            <LabelWrapper>
              <label className="label-radio" htmlFor="percentage">
                Percentage
              </label>
            </LabelWrapper>
          </div>

          <div className="radio-title mr-4">
            <input
              id="amount"
              disabled={viewReadOnly}
              type="radio"
              value="2"
              name="discountType"
              onChange={() => setDiscountType("2")}
              checked={selecteddiscountType == 2 ? true : false}
            />
            <LabelWrapper>
              <label className="label-radio" htmlFor="amount">
                Amount
              </label>
            </LabelWrapper>
          </div>
          <input
            onKeyPress={handleKeyPress}
            readOnly={viewReadOnly}
            type="number"
            className={`form-control ${viewReadOnly ? "disable-input" : ""}`}
            value={formBookings.discount}
            disabled={!discountType}
            onBlur={(e) => {
              const inputValue = parseInt(e.target.value, 10);
              if (inputValue > 100 && selecteddiscountType == 1) {
                setFormBookings({ ...formBookings, discount: 0 });
              } else if (inputValue > totalPrice) {
                setFormBookings({ ...formBookings, discount: 0 });
              }
            }}
            onChange={(e) => {
              const inputdiscount = e.target.value;
              if (inputdiscount.length <= 10) {
                setFormBookings({ ...formBookings, discount: inputdiscount });
              }
            }}
            min={0}
            max={selecteddiscountType == 1 ? 100 : undefined}
          />
        </div>

        {/* vat tax */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="far fa-money-bill-alt"></i>
              {configData?.tax_type?.title}(
              {configData?.serviceadmin?.tax_percentage}%)
            </span>
          </div>
          <input
            readOnly={viewReadOnly}
            type="text"
            className={`form-control ${viewReadOnly ? "disable-input" : ""}`}
            value={tax}
            maxLength={100}
            onChange={(e) =>
              setFormBookings({
                ...formBookings,
                vehicle_color: e.target.value,
              })
            }
          />
        </div>
        {/* Grand Total*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-coins"></i>Grand Total
            </span>
          </div>

          <h6>{grandTotal ? grandTotal : 0}</h6>
        </div>
        {/* Partially Paid*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-money-check-alt"></i>

              {viewReadOnly ? "Advance Paid" : "Advance Pay"}
            </span>
          </div>
          <input
            disabled={grandTotal ? false : true}
            onKeyPress={handleKeyPress}
            readOnly={viewReadOnly}
            type="number"
            className={`form-control pointer-events ${
              viewReadOnly ? "disable-input" : ""
            }`}
            value={formBookings.partiallyPaidPrice}
            onChange={(e) => {
              const inputPartiallyPaid = e.target.value;
              if (inputPartiallyPaid.length <= 10) {
                setFormBookings({
                  ...formBookings,
                  partiallyPaidPrice: inputPartiallyPaid,
                });
              }
            }}
            min={1}
            max={grandTotal}
            onBlur={(e) => {
              const inputValue = parseInt(e.target.value, 10);
              if (inputValue > grandTotal) {
                setFormBookings({
                  ...formBookings,
                  partiallyPaidPrice: "",
                });
              }
            }}
          />
        </div>
        {/* Pending Payment*/}
        {formBookings.partiallyPaidPrice &&
        formBookings.partiallyPaidPrice !== "0" ? (
          <PaymentWrapper>
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i class="fal fa-dollar-sign"></i>Pending Payment
                </span>
              </div>
              <h6 className="payment-detail">
                {formBookings?.balance_amount === 0
                  ? formBookings?.balance_amount
                  : pendingPay
                  ? pendingPay
                  : 0}
              </h6>
            </div>

            {/* Mode of Payment */}
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text required-field-icon">
                  <i class="fal fa-wallet"></i>Mode of Payment
                  {!viewReadOnly && <sup className="theme-text">*</sup>}
                </span>
              </div>
              <PaymentModeWrapper
                className={
                  error && formErrors.payment_type ? "" : "payment-flex"
                }
              >
                <div className="mode-payment">
                  {configData?.payment_methods?.map((paymentMethod) => (
                    <div className="radio-title mr-4">
                      <input
                        disabled={viewReadOnly}
                        id={paymentMethod.title}
                        type="radio"
                        value={paymentMethod.payment_method_id_pk}
                        name="payMethod"
                        onChange={(e) => {
                          setPayMethod(e.target.value);
                          SetFormErrors({ ...formErrors, payment_type: "" });
                        }}
                        checked={
                          payMethod == paymentMethod.payment_method_id_pk
                        }
                      />
                      <LabelWrapper>
                        <label
                          className="label-radio"
                          htmlFor={paymentMethod.title}
                        >
                          {paymentMethod.title}
                        </label>
                      </LabelWrapper>
                    </div>
                  ))}
                </div>
                <div className="input-block">
                  {error && formErrors.payment_type ? (
                    <span className="form-error">
                      {formErrors.payment_type}
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </PaymentModeWrapper>
            </div>
          </PaymentWrapper>
        ) : (
          ""
        )}

        {/* Comments*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-comments-alt"></i>Comments
            </span>
          </div>
          <div className="input-block">
            <textarea
              readOnly={viewReadOnly}
              className={`form-control ${viewReadOnly ? "disable-input" : ""} `}
              value={formBookings.comments}
              onChange={(e) =>
                setFormBookings({
                  ...formBookings,
                  comments: e.target.value,
                })
              }
            ></textarea>
          </div>
        </div>
        {viewReadOnly && !showQuoteModal ? (
          ""
        ) : (
          <div className="d-flex justify-content-end mb-4 mt-4">
            <div>
              <>
                {showBookingButton ? (
                  <Button className="mr-3" onClick={QuoteClick}>
                    Quote
                  </Button>
                ) : (
                  ""
                )}
                <Modal
                  size="lg"
                  show={lgShow}
                  onHide={() => setLgShow(false)}
                  aria-labelledby="example-modal-sizes-title-lg"
                  className="modal-total-style"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg ">
                      <div style={{ display: "flex" }}>
                        <img
                          src={img}
                          alt="Logo"
                          className="mr-2"
                          style={{ marginTop: "-15px", width: "100px" }}
                        />
                        <h3>Quote</h3>
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <div
                    style={{
                      textAlign: "right",
                      marginRight: "20px",
                      fontSize: "14px",
                    }}
                  >
                    <div> Date: {formatDate(currentDate)}</div>
                    <div>Time: {currentDate.toLocaleTimeString()}</div>
                  </div>
                  <Modal.Body className="total-padding">
                    <div className="main-wrapper p-1">
                      <div bordered className="row m-0">
                        <div className="col-lg-6">
                          <Table className="info-style">
                            <thead>
                              <tr className="text-info">
                                <td className="text-left" colSpan={2}>
                                  <b className="info-border-style">
                                    Customer info
                                  </b>
                                </td>
                              </tr>
                            </thead>
                            <tr>
                              <td className="text-left ">Name: </td>
                              <td>{formBookings.customer_name}</td>
                            </tr>
                            <tr className="text-left ">
                              <td>Phone:</td>
                              <td> {formBookings.phone_number}</td>
                            </tr>
                          </Table>
                        </div>

                        <div className="col-lg-6">
                          <Table>
                            <thead>
                              <tr>
                                <td>
                                  <h6>
                                    <b className="vehicle-info-border-style">
                                      Vehicle info
                                    </b>
                                  </h6>
                                </td>
                              </tr>
                            </thead>
                            <tr>
                              <td>Vehicle number</td>
                              <td>{formBookings?.vehicle_number}</td>
                            </tr>
                            <tr>
                              <td>Vehicle model</td>
                              <td>{formBookings?.vehicle_model ?? ""}</td>
                            </tr>
                            <tr>
                              <td>Vehicle year</td>
                              <td>
                                {" "}
                                {formBookings?.vehicle_manufacture_year ?? null}
                              </td>
                            </tr>
                            <tr>
                              <td>Vehicle color</td>
                              <td>{formBookings?.vehicle_color}</td>
                            </tr>
                          </Table>
                        </div>
                      </div>
                      <div className="pre-sub-block">
                        <div className="mb-4" hover={true}>
                          <Table>
                            <thead>
                              <tr className="heading-style">
                                <th className="sl-style">Sl.no</th>
                                <th className="service-style">
                                  Service details
                                </th>
                                <th className="rate-style">
                                  Rate (
                                  {configData?.serviceadmin?.currency_code})
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectService.map((item, index) => (
                                <tr
                                  className={
                                    index === selectService.length - 1
                                      ? "service-row border-bottom"
                                      : "service-row"
                                  }
                                  key={item.service_id_pk}
                                >
                                  <td className="text-center">{index + 1}</td>
                                  <td>{item.label}</td>
                                  <td className="text-left">
                                    {formatPrice(item.price, true)}
                                  </td>
                                </tr>
                              ))}
                              <tr className="text-right">
                                <td></td>
                                <td>{"Sub Total"}</td>
                                <td className="text-left">
                                  {formatPrice(totalPrice)}
                                </td>
                              </tr>
                              {formBookings.additional_charges && (
                                <tr className="text-right">
                                  <td></td>
                                  <td>{"Additional charges"}</td>
                                  <td className="text-left">
                                    {formatPrice(
                                      formBookings.additional_charges
                                    )}
                                  </td>
                                </tr>
                              )}
                              {formBookings.discount && (
                                <tr className="text-right">
                                  <td></td>
                                  <td>{"Additional discount"}</td>
                                  <td className="text-left">
                                    {formatPrice(formBookings.discount)}
                                  </td>
                                </tr>
                              )}

                              <tr className="text-right text-vat vat-row  ">
                                <td></td>
                                <td>
                                  {configData?.tax_type?.title} {""}
                                  {configData?.serviceadmin?.tax_percentage}(%)
                                </td>
                                <td className="text-left">
                                  {" "}
                                  {formatPrice(tax)}
                                </td>
                              </tr>
                              <tr className="text-right grand-total-row ">
                                <td></td>
                                <td>
                                  <b>{"Grand Total"}</b>
                                </td>
                                <td className="text-left">
                                  {formatPrice(grandTotal)}
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </>
            </div>
            {showBookingButton ? (
              <div>
                <button
                  className="btn btn-secondary"
                  type="button"
                  onClick={backToView}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-primary ml-3"
                  type="submit"
                  // onClick={submitclick}
                >
                  {editMode ? "Update Booking" : "Create Booking"}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        {/* {cancelModal && <CancelModal setCancelModal={setCancelModal} />} */}
      </div>
      <BookingsFileUploadModal
        setShow={setShow}
        showfilemodal={show}
        hidefilemodal={handleClose}
      />
    </FormWrapper>
  );
};
export default BookingsForm;

const FormWrapper = styled.form`
  .required-field-icon {
    width: min-content;
    .theme-text {
      top: -18px !important;
    }
  }
  /* Hide spinners in Chrome, Safari, Edge, Opera */
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  /* Hide spinners in Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /* General input styles for better consistency */
  input[type="number"] {
    appearance: none;
  }
  .view-mode {
    .dropdown__indicators {
      display: none;
    }
  }
  .disable-select {
    .dropdown__indicators {
      display: none;
    }
  }
  .css-1fdsijx-ValueContainer {
    font-size: 14px;
  }
  .disable-input {
    color: #999999 !important;
  }
  .disable-date {
    input {
      color: #999999;
    }
    span {
      color: #999999;
    }
  }
  .css-16xfy0z-control {
    background-color: #e8e8e8;
  }
  .input-block {
    .css-1xc3v61-indicatorContainer {
      color: #666666 !important;
    }
  }
`;

const Containerdrop = styled.div`
  .css-13cymwt-control {
    background-color: #e8e8e8;
    /* width: 100px !important; */
  }
  .info-style {
  }
  .heading-style .sl-style {
    width: 100px !important;
  }
  .css-t3ipsp-control {
    background-color: #e8e8e8;
    border: none !important;
    box-shadow: none;
  }
`;
const DropdownWrapper = styled.div`
  .css-13cymwt-control {
    background-color: #e8e8e8;
    border-radius: unset;
    height: 38px;
  }
  .country-code {
    min-width: 100px;
  }
  .country-code {
    > div:nth-of-type(2) {
      min-width: calc(100% + 100px);
      max-width: calc(100% + 100px);
    }
  }
  .css-16xfy0z-control {
    border-radius: 0px !important;
    background: #e8e8e8;
    height: 38px;
  }
  .css-1fdsijx-ValueContainer {
    padding: 0px 8px;
  }
  .css-t3ipsp-control {
    box-shadow: unset;
    background: #e8e8e8;
    height: 38px;
  }
`;
const PhoneWrapper = styled.div`
  display: flex;
  padding-right: unset;
  .phone-extension {
    min-width: 105px;
    margin-left: -13px;
  }
  .phone-extension {
    > div:nth-of-type(2) {
      min-width: calc(100% + 105px);
      max-width: calc(100% + 105px);
    }
  }
  .phone-extension > div {
    background: #e8e8e8;
    min-height: 38px;
    height: 38px;
  }

  .form-control {
    margin-left: 5px;
    width: 75%;
  }
  .css-1n6sfyn-MenuList {
    background: #e8e8e8;
  }
  .css-t3ipsp-control {
    background-color: #e8e8e8;
    border: none !important;
    box-shadow: none;
  }
`;
const SuggestionWrapper = styled.div`
  width: -webkit-fill-available;
  background-color: #e8e8e8;
  .sc-fuTSoq &.iBbIvF {
    width: 100%;
  }
  #empty-input {
    text-transform: capitalize !important;
  }
`;
const VehicleNumberWrapper = styled.div`
  .drop-downForm {
    border-radius: 0px !important;
    height: 38px;
  }
`;
const MultiWrapper = styled.div`
  .multiSelect-optionContainer {
    ul {
      display: none !important;
    }
    li {
      display: flex;
      align-items: center;
    }
  }
  .checkbox {
    margin-top: unset !important;
  }
  .multiselect-container .searchWrapper {
    background: #e8e8e8;
  }
  .icon_down_dir:before {
    content: none;
  }
  .icon_down_dir {
    width: 26px !important;
    right: 5px !important;
  }
  .custom-arrow-container {
    display: flex;
    align-items: center;
  }

  .vertical-line {
    width: 1px;
    height: 20px;
    background-color: #cccccc;
    margin-right: 5px;
  }
`;
const PaymentWrapper = styled.div`
  .payment-detail {
    display: flex;
    align-items: center;
  }
`;
const LabelWrapper = styled.div`
  .label-radio {
    margin-bottom: 0px !important;
  }
`;
const PaymentModeWrapper = styled.div`
  &.payment-flex {
    display: flex;
    align-items: center;
  }
  .mode-payment {
    display: flex;
  }
  .input-block {
    width: unset !important;
  }
`;
const VehicleWrapper = styled.div`
  &.vehicle-wrapper {
    display: flex;
    align-items: center;
  }

  .ratio-wrapper {
    display: flex;
  }
  .input-block {
    width: unset !important;
  }
`;
const CouponWrapper = styled.div`
  width: calc(100% - 290px) !important;
`;
const ButtonWrapper = styled.div`
  .coupon-label {
    z-index: unset !important;
  }
`;
