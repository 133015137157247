import styled, { css } from "styled-components";

import DataTable, {
  PaginationOptions,
  TableColumn,
} from "react-data-table-component";
import {
  DataGrid,
  GridColDef,
  GridOverlay,
  GridToolbar,
  gridPageCountSelector,
  GridPagination,
  useGridApiContext,
  useGridSelector,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridValueGetterParams,
  GridRenderCellParams,
  GridRowParams,
  MuiEvent,
  GridCallbackDetails,
  GridPaginationModel,
} from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import MuiPagination from "@mui/material/Pagination";
import { TablePaginationProps } from "@mui/material/TablePagination";
import { Row } from "react-bootstrap";
import { VendorListModel } from "../../../../api/services/vap/models/VendorListModel";
import React, { useState } from "react";
// import dateFormat, { masks } from "dateformat";

type Props = {
  tabledata?: VendorListModel[];
  viewVendorClick: (
    e: GridRowParams,
    event: MuiEvent,
    details?: GridCallbackDetails
  ) => void;
  collapse: boolean;
  paginationTrigger: boolean;
  addVendor: boolean;
  setPaginationTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  pageLoader:boolean;
  setPageLoader: React.Dispatch<React.SetStateAction<boolean>>;
};

// type ColumnData = any[];

const WindowLeft = ({
  tabledata,
  viewVendorClick,
  collapse,
  paginationTrigger,
  setPaginationTrigger,
  addVendor,
  setPageLoader,
}: Props) => {
  const paginationComponentOptions: PaginationOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "10",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
    noRowsPerPage: true,
  };
  const UserplanText = styled.div`
    color: #ffa97d;
  `;
  const InactiveText = styled.div`
    color: #ffa97d;
  `;
// const [pendingCount, setPendingCount] = useState(0);
const [viewPending, setViewPending] = useState<boolean>(false);
  const [pendingCount, setPendingCount] = useState(0);
  const [activeButton, setActiveButton] = useState("all");

  function Pagination({
    page,
    onPageChange,
    className,
  }: Pick<TablePaginationProps, "page" | "onPageChange" | "className">) {
    const apiRef = useGridApiContext();
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <MuiPagination
        color="primary"
        className={className}
        count={10}
        page={1}
        onChange={(event, newPage) => {
          console.log(event, "event", newPage, "newPage");
          //   handleGetAllUsersList(newPage, 10, "DESC");
          //   onPageChange(event as any, newPage - 1);
        }}
      />
    );
  }
  console.log(tabledata, "zzzzz");
  function CustomPagination(props: any) {
    return (
      <GridPagination
        ActionsComponent={Pagination}
        {...props}
        rowsPerPage={10}
        count={10}
      />
    );
  }
  //function for no data available
  const customRowsOverlay = () => {
    return (
      <GridOverlay>
        <p>No Data Available</p>
      </GridOverlay>
    );
  };
  const columns: GridColDef[] = [
    {
      field: "",
      headerName: "Sl.No",
      width: 100,
      renderCell: (params: GridRenderCellParams) => (
        <strong>{params.row.id}</strong>
      ),
    },
    {
      field: "company_name",
      headerName: "Name",
      width: 150,
      //   editable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      //   editable: true,
    },
    {
      field: "phone",
      headerName: "Phone",
      type: "Number",
      width: 150,
      //   editable: true,
    },
    {
      field: "country",
      headerName: "Country",
      type: "number",
      width: 150,
      //   editable: true,
    },
    {
      field: "created_by",
      headerName: "Created By",
      // type: "number",
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <StyledCell className={`created-by-block ${params?.row?.created_by}`}>
          <div className="">
            <>
              {params?.row?.created_by === null
                ? "Self"
                : typeof params?.row?.created_by === "number"
                ? "Admin"
                : ""}
            </>
          </div>
        </StyledCell>
      ),
    },
    {
      field: "app_status",
      headerName: "Account Status",
      type: "number",
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <StyledCell
          className={`account-status-block ${
            params?.row?.status === 1
              ? "Open"
              : params?.row?.status === 2
              ? "Pending "
              : params?.row?.status === 3
              ? "Rejected"
              : params?.row?.status === 4
              ? "Active"
              : params?.row?.status === 5
              ? "docs-In-Review"
              : ""
          }`}
        >
          <div className="status">
            <strong>
              {params?.row?.created_by === "Admin"
                ? "Active"
                : params?.row?.status === 1
                ? "Open"
                : params?.row?.status === 2
                ? "In Review"
                : params?.row?.status === 3
                ? "Rejected"
                : params?.row?.status === 4
                ? "Verified"
                : params?.row?.status === 5
                ? "Document In Review"
                : ""}
            </strong>
          </div>
        </StyledCell>
      ),
    },
    {
      field: "ecommerce_status",
      headerName: "Ecommerce Status",
      type: "number",
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <StyledCell
          className={`ecommerce-status-block ${params?.row?.ecommerce_status}`}
        >
          <div className="status">
            <strong>
              {params?.row?.app_status === 0
                ? "Inactive"
                : params?.row?.app_status === 1
                ? "Active"
                : ""}
            </strong>
          </div>
        </StyledCell>
      ),
    },
  ];

  const PaginationClick = (
    model: GridPaginationModel,
    details: GridCallbackDetails
  ) => {
    setPaginationTrigger(!paginationTrigger);
    console.log(model, "model", details, "details");
  };

 

  return (
    <div className="content-window-left">
      <div className="content-table-block">
        <div className="content-table table-responsive single-column-view">
          <Box sx={{ overflowX: 'auto', maxWidth: '100%' }}>
            <StyledDataGrid
              className={`StyledDataGrid ${collapse ? "collapse-view" : ""}`}
              onRowClick={viewVendorClick}
              onPaginationModelChange={PaginationClick}
              rows={tabledata!}
              getRowId={(row) => row.vendor_id_pk}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              //   checkboxSelection
              disableRowSelectionOnClick
            />
          </Box>
        </div>
      </div>
    </div>
  );
};

export default WindowLeft;
const StyledDataGrid = styled(DataGrid)`
  background: transparent !important;
  border: none !important;
  &.MuiDataGrid-root {
    
    .MuiDataGrid-cell:focus-within {
      outline: none !important;
      /* outline-width: 1px;
      outline-offset: -1px; */
    }
  }
  .MuiDataGrid-columnHeaders {
    border-bottom: none !important;
    .MuiDataGrid-columnHeadersInner {
      width: 100%;
      & > div {
        width: 100% !important;
        justify-content: space-between !important;
        .MuiDataGrid-columnHeader {
          &:focus-within {
            outline: 1px solid transparent !important;
          }
          &:focus {
            outline: 1px solid transparent !important;
          }
          &.MuiDataGrid-withBorderColor {
            background: transparent;
            &.MuiDataGrid-columnHeader--alignRight {
              .MuiDataGrid-columnHeaderDraggableContainer {
                justify-content: flex-start !important;
                flex-direction: row !important;
                .MuiDataGrid-columnHeaderTitleContainer {
                  justify-content: flex-start;
                  flex-direction: row;
                }
              }
            }
          }
          .MuiDataGrid-columnHeaderDraggableContainer {
            .MuiDataGrid-columnHeaderTitleContainer {
              .MuiDataGrid-columnHeaderTitleContainerContent {
                .MuiDataGrid-columnHeaderTitle {
                  color: #9e9e9e !important;
                }
              }
            }
          }
        }
      }
    }
  }
  .MuiDataGrid-virtualScroller {
    .MuiDataGrid-virtualScrollerContent {
      .MuiDataGrid-virtualScrollerRenderZone {
        width: 100% !important;
        .MuiDataGrid-row {
          width: 100% !important;
          border-bottom: solid 1px #f5f5f5 !important;
          /* border-bottom: 1px solid rgba(224, 224, 224, 1) !important; */
          justify-content: space-between !important;
          background: #ffffff !important;
          .MuiDataGrid-cell.MuiDataGrid-withBorderColor {
            background: #ffffff !important;
            /* border: none !important; */
            border-bottom: solid 1px #f5f5f5 !important;
            justify-content: flex-start;
            &:not([role="cell"]) {
              display: none !important;
            }
          }
        }
        .MuiDataGrid-row[row-selected="true"] {
          background: #e3f2fd !important;
          .MuiDataGrid-cell {
            background: transparent !important;
          }
        }
      }
    }
  }
  &.collapse-view {
    .MuiDataGrid-columnHeadersInner {
      div {
        .MuiDataGrid-columnHeader {
          &:not(:nth-child(-n + 2)) {
            background: red !important;
            display: none !important;
          }
        }
      }
    }
    .MuiDataGrid-virtualScrollerContent {
      width: 100% !important;
      .MuiDataGrid-virtualScrollerRenderZone {
        width: 100%;
        .MuiDataGrid-row {
          width: 100%;
          .MuiDataGrid-cell {
            &:not(:nth-child(-n + 2)) {
              background: red !important;
              display: none !important;
            }
            &:nth-child(2) {
              width: 100% !important;
              max-width: 100% !important;
            }
          }
        }
      }
    }
  }
  .MuiDataGrid-footerContainer{
    min-height:2px;
  }.MuiDataGrid-withBorderColor {
    border: none !important;
  }
`;
// const StyledDataGrid = styled(DataGrid)`
//   background: transparent !important;
//   border: none !important;
//   &.MuiDataGrid-root {
//     .MuiDataGrid-cell:focus-within {
//       outline: none !important;
//       /* outline-width: 1px;
//       outline-offset: -1px; */
//     }
//   }
//   .MuiDataGrid-columnHeaders {
//     border-bottom: none !important;
//     .MuiDataGrid-columnHeadersInner {
//       width: 100%;
//       & > div {
//         width: 100% !important;
//         justify-content: space-between !important;
//         .MuiDataGrid-columnHeader {
//           &:focus-within {
//             outline: 1px solid transparent !important;
//           }
//           &:focus {
//             outline: 1px solid transparent !important;
//           }
//           &.MuiDataGrid-withBorderColor {
//             background: transparent;
//             &.MuiDataGrid-columnHeader--alignRight {
//               .MuiDataGrid-columnHeaderDraggableContainer {
//                 justify-content: flex-start !important;
//                 flex-direction: row !important;
//                 .MuiDataGrid-columnHeaderTitleContainer {
//                   justify-content: flex-start;
//                   flex-direction: row;
//                 }
//               }
//             }
//           }
//           .MuiDataGrid-columnHeaderDraggableContainer {
//             .MuiDataGrid-columnHeaderTitleContainer {
//               .MuiDataGrid-columnHeaderTitleContainerContent {
//                 .MuiDataGrid-columnHeaderTitle {
//                   color: #9e9e9e !important;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
//   .MuiDataGrid-virtualScroller {
//     .MuiDataGrid-virtualScrollerContent {
//       .MuiDataGrid-virtualScrollerRenderZone {
//         width: 100% !important;
//         .MuiDataGrid-row {
//           width: 100% !important;
//           border-bottom: solid 1px #f5f5f5 !important;
//           /* border-bottom: 1px solid rgba(224, 224, 224, 1) !important; */
//           justify-content: space-between !important;
//           background: #ffffff !important;
//           .MuiDataGrid-cell.MuiDataGrid-withBorderColor {
//             background: #ffffff !important;
//             /* border: none !important; */
//             border-bottom: solid 1px #f5f5f5 !important;
//             justify-content: flex-start;
//             &:not([role="cell"]) {
//               display: none !important;
//             }
//           }
//         }
//       }
//     }
//   }

//   &.collapse-view {
//     .MuiDataGrid-columnHeadersInner {
//       div {
//         .MuiDataGrid-columnHeader {
//           &:not(:nth-child(-n + 2)) {
//             background: red !important;
//             display: none !important;
//           }
//         }
//       }
//     }
//     .MuiDataGrid-virtualScrollerContent {
//       width: 100% !important;
//       .MuiDataGrid-virtualScrollerRenderZone {
//         width: 100%;
//         .MuiDataGrid-row {
//           width: 100%;
//           .MuiDataGrid-cell {
//             &:not(:nth-child(-n + 2)) {
//               background: red !important;
//               display: none !important;
//             }
//             &:nth-child(2) {
//               width: 100% !important;
//               max-width: 100% !important;
//             }
//           }
//         }
//       }
//     }
//   }
//   .MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor {
//     border: none !important;
//   }
// `;
const StyledCell = styled.div`
  width: 100%;
  &.created-by-block {
    text-align: start;
  }
  .status {
    padding: 6px 16px;
    border-radius: 15px;
    min-width: 50px;
    max-width: 80px;
  }
  &.true {
    .status {
      background: #009970;
      color: white;
    }
  }

  &.false {
    .status {
      background: #f67979;
      color: white;
    }
  }
  &.Active {
    .status {
      background: #009970;
      color: white;
    }
  }

  &.Rejected {
    .status {
      background: #f67979;
      color: white;
    }
  }
  &.Open {
    .status {
      background: #887e7e;
      color: #ffffff;
    }
  }
  &.Pending {
    .status {
      background: #79b3f6;
      color: white;
    }
  }
  .docs-In-Review {
    .status {
      background: #0d0d67;
      color: white;
    }
  }
`;
