import React, { useContext } from "react";
import { SuperContext } from "../SuperContext";
import "../../../Pages/User/Ui/css/Modal.css";
import exitConfirmation from "../../../../../assets/img/icon/upload/file-backup.svg"
function ConfirmModal(props) {
  const {
    SetMappedServiceData,
    SetEditMode,
    SetViewReadOnly,
    SetFormChange,
  } = useContext(SuperContext);

  const closeConfirm = (e) => {
    props.serviceFormClose(false);
    SetViewReadOnly(false);
    SetFormChange(false);
    SetEditMode(false);
    SetMappedServiceData({});
  };

  const setConfirmModal = (e) => {
    // SetFormChange(false);
    props.setConfirmModal(false);
  };

  return (
    <div className="action-modal">
      <div className="d-flex justify-content-end">
        <button className="btn close-btn" onClick={setConfirmModal}>
          <i className="fal fa-times"></i>
        </button>
      </div>
      <div className="modal-info-img">
  <img src={exitConfirmation} className="file-exit" alt="" />
</div>
  <h5>Are you sure you want to exit?</h5>

      <div className="d-flex justify-content-center pt-3">
        <button className="btn mr-3 btn-cancel" onClick={setConfirmModal}>
          Cancel
        </button>
        <button className="btn btn-danger" onClick={closeConfirm}>
          Confirm<i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  );
}

export default ConfirmModal;
