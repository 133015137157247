import React from 'react'
import { useState,useEffect } from 'react';
import "../../../../../assets/css/tags.css";


const Suggestions = (props) => {

  const [priceDetails, setPriceDetails] = useState([]);
  const [serviceTags, setServiceTags] = ([]);

  useEffect(()=>{
    let{
      servicePriceDetails,
      // service_tags,
      ...rest
    }=props.similarService
    
    // setServiceTags(service_tags.split(','));
    setPriceDetails(servicePriceDetails);

  },[props.index])
  return (
    <div className="suggestion-content">
    <div className="input-group">
     <div className="input-group-prepend">  
     <p><b>SERVICE NAME</b></p>
     </div>
     <p className='input-text'>{props.similarService.service_name}</p>  
    </div>
    <div className="input-group">
    <div className="input-group-prepend">  
     <p><b>SERVICE TAGS</b></p>
     </div>
     {props.similarService.service_tags.split(',').map((tag, index) => (
            <div className="tag-item suggestion-tag-item" key={index}>
                <span className="text">{tag}</span>
              </div>
            ))}
     {/* <p className='input-text'>{props.similarService.service_tags}</p>   */}
    </div>
    <div className="input-group">
    <div className="input-group-prepend mb-1">  
     <p><b>SERVICE DESCRIPTION</b></p>
     </div>
     <p>{props.similarService.service_description}</p>  
    </div>
    <div className="price-group mt-2">
    <div className="price-title">  
     <p><b>PRICES</b></p>
     </div>
     {props.similarService.common_price && 
     (<div className='common_price_section pl-2'>
      <div className="input-group">
     <div className="input-group-prepend">  
     <p><b>COMMON PRICE</b></p>
     </div>
     <p className='input-text'>{props.similarService.common_price}</p>  
    </div>     
     </div>)}
    {props.similarService.common_price == null && (
      <div className="variant_price_section pl-2">
        {Object.keys(priceDetails).length ? (priceDetails.map((a,index)=>(
        <div className="input-group">
        <div className="input-group-prepend">  
        <p><b>{a.vehicleVariant.variant_name.toUpperCase()}</b></p>
        </div>
        <p className='input-text'>{a.price}</p>  
        </div>
        ))) : ""}
      </div>
    )}
     {/* <p className='input-text'>{props.similarService.prices}</p> */}
    </div>
  </div>
  )
}

export default Suggestions;