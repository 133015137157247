import React, { useState, useContext, useEffect, Fragment } from "react";
import { VariantContext } from "./VariantContext";
import "../../../../assets/css/tags.css";
import { v4 as uuidv4 } from "uuid";
import {
  getVehicleTypes,
  getVehicleVariants,
} from "../../../../api/services/sup/service-management"
import { toast } from "react-toastify";


const initialState = {
  service_name: "",
  service_center_name:"",
  vehicle_select: "",
  service_description: "",
  product_description: "",
  service_discount: "",
  others: "",
  commonPrice: "",
};

function VariantForm(props) {
  const [serviceCenterName, SetServiceCenterName ] = useState("");
  const [EditServiceID, SetEditServiceID] = useState("");
  const [formData, SetFormData] = useState(initialState);
  const [priceDetails, setPriceDetails] = useState([]);
  const [producttags, SetProductTags] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([
    {
      title: "",
      value: "",
    },
  ]);
  const [vehicleVariants, setVehicleVariants] = useState([]);
  const [warrantyDetails, setWarrantyDetails] = useState({
    warranty_description: "",
    warranty_month: "",
    warranty_year: "",
  });
  const [show, setShow] = useState(false); //Image Modal
  const [servicetags, SetServiceTags] = useState([]);
  const [inputlist, SetInputList] = useState([
    {
      price: "",
      service_price_id_fk: "",
      variant_id_fk: "",
      variant_name: "",
    },
  ]); //Add Dynamic Variants
  const {
    isUpdateForm,
    mappedServiceData,
    SetMappedServiceData,
    viewReadOnly,
    editMode,
    SetEditMode,
    review,
    SetReview,
    servicePic,
    updateOrCreateService,
    isSelected,
    setIsSelected,
    SetFormChange,
  } = useContext(VariantContext);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const fetchVehicleTypes = () => {
    const returnedPromise = getVehicleTypes();
    returnedPromise.then((res) => {
      setVehicleTypes(
        res.map((item) => ({
          title: item.title,
          value: item.value,
        }))
      );
    });
  };
  const fetchVehicleVariants = () => {
    const returnedPromise = getVehicleVariants();
    returnedPromise.then((res) => {
      setVehicleVariants(
        res.map((item) => ({
          price: "",
          variant_id_pk: item.variant_id_pk,
          variant_name: item.variant_name,
          service_price_id_fk: "",
          vehicle_type_id_fk: item.vehicle_type_id_fk,
        }))
      );
    });
  };
  const addVariantPriceDetails = (i, variant_id_pk, price) => {
    const data = priceDetails.map((priceItem) => priceItem);
    data[i].price = price;
    setPriceDetails(data);
  };

  //Service Tag
  const addServiceTags = (event) => {
    if (event.key === "Enter") {
      SetServiceTags([...servicetags, event.target.value]);
      event.target.value = "";
      event.preventDefault();
    }
  };
  const removeServiceTags = (indexToRemove) => {
    SetServiceTags(servicetags.filter((_, index) => index != indexToRemove));
  };
  // Product Tag Input
  function addProductTags(e) {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    SetProductTags([...producttags, value]);
    e.target.value = "";
    e.preventDefault();
  }
  function removeProductTags(index) {
    SetProductTags(producttags.filter((el, i) => i !== index));
  }
  const updateToast = () => {
    return editMode ? "Updated Successfully" : "Added Successfully";
  };

  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputlist];
    list[index][name] = name === "price" ? parseInt(value) : value;
    SetInputList(list);
  };
  const handleaddclick = () => {
    SetInputList([...inputlist, { variant_name: "", price: "" }]);
  };
  const handleremove = (index) => {
    const list = [...inputlist];
    list.splice(index, 1);
    SetInputList(list);
  };
  //Form Submit
  const handleVariantForm = (e) => {
    toast.success(updateToast, {
      position: "bottom-right",
    });
    e.preventDefault();
    props.VariantFormClose(false);
    SetEditMode(false);
    SetMappedServiceData({});
    
  };

  const setPrices = (servicePriceDetails, vehicle_select) => {
    const extractPriceDetails = (item) => {
      return {
        price: item.price,
        variant_id_fk: item.vehicleVariant.variant_id_pk,
        variant_name: item.vehicleVariant.variant_name,
        service_price_id_fk: item.service_price_id_pk,
      };
    };
    let unapprovedVariantPrices = servicePriceDetails
      .filter((item) => item.status === 0 && item.vehicleVariant.status === 0)
      .map(extractPriceDetails);
    let approvedVariantPrices = servicePriceDetails
      .filter((item) => item.status === 1 && item.vehicleVariant.status === 1)
      .map(extractPriceDetails);
    setPriceDetails(() => {
      let newPriceDetails = vehicleVariants
        .filter((item) => item.vehicle_type_id_fk === vehicle_select)
        .map((item) => {
          const priceData = approvedVariantPrices.filter(
            (aItem) => aItem.variant_name === item.variant_name
          );
          item.price = priceData[0] ? priceData[0].price : "";
          item.service_price_id_fk = priceData[0]
            ? priceData[0].service_price_id_fk
            : "";
          return item;
        });
      return newPriceDetails;
    });
    SetInputList(unapprovedVariantPrices ? unapprovedVariantPrices : [{}]);
  };

  useEffect(() => {
    if (
      !(
        mappedServiceData &&
        Object.keys(mappedServiceData).length === 0 &&
        Object.getPrototypeOf(mappedServiceData) === Object.prototype
      )
    ) {
      let {
        product_used,
        service_id_fk: id,
        servicePriceDetails,
        profile,
        service_tags,
        serviceWarrantyDetail,
        vehicleType,
        vehicle_type_id_fk: vehicle_select,
        ...rest
      } = mappedServiceData;

      setPrices(servicePriceDetails, vehicle_select);
  
      SetServiceCenterName(profile.service_center_name);

      SetEditServiceID(id);
      SetServiceTags(service_tags ? service_tags.split(",") : []);
      SetProductTags(product_used ? product_used.split(",") : []);
      
      SetFormData({ ...rest, vehicle_select, service_center_name:serviceCenterName });
      setWarrantyDetails({
        warranty_description: serviceWarrantyDetail.warranty_description,
        warranty_month: serviceWarrantyDetail.validupto_month,
        warranty_year: serviceWarrantyDetail.validupto_year,
      });
    }

    return () => {
      SetFormData(initialState);
      SetServiceTags([]);
      SetProductTags([]);
      SetInputList([{ variant_name: "", price: "" }]);
      SetEditMode(false);
      // SetMappedServiceData({});
    };
  }, [vehicleVariants]);

  useEffect(() => {
    console.log("second", vehicleVariants);
   fetchVehicleTypes();
    fetchVehicleVariants();
  }, [props.addservice, mappedServiceData]);

  return (
    <div className="col-12">
      <form className="w-100" id="add-service">
        {/* Service Image */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend d-flex align-items-center">
            <span className="input-group-text">
              <i className="fal fa-image"></i>Photo
            </span>
          </div>

          <div className="small-block">
            {viewReadOnly ? null : (
              <button type="button" className="btn-image" onClick={handleShow}>
                Upload
              </button>
            )}

            {servicePic &&
              servicePic.map((photo, index) => {
                return (
                  <div className="small-img" key={index}>
                    <img
                      src={photo.base64}
                      className="img-fluid"
                      alt=""
                      height="100"
                      width="100"
                    />
                  </div>
                );
              })}
          </div>
        </div>
        {/* Serice Name */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-cog"></i>Service Centre Name
            </span>
          </div>
          <input
            type="text"
            readonly
            className="form-control"
            placeholder=""
            aria-label="Username"
            aria-describedby="basic-addon1"
            readOnly={viewReadOnly}
            value={formData.service_center_name}
          />
        </div>
        {/* Vehichle Type */}
        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-car"></i>Vehicle Type
            </span>
          </div>
          <select
            id="warranty_period"
            className="form-select"
            disabled={viewReadOnly}
            value={formData.vehicle_select}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              let newPriceDetails = vehicleVariants.filter(
                (item) => item.vehicle_type_id_fk === value
              );
              setPriceDetails(newPriceDetails);
              SetFormData({ ...formData, vehicle_select: value });
            }}
          >
            <option value="" disabled>
              Select Type
            </option>
            {vehicleTypes.map((item) => (
              <option value={item.value}>{item.title}</option>
            ))}
            <option value="-1">Others</option>
          </select>
        </div>
        {/* Service Description */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-cog"></i>Service Description
            </span>
          </div>
          <textarea
            className="form-control"
            readOnly={viewReadOnly}
            value={formData.service_description}
            onChange={(e) =>
              SetFormData({ ...formData, service_description: e.target.value })
            }
          ></textarea>
        </div>
        {/* Service Tags */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-tag"></i>Service Tags
            </span>
          </div>
          <div className="tags-input-container">
            {servicetags.map((tag, index) => (
              <div className="tag-item" key={index}>
                <span className="text">{tag}</span>
                {viewReadOnly ? null : (
                  <span
                    onClick={() => removeServiceTags(index)}
                    className="close"
                  >
                    &times;
                  </span>
                )}
              </div>
            ))}

            <input
              onKeyDown={addServiceTags}
              readOnly={viewReadOnly}
              type="text"
              className="tags-input"
              placeholder="Eg. Car Wash..."
            />
          </div>
        </div>
        {/* Product Description */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-boxes"></i>Products Description
            </span>
          </div>
          <textarea
            className="form-control"
            value={formData.product_description}
            readOnly={viewReadOnly}
            onChange={(e) =>
              SetFormData({ ...formData, product_description: e.target.value })
            }
          ></textarea>
        </div>
        {/* Product Used Tags */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-tag"></i>Products Used
            </span>
          </div>
          <div className="tags-input-container">
            {producttags.map((tag, index) => (
              <div className="tag-item" key={index}>
                <span className="text">{tag}</span>
                {viewReadOnly ? null : (
                  <span
                    className="close"
                    onClick={() => removeProductTags(index)}
                  >
                    &times;
                  </span>
                )}
              </div>
            ))}

            <input
              readOnly={viewReadOnly}
              onKeyDown={addProductTags}
              type="text"
              className="tags-input"
              placeholder="Eg. Detailing Products..."
            />
          </div>
        </div>
        {/* Warranty Details Block */}
        <div className="col-12 block-header main-title mt-4">
          <h6>
            <span className="header-title">Warranty Details</span>
          </h6>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-certificate"></i>Warranty Description
            </span>
          </div>
          <textarea
            className="form-control"
            value={warrantyDetails.warranty_description}
            readOnly={viewReadOnly}
            onChange={(e) =>
              setWarrantyDetails({
                ...warrantyDetails,
                warranty_description: e.target.value,
              })
            }
          ></textarea>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-calendar"></i>Valid Upto
            </span>
          </div>
          <div className="form-width">
            <div className="row m-custom">
              <div className="col-md-6">
                <select
                  value={warrantyDetails.warranty_month}
                  disabled={viewReadOnly}
                  onChange={(e) =>
                    setWarrantyDetails({
                      ...warrantyDetails,
                      warranty_month: e.target.value,
                    })
                  }
                  id="warranty_period"
                  className="form-select"
                >
                  <option value="0">0 Month</option>
                  <option value="1">1 Month</option>
                  <option value="2">2 Months</option>
                  <option value="3">3 Months</option>
                  <option value="4">4 Months</option>
                  <option value="5">5 Months</option>
                  <option value="6">6 Months</option>
                  <option value="7">7 Months</option>
                  <option value="8">8 Months</option>
                  <option value="9">9 Months</option>
                  <option value="10">10 Months</option>
                  <option value="11">11 Months</option>
                </select>
              </div>
              <div className="col-md-6">
                <div className="form-group m-0">
                  <select
                    value={warrantyDetails.warranty_year}
                    disabled={viewReadOnly}
                    onChange={(e) =>
                      setWarrantyDetails({
                        ...warrantyDetails,
                        warranty_year: e.target.value,
                      })
                    }
                    id="warranty_period"
                    className="form-select"
                  >
                    <option value="0">0 Year</option>
                    <option value="1">1 Year</option>
                    <option value="2">2 Year</option>
                    <option value="3">3 Year</option>
                    <option value="4">4 Year</option>
                    <option value="5">5 Year</option>
                    <option value="6">6 Year</option>
                    <option value="7">7 Year</option>
                    <option value="8">8 Year</option>
                    <option value="9">9 Year</option>
                    <option value="10">10 Year</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Price Details Block */}
        <div className="col-12 block-header main-title mt-4">
          <h6>
            <span className="header-title">Price Details</span>
          </h6>
        </div>
        {formData.vehicle_select &&
          formData.vehicle_select === 1 &&
          priceDetails
            .filter(
              (item) => item.vehicle_type_id_fk === formData.vehicle_select
            )
            .map((item, i) => {
              return (
                <div className="input-group input-group-custom">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fal fa-motorcycle"></i>
                      {item.variant_name}
                    </span>
                  </div>
                  <input
                    type="text"
                    readonly
                    className="form-control"
                    placeholder=""
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    readOnly={viewReadOnly}
                    value={item.price ? item.price : ""}
                    onChange={(e) => {
                      addVariantPriceDetails(
                        i,
                        item.variant_id_pk,
                        parseInt(e.target.value)
                      );
                    }}
                  />
                </div>
              );
            })}
        {formData.vehicle_select &&
          formData.vehicle_select === 2 &&
          priceDetails
            .filter(
              (item) => item.vehicle_type_id_fk === formData.vehicle_select
            )
            .map((item, i) => (
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-car"></i>
                    {item.variant_name}
                  </span>
                </div>
                <input
                  type="text"
                  readOnly={viewReadOnly}
                  className="form-control"
                  placeholder=""
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={item.price ? item.price : ""}
                  onChange={(e) => {
                    addVariantPriceDetails(
                      i,
                      item.variant_id_pk,
                      parseInt(e.target.value)
                    );
                  }}
                />
              </div>
            ))}
        {formData.vehicle_select === 3 &&
          priceDetails
            .filter(
              (item) => item.vehicle_type_id_fk === formData.vehicle_select
            )
            .map((item, i) => (
              <div className="input-group input-group-custom">
                <div className="input-group-prepend">
                  <span className="input-group-text">
                    <i className="fal fa-bus"></i>
                    {item.variant_name}
                  </span>
                </div>
                <input
                  type="text"
                  readOnly={viewReadOnly}
                  className="form-control"
                  placeholder="price"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  value={item.price ? item.price : ""}
                  onChange={(e) => {
                    addVariantPriceDetails(
                      i,
                      item.variant_id_pk,
                      parseInt(e.target.value)
                    );
                  }}
                />
              </div>
            ))}
        {/* Add variants */}
        {inputlist.map((x, i) => {
          return (
            <Fragment>
              <div className="col-12 p-0">
                <div className="row input-list-row">
                  {x && (
                     <Fragment>
                      <div className="form-group col-md-6 mb-0">
                        <input
                          name="variant_name"
                          value={x.variant_name}
                          readOnly={viewReadOnly}
                          type="text"
                          className="form-control"
                          placeholder="Variant Name"
                          onChange={(e) => handleinputchange(e, i)}
                        />
                      </div>
                      <div className="form-group col-md-6 mb-0">
                        <input
                          name="price"
                          type="text"
                          value={x.price}
                          readOnly={viewReadOnly}
                          placeholder="Price"
                          className="form-control"
                          onChange={(e) => handleinputchange(e, i)}
                        />
                      </div>
                      </Fragment>
                  )}
                  {viewReadOnly ? null : (
                    <div className="button-remove">
                      {inputlist.length !== 1 && (
                        <span onClick={() => handleremove(i)}>Remove</span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {viewReadOnly ? null : (
                <div class="form-group button-add mt-3 text-right">
                  {inputlist.length - 1 == i && (
                    <button
                      type="button"
                      className="btn"
                      onClick={handleaddclick}
                    >
                      <i className="fal fa-plus"></i> Add Variants
                    </button>
                  )}
                </div>
              )}
            </Fragment>
          );
        })}
        <div className="or-div">
          <span className="or-span">OR</span>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-money-bill-alt"></i>Common Price
            </span>
          </div>
          <input
            type="text"
            readonly
            className="form-control"
            placeholder=""
            aria-label="Username"
            aria-describedby="basic-addon1"
            readOnly={viewReadOnly}
            value={formData.commonPrice}
            onChange={(e) =>
              SetFormData({ ...formData, commonPrice: e.target.value })
            }
            //   onChange={(e) => SetFormData({ ...formData, name: e.target.value })
            // }
          />
        </div>
      </form>
      {/* <div className="table-section d-flex justify-content-center" >
      <table className="table custom-table clickable-table w-75" >
              <thead>
                <tr>
                  <th scope="col" className="text-center">Service Name</th>
                  <th scope="col" className="text-center">Tags</th>
                </tr>
              </thead>
              <tbody>
                <td>asd</td>
                <td>asd</td>
               
              </tbody>
            </table>
      </div> */}
    </div>
  );
}

export default VariantForm;
