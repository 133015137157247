import React, { useState } from "react";
import BookingsSortDropdownContent from "./BookingsSortDropdownContent";
const BookingsSortDropdown = () => {
  const [sortview, Setsortview] = useState(false);
  return (
    <div className="sort-dropdown">
      <div className="dropdown sort-custom-dropdown">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={() => Setsortview(!sortview)}
        >
          <span className="Bookings-sort-btn sort-btn">
            Sort <i className="fal fa-sort"></i>
          </span>
        </button>
        {sortview && <BookingsSortDropdownContent />}
      </div>
    </div>
  );
};

export default BookingsSortDropdown;
