import React, { useEffect, useRef, useState } from "react";
import Layout from "../../Layout/Layout";
import ContentHeader from "../../Layout/ContentHeader";
import styled from "styled-components";
import SupFeatureForm from "./components/SupFeaturedForm";
import FeatureListCard from "./components/FeatureListCard";
import ContentTitleWithButton from "../components/Layouts/ContentTitleWithButton";
import WindowRightLayout from "../components/Layouts/WindowRightLayout";
import {
  getFeaturedList,
  getSingleFeature,
  createOrUpdateFeature,
  deleteFeature,
} from "../../../../api/services/sup/featured-management";
import {
  FeatuerdListModel,
  FeatuerdRequestModel,
  FeatuerdViewModel,
} from "./model/featuredModels";
import ConfirmationModal from "../../../ServiceAdmin/components/ConfirmationModal";
import deleteFile from "../../../../assets/img/icon/upload/recycle-bin.svg";
import { toast } from "react-toastify";
import SimpleReactValidator from "simple-react-validator";

type Props = {};

const initialFeatuerdRequestModel: FeatuerdRequestModel = {
  image: null,
  name: "",
  sortingOrder: 1,
  textPositionId: null,
  crossCellCount: '',
  mainCellCount: '',
};
export const SupFeaturePage = (props: Props) => {
  const [featuerdList, setFeatuerdList] = useState<FeatuerdListModel[]>([]);
  const [formData, setFormData] = useState<FeatuerdRequestModel>(
    initialFeatuerdRequestModel
  );
  const [isRemovedViewImage, setIsRemovedViewImage] =
    React.useState<boolean>(false);

  const [prevFormData, setPrevFormData] = useState<FeatuerdRequestModel>();
  const [rightView, setRightView] = useState(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [isAddNew, setIsAddNew] = React.useState<boolean>(false);
  const [deleteModalToggle, setdeleteModalToggle] =
    React.useState<boolean>(false);
  const [image, setImage] = useState<
    (
      | {
          base64: string;
          type: "image";
        }
      | { videoSrc: string; type: "video" }
    )[]
  >([]);
  const handleCreateOrUpdateFeature = (payload: FeatuerdRequestModel) => {
    let formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      if (key !== "image") {
        if (value !== undefined) {
          formData.append(key, String(value));
        }
      }
      if (key === "image" && value !== undefined) {
        formData.append("image", payload.image as any);
      }
    }

    createOrUpdateFeature(formData)
      .then((res) => {
        if (res) {
          console.log(res, "Create/Edit Success Response");
          toast.success(
            isAddNew ? "Created Successfully" : "Updated Successfully",
            {
              className: "error-toast",
              position: "bottom-right",
            }
          );
          handleGetFeaturedList();
          setIsRemovedViewImage(false);
        }
      })
      .catch((err) => {
        console.log(err, "response Featured list");
      });
  };
  const handleDeleteFeatured = (id: number) => {
    deleteFeature(id)
      .then((res) => {
        if (res) {
          console.log(res, "Delete Success Response");
          toast.success("Deleted Successfully", {
            className: "error-toast",
            position: "bottom-right",
          });
          handleGetFeaturedList();
          setdeleteModalToggle(false);
          setIsRemovedViewImage(false);
          setIsAddNew(false);
          setIsEdit(false);
          setRightView(false);
        }
      })
      .catch((err) => {
        console.log(err, "response Featured list");
      });
  };
  const handleGetFeaturedList = () => {
    getFeaturedList()
      .then((res: FeatuerdListModel[]) => {
        if (res) {
          setFeatuerdList(res);
        }
      })
      .catch((err) => {
        console.log(err, "response Featured list");
      });
  };
  const handleGetSingleFeature = (id: number) => {
    getSingleFeature(id)
      .then((res: FeatuerdViewModel) => {
        if (res) {
          setFormData({
            featuredId: res.featuredId,
            image: { url: res.imageUrl },
            name: res.name,
            sortingOrder: res.sortingOrder,
            textPositionId: res.textPositionId,
            crossCellCount: res.crossCellCount,
            mainCellCount: res.mainCellCount,
          });
          setPrevFormData({
            featuredId: res.featuredId,
            image: { url: res.imageUrl },
            name: res.name,
            sortingOrder: res.sortingOrder,
            textPositionId: res.textPositionId,
            crossCellCount: res.crossCellCount,
            mainCellCount: res.mainCellCount,
          });
        }
      })
      .catch((err) => {
        console.log(err, "response Featured list");
      });
  };
  const validator = useRef(new SimpleReactValidator());
  const handleAddFeature = () => {
    validator.current.hideMessages();
    setImage([]);
    setIsRemovedViewImage(false);
    setFormData(initialFeatuerdRequestModel);
    setIsAddNew(true);
    setRightView(true);
    setIsEdit(true);
  };
  const handleViewClick = (e: any, id: number) => {
    setImage([]);
    setIsRemovedViewImage(false);
    handleGetSingleFeature(id);
    setIsAddNew(false);
    setRightView(true);
    setIsEdit(false);
  };
  const onEditClick = () => {
    setIsEdit(true);
    setIsAddNew(false);
  };
  const handleFeatureDelete = () => {
    setdeleteModalToggle(true);
  };
  const onDeleteCancel = () => {
    setdeleteModalToggle(false);
  };
  const onDeleteConfirm = () => {
    handleDeleteFeatured(formData.featuredId ?? 0);
  };
  const handleCancelClick = () => {
    setImage([]);
    if (isAddNew) {
      setFormData(initialFeatuerdRequestModel);
      setRightView(false);
      setIsAddNew(false);
    } else {
      setIsEdit(false);
      setIsRemovedViewImage(false);
      setFormData(prevFormData!);
    }
  };
  const handleBackClick = () => {
    setIsAddNew(false);
    setIsEdit(false);
    setRightView(false);
    setFormData(initialFeatuerdRequestModel);
  };
  const handleSave = () => {
    handleCreateOrUpdateFeature(formData);
    setIsAddNew(false);
    setIsEdit(false);
    setRightView(false);
  };

  useEffect(() => {
    handleGetFeaturedList();
  }, []);
  return (
    <>
      <Layout>
        <Wrapper className="content-block">
          <section className="main-section">
            <div className="container-fluid p-0">
              <ContentHeader />
            </div>
            <div className="col-12 p-0">
              <ContentTitleWithButton
                handleAddClick={handleAddFeature}
                buttonTitle={"Add New Feature"}
                heading={"Featured"}
              />
              <div className="content-window">
                <LeftWindowWrapper
                  className={`${rightView ? "right-window-active" : ""}`}
                >
                  <FeatureListCard
                    features={featuerdList ?? []}
                    onViewClick={handleViewClick}
                    className="whole-card-wrapper"
                  />
                </LeftWindowWrapper>
                {rightView && (
                  <WindowRightLayout
                    isExpand={rightView}
                    heading={
                      isAddNew
                        ? "Create Featured Item"
                        : isEdit
                        ? "Edit Featured Item"
                        : "View Featured Item"
                    }
                    backToolTip="Back to Items"
                    handleCloseView={handleBackClick}
                    childrenRight={
                      isEdit ? (
                        <></>
                      ) : (
                        <ButtonWrapper>
                          <button
                            onClick={onEditClick}
                            className="btn btn-action btn-edit"
                            id="backBtn"
                            rel="tooltip"
                            data-for="editTooltip"
                            data-tip="Edit service"
                          >
                            <i className="fal fa-pen"></i>
                          </button>
                          <button
                            onClick={handleFeatureDelete}
                            className="btn btn-action btn-edit"
                            id="backBtn"
                            rel="tooltip"
                            data-for="deleteTooltip"
                            data-tip="Delete service"
                          >
                            <i className="fal fa-trash"></i>
                          </button>
                        </ButtonWrapper>
                      )
                    }
                  >
                    <SupFeatureForm
                      isEdit={isEdit}
                      isAddNew={isAddNew}
                      formData={formData}
                      setFormData={setFormData}
                      handleCancelClick={handleCancelClick}
                      isRemovedViewImage={isRemovedViewImage}
                      setIsRemovedViewImage={setIsRemovedViewImage}
                      onSave={handleSave}
                      setImage={setImage}
                      image={image}
                      validator={validator}
                    />
                  </WindowRightLayout>
                )}
              </div>
            </div>
          </section>
          <ConfirmationModal
            show={deleteModalToggle}
            Btn1Label="Cancel"
            Btn2Label="Confirm"
            label={`Are you sure you want to delete "${formData.name}" ?`}
            src={deleteFile}
            onClickConfirm={onDeleteConfirm}
            onClickCancel={onDeleteCancel}
            onHide={onDeleteCancel}
          />
        </Wrapper>
      </Layout>
    </>
  );
};

export default SupFeaturePage;
const LeftWindowWrapper = styled.div`
  width: 100%;
  overflow: auto;
  height: calc(100vh - 105px);
`;
const Wrapper = styled.div`
  .content-window {
    display: flex;
    gap: 15px;
  }
  /* .row-img {
    display: none;
  } */
  .right-window-active {
    width: unset !important;
    .whole-card-wrapper {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
const ButtonWrapper = styled.div``;
