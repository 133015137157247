import React, { useState, useContext, useEffect } from "react";
import BookingsRow from "./BookingsRow";
import { BookingsContext } from "./BookingsContext";
import "../../Layout/Ui/css/Pagination.css";
import noDataImage from "../../../../assets/img/icon/upload/empty-box.svg";
import DataTable from "react-data-table-component";
import dateFormat, { masks } from "dateformat";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";

const BookingsWindowLeft = (props) => {
  const {
    BookingsTable,
    SetBookingsTable,
    mappedBookingsData,
    setMappedBookingsData,
    viewBookings,
    setViewBookings,
    dateRange,
    setDateRange,
    filterValue,
    setFilterValue,
    bookDate,
    setBookDate,
    filterBookingshandler,
    setViewReadOnly,
    bookTime,
    setBookTime,
    currentDate,
    setCurrentDate,
    cancelData,
    searchTerm,
    setSearchTerm,
  } = useContext(BookingsContext);


  const [mainFilter, setMainFilter] = useState([]);

  const [selectBookDate, setSelectBookDate] = useState([
    dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
    dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
  ]);

  // console.log(
  //   selectBookDate,
  //   "--------------------selectBookDate--------------------"
  // );
  useEffect(() => {
    setFilterValue(
      BookingsTable.filter((item) =>
        dateFormat(
          new Date(item.date),
          "UTC:yyyy-mm-dd HH:MM",
          "yyyy-mm-dd"
        ).includes(dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM", "yyyy-mm-dd"))
      )
    );
  }, [BookingsTable]);

  const handleViewBookings = () => {
    setViewBookings(true);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Filas por página",
    rangeSeparatorText: "10",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
    noRowsPerPage: 0,
  };

  const [calendar, setCalendar] = useState(false);

  const nextDay = dateFormat(
    new Date(
      new Date(bookDate[0]).setDate(new Date(bookDate[0]).getDate() + 1)
    ),
    "yyyy-mm-dd HH:MM"
  );
  const prevDay = dateFormat(
    new Date(
      new Date(bookDate[0]).setDate(new Date(bookDate[0]).getDate() - 1)
    ),
    "yyyy-mm-dd HH:MM"
  );
  let startDate = dateFormat(new Date(bookDate[0]), "fullDate");
  let endDate = dateFormat(new Date(bookDate[1]), "fullDate");

  const columns = [
    {
      name: "Service Center Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row.phone_number,
      sortable: true,
    },
    {
      name: "Total Bookings",
      selector: (row) => row.total_bookings,
      sortable: true,
    },
    {
      name: "date",
      selector: (row) =>
        dateFormat(new Date(row.date), "UTC:yyyy-mm-dd HH:MM", "yyyy-mm-dd"),
      sortable: true,
    },
    // {
    //   name: "time",
    //   selector: (row) => row.time,
    //   sortable: true,
    // },
    // {
    //   name: "Status",
    //   selector: (row) => "booked",
    //   sortable: true,
    //   cell: (row) =>
    //     <div>
    //       {row.cancelData != undefined ?
    //         <div>
    //           {row.cancelData.cancelled == "Service Admin" ? <span className="badge bg-danger">Rejected</span> : <span className="badge bg-secondary">Cancelled</span>}
    //         </div>
    //         : <span className="badge bg-primary">Booked</span>

    //       }
    //     </div>,
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    // }
  ];
  const chooseDate = (dateValue) => {
    if (dateValue === nextDay) {
      setBookDate([dateValue, dateValue]);
      filterBookingshandler(dateValue);
    }
    if (dateValue === prevDay) {
      setBookDate([dateValue, dateValue]);
      filterBookingshandler(dateValue);
    }
  };
  const dateRangeFilterhandler = (e) => {
    setSelectBookDate(e);
  };
  const calendarOk = () => {
    if (
      dateFormat(selectBookDate[0], "yyyy-mm-dd") ===
      dateFormat(selectBookDate[1], "yyyy-mm-dd")
    ) {
      setFilterValue(
        BookingsTable.filter((item) =>
          dateFormat(item.date, "yyyy-mm-dd").includes(
            dateFormat(selectBookDate[0], "yyyy-mm-dd")
          )
        )
      );
    } else {
      setFilterValue(
        BookingsTable.filter(
          (item) =>
            dateFormat(item.date, "yyyy-mm-dd") >=
              dateFormat(selectBookDate[0], "yyyy-mm-dd") &&
            dateFormat(item.date, "yyyy-mm-dd") <=
              dateFormat(selectBookDate[1], "yyyy-mm-dd")
        )
      );
    }
    setBookDate(selectBookDate);

    setCalendar(false);
    setSelectBookDate([
      dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
      dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
    ]);
  };
  const currentDateHandler = () => {
    setBookDate([
      dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
      dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
    ]);
  };
  const viewCalendar = () => {
    setCalendar(true);
  };

  const viewData = (item) => {
    setMappedBookingsData(item);
    setViewReadOnly(true);
    props.bookingformClose(true);
  };
  const searchMe = [
    {
      name: "Salman",
      age: 24,
      father: "Rashid",
    },
    {
      name: "Vishnu",
      age: 24,
      father: "Shankar",
    },
    {
      name: "Vishnu",
      age: 24,
      father: "Anand",
    },
    {
      name: "Riya",
      age: 24,
      father: "George",
    },
  ];

  const filterData = BookingsTable.filter((event) => {
    if (searchTerm === "") {
      return event;
    } else if (event.name.toLowerCase().includes(searchTerm.toLowerCase())) {
      return event;
    }
  }).map((item) => {
    return item;
  });
  console.log(filterData, "filterData");
  console.log(typeof filterData, "type filterData");
  // setMainFilter(() => [...mainFilter , filterData]);
  // console.log(mainFilter , "MainFilter")
  return (
    <div className="content-window-left">
      <div className="content-table-block">
        <div className="content-table table-responsive single-column-view">
          <div className="date-block-main">
            <button
              className="btn btn-none"
              id=""
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title="Add New"
              onClick={viewCalendar}
            >
              <span>
                <i className="fal fa-calendar"></i>
              </span>
            </button>
            {startDate !== endDate ? (
              <button onClick={currentDateHandler}>Today</button>
            ) : (
              ""
            )}
            <div className="date-block">
              <div className="date-control">
                <div
                  className={`date-control-sub ${
                    startDate === endDate ? "" : "disabled-block"
                  }`}
                >
                  <div
                    className="date-control-left"
                    onClick={() => chooseDate(prevDay)}
                  >
                    <i class="fal fa-caret-left"></i>
                  </div>
                  <div
                    className="date-control-right"
                    onClick={() => chooseDate(nextDay)}
                  >
                    <i class="fal fa-caret-right"></i>
                  </div>
                </div>
              </div>
              <div className="date">
                {startDate === endDate ? (
                  <h6>{startDate}</h6>
                ) : (
                  <h6>
                    {startDate} - {endDate}
                  </h6>
                )}
              </div>
            </div>
          </div>

          <DataTable
            onRowClicked={viewData}
            columns={columns}
            data={filterData}
            // data={filterValue}
            pagination
            paginationComponentOptions={paginationComponentOptions}
          />
          {calendar && (
            <div className="popup-main">
              <div className="popup-sub">
                <div className="popup-content">
                  <div className="popup-header"></div>
                  <div className="popup" id="popupDiv">
                    <Calendar
                      selectRange={true}
                      goToRangeStartOnSelect={true}
                      onChange={(e) => dateRangeFilterhandler(e)}
                      value={selectBookDate}
                    />
                  </div>
                  <div className="popup-footer">
                    <button className="btn btn-light">Cancel</button>
                    <button className="btn btn-primary" onClick={calendarOk}>
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BookingsWindowLeft;
