import React, { useState } from "react";
import ProductFilterDropdownContent from "./ProductFilterDropdownContent";
import Popover from "@mui/material/Popover";
type Props = {};

const ProductFilterDropdown = (props: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className="filter-dropdown">
      <div className="dropdown filter-custom-dropdown">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <span className="filter-btn">
            Filter<i className="fal fa-filter"></i>
          </span>
        </button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <ProductFilterDropdownContent />
        </Popover>
      </div>
    </div>
  );
};

export default ProductFilterDropdown;
