import React, { useContext, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import ReactTooltip from "react-tooltip-rc";
import VendorForm from "./VendorForm"; // Import your VendorForm component
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import styled from "styled-components";
import { Button, Popover } from "@mui/material";
import SupOutlinedButton from "../../../SupOutlinedButton";
// import Button from "@mui/material/Button"; //verify popup
// import { styled } from '@mui/material/styles';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import Typography from "@mui/material/Typography"; //verify popup
import { VendorContext } from "./context/VendorContext";
import {
  getVendorDetails,
  getVendorDocumentDetails,
  VendorSaveChangesButton,
  VendorVerifyDetails,
} from "../../../../api/services/sup/vendor-management";
import {
  SaveChangesDataModel,
  SaveChangesModel,
} from "./model/SaveChangesModel";
// import { Button, Popover } from "@mui/material";
// import SupOutlinedButton from "../../../../../SupOutlinedButton";
// import { ButtonProps } from "../../../SupOutlinedButton";
import mainLoader from "../../../../assets/img/loader/loader.gif";

type Props = {
  dataStored?: any;
  closeView: () => void;
  viewVendor: boolean;
  addVendor: boolean;
  SetMappedMappedData?: React.Dispatch<React.SetStateAction<undefined>>;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  setAddActivestep: React.Dispatch<React.SetStateAction<number>>;
  addActiveStep: number;
  setRightView: React.Dispatch<React.SetStateAction<boolean>>;
  setListStatus: React.Dispatch<React.SetStateAction<boolean>>;
  listStatus: boolean;
  pageLoader: boolean;
  setPageLoader: React.Dispatch<React.SetStateAction<boolean>>;
  SetAddVendor: React.Dispatch<React.SetStateAction<boolean>>;
};
type verifyVendorPayload = {
  vendor_id?: number;
  status?: number;
};

const WindowRight = ({
  dataStored,
  closeView,
  viewVendor,
  SetMappedMappedData,
  setActiveStep,
  activeStep,
  addVendor,
  setAddActivestep,
  addActiveStep,
  setRightView,
  setListStatus,
  listStatus,
  pageLoader,
  setPageLoader,
  SetAddVendor,
}: Props) => {
  const { documentsData, setDocumentsData, saveChangesData } =
    useContext(VendorContext);

  // const [activeStep, setActiveStep] = React.useState(0);
  console.log(activeStep, "activeStep");
  const [mappedDocumentsData, SetMappedDocumentsData] = React.useState();
  const [verifyVendorPaylaod, setVerifyVendorPaylaod] =
    React.useState<verifyVendorPayload>({
      vendor_id: undefined,
      status: undefined,
    });
  const [showRejectVendorButton, setShowRejectVendorButton] =
    React.useState(true);
  const [showSaveChangesButton, setShowSaveChangesButton] =
    React.useState(true);
  const [showVerifyButton, setShowVerifyButton] = React.useState(true);
  const [showGrantAccessButton, setShowGrantAccessButton] =
    React.useState(true);
  const [openRejectDialog, setOpenRejectDialog] = React.useState(false); //rejectdialog
  const boolVal = React.useRef(false); //rejectdialog

  type Props = { onNextClick?: () => void };
  const steps = ["Registration", "Documentation"];
  const [open, setOpen] = React.useState(false); //verify popup
  function handleSubmitClick() {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  }

  const [saveChangesClicked, setSaveChangesClicked] = React.useState(
    localStorage.getItem("saveChangesClicked") === "true" || false
  );

  const [status, setStatus] = React.useState<number | undefined>(
    localStorage.getItem("status")
      ? Number(localStorage.getItem("status"))
      : undefined
  );

  const handleClickOpen = (status: number) => {
    // Verify popup
    if (status === 1) {
      setOpen(true);
    } else if (status === 2) {
      VendorVerifyDetails({
        vendor_id: dataStored.vendor_id_pk,
        status: status,
      })
        .then((res) => {
          if (res) {
            console.log(res, "response VendorVerifyDetails");
            setOpen(true);
          }
        })
        .catch((err) => {
          console.log(err, "response VendorVerifyDetails error");
        });
    } else if (status === 3) {
      VendorVerifyDetails({
        vendor_id: dataStored.vendor_id_pk,
        status: status,
      })
        .then((res) => {
          if (res) {
            console.log(res);
            setOpenRejectDialog(false);
            //  setShowVerifyButton(false);
            //  setShowGrantAccessButton(false);
            // setShowSaveChangesButton(false);
            //  setShowRejectVendorButton(false)
            handleRejectlist(null);
            toast.success("Vendor Rejected Successfully", {
              position: "top-right",
            });
          }
        })
        .catch((err) => {
          console.log(err, "vendor access  error");
        });
    } else if (status === 4) {
      VendorVerifyDetails({
        vendor_id: dataStored.vendor_id_pk,
        status: status,
      })
        .then((res) => {
          if (res) {
            console.log(res, "response Grand vendor access Details");
            handleRejectlist(null); // change func name this is for getdetails
            // closeView();
          }
          toast.success("Success", {
            position: "top-right",
          });
        })
        .catch((err) => {
          console.log(err, "response Grand vendor access  error");
        });
    }
  };

  const handleClose = () => {
    //verify popup
    setOpen(false);
  };
  const handleVerifyOkClick = () => {
    getVendorDetails(dataStored?.vendor_id_pk)
      .then((res) => {
        if (res) {
          console.log(res, "response afterVerify getVendorDetails");
          toast.success("Credentials send to vendor successfully", {
            position: "top-right",
          });
          SetMappedMappedData?.(res);
        }
      })
      .catch((err) => {
        console.log(err, "response afterVerify getVendorDetails error");
      });

    getVendorDocumentDetails(dataStored?.vendor_id_pk)
      .then((res) => {
        if (res) {
          console.log(res, "response getVendorDocuments");
          setDocumentsData(res);
        }
      })
      .catch((err) => {
        console.log(err, "response getVendorDocuments error");
      });

    if (activeStep === 0) {
      // If on the registration step, proceed to the next step document
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setShowVerifyButton(false);
      setShowGrantAccessButton(true);
    }
    handleClose();
  };
  const handleStatusClick = (status: number) => {
    setOpenRejectDialog(true);
    // statusChange(status);
  };
  const handleVerifyVendor = (status: number) => {
    // setOpenRejectDialog(true);
    statusChange(3);
  };

  const handleCloseRejectDialog = () => {
    setOpenRejectDialog(false);
  };

  const handleViewVenodrStepperClick = (i: number) => {
    if (i === 1) {
      setPageLoader(true);
      getVendorDocumentDetails(dataStored?.vendor_id_pk)
        .then((res) => {
          if (res) {
            console.log(res, "response getVendorDocuments");
            setDocumentsData(res);
            setPageLoader(false);
          }
        })
        .catch((err) => {
          console.log(err, "response getVendorDocuments error");
        });
    }
    setActiveStep(i);
  };

  const handleAddVendorStepperClick = (index: number) => {
    setActiveStep(index); // Assuming you want to set the active step
  };

  const statusChange = (statusId: number) => {};

  console.log(documentsData, "documentsData wr");
  console.log(mappedDocumentsData, "mappedDocumentsData wr");

  const handleRejectlist = (e: any) => {
    // let vendorId = e.row.vendor_id_pk; // vendor id from  row clicked
    getVendorDetails(dataStored.vendor_id_pk)
      .then((res) => {
        if (res) {
          console.log(res, "response getVendorDetails");

          SetMappedMappedData?.(res);
        }
      })
      .catch((err) => {
        console.log(err, "response getVendorDetails error");
      });
    console.log(e, "row-event");
  };

  const handleSaveChanges = () => {
    console.log(saveChangesData, "saveChangesData00");

    // VendorSaveChangesButton(saveChangesData)
    VendorSaveChangesButton({documents:saveChangesData})
      .then((result) => {
        setSaveChangesClicked(true);
        localStorage.setItem("saveChangesClicked", "true");
        console.log("result", result); // need to preflight call error
        if (result) {
          VendorVerifyDetails({
            vendor_id: dataStored.vendor_id_pk,
            status: 5,
          })
            .then((res) => {
              if (res) {
                console.log(res, "response Grand vendor access Details");

                toast.success("Changes saved", {
                  position: "top-right",
                });
                closeView();
              }
            })
            .catch((err) => {
              console.log(err, "response Grand vendor access  error");
            });
        } else {
          toast.success("Changes saved", {
            position: "top-right",
          });
        }
      })
      .catch((error) => {
        console.error("Error saving changes:", error);
      });
  };
  console.log(saveChangesData, "saveChangesData00");

  React.useEffect(() => {
    // Update the status when the component mounts
    setStatus(
      localStorage.getItem("status")
        ? Number(localStorage.getItem("status"))
        : undefined
    );
  }, []);

  const isGrandButtonDisabled = (dataArray: SaveChangesDataModel[]) => {
    // Check if the "Save Changes" button is not clicked
    // or if dataArray is empty or any item in dataArray has a status other than 1
    return (
      !saveChangesClicked ||
      dataArray.length === 0 ||
      dataArray.some(item => item.status !== 1)
    );
  };

  // let boolVal;
  React.useEffect(() => {
    isGrandButtonDisabled(saveChangesData);
  }, [saveChangesData]);
  console.log(boolVal, "saveChangesDatasaveChangesData boolVal");

  return (
    <>
      {pageLoader ? (
        <div className="loader-block">
          <img className="img-fluid" src={mainLoader} alt="" />
        </div>
      ) : (
        <div className="content-window-right">
          <div className="content-right-block">
            <div className="card-right">
              <div className="header-block">
                <div className="header-sec">
                  <div className="content-right-top">
                    <button
                      onClick={closeView}
                      className="btn btn-action btn-back"
                      rel="tooltip"
                      data-for="backAction"
                      data-tip="Close"
                      data-toggle="tooltip"
                      data-placement="top"
                    >
                      <i className="fal fa-long-arrow-left"></i>
                    </button>
                    <ReactTooltip
                      id="backAction"
                      place="bottom"
                      type="info"
                      multiline={true}
                    />

                    <VendorTitle>
                      {viewVendor
                        ? `View ${dataStored?.company_name}`
                        : "Add Vendor"}
                    </VendorTitle>

                    {/* <button onClick={handleEdit}>edit</button> */}
                  </div>
                </div>
              </div>
            </div>
            <Scrollbars style={{ height: `calc(82vh - 15px)` }}>
          <div className="name-block">
            <div
              className="sub-block"
              style={{
                padding: "15px",
                alignItems: "center",
                width: "80%",
                justifyContent: "center",
              }}
            >
              <div
                className="block-name"
                style={{
                  width: "96%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "fixed",
                  background: "#fff",
                  zIndex: 2,
                  marginTop: "20px",
                  left: "0%",
                }}
              >
                <Box sx={{ width: "50%", padding: "15px 10px" }}>
                  <div className="nav-bar">
                    {viewVendor && (
                      <StyledStepper activeStep={activeStep}>
                        {steps.map((label, index) => (
                          <Step key={label}>
                            <StepLabel
                              onClick={() =>
                                handleViewVenodrStepperClick(index)
                              }
                              className=""
                            >
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </StyledStepper>
                    )}

                    {addVendor && (
                      <StyledStepper activeStep={addActiveStep}>
                        {steps.map((label, index) => (
                          <Step key={label}>
                            <StepLabel
                              onClick={() => handleAddVendorStepperClick(index)}
                              className=""
                            >
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </StyledStepper>
                    )}
                  </div>
                </Box>
              </div>
            </div>
          </div>
          <div className="row-right">
            <VendorForm
              setActiveStep={setActiveStep}
              activeStep={activeStep}
              dataStored={dataStored}
              docData={documentsData}
              addVendor={addVendor}
              viewVendor={viewVendor}
              setAddActiveStep={setAddActivestep}
              addActiveStep={addActiveStep}
              setRightView={setRightView}
              setListStatus={setListStatus}
              listStatus={listStatus}
              SetAddVendor={SetAddVendor}
              setSaveChangesClicked={setSaveChangesClicked}
            />
            {/* verify popup */}
            <div>
              <CustomStyledDialog
                className="customDialog"
                open={open}
                onClose={handleClose}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 9,
                    top: 9,
                    color: (theme: any) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                  <DialogWrapper className="dialogContent">
                    <span>Vendor Details Verified</span>
                    <VeriftyOkButton
                      className="okbutton"
                      autoFocus
                      onClick={handleVerifyOkClick}
                    >
                      OK
                    </VeriftyOkButton>
                  </DialogWrapper>
                </DialogContent>
              </CustomStyledDialog>
            </div>
            {/* Reject Popup */}
            <div>
              <CustomStyledDialog
                className="customDialog"
                open={openRejectDialog}
                onClose={handleCloseRejectDialog}
              >
                <IconButton
                  aria-label="close"
                  onClick={handleCloseRejectDialog}
                  sx={{
                    position: "absolute",
                    right: 9,
                    top: 9,
                    color: (theme: any) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                  <CustomDialogWrapper className="dialogContent">
                    <span>Are you sure you want to reject the vendor?</span>
                    <div className="reject-ok-buttons">
                      <CancelButton onClick={handleCloseRejectDialog}>
                        Cancel
                      </CancelButton>
                      <OkButton autoFocus onClick={() => handleClickOpen(3)}>
                        OK
                      </OkButton>
                    </div>
                  </CustomDialogWrapper>
                </DialogContent>
              </CustomStyledDialog>
            </div>
            {viewVendor && (
              <div
                style={{
                  position: "relative",
                  height: "100%",
                  padding: "8px 16px",
                }}
              >
                <SubmitBtnWrapper className="SubmitBtnWrapper">
                  <div className={`reject-save-block`}>
                    {dataStored?.status !== 3 && dataStored?.status !== 4 && (
                      <SupOutlinedButton
                        buttonType="reject"
                        onClick={() => handleStatusClick(3)}
                      >
                        Reject vendor
                      </SupOutlinedButton>
                    )}
                    {activeStep === 1 &&
                      dataStored?.status !== 3 &&
                      dataStored?.status !== 4 && (
                        <SupOutlinedButton
                          buttonType="save"
                          onClick={handleSaveChanges}
                        >
                          Save Changes
                        </SupOutlinedButton>
                      )}
                  </div>
                  <div className="end-block">
                    {dataStored?.status === 1 &&
                    dataStored?.status !== 3 &&
                    dataStored?.status !== 4 ? (
                      <VeriftyButton
                        className="verifyButton"
                        variant="outlined"
                        onClick={() => handleClickOpen(2)}
                      >
                        Verify Vendor Details
                      </VeriftyButton>
                    ) : (
                      dataStored?.status === 2 &&
                      dataStored?.status !== 3 &&
                      dataStored?.status !== 4 &&
                      activeStep === 1 && (
                        <StyledButton
                          className="grandDisablebtn"
                          variant="contained"
                          onClick={() => handleClickOpen(4)}
                          disabled={isGrandButtonDisabled(saveChangesData)}
                        >
                          Grant vendor access
                        </StyledButton>
                      )
                    )}
                  </div>
                </SubmitBtnWrapper>
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </div>
         
      )}
    </>
  );
};

export default WindowRight;
const VendorTitle = styled.h4`
  white-space: nowrap;
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const RejectReasonTooltip = styled.div`
  position: absolute;
  background-color: #fff;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;

  &:hover {
    visibility: visible;
    opacity: 1;
  }
`;
const CustomDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 27%;
  span {
    text-align: center;
    padding-bottom: 24px;
    font-weight: bold;
  }
  .reject-ok-buttons {
    display: flex;
    padding-top: 8%;
  }
`;
const CancelButton = styled(Button)`
  &.MuiButtonBase-root {
    background: #ccc;
    color: #333;
    align-items: center;
    width: 100px;
    margin-right: 10px;
  }
  &:hover {
    background: #aaa !important;
  }
`;

const OkButton = styled(Button)`
  &.MuiButtonBase-root {
    background: #f67979;
    color: white;
    align-items: center;
    width: 100px;
    margin-left: 30px;
  }

  &:hover {
    background: #d9534f !important;
  }
`;
const CustomStyledDialog = styled(Dialog)`
  &.customDialog {
    .MuiDialog-container {
      .MuiPaper-root {
        height: 300px;
        width: 400px;
      }
    }
  }
`;
const DialogWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  padding-top: 18%;
  span {
    text-align: center;
    padding-bottom: 96px;
    font-weight: bold;
  }
`;
const VeriftyButton = styled(Button)`
  &.MuiDialogContent-root {
    .MuiTypography-root {
      margin-top: 70px;
    }
  }
  &.verifyButton {
    background: rgb(1, 87, 155);
    color: white;
    align-items: center;
    border: 1px solid rgb(1, 87, 155) !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    min-width: 150px !important;

    border-radius: 5px !important;
    box-shadow: none !important;
    padding: 10px 16px !important;
    height: 40px !important;
  }
  &:hover {
    background: rgb(25 118 210) !important;
  }
`;
const VeriftyOkButton = styled(Button)`
  &.okbutton {
    background: rgb(41 59 199);
    color: white;
    align-items: center;
    width: 100px;
    margin-left: 30px;
  }
  &:hover {
    background: rgb(41 59 199) !important;
  }
`;

const SubmitBtnWrapper = styled.div`
  width: 98%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0%;
  right: 0%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background: white;
  position: fixed;
  bottom: 0px;
  z-index: 2;

  /* box-shadow: 0px 1px 15px 1px #f5f5f5; */
  .reject-save-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0 0px 0px;
    /* left: 0%; */
    .reject {
      margin: 0 20px 0 0;
    }
  }
  .end-block {
    padding: 10px 0px 0px 0px;
    button {
    }
  }
`;

const Wrapper = styled.div`
  padding: 2px 2px;
  width: fit-content;
  max-width: 100%;
  &.button-wrapper {
    .MuiButtonBase-root {
      font-size: 12px;
      font-weight: 600;
      max-height: 40px;
      color: #f67979 !important;
      border: 1px solid #f67979 !important;
      text-transform: uppercase !important;
      letter-spacing: 1px !important;
      background: transparent !important;
      min-width: 150px !important;
      max-width: 180px !important;
      border-radius: 5px !important;
      box-shadow: none !important;
      padding: 10px 16px !important;
      line-height: 1;
      align-items: center;
      -webkit-transition: border-radius 0.25s;
      transition: border-radius 0.25s;
      justify-content: center;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      height: 40px !important;
    }
    &.reject {
      .MuiButtonBase-root {
        background: transparent !important;
        color: #f67979 !important;
        border: 1px solid #f67979 !important;
        &:hover {
          background: #f67979 !important;
          color: #ffffff !important;
        }
      }
    }
    &.save {
      .MuiButtonBase-root {
        background: transparent !important;
        color: #01579b !important;
        border: 1px solid #01579b !important;
        &:hover {
          background: #01579b !important;
          color: #ffffff !important;
        }
      }
    }
  }
`;

const StyledButton = styled(Button).attrs({
  className: "grandDisablebtn",
})`
  margin-top: 0px !important;
  width: 100%;
  max-width: 200px !important;
  color: #ffffff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-family: "Outfit Regular" !important;
  border: none !important;
  border-radius: 6px !important;
  background: #01579b !important;

  &.Mui-disabled {
    background-color: #80abcd !important;
  }

  max-width: 180px !important;
  color: rgb(255, 255, 255) !important;
  font-size: 12px !important;
  border: none !important;
  padding: 10px 10px !important;
  border-radius: 6px !important;
  text-transform: uppercase !important;
  background: rgb(1, 87, 155) !important;
`;

const StyledStepper = styled(Stepper)`
  .MuiStep-root {
    .MuiStepLabel-root {
      .MuiStepLabel-labelContainer {
        .MuiStepLabel-label {
          text-transform: uppercase;
          &.Mui-active {
            color: #01579b;
          }
          &.Mui-completed {
            color: #000000 !important ;
          }
          &.Mui-disabled {
            color: #000000 !important;
          }
        }
      }
      .MuiStepLabel-iconContainer {
        &.Mui-disabled {
          svg {
            border: 1px solid black !important;
            border-radius: 50% !important;
            color: transparent !important;
            circle {
              fill: white;
            }
            .MuiStepIcon-text {
              fill: #000000 !important;
            }
          }
        }
      }
    }
    &.Mui-completed {
      .MuiStepLabel-iconContainer {
        position: relative;
        svg {
          fill: white;
          border: 1px solid black;
          border-radius: 50%;
          color: black;
        }
        &.MuiStepLabel-iconContainer::before {
          content: "1";
          position: absolute;
          left: 8px;
          font-size: 13px;
          top: 3px;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
        }
      }
    }
  }
  .MuiStepConnector-root {
    &.Mui-completed,
    &.Mui-active {
      .MuiStepConnector-line {
        border-color: #00b383;
      }
    }
  }
`;
function useEffect(arg0: () => void, arg1: never[]) {
  throw new Error("Function not implemented.");
}
