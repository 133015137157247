import React, { useContext, useState, Fragment, useEffect } from "react";
import { ProfileContext } from "../ProfileContext";
import { Scrollbars } from "react-custom-scrollbars-2";
import gstImage from "../../../../../assets/img/gst/1.png";
import ReactTooltip from "react-tooltip-rc";
import { GstValidation } from "../Ui/GstValidation";
import { toast } from "react-toastify";
import {
  createOrUpdateGstDetails,
  listGst,
} from "../../../../../api/services/sap/profile-management";

const GstTab = ({showEdit, setShowEdit}) => {

  const {
    innertab,
    setGstFormData,
    gstformData,
    setGstViewOnly,
    profileConfigData,
  } = useContext(ProfileContext);
 
 //Validation State
 const [formErrors, SetFormErrors] = useState({});
 const [error, setError] = useState(false);
 const [formsubmit, setFormSubmit] = useState(false);
  useEffect(() => {
    const id = localStorage.getItem("userid");
    const data = listGst();
    data
      .then((response) => {
        setGstFormData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  //VALIDATION FUNCTION
 
  //ON change Validate
  const onChangeValidate = (field, value) => {
    const errors = GstValidation(gstformData, value, true, field,profileConfigData);
    if (Object.keys(errors).length !== 0) {
      setError(true);
      if (Object.keys(formErrors).length !== 0) {
        const updatedErrorObject = { ...formErrors, ...errors };
        SetFormErrors(updatedErrorObject);
      } else {
        SetFormErrors(errors);
      }
    } else {
      if (Object.keys(formErrors).length !== 0) {
        const updatedErrorObject = { ...formErrors };
        SetFormErrors(updatedErrorObject);
      } else {
        setError(false);
      }
    }
  };

  //preFormSubmitHandler
  const gstFormSubmitHandler = (e) => {
    e.preventDefault();
    setFormSubmit(true);
    let errors = GstValidation(gstformData);
    const id = localStorage.getItem("userid");
    if (Object.keys(errors).length != 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      const gstData = createOrUpdateGstDetails({
        ...gstformData,
        userid: id,
      });
      setShowEdit(true);
      toast.success("Details Updated Successfully", {
        position: "top-right",
      });
      gstData.then((res) => {
        const data = listGst(id);
        data.then((res) => {
          setGstFormData(res);
        });
      });
    }
  };

  const areFieldsEmpty = () => {
    return !gstformData?.business_name || !gstformData?.gst_number;
  };
 

  return (
    <div
      className={innertab === 5 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div className="main-tab-block">
          <div className="inner-block tab-form-block">
            <Fragment>
              <div className=""></div>
              <form className="" onSubmit={gstFormSubmitHandler}>
                <div className="row mb-3">
                  <div className="col-4">
                    <div className="form-box gst-img-box">
                      {profileConfigData?.tax_type?.tax_type_id_pk === 1 ? (
                        ""
                      ) : (
                        <img className="gstimage" src={gstImage} alt="" />
                      )}
                    </div>
                  </div>

                  <div className="col-8 gst-info-block">
                    {profileConfigData?.tax_type?.tax_type_id_pk === 2 ? (
                      <div className="p-3 text-start">
                        <h6>
                          Businesses belonging to the manufacturing sector and
                          having a turnover exceeding ₹ 40 lakhs need to have a
                          GSTIN and for the service sector, the said amount is ₹
                          20 lakhs or more.
                        </h6>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="form-box">
                  {/* GSTINn number */}
                  <div class="mb-3">
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="fal fa-sort-numeric-down"></i>
                          {profileConfigData?.tax_type?.title} number
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <div className="input-block">
                        <input
                          readOnly={showEdit ? true : false}
                          type="text"
                          className="form-control"
                          maxLength={18}
                          placeholder={profileConfigData?.tax_type?.title ==="GST" ? " GST number" :" VAT number"
                        }
                          onChange={(e) => {
                            onChangeValidate("gst_number", e.target.value);
                            setGstFormData({
                              ...gstformData,
                              gst_number: e.target.value,
                            });
                          }}
                          value={
                            gstformData && gstformData.gst_number
                              ? gstformData.gst_number
                              : ""
                          }
                          onBlur={(e) => {
                            if (showEdit) {
                              onChangeValidate("gst_number", e.target.value);
                            }
                          }}
                        />
                        {error && formErrors.gst_number ? (
                          <span className="form-error">
                            {formErrors.gst_number}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="fal fa-user-shield"></i>Business name
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <div className="input-block">
                        <input
                          class="form-control"
                          readOnly={showEdit ? true : false}
                          type="text"
                          className="form-control"
                          placeholder="Legal Business name"
                          maxLength={200}
                          onChange={(e) => {
                            onChangeValidate("business_name", e.target.value);
                            setGstFormData({
                              ...gstformData,
                              business_name: e.target.value,
                            });
                          }}
                          value={
                            gstformData && gstformData.business_name
                              ? gstformData.business_name
                              : ""
                          }
                          onBlur={(e) => {
                            if (showEdit) {
                              onChangeValidate("business_name", e.target.value);
                            }
                          }}
                        />
                        {error && formErrors.business_name ? (
                          <span className="form-error">
                            {formErrors.business_name}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {!showEdit && (
                  <div class="form-box text-end">
                    <button
                      class="btn btn-update"
                      type="submit"
                      disabled={areFieldsEmpty()}
                    >
                      <i class="fal fa-save mr-2"></i>Save
                    </button>
                  </div>
                )}
              </form>
            </Fragment>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default GstTab;
