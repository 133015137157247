import React from "react";
import { useState, useContext,useEffect } from "react";
import { changeActiveStatus } from "../../../../../api/services/sap/service-management";
import "../../Service/Ui/css/NavToggle.css";
import { ServiceContext } from "../ServiceContext";
import { toast } from "react-toastify";



function NavToggle(props) {

  const [isSelected, setIsSelected] = useState(props.isActive);
  const {table, SetTable,} = useContext(ServiceContext);

  let changeservicetoggleblock = isSelected ? " change-toggle-block" : "";
  let changeservicetogglespan = isSelected ? " change-toggle-span" : "";

  useEffect(() => {
    // if (isSelected !== props.isActive)
     setIsSelected(props.isActive);
  }, [, props.isActive]);

  const test = () => {

    const data = changeActiveStatus({
      service_id: props.id,
      type: isSelected === 1 ? "inactive" : "active",
    });
    data
      .then((res) => {
        setIsSelected(res.data.is_active);
        props.setActiveStatus(res.data.is_active);
        if (props.mappedRowData) {
          props.mappedRowData.is_active = res.data.is_active;
        } else if (props.mappedServiceData) {
          props.mappedServiceData.is_active = res.data.is_active;
        }

        const tableData = table.map((e, i) => {
          if (e.id === props.id) {
            console.log(e.id, "inside if");
            e.is_active = res.data.is_active;
          }
          return e;
        });
        SetTable([...table]);
        console.log(tableData,"tableData.....")
        toast.success("Status Changed Successfully", {
          position: "bottom-right",
        });
      })
      .catch((e) => {
        console.log(e);
        toast.error("Status Changing Failed", {
          position: "bottom-right",
        });
      });
  }
  
  return (
    <div onClick={test} className={`toggle-block  ${changeservicetoggleblock}`}>
      <span className={`toggle-span ${changeservicetogglespan}`}></span>
    </div>
  );
}

export default NavToggle;
