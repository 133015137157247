import React, { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { IconButton } from "@mui/material";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import coupongImage from "./assets/img/discount-coupon.png";
import { toast } from "react-toastify";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DoneAllIcon from "@mui/icons-material/DoneAll";

type Props = {
  open?: boolean;
  handleClose?: (e: any) => void;
};

const CouponDetailsPopup = ({ open, handleClose }: Props) => {
  const [copyCode, setCopyCode] = React.useState(false);

  const couponInputRef = useRef<HTMLInputElement>(null);
  // Define the event handler
  const handleCopy = async () => {
    if (copyCode) return;
    let coupValue = couponInputRef?.current?.value ?? "";

    try {
      await navigator.clipboard.writeText(coupValue);
      toast.success("Coupon code copied to clipboard!");
      setCopyCode(true);
    } catch (err) {
      toast.error("Failed to copy coupon code.");
      setCopyCode(false);
      console.error("Failed to copy: ", err);
    }
  };

  const handleClosePopup = (e: any) => {
    handleClose?.(e);
    setCopyCode(false);
  };
  return (
    <StyledDialog
      open={open!}
      onClose={handleClosePopup}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="StyledDialog"
    >
      <DialogTitle id="alert-dialog-title"></DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClosePopup}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <StyledDialogContent className="StyledDialogContent">
        <CouponThumbImageWrapper>
          <CouponThumbImageWrap>
            <CouponThumbImage src={coupongImage} alt="coupon-thumb" />
          </CouponThumbImageWrap>
        </CouponThumbImageWrapper>
        <DiscountTitle>70 % OFF</DiscountTitle>
        <CouponDescriptionTitle className="text-center mb-3">
          Special discount for all the new customers
        </CouponDescriptionTitle>
        <CouponDescription id="alert-dialog-description">
          Not applicable to ICANN fees, taxes, transfers,or gift cards. Cannot
          be used in conjunction with any other offer, sale, discount or
          promotion.
        </CouponDescription>
        <h6 className="color-mid ">Click below to get your coupon code</h6>
        <CouponWrapper>
          <div className="copy-coupon-wrap">
            <input
              ref={couponInputRef}
              type="text"
              value="X410-17GT-OL57"
              id="couponValElement"
              className={`coupon-code form-control ${copyCode ? "copied" : ""}`}
              readOnly
            />
            {copyCode ? (
              <DoneAllIcon sx={{ color: "#4caf50" }} />
            ) : (
              <CopyAllIcon />
            )}

            <button onClick={handleCopy}>Copy Coupon</button>
          </div>
          <p
            className="clipboard"
            style={{ display: copyCode ? "block" : "none" }}
          >
            Code copied to clipboard
          </p>
        </CouponWrapper>
      </StyledDialogContent>
      <DialogActions sx={{ paddingRight: "20px" }}></DialogActions>
    </StyledDialog>
  );
};

export default CouponDetailsPopup;
const CouponWrapper = styled.div`
  padding: 5px 30px 0;
  input {
    display: inline-block;
    font-size: 21px;
    position: relative;
    margin: 20px auto;
    padding: 5px 30px;
    text-align: center;
    border: none !important;
    background: #ffffff !important;
    color: #f50;
    opacity: 1;
    height: 80px;
    box-shadow: #00000005 0px 0.025em 0.025em, #00000024 0px 0em 0.5em,
      #ffffff00 0px 0px 0px 1px inset !important;
    border-radius: 20px;
    transition: all ease-in-out 150ms;
    text-decoration: none;
  }
  p.clipboard {
    text-align: center;
    color: #4caf50;
  }

  .copy-coupon-wrap {
    position: relative;
    display: flex;
    align-items: center;
    gap: 0px 10px;
    button {
      height: 80px;
      position: absolute;
      top: 20px;
      bottom: 20px;
      left: 0;
      right: 0;
      width: 100%;
      opacity: 0;
      user-select: none;
    }
  }
`;
const DiscountTitle = styled.h3`
  font-family: "Outfit SemiBold";
  font-size: 30px;
  letter-spacing: 0.9px;
  color: #69a4e8;
  text-align: center;
  padding: 15px 0;
`;
const CouponThumbImage = styled.img``;
const CouponThumbImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 22px 0;
`;
const CouponThumbImageWrap = styled.div`
  padding: 20px;
  background: #427df81a;
  border-radius: 50%;
`;
const CouponDescriptionTitle = styled.h4`
  font-family: "Inter Medium";
  font-size: 20px;
`;
const CouponDescription = styled.p`
  font-family: "Inter Medium";
  font-size: 13px;
  color: #757575;
  letter-spacing: 0.1px;
  text-align: center;
`;
const StyledDialogContent = styled(DialogContent)`
  padding: 44px 24px 40px 24px !important;
  .color-mid {
    text-align: center;
    margin: 22px 0px 0px 0px;
    color: #717f86 !important;
    text-transform: uppercase;
    font-size: 14px;
    display: block;
    line-height: 1.4;
    font-weight: 400;
  }
`;
const StyledDialog = styled(Dialog)`
  .MuiDialog-container {
    > .MuiDialog-paper {
      border-radius: 18px !important;
    }
  }
`;
