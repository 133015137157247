import React, { useState, useContext, Fragment } from "react";
import { Button, Modal, Nav, Container, Row, Col } from "react-bootstrap";
import { OrdersContext } from "../OrdersContext";
import uploadImageFile from "../../../../../assets/img/icon/upload/photos.svg";

const PostServiceImageModal = () => {
  const {
    innertab,
    SetinnerTab,
    mappedOrderData,
    postFormData,
    setPostFormData,
    postservicepic,
    setPostServicePic,
    postaddonImages,
    setPostAddonImages,
    postAddImageSubmit,
    setPostAddImageSubmit,
    postModal,
    setPostModal,
    handleClosePostModal,
    handleShowPostModal,
    currentPostservicepic,
    setCurrentPostservicepic,
  } = useContext(OrdersContext);
  //Convert to Base64
  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      const postPicLength = postservicepic.length;
      reader.addEventListener("load", () => {
        res({
          inspection_image: {
            base64: reader.result,
            name: name,
            type,
            size: size,
          },
          index: postPicLength,
          description: "",
        });
      });
      reader.readAsDataURL(image);
    });
  };
  //OnChange Function
  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const newImagesPromises = [];
      for (let i = 0; i < e.target.files.length; i++) {
        newImagesPromises.push(fileToDataUri(e.target.files[i]));
      }
      const newImages = await Promise.all(newImagesPromises);
      // setPostServicePic([...postservicepic, ...newImages]);
      setCurrentPostservicepic([...newImages]);
    }
  };
  //Close Modal
  const closeFileUpload = () => {
    setPostModal(false);
    setPostServicePic([]);
  };
  //Confirm Modal Click Function
  const ImageSubmit = (e) => {
    setPostServicePic([...postservicepic, ...currentPostservicepic]);
    setPostModal(false);
    e.preventDefault();
    // setCancelFile(true);
    setPostAddImageSubmit(true);
    setCurrentPostservicepic([]);
    console.log(postservicepic, "on confirm");
  };
  return (
    <Modal
      className="file-modal"
      show={postModal}
      onHide={handleClosePostModal}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={3} className="p-0">
              <Row className="guidelines-info w-100">
                <Col md={12}>
                  <Col md={12}>
                    <h5>Dimensions: </h5>
                    <p>1024 x 768px</p>
                  </Col>
                  <Col md={12}>
                    <h5>Format:</h5>
                    <p>JPG or PNG</p>
                  </Col>
                  <Col md={12}>
                    <h5>File Size:</h5>
                    <p>Below 10 MB</p>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col xs={9} className="upload-block">
              <Modal.Header closeButton></Modal.Header>
              <h5 className="title">Upload Images</h5>
              <div className="form-group">
                <div className="custom-file">
                  <input
                    onChange={uploadImage}
                    type="file"
                    multiple
                    accept="image/jpeg, image/png, image/webp"
                    className="custom-file-input form-control-sm"
                    id="imageUpload"
                  />
                  <label
                    className="custom-file-label col-form-label-sm"
                    for="imageUpload"
                  >
                    <div className="text-center">
                      <div className="upload-section">
                        <div className="upload-img">
                          <img src={uploadImageFile} alt="loading..." />
                        </div>
                        <div className="upload-text">
                          <h5>
                            <span>Click to upload</span> or drag and drop
                          </h5>
                          <h6>Maximum file size 10 MB.</h6>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <Row className="row-img">
                {/* <div className="mb-2 text-center">
              {Object.keys(postservicepic).length &&
              Object.keys(postservicepic).length > 1 ? (
                <p className="text-danger">
                  Only one image Can be Uploaded
                </p>
              ) : (
                ""
              )}
            </div> */}
                {/* {Object.keys(postservicepic).length > 1 ? (
              <div className="col-12 col-img-error">
                <p>Maximum 1 image</p>
              </div>
            ) : (
              ""
            )} */}
                {currentPostservicepic &&
                  currentPostservicepic.map((photo, index) => {
                    // console.log(pic);
                    return (
                      <div className="col-md-4 col-img-list">
                        <div className="img-list" key={photo}>
                          <button
                            className="btn"
                            onClick={() =>
                              setCurrentPostservicepic(
                                currentPostservicepic.filter(
                                  (e) =>
                                    e.inspection_image !==
                                    photo.inspection_image
                                )
                              )
                            }
                          >
                            <i class="fal fa-times"></i>
                          </button>
                          <img
                            src={photo.inspection_image.base64}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}
              </Row>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClosePostModal}>
                  Close
                </Button>
                {Object.keys(currentPostservicepic).length &&
                Object.keys(currentPostservicepic).length ? (
                  <Button variant="primary" onClick={ImageSubmit}>
                    Confirm
                    <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                  </Button>
                ) : (
                  <Button variant="primary" disabled onClick={ImageSubmit}>
                    Confirm
                    <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                  </Button>
                )}
              </Modal.Footer>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default PostServiceImageModal;
