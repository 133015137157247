import React, { useContext } from "react";
import { ApprovalContext } from "../ApprovalContext";
import "../Ui/css/ServiceModal.css";
import '../Ui/css/RejectModal.css'

function ConfirmModal(props) {
  const {
    mappedServiceData,
    table,
    SetMappedServiceData,
    SetEditMode,
    SetViewReadOnly,
    setIsUpdateForm,
    SetFormChange
  } = useContext(ApprovalContext);

  const closeConfirm = (e) => {
    props.approveService(e);
  };

  const setConfirmModal = (e) => {
    props.setConfirmModal(false);
  };

  return (
    <div className="action-modal reject-modal">
      <div className="d-flex justify-content-end">
        <button className="btn close-btn" onClick={setConfirmModal}>
          <i className="fal fa-times"></i>
        </button>
      </div>
      <h5>Seems like there are some variants yet to be approved. Please take an action on pending variants before approving the service?</h5>
      <div className="d-flex justify-content-center pt-3">
        <button className="btn mr-3 btn-danger " onClick={setConfirmModal}>
          Ok
        </button>
        {/* <button class="btn btn-danger" onClick={closeConfirm}>
          Approve<i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
        </button> */}
      </div>
    </div>
  );
}

export default ConfirmModal;
