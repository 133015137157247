import React, { useState, useContext, useEffect, Fragment } from "react";
import { ServiceContext } from "./ServiceContext";
import configData from "../../../../config.json";
import "../../../../assets/css/tags.css";
import ServiceFileUploadModal from "./ServiceFileUploadModal";
import { toast } from "react-toastify";
import { Validation } from "./Ui/Validation";
import uploadImageFile from "../../../../assets/img/icon/upload/photos.svg";
import ReactTooltip from "react-tooltip-rc";
import Select from "react-select";
import styled from "styled-components";

const initialState = {
  service_name: "",
  service_images: "",
  service_description: "",
  product_description: "",
  service_discount: "",
  others: "",
  common_price: "",
  vehicle_select: "",
  is_active: 0,
  is_new: 1,
};

const errorFields = {
  service_images: "",
  service_name: "",
  vehicle_select: "",
  service_description: "",
  servicetags: "",
  producttags: "",
  warranty_description: "",
  validupto_month: "",
  validupto_year: "",
  priceDetails: "",
  isPrice: "",
  common_price: "",

  // others: "",
  // common_price: "",
};
function ServiceForm(props) {
  const [formErrors, SetFormErrors] = useState(errorFields);
  const [error, setError] = useState(false);
  const [EditServiceID, SetEditServiceID] = useState("");
  const [formData, SetFormData] = useState(initialState);

  let [priceDetails, setPriceDetails] = useState([]);
  const [producttags, SetProductTags] = useState([]);
  const [warrantyDetails, setWarrantyDetails] = useState({
    warranty_id: "",
    warranty_description: "",
    validupto_month: "",
    validupto_year: "",
  });
  const [serviceImageData, setServiceImages] = useState([]);
  const [show, setShow] = useState(false); //Image Modal
  const [duplicateVariant, setDuplicateVariant] = useState(false); //Image Modal
  const [servicetags, SetServiceTags] = useState([]);
  let [inputlist, SetInputList] = useState([]); //Add Dynamic Variants
  const [isPrice, setIsPrice] = useState("");
  const {
    mappedServiceData,
    SetMappedServiceData,
    viewReadOnly,
    addMode,
    setAddMode,
    editMode,
    SetEditMode,
    review,
    SetReview,
    setServicePic,
    updateOrCreateService,
    SetFormChange,
    addImageSubmit,
    setAddImageSubmit,
    vehicleTypes,
    vehicleVariants,
    saveVehicleVariants,
    pic,
    setPic,
    setIsUpdateForm,
  } = useContext(ServiceContext);
  const handleClose = () => setShow(false);
  const addVariantPriceDetails = (i, variant_id_pk, price) => {
    const data = priceDetails.map((priceItem) => priceItem);
    data[i].price = price ? price : "";
    setPriceDetails(data);
  };

  //Service Tag
  const addServiceTags = (event) => {
    if (event.key === "Enter" || event.key === "Tab") {
      const value = event.target.value;
      if (!value.trim()) return;
      SetServiceTags([...servicetags, event.target.value]);
      event.target.value = "";
      event.preventDefault();
    } else return;
  };

  const removeServiceTags = (indexToRemove) => {
    SetServiceTags(servicetags.filter((_, index) => index !== indexToRemove));
  };
  const addProductTags = (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      const value = e.target.value;
      if (!value.trim()) return;
      SetProductTags([...producttags, value]);
      e.target.value = "";
      e.preventDefault();
    } else return;
  };
  const removeProductTags = (index) => {
    SetProductTags(producttags.filter((el, i) => i !== index));
  };
  const updateToast = () => {
    return editMode ? "Updated Successfully" : "Added Successfully";
  };

  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputlist];
    list[index][name] =
      name === "price" ? (parseInt(value) ? parseInt(value) : "") : value;
    SetInputList(list);
  };
  const [showVariants, setShowVariant] = useState(0);
  const handleaddclick = () => {
    SetInputList([
      ...inputlist,
      {
        price: "",
        service_price_id: "",
        vehicle_variant_id: "",
        variant_name: "",
        status: "",
        rejected_reason: "",
      },
    ]);
    setShowVariant(1);
  };
  const handleremove = (index) => {
    const list = [...inputlist];
    list.splice(index, 1);
    SetInputList(list);
  };

  const onChangeValidate = (field) => {
    const errors = Validation(
      formData,
      servicetags,
      isPrice,
      priceDetails,
      true,
      field
    );
    if (Object.keys(errors).length != 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      setError(false);
      SetFormErrors(errorFields);
    }
  };
  //Form Submit
  const formSubmit = (e) => {
    e.preventDefault();
    let errors = Validation(
      {
        ...formData,
        service_images: [...serviceImageData, ...pic],
      },
      servicetags,
      isPrice,
      priceDetails
    );
    const onSuccess = () => {
      toast.success(updateToast, {
        position: "bottom-right",
      });
      props.serviceformClose(false);
      SetEditMode(false);
      setAddMode(false);
      setIsUpdateForm(false);
      SetMappedServiceData({});
      setAddImageSubmit(true);
      setServicePic([]);
    };

    if (Object.keys(errors).length !== 0) {
      setError(true);
      SetFormErrors(errors);
      toast.error("Please enter all required fields", {
        position: "bottom-right",
      });
    } else {
      if (isPrice === "Common Price") {
        const newPrices = priceDetails.map((e) => ((e.price = ""), e));
        const newInputList = inputlist.map((e) => ((e.price = ""), e));
        priceDetails = newPrices;
        inputlist = newInputList;
      }
      if (isPrice === "Normal Price") formData.common_price = "";
      const data = {
        ...formData,
        service_images: pic,
        product_tags: producttags,
        service_tags: servicetags,
        warranty_details: warrantyDetails,
        price_details: priceDetails,
        new_variants: inputlist,
        status:
          formData.status === 2
            ? 2
            : Object.values(e.nativeEvent.submitter.classList).includes(
                "save-draft"
              )
            ? 0
            : 1,
      };

      updateOrCreateService(data, EditServiceID, { onSuccess });
    }
  };

  const handleKeyPress = (e) => {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (regex.test(key)) {
      e.preventDefault();
    }
  };
  const filterPriceDetails = (servicePriceDetails) => {
    const extractPriceDetails = (item) => {
      return {
        price: item.price,
        vehicle_variant_id: item.vehicle_variant_id,
        variant_name: item.vehicleVariant.variant_name,
        service_price_id: item.service_price_id,
        status: item.vehicleVariant.status,
        rejected_reason: item.vehicleVariant.rejected_reason,
      };
    };

    let unapprovedVariantPrices =
      servicePriceDetails &&
      servicePriceDetails
        ?.filter(
          (item) =>
            item.vehicleVariant.status === 0 ||
            item.vehicleVariant.status === -1
        )
        .map(extractPriceDetails);
    let approvedVariantPrices = vehicleVariants.map((e) => {
      const priceDetail = servicePriceDetails
        .filter((item) => item.vehicleVariant.status === 1)
        .map(extractPriceDetails)
        .filter((el) => el.vehicle_variant_id === e.vehicle_variant_id);
      if (priceDetail.length) {
        return priceDetail[0];
      }
      return {
        price: "",
        vehicle_variant_id: e.vehicle_variant_id,
        variant_name: e.variant_name,
        service_price_id: "",
      };
    });

    setPriceDetails(approvedVariantPrices);
    if (unapprovedVariantPrices.length) SetInputList(unapprovedVariantPrices);
  };
  const setTags = (serviceTags, productTags) => {
    const hasServiceTags =
      serviceTags.length >= 2 || !(serviceTags.length === 1 && !serviceTags[0]);
    const hasProductTags =
      productTags.length >= 2 || !(productTags.length === 1 && !productTags[0]);
    SetServiceTags(hasServiceTags ? serviceTags : []);
    SetProductTags(hasProductTags ? productTags : []);
  };
  const variantIcon = () => {
    if (formData.vehicle_select === 1)
      return <i className="fal fa-motorcycle"></i>;
    if (formData.vehicle_select === 2) return <i className="fal fa-car"></i>;
    if (formData.vehicle_select === 3) return <i className="fal fa-bus"></i>;
  };

  const sideEffectFn = () => {
    const hasServiceData = !(
      mappedServiceData &&
      Object.keys(mappedServiceData).length === 0 &&
      Object.getPrototypeOf(mappedServiceData) === Object.prototype &&
      Object.keys(initialState).values === Object.keys(formData).values
    );

    if (hasServiceData) {
      const {
        service_id: id,
        vehicle_type_id,
        product_tags,
        service_tags,
        serviceImages,
        servicePriceDetails,
        serviceWarrantyDetail,
        ...rest
      } = mappedServiceData;
      if (mappedServiceData.common_price) setIsPrice("Common Price");
      else setIsPrice("Normal Price");
      let vehicle_select =
        mappedServiceData.status === 0 && formData.vehicle_select !== ""
          ? formData.vehicle_select
          : vehicle_type_id;

      SetEditServiceID(id);
      filterPriceDetails(servicePriceDetails);
      SetFormData({ ...rest, vehicle_select });
      setTags(service_tags, product_tags);
      setWarrantyDetails(serviceWarrantyDetail);
      setServiceImages(serviceImages);
    } else {
      setPriceDetails(
        vehicleVariants.map((e) => ({
          price: "",
          vehicle_variant_id: e.vehicle_variant_id,
          variant_name: e.variant_name,
          service_price_id: "",
        }))
      );
    }

    return () => {
      if (!props.addservice) {
        SetFormData(initialState);
        SetServiceTags([]);
        SetProductTags([]);
        SetInputList([
          {
            price: "",
            service_price_id: "",
            vehicle_variant_id: "",
            variant_name: "",
            status: "",
          },
        ]);
        setWarrantyDetails({
          warranty_id: "",
          warranty_description: "",
          validupto_month: "",
          validupto_year: "",
        }); //Added so as to remove
        setServiceImages([
          {
            service_image_id: "",
            image_name: "",
            image_url: "",
          },
        ]);
        SetEditMode(false);
      }
    };
  };
  useEffect(() => {
    setPic([]);
    setServiceImages([]);
    SetFormData(initialState);
    SetServiceTags([]);
    SetProductTags([]);
    SetInputList([]);
    setWarrantyDetails({
      warranty_id: "",
      warranty_description: "",
      validupto_month: "",
      validupto_year: "",
    }); //Added so as to remove
  }, [addMode, mappedServiceData]);

  useEffect(sideEffectFn, [vehicleVariants]);

  const vehicleOptions = vehicleTypes.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  const MonthOptions = [
    { value: 0, label: "0 Months" },
    { value: 1, label: "1 Months" },
    { value: 2, label: "2 Months" },
    { value: 3, label: "3 Months" },
    { value: 4, label: "4 Months" },
    { value: 5, label: "5 Months" },
    { value: 6, label: "6 Months" },
    { value: 7, label: "7 Months" },
    { value: 8, label: "8 Months" },
    { value: 9, label: "9 Months" },
    { value: 10, label: "10 Months" },
    { value: 11, label: "11 Months" },
  ];
  const YearOptions = [
    { value: 0, label: "0 Years" },
    { value: 1, label: "1 Years" },
    { value: 2, label: "2 Years" },
    { value: 3, label: "3 Years" },
    { value: 4, label: "4 Years" },
    { value: 5, label: "5 Years" },
    { value: 6, label: "6 Years" },
    { value: 7, label: "7 Years" },
    { value: 8, label: "8 Years" },
    { value: 9, label: "9 Years" },
    { value: 10, label: "10 Years" },
    { value: 11, label: "11 Years" },
  ];
  // Check if variant names are duplicated
  const hasMatchingName = priceDetails?.some((itemA) =>
    inputlist.some((itemB) => itemA.variant_name === itemB.variant_name)
  );
  // Check for duplicate names in array B
  const hasDuplicateNames = (name) => {
    const seenNames = new Set();

    for (const item of inputlist) {
      if (seenNames.has(item.variant_name)) {
        return true;
      }
      seenNames.add(item.variant_name);
    }

    return false;
  };
  //disable add variant button
  function isDisableVariantButton() {
    return (
      inputlist.some((item) => !item.price || !item.variant_name) ||
      hasMatchingName ||
      hasDuplicateNames()
    );
  }

  console.log(
    hasDuplicateNames(),
    " hasDuplicateNames()",
    inputlist,
    "inputlist"
  );

  return (
    <Wrapper className="col-12">
      <form className="w-100" id="add-service" onSubmit={formSubmit}>
        {/* Service Image */}
        <div
          className="input-group input-group-custom"
          style={{ flexWrap: "unset" }}
        >
          <div className="input-group-prepend d-flex align-items-center">
            <span className="input-group-text">
              <i class="fal fa-images"></i>Photo
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="small-block">
            {!editMode && addImageSubmit
              ? pic &&
                pic.map((photo, index) => {
                  return (
                    <div className="small-img ko" key={index}>
                      {" "}
                      <img
                        src={photo.base64}
                        className="img-fluid"
                        alt=""
                        height="100"
                        width="100"
                      />{" "}
                    </div>
                  );
                })
              : editMode
              ? pic &&
                pic.map((photo, index) => {
                  return (
                    <div className="small-img ko img-list" key={index}>
                      {" "}
                      <button
                        type="button"
                        className="btn"
                        onClick={() => setPic(pic.filter((e) => e !== photo))}
                      >
                        <i class="fal fa-times"></i>
                      </button>
                      <img
                        src={photo.base64}
                        className="img-fluid"
                        alt=""
                        height="100"
                        width="100"
                      />{" "}
                    </div>
                  );
                })
              : ""}

            {serviceImageData &&
              serviceImageData.map((photo, index) => {
                return (
                  <div
                    className="img-list"
                    key={index}
                    service-image-id={photo.service_image_id}
                  >
                    {editMode ? (
                      <button
                        type="button"
                        className="btn"
                        onClick={() =>
                          setServiceImages(
                            serviceImageData.filter(
                              (e) =>
                                e.service_image_id !== photo.service_image_id
                            )
                          )
                        }
                      >
                        <i class="fal fa-times"></i>
                      </button>
                    ) : (
                      ""
                    )}
                    <img
                      src={`${configData.SERVER_URL}${photo.image_url}`}
                      className="img-fluid"
                      alt=""
                      height="100"
                      width="100"
                    />
                  </div>
                );
              })}
            {!viewReadOnly && (
              <button
                type="button"
                className="btn-image"
                onClick={props.handleShow}
              >
                <div className="upload-section justify-content-center">
                  <div className="upload-img">
                    <img src={uploadImageFile} alt="loading..." />
                  </div>
                </div>
                Upload
              </button>
            )}
          </div>
        </div>
        <ErrorWrapper>
          {error && formErrors.service_images ? (
            <span className="form-error ">{formErrors.service_images}</span>
          ) : (
            ""
          )}
        </ErrorWrapper>

        {/* Serice Name */}

        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-cog"></i>Service Name
              <sup className="theme-text">*</sup>
            </span>
          </div>

          <div className="input-block">
            <input
              type="text"
              onKeyPress={handleKeyPress}
              className={`form-control ${viewReadOnly ? "disable-input" : ""}`}
              placeholder=""
              aria-label="Username"
              aria-describedby="basic-addon1"
              readOnly={viewReadOnly}
              value={formData.service_name}
              onChange={(e) =>
                SetFormData(
                  { ...formData, service_name: e.target.value },
                  SetFormChange(true)
                )
              }
              onBlur={() => onChangeValidate("service_name")}
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            />

            {error && formErrors.service_name ? (
              <span className="form-error">{formErrors.service_name}</span>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* Vehichle Type */}
        <VehicleTypeContainer>
          <div class="input-group input-group-custom">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-car"></i>Vehicle Type
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <Select
                className={`form-select p-0 ${
                  viewReadOnly
                    ? "disable-select"
                    : [-1, 2].includes(mappedServiceData.status) && editMode
                    ? "disable-select"
                    : ""
                }`}
                isDisabled={
                  viewReadOnly
                    ? true
                    : [-1, 2].includes(mappedServiceData.status) && editMode
                    ? true
                    : false
                }
                id="warranty_period"
                options={vehicleOptions}
                value={vehicleOptions.find(
                  (x) => x.value === formData.vehicle_select
                )}
                onChange={(e) => {
                  const value = parseInt(e.value);
                  saveVehicleVariants(value);
                  SetFormData({ ...formData, vehicle_select: e.value });
                  SetInputList([]);
                }}
              />
              {error && formErrors.vehicle_select ? (
                <span className="form-error">{formErrors.vehicle_select}</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </VehicleTypeContainer>

        {/* Service Description */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text" style={{ width: "min-content" }}>
              <i className="fal fa-cog"></i>Service Description
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <textarea
              className={`form-control ${viewReadOnly ? "disable-input" : ""}`}
              readOnly={viewReadOnly}
              value={formData.service_description}
              maxLength={1000}
              onChange={(e) =>
                SetFormData({
                  ...formData,
                  service_description: e.target.value,
                })
              }
              onBlur={() => onChangeValidate("service_description")}
            ></textarea>
            {error && formErrors.service_description ? (
              <span className="form-error">
                {formErrors.service_description}
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Service Tags */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-tag"></i>Service Tags
              <sup className="theme-text">*</sup>
            </span>
          </div>

          <div className="input-block ">
            <div
              className="tags-input-container w-100"
              style={{ background: "#e8e8e8" }}
            >
              <DesignText>
                {servicetags.map((tag, index) => (
                  <div className="tag-item" key={index}>
                    <span className="my-custom-text">{tag}</span>

                    {viewReadOnly ? null : (
                      <span
                        onClick={() => removeServiceTags(index)}
                        className="close"
                      >
                        &times;
                      </span>
                    )}
                  </div>
                ))}
              </DesignText>
              <input
                onKeyDown={addServiceTags}
                readOnly={viewReadOnly}
                type="text"
                className={`tags-input ${viewReadOnly ? "disable-input" : ""}`}
                // placeholder="Eg. Car Wash..."
                maxLength={15}
                placeholder={
                  viewReadOnly
                    ? ""
                    : !servicetags.length
                    ? "Eg. Car Wash..."
                    : ""
                }
                onBlur={() => onChangeValidate("servicetags")}
              />
            </div>
            {error && formErrors.servicetags ? (
              <span className="form-error">{formErrors.servicetags}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Product Description */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-boxes"></i>Products Description
            </span>
          </div>
          <div className="input-block">
            <textarea
              className={`form-control ${viewReadOnly ? "disable-input" : ""}`}
              value={formData.product_description}
              readOnly={viewReadOnly}
              maxLength={1000}
              onChange={(e) =>
                SetFormData({
                  ...formData,
                  product_description: e.target.value,
                })
              }
            ></textarea>
          </div>
        </div>
        {/* Product Used Tags */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-tag"></i>Products Used
            </span>
          </div>
          <div className="input-block">
            <div
              className="tags-input-container w-100 "
              style={{ background: "#e8e8e8" }}
            >
              <DesignText>
                {producttags.map((tag, index) => (
                  <div className="tag-item" key={index}>
                    <span className="my-custom-text">{tag}</span>
                    {viewReadOnly ? null : (
                      <span
                        className="close"
                        onClick={() => removeProductTags(index)}
                      >
                        &times;
                      </span>
                    )}
                  </div>
                ))}
              </DesignText>
              <input
                readOnly={viewReadOnly}
                onKeyDown={addProductTags}
                type="text"
                className={`tags-input ${viewReadOnly ? "disable-input" : ""}`}
                placeholder={
                  viewReadOnly
                    ? ""
                    : !producttags.length
                    ? "Eg. Detailing Products..."
                    : ""
                }
              />
            </div>
          </div>
        </div>
        {/* Warranty Details Block */}
        <div className="col-12 block-header main-title mt-4">
          <h6>
            <span className="header-title">Warranty Details</span>
          </h6>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-certificate"></i>Warranty Description
            </span>
          </div>
          <div className="input-block">
            <textarea
              className={`form-control ${viewReadOnly ? "disable-input" : ""}`}
              value={warrantyDetails.warranty_description}
              readOnly={viewReadOnly}
              maxLength={1000}
              onChange={(e) =>
                setWarrantyDetails({
                  ...warrantyDetails,
                  warranty_description: e.target.value,
                })
              }
            ></textarea>
          </div>
        </div>

        <ValidWrapper>
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-calendar"></i>Valid Upto
              </span>
            </div>
            <div className="form-width">
              <div className="row m-custom">
                <div className="col-md-6">
                  <div className="input-block w-100 ">
                    <Select
                      className={`form-select p-0 ${
                        viewReadOnly && "disable-select"
                      }`}
                      id="warranty_period"
                      placeholder={
                        warrantyDetails.validupto_month
                          ? MonthOptions.find(
                              (x) => x.value === warrantyDetails.validupto_month
                            ).label
                          : viewReadOnly
                          ? ""
                          : "Select..."
                      }
                      isDisabled={viewReadOnly ? true : false}
                      value={
                        !viewReadOnly
                          ? MonthOptions.find(
                              (x) => x.value === warrantyDetails.validupto_month
                            )
                          : ""
                      }
                      options={MonthOptions}
                      onChange={(e) => {
                        setWarrantyDetails({
                          ...warrantyDetails,
                          validupto_month: parseInt(e.value),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group m-0">
                    <div className="input-block w-100">
                      <Select
                        className={`form-select p-0 ${
                          viewReadOnly && "disable-select"
                        }`}
                        options={YearOptions}
                        placeholder={
                          warrantyDetails.validupto_year
                            ? YearOptions.find(
                                (x) =>
                                  x.value === warrantyDetails.validupto_year
                              ).label
                            : viewReadOnly
                            ? ""
                            : "Select..."
                        }
                        isDisabled={viewReadOnly ? true : false}
                        value={
                          !viewReadOnly
                            ? YearOptions.find(
                                (x) =>
                                  x.value === warrantyDetails.validupto_year
                              )
                            : ""
                        }
                        onChange={(e) => {
                          setWarrantyDetails({
                            ...warrantyDetails,
                            validupto_year: parseInt(e.value),
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ValidWrapper>
        {/* Price Details Block */}
        <div className="col-12 block-header main-title mt-4">
          <h6>
            <span className="header-title">Price Details</span>
          </h6>
        </div>
        <div className="input-block">
          {error && formErrors.isPrice ? (
            <span className="form-error">{formErrors.isPrice}</span>
          ) : (
            ""
          )}
        </div>
        {/* Normal Price */}
        {viewReadOnly ? null : (
          <div className="mb-3 radio-title" style={{ gap: "12px" }}>
            <input
              type="radio"
              value="Normal Price"
              name="isPrice"
              disabled={
                !viewReadOnly && !editMode && formData.vehicle_select === ""
                  ? true
                  : false
              }
              // onClick={(e) => setIsPrice(e.target.value)}
              onChange={(e) => {
                setIsPrice(e.target.value);
                e.target.checked = true;
              }}
              checked={isPrice === "Normal Price" ? true : false}
              onBlur={() => onChangeValidate("isPrice")}
              maxLength="7"
            />
            Set Price with respect to Variant
            <sup className="theme-text radio-required">*</sup>
          </div>
        )}
        {isPrice === "Normal Price" ? (
          // (!viewReadOnly || editMode ) &&
          <div
            className={` ${
              viewReadOnly
                ? "price-change view-only"
                : isPrice === "Common Price"
                ? "price-fade"
                : "price-change"
            }`}
          >
            {formData.vehicle_select &&
              priceDetails.map((item, i) => {
                const hideField =
                  viewReadOnly && (isNaN(item.price) || !Number(item.price));
                return (
                  <div
                    className={`input-group input-group-custom${
                      hideField ? " d-none" : ""
                    }`}
                  >
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        {variantIcon(formData.vehicle_select)}
                        {item.variant_name}
                      </span>
                    </div>
                    <input
                      type="text"
                      readonly
                      className={`form-control ${
                        viewReadOnly ? "disable-input" : ""
                      }`}
                      placeholder="Price"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={item.price ? item.price : ""}
                      readOnly={viewReadOnly}
                      style={{ background: "#e8e8e8" }}
                      maxLength="7"
                      onChange={(e) => {
                        const value = parseInt(e.target.value);
                        if (!isNaN(value) && value >= 0) {
                          addVariantPriceDetails(i, item.variant_id_pk, value);
                        } else {
                          addVariantPriceDetails(i, item.variant_id_pk, null);
                        }
                      }}
                      onBlur={() => onChangeValidate("priceDetails")}
                    />
                    {error && formErrors.priceDetails ? (
                      <span className="form-error">
                        {formErrors.priceDetails}
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            {/* Add variants */}

            <div>
              {inputlist.map((x, i) => {
                return (
                  <Fragment>
                    <div className="col-12 p-0 pb-2">
                      <div className="input-list-row">
                        <NewVariantLabelWrapper className="">
                          {variantIcon(formData.vehicle_select)}
                          {`New variant `}
                        </NewVariantLabelWrapper>
                        <div className="col-main-list">
                          <div className="form-group col-list-row">
                            <input
                              name="variant_name"
                              value={x.variant_name}
                              readOnly={viewReadOnly}
                              type="text"
                              className={`form-control ${
                                viewReadOnly ? "disable-input" : ""
                              }`}
                              placeholder="Variant Name"
                              onChange={(e) => handleinputchange(e, i)}
                              maxLength="7"
                            />
                          </div>
                          <div className="form-group col-md-6 mb-0">
                            <input
                              name="price"
                              type="text"
                              value={x.price}
                              readOnly={viewReadOnly}
                              placeholder="Price"
                              className={`form-control ${
                                viewReadOnly ? "disable-input" : ""
                              }`}
                              onChange={(e) => handleinputchange(e, i)}
                              maxLength="7"
                            />
                          </div>
                          {mappedServiceData.status !== 0 && x.status === -1 ? (
                            <div className="variant-action d-flex align-center">
                              <p
                                className="text-danger mr-1"
                                style={{ fontSize: "14px" }}
                              >
                                Rejected{" "}
                              </p>
                              <span
                                className="text-danger"
                                data-for="info"
                                data-tip={x.rejected_reason}
                              >
                                <i className="fal fa-info-circle"></i>
                              </span>
                              <ReactTooltip
                                id="info"
                                place="bottom"
                                type="info"
                                effect="float"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        {viewReadOnly ? null : (
                          <div className="button-remove">
                            {inputlist.length && (
                              <span onClick={() => handleremove(i)}>
                                <span>
                                  <i class="fal fa-times"></i>
                                </span>
                              </span>
                            )}
                          </div>
                        )}
                      </div>

                      <>
                        {priceDetails &&
                        priceDetails?.find((item) => {
                          return item.variant_name === x?.variant_name;
                        }) ? (
                          <ErrorWrapper className="left-space">
                            <span className="form-error ">
                              <>
                                Variant with name {`" ${x?.variant_name} "`}
                                already exists
                              </>
                            </span>
                          </ErrorWrapper>
                        ) : (
                          ""
                        )}
                      </>
                    </div>
                  </Fragment>
                );
              })}
            </div>

            {
              <>
                {hasDuplicateNames() && !hasMatchingName ? (
                  <>
                    <ErrorWrapper className="left-space">
                      <span className="form-error ">
                        <>Cannot add variants with same name</>
                      </span>
                    </ErrorWrapper>
                  </>
                ) : (
                  ""
                )}
              </>
            }

            {viewReadOnly ? null : (
              <div class="form-group button-add mt-3 text-right">
                <StyledButton
                  type="button"
                  className="btn"
                  onClick={handleaddclick}
                  disabled={
                    !formData.vehicle_select ||
                    formData.vehicle_select === "" ||
                    isDisableVariantButton()
                  }
                  data-for="disabled-add-variants"
                  data-tip={"Please fill variant details to add new variants"}
                >
                  <span>
                    <i className="fal fa-plus"></i> Add Variants
                  </span>
                </StyledButton>
                {/* {isDisableVariantButton() && (
                  <ReactTooltip
                    id="disabled-add-variants"
                    place="bottom"
                    type="info"
                    effect="float"
                  />
                )} */}
              </div>
            )}
          </div>
        ) : (
          <></>
        )}

        {/* Common Price */}
        {(mappedServiceData.common_price === null ||
          mappedServiceData.common_price === undefined) &&
        viewReadOnly ? (
          " "
        ) : (
          <div className="row common-row m-0">
            {viewReadOnly ? null : (
              <div
                className="radio-title"
                style={{ width: "min-content", gap: "12px" }}
              >
                <input
                  readOnly={viewReadOnly}
                  disabled={
                    !viewReadOnly && !editMode && formData.vehicle_select === ""
                      ? true
                      : false
                  }
                  type="radio"
                  value="Common Price"
                  name="isPrice"
                  onChange={(e) => {
                    setIsPrice(e.target.value);
                    e.target.checked = true;
                    SetInputList([]);
                  }}
                  checked={isPrice === "Common Price" ? true : false}
                  onBlur={() => onChangeValidate("isPrice")}
                />
                Set Common Price
                <sup className="theme-text radio-required">*</sup>
              </div>
            )}
            <div
              className={`input-group input-group-custom              
            ${
              viewReadOnly
                ? " price-change view-only"
                : isPrice === "Common Price"
                ? " price-change"
                : " price-fade"
            }
            `}
            >
              {viewReadOnly && (
                <div className="input-group-prepend">
                  <span class="input-group-text">Common Price</span>
                </div>
              )}
              <input
                type="text"
                readonly
                className={`form-control ${
                  viewReadOnly ? "disable-input" : ""
                }`}
                placeholder=" Common Price"
                aria-label="Username"
                aria-describedby="basic-addon1"
                readOnly={viewReadOnly}
                value={formData.common_price}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  if (!isNaN(value) && value >= 0) {
                    SetFormData({
                      ...formData,
                      common_price: value,
                    });
                  } else {
                    SetFormData({
                      ...formData,
                      common_price: "",
                    });
                  }
                }}
                onBlur={() => onChangeValidate("common_price")}
                maxLength="7"
              />
            </div>
            {error && formErrors.common_price ? (
              <span
                className="input-block form-error"
                style={{
                  fontSize: "13px",
                  color: "#f44336",
                  padding: "0",
                }}
              >
                {formErrors.common_price}
              </span>
            ) : (
              ""
            )}
          </div>
        )}

        {viewReadOnly && mappedServiceData.status === -1 ? (
          <Fragment>
            <hr />
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i className="fal fa-info-circle text-danger"></i>Reason For
                  Delete
                  <sup className="theme-text">*</sup>
                </span>
              </div>
              <textarea
                className="form-control"
                readOnly="true"
                value={mappedServiceData.rejected_reason}
              ></textarea>
            </div>
          </Fragment>
        ) : (
          ""
        )}
        {viewReadOnly ? null : (
          <div className="row p-0 mt-5">
            <div className="col-6 text-left">
              <button className="btn btn-update save-draft" type="submit">
                <i class="fal fa-save mr-2"></i>
                {/* {editMode ? "Update Draft" : "Save Draft"} */}
                {mappedServiceData.status === 0 ? "Update Draft" : "Save Draft"}
              </button>
            </div>
            <div className="col-6 text-right">
              <button
                className="btn btn-update"
                onClick={() => SetReview(!review)}
                type="submit"
                disabled={
                  isDisableVariantButton() && isPrice !== "Common Price"
                }
              >
                Send For Review
              </button>
            </div>
          </div>
        )}
        {/* Service Image Modal */}
        <ServiceFileUploadModal
          setShow={setShow}
          showfilemodal={show}
          hidefilemodal={handleClose}
        />
      </form>
    </Wrapper>
  );
}

export default ServiceForm;

const NewVariantLabelWrapper = styled.div`
  /* font-size: 14px; */
  padding: 0 15px 0 0;
  min-width: 160px;
  max-width: 160px;
  font-size: clamp(0.5rem, 14px, 1rem);
  text-align: start;
  i {
    margin-right: 10px;
    min-width: 20px;
  }
`;
const Wrapper = styled.div`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background: #e8e8e8 !important;
    background-color: #e8e8e8 !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    /* background-clip: text !important; */
  }
  .input-group-custom {
    padding: 0px !important;
  }
  .radio-required {
    color: #0083ff;
    font-size: 16px;
    margin: 0 0 -5px 0;
    right: 0;
  }
  .disable-input {
    color: #999999 !important;
  }
  .disable-select {
    .css-1xc3v61-indicatorContainer {
      display: none;
    }
  }
  .css-1fdsijx-ValueContainer {
    font-size: 14px;
  }
  .css-16xfy0z-control {
    background-color: #e8e8e8;
  }
  .input-block {
    input[aria-label="Username"] {
      min-height: 38px;
    }
    .css-1xc3v61-indicatorContainer {
      color: #666666 !important;
    }
  }
  .css-t3ipsp-control {
    background-color: #e8e8e8;
    border: none !important;
    box-shadow: none;
  }

  textarea {
    resize: none;
    ::-webkit-scrollbar {
      width: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #ededed;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      width: 9px;
      background: #cccccc;
      border-radius: 10px;
    }
  }
`;
const DesignText = styled.span`
  /* white-space: nowrap; 
   width: 250px; 
   overflow: hidden; 
   text-overflow: ellipsis; */
  .my-custom-text {
    white-space: nowrap;
    min-width: 20px;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /* &.theme-light
    &.form-control
    &.content-block
    &.content-window
    &.main-section {
    white-space: nowrap;

    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 70px;
  } */
  /* &.content-right-block input {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 70px;
  } */
  &.theme-light &.content-block &.main-section &.content-window &.form-control {
    color: #252525;
    background: #e8e8e8;
    border: none;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    padding-right: 70px !important;
  }
`;
const VehicleTypeContainer = styled.div`
  .css-13cymwt-control {
    background-color: #e8e8e8;
  }
`;
const ValidWrapper = styled.div`
  .css-13cymwt-control {
    background-color: #e8e8e8;
  }
`;
const ErrorWrapper = styled.div`
  text-align: left;
  &.left-space {
    padding: 0 0 0 160px;
  }
  .form-error {
    font-size: 13px;
    color: #f44336;
  }
`;
const StyledButton = styled.button`
  &:disabled {
    cursor: not-allowed !important;
    pointer-events: auto;
    &:hover {
      background: transparent !important;
      color: #000000 !important;
      opacity: 0.4 !important;
    }
  }
`;
