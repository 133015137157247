import React, { useState } from "react";
import InputLayout from "../../../../SuperAdmin/Pages/components/Layouts/InputLayout";
import CustomTextFieldWithChip from "../../../../SuperAdmin/Pages/components/CustomTextfieldWithChip";
import styled from "styled-components";
import CustomDropDown from "../../../../SuperAdmin/Pages/components/CustomDropDown";
import CustomFileUpload from "../../../../SuperAdmin/Pages/components/CustomFileUpload";

interface ImageMedia {
  base64: string;
  type: "image";
}

interface VideoMedia {
  videoSrc: string;
  type: "video";
}

type Media = ImageMedia | VideoMedia;

type OfferFormProps = {
  formData: any;
  setFormData: React.Dispatch<React.SetStateAction<any>>;
  isEdit?: boolean;
  handleCancelClick: () => void;
};

const OfferFrom = ({
  formData,
  setFormData,
  isEdit,
  handleCancelClick,
}: OfferFormProps) => {
  const dummyData = [
    { id: 1, title: "React" },
    { id: 2, title: "Angular" },
    { id: 3, title: "Vue" },
    { id: 4, title: "Node.js" },
    { id: 5, title: "Express" },
    { id: 6, title: "MongoDB" },
    { id: 7, title: "Redis" },
    { id: 8, title: "PostgreSQL" },
    { id: 9, title: "MySQL" },
    { id: 10, title: "GraphQL" },
  ];
  const dummyOptions = [
    { label: "Top Offer", value: 1 },
    { label: "Combo Deals", value: 2 },
  ];
  const [image, setImage] = useState<
  (
    | {
        base64: string;
        type: "image";
      }
    | { videoSrc: string; type: "video" }
  )[]
  >([]);

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleDiscountTypeChange = (e: any) => {
    const selectedDiscountType = e.target.value;
    setFormData((prev: any) => ({
      ...prev,
      discountType: selectedDiscountType,
      percentage: selectedDiscountType === "percentage" ? "" : prev.percentage,
      twoWheeler: selectedDiscountType === "amount" ? "" : prev.twoWheeler,
      fourWheeler: selectedDiscountType === "amount" ? "" : prev.fourWheeler,
    }));
  };
  const [isRemovedViewImage, setIsRemovedViewImage] =
    React.useState<boolean>(false);
  const handleRemoveDefaultImage = () => {
    setIsRemovedViewImage(true);
    setFormData({
      ...formData,
      image: "",
    });
  };
  const handleImageUpload = (e: Media[]) => {
    setFormData({
      ...formData,
      image: e[0]!,
    });
  };

  return (
    <>
      <Wrapper className={`col-12 ${!isEdit ? "view-mode" : ""}`}>
        <form className="w-100" id="add-offer">
          <InputLayout
            isImportant
            title="Photo"
            icon={<i className="fal fa-images"></i>}
          >
            <div className="upload-photo">
              <CustomFileUpload
                setImage={setImage}
                viewonly={!isEdit}
                showImage={isRemovedViewImage ? undefined : formData.image}
                handleRemoveDefaultImage={handleRemoveDefaultImage}
                onImageConfirmClick={handleImageUpload}
              />
            </div>
          </InputLayout>
          <InputLayout
            isImportant
            title="Title"
            icon={<i className="fal fa-keyboard"></i>}
          >
            <input
              type="text"
              name="title"
              className="form-control"
              value={formData.title}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </InputLayout>
          <InputLayout
            isImportant
            title="Terms and condition"
            icon={<i className="fal  fa-tasks"></i>}
          >
            <textarea
              name="termsAndConditions"
              className="form-control"
              value={formData.termsAndConditions}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </InputLayout>
          <InputLayout
            isImportant
            title="Description"
            icon={<i className="fal fa-clipboard"></i>}
          >
            <textarea
              name="description"
              className="form-control"
              value={formData.description}
              onChange={handleChange}
              disabled={!isEdit}
            />
          </InputLayout>
          <InputLayout
            isImportant
            title="Offer Type"
            icon={<i className="fal  fa-money-bill-alt"></i>}
          >
            <div className="offer-type">
              <label>
                <input
                  type="radio"
                  name="discountType"
                  value="amount"
                  checked={formData.discountType === "amount"}
                  onChange={handleDiscountTypeChange}
                  disabled={!isEdit}
                />
                Amount
              </label>
              <label>
                <input
                  type="radio"
                  name="discountType"
                  value="percentage"
                  checked={formData.discountType === "percentage"}
                  onChange={handleDiscountTypeChange}
                  disabled={!isEdit}
                />
                Percentage
              </label>
            </div>
          </InputLayout>
          {formData.discountType === "percentage" && (
            <InputLayout
              isImportant
              title="Percentage"
              icon={<i className="fal  fa-percent"></i>}
            >
              <input
                type="text"
                name="percentage"
                className="form-control"
                value={formData.percentage}
                onChange={handleChange}
                disabled={!isEdit}
              />
            </InputLayout>
          )}
          {formData.discountType === "amount" && (
            <>
              <InputLayout
                isImportant
                title="2 Wheeler"
                icon={<i className="fal  fa-motorcycle"></i>}
              >
                <input
                  type="text"
                  name="twoWheeler"
                  className="form-control"
                  value={formData.twoWheeler}
                  onChange={handleChange}
                  disabled={!isEdit}
                />
              </InputLayout>
              <InputLayout
                isImportant
                title="4 Wheeler"
                icon={<i className="fal  fa-car"></i>}
              >
                <input
                  type="text"
                  name="fourWheeler"
                  className="form-control"
                  value={formData.fourWheeler}
                  onChange={handleChange}
                  disabled={!isEdit}
                />
              </InputLayout>
            </>
          )}
          <InputLayout
            isImportant
            title="Offer Category"
            icon={<i className="fal  fa-list"></i>}
          >
            <CustomDropDown
              data={dummyOptions}
              disabled={!isEdit}
              className={`${!isEdit ? "disable-select" : ""}`}
              //   name="fourWheeler"
              //   value={formData.fourWheeler}
              //   onChange={handleChange}
            />
          </InputLayout>
          <InputLayout
            isImportant
            title="Services"
            icon={<i className="fal  fa-wrench"></i>}
          >
            <CustomTextFieldWithChip
              options={dummyData}
              //   name="fourWheeler"
              //   value={formData.fourWheeler}
              onChange={handleChange}
            />
          </InputLayout>
        </form>
        {isEdit ? (
          <ButtonWrapper>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary ml-3"
              type="button"
              onClick={() => {}}
            >
              Save
            </button>
          </ButtonWrapper>
        ) : (
          ""
        )}
      </Wrapper>
    </>
  );
};

export default OfferFrom;
const Wrapper = styled.div`
  .offer-type {
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    padding-top: 10px;
    label {
      color: #252525;
      font-size: 14px;
    }
    input {
      margin-right: 5px;
    }
  }
  .disable-select {
    .dropdown__indicators {
      display: none;
    }
  }
  .dropdown__control {
    background-color: #e8e8e8;
    box-shadow: none;
    border: none !important;
  }
  &.view-mode {
    input[type="text"] {
      color: #999999 !important;
    }
    textarea {
      color: #999999 !important;
    }
    .date-picker {
      color: #999999 !important;
      input {
        color: #999999 !important;
      }
    }
    .dropdown__indicators {
      display: none;
    }
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
