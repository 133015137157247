import React, { useState, createContext, useEffect } from "react";
import { getServiceList, getService } from "../../../../api/services/sup/service-management";

export const ApprovalContext = createContext("");

export const ApprovalProvider = (props) => {
  const [editMode, SetEditMode] = useState(false);
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [mappedServiceData, SetMappedServiceData] = useState({});
  const [review, SetReview] = useState(false);
  const [servicePic, setServicePic] = useState([]);
  const [table, SetTable] = useState([]);
  const [viewReadOnly, SetViewReadOnly] = useState(false);
  const [formChange, SetFormChange] = useState(false);
  //Toggle For Action
  const [isSelected, setIsSelected] = useState(false);

  const getServiceData = (id,openEditForm) =>{
     const data = getService(id);
      data.then((res)=>{
      SetMappedServiceData(res.data);
      if(openEditForm){
        openEditForm(true);
      }
      SetViewReadOnly(true);
      setIsUpdateForm(true);
     })
  }
  

  let initialStateValues = {
    // deleteServiceAction,
    editMode,
    // handleServiceDelete,
    isUpdateForm,
    mappedServiceData,
    review,
    servicePic,
    SetEditMode,
    // setEditData,
    getServiceData,
    setIsUpdateForm,
    SetMappedServiceData,
    SetReview,
    setServicePic,
    SetTable,
    SetViewReadOnly,
    table,
    viewReadOnly,
    formChange,
    SetFormChange,
    isSelected,
    setIsSelected,
  };

  useEffect(() => {
    const data = getServiceList();
    data.then((res) => {
      console.log(data);
      let tableData = res.data.map((item, i) => ({
        id: item.service_id_pk,
        name: item.service_name,
        vehicle_select: item.vehicleType.title,
        status: item.status,
        serviceCenter:item.profile.service_center_name,
        is_new: item.is_new,
        is_active:item.is_active

      }));
      SetTable([...tableData]);
    });
  }, []);

  return (
    <ApprovalContext.Provider value={initialStateValues}>
      {props.children}
    </ApprovalContext.Provider>
  );
};
