import React  from 'react';
import "../../../../assets/css/main-admin.css";
import Layout from "../../Layout/Layout";
import ContentHeader from "../../Layout/ContentHeader";
import ServiceFilterWrapper from "../User/Ui/SuperUserFilterWrapper";


const DashboardSup = () => {
  return (
    <Layout>
      <div className="content-block">
      <section>
      <ContentHeader />
        <div class="content-title-action">
          <div class="title-action">
            <div class="title-action-left">
              <div class="action-block">
              </div>
            </div>
            <div class="title-action-right d-flex">
               <div class="filter-wrapper">
            <div class="search-sort-filter">
                <div class="header-action-block">
                    <form action="" method="">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text search-span-txt">
                                <i class="fal fa-search"></i>
                                </span>
                            </div>
                            <input
                                type="text"
                                class="form-control"
                                id="search-id"
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                            />          
                        </div>
                    </form>
                    <div class="sort-filter">
                    </div>
                </div>
            </div>
        </div>


              <div class="action-block">
                <button
                  class="btn btn-card"
                  rel="tooltip"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="view"
                  view-click="service"
                >
                  <span>
                    <i class="fas fa-table"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
          <div className="main-admin-content-block">
            <div className="row ml-0 mr-0">
              <div className="col-xl-3 mb-4 col-sm-6 mb-6 col-12">
                <div className="card-main">
                  <div className="card-main-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="cards-txt">
                        <h4>Orders</h4>
                        <h4 className="text-white mt-3">$6781.00</h4>
                        <h6 className="text-muted">35.19% Since last month</h6>
                      </div>
                      <div className="icon-box bg-primary-gradient">
                        <i className="fal fa-bullhorn"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 mb-4 col-sm-6 mb-6 col-12">
                <div className="card-main">
                  <div className="card-main-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="cards-txt">
                        <h4>Sales</h4>
                        <h4 className="text-white mt-3">$6781.00</h4>
                        <h6 className="text-muted">73.52% Since last month</h6>
                      </div>
                      <div className="icon-box bg-secondary-gradient ">
                        <i className="fal fa-book"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 mb-4 col-sm-6 mb-6 col-12">
                <div className="card-main">
                  <div className="card-main-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="cards-txt">
                        <h4>Revenue</h4>
                        <h4 className="text-white mt-3">$4753.00</h4>
                        <h6 className="text-muted">49.39% Since last month</h6>
                      </div>
                      <div className="icon-box bg-tertiary-gradient">
                        <i className="fal fa-chart-pie"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 mb-4 col-sm-6 mb-6 col-12">
                <div className="card-main">
                  <div className="card-main-body">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="cards-txt">
                        <h4>Cost</h4>
                        <h4 className="text-white mt-3">$4753.00</h4>
                        <h6 className="text-muted">49.39% Since last month</h6>
                      </div>
                      <div className="icon-box bg-quaternary-gradient ">
                        <i className="fal fa-dollar-sign"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ml-0 mr-0">
              <div className="col-xl-8 col-md-12 mb-4">
                <div className="theme-graph-content">
                  <div id="primary-bargraph"></div>
                </div>
              </div>
              <div className="col-xl-4 mb-4 col-md-6">
                <div className="primary-graph">
                  <div className="theme-graph-content">
                    <div id="radialbar"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </section>
      </div>  
    </Layout>
  );
};

export default DashboardSup;
