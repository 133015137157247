// import {ContentHeader,FilterWrapper,ContentTitle,WindowLeft,WindowRight,InnerLoader} from './Ui';
import Layout from "../../Layout/Layout";
// import BookingsContentHeader from "./Ui/BookingsContentHeader";
// import BookingsContentTitle from "./Ui/BookingsContentTitle";
// import BookingsWindowLeft from "./BookingsWindowLeft";
// import BookingsWindowRight from "./BookingsWindowRight";
// import BookingsInnerLoader from "./Ui/BookingsInnerLoader";
import React, { useEffect, useRef, useState } from "react";
// import './Ui/css/Approval.css'
// import { BookingsProvider } from "./BookingsContext";
import ContentHeader from "../../Layout/ContentHeader";
import VendorContentTitle from "../components/Layouts/ContentTitleWithButton";
import WindowLeft from "./WindowLeft";
import WindowRight from "./WindowRight";
import { VendorListModel } from "../../../../api/services/vap/models/VendorListModel";
import {
  getVendorDetails,
  getVendorDocumentDetails,
  getVendorList,
  VendorVerifyDetails,
} from "../../../../api/services/sup/vendor-management";
import { VendorProvider } from "./context/VendorContext";
import { GridCallbackDetails, GridRowParams, MuiEvent } from "@mui/x-data-grid";
import { react } from "@babel/types";
// import { StaticRouterProps } from "react-router-dom";
// import { VendorProvider } from "./context/VendorContext";
import mainLoader from "../../../../assets/img/loader/loader.gif";
import ContentTitleWithButton from "../components/Layouts/ContentTitleWithButton";

const VendorSup = () => {
  //useStates
  const [addVendor, SetAddVendor] = useState(false);
  const [viewVendor, SetViewVendor] = useState(false);
  const [paginationTrigger, setPaginationTrigger] = useState(false);
  const [rightView, SetRightView] = useState(false);
  const [vendorList, SetVendorList] = useState<VendorListModel[]>([]);
  const [mappedVendorData, SetMappedMappedData] = useState();
  console.log(vendorList, "vendorList");
  console.log(mappedVendorData, "mappedVendorData");
  const [liststatus, setListstatus] = useState(false);
  const [activeStep, setActiveStep] = React.useState(1);
  const [pendingCount, setPendingCount] = useState(1);
  const [activeButton, setActiveButton] = useState<number | null>(0);
  const [filterStatus, setFilterStatus] = useState<string | number | null>(0);
  const [selectedRowIndex, setSelectedRowIndex] = React.useState(0);
  const [addActiveStep, setAddActiveStep] = React.useState(0);
  const [PageLoader, setPageLoader] = React.useState(false);
  //useRef for Selected Row
  const selectedRowRef = useRef<any>(null);
  const handleAddVendorsClick = () => {
    handleViewRight();
    SetAddVendor(true);
    SetViewVendor(false);
  };
  const handleViewRight = () => SetRightView(true);

  const tabs = [
    { label: " All Vendors", status: null },
    { label: " Approved Vendors", status: 4 },
    { label: " Rejected Vendors", status: 3 },
    { label: " Pending Vendors", status: 1 },
  ];
  const handleViewListing = (status: number | null, i: number) => {
    console.log("statusssss", status);
    setActiveButton(i);
    handleGetVendorList(status);
    SetRightView(false);
  };

  //Select all rows and remove selected row Attribute
  function removeSelectedRowAttr() {
    const allTableRows = document.querySelectorAll(".MuiDataGrid-row");
    allTableRows.forEach((rowElement) => {
      rowElement.removeAttribute("row-selected");
    });
  }
  //preserve  active row on pagination
  function setSelectedRowAttr() {
    const allTableRows = document.querySelectorAll(".MuiDataGrid-row");
    const selectedRowDataIndex = selectedRowRef.current
      ? selectedRowRef.current.getAttribute("data-rowindex")
      : "";
    if (allTableRows) {
      allTableRows.forEach((row) => {
        const rowIndex = row.getAttribute("data-rowindex");

        // Check if the data-rowindex exists
        if (rowIndex === selectedRowDataIndex) {
          // Set a custom attribute for the specific element
          row.setAttribute("row-selected", "true");
        }
      });
    }
    // const allTableRows = document.querySelectorAll(".MuiDataGrid-row");
    // allTableRows.forEach((rowElement) => {
    //   rowElement.removeAttribute("row-selected");
    // });
  }
  const handleCloseRight = () => {
    SetRightView(false);
    setListstatus(!liststatus);
    removeSelectedRowAttr();
    getVendorList();
  };
  let toggleClassCheck = rightView ? " change" : "";

  console.log(selectedRowRef.current, " selectedRowRef.current");

  const handleViewVendor = (
    e: GridRowParams,
    event: any,
    details?: GridCallbackDetails
  ) => {
    console.log("viewVendorClick-- params", e);
    console.log("viewVendorClick-- event", event);
    console.log("viewVendorClick-- details", details);
    //Set selected row
    if (event) {
      selectedRowRef.current = event.target.closest(".MuiDataGrid-row");

      // Check if the closest parent element is found
      if (selectedRowRef.current) {
        // Remove the "row-selected" Attribute from all row elements
        removeSelectedRowAttr();
        // Append a new row-selected Atribute to the closest parent element
        selectedRowRef.current.setAttribute("row-selected", "true");
      }
    }
    SetAddVendor(false);
    //   const [pageloader, SetPageLoader] = useState(false);
    //  r
    //   useEffect(() => {
    //     SetPageLoader(true);
    //     const loaderTime = setTimeout(() => {
    //       SetPageLoader(false);
    //     }, 1500);
    //     return () => {
    //       clearTimeout(loaderTime);
    //     };
    //   }, []);

    let vendorId = e.row.vendor_id_pk; // vendor id from  row clicked
    setPageLoader(true);
    getVendorDetails(vendorId)
      .then((res) => {
        if (res) {
          console.log(res, "response getVendorDetails");
          setActiveStep(0);
          SetMappedMappedData(res);
          handleViewRight();
          SetViewVendor(true);
          setPageLoader(false);
        }
      })
      .catch((err) => {
        console.log(err, "response getVendorDetails error");
      });
    console.log(e, "row-event");
  };
  useEffect(() => {
    setSelectedRowAttr();
  }, [paginationTrigger]);

  function handleGetVendorList(params: number | null) {
    console.log("pppp", params);
    getVendorList(params)
      .then((res) => {
        if (res) {
          console.log(
            res,
            "response vendor list................................................................."
          );
          SetVendorList(
            res?.map((item: any, i: number) => {
              return { ...item, id: i + 1 };
            })
          );
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err, "response vendor list");
      });
  }

  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    handleGetVendorList(null);
  }, [liststatus]);
  console.log(
    vendorList,
    "---vendorList vendor list0000000000000000000000000000000"
  );

  return (
    <>
      {loader ? (
        <div className="loader-block">
          <img className="img-fluid" src={mainLoader} alt="" />
        </div>
      ) : (
        <Layout>
          <div className="content-block">
            {/* <BookingsInnerLoader /> */}
            <section className="main-section">
              <div className="container-fluid p-0">
                <ContentHeader />
                <VendorProvider>
                  <div className="col-12 p-0 page-window">
                    <ContentTitleWithButton
                      handleAddClick={handleAddVendorsClick}
                      buttonTitle={"Add New"}
                      heading={'Vendors'}
                    />
                    <div className="content-section" view-content="service">
                      <div className="tab-button-group">
                        {tabs.map((tab, index) => (
                          <button
                            key={tab.label}
                            type="button"
                            className={activeButton === index ? "active" : ""}
                            onClick={() => handleViewListing(tab.status, index)}
                          >
                            {tab.label}
                            {/* {tab.status === 4 ? ` (${pendingCount})` : ""} */}
                          </button>
                        ))}
                      </div>

                      <div className={`content-window ${toggleClassCheck} `}>
                        <WindowLeft
                          viewVendorClick={handleViewVendor}
                          collapse={rightView}
                          tabledata={vendorList}
                          paginationTrigger={paginationTrigger}
                          setPaginationTrigger={setPaginationTrigger}
                          addVendor={addVendor}
                          pageLoader={PageLoader}
                          setPageLoader={setPageLoader}
                        />
                        {/* <BookingsWindowLeft
                        handleAddBookings={handleAddBookings}
                        bookingformClose={SetAddBookings}
                      /> */}
                        {rightView && (
                          <WindowRight
                            closeView={handleCloseRight}
                            addVendor={addVendor}
                            viewVendor={viewVendor}
                            dataStored={mappedVendorData}
                            SetMappedMappedData={SetMappedMappedData}
                            setActiveStep={setActiveStep}
                            activeStep={activeStep}
                            setAddActivestep={setAddActiveStep}
                            addActiveStep={addActiveStep}
                            setRightView={SetRightView}
                            listStatus={liststatus}
                            setListStatus={setListstatus}
                            pageLoader={PageLoader}
                            setPageLoader={setPageLoader}
                            SetAddVendor={function (
                              value: React.SetStateAction<boolean>
                            ): void {
                              throw new Error("Function not implemented.");
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </VendorProvider>
              </div>
            </section>
          </div>
        </Layout>
      )}
    </>
  );
};

export default VendorSup;
