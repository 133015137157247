import React from "react";
import Sidebar from "./Sidebar";

type Props = {
  children?: React.ReactNode; // make the component able to receive children elements
};

const VendorLayout = ({ children }: Props) => {
  return (
    <section id="main" className="main">
      <div className="container-fluid p-0">
        <div className="row m-0">
          <Sidebar />
          {children}
        </div>
      </div>
    </section>
  );
};

export default VendorLayout;
