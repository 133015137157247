import React, { useState } from 'react'
import OrderHistoryDropDownContent from './OrderHistoryDropDownContent';

const OrderHistoryDropDown = (props) => {
    const [orderView, setOrderView] = useState(false);
    const [option, setOption] = useState("All Orders");
  return (
    <div className="sort-dropdown text-left">
     <div className="dropdown sort-custom-dropdown">
      <button
        className="btn btn-secondary dropdown-toggle"
        style={{color:`#000` , backgroundColor:`#fff`}}
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => setOrderView(!orderView)}
      >
        <span className="service-sort-btn sort-btn">
         {option}
        </span>
      </button>
     
      {orderView && <OrderHistoryDropDownContent setOption={setOption} setOrderView={setOrderView}/>}
    </div>
  </div>
  )
}

export default OrderHistoryDropDown