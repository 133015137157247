import React, { useState, useContext, useEffect } from "react";
import { CustomerContext } from "../CustomerContext";
import { useDebounce } from "./hooks/useDebounce";
import { getCustomerList } from "../../../../../api/services/sap/customer-management";
const CustomerContentTitle = (props) => {
  const { customerList, setCustomerList } = useContext(CustomerContext);
  const [serachedValue, setSerachedValue] = useState("");

  const debouncedValue = useDebounce(serachedValue);

  const handleSearchChange = (e) => {
    setSerachedValue(e.target.value);
  };
  useEffect(() => {
    const fetchCustomerList = async () => {
      try {
        const response = await getCustomerList(serachedValue);
        console.log(response, "response.......");
        setCustomerList(response.data);
      } catch (error) {
        console.error("Error fetching customer list:", error);
      }
    };
    fetchCustomerList();
  }, [debouncedValue]);

  return (
    <div className="content-title-action">
      <div className="title-action">
        <div className="title-action-left">
          <h5 className="head">Customers</h5>
        </div>
        <div className="search-sort-filter">
          <div className="header-action-block">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text search-span-txt">
                  <i className="fal fa-search"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                id="search-id"
                placeholder="Search"
                aria-label="Username"
                aria-describedby="basic-addon1"
                onChange={(e) => handleSearchChange(e)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerContentTitle;
