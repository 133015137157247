import React, { useState } from "react";
import styled from "styled-components";
import InputLayout from "../../components/Layouts/InputLayout";
import CustomFileUpload from "../../components/CustomFileUpload";
import DatePicker from "react-date-picker";
import { format } from "date-fns";
import { FormData } from "../SupCouponsPage";
import Select from "react-select";

type Props = {
  handleCloseView: () => void;
  isEdit?: boolean;
  formData: FormData;
  setFormData: React.Dispatch<FormData>;
};
const offerData = [
  { offer_id: 1, offer_name: "New Year Discount" },
  { offer_id: 2, offer_name: "Festive Season Offer" },
  { offer_id: 3, offer_name: "Summer Sale" },
  { offer_id: 4, offer_name: "Winter Special" },
  { offer_id: 5, offer_name: "Loyalty Bonus" },
  { offer_id: 6, offer_name: "Referral Discount" },
  { offer_id: 7, offer_name: "Flash Sale" },
  { offer_id: 8, offer_name: "Weekend Special" },
];
const CouponForm = ({
  handleCloseView,
  isEdit,
  formData,
  setFormData,
}: Props) => {
  
  const [image, setImage] = useState<
  (
    | {
        base64: string;
        type: "image";
      }
    | { videoSrc: string; type: "video" }
  )[]
  >([]);

  const generateCouponCode = () => {
    const generateRandomString = () => {
      const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let result = "";
      for (let i = 0; i < 4; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
      }
      return result;
    };

    const newCouponCode = `${generateRandomString()}-${generateRandomString()}-${generateRandomString()}`;
    setFormData({ ...formData, couponCode: newCouponCode });
  };

  React.useEffect(() => {
    setFormData({
      ...formData,
      image: image[0]!,
    });
  }, [image]);

  return (
    <>
      <Wrapper className={`col-12 ${!isEdit ? "view-mode" : ""}`}>
        <form className="w-100" id="add-offer">
          <InputLayout
            isImportant
            title="Photo"
            icon={<i className="fal fa-images"></i>}
          >
            <div className="upload-photo">
              <CustomFileUpload setImage={setImage} viewonly={!isEdit} />
            </div>
          </InputLayout>
          <InputLayout
            isImportant
            title="Title"
            icon={<i className="fal fa-keyboard"></i>}
          >
            <input
              disabled={!isEdit}
              type="text"
              name="title"
              className="form-control"
              value={formData.title}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  title: e.target.value,
                })
              }
            />
          </InputLayout>

          <DatesWrapper>
            <InputLayout
              isImportant
              title="Start Date"
              icon={<i className="fal fa-calendar"></i>}
              className="startDate"
            >
              <DatePicker
                disabled={!isEdit}
                clearIcon={<></>}
                calendarIcon={<></>}
                format={"dd-MM-yyyy"}
                className={`form-control date-picker ${
                  !isEdit ? "disable-date" : ""
                } `}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    startDate: e as Date,
                  })
                }
                value={
                  formData.startDate
                    ? formData.startDate
                    : format(new Date(), "yyyy-MM-dd")
                }
              />
            </InputLayout>
            <InputLayout
              isImportant
              title="End Date"
              icon={<i className="fal fa-calendar"></i>}
              className="endDate"
            >
              <DatePicker
                disabled={!isEdit}
                clearIcon={<></>}
                calendarIcon={<></>}
                format={"dd-MM-yyyy"}
                className={`form-control date-picker ${
                  !isEdit ? "disable-date" : ""
                } `}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    endDate: e as Date,
                  })
                }
                value={
                  formData.endDate
                    ? formData.endDate
                    : format(new Date(), "yyyy-MM-dd")
                }
              />
            </InputLayout>
          </DatesWrapper>
          <CouponWrapper>
            <InputLayout
              isImportant
              title="Coupon Code"
              icon={<i className="fal fa-receipt"></i>}
              className="coupon-code"
            >
              <input
                disabled={!isEdit}
                type="text"
                name="cuponCode"
                className="form-control"
                value={formData.couponCode}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    couponCode: e.target.value,
                  })
                }
              />
            </InputLayout>
            {isEdit && (
              <button
                type="button"
                className="generate-button"
                onClick={generateCouponCode}
              >
                Generate
              </button>
            )}
          </CouponWrapper>
          <InputLayout
            isImportant
            title="Offer Type"
            icon={<i className="fal fa-paste"></i>}
          >
            <Select
              isDisabled={!isEdit}
              className={`form-select p-0 ${!isEdit ? "disable-select" : ""}`}
              classNamePrefix={"dropdown"}
              options={offerData}
              value={formData.offerType}
              defaultValue={formData.offerType}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  offerType: e!,
                });
              }}
              getOptionLabel={(option) => option.offer_name}
              getOptionValue={(option) => option.offer_name}
            />
          </InputLayout>
          <InputLayout
            isImportant
            title="Description"
            icon={<i className="fal fa-clipboard"></i>}
          >
            <textarea
              disabled={!isEdit}
              name="description"
              className="form-control"
              value={formData.description}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  description: e.target.value,
                })
              }
            />
          </InputLayout>
        </form>
        {isEdit ? (
          <div className="col-12 text-end p-0 mt-3 btn-wrapper">
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleCloseView}
            >
              Cancel
            </button>
            <button className="btn btn-update" type="submit">
              <i className="fal fa-save mr-2"></i>Save
            </button>
          </div>
        ) : (
          ""
        )}
      </Wrapper>
    </>
  );
};

export default CouponForm;

const Wrapper = styled.div`
  .offer-type {
    display: flex;
    justify-content: flex-start;
    gap: 40px;
    padding-top: 10px;
    label {
      color: #252525;
      font-size: 14px;
    }
    input {
      margin-right: 5px;
    }
  }
  .btn-wrapper {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
  .upload-photo {
    width: 150px;
  }
  .disable-select {
    .dropdown__indicators {
      display: none;
    }
  }
  .dropdown__control {
    background-color: #e8e8e8;
    box-shadow: none;
    border: none !important;
  }
  &.view-mode {
    input[type="text"] {
      color: #999999 !important;
    }
    textarea {
      color: #999999 !important;
    }
    .date-picker {
      color: #999999 !important;
      input {
        color: #999999 !important;
      }
    }
    .dropdown__indicators {
      display: none;
    }
  }
`;
const DatesWrapper = styled.div`
  display: flex;
  gap: 20px;
  .startDate {
    width: 100%;
  }
  .endDate {
    width: 100%;
  }
`;
const CouponWrapper = styled.div`
  display: flex;
  gap: 20px;
  .coupon-code {
    width: 100%;
  }
  button {
    width: 20%;
    height: 38px;
    border: none;
    background-color: #4294ff;
    color: #ffffff;
    border-radius: 3px;
  }
`;
