import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { NavLink } from "react-router-dom";
import LogoColor from "./assets/img/motorque-logo.svg";

type Props = {};

const Sidebar = (props: Props) => {
  const menuItems: Array<any> = [
    // {
    //   path: "/dashboard",
    //   name: "Dashboard",
    //   icon: <i className="fal fa-user"></i>,
    // },
    // {
    //   path: "/service",
    //   name: "Service",
    //   icon: <i className="fal fa-taxi"></i>,
    // },
    // {
    //   path: "/staff",
    //   name: "Staff",
    //   icon: <i className="fal fa-users"></i>,
    // },
    // {
    //   path: "/bookings",
    //   name: "Bookings",
    //   icon: <i className="fal fa-calendar"></i>,
    // },
    // {
    //   path: "/orders",
    //   name: "Orders",
    //   icon: <i className="fal fa-tasks"></i>,
    // },
    // {
    //   path: "/customer",
    //   name: "Customer",
    //   icon: <i className="fal fa-user"></i>,
    // },
  ];

  const [activeStatus, setActiveStatus] = React.useState(false);
  //   const updatedActiveStatus = (status) => {
  //     setActiveStatus(status);
  //   };

  return (
    <div className="sidebar-block">
      <div className="menu-side">
        <div className="sidebar-min-head text-center">
          <div className="sidebar-logo">
            <span className="logo">
              <img
                className="img-fluid"
                src={LogoColor}
                style={{ height: "100px", objectFit: "cover" }}
                alt=""
              />
            </span>
          </div>
        </div>
        <Scrollbars style={{ height: `calc(100vh - 213px)` }}>
          <div className="menu-scroll">
            <ul className="list-sidebar bg-defoult menu-list">
              <li>
                {menuItems &&
                  menuItems.map((item, index) => (
                    <NavLink
                      to={item.path}
                      key={index}
                      className={`link active`}
                    >
                      <div className="nav-label">
                        <span className="mr-1 icon-span">{item.icon}</span>
                        <span className="span-name">{item.name}</span>
                      </div>
                    </NavLink>
                  ))}
              </li>
            </ul>
          </div>
        </Scrollbars>
        <div className="bottom-action">
          <button className="btn btn-faq">FAQS</button>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
