import React, { useContext } from "react";
import ReactTooltip from "react-tooltip-rc";
import StaffFilterWrapper from "./StaffFilterWrapper";
import { StaffContext } from "../StaffContext";
const StaffContentTitle = (props) => {
  const {
    SetRowActive,
    
  } = useContext(StaffContext);
  const handleAdd = () => { 
    props.handleAdd();
    SetRowActive(false);
    const allTableRows = document.querySelectorAll(".rdt_TableRow");
      allTableRows.forEach((rowElement) => {
        rowElement.classList.remove("current-selected-row");
      });
  };
  return (
    <div class="content-title-action">
      <div class="title-action">
        <div class="title-action-left">
          <h5 class="head">Staff</h5>
          <div class="action-block">
            <button
              className="btn add-new "
              onClick={handleAdd}
            >
              <span>
                <i class="fal fa-plus"></i> Add New
              </span>
            </button>
          </div>
        </div>
        <div class="title-action-right d-flex">
          <StaffFilterWrapper />
          {/* <button
          className="btn btn-card"
          rel="tooltip"
          data-toggle="tooltip"
          title="view"
          view-click="service"
        >
          <span>
            <i className="fas fa-table"></i>
          </span>
        </button> */}
        </div>
      </div>
    </div>
  );
};

export default StaffContentTitle;
