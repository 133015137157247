import React, { useState, useContext, useEffect } from "react";
import DataTable from "react-data-table-component";
import dateFormat, { masks } from "dateformat";
import { CustomerContext } from "./CustomerContext";
import CustomerDeleteModal from "./Ui/CustomerDeleteModal";
import CustomerContentTitle from "./Ui/CustomerContentTitle";
import { getCustomerList } from "../../../../api/services/sap/customer-management";
import styled from "styled-components";

const OrderWindowLeft = () => {
  const {
    deleteId,
    setDeleteId,
    orderTable,
    setMappedOrderData,
    filterValue,
    setFilterValue,
    viewInnerOrder,
    setViewInnerOrder,
    setVehicleFilter,
    customerList,
    setCustomerList,
  } = useContext(CustomerContext);

  const [deleteModal, setDeleteModal] = useState(false);
  const columns = [
    {
      name: "Name",
      selector: (row) => row.customer_name,
      sortable: true,
    },
    {
      name: "Contact Number",
      selector: (row) => row.phone_number,
      sortable: true,
      // cell: (row) => (
      //   <div>
      //     {row.customer_name}
      //     {row.booking_from_app === 1 && (
      //       <span class="ml-1 badge rounded-pill bg-warning text-dark">
      //         APP
      //       </span>
      //     )}
      //   </div>
      // ),
      allowOverflow: false,
    },
    {
      name: "App User",
      selector: (row) => row.app_user,
      sortable: true,
      cell: (row) => <div>{row.app_user === 1 ? "Yes" : "No"}</div>,
    },
    {
      name: "No of Vehicles",
      selector: (row) => row.vehicle_count,
      sortable: true,
    },
    {
      name: "No of Orders",
      selector: (row) => row.order_count,
      sortable: true,
    },
  ];
  //PAGINATION OPTIONS
  const paginationComponentOptions = {
    rowsPerPageText: false,
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    noRowsPerPage: true,
  };
  const sortIcon = <i class="fal fa-sort"></i>;
  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  const handleDeleteModal = (e, rowid) => {
    setDeleteModal(true);
    setDeleteId(rowid);
    e.stopPropagation();
  };

  const handleDeleteCancel = () => {
    setDeleteModal(false);
  };

  const viewHandler = (item) => {
    
    const data = orderTable.filter(
      (tValue) => tValue.customer_id === item.customer_id
    );
    console.log(data, "Customer Data");
    const mappedOrderDataObject = Object.assign({}, ...data);
    setMappedOrderData(mappedOrderDataObject);
    setViewInnerOrder(true);
    console.log(viewInnerOrder, "viewInnerOrder");
    setVehicleFilter(false);
  };

  useEffect(() => {
    setFilterValue(orderTable);
  }, [orderTable]);

  return (
   
    <div className="content-window-left">
      <div className="content-table-block">
        <div className="content-table table-responsive">
          <div className="list-order-block"></div>
          <WrapperLeft>
          <DataTable
            columns={columns}
            data={customerList}
            highlightOnHover={true}
            pointerOnHover={true}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            sortIcon={sortIcon}
            customStyles={customStyles}
            setCustomerList={setCustomerList}
            onRowClicked={(event) => viewHandler(event)}
          />
          </WrapperLeft>
        </div>
        {deleteModal && (
          <CustomerDeleteModal
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            handleDeleteCancel={handleDeleteCancel}
            order_id={deleteId}
          />
        )}
      </div>
    </div>
    
  );
};

export default OrderWindowLeft;
const WrapperLeft = styled.div`
.kjHanM {
  margin: 0px 5px;
}
.DfDUN {
  width:30px;
}
`;
