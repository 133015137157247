import React, { Fragment, useContext } from "react";
import { Modal } from "react-bootstrap";
import { StaffContext } from "./StaffContext";
import { toast } from "react-toastify";
import deleteFile from "../../../../assets/img/icon/upload/recycle-bin.svg";
const StaffDeleteModal = (props) => {
  const {
    staffTable,
    handleStaffDelete,
    deleteId,
    deleteModal,
    setDeleteModal,
    // handleDeleteCancel
  } = useContext(StaffContext);
  
  // props.closeStaffView(true)

  const handleDeleteCancel = (e) => {
    setDeleteModal(false);
    // props.closeStaffView(true);

  };

  const deleteConfirm = () => {
    const obj = staffTable.filter((p) => p.id === deleteId);
    if (props.closeStaffView) {
      props.closeStaffView(false);
    }
    handleStaffDelete(deleteId);
    setDeleteModal(false);
    toast.success("Staff Deleted Successfully", {
      position: "bottom-right",
    });

  };

  return (
    <Fragment>
      <Modal
        className="custom-modal"
        show={deleteModal}
        onHide={handleDeleteCancel}
        // onClick={closeDeleteModal}
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>WARNING ....</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="modal-info-img">
            <img src={deleteFile} className="delete-file" alt="" />
          </div>
          <h5>Are you sure you want to delete?</h5>
          {/* <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet ullam
            voluptatem quam pariatur?
          </p> */}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-light" onClick={handleDeleteCancel}>
            Close
          </button>
          <button className="btn btn-danger" onClick={deleteConfirm}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default StaffDeleteModal;
