import { Button, Dialog, Stepper } from "@mui/material";
import React, { useEffect, useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import ReactTooltip from "react-tooltip-rc";
import styled from "styled-components";
import ProductForm, { ProductModel } from "../ProductForm";
import StyledTextEditor from "../../../../StyledTextEditor";
import { ProductListModel } from "../../../../../api/services/sap/vap/models/productListModel";
import { SaveButton } from "../../../../SaveButton";
import MuiUpdateStockModal from "../../../../MuiUpdateStockModal";
import {
  createProduct,
  getProductDetails,
  getProductList,
  getProductMeasurement,
} from "../../../../../api/services/sap/product-management";
import { productDetailsDataType } from "../../../../../api/services/sap/vap/models/createProductModel";
import { ProductDetailsModel } from "../../../../../api/services/sap/vap/models/productDetailsModel";
import { dropDownModel } from "../Products";
import { toast } from "react-toastify";
// import { ProductDetailsModel } from "../../../../../api/services/sap/vap/models/productDetailsModel";
import mainLoader from "../../../../../assets/img/loader/loader.gif";
import SimpleReactValidator from "simple-react-validator";

type measureUnitModel = {
  stock_measure_id_pk: number;
  unit: string;
}[];

type Props = {
  closeView?: () => void;
  rightView: boolean;
  viewProduct: boolean;
  tableDatas?: ProductListModel[];
  SetMappedProductData?: React.Dispatch<
    React.SetStateAction<ProductModel | undefined>
  >;
  datastored?: any;
  editButton?: boolean;
  addProduct?: boolean;
  onEditClick: () => void;
  isEditMode?: boolean;
  addProductDetails?: productDetailsDataType;
  SetAddProductDetails?: React.Dispatch<
    React.SetStateAction<productDetailsDataType>
  >;
  values?: dropDownModel;
  setFormSubmitted?: React.Dispatch<React.SetStateAction<boolean>>;
  mappedProductData?: ProductModel | undefined;
  setProductListstatus: React.Dispatch<React.SetStateAction<boolean>>;
  pageLoader: boolean;
  setPageLoader: React.Dispatch<React.SetStateAction<boolean>>;
  handleCarddetails: (id?: number | undefined) => void;
  setEditButton?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditMode?: (value: React.SetStateAction<boolean>) => void;
  validator?: SimpleReactValidator;
  stockunit?: measureUnitModel | undefined;
  setViewproduct?: React.Dispatch<React.SetStateAction<boolean>>;
  setEdithMode?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedMeasurementUnit?: React.Dispatch<React.SetStateAction<string | number>>;
  selectedMeasurementUnit?: string | number;
};

const WindowRight = ({
  closeView,
  rightView,
  viewProduct,
  tableDatas,
  SetMappedProductData,
  datastored,
  editButton,
  addProduct,
  onEditClick,
  isEditMode,
  addProductDetails,
  SetAddProductDetails,
  values,
  setFormSubmitted,
  mappedProductData,
  setProductListstatus,
  pageLoader,
  setPageLoader,
  handleCarddetails,
  setEditButton,
  setEditMode,
  setViewproduct,
  stockunit,
  setSelectedMeasurementUnit,
  selectedMeasurementUnit
}: // setSaveButton,
  Props) => {
  console.log(tableDatas, "tableDatas");
  console.log(stockunit, " stockunit");
  // const [isEditMode, setEditMode] = useState(false);

  // const onEditclick = () => {

  //   setEditMode(!isEditMode);
  // };
  const [measurementUnit, setMeasurementUnit] = useState<
  measureUnitModel
>([]);
// const [selectedMeasurementUnit, setSelectedMeasurementUnit] = useState<
//     string | number
//   >("");

  const [productDetails, setProductDetails] =
    React.useState<productDetailsDataType>({});

  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [validatorValue, setValidatorValue] = useState<number>(0);
  console.log("datastored", datastored, "mappedProductData", mappedProductData)

  const defaultformData = !isEditMode ?
    {
      product_name: datastored!?.product_name! ?? "",
      thumbnail: datastored?.thumbnail,
      sku_id: datastored?.sku_id,
      price_per_unit: datastored?.price_per_unit,
      brand_id: datastored?.brand?.brand_id_pk,
      description: datastored?.description,
      direction_of_use: datastored?.direction_of_use,
      vendor_sku_id: datastored?.vendor_sku_id,
      type: 1,
      source: 0,
      stock: datastored?.stock,
      quantity_per_unit: datastored?.quantity_per_unit,
      stock_unit: datastored?.stock_unit,
      measurement_unit: datastored?.measurement_unit,
      show_product_in_store: datastored?.show_product_in_store,
      in_stock: true,
      min_stock_count: 5,
    } : {
      product_id: mappedProductData?.id,
      product_name: mappedProductData?.product_name,
      thumbnail: mappedProductData?.thumbnail,
      sku_id: mappedProductData?.sku_id,
      price_per_unit: mappedProductData?.price_per_unit,
      brand_id: datastored?.brand?.brand_id_pk,
      description: mappedProductData?.description,
      direction_of_use: mappedProductData?.direction_of_use,
      vendor_sku_id: "",
      type: 1,
      source: 0,
      stock: mappedProductData?.stock,
      quantity_per_unit: mappedProductData?.quantity_per_unit,
      stock_unit: mappedProductData?.stock_unit,
      measurement_unit: mappedProductData?.measurement_unit,
      show_product_in_store: mappedProductData?.show_product_in_store,
      in_stock: true,
      min_stock_count: 5,
    };

  let formData = new FormData();
  for (const [key, value] of Object.entries(defaultformData)) {
    if (value !== undefined) {
      formData.append(key, String(value));
    }
  }
  formData.append("product_attachment", datastored?.attachment!);

  for (const pair of formData.entries()) {
    console.log(pair[0] + ": " + pair[1], "formData32145");
  }

  const onSaveClick = () => {
    setPageLoader(true);
    const errorMessages = simpleValidator.current.getErrorMessages();
    console.log(errorMessages, "errorMessages");
    const hasValidationErrors = Object.values(errorMessages ?? {}).every(
      (value) => value !== null
    );

    if (hasValidationErrors) {
      for (const key in errorMessages) {
        console.log(key, "keys");

        if (errorMessages.hasOwnProperty(key) && errorMessages[key] !== null) {
          simpleValidator.current.showMessageFor(key);
        }
      }
      forceUpdate();

      toast.error("enter all required fields", {
        position: "top-right",
      });
      setPageLoader(false);
      console.log("Validation errors detected");
    } else {
      setPageLoader(false);
      createProduct(formData)
        .then((res) => {
          console.log(res, "ressssss");
          if (res && res.product_id) {
            getProductList();

            console.log("check... ressssss");
            toast.success("Added to Inventory", {
              position: "top-right",
            });
            setProductListstatus(true);
            handleCarddetails(res.product_id);
            setEditButton?.(false);
            setEditMode?.(false);
          } else {
            toast.error("Enter all required fields", {
              position: "top-right",
            });
          }
          setPageLoader(false);
        })
        .catch((err) => {
          console.log(err, "response addProduct error");
          toast.error("Error", {
            position: "top-right",
          });
        });
    }
  };
  const getAllProductDetails = (id: string) => {
    getProductDetails(id).then(
      (res) => {
        console.log("getAllProductDetails",res);
        console.log("stock_unit",res.measurement_unit);

        SetMappedProductData?.(res)
        setSelectedMeasurementUnit?.(res.measurement_unit)
        getProductMeasurement?.(res?.measurement_unit).then(res => setMeasurementUnit?.(res) )
      }).catch((err) => {
        console.log("Error Object", err);
        })
  }
  const SavebuttonClick = () => {
    setPageLoader(true);
    const errorMessages = simpleValidator.current.getErrorMessages();
    console.log(errorMessages, "errorMessages");
    const hasValidationErrors = Object.values(errorMessages ?? {}).some(
      (value) => value !== null
    );

    // Check if the form is in edit mode and has validation errors
    if (isEditMode && hasValidationErrors) {
      for (const key in errorMessages) {
        console.log(key, "keys");

        if (errorMessages.hasOwnProperty(key) && errorMessages[key] !== null) {
          simpleValidator.current.showMessageFor(key);
        }
      }

      toast.error("Enter all required fields", {
        position: "top-right",
      });
      setPageLoader(false);
      console.log("Validation errors detected");
    } else {
      createProduct(formData)
        .then((res) => {
          console.log(res, 'ressssss');

          getProductList();
          getAllProductDetails(res.product_id)
          // getProductDetails(res.product_id);
          // getProductMeasurement();
          console.log(res.sta, "ressssss");
          handleCarddetails(res);
          setProductListstatus(true);
          setViewproduct?.(true);
          setEditButton?.(false);
          setEditMode?.(!isEditMode);

          setPageLoader(false);
          toast.success("Updated Inventory", {
            position: "top-right",
          });
        })
        .catch((err) => {
          console.log("Error Object", err);
          console.log(err, "response addProduct error");

          // Check if there is an error message in the response
          if (
            err.response &&
            err.response.data &&
            err.response.data.errorMessage
          ) {
            toast.error(err.response.data.errorMessage, {
              position: "top-right",
            });
          } else {
            // If no specific error message, show a generic error message
            toast.error("An error occurred while updating inventory", {
              position: "top-right",
            });
          }
          setPageLoader(false);
        });
    }
  };

  const forceUpdate = () => {
    return setValidatorValue((value) => value + 1);
  };
  console.log("selectedMeasurementUnit",selectedMeasurementUnit)
  return (
    <>
      {pageLoader ? (
        <div className="loader-block">
          <img className="img-fluid" src={mainLoader} alt="" />
        </div>
      ) : (
        <div className="content-window-right">
          <div className="content-right-block">
            <div className="card-right">
              <div className="header-block">
                <div className="header-sec">
                  <TitleWrapper className="content-right-top">
                    <div className="back-btn-title">
                      <button
                        onClick={closeView}
                        className="btn btn-action btn-back"
                        rel="tooltip"
                        data-for="backAction"
                        data-tip="Close"
                        data-toggle="tooltip"
                        data-placement="top"
                      >
                        <i className="fal fa-long-arrow-left"></i>
                      </button>
                      <ProductTitle>
                        <div style={{ marginRight: "80px" }}>
                          {viewProduct ? "View" : "Add Inventory"}
                        </div>
                      </ProductTitle>
                    </div>
                    {viewProduct ? (
                      <div style={{ display: "flex" }}>
                        {isEditMode ? (
                          <SaveButton onClick={SavebuttonClick} />
                        ) : (
                          <React.Fragment>
                            <MuiUpdateStockModal
                              item={{
                                id: 1,
                                name: "feynaaa",
                                brand: "sjbcjsbcjbsc",
                                stock: "scscscscs",
                                price: 0,
                                tag: "cscscscscs",
                                config: undefined,
                                currentStock: 10,
                              }}
                            />
                            <button
                              className="btn btn-action btn-edit"
                              rel="tooltip"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Edit"
                              onClick={onEditClick}
                            >
                              <i className="fal fa-pen"> </i>
                            </button>
                          </React.Fragment>
                        )}
                      </div>
                    ) : (
                      <SaveButton onClick={onSaveClick} />
                    )}
                  </TitleWrapper>
                </div>
                <Scrollbars
                  className="ScrollbarsOverflow"
                  style={{ height: `calc(100vh - 167px)` }}
                >
                  <div className="name-block">
                    <div
                      className="sub-block"
                      style={{
                        padding: "15px",
                        alignItems: "center",
                        width: "100%",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        className="block-name"
                        style={{
                          width: "96%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          position: "fixed",
                          background: "#fff",
                          zIndex: 2,
                          marginTop: "20px",
                          left: "0%",
                        }}
                      >
                        {/* Content inside the block-name */}
                      </div>

                      <ProductForm
                        datastored={datastored}
                        viewProduct={viewProduct}
                        editButton={editButton}
                        addProduct={addProduct}
                        SetMappedProductData={SetMappedProductData}
                        addProductDetails={addProductDetails}
                        SetAddProductDetails={SetAddProductDetails}
                        values={values}
                        setFormSubmitted={setFormSubmitted}
                        mappedProductData={mappedProductData}
                        validator={simpleValidator.current}
                        validatorValue={validatorValue}
                        stockunit={stockunit}
                        setMeasurementUnit={setMeasurementUnit}
                        measurementUnit={measurementUnit}
                        setSelectedMeasurementUnit={setSelectedMeasurementUnit}
                        selectedMeasurementUnit={selectedMeasurementUnit}         
                      />
                    </div>
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WindowRight;

const ProductTitle = styled.h4`
  white-space: nowrap;
  width: 169px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
`;
const RejectReasonTooltip = styled.div`
  position: absolute;
  background-color: #fff;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;

  &:hover {
    visibility: visible;
    opacity: 1;
  }
`;
const CustomDialogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 27%;
  span {
    text-align: center;
    padding-bottom: 24px;
    font-weight: bold;
  }
  .reject-ok-buttons {
    display: flex;
    padding-top: 8%;
  }
  .content-top-edit {
    display: flex;
    justify-content: space-between;
  }
`;
const CancelButton = styled(Button)`
  &.MuiButtonBase-root {
    background: #ccc;
    color: #333;
    align-items: center;
    width: 100px;
    margin-right: 10px;
  }
  &:hover {
    background: #aaa !important;
  }
`;

const OkButton = styled(Button)`
  &.MuiButtonBase-root {
    background: #f67979;
    color: white;
    align-items: center;
    width: 100px;
    margin-left: 30px;
  }

  &:hover {
    background: #d9534f !important;
  }
`;
const CustomStyledDialog = styled(Dialog)`
  &.customDialog {
    .MuiDialog-container {
      .MuiPaper-root {
        height: 300px;
        width: 400px;
      }
    }
  }
`;
const DialogWrapper = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  padding-top: 18%;
  span {
    text-align: center;
    padding-bottom: 96px;
    font-weight: bold;
  }
`;
const VeriftyButton = styled(Button)`
  &.MuiDialogContent-root {
    .MuiTypography-root {
      margin-top: 70px;
    }
  }
  &.verifyButton {
    background: rgb(1, 87, 155);
    color: white;
    align-items: center;
    border: 1px solid rgb(1, 87, 155) !important;
    text-transform: uppercase !important;
    letter-spacing: 1px !important;
    min-width: 150px !important;

    border-radius: 5px !important;
    box-shadow: none !important;
    padding: 10px 16px !important;
    height: 40px !important;
  }
  &:hover {
    background: rgb(25 118 210) !important;
  }
`;
const VeriftyOkButton = styled(Button)`
  &.okbutton {
    background: rgb(41 59 199);
    color: white;
    align-items: center;
    width: 100px;
    margin-left: 30px;
  }
  &:hover {
    background: rgb(41 59 199) !important;
  }
`;

const SubmitBtnWrapper = styled.div`
  width: 98%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0%;
  right: 0%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  background: white;
  position: fixed;
  bottom: 0px;
  z-index: 2;

  /* box-shadow: 0px 1px 15px 1px #f5f5f5; */
  .reject-save-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0 0px 0px;
    /* left: 0%; */
    .reject {
      margin: 0 20px 0 0;
    }
  }
  .end-block {
    padding: 10px 0px 0px 0px;
    button {
    }
  }
`;

const Wrapper = styled.div`
  padding: 2px 2px;
  width: fit-content;
  max-width: 100%;
  &.button-wrapper {
    .MuiButtonBase-root {
      font-size: 12px;
      font-weight: 600;
      max-height: 40px;
      color: #f67979 !important;
      border: 1px solid #f67979 !important;
      text-transform: uppercase !important;
      letter-spacing: 1px !important;
      background: transparent !important;
      min-width: 150px !important;
      max-width: 180px !important;
      border-radius: 5px !important;
      box-shadow: none !important;
      padding: 10px 16px !important;
      line-height: 1;
      align-items: center;
      -webkit-transition: border-radius 0.25s;
      transition: border-radius 0.25s;
      justify-content: center;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      height: 40px !important;
    }
    &.reject {
      .MuiButtonBase-root {
        background: transparent !important;
        color: #f67979 !important;
        border: 1px solid #f67979 !important;
        &:hover {
          background: #f67979 !important;
          color: #ffffff !important;
        }
      }
    }
    &.save {
      .MuiButtonBase-root {
        background: transparent !important;
        color: #01579b !important;
        border: 1px solid #01579b !important;
        &:hover {
          background: #01579b !important;
          color: #ffffff !important;
        }
      }
    }
  }
`;

const StyledButton = styled(Button).attrs({
  className: "grandDisablebtn",
})`
  margin-top: 0px !important;
  width: 100%;
  max-width: 200px !important;
  color: #ffffff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-family: "Outfit Regular" !important;
  border: none !important;
  border-radius: 6px !important;
  background: #01579b !important;

  &.Mui-disabled {
    background-color: #80abcd !important;
  }

  max-width: 180px !important;
  color: rgb(255, 255, 255) !important;
  font-size: 12px !important;
  border: none !important;
  padding: 10px 10px !important;
  border-radius: 6px !important;
  text-transform: uppercase !important;
  background: rgb(1, 87, 155) !important;
`;

const StyledStepper = styled(Stepper)`
  .MuiStep-root {
    .MuiStepLabel-root {
      .MuiStepLabel-labelContainer {
        .MuiStepLabel-label {
          text-transform: uppercase;
          &.Mui-active {
            color: #01579b;
          }
          &.Mui-completed {
            color: #000000 !important ;
          }
          &.Mui-disabled {
            color: #000000 !important;
          }
        }
      }
      .MuiStepLabel-iconContainer {
        &.Mui-disabled {
          svg {
            border: 1px solid black !important;
            border-radius: 50% !important;
            color: transparent !important;
            circle {
              fill: white;
            }
            .MuiStepIcon-text {
              fill: #000000 !important;
            }
          }
        }
      }
    }
    &.Mui-completed {
      .MuiStepLabel-iconContainer {
        position: relative;
        svg {
          fill: white;
          border: 1px solid black;
          border-radius: 50%;
          color: black;
        }
        &.MuiStepLabel-iconContainer::before {
          content: "1";
          position: absolute;
          left: 8px;
          font-size: 13px;
          top: 3px;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
        }
      }
    }
  }
  .MuiStepConnector-root {
    &.Mui-completed,
    &.Mui-active {
      .MuiStepConnector-line {
        border-color: #00b383;
      }
    }
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .back-btn-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;
