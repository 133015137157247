import React from "react";
import VendorLayout from "../../../components/layout/vendor";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import styled from "styled-components";
import RegisterServiceCenter from "./components/registration";
import DocumentationServiceCenter from "./components/documentation"
// import RegisterVendor from "../../VendorAdmin/registration/components/registration";
// import VendorDocumentation from "./components/documentation";
// import VendorRegisterDashboard from "./components/dashboard";

const steps = ["Registration", "Documentation"];

type Props = {};
type CredentialData = {
  phone: undefined | string;
  email: string;
};
type countryValue = {
  // id: undefined,
  name: string;
};
const SapRegistration = (props: Props) => {
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const [credentialsData, setCredentialsData] = React.useState<CredentialData>({
    phone: undefined,
    email: "",
  });
  const [countryValue, setCountryValue] = React.useState<countryValue>({
    // id: undefined,
    name: "",
  });

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <VendorLayout>
      <div className="content-block">
        <Wrapper className="Wrapper">
          {activeStep !== 2 && (
            <TitleHeaderWrapper className="TitleHeaderWrapper">
              <TitleHeader>Register as Service Center</TitleHeader>
            </TitleHeaderWrapper>
          )}
          <SubTitleWrapper className="SubTitleWrapper">
            {activeStep !== 2 && (
              <SubTitleHeader>
                Thankyou for your interest in joining our platform!
              </SubTitleHeader>
            )}
            {activeStep !== 2 && (
              <SubTitleHeader className="light-header">
                Complete the next few steps to get yourself onboarded..
              </SubTitleHeader>
            )}
          </SubTitleWrapper>
          {!(activeStep > 1) && (
            <Box
              className="StyledStepper-Wrapper"
              sx={{
                width: "80%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <StyledStepper
                sx={{ width: "100%", minWidth: "50%", maxWidth: "50%" }}
                className="StyledStepper"
                activeStep={activeStep}
              >
                {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = {};
                  const labelProps: {
                    optional?: React.ReactNode;
                  } = {};

                  return (
                    <Step key={label} {...stepProps}>
                      <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                  );
                })}
              </StyledStepper>
            </Box>
          )}

          {activeStep === 0 && (
            <ContentWrapper className="ContentWrapper">
              <RegisterServiceCenter
                credentialsData={credentialsData}
                setCredentialsData={setCredentialsData}
                countryValue={countryValue}
                setCountryValue={setCountryValue}
                onNextClick={handleNext}
              />
            </ContentWrapper>
          )}
          {activeStep === 1 && (
            <ContentWrapper className="ContentWrapper">
              <DocumentationServiceCenter
                email={credentialsData.email}
                phone={credentialsData.phone}
                countryValue={countryValue.name}
                onNextClick={handleNext}
              />
            </ContentWrapper>
          )}
          {activeStep === 2 // && 
        //   <VendorRegisterDashboard />
          }
        </Wrapper>
      </div>
    </VendorLayout>
  );
};

export default SapRegistration;
const Wrapper = styled.div`
  padding: 15px 15px 0 15px;
`;
const ContentWrapper = styled.div`
  padding: 15px 15px 15px 15px;
`;
const TitleHeaderWrapper = styled.div`
  text-align: start;
  margin: 0 0 30px 0;
`;
const TitleHeader = styled.h4`
  text-align: start;
`;
const SubTitleWrapper = styled.div`
  text-align: start;
  margin: 0 0 30px 0;
`;
const SubTitleHeader = styled.h6`
  text-align: start;
  &.light-header {
    color: #a09d9d !important ;
  }
`;
const StyledStepper = styled(Stepper)`
  .MuiStep-root {
    .MuiStepLabel-root {
      .MuiStepLabel-labelContainer {
        .MuiStepLabel-label {
          text-transform: uppercase;
          &.Mui-active {
            color: #01579b;
          }
          &.Mui-completed {
            color: #00b383;
          }
          &.Mui-disabled {
            color: #000000 !important;
          }
        }
      }
      .MuiStepLabel-iconContainer {
        &.Mui-disabled {
          svg {
            border: 1px solid black !important;
            border-radius: 50% !important;
            color: transparent !important;
            circle {
              fill: white;
            }
            .MuiStepIcon-text {
              fill: #000000 !important;
            }
          }
        }
      }
    }
    &.Mui-completed {
      .MuiStepLabel-iconContainer {
        svg {
          color: #00b383;
        }
      }
    }
  }
  .MuiStepConnector-root {
    &.Mui-completed,
    &.Mui-active {
      .MuiStepConnector-line {
        border-color: #00b383;
      }
    }
  }
`;
