import React from "react";
import SimpleReactValidator from "simple-react-validator";
import styled from "styled-components";
import { TextField } from "@mui/material";

export type MTextFieldProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // to handle onClick functions
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>
  color?: "primary" | "secondary"; // two styling options (you can create as many as you want)
  border?: "primary" | "secondary";
  disabled?: boolean; // make the button disabled or not
  label?: String;
  placeholder?: string;
  name?: string;
  error?: string | null;
  defaultValue?: string;
  value?: string;
  type?: "password" | "text" | "number";
  autoComplete?: "off" | "";
  validator?: SimpleReactValidator;
  validations?: string;
  className?: string;
  isRequired?: boolean;
};
const MuiStyledTextArea = ({
  onChange,
  onKeyDown,
  color,
  border,
  label,
  placeholder,
  name,
  type,
  disabled,
  defaultValue,
  value,
  error,
  autoComplete,
  validator,
  validations,
  className,
  isRequired,
}: MTextFieldProps) => {
  const simpleValidator = React.useRef(validator);
  const handleBlur = () => {
    simpleValidator?.current?.showMessageFor(error ? error : "field");
  };
  React.useEffect(() => {
    // handleBlur?.();
    console.log(value, "textvalue");
  }, [value]);
  return (
    <Wrapper className="textfield-wrapper">
      <FieldLabel>
        {`${label ? label : ""} `}
        <div className="red-star">{validations ? "*" : ""}</div>
      </FieldLabel>
      <TextField
        autoComplete={autoComplete}
        className={className}
        name={name}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        type={"text"}
        disabled={disabled}
        multiline
        minRows={3}
        maxRows={4}
        onChange={onChange}
        onKeyDown={onKeyDown}
        inputProps={{
          autoComplete: "new-password",
          form: {
            autoComplete: "off",
          },
          inputMode: "text",
        }}
        InputProps={{}}
      />
      <Errortext className="error-text">
        {simpleValidator?.current?.message(
          error ? error : "field",
          value,
          validations ?? ""
        )}
      </Errortext>
    </Wrapper>
  );
};

export default MuiStyledTextArea;
const Wrapper = styled.div`
  &.textfield-wrapper {
    padding: 5px 0px;
    width: 100%;
    min-width: 250px;
    max-width: 400px;
    border-radius: 0px !important;
    .MuiTextField-root {
      width: 100%;
      /* width: 100%;
      padding: 0px 15px 0px 15px; */
      .MuiFormLabel-root {
        /* top: -4px !important;
        color: #000000 !important;
        &.Mui-focused {
          color: #000000 !important;
        } */
      }
      .MuiInputBase-root {
        border-radius: 5px !important;
        /* height: 100%;
        max-height: 48px !important;
        border-radius: 10px !important;
        padding: 0 15px !important;
        background: #ffffff;
        font-size: 12px !important;
        border: 1px solid #cacaca; */
        .MuiOutlinedInput-notchedOutline {
          border: 1px solid #cacaca !important;
          /* border: none !important;
          box-shadow: none !important; */
        }
        &.Mui-disabled {
          cursor: pointer !important;
          .MuiInputBase-input {
            &.Mui-disabled {
              /* -webkit-text-fill-color: #8f8f8f !important; */
            }
          }
        }
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            /* border: none !important;
            box-shadow: none !important; */
          }
        }
      }
      .MuiInputBase-input {
        /* box-shadow: none !important;
        background: transparent !important;
        color: #000000 !important; */
        ::placeholder {
          /* Firefox */
          color: #a09d9d;
          opacity: 1;
        }
        @media (min-width: 1366px) and (max-width: 4000px) {
          font-size: 14px;
        }
        @media (min-width: 1200px) and (max-width: 1365px) {
          font-size: 13px;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 13px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          font-size: 12px;
        }
        @media (min-width: 576px) and (max-width: 767px) {
          font-size: 12px;
        }
        @media (min-width: 320px) and (max-width: 575px) {
          font-size: 11px;
        }
      }
      .MuiInputBase-input.MuiOutlinedInput-input:-webkit-autofill {
        box-shadow: inset 0 0 100px 100px #4e4e4e !important;
        font-size: 15px !important;
        border-radius: 0px !important;
        -webkit-text-fill-color: #fff !important;
      }
      //sup styles
      &.sup {
        .MuiInputBase-root {
          border-color: #ffffff;
          /* height: 100%;
          max-height: 48px !important;
          border-radius: 10px !important;
          padding: 0 15px !important;
          background: #ffffff;
           */
          font-size: 12px !important;
          .MuiOutlinedInput-notchedOutline {
            /* border: none !important;
            box-shadow: none !important; */
          }
          &.Mui-disabled {
            /* cursor: pointer !important; */
            .MuiInputBase-input {
              &.Mui-disabled {
                font-size: 14px !important;
                font-weight: 500;
                color: black !important;
                -webkit-text-fill-color: rgb(0 0 0) !important;
                /* -webkit-text-fill-color: #8f8f8f !important; */
              }
            }
          }
        }
      }
    }
  }
`;
const FieldLabel = styled.div`
  display: flex;
  text-align: left;
  margin: 0 0 10px 0;
  color: #a09d9d;
  font-size: 14px;
  .red-star {
    color: red;
    font-weight: bold;
  }
`;
const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Errortext = styled.span`
  display: block;
  font-size: 12px;
  color: #e91344;
  padding-top: 4px !important;
  width: 280px;
`;
