import React, { Fragment, useContext, useEffect } from 'react'
import { OrdersContext } from "./OrdersContext";
import OrderWindowLeft from "./OrderWindowLeft";
import OrderWindowRight from "./OrderWindowRight";
import mainLoader from "../../../../assets/img/loader/loader.gif";

const WindowOrders = (props) => {
    const {
        viewInnerOrder,
        setViewInnerOrder,
        loader,
        setLoader } =
        useContext(OrdersContext);

    return (
        <Fragment>
            {viewInnerOrder && loader ?
                <OrderWindowRight
                    addOrders={props.addOrders}
                    handleAddOrders={props.handleAddOrders}
                />
                :
                <OrderWindowLeft
                    handleAddOrders={props.handleAddOrders}
                    OrderformClose={props.SetAddOrders}
                />
            }
        </Fragment>
    )
}

export default WindowOrders

