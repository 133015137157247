import React, { Fragment, useContext, useEffect } from 'react'
import { CustomerContext } from "./CustomerContext";
import CustomerWindowLeft from "./CustomerWindowLeft";
import CustomerWindowRight from "./CustomerWindowRight";
import mainLoader from "../../../../assets/img/loader/loader.gif";

const WindowCustomer = (props) => {
    const {
        viewInnerOrder,
        setViewInnerOrder,
        loader,
        setLoader } =
        useContext(CustomerContext);

    return (
        <Fragment>
            {viewInnerOrder && loader ?
                <CustomerWindowRight
                    addOrders={props.addOrders}
                    handleAddOrders={props.handleAddOrders}
                />
                :
                <CustomerWindowLeft
                    handleAddOrders={props.handleAddOrders}
                    OrderformClose={props.SetAddOrders}
                />
            }
        </Fragment>
    )
}

export default WindowCustomer

