import React from "react";
import styled from "styled-components";

export const CloseSvgIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M12.7944 8.00195L8.00244 12.794"
        stroke="#F67979"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        d="M12.796 12.797L8 8"
        stroke="#F67979"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.584 1H5.915C2.894 1 1 3.139 1 6.166V14.334C1 17.361 2.885 19.5 5.915 19.5H14.583C17.614 19.5 19.5 17.361 19.5 14.334V6.166C19.5 3.139 17.614 1 14.584 1Z"
        stroke="#F67979"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></path>
    </svg>
  );
};
export const EnterRightArrowIcon = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
    >
      <rect
        x="0.5"
        y="0.5"
        width="18"
        height="18.0003"
        rx="4.5"
        fill="#01579B"
        stroke="#01579B"
      />
      <path
        d="M12.8883 9.51172H5.83643"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.0442 6.33212L12.8884 9.5115L10.0442 12.6914"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
export const EditButtonIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.6653 0.0103353C16.1038 -0.0795717 17.5224 0.419911 18.5913 1.3989C19.5703 2.46779 20.0697 3.88633 19.9898 5.33483V14.6652C20.0797 16.1137 19.5703 17.5322 18.6013 18.6011C17.5323 19.5801 16.1038 20.0796 14.6653 19.9897H5.33487C3.88636 20.0796 2.46781 19.5801 1.39891 18.6011C0.419915 17.5322 -0.0795724 16.1137 0.0103353 14.6652V5.33483C-0.0795724 3.88633 0.419915 2.46779 1.39891 1.3989C2.46781 0.419911 3.88636 -0.0795717 5.33487 0.0103353H14.6653ZM14.4056 4.57561C13.7862 3.95625 12.7872 3.95625 12.1679 4.57561L11.4985 5.25491C11.3986 5.35481 11.3986 5.52463 11.4985 5.62453C11.4985 5.62453 11.5182 5.64401 11.5531 5.67871L11.7988 5.92309C11.9409 6.0645 12.1183 6.24111 12.2965 6.4187L12.9006 7.0225C13.0266 7.14912 13.1102 7.23397 13.1169 7.24285C13.2268 7.36273 13.2967 7.52256 13.2967 7.70238C13.2967 8.06201 13.007 8.3617 12.6374 8.3617C12.4675 8.3617 12.3077 8.29177 12.1978 8.18188L10.5295 6.5236C10.4496 6.44368 10.3098 6.44368 10.2298 6.5236L5.46474 11.2887C5.13507 11.6183 4.94527 12.0579 4.93528 12.5274L4.87534 14.8949C4.87534 15.0248 4.9153 15.1447 5.00521 15.2346C5.09512 15.3245 5.21499 15.3744 5.34486 15.3744H7.69245C8.17196 15.3744 8.63149 15.1846 8.98113 14.845L15.7042 8.10196C16.3136 7.4826 16.3136 6.48364 15.7042 5.87427L14.4056 4.57561Z"
        fill="#01579B"
      />
    </svg>
  );
};


export const InfoRejectButtonIcon = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.334 0.75H5.665C2.644 0.75 0.75 2.889 0.75 5.916V14.084C0.75 17.111 2.635 19.25 5.665 19.25H14.333C17.364 19.25 19.25 17.111 19.25 14.084V5.916C19.25 2.889 17.364 0.75 14.334 0.75Z"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.99463 14V10"
        stroke="black"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.98999 6.20508H9.99999"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const StyledEditButtonIcon = styled.svg`

  position: relative;
  right: 30px;
`;
