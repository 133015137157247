import React, { useContext } from "react";
import { SuperContext } from "../SuperContext";
import "../../../Pages/User/Ui/css/Modal.css"
import { toast } from "react-toastify";
import deleteFile from "../../../../../assets/img/icon/upload/recycle-bin.svg";

function Modal(props) {
  const {
    table,
    handleServiceDelete,
  } = useContext(SuperContext);

  const deleteConfirm = (e) => {
    toast.error("Deleted Successfully" , {
      className:"error-toast",
      position:"bottom-right"
    })
    const obj = table.filter((p) => p.id == props.id);
    handleServiceDelete(obj[0].id);
    if(props.SetIsDeleted){
      props.SetIsDeleted(true);
    }
  };
  return (
    <div className="action-modal">
        <div className="d-flex justify-content-end">
        <button className="btn close-btn" onClick={() => props.SetDeleteModal(false)}><i className="fal fa-times"></i></button>
        </div>
        <div className="modal-info-img">
 <img src={deleteFile} className="delete-file" alt="" />
</div>
  <h5>Are you sure you want to delete?</h5>
      <div className="d-flex justify-content-center pt-3">
        <button  className="btn mr-3 btn-cancel" onClick={() => props.SetDeleteModal(false)}>Cancel</button>
        <button className="btn btn-danger" onClick={deleteConfirm}>Confirm<i class="fal fa-angle-right ml-2" aria-hidden="true"></i></button>
      </div>
    </div>
  );
}

export default Modal;
