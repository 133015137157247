import React, { useState } from "react";
import BookingsFilterDropdownContent from "./BookingsFilterDropdownContent";
const FilterDropdown = () => {
  const [filter, SetFilter] = useState(false);

  return (
    <div className="filter-dropdown">
      <div className="dropdown filter-custom-dropdown">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={() => SetFilter(!filter)}
        >
          <span className="filter-btn">
            Filter<i className="fal fa-filter"></i>
          </span>
        </button>
        {filter && <BookingsFilterDropdownContent />}
      </div>
    </div>
  );
};

export default FilterDropdown;
