import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { EditButton } from "../EditButton";
import { PurchaseButton } from "../PurchaseButton";
import images from "./assets/img/CERAMIC-ULTRA.png";
import styled from "styled-components";
import { ProductListModel } from "../../api/services/sap/vap/models/productListModel";
import configData from "../../config.json";


type Props = {
 
  item?: ProductListModel;
};


export default function MuiProductCard({item}:Props) {
  console.log(item,"itemzzzzzzz")
  return (
    // <div>
    <StyledCard
      className="custom-product-card"
      sx={{
        maxWidth: 214,
        marginLeft: 1,
        position: "relative",
        minWidth:214
      }}
    >
      <CardMedia
         className={`custom-image-card ${!item?.thumbnail && "static-image"}`}
        component="img"
        image={
          item?.thumbnail 
            ? `${configData.SERVER_URL}/${item.thumbnail}`
            : "/static/media/photos.012563c49cb4503fc4028085b7283e02.svg"
        }
        alt="thumbnail"
        
      />
      <CardContent className="custom-card-content">
        {/* {item?.tag === "newly added" ? (
          <div className="custom-new-item-tag">{item?.tag}</div>
        ) : item?.tag === "" ? (
          <div className="custom-empty-item-tag">{item?.tag}</div>
        ) : (
          <div className="custom-left-item-tag">{'item?.tag'}</div>
        )} */}

        {item?.stock && item?.stock >= 1 && item?.stock <= 5 ? (
          <div className="custom-left-item-tag">only 5 left</div>
        ) : (
          ""
        )}

        {<Typography gutterBottom variant="h5" component="div"></Typography>}
        <Typography
          className="custom-card-item-name"
          variant="body2"
          color="text.secondary"
        >
          {item?.product_name}
        </Typography>
        <div className="custom-brand-name">
          <span className="brand-text">{item?.brand?.brand_name}</span>
          {item?.id === 1 ? (
            <div className="custom-purchase-from">Own Stock</div>
          ) : (
            <div className="custom-purchase-from">Via e-commerce</div>
          )}
        </div>
        <div className="custom-price-tag">
          $ {item?.price_per_unit}
          {item && item.stock === 0 && (
            <div className="custom-outofstock">Out of stock</div>
          )}
        </div>
      </CardContent>
      <CardActions>
        <EditButton />
        <PurchaseButton />
      </CardActions>
    </StyledCard>
    // </div>
  );
}

const StyledCard = styled(Card)`
  .custom-product-card {
    position: relative !important;
  }
  .custom-price-tag {
    display: flex;
    color: rgb(78, 167, 61, 87%);
    text-align: left;
    font-size: 13px;
    font-weight: 700;
    justify-content: space-between;
  }

  .custom-card-item-name {
    text-align: left;
    font-weight: 700;
  }

  .custom-image-card {
    border: 2px solid #ccc;
    margin: 10px;
    border: 2px dotted #ccc;
    box-sizing: border-box;
    width: 195px;
  }
  .static-image {
    
    
  }
  .custom-purchase-from {
    color: rgb(255, 160, 73);
    font-size: 11px;
  }

  .custom-brand-name {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .brand-text {
    color: #6d6666;
    margin-right: 8px;
    font-size: 11px;
  }
  .custom-new-item-tag {
    background-color: #58c558;
    color: white;
    width: 38% !important;
    font-size: 10px !important;
    height: 18px;
    position: absolute !important;
    top: 20px;
    left: 0;
  }
  .custom-left-item-tag {
    background-color: #f80b0b;
    color: white;
    width: 38% !important;
    font-size: 10px !important;
    height: 18px;
    position: absolute !important;
    top: 20px;
    left: 0;
  }
  .custom-card-content {
    padding: 0px 16px 0px 16px;
  }
  .custom-price-tag {
    margin-top: 10px;
  }
  .custom-outofstock {
    color: red;
    text-align: left;
    font-size: 13px;
    font-weight: 700;
  }
`;
