import React, { useState, useContext, useEffect, Fragment } from "react";
import RejectModal from "./Ui/RejectModal";
import { VariantContext } from "./VariantContext";
import ReactTooltip from "react-tooltip-rc";
import "./Ui/css/variant-content.css"
import RejectVariantModal from "./Ui/RejectVariantModal";
import { approveOrRejectVariant } from "../../../../api/services/sup/variant-management";
import { toast } from "react-toastify";

function VariantRow(props) {
  const [openEditForm, setOpenEditForm] = useState(false);
  const [variant, setVariant] = useState({});
  const [deletemodal, SetDeleteModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [isRejected, SetIsRejected ] = useState(false);

  const {
    variantApproved,
    setVariantApproved
  } = useContext(VariantContext);

  //Popup delete
  const handleDelete = (e) => {
    SetDeleteModal(true);
    e.stopPropagation();
  };

   

  const openRejectModal=(e)=>{
    setRejectModal(true);
    e.stopPropagation();
  }

  const approveVariant = (e) => {
    e.preventDefault();
    const req = approveOrRejectVariant({
      type: "approve",
      variant_id_fk: props.id,
    });
    req.then((res) => {
      reloadPageDetails();
      let variants = [...variantApproved, res ]
      setVariantApproved(variants);
      toast.success(res.message, {
        position: "bottom-right",
      });
    }).catch((e) => {
      toast.error('Error', {
        position: "bottom-right",
      });
    });
  }
 
  
  const reloadPageDetails=()=>{
    props.fetchVehicleVariants();
  }

  return (
    <Fragment>
      <tr>
        <td>{props.name}</td>
        <td>{props.vehicleType}</td>
        <td>Service Center Name</td>
        <td>
          {props.status == 0 ? (
            <div className="action-block-right d-flex align-items-center justify-content-center">
              <button type="button" className="btn btn-success mr-1"  onClick={(e)=>{approveVariant(e)}}
              >Approve</button>
              <button type="button" className="btn btn-danger" onClick={openRejectModal} >Reject</button>
            </div>
          ) : (
            <div className="reject_variant">
              <p className="text-danger text-center" style={{fontWeight:`600`}} data-for="rejected" data-tip={props.reason}>Rejected</p>
              <ReactTooltip id="rejected" place="right" type="info" effect="float" className="tooltip"/>
            </div>
          ) }
        </td>
      </tr>
      {rejectModal && <RejectVariantModal  variant={props}  setRejectModal={setRejectModal} reloadPageDetails={reloadPageDetails} />}
    </Fragment>
  );
}

export default VariantRow;
