import React from "react";
import mainLoader from "../../../assets/img/loader/loader.gif";
const Loader = () => {
  return (
    <div className="main-loader">
      <div className="main-loader-block">
        <img className="img-fluid" src={mainLoader} />
      </div>
    </div>
  );
};

export default Loader;
