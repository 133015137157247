import React, { useState } from "react";
import Layout from "../../Layout/Layout";
import ContentHeader from "../../Layout/Ui/ContentHeader";

import styled from "styled-components";

import SapFeatureForm from "./components/SapFeatureForm";
import ContentTitleWithButton from "../../../SuperAdmin/Pages/components/Layouts/ContentTitleWithButton";
import WindowRightLayout from "../../../SuperAdmin/Pages/components/Layouts/WindowRightLayout";
import FeatureListCard from "./components/FeatureListCard";

export interface FeatureFormData {
  title: string;
  image: {};
  textPosition?: number;
  imgPosition?: number;
}
export interface FeatureResponseData {
  title: string;
  image: string;
  textPosition?: number;
  imgPosition?: number;
}
type Props = {};
const dummyFeatures: FeatureResponseData[] = [
  {
    title: "Feature 1",
    image: "https://via.placeholder.com/150",
    textPosition: 1,
    imgPosition: 1,
  },
  {
    title: "Feature 2",
    image: "https://via.placeholder.com/150",
    textPosition: 1,
    imgPosition: 1,
  },
  {
    title: "Feature 3",
    image: "https://via.placeholder.com/150",
    textPosition: 1,
    imgPosition: 1,
  },
  {
    title: "Feature 4",
    image: "https://via.placeholder.com/150",
    textPosition: 1,
    imgPosition: 1,
  },
  {
    title: "Feature 5",
    image: "https://via.placeholder.com/150",
    textPosition: 1,
    imgPosition: 1,
  },
  {
    title: "Feature 6",
    image: "https://via.placeholder.com/150",
    textPosition: 1,
    imgPosition: 1,
  },
  {
    title: "Feature 7",
    image: "https://via.placeholder.com/150",
    textPosition: 1,
    imgPosition: 1,
  },
  {
    title: "Feature 8",
    image: "https://via.placeholder.com/150",
    textPosition: 1,
    imgPosition: 1,
  },
  {
    title: "Feature 9",
    image: "https://via.placeholder.com/150",
    textPosition: 1,
    imgPosition: 1,
  },
  {
    title: "Feature 10",
    image: "https://via.placeholder.com/150",
    textPosition: 1,
    imgPosition: 1,
  },
  {
    title: "Feature 11",
    image: "https://via.placeholder.com/150",
    textPosition: 1,
    imgPosition: 1,
  },
];
const initialFormData = { title: "", image: {} };

export const SapFeaturePage = (props: Props) => {
  const [formData, setFormData] = useState<FeatureFormData>(initialFormData);
  const [prevFormData, setPrevFormData] = useState<FeatureFormData>();
  const [rightView, setRightView] = useState(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [isAddNew, setIsAddNew] = React.useState<boolean>(false);
  const handleAddFeature = () => {
    setFormData(initialFormData);
    setIsAddNew(true);
    setRightView(true);
    setIsEdit(true);
  };

  const handleViewClick = (e: any, data: any) => {
    setPrevFormData(data);
    setFormData(data);
    setIsAddNew(false);
    setRightView(true);
    setIsEdit(false);
  };
  const onEditClick = () => {
    setIsEdit(true);
    setIsAddNew(false);
  };

  const handleCancelClick = () => {
    if (isAddNew) {
      setFormData(initialFormData);
      setRightView(false);
      setIsAddNew(false);
    } else {
      setIsEdit(false);
      setFormData(prevFormData!);
    }
  };
  const handleBackClick = () => {
    setIsAddNew(false);
    setIsEdit(false);
    setRightView(false);
    setFormData(initialFormData);
  };
  return (
    <>
      <Layout>
        <Wrapper className="content-block">
          <section className="main-section">
            <div className="container-fluid p-0">
              <ContentHeader />
            </div>
            <div className="col-12 p-0">
              <ContentTitleWithButton
                handleAddClick={handleAddFeature}
                buttonTitle={"Add New Feature"}
                heading={"Featured"}
              />
              <div className="content-window">
                <LeftWindowWrapper
                  className={`${rightView ? "right-window-active" : ""}`}
                >
                  <FeatureListCard
                    features={dummyFeatures}
                    onViewClick={handleViewClick}
                    className="whole-card-wrapper"
                  />
                </LeftWindowWrapper>
                {rightView && (
                  <WindowRightLayout
                    isExpand={rightView}
                    heading={
                      isAddNew
                        ? "Create Featured Item"
                        : isEdit
                        ? "Edit Featured Item"
                        : "View Featured Item"
                    }
                    backToolTip="Back to Items"
                    handleCloseView={handleBackClick}
                    childrenRight={
                      isEdit ? (
                        <></>
                      ) : (
                        <button
                          onClick={onEditClick}
                          className="btn btn-action btn-edit"
                          style={{
                            color: "#2e7d32",
                            background: "#f1ffe0",
                            border: "1px solid #f1ffe0",
                          }}
                          rel="tooltip"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Edit"
                          aria-describedby="tooltip55424"
                        >
                          <i className="fal fa-pen"> </i>
                        </button>
                      )
                    }
                  >
                    <SapFeatureForm
                      isEdit={isEdit}
                      formData={formData}
                      setFormData={setFormData}
                      handleCancelClick={handleCancelClick}
                    />
                  </WindowRightLayout>
                )}
              </div>
            </div>
          </section>
        </Wrapper>
      </Layout>
    </>
  );
};

export default SapFeaturePage;
const LeftWindowWrapper = styled.div`
  width: 100%;
  overflow: auto;
  height: calc(100vh - 105px);
`;
const Wrapper = styled.div`
  .content-window {
    display: flex;
    gap: 15px;
  }
  /* .row-img {
    display: none;
  } */
  .right-window-active {
    width: unset !important;
    .whole-card-wrapper {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
