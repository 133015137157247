import SuperUserRow from "./SuperUserRow";
import React, { useState, useContext, Fragment } from "react";
import { SuperContext } from "./SuperContext";
import noDataImage from "../../../../assets/img/icon/upload/empty-box.svg";
import ReactPaginate from "react-paginate";
import "../../Layout/Ui/css/Pagination.css";

const WindowLeft = (props) => {
  let [someObj, setSomeObj] = useState();

  const {
    table,
    SetTable: setTable,
    mappedServiceData,
  } = useContext(SuperContext);

  const [viewservice, SetViewService] = useState(false);
  //Handle Service Json
  const handleViewService = (serviceJson) => {
    SetViewService(!viewservice);
    // serviceJson = JSON.parse(serviceJson)
    setSomeObj(serviceJson[0]);
  };
  // console.log(someObj);
  let toggleClassCheck = viewservice ? " view" : "";

  //With React
  const [pageNumber, setPageNumber] = useState(0);
  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const displayUsers = table.slice(pagesVisited, pagesVisited + usersPerPage);
  const pageCount = Math.ceil(table.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };
  return (
    <Fragment>
      <div className="content-window-left">
        <div className="content-table-block">
          <div className="content-table table-responsive">
            <table className="table custom-table clickable-table">
              <thead>
                <tr
                  className={`trow single-row ${
                    Object.keys(mappedServiceData).length &&
                    mappedServiceData.id === props.id
                      ? "activeRow"
                      : "test1"
                  }`}
                >
                  <th scope="col">Name</th>
                  <th scope="col">Owner Name</th>
                  <th scope="col">Email Address</th>
                  <th scope="col">Phone Number</th>
                  <th scope="col">Status</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(displayUsers).length ? (
                  displayUsers.map((a, index) => {
                    return (
                      <SuperUserRow
                        handleViewService={handleViewService}
                        key={a.id}
                        id={a.id}
                        name={a.name}
                        user_name={a.user_name}
                        owner_name={a.owner_name}
                        email={a.email}
                        phone_number={a.phone_number}
                        phone_extension={a.phone_extension}
                        website={a.website}
                        address={a.address}
                        status={a.status}
                        serviceformClose={props.serviceformClose}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={5} className="no-data-td">
                      <div className="no-data-available">
                        <div className="no-data-img">
                          <img src={noDataImage} alt="" />
                          <h5>No Data Available</h5>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {Object.keys(displayUsers).length ? (
              <ReactPaginate
                previousLabel={<i className="fal fa-long-arrow-left"></i>}
                nextLabel={<i className="fal fa-long-arrow-right"></i>}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WindowLeft;
