import React, { useState, useContext } from "react";
import { CustomerContext } from "./CustomerContext";
import Feedback from "./Feedback";
import OrderHistory from "./OrderHistory";
import PersonalDetails from "./PersonalDetails";
import VehicleDetails from "./VehicleDetails";
import styled from "styled-components";

const OrderWindowRight = () => {
  const {
    innertab,
    SetinnerTab,
    orderTable,
    setOrderTable,
    mappedOrderData,
    setViewInnerOrder,
    setEditMode,
  } = useContext(CustomerContext);
  const toggleInnerTab = (index) => {
    SetinnerTab(index);
  };
  const handleCustomerEdit = () => {
    setViewInnerOrder(false);
    SetinnerTab(1)
    setEditMode(false);
  };
  return (
    <div className="main-block w-100">
      <div className="content-right-block full-right-block">
        <div className="inner-tab-main p-0">
          <div className="inner-tab v-tab" id={"cnvden"}>
            <div className="row m-0">
              <div className="div-left-block">
                <div className="backToList-block">
                  <button  className="backtoList" onClick={handleCustomerEdit}>
                    <i class="fal fa-long-arrow-left"></i> Back to List</button>
                </div>
                {/* <div className="close-customer-block">
              <span
                className="return-rating-span"
                style={{ textAlign: "left", cursor: "pointer" }}
                onClick={handleCustomerEdit}
              >
                <i class="fal fa-long-arrow-left"></i>
              </span>
              <h3 className="mb-0">{mappedOrderData.customer_name}</h3>
            </div> */}
                <div className="tab-ul">
                  <ul
                    className="nav nav-tabs flex-direction-column customer-nav-tabs"
                    id=""
                    role="tablist"
                  >
                    <li className="nav-item">
                      <a
                        className={innertab === 1 ? " nav-link active" : "nav-link"}
                        id="nav-personal-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-personal"
                        aria-selected="true"
                        onClick={() => toggleInnerTab(1)}
                      >
                        <span>Personal Details</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={innertab === 2 ? " nav-link active" : "nav-link"}
                        id="nav-privileges-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-privileges"
                        aria-selected="false"
                        onClick={() => toggleInnerTab(2)}
                      >
                        <span>Vehicle Details</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={innertab === 3 ? " nav-link active" : "nav-link"}
                        id="nav-work-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-work"
                        aria-selected="false"
                        onClick={() => toggleInnerTab(3)}
                      >
                        <span>Order History</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={innertab === 4 ? " nav-link active" : "nav-link"}
                        id="nav-work-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-work"
                        aria-selected="false"
                        onClick={() => toggleInnerTab(4)}
                      >
                        <span>Feedback</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="div-right-block">
              <Wrapper className="tab-content staff-tab-content" id="">
                <PersonalDetails />
                <VehicleDetails />
                <OrderHistory />
                <Feedback />
              </Wrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderWindowRight;
const Wrapper =styled.div`
`;