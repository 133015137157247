import { useEffect, useState } from 'react';
import { getPhoneEextensions } from '../../api/services/common/commonAPIs';

export const usePhoneExtension = () =>{

    const [phoneExtension,setPhoneExtension]= useState([]);

    useEffect(() => {
        const getPhoneExtensionFn = () => {
          getPhoneEextensions()
            .then((response) => {
            let phoneExtensionOption = response.map((phone) => ({
                ...phone,
                value: phone.phone_extension,
                label: `${phone.country_name} (${phone.phone_extension})`,
              }));
              setPhoneExtension(phoneExtensionOption);
            })
            .catch((err) => {
              console.log(err);
            });
        };
        getPhoneExtensionFn()
      },[])
return phoneExtension;
}