import React, { useState } from "react";
import styled from "styled-components";
import { TextField, InputAdornment, IconButton, Button } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import SimpleReactValidator from "simple-react-validator";
import verifyicon from "./assets/img/Vector.svg";

export type MTextFieldProps = {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // to handle onClick functions
  color?: "primary" | "secondary"; // two styling options (you can create as many as you want)
  border?: "primary" | "secondary";
  disabled?: boolean; // make the button disabled or not
  disableVerifybtn?: boolean; // make the button disabled or not
  disableSendOtpbtn?: boolean; // make the button disabled or not
  label?: String;
  placeholder?: string;
  name?: string;
  fieldName?: string;
  error?: string | null;
  defaultValue?: string;
  value?: string;
  type?: "password" | "text" | "number";
  autoComplete?: "off" | "";
  validator?: SimpleReactValidator;
  validations?: string;
  showAdornment?: boolean;
  sendOtp?: boolean;
  verifyOtp?: boolean;
  onCheckClick?: React.MouseEventHandler<HTMLButtonElement>;
  onSendClick?: React.MouseEventHandler<HTMLButtonElement>;
  sendOtpOnly?: boolean;
  checkValue?: any;
};

export function AdornmentTextField({
  onChange,
  color,
  border,
  label,
  placeholder,
  name,
  type,
  disabled,
  defaultValue,
  value,
  error,
  autoComplete,
  validator,
  validations,
  showAdornment,
  verifyOtp,
  sendOtp,
  onCheckClick,
  onSendClick,
  sendOtpOnly,
  fieldName,
  disableVerifybtn,
  disableSendOtpbtn,
  checkValue,
}: MTextFieldProps) {


  const simpleValidator = React.useRef(validator);
  const [emailValidate, setEmailValidate] = useState(false);
  const handleBlur = (e: any) => {
    e.preventDefault();
    console.log(e, "ep");
    if (!e?.target?.value && validations)
      simpleValidator?.current?.showMessageFor(
        name?.toString()! ? name?.toString()! : "field"
      );
    setEmailValidate(!emailValidate);
  };

  // React.useEffect(() => {
  //   // handleBlur?.();
  //   simpleValidator?.current?.showMessages();
  //   console.log(value, "textvalue");
  // }, [emailValidate, value]);

  const EndAdornment = () => {
    return (
      <>
        {verifyOtp && sendOtp ? (
          <InputAdornment position="end">
            <SuccessWrapper>
              <img src={verifyicon} width={`25px`} alt="success-icon" />
              <span>Verified!</span>
            </SuccessWrapper>
          </InputAdornment>
        ) : !sendOtp ? (
          <InputAdornment position="end">
            <SuccessWrapper>
              <Button
                onClick={onSendClick}
                disabled={disableSendOtpbtn}
                variant="outlined"
                size="small"
              >
                Send Otp
              </Button>
            </SuccessWrapper>
          </InputAdornment>
        ) : !verifyOtp && sendOtp && !sendOtpOnly ? (
          <InputAdornment position="end">
            <SuccessWrapper>
              <Button
                onClick={onCheckClick}
                disabled={disableVerifybtn!}
                variant="outlined"
                size="small"
              >
                Verify
              </Button>
            </SuccessWrapper>
          </InputAdornment>
        ) : sendOtp ? (
          <InputAdornment position="end">
            <SuccessWrapper>
              <Button
                onClick={onSendClick}
                disabled={!value?.toString().length ? false : true}
                variant="outlined"
                size="small"
              >
                Send Otp
              </Button>
            </SuccessWrapper>
          </InputAdornment>
        ) : (
          ""
        )}
      </>
    );
  };
 
  return (
    <Wrapper className="textfield-wrapper">
      <FieldLabel>
        {/* label rendering with asterisk */}
        {label && (
          <>
            {`${label} `}
            {validations && <span className="red-star">*</span>}
          </>
        )}
      </FieldLabel>

      <TextField
        autoComplete={autoComplete}
        label={label}
        name={name}
        placeholder={placeholder}
        // defaultValue={defaultValue}
        value={value}
        type={"text"}
        disabled={disabled}
        onChange={onChange}
        onBlur={(e) => handleBlur(e)}
        inputProps={{
          autoComplete: "new-password",
          form: {
            autoComplete: "off",
          },
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
        InputProps={
          showAdornment
            ? {
                endAdornment: EndAdornment(),
              }
            : undefined
        }
      />
      <Errortext className="error-text">
        {simpleValidator?.current?.message(
          name?.toString() ? name?.toString() : "field",
          value?.toString() && value?.toString(),
          validations ?? ""
        )}
      </Errortext>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  &.textfield-wrapper {
    padding: 5px 0px;
    width: 100%;
    min-width: 250px;
    max-width: 400px;
    border-radius: 0px !important;
    .MuiTextField-root {
      width: 100%;
      margin: 0 0 0 15px;
      .MuiFormLabel-root {
        top: -4px !important;
        color: #000000 !important;
        &.Mui-focused {
          color: #000000 !important;
        }
      }
      .MuiInputBase-root {
        height: 100%;
        max-height: 48px !important;
        border-radius: 10px !important;
        padding: 0 15px !important;
        background: #ffffff;
        font-size: 12px !important;
        border: 1px solid #cacaca;
        .MuiOutlinedInput-notchedOutline {
          border: none !important;
          box-shadow: none !important;
        }
        &.Mui-disabled {
          cursor: pointer !important;
          .MuiInputBase-input {
            &.Mui-disabled {
              -webkit-text-fill-color: #8f8f8f !important;
            }
          }
        }
        &:hover {
          .MuiOutlinedInput-notchedOutline {
            border: none !important;
            box-shadow: none !important;
          }
        }
      }
      .MuiInputBase-input {
        box-shadow: none !important;
        background: transparent !important;
        color: #000000 !important;
        ::placeholder {
          /* Firefox */
          color: #a09d9d;
          opacity: 1;
        }
        @media (min-width: 1366px) and (max-width: 4000px) {
          font-size: 14px;
        }
        @media (min-width: 1200px) and (max-width: 1365px) {
          font-size: 13px;
        }
        @media (min-width: 992px) and (max-width: 1199px) {
          font-size: 13px;
        }
        @media (min-width: 768px) and (max-width: 991px) {
          font-size: 12px;
        }
        @media (min-width: 576px) and (max-width: 767px) {
          font-size: 12px;
        }
        @media (min-width: 320px) and (max-width: 575px) {
          font-size: 11px;
        }
      }
      .MuiInputBase-input.MuiOutlinedInput-input:-webkit-autofill {
        box-shadow: inset 0 0 100px 100px #4e4e4e !important;
        font-size: 15px !important;
        border-radius: 0px !important;
        -webkit-text-fill-color: #fff !important;
      }
    }
  }
`;
const FieldLabel = styled.div`
  text-align: left;
  margin: 0 0 10px 0;
  color: #a09d9d;
  font-size: 14px;
  .red-star {
    color: red;
    font-weight: bold;
  }
`;
const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const SuccessWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  max-width: 103px;
  img {
    width: 25px;
  }
  span {
    color: #00b383;
    margin: 0 10px 0 10px;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0px;
    font-weight: 400;
  }
  button {
    min-width: 84px;
    max-width: 84px;
  }
`;
const Errortext = styled.span`
  display: block;
  font-size: 14px;
  color: #e91344;
  letter-spacing: 0.5px;
  padding-top: 8px !important;
  margin-left: 15px;
`;
