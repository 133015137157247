import React, { useState, useContext, useCallback } from "react";
import { Button, Modal, Nav, Container, Row, Col } from "react-bootstrap";
import { ServiceContext } from "./ServiceContext";
import uploadImageFile from "../../../../assets/img/icon/upload/photos.svg";
import CropModal from "../../../ImageCropper";
const ServiceFileUploadModal = (props) => {
  const {
    pic,
    setPic,
    cancelFile,
    setCancelFile,
    addonImages,
    setAddonImages,
    addImageSubmit,
    setAddImageSubmit,
  } = useContext(ServiceContext);
  const fileToDataUri = (image) => {
    return new Promise((res) => {
      const reader = new FileReader();
      const { type, name, size } = image;
      reader.addEventListener("load", () => {
        res({
          base64: reader.result,
          name: name,
          type,
          size: size,
        });
      });
      reader.readAsDataURL(image);
    });
  };

  const [src, setSrc] = useState(null);
  const setCroppedImageUrlCallback = (url, blob) => {
    if (url !== null) {
      fileToDataUri(blob).then((newImage) => {
        setPic([...pic, newImage]);
      });
    }
  };
  const [blob, setBlob] = React.useState(null);

  const uploadImage = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () => {
          const blob = new Blob([reader.result], {
            type: e.target.files[0].type,
          });
          setBlob(blob);
          setSrc(reader.result);
          setCropModal(true);
        },
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const closeFileUpload = () => {
    props.hidefilemodal(false);
    setPic([]);
  };
  const ImageSubmit = (e) => {
    setPic(pic);
    props.setShow(false);
    e.preventDefault();
    setCancelFile(true);
    setAddImageSubmit(true);
  };
  const [openCropModal, setCropModal] = useState(false);
  return (
    <Modal
      className="file-modal"
      show={props.showfilemodal}
      onHide={props.hidefilemodal}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={3} className="p-0">
              <Row className="guidelines-info w-100">
                <Col md={12}>
                  <Col md={12}>
                    <h5>Dimensions: </h5>
                    <p>1024 x 768px</p>
                  </Col>
                  <Col md={12}>
                    <h5>Format:</h5>
                    <p>JPG or PNG</p>
                  </Col>
                  <Col md={12}>
                    <h5>File Size:</h5>
                    <p>Below 10 MB</p>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col xs={9} className="upload-block">
              <Modal.Header closeButton></Modal.Header>
              <h5 className="title">Upload Images</h5>
              <div className="form-group">
                <div className="custom-file">
                  <input
                    onChange={uploadImage}
                    type="file"
                    multiple
                    accept="image/jpeg, image/png, image/webp"
                    className="custom-file-input form-control-sm"
                    id="imageUpload"
                  />
                  <label
                    className="custom-file-label col-form-label-sm"
                    for="imageUpload"
                  >
                    <div className="text-center">
                      <div className="upload-section">
                        <div className="upload-img">
                          <img src={uploadImageFile} alt="loading..." />
                        </div>
                        <div className="upload-text">
                          <h5>
                            <span>Click to upload</span> or drag and drop
                          </h5>
                          <h6>Maximum file size 10 MB.</h6>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <Row className="row-img">
                <div className="mb-2 text-center">
                  {Object.keys(pic).length && Object.keys(pic).length > 3 ? (
                    <p className="text-danger">THree image Can be Uploaded</p>
                  ) : (
                    ""
                  )}
                </div>
                {Object.keys(pic).length > 3 ? (
                  <div className="col-12 col-img-error">
                    <p>Maximum 3 image</p>
                  </div>
                ) : (
                  ""
                )}
                {pic &&
                  pic.map((photo, index) => {
                    return (
                      <div className="col-md-4 col-img-list">
                        <div className="img-list" key={photo}>
                          <button
                            className="btn"
                            onClick={() =>
                              setPic(pic.filter((e) => e !== photo))
                            }
                          >
                            <i class="fal fa-times"></i>
                          </button>
                          <img
                            src={photo.base64}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </div>
                    );
                  })}
              </Row>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeFileUpload}>
                  Close
                </Button>
                {Object.keys(pic).length && Object.keys(pic).length <= 3 ? (
                  <Button variant="primary" onClick={ImageSubmit}>
                    Confirm
                    <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                  </Button>
                ) : (
                  <Button variant="primary" disabled onClick={ImageSubmit}>
                    Confirm
                    <i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
                  </Button>
                )}
              </Modal.Footer>
            </Col>
          </Row>
          <CropModal
            src={src}
            setCroppedImageUrl={setCroppedImageUrlCallback}
            openCropModal={openCropModal}
            setCropModal={setCropModal}
            blob={blob}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default ServiceFileUploadModal;
