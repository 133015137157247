import React, { useContext } from "react";
import { CustomerContext } from "./CustomerContext";
import Scrollbars from "react-custom-scrollbars-2";
import AdminRating from "./Innertab/AdminRating";
import CustomerRating from "./Innertab/CustomerRating";
const Feedback = () => {
  const {
    innertab,
    feedbackInnerTab,
    setFeedbackInnerTab,
    customerRating,
    setCustomerRating,
  } = useContext(CustomerContext);
  const feedbackToggleInnerTab = (index) => {
    setFeedbackInnerTab(index);
    // if (index === 1) {
    //   setEditBtn(true);
    // } else if (index === 2) {
    //   setEditBtn(false);
    // } else if (index === 3) {
    //   setEditBtn(false);
    // } else if (index === 4) {
    //   setEditBtn(false);
    // }
  };
  return (
    <div
      className={innertab === 4 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div className="inner-form-block customer-inner-form-block">
          <div className="order-right-block">
            <div className="tab-custom">
              <div className="inner-tab-main">
                <div className="inner-tab v-tab d-flex flex-column ">
                  {/* {customerRating && (
              
                  )} */}
                  {customerRating ? (
                    <span
                      className="return-rating-span"
                      style={{ textAlign: "left" , cursor: "pointer" }}
                      onClick={() => setCustomerRating(false)}
                    >
                      <i class="far fa-less-than"></i>
                    </span>
                  ) : (
                    <div className="tab-ul">
                      <ul
                        className="nav nav-tabs flex-direction-column feedback-nav-tabs"
                        id=""
                        role="tablist"
                      >
                        <li className="nav-item">
                          <a
                            className={
                              feedbackInnerTab === 2
                                ? " nav-link active"
                                : "nav-link"
                            }
                            id="nav-personal-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="nav-personal"
                            aria-selected="true"
                            onClick={() => feedbackToggleInnerTab(2)}
                          >
                            <span>Customer rates you</span>
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={
                              feedbackInnerTab === 1
                                ? " nav-link active"
                                : "nav-link"
                            }
                            id="nav-privileges-tab"
                            data-toggle="tab"
                            role="tab"
                            aria-controls="nav-privileges"
                            aria-selected="false"
                            onClick={() => feedbackToggleInnerTab(1)}
                          >
                            <span>You rate Customer</span>
                            
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}

                  <div className="tab-content customer-tab-content mt-4" id="">
                    <CustomerRating />
                    <AdminRating />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Scrollbars>
    </div>
  );
};

export default Feedback;
