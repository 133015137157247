import React from "react";
import Select from "react-select";
import styled from "styled-components";

const CustomYearPicker = ({ value, onChange, readOnly }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const startYear = 1900; // Change the start year to 1900
  const endYear = currentYear; // Change the end year to the current year

  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );

  const [dropData, setDropData] = React.useState();

  React.useEffect(() => {
    setDropData(
      years.map((year) => ({
        value: year,
        label: year.toString(),
      }))
    );
  }, []);

  return (
    <>
      <Wrapper>
        <Select
          className="year-picker"
          classNamePrefix={"dropdown"}
          options={dropData}
          value={value ? { value: value, label: value.toString() } : null}
          onChange={(option) => onChange(option.value)}
          isDisabled={readOnly}
        />
      </Wrapper>
    </>
  );
};

export default CustomYearPicker;
const Wrapper = styled.div`
  width: 100%;
  .css-13cymwt-control {
    background: #e8e8e8;
  }
  .css-1fdsijx-ValueContainer {
    justify-content: flex-start;
  }
  .css-1n6sfyn-MenuList {
    text-align: left;
  }
  .css-t3ipsp-control {
    background-color: #e8e8e8;
    border: none !important;
    box-shadow: none;
  }
`;
