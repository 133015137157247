import React, { useState } from "react";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import UploadIcon from "./assets/UploadIcon.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import styled from "styled-components";
import SimpleReactValidator from "simple-react-validator";

interface FileUploadButtonProps {
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFileUpload: (file: File) => void;
  id?: string;
  className?: string;
  onRemoveClick?: () => void;
  validator?: SimpleReactValidator;
  validations?: string;
  fieldName?: string;
  isSubmit?: boolean;
  defaultValue?: File | null;
}

export function FileUploadButton({
  onFileUpload,
  id,
  className,
  onChange,
  onRemoveClick,
  validator,
  validations,
  fieldName,
  defaultValue,
  isSubmit,
}: FileUploadButtonProps) {
  const simpleValidator = React.useRef<HTMLInputElement | null>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // ref
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      setSelectedFile(file);
      onFileUpload(file);
    }
    onChange?.(event);
  };

  const removeFile = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    inputRef.current!.value = "";
    setSelectedFile(null);
    onRemoveClick?.();
  };
  const handleUploadClick = (e: any) => {
    // onUpload?.(e);
    inputRef?.current?.click();
  };

  React.useEffect(() => {
    if (
      (simpleValidator?.current && validations) ||
      (isSubmit && simpleValidator?.current && validations)
    ) {
      validator?.showMessages();
    }
  }, [selectedFile]);
  return (
    <Wrapper className={`Wrapper-${className}`}>
      <label htmlFor="file-upload">
        <input
          ref={inputRef}
          type="file"
          id={id}
          className={className}
          accept={".pdf, .doc, .docx"}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        <Button
          variant="contained"
          component="span"
          startIcon={<img src={UploadIcon} alt="Upload" />}
          style={{
            background: "#01579B",
            fontSize: "12px",
            width: "183px",
            height: "35px",
          }}
          onClick={(e) => handleUploadClick(e)}
        >
          {selectedFile! ? (
            <>
              <StyledFileName>{selectedFile.name}</StyledFileName>

              <CancelIcon
                onClick={(e) => removeFile(e)}
                style={{ cursor: "pointer", marginLeft: "5px" }}
              />
            </>
          ) : (
            "Upload Document"
          )}
        </Button>
      </label>
      <Errortext className="error-text">
        {validator?.message(
          fieldName?.toString() ? fieldName?.toString() : "field",
          defaultValue! && defaultValue!,
          validations ?? ""
        )}
      </Errortext>
    </Wrapper>
  );
}

const StyledFileName = styled.span`
  width: 183px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Wrapper = styled.div`
 
`;
const Errortext = styled.span`
  display: block;
  font-size: 13px;
  color: #e91344;
  padding-top: 4px !important;
  width: 280px;
  text-align: left;
 
`;
