import React, { useContext, useEffect, useState } from "react";
import NavToggle from "./NavToggle";
import ProfileBlock from "./ProfileBlock";
import { ProfileContext } from "../../Pages/Profile/ProfileContext";
import configData from "../../../../config.json";
import { getProfileInfo } from "../../../../api/services/sap/profile-management";
import { toast } from "react-toastify";

const ContentHeader = () => {
  const { innertab, SetinnerTab, userProfile, setUserProfile } =
    useContext(ProfileContext);
  const [addprofile, SetAddProfile] = useState(false);
  const [serviceCenterName, setServiceCenterName] = useState("");
  const [newUser, setNewUser] = useState(false);
  const profileBlock = () => {
    SetAddProfile(!addprofile);
  };

  useEffect(() => {
    let serviceCenterName = localStorage.getItem("serviceCenterName");
    setServiceCenterName(serviceCenterName);

    const data = getProfileInfo();
    data.then((res) => {
      setNewUser(res);
      // if (res.country_id_fk === null) {
      //   toast.error("Please enter country field in your Profile", {
      //     position: "top-right",
      //   });
      // }
    });
  }, []);

  return (
    <div className="content-header-main">
      <div className="content-header">
        {/* <div className="content-title-block">
            <h4 className="head text-uppercase">Customer</h4>
          </div> */}
        <div className="content-top">
          <div className="content-top-left d-flex align-items-center">
            {/* <NavToggle /> */}
            <div className="content-details">
              <h4>
                {userProfile && userProfile.service_center_name
                  ? userProfile.service_center_name
                  : serviceCenterName && serviceCenterName}
              </h4>
              {/* <p>Balaramapuram</p> */}
            </div>
          </div>
          <div className="content-top-right">
            <button className="btn btn-faq">FAQS</button>
            <button className="btn btn-notification">
              <i className="fal fa-bell"></i>
            </button>
            {newUser && newUser.logo ? (
              <span className="ml-2 mr-2">
                <img
                  className="img-fluid user-img"
                  onClick={profileBlock}
                  src={
                    newUser &&
                    `${configData.SERVER_URL}/uploads/service_admin/${newUser.logo}`
                  }
                  style={{ width: "30px", height: "30px", borderRadius: "50%" }}
                  rounded="true"
                />
              </span>
            ) : (
              <button className="btn btn-user" onClick={profileBlock}>
                <i className="fal fa-user"></i>
              </button>
            )}
          </div>
          {addprofile && <ProfileBlock />}
        </div>
      </div>
    </div>
    // <div class="content-header-main">
    //     <div class="content-header">
    //         <div class="content-title-block">
    //         <h4 class="head"><i class="fal fa-th-large"></i> Service</h4>
    //         </div>
    //         <div class="header-bar">
    //             <nav class="header-nav">
    //                 <div class="nav nav-tabs" id="nav-tab" role="tablist">
    //                     <a class="nav-item nav-link active" id="nav-service-tab" data-toggle="tab" href="#nav-services" role="tab" aria-controls="nav-services" aria-selected="true">Services</a>
    //                     {/* <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-packages" role="tab" aria-controls="nav-packages" aria-selected="false">Offers & Discounts</a>
    //                     <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-insights" role="tab" aria-controls="nav-insights" aria-selected="false">Insights</a> */}
    //                 </div>
    //             </nav>
    //         </div>
    //     </div>
    // </div>
  );
};

export default ContentHeader;
