import React  from 'react';
import StaffFilterDropdown from "./StaffFilterDropdown";
import StaffSortDropdown from "./StaffSortDropdown";
const StaffFilterWrapper = () => {
  return (
    <div class="filter-wrapper">
      <div class="search-sort-filter">
        <div class="d-flex header-action-block">
          <form action="" method="">
            <div class="input-group">
              <div class="input-group-prepend">
                <span class="input-group-text search-span-txt">
                  <i class="far fa-search"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control"
                id="search-id"
                placeholder="Search"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
          </form>
          <div class="sort-filter">
            <StaffSortDropdown/>
            <StaffFilterDropdown/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default StaffFilterWrapper;
