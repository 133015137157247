import React, {
  useContext,
  useState,
  Fragment,
  useEffect,
  createContext,
} from "react";
import {
  getProfileInfo,
  updateProfileInfo,
  getBankList,
} from "../../../../api/services/sap/profile-management";
import {
  getCountryList,
  getCurrencyList,
} from "../../../../api/services/common/commonAPIs";
import { getServiceConfiguration } from "../../../../api/services/sap/bookings-management";

export const ProfileContext = createContext("");

export const ProfileProvider = (props) => {
  const [innertab, SetinnerTab] = useState(1);
  const [viewonly, setViewOnly] = useState(false);
  //sapLogo
  const [sapLogo, setSapLogo] = useState([]);
  const [addonImages, setAddonImages] = useState([]);
  const [addImageSubmit, setAddImageSubmit] = useState(false);

  //sap liscence
  const [sapLicense, setSapLicense] = useState([]);
  const [userProfile, setUserProfile] = useState([]);
  const [previousUserProfileData, setPreviousUserProfileData] = useState([]);
  const [showImage, setShowImage] = useState("");
  //License Modal State
  const [logoModal, setlogoModal] = useState(false);
  const handleClose = () => {
    setShowImage(userProfile.logo);
    setlogoModal(false);
  };
  const handleShow = () => setlogoModal(true);
  //Logo Modal State
  const [licenseModal, setlicenseModal] = useState(false);
  const handleLicenseClose = () => setlicenseModal(false);
  const handleLicenseShow = () => setlicenseModal(true);

  //innertab view-only-mode states
  const [infoviewonly, setInfoViewOnly] = useState(true);
  const [bankviewonly, setBankViewOnly] = useState(false);
  const [timingviewonly, setTimingViewOnly] = useState(true);
  const [licenseviewonly, setLicenseViewOnly] = useState(true);
  const [gstviewonly, setGstViewOnly] = useState(true);
  const [socialviewonly, setSocialViewOnly] = useState(true);

  //BANK Validation State
  const [formErrors, SetFormErrors] = useState({});
  const [error, setError] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);

  //bank form
  const [bankformData, setBankFormData] = useState({
    account_holder_name: "",
    account_number: "",
    bank_name: "",
    branch_name: "",
    ifsc_code: "",
  });
  const [profileConfigData, setProfileConfigData] = useState(null);
  const [gstformData, setGstFormData] = useState();

  const [infoTabErrors, setInfoTabErrors] = useState(false);

  useEffect(() => {
    const data = getBankList();
    data
      .then((res) => {
        setBankFormData(
          res
            ? res
            : {
                account_holder_name: "",
                account_number: "",
                bank_name: "",
                branch_name: "",
                ifsc_code: "",
              }
        );
        // res && setBankViewOnly(true);
        // res.map((item) => ({
        //   account_holder_name: item.account_holder_name
        //     ? item.account_holder_name
        //     : "",
        //   account_number: item.account_number ? item.account_number : "",
        //   bank_name: item.bank_name ? item.bank_name : "",
        //   branch_name: item.branch_name ? item.branch_name : "",
        //   ifsc_code: item.ifsc_code ? item.ifsc_code : "",
        // }))

        // setBankViewOnly(true);
      })
      .catch((err) => {
        console.log(err, "catch err");
        setBankFormData({
          account_holder_name: "",
          account_number: "",
          bank_name: "",
          branch_name: "",
          ifsc_code: "",
        });
      });

    return () => {
      setError(false);
      SetFormErrors({});
    };
  }, [bankviewonly, getBankList]);

  //Toggle Tabs Function
  const toggleInnerTab = (index) => {
    if (index === innertab) {
      return;
    }
    SetinnerTab(index);

    if (index === 1) {
      setInfoViewOnly(true);
      setBankViewOnly(true);
      setTimingViewOnly(true);
      setLicenseViewOnly(true);
      setGstViewOnly(true);
      setSocialViewOnly(true);
      setShowImage(userProfile.logo);
      setInfoTabErrors(false);
    }
    if (index === 2) {
      setInfoViewOnly(true);
      setTimingViewOnly(true);
      setLicenseViewOnly(true);
      setGstViewOnly(true);
      setSocialViewOnly(true);
      setShowImage(userProfile.logo);
    }
    if (index === 3) {
      setTimingViewOnly(true);
      setInfoViewOnly(true);
      setBankViewOnly(true);
      setLicenseViewOnly(true);
      setGstViewOnly(true);
      setSocialViewOnly(true);
      setShowImage(userProfile.logo);
    }
    if (index === 4) {
      setLicenseViewOnly(true);
      setTimingViewOnly(true);
      setInfoViewOnly(true);
      setBankViewOnly(true);
      setGstViewOnly(true);
      setSocialViewOnly(true);
      setShowImage(userProfile.logo);
    }
    if (index === 5) {
      setGstViewOnly(false);
      setLicenseViewOnly(true);
      setTimingViewOnly(true);
      setInfoViewOnly(true);
      setBankViewOnly(true);
      setSocialViewOnly(true);
      setShowImage(userProfile.logo);
    }
    if (index === 6) {
      setSocialViewOnly(true);
      setGstViewOnly(true);
      setLicenseViewOnly(true);
      setTimingViewOnly(true);
      setInfoViewOnly(true);
      setBankViewOnly(true);
      setShowImage(userProfile.logo);
    }
  };

  //gest user data
  const getCountryListFn = () => {
    getCountryList()
      .then((response) => {
        let countryListOptions = response.map((country) => ({
          ...country,
          value: country.country_id_pk,
          label: country.country_name,
        }));
        setCountryList(countryListOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCurrencyListFn = () => {
    getCurrencyList()
      .then((response) => {
        let currencyListOptions = response.map((currency) => ({
          ...currency,
          value: currency.currency_id_pk,
          label: `${currency.currency_name} (${currency.currency_code})`,
        }));
        setCurrencyList(currencyListOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    // getCountryListFn();
    getCurrencyListFn();
    const id = localStorage.getItem("userid");
    const data = getProfileInfo(id);
    data
      .then((response) => {
        setUserProfile(response);
        setPreviousUserProfileData(response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const fetchVatOrGstData = async (data) => {
      try {
        let data = {
          table_reference: [
            "tax_types",
            "payment_methods",
            "bank_number_types",
          ],
        };
        const response = await getServiceConfiguration(data);
        setProfileConfigData(response);
      } catch (error) {
        console.error("Error fetching :", error);
      }
    };
    fetchVatOrGstData();
  }, []);

  let initialStateValues = {
    innertab,
    SetinnerTab,
    toggleInnerTab,
    viewonly,
    setViewOnly,
    sapLogo,
    setSapLogo,
    addonImages,
    setAddonImages,
    setAddImageSubmit,
    logoModal,
    setlogoModal,
    handleClose,
    handleShow,
    sapLicense,
    setSapLicense,
    licenseModal,
    setlicenseModal,
    handleLicenseClose,
    handleLicenseShow,
    userProfile,
    setUserProfile,
    infoviewonly,
    setInfoViewOnly,
    bankviewonly,
    setBankViewOnly,
    timingviewonly,
    setTimingViewOnly,
    licenseviewonly,
    setLicenseViewOnly,
    gstviewonly,
    setGstViewOnly,
    socialviewonly,
    setSocialViewOnly,
    bankformData,
    setBankFormData,
    formErrors,
    SetFormErrors,
    error,
    setError,
    gstformData,
    setGstFormData,
    countryList,
    setCountryList,
    currencyList,
    setCurrencyList,
    profileConfigData,
    setProfileConfigData,
    setShowImage,
    showImage,
    previousUserProfileData,
    setPreviousUserProfileData,
    infoTabErrors,
    setInfoTabErrors,
  };

  return (
    <ProfileContext.Provider value={initialStateValues}>
      {props.children}
    </ProfileContext.Provider>
  );
};
