import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { getProfileInfo } from "../../../api/services/sap/profile-management";

const Popup = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getProfileInfo();
        if (res.country_id_fk === null && location.pathname !== "/profile") {
          handleShow();
        }
      } catch (error) {
        console.log("Error fetching profile info:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Wrapperpopup>
      <ModalWrapper className="popup-main-div" show={show} onHide={handleClose}>
        <StyledHeader closeButton className="modal-header">
          <ModalWrapper.Title className="title-popup-div">
            IMPORTANT !
          </ModalWrapper.Title>
        </StyledHeader>
        <StyledBody>
          Please ensure that your country is entered in your profile.{" "}
          <a href="/profile">Go to profile</a>
        </StyledBody>
      </ModalWrapper>
    </Wrapperpopup>
  );
};

export default Popup;
const ModalWrapper = styled(Modal)`
  .modal-content {
    max-width: 660px;
    min-width: 300px;
    width: 660px;
    color: red;
  }
`;
const StyledHeader = styled(Modal.Header)`
  max-width: 660px;
  min-width: 300px;
  width: 640px;
  height: 40px;
`;
const StyledBody = styled(Modal.Body)`
  max-width: 660px;
  min-width: 300px;
  width: 660px;
  padding: 10px 20px !important;
`;
const Wrapperpopup = styled.div``;
