import React from "react";
import { useNavigate } from "react-router-dom";
import { logout } from "../../../../../api/auth/sap/logout";
import "./css/Profile.css";

function ProfileBlock() {
  const navigate = useNavigate();
  const logoutAction = () => {
    const returnedPromise = logout();
    returnedPromise.then((res) => {
      navigate('/');
    })
  }
  return (
    <div className="profile-main-block">
      <ul>
        <li><button className="btn">Lorem</button></li>
        <li><button className="btn" onClick={logoutAction}>Logout</button></li>
      </ul>
    </div>
  );
}

export default ProfileBlock;
