import { useEffect, useState } from "react";
import { getCountryList } from "../../api/services/common/commonAPIs";

export const useCountryRegion = (data) => {
  const [countryRegion, setCountryRegion] = useState([]);

  useEffect(() => {
    const getCountryRegionFn = () => {
      getCountryList(data)
        .then((response) => {
          setCountryRegion(response);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getCountryRegionFn();
  }, []);
  return countryRegion;
};
