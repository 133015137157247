import React, { useContext, useEffect } from "react";
import { CustomerContext } from "./CustomerContext";
import Scrollbars from "react-custom-scrollbars-2";
import { useState } from "react";
import { getCustomerAddVehicle, getCustomerVehicleList } from "../../../../api/services/sap/customer-management";
import vehicleIcon from "../../../../assets/img/icon/vehicle.png";
import Modal from "react-bootstrap/Modal";
import { Button } from "react-bootstrap";
import Select from "react-select";
import { useCountryRegion } from "../../../hooks/useCountryRegion";
import styled from "styled-components";
import { getVehicleModel ,} from "../../../../api/services/sap/bookings-management";
import { toast } from "react-toastify";


let initialValue = {
  country_id:"",
  vehicle_number: "",
  vehicle_manufacturer: "",
  vehicle_manufacture_year: "",
  vehicle_model: "",
  vehicle_color: "",
  vehicleType:"",
  
};

const VehicleDetails = () => {
  const {
    innertab,
    mappedOrderData,
    vehicleData,
    setVehicleData,
    vehicleTypes,
    setMappedOrderData,
  } = useContext(CustomerContext);
  const [formData, setFormData] = useState(initialValue);
  const [lgShow, setLgShow] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("UAE");
  const [selectRegion, setSelectRegion] = useState("AUH");
  const countryRegion = useCountryRegion({ region: true });
  const [manufacturers, setManufacturers] = useState([]);
  const [models, setModels] = useState([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState(null);
  const [selectedVehicleType, setSelectedVehicleType] = useState(null);
  const [selectedModel, setSelectedModel] = useState(null);
  const resetForm = () => {
    setFormData(initialValue);
    setSelectedCountry("UAE");
    setSelectRegion("AUH");
    setSelectedManufacturer(null);
    setSelectedVehicleType(null);
    setSelectedModel(null);
  };
  
    const submitHandler = () => {
      let regionId = null;
      if (selectedCountry === "UAE") {
        regionId = filteredRegions?.id ? filteredRegions.id : null;
      }
      const payload = {
        ...formData,
        customer_id:mappedOrderData.customer_id,
        country_id: filteredCountries?.country_id_pk,
        ...(selectedCountry === "UAE" && { region_id: regionId }),
        vehicle_number: formData.vehicle_number,
        vehicleType: formData.vehicleType,
        vehicle_manufacturer: formData.vehicle_manufacturer,
        vehicle_model: formData.vehicle_model,
        vehicle_manufacture_year: formData.vehicle_manufacture_year,
        vehicle_color: formData.vehicle_color,
      };
      getCustomerAddVehicle(payload)
        .then((response) => {
          toast.success("Vehicle Added Successfully", {
            position: "bottom-right",
          });
          console.log("Vehicle added successfully:", response);
          setLgShow(false);
          setMappedOrderData({ ...mappedOrderData });
        })
        .catch((error) => {   
          console.error("Error adding vehicle:", error);
        });
    };


    useEffect(() => {
      if (mappedOrderData) {
        getCustomerVehicleList(mappedOrderData.customer_id)
          .then((res) => {
            setVehicleData(res.data);
          })
          .catch((error) => {
            console.error("Error fetching vehicle list:", error);
          });
      }
    }, [mappedOrderData]);
    

  const filteredCountries = countryRegion.find((country) =>
  country.country_code.toLowerCase().includes(selectedCountry.toLowerCase())
);
const filteredRegions = countryRegion
 .map((country) =>
   country.regions?.find(
     (region) => region.code?.toLowerCase() === selectRegion.toLowerCase()
   )
 )
?.find((region) => region !== undefined);
  const QuoteClick = () => {
    resetForm();
    setLgShow(true);
  };
  let options = [];
  const uaeCountry = countryRegion.find(
    (country) => country.country_code === "UAE",
  );
  if (uaeCountry) {
    options = uaeCountry.regions.map((region) => ({
      value: region.code,
      label: `${region.name} (${region.code})`,
    }));
  }
  const vehicleOptions = vehicleTypes.map((item) => ({
    label: item.title,
    value: item.value,
  }));
  const handleVehicleTypeChange = (selectedOption) => {
    setSelectedVehicleType(selectedOption);
    setFormData({
      ...formData,
      vehicleType: selectedOption.value,
    });
    setSelectedManufacturer(null); 
    setSelectedModel(null); 
    handleGetModel(selectedOption.value);


  };
  const handleGetModel = (vehicleTypeID) => {
    const data = getVehicleModel(vehicleTypeID);
    data.then((response) => {
   
      const manufacturers = response
        .filter((item) => item.variant_id_fk )
        .map((item) => ({
          label: item.manufacturer,
          value: item.manufacturer_id,
        }))
        .filter(
          (item1, index, all) =>
            all.findIndex((item2) => item2.value === item1.value) === index,
        );
      setManufacturers(manufacturers);
      setSelectedManufacturer(null);
    });
  };

  const handleManufacturerChange = (selectedOption) => {
    setSelectedManufacturer(selectedOption);
  
    setFormData({
      ...formData,
      vehicle_manufacturer: selectedOption.value,
    });
    const data = getVehicleModel(selectedVehicleType.value);
    data.then((response) => {
     
      const models = response
        .filter((item) => item.variant_id_fk)
        .map((item) => ({
          ...item,
          label: item.model,
          value: item.vehicle_model_id_pk, 
        }));    
      const filteredModels = models.filter(
        (model) => model.manufacturer_id === selectedOption.value
      );
  
      setModels(filteredModels);
    });
  };
  const handleModelChange = (selectedOption) => {
    setSelectedModel(selectedOption);
    setFormData({
      ...formData,
      vehicle_model: selectedOption.value,
    });
    
  };
  return (
    <div
      className={innertab === 2 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <div>
        <div className="d-flex  mb-4 mt-4">
          <div>
            <>
              <Button className="mr-3" onClick={QuoteClick}>
                Add Vehicle
              </Button>

              <Modal
                size="lg"
                show={lgShow}
                aria-labelledby="example-modal-sizes-title-lg"
                className="modal-total-style"
              >
                <Modal.Header onHide={() => setLgShow(false)} closeButton />
                <div
                  style={{
                    textAlign: "right",
                    marginRight: "20px",
                    fontSize: "14px",
                  }}
                ></div>
                <Modal.Body className="total-padding">
                  <div className="main-wrapper p-1">
                    {/*Vehicle Number Block */}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="fal fa-sort-numeric-down"></i>Vehicle Number
                          {<sup className="theme-text">*</sup>}
                        </span>
                      </div>
                      <div className="input-block">
                        <div style={{ display: "flex" }}>
                          <DropdownWrapper>
                            <Select
                              className="country-code"
                              options={countryRegion.map((country) => ({
                                value: country.country_code,
                                label: `${country.country_name} (${country.country_code})`,
                              }))}
                              value={{
                                value: selectedCountry,
                                label: selectedCountry,
                              }}
                              onChange={(selectedOption) => {
                                setSelectedCountry(selectedOption.value);
                              }}
                            />
                          </DropdownWrapper>
                          {selectedCountry === "UAE" && (
                            <DropdownWrapper>
                              <Select
                                className="country-code"
                                options={options}
                                value={{
                                  value: selectRegion,
                                  label: selectRegion,
                                }}
                                onChange={(selectedOption) =>
                                  setSelectRegion(selectedOption.value)
                                }
                              />
                            </DropdownWrapper>
                          )}
                          <VehicleWrapper style={{width:"100%",maxWidth:"350px"}}>
                            <input
                            
                              type="text"
                              className="form-control"
                              placeholder="vehicle number"
                              aria-label="Username"
                              aria-describedby="basic-addon1"
                              value={formData.vehicle_number}
                              onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    vehicle_number: e.target.value.toUpperCase() 
                                });
                            }}
                            maxLength={50}
                              
                            />
                          </VehicleWrapper>
                        </div>
                      </div>
                    </div>
                    {/* Vehichle Type */}
                    <VehicleTypeContainer>
                      <div class="input-group input-group-custom">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <i class="fal fa-car"></i>Vehicle Type
                            <sup className="theme-text">*</sup>
                          </span>
                        </div>
                        <div className="input-block">
                          <Select
                            id="warranty_period"
                            options={vehicleOptions}
                            value={selectedVehicleType}
                            onChange={handleVehicleTypeChange}
                          />
                        </div>
                      </div>
                    </VehicleTypeContainer>
                    {/* Manufactured  Block*/}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fal fa-sitemap"></i>Manufacturer
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <div className="input-block">
                        <Containerdrop>
                          <Select
                            options={manufacturers}
                            value={selectedManufacturer}
                            onChange={handleManufacturerChange}
                          />
                        </Containerdrop>
                      </div>
                    </div>
                    {/* Model  Block*/}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fal fa-sitemap"></i>Model
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <div className="input-block">
                        <Containerdrop>
                          <Select
                            options={models}
                            value={selectedModel}
                            onChange={handleModelChange}
                          />
                        </Containerdrop>
                      </div>
                    </div>
                    {/* Manufacture block*/}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="fal fa-calendar-check"></i>Manufactured Year
                        </span>
                      </div>
                      <input
                        style={{
                          background: "#e8e8e8",
                          width: "100%",
                          maxWidth: "550px",
                        }}
                        type="number"
                        className="form-control"
                        value={formData.vehicle_manufacture_year}
                        onChange={(e) => {
                          const inputYear = e.target.value;
                          if (inputYear.length <= 4) {
                            setFormData({
                              ...formData,
                              vehicle_manufacture_year: inputYear,
                            });
                          }
                        }}
                      
                      />
                    </div>

                    {/* vehicle_color Block*/}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="fal fa-palette"></i>Color
                        </span>
                      </div>
                      <input
                        type="text"
                        style={{
                          background: "#e8e8e8",
                          width: "100%",
                          maxWidth: "550px",
                        }}
                        className="form-control"
                        value={formData.vehicle_color}
                        maxLength={100}
                        onChange={(e) => {
                          setFormData({
                              ...formData,
                              vehicle_color: e.target.value
                          });
                      }}
                      />
                    </div>
                  </div>
                  <ButtonWrapper>
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={() => setLgShow(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn-primary ml-3"
                      type="submit"
                      onClick={submitHandler}
                    >
                      Save
                    </button>
                  </ButtonWrapper>
                </Modal.Body>
              </Modal>
            </>
          </div>
        </div>
      </div>
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div
          className="inner-form-block customer-inner-form-block"
          style={{ background: "white" }}
        >
          {vehicleData &&
            vehicleData.map((item, index) => {
              return (
                <div className="row ml-0 mr-0 mt-0 mb-3 vehicle-details-block">
                  <div
                    className=""
                    style={{
                      textAlign: "left",
                      paddingBottom: "15px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="vehicle-num">Vehicle {index + 1}</p>
                    <p className="variant-name-block">{item.variant_name}</p>
                  </div>
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-size">
                        <i className="fal fa-sort-numeric-down"></i>Vehicle Number
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={item.vehicle_registration_number}
                      readOnly
                    />
                  </div>
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-size">
                        {/* <i className="fal fa-user"></i> */}
                        <img
                          className="img-fluid"
                          src={vehicleIcon}
                          alt=""
                          style={{
                            width: "30px",
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyCcontent: "center",
                          }}
                        />
                        Vehicle Type
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={item.vehicle_type}
                      readOnly
                    />
                  </div>
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-size">
                        <img
                          className="img-fluid"
                          src={vehicleIcon}
                          alt=""
                          style={{
                            width: "30px",
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyCcontent: "center",
                          }}
                        />{" "}
                        Manufacturer
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={item.vehicleModel.vehicleManufacture.manufacturer}
                      readOnly
                    />
                  </div>
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-size">
                        <img
                          className="img-fluid"
                          src={vehicleIcon}
                          alt=""
                          style={{
                            width: "30px",
                            marginRight: "10px",
                            display: "flex",
                            alignItems: "center",
                            justifyCcontent: "center",
                          }}
                        />
                        Model
                      </span>
                    </div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={item.vehicleModel.model}
                      readOnly
                    />
                  </div>
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-size">
                        <i class="fal fa-calendar-check"></i>
                        Manufactured Year
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      value={item.manufactured_year}
                      readOnly
                    />
                  </div>
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text icon-size">
                        <i class="fal fa-palette"></i>
                        Color
                      </span>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      aria-label="Color"
                      aria-describedby="basic-addon1"
                      value={item.color}
                      readOnly
                      maxLength={100}
                    />
                  </div>
                  <div className="text-right">
                    <a href="#" style={{ textDecoration: "none" }}>
                      Orders Completed : {item.order_completed}{" "}
                    </a>
                  </div>
                </div>
              );
            })}
        </div>
      </Scrollbars>
    </div>
  );
};

export default VehicleDetails;
const DropdownWrapper = styled.div`
  .css-13cymwt-control {
    background-color: #e8e8e8;
    border-radius: unset;
    min-height: 33.5px;
  }
  .country-code {
    min-width: 100px;
  }
  .country-code {
    > div:nth-of-type(2) {
      min-width: calc(100% + 100px);
      max-width: calc(100% + 100px);
    }
  }
  .css-16xfy0z-control {
    border-radius: unset;
    background: #e8e8e8;
    min-height: 33.5px;
  }
  .css-1fdsijx-ValueContainer {
    padding: 0px 8px;
  }
  .css-t3ipsp-control {
    box-shadow: unset;
    background: #e8e8e8;
    height: 38px;
  }
  .sc-bizigk hHHlt {
    width: 100%;
    max-width: 350px;
  }
`;
const VehicleWrapper = styled.div`
  .form-control {
    background: #e8e8e8;
  }
`;
const VehicleTypeContainer = styled.div`
  .css-13cymwt-control {
    background-color: #e8e8e8;
    width: 100%;
    max-width: 550px;
  }
`;
const Containerdrop = styled.div`
  .css-13cymwt-control {
    width: 100%;
    max-width: 550px;
    background-color: #e8e8e8;
  }
  .info-style {
  }
  .heading-style .sl-style {
    width: 100px !important;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  max-width: 712px;
  .btn-primary {
    width: 100px;
  }
  .btn-secondary {
    width: 100px;
  }
`;
