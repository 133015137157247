import React, { useState } from "react";
import Layout from "../../Layout/Layout";
import ContentHeader from "../../Layout/ContentHeader";
import ContentTitleWithButton from "../components/Layouts/ContentTitleWithButton";
import styled from "styled-components";
import WindowRightLayout from "../components/Layouts/WindowRightLayout";
import OfferForm from "./components/OfferForm";
import OfferListCard from "./components/OfferListCard";

type Props = {};
export interface DiscountType {
  discountTypeId: number;
  name: string;
}

export interface Offer {
  offerId: number;
  serviceAdminId: number;
  code: string;
  name: string;
  description: string;
  discountType: DiscountType;
  discount: number;
  image: string;
  startsOn: string;
  endsOn: string;
  catchPhrase: string;
}

export interface OfferResponse {
  status: boolean;
  data: Offer[];
  message: string;
  imagePath: string;
}
const dummyOffers: OfferResponse = {
  status: true,
  data: [
    {
      offerId: 1,
      serviceAdminId: 1,
      code: "TOFF001",
      name: "Sample Offer",
      description: "Sample Offer Description",
      discountType: {
        discountTypeId: 1,
        name: "Percentage",
      },
      discount: 5,
      image:
        "https://img.freepik.com/free-psd/cyber-monday-super-sale-social-media-banner-post-template_106176-3267.jpg?w=740&t=st=1718177174~exp=1718177774~hmac=a1062dc4aa7f28da1b215c29792293b28051f562c46e977a8e8326c868065a74",
      startsOn: "2024-06-10T00:00:00",
      endsOn: "2024-06-20T00:00:00",
      catchPhrase: "Get 5% off of first time orders.",
    },
    {
      offerId: 2,
      serviceAdminId: 2,
      code: "BOFF002",
      name: "Black Friday Bonanza",
      description: "Enjoy massive discounts this Black Friday.",
      discountType: {
        discountTypeId: 1,
        name: "Percentage",
      },
      discount: 50,
      image:
        "https://img.freepik.com/free-psd/cyber-monday-super-sale-social-media-banner-post-template_106176-3267.jpg?w=740&t=st=1718177174~exp=1718177774~hmac=a1062dc4aa7f28da1b215c29792293b28051f562c46e977a8e8326c868065a74",
      startsOn: "2024-11-25T00:00:00",
      endsOn: "2024-11-30T00:00:00",
      catchPhrase: "Unbelievable 50% off on all items.",
    },
    {
      offerId: 3,
      serviceAdminId: 3,
      code: "COFF003",
      name: "Christmas Special",
      description: "Celebrate Christmas with special discounts.",
      discountType: {
        discountTypeId: 2,
        name: "Flat",
      },
      discount: 100,
      image:
        "https://img.freepik.com/free-psd/cyber-monday-super-sale-social-media-banner-post-template_106176-3267.jpg?w=740&t=st=1718177174~exp=1718177774~hmac=a1062dc4aa7f28da1b215c29792293b28051f562c46e977a8e8326c868065a74",
      startsOn: "2024-12-20T00:00:00",
      endsOn: "2024-12-25T00:00:00",
      catchPhrase: "Flat $100 off on select items.",
    },
    {
      offerId: 4,
      serviceAdminId: 4,
      code: "SOFF004",
      name: "Summer Sale",
      description: "Cool down with our hot summer deals.",
      discountType: {
        discountTypeId: 1,
        name: "Percentage",
      },
      discount: 30,
      image:
        "https://img.freepik.com/free-psd/cyber-monday-super-sale-social-media-banner-post-template_106176-3267.jpg?w=740&t=st=1718177174~exp=1718177774~hmac=a1062dc4aa7f28da1b215c29792293b28051f562c46e977a8e8326c868065a74",
      startsOn: "2024-07-01T00:00:00",
      endsOn: "2024-07-15T00:00:00",
      catchPhrase: "30% off on all summer clothing.",
    },
    {
      offerId: 5,
      serviceAdminId: 5,
      code: "NOFF005",
      name: "New Year Offer",
      description: "Start the new year with amazing discounts.",
      discountType: {
        discountTypeId: 2,
        name: "Flat",
      },
      discount: 50,
      image:
        "https://img.freepik.com/free-psd/cyber-monday-super-sale-social-media-banner-post-template_106176-3267.jpg?w=740&t=st=1718177174~exp=1718177774~hmac=a1062dc4aa7f28da1b215c29792293b28051f562c46e977a8e8326c868065a74",
      startsOn: "2024-12-31T00:00:00",
      endsOn: "2024-01-02T00:00:00",
      catchPhrase: "Flat $50 off on new year gifts.",
    },
  ],
  message: "Success",
  imagePath: "https://via.placeholder.com/",
};
const initialFormData = {
  title: "",
  termsAndConditions: "",
  description: "",
  offerType: null,
  discountType: "",
  discountValue: "",
  percentage: "",
  twoWheeler: "",
  fourWheeler: "",
  image: undefined
};
const SupOfferPage = (props: Props) => {
  const [formData, setFormData] = useState(initialFormData);
  console.log(formData,'abcdformData');
  
  const [prevFormData, setPrevFormData] = useState(initialFormData);
  const [rightView, setRightView] = useState(false);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const [isAddNew, setIsAddNew] = React.useState<boolean>(false);
  const [isRemovedViewImage, setIsRemovedViewImage] =
    React.useState<boolean>(false);

  const handleAddOffers = () => {
    setFormData(initialFormData);
    setIsRemovedViewImage(false);
    setIsAddNew(true);
    setRightView(true);
    setIsEdit(true);
  };
  const handleViewClick = (
    event: React.MouseEvent<HTMLDivElement>,
    offer: any
  ) => {
    setPrevFormData({
      title: offer.name,
      termsAndConditions: "",
      description: offer.description,
      offerType: null,
      discountType:
        offer.discountType.discountTypeId === 1 ? "percentage" : "amount",
      discountValue: "",
      percentage: offer.discountType.discountTypeId === 1 ? offer.discount : "",
      twoWheeler: "",
      fourWheeler: "",
      image: offer.image,
    });
    setFormData({
      title: offer.name,
      termsAndConditions: "",
      description: offer.description,
      offerType: null,
      discountType:
        offer.discountType.discountTypeId === 1 ? "percentage" : "amount",
      discountValue: "",
      percentage: offer.discountType.discountTypeId === 1 ? offer.discount : "",
      twoWheeler: "",
      fourWheeler: "",
      image: offer.image,
    });
    setIsRemovedViewImage(false);
    setIsAddNew(false);
    setRightView(true);
    setIsEdit(false);
  };
  const onEditClick = () => {
    setIsEdit(true);
    setIsAddNew(false);
  };
  const handleBackClick = () => {
    setIsAddNew(false);
    setIsEdit(false);
    setRightView(false);
    setFormData(initialFormData);
  };
  const handleCancelClick = () => {
    if (isAddNew) {
      setFormData(initialFormData);
      setRightView(false);
      setIsAddNew(false);
    } else {
      setIsEdit(false);
      setFormData(prevFormData!);
      setIsRemovedViewImage(false);
    }
  };
  return (
    <>
      <Layout>
        <Wrapper className="content-block">
          <section className="main-section">
            <div className="container-fluid p-0">
              <ContentHeader />
            </div>
            <div className="col-12 p-0">
              <ContentTitleWithButton
                handleAddClick={handleAddOffers}
                buttonTitle={"Add New Offer"}
                heading={"Offers"}
              />
              <div className="content-window">
                <LeftWindowWrapper
                  className={`${rightView ? "right-window-active" : ""}`}
                >
                  <OfferListCard
                    offers={dummyOffers.data}
                    onViewClick={handleViewClick}
                    className="whole-card-wrapper"
                  />
                </LeftWindowWrapper>
                {rightView && (
                  <WindowRightLayout
                    isExpand={rightView}
                    heading={
                      isAddNew
                        ? "Add New Offer"
                        : isEdit
                        ? "Edit Offer"
                        : "View Offer"
                    }
                    backToolTip="Back to offers"
                    handleCloseView={handleBackClick}
                    childrenRight={
                      isEdit ? (
                        <></>
                      ) : (
                        <button
                          onClick={onEditClick}
                          className="btn btn-action btn-edit"
                          style={{
                            color: "#2e7d32",
                            background: "#f1ffe0",
                            border: "1px solid #f1ffe0",
                          }}
                          rel="tooltip"
                          data-toggle="tooltip"
                          data-placement="top"
                          title=""
                          data-original-title="Edit"
                          aria-describedby="tooltip55424"
                        >
                          <i className="fal fa-pen"> </i>
                        </button>
                      )
                    }
                  >
                    <OfferForm
                      isEdit={isEdit}
                      formData={formData}
                      setFormData={setFormData}
                      handleCancelClick={handleCancelClick}
                      setIsRemovedViewImage={setIsRemovedViewImage}
                      isRemovedViewImage={isRemovedViewImage}
                    />
                  </WindowRightLayout>
                )}
              </div>
            </div>
          </section>
        </Wrapper>
      </Layout>
    </>
  );
};

export default SupOfferPage;
const Wrapper = styled.div`
  .content-window {
    display: flex;
    gap: 15px;
  }
  .right-window-active {
    width: unset !important;
    .whole-card-wrapper {
      grid-template-columns: repeat(1, 1fr);
    }
    .offer-card {
      &:hover {
        transform: unset;
      }
    }
  }
`;
const LeftWindowWrapper = styled.div`
  width: 100%;
  overflow: auto;
  height: calc(100vh - 105px);
`;
