import { Button } from "react-bootstrap";
import React, { useState,useContext } from "react";
import ServiceFilterWrapper from "./ServiceFilterWrapper";
import { ApprovalContext } from "../ApprovalContext";

const ServiceContentTitle = (props) => {
  const {
   
    mappedServiceData,
    SetMappedServiceData,
    viewReadOnly,
    SetViewReadOnly,
    editMode,SetEditMode
  } = useContext(ApprovalContext);


  const handleClick = (e) => {
    props.handleAddService(true) 
    SetViewReadOnly(false);
    SetEditMode(false);
    SetMappedServiceData({});
   }
  return (
    <div class="content-title-action">
      <div class="title-action">
        <div class="title-action-left">
        <h5 class="head">Services</h5>  
        </div>  
        <div class="title-action-right d-flex">
          <ServiceFilterWrapper />

          {/* <div class="action-block">
            <button
              class="btn btn-card"
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title="view"
              view-click="service"
            >
              <span>
                <i class="fas fa-table"></i>
              </span>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ServiceContentTitle;
