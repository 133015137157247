import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { login } from "../api/auth/sap/login";
import { loginAPI } from "../../../api/auth/sap/login";

// import "../assets/css/main-admin.css";
import "../../../assets/css/main-admin.css";
import isSAPAuthenticated from "../../../utils/isSAPAuthenticated";
import isSUPAuthenticated from "../../../utils/isSUPAuthenticated";

import LoginForm from "../Login/LoginForm";
import Logo from "./assets/img/motorque-logo.svg";

const Login = () => {
  const [error, setError] = useState("");

  const bg = require("../../../assets/img/main-admin-img/logo/sample.png");
  let navigate = useNavigate();

  const handleClick = (data) => {
    if (isSAPAuthenticated()) {
      navigate("/service");
    }
  };

  const loginAction = async (details) => {
    loginAPI(details)
      .then((res) => {
        handleClick(res);
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  return (
    <div className="Login">
      <section className="">
        <div className="login-admin-block">
          <div className="login-admin-subblock">
            <div className="bg-blur"></div>
            <div className="login-main">
              <div className="login-details">
                <div className="sign-in-form w-100 m-auto">
                  <div className="text-center admin-logo">
                    <img src={Logo} alt="" className="img-fluid" />
                  </div>
                  <h3 className="mb-4 text-center">SAP Sign in</h3>
                  <LoginForm Login={loginAction} error={error} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
