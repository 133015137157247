import React, { Fragment, useContext, useEffect, useState,} from "react";
import InfoTab from "./Innertab/InfoTab";
import BankDetailstab from "./Innertab/BankDetailsTab";
import TimingsTab from "./Innertab/TimingsTab";
import GstTab from "./Innertab/GstTab";
import { ProfileContext } from "./ProfileContext";
import ReactTooltip from "react-tooltip-rc";
import "./Innertab.css";
import LicenseTab from "./Innertab/LicenseTab";
import SocialMediaTab from "./Innertab/SocialMediaTab";
const ProfileWindow = () => {
  const {
    innertab,
    SetinnerTab,
    toggleInnerTab,
    infoviewonly,
    setInfoViewOnly,
    bankviewonly,
    setBankViewOnly,
    timingviewonly,
    setTimingViewOnly,
    licenseviewonly,
    setLicenseViewOnly,
    gstviewonly,
    setGstViewOnly,
    socialviewonly,
    setSocialViewOnly,
    bankformData,
    gstformData,
    profileConfigData,
  } = useContext(ProfileContext);

  const [showEdit, setShowEdit] = useState(false);
  const[pageLoader,setPageLoader] = useState(false);

  const profileEditClickHandler = () => {
    if (innertab === 1) {
      setInfoViewOnly(false)
      setBankViewOnly(true)
      setTimingViewOnly(true)
      setLicenseViewOnly(true)
      setGstViewOnly(true)
      setSocialViewOnly(true)
    
    }
    if (innertab === 2) {
      setBankViewOnly(false)
      setInfoViewOnly(true)
      setTimingViewOnly(true)
      setLicenseViewOnly(true)
      setGstViewOnly(true)
      setSocialViewOnly(true)
   
      
    }
    if (innertab === 3) {
      setTimingViewOnly(false)
      setInfoViewOnly(true)
      setBankViewOnly(true)
      setLicenseViewOnly(true)
      setGstViewOnly(true)
      setSocialViewOnly(true)
   
    }
    if (innertab === 4) {
      setLicenseViewOnly(false)
      setTimingViewOnly(true)
      setInfoViewOnly(true)
      setBankViewOnly(true)
      setGstViewOnly(true)
      setSocialViewOnly(true)
      
    }
    if (innertab === 5) {
      setShowEdit(false);
      setLicenseViewOnly(true)
      setTimingViewOnly(true)
      setInfoViewOnly(true)
      setBankViewOnly(true)
      setSocialViewOnly(true)

    }
    if (innertab === 6) {
      setSocialViewOnly(false)
      setGstViewOnly(true)
      setLicenseViewOnly(true)
      setTimingViewOnly(true)
      setInfoViewOnly(true)
      setBankViewOnly(true)
   
    }
  };

  useEffect(() => {
    if (bankformData?.service_admin_account_id ) {
      setBankViewOnly(true);
    } else {
      setBankViewOnly(false);
    }
  }, [bankformData?.service_admin_account_id, setBankViewOnly]);

  
  useEffect(() => {
    if (gstformData?.service_admin_gst_id ) { 
      setShowEdit(true);
    } else if (gstformData?.service_admin_gst_id === undefined){
      setShowEdit(false)
    }
  }, [gstformData?.service_admin_gst_id]);

  return (
    <Fragment>
     
      <div className="content-window w-100">
        <div className="content-window-right w-100 p-3">
          <div className="content-right-block">
            <div class="card-right">
              <div class="header-block">
                <div class="header-sec">
                  <div className="content-right-top">
                    <a
                      title="Close Window"
                      // onClick={closeStaffViewHandler}
                      class="btn btn-action btn-back link"
                      href="/service"
                      rel="tooltip"
                      data-for="backTooltip"
                      data-tip="Back to services"
                    >
                      <i class="fal fa-long-arrow-left"></i>
                      <ReactTooltip
                        id="backTooltip"
                        place="right"
                        type="info"
                        effect="float"
                      />
                    </a>
                    {/* <h4>{props.viewStaff && mappedStaff.length ? `${mappedStaff[0].name}` : ""}</h4> */}
                    {/* <h2 className="mb-0">{editMode ? "Edit" :
                  viewReadOnly
                   || infoviewonly || bankviewonly || licenseviewonly || gstviewonly || socialviewonly  
                   ? "view" : "Add"}</h2> */}
                  </div>
                  <div className="action-block-right text-end">
                    {timingviewonly && innertab === 3 ? (
                      <button
                        className="btn btn-action btn-edit"
                        rel="tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                        data-for="Edit"
                        data-tip="Edit Details"
                        onClick={profileEditClickHandler}
                      >
                        <i className="fal fa-pen"> </i>
                        <ReactTooltip
                          id="Edit"
                          place="top"
                          type="info"
                          effect="float"
                        />
                      </button>
                    ) : null}
                    {infoviewonly && innertab === 1 ? (
                      <button
                        className="btn btn-action btn-edit"
                        rel="tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                        data-for="Edit"
                        data-tip="Edit Details"
                        onClick={profileEditClickHandler}
                      >
                        <i className="fal fa-pen"> </i>
                        <ReactTooltip
                          id="Edit"
                          place="top"
                          type="info"
                          effect="float"
                        />
                      </button>
                    ) : null}
                    {/* bankviewonly && bankformData.length  || */}
                    {/* Object.keys(bankformData).filter((value) => (bankformData[value] === undefined)) && !bankviewonly || !Object.keys(bankformData).filter((value) => (bankformData[value] === undefined)).length && !bankviewonly ||  !bankviewonly && Object.keys(bankformData).length  */}
                    {/* {bankviewonly && !Object.keys(bankformData).length && innertab == 2 &&<button */}
                    {bankviewonly && innertab === 2 ? (
                      <button
                        className="btn btn-action btn-edit"
                        rel="tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                        data-for="Edit"
                        data-tip="Edit Details"
                        onClick={profileEditClickHandler}
                      >
                        <i className="fal fa-pen"> </i>
                        <ReactTooltip
                          id="Edit"
                          place="top"
                          type="info"
                          effect="float"
                        />
                      </button>
                    ) : (
                      ""
                    )}
                    {licenseviewonly && innertab === 4 ? (
                      <button
                        className="btn btn-action btn-edit"
                        rel="tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                        data-for="Edit"
                        data-tip="Edit Details"
                        onClick={profileEditClickHandler}
                      >
                        <i className="fal fa-pen"> </i>
                        <ReactTooltip
                          id="Edit"
                          place="top"
                          type="info"
                          effect="float"
                        />
                      </button>
                    ) : null}
                    {showEdit && innertab === 5 ? (
                      <button
                        className="btn btn-action btn-edit"
                        rel="tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                        data-for="Edit"
                        data-tip="Edit Details"
                        onClick={profileEditClickHandler}
                      >
                        <i className="fal fa-pen"> </i>
                        <ReactTooltip
                          id="Edit"
                          place="top"
                          type="info"
                          effect="float"
                        />
                      </button>
                    ) : (
                      ""
                    )}
                    {socialviewonly && innertab === 6 ? (
                      <button
                        className="btn btn-action btn-edit"
                        rel="tooltip"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                        data-for="Edit"
                        data-tip="Edit Details"
                        onClick={profileEditClickHandler}
                      >
                        <i className="fal fa-pen"> </i>
                        <ReactTooltip
                          id="Edit"
                          place="top"
                          type="info"
                          effect="float"
                        />
                      </button>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="p-3">
              <div className="inner-tab-main profiletab">
                <div className="inner-tab v-tab d-flex ">
                  <div className="tab-ul">
                    <ul
                      className="nav nav-tabs flex-direction-column"
                      id=""
                      role="tablist"
                    >
                      <li className="nav-item">
                        <a
                          className={
                            innertab === 1 ? " nav-link active" : "nav-link"
                          }
                          id="nav-personal-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-personal"
                          aria-selected="true"
                          onClick={() => toggleInnerTab(1)}
                        >
                          <span>Service Centre Info</span>
                        </a>
                      </li>
                      {/* <li className="nav-item">
                  <a
                    className={innertab === 2 ? " nav-link active" : "nav-link"}
                    id="nav-privileges-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="nav-privileges"
                    aria-selected="false"
                    onClick={() => toggleInnerTab(2)}
                  >
                    <span>Credentials</span>
                  </a>
                </li> */}
                      <li className="nav-item">
                        <a
                          className={
                            innertab === 2 ? " nav-link active" : "nav-link"
                          }
                          id="nav-privileges-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-privileges"
                          aria-selected="false"
                          onClick={() => toggleInnerTab(2)}
                        >
                          <span>Bank Details</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            innertab === 3 ? " nav-link active" : "nav-link"
                          }
                          id="nav-work-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-work"
                          aria-selected="false"
                          onClick={() => toggleInnerTab(3)}
                        >
                          <span>Outlet Timings</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            innertab === 4 ? " nav-link active" : "nav-link"
                          }
                          id="nav-work-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-work"
                          aria-selected="false"
                          onClick={() => toggleInnerTab(4)}
                        >
                          <span>License & Permissions</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            innertab === 5 ? " nav-link active" : "nav-link"
                          }
                          id="nav-work-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-work"
                          aria-selected="false"
                          onClick={() => toggleInnerTab(5)}
                        >
                          <span>{profileConfigData?.tax_type?.title}</span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className={
                            innertab === 6 ? " nav-link active" : "nav-link"
                          }
                          id="nav-work-tab"
                          data-toggle="tab"
                          role="tab"
                          aria-controls="nav-work"
                          aria-selected="false"
                          onClick={() => toggleInnerTab(6)}
                        >
                          <span>Social Media Handles</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="tab-content staff-tab-content w-100" id="">
                    <InfoTab />
                    <BankDetailstab />
                    <TimingsTab />
                    <LicenseTab />
                    <GstTab showEdit={showEdit} setShowEdit={setShowEdit} />
                    <SocialMediaTab />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </Fragment>
  );
};

export default ProfileWindow;
