import React from "react";

function FilterDropdownContent() {
  return (
    <div className="dropdown-menu d-block" aria-labelledby="dropdownMenuButton">
      <a className="dropdown-item" href="">
        All vehivles
      </a>
      <a className="dropdown-item" href="">
        Four wheelers only
      </a>
      <a className="dropdown-item" href="">
        Four wheelers only
      </a>
    </div>
  );
}

export default FilterDropdownContent;
