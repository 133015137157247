import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // to handle onClick functions
  children?: React.ReactNode; // make the component able to receive children elements
  buttonType?: "reject" | "save" | "icon"; // two styling options (you can create as many as you want)
  disabled?: boolean; // make the button disabled or not
  rounded?: boolean; // make the button disabled or not
  type?: "submit" | "button";
  variant?: "contained" | "outlined" | "text";
  color?: "success" | "warning";
  
};

const SupOutlinedButton = ({
  onClick,
  children,
  variant,
  color,
  type,
  rounded,
  buttonType,
}: ButtonProps) => {
  return (
    <Wrapper className={`button-wrapper ${buttonType}`}>
      <Button onClick={onClick} variant={variant} color={color} type={type}>
        {children}
      </Button>
    </Wrapper>
  );
};

export default SupOutlinedButton;
const Wrapper = styled.div`
  padding: 2px 2px;
  width: fit-content;
  max-width: 100%;
  &.button-wrapper {
    .MuiButtonBase-root {
      font-size: 12px;
      font-weight: 600;
      max-height: 40px;
      color: #f67979 !important;
      border: 1px solid #f67979 !important;
      text-transform: uppercase !important;
      letter-spacing: 1px !important;
      background: transparent !important;
      min-width: 150px !important;
      max-width: 180px !important;
      border-radius: 5px !important;
      box-shadow: none !important;
      padding: 10px 16px !important;
      line-height: 1;
      align-items: center;
      -webkit-transition: border-radius 0.25s;
      transition: border-radius 0.25s;
      justify-content: center;
      line-height: 1.75;
      letter-spacing: 0.02857em;
      height: 40px !important;
    }
    &.reject {
      .MuiButtonBase-root {
        background: transparent !important;
        color: #f67979 !important;
        border: 1px solid #f67979 !important;
        &:hover {
          background: #f67979 !important;
          color: #ffffff !important;
        }
      }
    }
    &.save {
      .MuiButtonBase-root {
        background: transparent !important;
        color: #01579b !important;
        border: 1px solid #01579b !important;
        &:hover {
          background: #01579b !important;
          color: #ffffff !important;
        }
      }
    }
  }
`;
