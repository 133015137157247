import { supAxios } from "../../axios";

const urlPrefix = 'approval';

const getVehicleTypes = async () => {
    const axiosInst = supAxios();
    try {
        const res = await axiosInst({
            method: 'GET',
            url: `${urlPrefix}/vehicle-types`,
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
    }
}

const getVehicleVariants = async () => {
    const axiosInst = supAxios();
    try {
        const res = await axiosInst({
            method: 'GET',
            url: `${urlPrefix}/vehicle-variants`,
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
    }
}

const getServiceList = async (data = {}) => {
    const axiosInst = supAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url : `${urlPrefix}/get-service-list`,
            data,
        });
        console.log(res);
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

const getService = async (id = undefined) => {
    const axiosInst = supAxios();
    try {
        const res = await axiosInst({
            method: 'GET',
            url : `${urlPrefix}/get-service/${id}`,
        });
        console.log(res);
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

const approveOrRejectService = async (data = {}) => {
    const axiosInst = supAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url : `${urlPrefix}/approve-or-reject-service`,
            data,
        });
        console.log(res);
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

const getSimilarServicesAPI = async (data = {}) => {
    const axiosInst = supAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url : `${urlPrefix}/get-similar-service-list`,
            data,
        });
        console.log(res);
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

export { getServiceList, getService, approveOrRejectService, getVehicleTypes, getVehicleVariants, getSimilarServicesAPI };