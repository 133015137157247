import React from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import "./assets/css/styles.css";

type MTooltipProps = {
  children: React.ReactElement<any, any>;
  title?: React.ReactNode;
  reasontitle?:string;
  placement?:
    | "bottom"
    | "left"
    | "right"
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
  open?: boolean;
};

const MTooltip = ({ children, title, placement, open,reasontitle }: MTooltipProps) => {
  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className + " custom-tooltip" }}
      arrow
      placement={placement ? placement : "top"}
      className="custom-tooltip"
      open={open}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
    },
  }));
  const TooltipContent = styled("div")({

    color: "red",
    alignItems: "top",
  });
  return (
    <HtmlTooltip
      title={
        <React.Fragment>
          <TooltipContent>{reasontitle}</TooltipContent>
          <Typography color="black">{title}</Typography>
        </React.Fragment>
      }
      children={children}
    ></HtmlTooltip>
  );
};

export default MTooltip;
