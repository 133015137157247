import { AxiosResponse } from "axios";
// import { SaveChangesModel } from "../../../components/SuperAdmin/Pages/vendors/model/SaveChangesModel";
import { supAxios, vapAxios } from "../../axios";
import { SaveChangesModel } from "../../../components/SuperAdmin/Pages/vendors/model/SaveChangesModel";

// this is a dummy end point for vap
const getVendorList = async (params?: number | null) => {
  console.log("paramsss",params)
  const axiosInst = supAxios(); //sup Axios change it to
  const url = `/vendor/vendor-list/${params}`; // url
  try {
    const res = await axiosInst({
      method: "GET",
      url,
    });
    console.log(res);
    if (res.status) {
      return res.data;
    }
    throw new Error(res.statusText);
  } catch (err) {
    console.log(err);
    return err;
  }
};


/**
 * API to get Vendor details
 * @param vendorId
 * @returns  Vendor details Success
 * @throws Error
 */
const getVendorDetails = async (vendorId?: number) => {
  const axiosInst = supAxios();
  const url = `vendor/details/${vendorId}`; // url vendor/details/:vendor_id
  try {
    const res = await axiosInst({
      method: "GET",
      url,
    });
    console.log(res);
    if (res.status) {
      return res.data;
    }
    throw new Error(res.statusText);
  } catch (err) {
    console.log(err);
    return err;
  }
};

/**
 * API to get Vendor Document details
 * @param vendorId
 * @returns  Vendor details Success
 * @throws Error
 */
const getVendorDocumentDetails = async (vendorId?: number) => {
  const axiosInst = supAxios();
  const url = `vendor/document-details/${vendorId}`; // url vendor/details/:vendor_id
  try {
    const res = await axiosInst({
      method: "GET",
      url,
    });
    console.log(res);
    if (res.status) {
      return res.data;
    }
    throw new Error(res.statusText);
  } catch (err) {
    console.log(err);
    return err;
  }
};
/**
 * API to get Vendor Verify details
 * @param vendorId
 * @returns  Vendor verify details Success
 * @throws Error
 */
const VendorVerifyDetails = async (data: {
  vendor_id: number;
  status: number;
}) => {
  const axiosInst = supAxios();
  const url = "vendor/vendor-status-change"; // url vendor/verify details/:vendor_id
  try {
    const res = await axiosInst({
      method: "POST",
      url,
      data,
    });
    console.log(res);
    if (res.status) {
      return res.data;
    }
    throw new Error(res.statusText);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const VendorSaveChangesButton = async (
  data?: any
): Promise<SaveChangesModel[]> => {
  const axiosInst = supAxios();
  const url = "vendor/document-save-changes";
  try {
    const res: AxiosResponse<SaveChangesModel[]> = await axiosInst({
      method: "POST",
      url,
      data,
    });
    if (res?.status) {
      return Promise.resolve(res.data);
    }
    throw new Error(data?.vendor_id);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

export {
  getVendorList,
  getVendorDetails,
  getVendorDocumentDetails,
  VendorVerifyDetails,
  VendorSaveChangesButton,
};
