import React from "react";
import styled from "styled-components";

import Grid from "@mui/material/Grid";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import SimpleReactValidator from "simple-react-validator";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { MuiStyledTextField } from "../../../../../../MuiStyledTextField";
import PhoneNumberField from "../../../../../../PhoneNumberField";
import { FileUploadButton } from "../../../../../../FileUploadButton";
import RoundedWrapperCard from "../../../../../../RoundedWrapperCard";

type Props = {
  isCoOwnerAdd?: boolean;
  data?: ownerDataType;
  index?: number;
  CoOwnerData?: {} | null;
  setCoOwnerData: React.Dispatch<React.SetStateAction<{} | null | undefined>>;
  setCoOwnerList: React.Dispatch<React.SetStateAction<ownerDataType[]>>;
  coOwnerList: ownerDataType[];
  setIsCoOwnersValid?: React.Dispatch<React.SetStateAction<boolean>>;
  submitClick?: boolean;
  isSubmit?: boolean;
  onRemoveClick?: (index: number) => void;
  isVisaUpload?: boolean;
  isNationalIdUpload?: boolean;
  isPassportUpload?: boolean;
};
type ownerDataType = {
  user_type?: number;
  name?: string;
  resident_of_uae?: string;
  phone?: number | null;
  national_ID?: File | null;
  passport?: File | null;
  visa?: File | null;
};

const CoOwnerComponent = ({
  data,
  CoOwnerData,
  coOwnerList,
  setCoOwnerData,
  index,
  setCoOwnerList,
  submitClick,
  isSubmit,
  onRemoveClick,
  isVisaUpload,
  isNationalIdUpload,
  isPassportUpload,
}: Props) => {
  const [value, setValue] = React.useState(" ");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);

    let prevdata: ownerDataType[] =
      coOwnerList &&
      coOwnerList!?.map((cowner: ownerDataType, i: number) => {
        if (i === index) {
          return { ...cowner, resident_of_uae: event.target.value };
        }
        return cowner;
      });
    setCoOwnerList(prevdata);
  };
  console.log(coOwnerList, "co-list");
  const simpleValidator = React.useRef(new SimpleReactValidator());
  // console.log(value, "valuesss")
  const handleNationalIdUpload = (file: File) => {
    console.log("Selected file:", file);
    //  setCoOwnerList({ ...coOwnerList, national_ID: file })
    let prevdata: ownerDataType[] =
      coOwnerList &&
      coOwnerList!?.map((cowner: ownerDataType, i: number) => {
        if (i === index) {
          return { ...cowner, national_ID: file };
        }
        return cowner;
      });
    setCoOwnerList(prevdata);
  };
  const handlePassportUpload = (file: File) => {
    console.log("Selected file:", file);
    let prevdata: ownerDataType[] =
      coOwnerList &&
      coOwnerList!?.map((cowner: ownerDataType, i: number) => {
        if (i === index) {
          return { ...cowner, passport: file };
        }
        return cowner;
      });
    setCoOwnerList(prevdata);
  };
  const handlevisaUpload = (file: File) => {
    console.log("Selected file:", file);
    let prevdata: ownerDataType[] =
      coOwnerList &&
      coOwnerList!?.map((cowner: ownerDataType, i: number) => {
        if (i === index) {
          return { ...cowner, visa: file };
        }
        return cowner;
      });
    setCoOwnerList(prevdata);
  };
  React.useEffect(() => {
    if (data) {
      setCoOwnerData(data);
    }
  }, [data]);
  console.log(CoOwnerData, "CoOwnerList");
  console.log(data, "data");
  const handleChangeName = (e: any) => {
    let prevdata: ownerDataType[] =
      coOwnerList &&
      coOwnerList!?.map((cowner: ownerDataType, i: number) => {
        if (i === index) {
          return { ...cowner, name: e.target.value };
        }
        return cowner;
      });
    setCoOwnerList(prevdata);
  };
  const handleChangePhone = (e: any) => {
    let prevdata: ownerDataType[] =
      coOwnerList &&
      coOwnerList!?.map((cowner: ownerDataType, i: number) => {
        if (i === index) {
          return { ...cowner, phone: e };
        }
        return cowner;
      });
    setCoOwnerList(prevdata);
  };

  React.useEffect(() => {
    if (isSubmit! === true) {
      // debugger;
      simpleValidator.current.showMessages();
      console.log(
        simpleValidator.current,
        "simpleValidator.current.showMessages()"
      );
    }
  }, [isSubmit]);
  return (
    <MainWrapper>
      <RoundedWrapperCardStyled
        className="RoundedWrapperCard"
        style={{ marginBottom: "30px" }}
      >
        <CloseButton className="btn" onClick={() => onRemoveClick?.(index!)}>
          <CloseRoundedIcon />
        </CloseButton>
        <StyledGridWrapper container spacing={2}>
          <Grid item xs={6}>
            <MuiStyledTextField
              label="Co-Owner Name"
              placeholder="Enter your name"
              className="background-gray"
              // onChange={(e) => {
              //   if (CoOwnerData)
              //     setCoOwnerData({ ...CoOwnerData, name: e.target.value });
              // }}
              onChange={handleChangeName}
              value={data?.name}
              validations={"required"}
              validator={simpleValidator.current}
              name="Co-Owner Name"
            />
          </Grid>
          <Grid item xs={6} className="styled-PhoneNumberField">
            <PhoneNumberField
              label="Co-Owner Contact Number"
              placeholder="Enter your contact number"
              onChange={handleChangePhone}
              value={data?.phone?.toString()}
              validator={simpleValidator.current}
              name="Co-Owner Contact Number"
              validations="required"
            />
          </Grid>
          <Grid item xs={6}>
            <RadioGroupWrapper>
              <FormControl>
                <FieldLabel>
                  Are you a resident of United Arab Emirates (UAE)?{" "}
                  <span className="validField">*</span>
                </FieldLabel>
                <RadioButtonWrapper className="RadioButtonWrapper">
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={value}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label="Yes, I am a resident of UAE"
                    />
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label="No, I do not reside in UAE"
                    />
                  </RadioGroup>
                </RadioButtonWrapper>
              </FormControl>
            </RadioGroupWrapper>
          </Grid>

          <Grid item xs={6}>
            <FieldLabel>
              Upload your <span>Emirates National ID </span>(Applicable only if
              you are a resident of UAE)
            </FieldLabel>
            <UploadFieldWrapper>
              <FileUploadButton onFileUpload={handleNationalIdUpload} />

              {submitClick && value === "1" && !isNationalIdUpload && (
                <span className="validation-error">
                  Please upload a valid Document
                </span>
              )}
            </UploadFieldWrapper>
          </Grid>
          <Grid item xs={6}>
            <FieldLabel>
              Upload a copy of <span>your passport </span>for documentation
              purposes
            </FieldLabel>
            <UploadFieldWrapper>
              <FileUploadButton onFileUpload={handlePassportUpload} />
              {submitClick && value === "1" && !isPassportUpload && (
                <span className="validation-error">
                  Please upload a valid Document
                </span>
              )}
            </UploadFieldWrapper>
          </Grid>
          <Grid item xs={6}>
            <FieldLabel>
              Upload a copy of<span> your visa</span> for documentation purposes
            </FieldLabel>
            <UploadFieldWrapper>
              <FileUploadButton onFileUpload={handlevisaUpload} />

              {submitClick && value === "1" && !isVisaUpload && (
                <span className="validation-error">
                  Please upload a valid Document
                </span>
              )}
            </UploadFieldWrapper>
          </Grid>
        </StyledGridWrapper>
      </RoundedWrapperCardStyled>
    </MainWrapper>
  );
};
export default CoOwnerComponent;
const MainWrapper = styled.div``;

const RoundedWrapperCardStyled = styled(RoundedWrapperCard)`
  position: relative;
`;
const CloseButton = styled.button`
  position: absolute;
  top: -12px;
  right: -9px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #0083ff !important;
  font-weight: lighter;
  border: none;
  width: 25px;
  height: 25px;
  background: #e5f0ff !important ;
  border-radius: 20px !important;
  padding: 0px !important;
  font-size: 18px !important;
  .MuiSvgIcon-root {
    font-size: 17px !important;
    margin-top: -4px !important;
  }
`;

const StyledGridWrapper = styled(Grid)`
  /* display: "block"; */
  .styled-PhoneNumberField {
    .FieldWrapper {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73;
      input {
        background: #e8e8e8 !important;
      }
    }
  }
  .textfield-wrapper {
    max-width: 100% !important;
  }
  .background-gray {
    div {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73 !important;
    }
    .react-tel-input {
      background: #e8e8e8;
    }
  }
`;
const RadioGroupWrapper = styled.div`
  display: "block";
  text-align: left;
`;
const RadioButtonWrapper = styled.div`
  padding: 0 20px;
  .MuiFormControlLabel-label {
    font-size: 12px;
    font-weight: 600;
  }
  label {
    margin-bottom: 0;
  }
  .MuiButtonBase-root {
    padding: 3px 5px 3px 0;
  }
`;
const FieldLabel = styled.div`
  text-align: left;
  margin: 0 0 10px 0;
  color: #a09d9d;
  font-size: 14px;
  span {
    font-weight: 600;
    color: #000000;
  }
  .validField {
    color: #ff0000;
  }
`;
const UploadFieldWrapper = styled.div`
  padding: 5px 15px;
  color: #e91344 !important;
  text-align: left;
  font-size: 13px;
`;
