import React, { Fragment } from 'react'
import { Modal } from 'react-bootstrap'
import exitConfirmation from "../../../../../assets/img/icon/upload/file-backup.svg";

const AddCancelConfirmModal = (props) => {
  return (
    <Fragment>
      
    <Modal className='custom-modal' show={props.addstaff ? props.cancelConfirmModal : props.editstaff ? props.cancelConfirmModal:""} onHide={props.closeAddCancelHandler} centerd>
    <Modal.Header closeButton>
          {/* <Modal.Title>WARNING ....</Modal.Title> */}
        </Modal.Header>
      <Modal.Body>
      <div className="modal-info-img">
  <img src={exitConfirmation} className="file-exit" alt="" />
</div>
  <h5>Are you sure you want to exit?</h5>
  {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet ullam voluptatem quam pariatur?</p> */}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-light" onClick={props.closeconfirm}>
          Close
        </button>
        {props.addstaff && <button className="btn btn-danger" onClick={props.confirmCancelModal}>
          Confirm
        </button>}
        {props.editstaff && <button className="btn btn-danger" onClick={props.confirmCancelModal}>
          Confirm
        </button>}
      </Modal.Footer>
    </Modal>
  </Fragment>
  )
}

export default AddCancelConfirmModal