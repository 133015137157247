export const Validation = (values, isOnChange, field) => {
  const errors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const regWebsite = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/;

  if (isOnChange) {
    if (field === "name") {
      if (!values.name) {
        errors.name = "Name is required.";
      }
    } else if (field === "owner_name") {
      if (!values.owner_name) {
        errors.owner_name = "Owner Name is required.";
      }
    } else if (field === "phone_number") {
      if (!values.phone_number) {
        errors.phone_number = "Phone Number is required.";
      } else if (values.phone_number.length !== 10) {
        errors.phone_number = "Please enter a valid phone number.";
      }
    } else if (field === "password") {
      if (!values.password) {
        errors.password = "Password is required.";
      } else if (values.password.length <= 6) {
        errors.password = "Password must be more than 6 characters.";
      }
    } else if (field === "confirm_password") {
      if (!values.confirm_password) {
        errors.confirm_password = "Please confirm the password.";
      } else if (values.confirm_password !== values.password) {
        errors.confirm_password = "Passwords do not match, please verify.";
      }
    } else if (field === "address") {
      if (!values.address) {
        errors.address = "Address is required.";
      }
    } else if (field === "email") {
      if (!values.email) {
        errors.email = "Email is required.";
      } else if (!emailRegex.test(values.email)) {
        errors.email = "This is not a valid email format!";
      }
    } else  if (field === "google_location_url" && values.google_location_url) {
      if (!regWebsite.test(values.google_location_url)) {
        errors.google_location_url = "Enter valid URL.";
      }
    }
  } else {
    // Name
    if (!values.name) {
      errors.name = "Name is required.";
    }

    // Owner Name
    if (!values.owner_name) {
      errors.owner_name = "Owner Name is required.";
    }

    // Email
    if (!values.email) {
      errors.email = "Email is required.";
    } else if (!emailRegex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }

    // Password
    if (!values.password) {
      errors.password = "Password is required.";
    } else if (values.password.length <= 6) {
      errors.password = "Password must be more than 6 characters.";
    }

    // Phone Number
    if (!values.phone_number) {
      errors.phone_number = "Phone Number is required.";
    } else if (values.phone_number.length !== 10) {
      errors.phone_number = "Please enter a valid phone number.";
    }

    // Confirm Password
    if (!values.confirm_password) {
      errors.confirm_password = "Please confirm the password.";
    } else if (values.confirm_password !== values.password) {
      errors.confirm_password = "Passwords do not match, please verify.";
    }

    // Address
    if (!values.address) {
      errors.address = "Address is required.";
    }

    // Google Location
    if (values && values.google_location_url && !regWebsite.test(values.google_location_url)) {
      errors.google_location_url = "Enter valid URL.";
    } else {
      delete errors.google_location_url;
    }
  }

  return errors;
};
