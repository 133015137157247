import React, { Fragment, useContext } from "react";
import { Modal } from "react-bootstrap";
import { OrdersContext } from "../OrdersContext";
import { toast } from "react-toastify";
import deleteFile from "../../../../../assets/img/icon/upload/recycle-bin.svg";
const OrderDeleteModal = (props) => {
  const {
    orderTable,
    handleOrderDelete,
  } = useContext(OrdersContext);

  const deleteConfirm = () => {
    const obj = orderTable.filter(
      (tValue) => tValue.order_id === props.order_id
    );

    handleOrderDelete(obj[0].order_id);
    props.setDeleteModal(false);
    toast.error("Staff Deleted Successfully", {
      position: "bottom-right",
    });
  };

  return (
    <Fragment>
      <Modal
        className="custom-modal"
        show={props.deleteModal}
        onHide={props.handleDeleteCancel}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-info-img">
            <img src={deleteFile} className="delete-file" alt="" />
          </div>
          <h5>{`Are you sure you want to delete ${props.customer_name != undefined ? props.customer_name : ""}?`}</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet ullam
            voluptatem quam pariatur?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-light" onClick={props.handleDeleteCancel}>
            Close
          </button>
          <button className="btn btn-danger" onClick={deleteConfirm}>
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default OrderDeleteModal;
