import styled from "styled-components";
import React, { useState } from "react";

export default function AddAndMinusButton() {
  const [count, setCount] = useState(0);

  function incrementCount() {
    setCount((prevCount) => prevCount + 1);
  }

  function decrementCount() {
    setCount((prevCount) => prevCount - 1);
  }

  return (
    <Styledbutton className="App">
      <button className="add-button" onClick={incrementCount}>
        +
      </button>

      <div className="number">{count}</div>
      <button className="minus-button" onClick={decrementCount}>
        -
      </button>
    </Styledbutton>
  );
}

const Styledbutton = styled.div`
  .add-button {
    border: aliceblue;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    background-color: white;
  }
  .minus-button {
    border: aliceblue;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    background-color: white;
  }
  &.App {
    display: flex;
  }
  .number {
    background-color: white;
  }
`;
