import React, { Fragment } from "react";
import ProductFilterWrapper from "../ProductFilterWrapper";
import { GoToShopButton } from "../../../../GoToShopButton";
import styled from "styled-components";

type Props = {
  gridViewClick?: () => void;
  productAddClick?: () => void;
  showGrid: boolean;
};

const ProductContentTitle = ({
  gridViewClick,
  showGrid,
  productAddClick,
}: Props) => {
  return (
    <Wrapper className="content-title-action">
      <div className="title-action vendor">
        <div className="title-action-left">
          <h5 className="head">Inventory</h5>
          <div className="action-block">
            <button
              className="btn add-new"
              id="addBtn"
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title="Add New"
              onClick={productAddClick}
            >
              <span>
                <i className="fal fa-plus"></i> Add Inventory
              </span>
            </button>
            <button
              className="btn update-stock"
              id="updateStock"
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title="Update Stock"
            >
              <span>
                <i ></i> Update Stock
              </span>
            </button>

            <GoToShopButton />
          </div>
        </div>
        <div className="title-action-right d-flex">
          <ProductFilterWrapper
            gridViewClick={gridViewClick}
            showGrid={showGrid}
          />
        </div>
      </div>
    </Wrapper>
  );
};

export default ProductContentTitle;
const Wrapper = styled.div`
.action-block{
  gap: 10px !important;
}
  .update-stock {
    color: rgb(0, 131, 255) !important;
    border-color: rgb(0, 131, 255) !important;
    padding: 5px 20px;
    :hover{
      background-color: rgb(0, 131, 255) !important;
      color: #ffffff !important;

    }
  }
`;
