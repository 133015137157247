// import {ContentHeader,FilterWrapper,ContentTitle,WindowLeft,WindowRight,InnerLoader} from './Ui';
import Layout from "../../Layout/Layout";
import ContentHeader from "../../../ServiceAdmin/Layout/Ui/ContentHeader";

import ServiceContentTitle from "./Ui/ServiceContentTitle";
import WindowLeft from "./ServiceWindowLeft";
import WindowRight from "./ServiceWindowRight";
import InnerLoader from "./Ui/ServiceInnerLoader";
import React, { useState, useEffect, Fragment } from "react";
import { ServiceProvider } from "./ServiceContext";
import ConfirmModal from "../Service/Ui/ConfirmModal";
import mainLoader from "../../../../assets/img/loader/loader.gif";

const Service = (props) => {
  const isAuthenticated = localStorage.getItem("isSAPAuthenticated");

  //Add Service Click
  const [addService, setAddService] = useState(false);
  const handleAddService = () => {
    setAddService(true);
  };
  let toggleClassCheck = addService ? " change" : "";

  //Delete Popup State
  const [confirmModal, SetConfirmModal] = useState(false);
  const [pageloader, SetPageLoader] = useState(false);
  // const [viewservice, SetViewService] = useState(false);
  // const handleViewService = () => SetViewService(!viewservice);
  // let toggleCheck = viewservice ? " change" : "";
  //Use Effect Loader
  useEffect(() => {
    SetPageLoader(true);
    const loaderTime = setTimeout(() => {
      SetPageLoader(false);
    }, 1500);
    return () => {
      clearTimeout(loaderTime);
    };
  }, []);
  return (
    <Fragment>
      {pageloader ? (
        <div className="loader-block">
          <img className="img-fluid" src={mainLoader} alt="" />
        </div>
      ) : (
        <Layout>
          <div className="content-block">
            <InnerLoader />
            <section className="main-section">
              <div className="container-fluid p-0">
                <ContentHeader />
                <div className="tab-content" id="nav-tabContent">
                  {/* Tab 1 */}
                  <div
                    className="tab-pane fade show active"
                    id="nav-services"
                    role="tabpanel"
                    aria-labelledby="nav-home-tab"
                  >
                    <div className="col-12 p-0">
                      {/* <FilterWrapper /> */}
                      <ServiceProvider>
                        <ServiceContentTitle
                          handleAddService={handleAddService}
                        />
                        {/* <FilterWrapper /> */}
                        <div className="content-section" view-content="service">
                          <div
                            className={`content-window ${toggleClassCheck} `}
                          >
                            <WindowLeft serviceformClose={setAddService} />
                            {addService && (
                              <WindowRight
                                addservice={addService}
                                serviceformClose={setAddService}
                              />
                            )}
                          </div>
                        </div>
                        {/* Delete Popup*/}
                        {confirmModal && (
                          <ConfirmModal
                            setConfirmModal={SetConfirmModal}
                            // id={mappedServiceData.id}
                            serviceFormClose={props.serviceformClose}
                          />
                        )}
                      </ServiceProvider>
                    </div>
                  </div>
                  {/* Tab 2 */}
                  <div
                    className="tab-pane fade"
                    id="nav-packages"
                    role="tabpanel"
                    aria-labelledby="nav-profile-tab"
                  >
                    <div className="col-12 p-0">
                      <div className="col-12 p-0 filter-wrapper">
                        <div className="search-sort-filter">
                          <div className="pr-3 d-flex header-action-block">
                            <form action="" method="">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span className="input-group-text search-span-txt">
                                    <i className="far fa-search"></i>
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="search-id"
                                  placeholder="Search"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                />
                              </div>
                            </form>
                            <div className="sort-filter">
                              <div className="dropdown sort-custom-dropdown">
                                <div
                                  className="sort-block dropdown"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  rel="tooltip"
                                  data-placement="left"
                                  title="Sort"
                                >
                                  <span className="service-sort-btn sort-btn">
                                    Sort <i className="far fa-sort"></i>
                                  </span>
                                </div>
                                <div className="dropdown-menu sort-dropdown">
                                  <a className="dropdown-item" href="">
                                    Recents
                                  </a>
                                  <a className="dropdown-item" href="">
                                    Name
                                  </a>
                                  <a className="dropdown-item" href="">
                                    Price low
                                  </a>
                                  <a className="dropdown-item" href="">
                                    Price high
                                  </a>
                                  <a className="dropdown-item" href="">
                                    oldest
                                  </a>
                                </div>
                              </div>
                              <div className="dropdown filter-custom-dropdown">
                                <div
                                  className="filter-block dropdown"
                                  id="servicefilterdropdown"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                  rel="tooltip"
                                  data-placement="left"
                                  title="Filter"
                                >
                                  <span className="filter-btn">
                                    Filter<i className="far fa-filter"></i>
                                  </span>
                                </div>
                                <div
                                  className="dropdown-menu filter-dropdown"
                                  aria-labelledby="servicefilterdropdown"
                                >
                                  <a className="dropdown-item" href="">
                                    All vehivles
                                  </a>
                                  <a className="dropdown-item" href="">
                                    Four wheelers only
                                  </a>
                                  <a className="dropdown-item" href="">
                                    Four wheelers only
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="content-title-action">
                        <div className="title-action">
                          <div className="title-action-left">
                            <h5 className="head">Offers & Discounts</h5>
                            <div className="action-block">
                              <button
                                className="btn add-new"
                                id="addBtn"
                                rel="tooltip"
                                data-toggle="tooltip"
                                data-placement="left"
                                title="Add New"
                              >
                                <span>
                                  <i className="fal fa-plus"></i> Add New Coupon
                                </span>
                              </button>
                            </div>
                          </div>
                          <div className="title-action-right">
                            <div className="action-block">
                              <button
                                className="btn btn-card"
                                rel="tooltip"
                                data-toggle="tooltip"
                                data-placement="left"
                                title="view"
                                view-click="discounts"
                              >
                                <span>
                                  <i className="fas fa-table"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="content-section" view-content="discounts">
                        <div className="content-window">
                          <div className="content-window-left">
                            <div className="content-table-block">
                              <div className="content-table table-responsive">
                                <table className="table bg-white custom-table clickable-table">
                                  <thead>
                                    <tr>
                                      <th scope="col">No.</th>
                                      <th scope="col">Title</th>
                                      <th scope="col">Coupon Code</th>
                                      <th scope="col">Discount</th>
                                      <th scope="col">Valid Till</th>
                                      <th scope="col">Total Coupons</th>
                                      <th scope="col">Remaining</th>
                                      <th scope="col">Used</th>
                                      <th scope="col">Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td className="none-td">1</td>
                                      <td className="none-td">Black cars</td>
                                      <td>BLACKC2058</td>
                                      <td>10 % OFF</td>
                                      <td className="none-td">
                                        2/03/22 - 30/03/22
                                      </td>
                                      <td className="none-td">50</td>
                                      <td className="none-td">20</td>
                                      <td className="none-td">30</td>
                                      <td>
                                        <span className="active-coupons">
                                          Active
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="none-td">2</td>
                                      <td className="none-td">Black cars</td>
                                      <td>BLACKC2058</td>
                                      <td>10 % OFF</td>
                                      <td className="none-td">
                                        2/03/22 - 30/03/22
                                      </td>
                                      <td className="none-td">50</td>
                                      <td className="none-td">20</td>
                                      <td className="none-td">30</td>
                                      <td>
                                        <span className="active-coupons">
                                          Active
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="none-td">2</td>
                                      <td className="none-td">Black cars</td>
                                      <td>BLACKC2058</td>
                                      <td>10 % OFF</td>
                                      <td className="none-td">
                                        2/03/22 - 30/03/22
                                      </td>
                                      <td className="none-td">50</td>
                                      <td className="none-td">20</td>
                                      <td className="none-td">30</td>
                                      <td>
                                        <span className="active-coupons">
                                          Active
                                        </span>
                                      </td>
                                    </tr>
                                    <tr className="expired-coupon">
                                      <td className="none-td">2</td>
                                      <td className="none-td">Black cars</td>
                                      <td>BLACKC2058</td>
                                      <td>10 % OFF</td>
                                      <td className="none-td">
                                        2/03/22 - 30/03/22
                                      </td>
                                      <td className="none-td">50</td>
                                      <td className="none-td">20</td>
                                      <td className="none-td">30</td>
                                      <td>
                                        <span className="expired-coupons">
                                          Expired
                                        </span>
                                      </td>
                                    </tr>
                                    <tr className="expired-coupon">
                                      <td className="none-td">2</td>
                                      <td className="none-td">Black cars</td>
                                      <td>BLACKC2058</td>
                                      <td>10 % OFF</td>
                                      <td className="none-td">
                                        2/03/22 - 30/03/22
                                      </td>
                                      <td className="none-td">50</td>
                                      <td className="none-td">20</td>
                                      <td className="none-td">30</td>
                                      <td>
                                        <span className="expired-coupons">
                                          Expired
                                        </span>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td className="none-td">2</td>
                                      <td className="none-td">Black cars</td>
                                      <td>BLACKC2058</td>
                                      <td>10 % OFF</td>
                                      <td className="none-td">
                                        2/03/22 - 30/03/22
                                      </td>
                                      <td className="none-td">50</td>
                                      <td className="none-td">20</td>
                                      <td className="none-td">30</td>
                                      <td>
                                        <span className="active-coupons">
                                          Active
                                        </span>
                                      </td>
                                    </tr>
                                    <tr className="expired-coupon">
                                      <td className="none-td">2</td>
                                      <td className="none-td">Black cars</td>
                                      <td>BLACKC2058</td>
                                      <td>10 % OFF</td>
                                      <td className="none-td">
                                        2/03/22 - 30/03/22
                                      </td>
                                      <td className="none-td">50</td>
                                      <td className="none-td">20</td>
                                      <td className="none-td">30</td>
                                      <td>
                                        <span className="expired-coupons">
                                          Expired
                                        </span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div className="content-card-block">
                              <div className="content-card">
                                <div className="row m-0">
                                  <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-card">
                                    <div className="card card-box">
                                      <div className="img-box">
                                        <img
                                          className="card-img-top card-box-img"
                                          src="assets/img/vehichle/5.jpg"
                                          alt="Card-imagecap"
                                        />
                                      </div>
                                      <div className="card-body card-box-body">
                                        <div className="card-txt">
                                          <div className="card-title-block">
                                            <h5>Foam Wash</h5>
                                            <span className="status badge badge-custom-success">
                                              Active
                                            </span>
                                          </div>
                                          <p className="card-text">
                                            Some quick example text to build on
                                            the card tt.
                                          </p>
                                          <div className="card-footer-box pt-2">
                                            <div className="card-table">
                                              <table className="table small-striped">
                                                <tbody>
                                                  <tr>
                                                    <td>Discount</td>
                                                    <td>10% OFF</td>
                                                  </tr>
                                                  <tr>
                                                    <td>VALID TILL</td>
                                                    <td>2/03/22 - 30/03/22</td>
                                                  </tr>
                                                  <tr>
                                                    <td>TOTAL COUPONS</td>
                                                    <td>50</td>
                                                  </tr>
                                                  <tr>
                                                    <td>REMAINING</td>
                                                    <td>20</td>
                                                  </tr>
                                                  <tr>
                                                    <td>Status</td>
                                                    <td className="text-success">
                                                      <i className="fa fa-circle size-10"></i>{" "}
                                                      Active
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Status</td>
                                                    <td className="text-danger">
                                                      <i className="fa fa-circle size-10"></i>{" "}
                                                      Expired
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-md-6 col-lg-4 col-xl-3 col-card">
                                    <div className="card card-box">
                                      <div className="img-box">
                                        <img
                                          className="card-img-top card-box-img"
                                          src="assets/img/vehichle/5.jpg"
                                          alt="Card-imagecap"
                                        />
                                      </div>
                                      <div className="card-body card-box-body">
                                        <div className="card-txt">
                                          <div className="card-title-block">
                                            <h5>Foam Wash</h5>
                                            <span className="status badge badge-custom-danger">
                                              Expired
                                            </span>
                                          </div>
                                          <p className="card-text">
                                            Some quick example text to build on
                                            the card tt.
                                          </p>
                                          <div className="card-footer-box pt-2">
                                            <div className="card-table">
                                              <table className="table small-striped">
                                                <tbody>
                                                  <tr>
                                                    <td>Discount</td>
                                                    <td>10% OFF</td>
                                                  </tr>
                                                  <tr>
                                                    <td>VALID TILL</td>
                                                    <td>2/03/22 - 30/03/22</td>
                                                  </tr>
                                                  <tr>
                                                    <td>TOTAL COUPONS</td>
                                                    <td>50</td>
                                                  </tr>
                                                  <tr>
                                                    <td>REMAINING</td>
                                                    <td>20</td>
                                                  </tr>
                                                  <tr>
                                                    <td>Status</td>
                                                    <td className="text-success">
                                                      <i className="fa fa-circle size-10"></i>{" "}
                                                      Active
                                                    </td>
                                                  </tr>
                                                  <tr>
                                                    <td>Status</td>
                                                    <td className="text-danger">
                                                      <i className="fa fa-circle size-10"></i>{" "}
                                                      Expired
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="content-window-right">
                            <div className="content-right-block">
                              <div className="card-right">
                                <div className="header-block">
                                  <div className="header-sec">
                                    <nav aria-label="breadcrumb">
                                      <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                          <a href="#">Service</a>
                                        </li>
                                        <li
                                          className="breadcrumb-item active"
                                          aria-current="page"
                                        >
                                          Add Coupon
                                        </li>
                                      </ol>
                                    </nav>
                                    <div className="action-block-right">
                                      <button
                                        className="btn btn-action btn-publish"
                                        rel="tooltip"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Publish services so that customers can view"
                                      >
                                        <i className="fal fa-arrow-to-top"></i>
                                      </button>
                                      <button
                                        className="btn btn-action btn-edit"
                                        rel="tooltip"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Edit"
                                        aria-describedby="tooltip55424"
                                      >
                                        <i className="fal fa-pen"> </i>
                                      </button>
                                      <button
                                        className="btn btn-action btn-delete"
                                        rel="tooltip"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Delete"
                                      >
                                        <i className="fal fa-trash"></i>
                                      </button>
                                      <button
                                        className="btn btn-action btn-close"
                                        rel="tooltip"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title=""
                                        data-original-title="Close Window"
                                      >
                                        <i className="fal fa-times"></i>
                                      </button>
                                    </div>
                                  </div>
                                  <h2>Service name</h2>
                                </div>
                              </div>
                              <div className="row m-0">
                                <form action="" className="w-100">
                                  <div className="input-group input-group-custom">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="fal fa-file-edit"></i>
                                        Coupon Title
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                  <div className="input-group input-group-custom">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="fal fa-cog"></i>Service
                                        Package
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                  <div className="input-group input-group-custom">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="fal fa-cog"></i>Vehicle
                                        Type
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                  <div className="input-group input-group-custom">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="fal fa-cog"></i>Offer
                                        Description
                                      </span>
                                    </div>
                                    <textarea className="form-control"></textarea>
                                  </div>
                                  <div className="input-group input-group-custom">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="fal fa-cog"></i>Product
                                        Description
                                      </span>
                                    </div>
                                    <textarea className="form-control"></textarea>
                                  </div>
                                  <div className="input-group input-group-custom">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="fal fa-cog"></i>Service
                                        Tags
                                      </span>
                                    </div>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      aria-label="Username"
                                      aria-describedby="basic-addon1"
                                    />
                                  </div>
                                  <div className="input-group input-group-custom">
                                    <div className="input-group-prepend">
                                      <span className="input-group-text">
                                        <i className="fal fa-calendar"></i>Set
                                        Coupon Validity
                                      </span>
                                    </div>
                                    <div
                                      id="reportrange"
                                      className="form-control report-range"
                                    >
                                      <i className="glyphicon glyphicon-calendar fa fa-calendar"></i>
                                      <span></span> <b className="caret"></b>
                                    </div>
                                  </div>
                                  <div className="col-12 text-right p-0">
                                    <button
                                      className="btn btn-update"
                                      type="submit"
                                    >
                                      Update changes
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Tab 3 */}
                  <div
                    className="tab-pane fade"
                    id="nav-insights"
                    role="tabpanel"
                    aria-labelledby="nav-contact-tab"
                  >
                    <h1>Insights</h1>
                  </div>
                </div>
              </div>
              <div
                className="modal fade delete-modal"
                id="deleteModal"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="exampleModalLongTitle"
                data-backdrop="static"
              >
                <div
                  className="modal-dialog modal-dialog-centered delete-service-modal-dialog"
                  role="document"
                >
                  <div className="modal-content">
                    <div className="modal-header">
                      <div className="modal-icon-box">
                        <i className="fal fa-trash"></i>
                      </div>
                    </div>
                    <h4 className="modal-title w-100 text-center">
                      Are you sure?
                    </h4>
                    <div className="modal-body">
                      <p>
                        Do you really want to delete these records? This process
                        cannot be undone.
                      </p>
                    </div>
                    <div className="modal-footer align-items-center justify-content-center">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        data-dismiss="modal"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="btn text-white bg-danger"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default Service;
