import React, { useContext, useState, Fragment, useEffect } from "react";
import { ProfileContext } from "../ProfileContext";
import { Scrollbars } from "react-custom-scrollbars-2";
import moment from "moment";
import {
  createOrUpdateOutletTiming,
  listOutletTimings,
} from "../../../../../api/services/sap/profile-management";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";

const TimingsTab = () => {
  // const [timevalue, setTimeValue] = useState([]);
  const [formsubmit, setFormSubmit] = useState(false);
  const [outLetTime, setOutLetTime] = useState([]);
 const [expandedPanels, setExpandedPanels] = useState({});

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const { innertab, timingviewonly, setTimingViewOnly } =
    useContext(ProfileContext);

  useEffect(() => {
    const fetchOutletTimingData = async () => {
      try {
        const payload = {
          service_admin_id: 1,
          0: {
            is_open: "true",
            timing: [
              { start_time: "10:00", end_time: "02:30" },
              { start_time: "10:00", end_time: "02:30" },
            ],
          },
          1: {
            is_open: "true",
            timing: [{ start_time: "10:00", end_time: "02:30" }],
          },
          2: {
            is_open: "true",
            timing: [{ start_time: "10:00", end_time: "02:30", time_id: 550 }],
          },
          3: {
            is_open: "true",
            timing: [{ start_time: "10:00", end_time: "02:30", time_id: 555 }],
          },
          4: {
            is_open: "true",
            timing: [{ start_time: "10:00", end_time: "02:30", time_id: 551 }],
          },
          5: {
            is_open: "true",
            timing: [{ start_time: "10:00", end_time: "02:30", time_id: 552 }],
          },
          6: {
            is_open: "true",
            timing: [{ start_time: "10:00", end_time: "02:30", time_id: 553 }],
          },
        };
        const res = await createOrUpdateOutletTiming(payload);
        if (res) {
          let newValue = res.map((item) => {
            return {
              ...item,
              timing: [
                { start_time: "10:00", end_time: "02:30" },
                
              ],
            };
          });
          setOutLetTime(newValue);
        } else {
          console.log("Response is empty");
        }
      } catch (err) {
        console.log("Error fetching outlet timing data:", err);
      }
    };
    fetchOutletTimingData();
  }, []);


   const handleAccordionChange = (outletKey) => (event, isExpanded) => {
     setExpandedPanels({
       ...expandedPanels,
       [outletKey]: isExpanded ? outletKey : false,
     });
   };

  // const initialWorkingDays = daysOfWeek.map((day, index) => ({
  //   index,
  //   days: day,
  //   isOpen: false,
  //   startTime: moment(),
  //   endTime: moment(),
  //   textFields: [],
  // }));
  // const [workingDays, setWorkingDays] = useState(initialWorkingDays);

  //preFormSubmitHandler
  const timingsFormSubmitHandler = (e) => {
    e.preventDefault();
    setFormSubmit(true);
    setTimingViewOnly(true);
     setExpandedPanels({});
    // const res =  createOrUpdateOutletTiming(payload);
    // console.log("response", res);
  };

  function getWeekdayName(weekdayNumber) {
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    if (weekdayNumber >= 0 && weekdayNumber <= 6) {
      return weekdays[weekdayNumber];
    } else {
      return "Invalid weekday number";
    }
  }
  //  const handleAddButtonClick = () => {

  //   };

  const handleAddButtonClick = (dayIndex) => {
    if (
      !Array.isArray(outLetTime) ||
      dayIndex < 0 ||
      dayIndex >= outLetTime.length
    )
      return;

    const newTimeKey = `time${
      Object.keys(outLetTime[dayIndex]?.timing || {}).length + 1
    }`;
    const newTimeSlot = [{ start_time: "10:30", end_time: "12:30" }];
    const updatedDay = {
      ...outLetTime[dayIndex],
      timing: {
        ...outLetTime[dayIndex]?.timing,
        [newTimeKey]: newTimeSlot,
      },
    };

    const updatedOutLetTime = [...outLetTime];
    updatedOutLetTime[dayIndex] = updatedDay;
    let newOutLetTime = [...outLetTime];
    console.log(newOutLetTime, "newOutLetTime");
    newOutLetTime[dayIndex]["timing"].push({
      start_time: "",
      end_time: "",
    });
    setOutLetTime(newOutLetTime);
  };

const handleRemoveTimeSlot = (dayIndex, timeKey) => {
  // Ensure outLetTime is an array
  if (!outLetTime || !Array.isArray(outLetTime)) return;

  const updatedDay = { ...(outLetTime[dayIndex] || {}) };
  delete updatedDay.timing[timeKey];

  const remainingTimeSlots = Object.keys(updatedDay.timing || {}).length;

  if (remainingTimeSlots === 0) {
    updatedDay.timing = [{ start_time: "", end_time: "" }];
  }

  const updatedOutLetTime = [...outLetTime];
  updatedOutLetTime[dayIndex] = updatedDay;

  setOutLetTime(updatedOutLetTime);
};


  // const addTextField = (index) => {
  //   const newWorkingDays = [...outLetTime];
  //   newWorkingDays[index].textFields.push({ id: newWorkingDays[index].textFields.length });
  //   setOutLetTime(newWorkingDays);
  // };

  // const removeTextField = (dayIndex, textFieldIndex) => {
  //   const newWorkingDays = [...outLetTime];
  //   newWorkingDays[dayIndex].textFields = newWorkingDays[dayIndex].textFields.filter((textField) => textField.id !== textFieldIndex);
  //   setOutLetTime(newWorkingDays);
  // };

  function formatTime(timeString) {
    const time = new Date(`2000-01-01T${timeString}`);
    return time.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  }
  // const handleTextFieldChange = (e, dayIndex, textFieldIndex, field) => {
  //   const { value } = e.target;
  //   const newWorkingDays = [...outLetTime];
  //   newWorkingDays[dayIndex].textFields[textFieldIndex][field] = value;
  //   setOutLetTime(newWorkingDays);
  // };

  // const handleAccordionChange = (outletKey) => () => {
  //   const updatedOutLetTime = { ...outLetTime };
  //   updatedOutLetTime[outletKey].is_open =
  //     !updatedOutLetTime[outletKey].is_open;
  //   setOutLetTime(updatedOutLetTime);
  // };

  // console.log(updatedOutLetTime, "updatedOutLetTime");
  return (
    <Wrapper
      className={innertab === 3 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div className="main-tab-block">
          <div className="inner-block tab-form-block">
            <Fragment>
              <form className="" onSubmit={timingsFormSubmitHandler}>
                {!timingviewonly && (
                <div className="common-time-container">
                  <div className="checkbox-container">
                    <Checkbox />
                  </div>
                  <h5 className="mb-3 text-start text-info">Set Common Time</h5>
                </div>
                )}
                <div className="form-box">
                  {outLetTime &&
                    Object.keys(outLetTime).map((outletKey, dataIndex1) => {
                      const outlet = outLetTime[outletKey];
                       const isExpanded =
                         expandedPanels[outletKey] === outletKey;
                      return (
                        <Fragment key={outletKey}>
                          <AccordionWrapper>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={outlet.is_open === "true"}
                                  disabled={timingviewonly}
                                  onChange={() => {
                                    const updatedOutLetTime = [...outLetTime];
                                    updatedOutLetTime[outletKey].is_open =
                                      outlet.is_open === "true"
                                        ? "false"
                                        : "true";
                                    setOutLetTime(updatedOutLetTime);
                                  }}
                                />
                              }
                            />
                            <Accordion
                              disabled={timingviewonly}
                              expanded={isExpanded}
                              onChange={handleAccordionChange(outletKey)}
                            >
                              <AccordionSummary
                                aria-controls={`panel${outletKey}-content`}
                                id={`panel${outletKey}-header`}
                              >
                                <div className="typography-wrapper">
                                  <Typography>
                                    {getWeekdayName(outlet.weekday)}
                                  </Typography>

                                  <Typography>
                                    <div className="time-input-show">
                                      {outlet.is_open === "true"
                                        ? Object.values(
                                            outlet.timing || {}
                                          ).map((timeSlot) => {
                                            console.log(timeSlot, "timeSlot");
                                            return (
                                              <div
                                                key={`${timeSlot.start_time}-${timeSlot.end_time}`}
                                              >
                                                {`${formatTime(
                                                  timeSlot.start_time
                                                )} - ${formatTime(
                                                  timeSlot.end_time
                                                )}`}
                                              </div>
                                            );
                                          })
                                        : "Closed"}
                                    </div>
                                  </Typography>
                                </div>
                              </AccordionSummary>
                              {outlet?.timing &&
                                Object.keys(outlet?.timing).map(
                                  (timeKey, index) => {
                                    const time = outlet?.timing;
                                    return (
                                      <AccordionDetails key={timeKey}>
                                        <div className="text-field">
                                          <TextField
                                            label="Open Time"
                                            id={`open-time-${timeKey}`}
                                            value={
                                              outlet?.timing[timeKey]
                                                ?.start_time
                                            }
                                            onChange={(e) => {
                                              console.log(
                                                e,
                                                outletKey,
                                                "outletKey"
                                              );
                                              let newOutlettime = outLetTime;
                                              console.log(
                                                e.target.value,
                                                " e.target.value"
                                              );
                                              newOutlettime[outletKey][
                                                "timing"
                                              ][timeKey]["start_time"] =
                                                e.target.value;
                                              // const updatedTiming = {
                                              //   ...outLetTime?.timing,
                                              //   [timeKey]: {
                                              //     ...time,
                                              //     start_time: e.target.value,
                                              //   },
                                              // };
                                              setOutLetTime(newOutlettime);
                                            }}
                                            type="time"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            inputProps={{
                                              step: 300,
                                            }}
                                          />
                                          <TextField
                                            label="End Time"
                                            id={`close-time-${timeKey}`}
                                            value={
                                              outlet?.timing[timeKey]?.end_time
                                            }
                                            onChange={(e) => {
                                              console.log(
                                                e,
                                                outletKey,
                                                "outletKey"
                                              );
                                              let newOutlettime = outLetTime;
                                              console.log(
                                                e.target.value,
                                                " e.target.value"
                                              );
                                              newOutlettime[outletKey][
                                                "timing"
                                              ][timeKey]["end_time"] =
                                                e.target.value;
                                              setOutLetTime(newOutlettime);
                                            }}
                                            type="time"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            inputProps={{
                                              step: 300,
                                            }}
                                          />
                                          <IconButton
                                            aria-label="close"
                                            onClick={() =>
                                              handleRemoveTimeSlot(
                                                outletKey,
                                                timeKey
                                              )
                                            }
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        </div>
                                      </AccordionDetails>
                                    );
                                  }
                                )}
                              <CloseButtonWrapper>
                                <div className="addbtn">
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={() =>
                                      handleAddButtonClick(dataIndex1)
                                    }
                                  >
                                    <i class="fal fa-plus"></i>
                                    <span class="add-text">Add Hour</span>
                                  </button>
                                </div>
                              </CloseButtonWrapper>
                            </Accordion>
                          </AccordionWrapper>
                        </Fragment>
                      );
                    })}
                </div>
                {!timingviewonly && (
                  <div className="button-wrapper">
                    <button
                      className="btn btn-secondary"
                      type="button"
                      onClick={""}
                    >
                      Cancel
                    </button>
                    <button class="btn btn-update" type="submit">
                      Submit
                    </button>
                  </div>
                )}
              </form>
            </Fragment>
          </div>
        </div>
      </Scrollbars>
    </Wrapper>
  );
};

export default TimingsTab;
const Wrapper = styled.div`
  .main-tab-block {
    padding: 0px 170px;
    .inner-block {
      width: 100%;
      padding: 80px 45px 40px 45px;

      background: #eff8ff;
      border-radius: 20px;
    }
  }
  .Mui-disabled {
    opacity: 1 !important;
  }
  .common-time-container {
    display: flex;
    align-items: center;
    margin-left: -10px;
    height: 60px;
  }

  .checkbox-container {
    margin-right: 10px; /* Adjust as needed */
  }
  .text-start.text-info {
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: bold;
    font-style: italic;
    font-size: medium;
  }
  .mb-3,
  .my-3 {
    margin-bottom: 0rem !important;
  }
  .MuiAccordion-rounded:last-of-type {
    width: 100%;
  }
  .MuiButtonBase-root.MuiAccordionSummary-root.Mui-expanded.MuiAccordionSummary-gutters {
    min-height: 50px !important;
    height: 50px !important;
    .time-input-show{
display: none;
    }
  }
  .MuiAccordion-region {
    margin-bottom: 21px;
    margin-top: 21px;
  }
  .MuiCollapse-root.MuiCollapse-vertical.MuiCollapse-entered {
    height: 0px;
  }
  .MuiFormControlLabel-labelPlacementEnd {
    height: 50px;
    /* min-height: 50px; */
  }
  .MuiButtonBase-root-MuiCheckbox-root.Mui-disabled {
    color: #0083ff;
  }

  .MuiSvgIcon-root {
    margin-right: 0px !important;
    font-size: 24px;
    color: "#0083ff";
        &.Mui-checked: {
          color: #0083ff, 
        },
  }
  
  .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium{
 font-size: 20px;

  }
  .typography-wrapper {
    width:100%;
  }
  
    .MuiTypography-root {
    margin-left: 30px;
  }
  .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiCheckbox-sizeMedium.Mui-disabled.PrivateSwitchBase-root {
    color: #606366;
  }

  .form-box {
    background: #eff8ff !important ;
  }

  .MuiCheckbox-root {
    margin-left: -24px !important;
  }
  .MuiCheckbox-root:hover {
    background-color: unset;
  }
  .MuiSvgIcon-root {
    margin-right: 25px;
  }
  .typography-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    margin-top: 5px;
    .closed-typography {
      color: #236ce5;
    }
  }
  .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
    background: #efefef !important;
    width: 21px;
    height: 21px;
    margin-top: 15px;
  }
  .MuiAccordion-root {
    border-radius: 5px;
    margin-bottom: 10px;
    box-shadow: none !important;
  }
  .MuiAccordionSummary-content {
    /* height: 25px !important; */
    margin: 0px 0 !important;
  }
  .MuiAccordionSummary-root {
    border-radius: 5px;
    background: #d4e8f8 !important;
  }
  .MuiCollapse-root {
    padding: 0 20px;
    background: #eff8ff !important;
  }
  .MuiCollapse-wrapper {
    background: white !important;
    /* height: 120px; */
    align-items: center;
  }
  .text-field {
    /* margin-top: 90px; */
    .MuiTextField-root {
      width: 230px;
      min-width: 100px;
      padding: 0px 5px;
    }
  }
  .addbtn {
    margin-left: 30px;
    position: relative;
    width: 20px;
    .btn {
      border: 1px solid #008eff;
      border-radius: 50%;
      background: #a7d3f6;
      height: 10px;
      min-width: 10px;
      max-width: 10px;
      height: 25px;
      display: flex;
      justify-content: center;
      .fal {
        color: #008eff;
      }
    }
  }
.Mui-expanded.time-input-show{
  min-height: 20px;
  height:20px;
}

  .add-text {
    position: absolute;
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    margin-left: 10px;
    color: #008eff;
  }

  .button-wrapper {
    display: flex;
    justify-content: end;
    width: 100%;
    gap: 10px;
    /* margin-bottom: 30px; */
    margin-top: 45px;

    .btn-secondary {
      width: 100px;
      background: #ededed;
      color: #707172;
      border: none;
    }
    .btn-update {
      width: 100px;
      color: #ffffff;
      background: #0083ff;
      background-color: #0083ff;
      &:hover {
        color: #ffffff !important;
        background: #0083ff !important;
        background-color: #0083ff !important;
      }
    }
  }
`;
 const CloseButtonWrapper = styled.div`
   .MuiSvgIcon-root {
     font-size: 3px;
   }
 `;
const AccordionWrapper = styled.div`
  display: flex;
`;
