import React from "react";
import styled from "styled-components";
import RoundedWrapperCard from "../../../../../components/RoundedWrapperCard";
import BannerImage from "./assets/img/thankyou.png";

type Props = {};

const VendorRegisterDashboard = (props: Props) => {
  return (
    <StyledWrapper className="StyledWrapper">
      <StyledSubWrapper className="StyledSubWrapper">
        <RoundedWrapperCard className="RoundedWrapperCard">
          <StyledBannerImage src={BannerImage} />
          <div className="message-text-block">
            <h3>Thank you for registering as a vendor with us. </h3>
            <p className="success-para-text">
              You'll shortly receive a confirmation email on your registered
              email address.
            </p>

            {/* <h4>Doucumentaion Successful!.</h4> */}
          </div>
        </RoundedWrapperCard>
      </StyledSubWrapper>
    </StyledWrapper>
  );
};

export default VendorRegisterDashboard;
const StyledWrapper = styled.div`
width: 100%;
  display: "block";
`;
const StyledBannerImage = styled.img`
  display: "block";
  width: 100%;
  max-width: 300px;
`;
const StyledSubWrapper = styled.div`
  display: "block";
  width: 100%;
  padding: 0px 0 0px 0;
  .CardHeadingLabel {
    margin: 0px 0px 0px;
  }
  .RoundedWrapperCard {
    margin: 0px 0px 0px;
    width: 100%;
    min-height: 600px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .message-text-block {
    h3 {
      padding: 0 0 26px 0;
      font-weight: 600;
      letter-spacing: 1px;
      font-size: 30px;
    }
    p {
    }
  }
`;
