import Serviceform from "./ServiceForm";
import React, { useState, useContext, useEffect, Fragment } from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { ServiceContext } from "./ServiceContext";
import { getService } from "../../../../api/services/sap/service-management";
import ConfirmModal from "../Service/Ui/ConfirmModal";
import NavToggle from "./Ui/NavToggle";
import ServiceFileUploadModal from "../Service/ServiceFileUploadModal";
import RiseLoader from "react-spinners/RiseLoader";
import ReactTooltip from "react-tooltip-rc";
import ServiceModal from "./Ui/ServiceModal";

import mainLoader from "../../../../assets/img/loader/loader.gif";
import styled from "styled-components";
import ImageCropper from "../../../ImageCropper";

const WindowRight = (props) => {
  const [confirmModal, SetConfirmModal] = useState(false);

  const {
    table,
    SetTable,
    SetViewRead,
    viewReadOnly,
    setIsUpdateForm,
    SetViewReadOnly,
    SetEditMode,
    editMode,
    SetMappedServiceData,
    mappedServiceData,
    formChange,
    SetFormChange,
    isSelected,
    setIsSelected,
    addservice,
    isUpdateForm,
    cancelFile,
    setCancelFile,
    loader,
    setLoader,
    SetRowActive,
  } = useContext(ServiceContext);

  const [activeStatus, setActiveStatus] = useState(mappedServiceData.is_active);
  //Use Effect Loader
  useEffect(() => {
    // setLoader(true);
  }, []);

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [deletemodal, SetDeleteModal] = useState(false);
  const [isDeleted, SetIsDeleted] = useState(false);

  const handleCloseView = (e) => {
    if (formChange) {
      SetConfirmModal(true);
      // e.stopPropagation();
    } else {
      props.serviceformClose(false);
      SetViewReadOnly(false);
      SetEditMode(false);
      setIsUpdateForm(false);
      SetMappedServiceData({});
      SetRowActive(false);
      const allTableRows = document.querySelectorAll(".rdt_TableRow");
      allTableRows.forEach((rowElement) => {
        rowElement.classList.remove("current-selected-row");
      });
    }
  };

  const handleEditView = (e) => {
    SetViewReadOnly(false);
    SetEditMode(true);
  };
  const handleInnerDelete = () => {
    SetDeleteModal(true);
    // props.serviceformClose(true);
  };
  useEffect(() => {
    if (isDeleted) handleCloseView();
  }, [isDeleted]);
  //Use Effect Loader
  useEffect(() => {
    setLoader(true);
    const loaderTime = setTimeout(() => {
      setLoader(false);
    }, 1000);
    return () => {
      clearTimeout(loaderTime);
    };
  }, [addservice, viewReadOnly, mappedServiceData, editMode]);
  return (
    <Fragment>
      {loader ? (
        <div className="loader-block">
          <img className="img-fluid" alt="" src={mainLoader} />
        </div>
      ) : (
        <div
          className={`content-window-right 
        ${viewReadOnly ? "view-window" : ""}
        ${editMode ? "edit-window" : ""}
        ${addservice ? "add-window" : ""}`}
        >
          <div className="content-right-block">
            <div className="card-right">
              <div className="header-block">
                <div className="header-sec">
                  <ContentRightTopWrapper className="content-right-top">
                    <button
                      onClick={handleCloseView}
                      className="btn btn-action btn-back"
                      id="backBtn"
                      rel="tooltip"
                      data-for="backTooltip"
                      data-tip="Back to listing"
                    >
                      <i className="fal fa-long-arrow-left"></i>
                    </button>
                    <ReactTooltip
                      id="backTooltip"
                      place="left"
                      type="info"
                      effect="float"
                    />
                    <Namewrapper>
                      <div className="namewrapper">
                        {editMode
                          ? `Edit ${mappedServiceData.service_name}`
                          : viewReadOnly
                          ? `${mappedServiceData.service_name}`
                          : "Add Service"}
                      </div>
                    </Namewrapper>
                  </ContentRightTopWrapper>
                  <div className="action-block-right d-flex align-items-center justify-content-center">
                    {viewReadOnly && (
                      <Fragment>
                        {/* status : {
                          Rejected : -1,
                        Draft : 0,
                        Send for Review : 1,
                        Approved : 2,
                        } */}

                        <div className="active-sec d-flex align-items-center">
                          {/* {activeStatus ? (
                          <span>Active</span>
                        ) : (
                          <span>Inactive</span>
                        )} */}
                          {mappedServiceData &&
                          mappedServiceData.is_active === 1 ? (
                            <span className="mr-2">Active</span>
                          ) : (
                            <span className="mr-2">Inactive</span>
                          )}
                          <button
                            type="button"
                            class="btn none-btn"
                            disabled={
                              (mappedServiceData.is_new == 1 &&
                                mappedServiceData.status == 2) ||
                              mappedServiceData.status == 2 ||
                              (mappedServiceData.is_new != 1 &&
                                mappedServiceData.status != -1)
                                ? false
                                : true
                            }
                          >
                            <span
                              data-for="toggleStatus"
                              data-tip="Change status"
                            >
                              <NavToggle
                                setActiveStatus={setActiveStatus}
                                status={mappedServiceData.status}
                                id={mappedServiceData.service_id}
                                name={mappedServiceData.name}
                                mappedServiceData={mappedServiceData}
                                isActive={mappedServiceData.is_active}
                              />
                            </span>

                            <ReactTooltip
                              id="toggleStatus"
                              place="bottom"
                              type="info"
                              effect="float"
                            />
                          </button>
                        </div>

                        {editMode ||
                          ([0, -1, 2].includes(mappedServiceData.status) && (
                            <button
                              onClick={handleEditView}
                              className="btn btn-action btn-edit"
                              id="backBtn"
                              rel="tooltip"
                              data-for="editTooltip"
                              data-tip="Edit service"
                            >
                              <ReactTooltip
                                id="editTooltip"
                                place="bottom"
                                type="info"
                                effect="float"
                              />
                              <i className="fal fa-pen"></i>
                            </button>
                          ))}
                        <button
                          onClick={handleInnerDelete}
                          className="btn btn-action btn-edit"
                          id="backBtn"
                          rel="tooltip"
                          data-for="deleteTooltip"
                          data-tip="Delete service"
                        >
                          <ReactTooltip
                            id="deleteTooltip"
                            place="bottom"
                            type="info"
                            effect="float"
                          />
                          <i className="fal fa-trash"></i>
                        </button>
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
              <div className="name-block">
                <div className="sub-block">
                  <div className="block-name">
                    {mappedServiceData.name && (
                      <h2>{mappedServiceData.name}</h2>
                    )}
                  </div>
                </div>
              </div>
              <div className="row m-0 row-right">
                {/* <!-- add service form --> */}
                <Serviceform
                  handleShow={handleShow}
                  addservice={props.addservice}
                  serviceformClose={props.serviceformClose}
                />
                <ServiceFileUploadModal
                  setShow={setShow}
                  showfilemodal={show}
                  hidefilemodal={handleClose}
                />
                {/* <!-- add service form end--> */}
              </div>
              {confirmModal && (
                <ConfirmModal
                  setConfirmModal={SetConfirmModal}
                  id={mappedServiceData.id}
                  serviceFormClose={props.serviceformClose}
                />
              )}
            </Scrollbars>
            {deletemodal && (
              <ServiceModal
                SetDeleteModal={SetDeleteModal}
                SetIsDeleted={SetIsDeleted}
                id={mappedServiceData.id}
                name={mappedServiceData.service_name}
                serviceFormClose={props.serviceformClose}
              />
            )}
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default WindowRight;
const ContentRightTopWrapper = styled.div`
  width: 100%;
`;
const Namewrapper = styled.div`
  display: grid;
  width: 100%;
  padding: 0 15px 0 0;
  .namewrapper {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 10px;
    text-align: left;
  }
`;
