import React  from 'react';
import Loader from "./Loader";
import Sidebar from "./Sidebar";
import "../../../assets/css/admin-custom.css";
import "../../../assets/css/bootstrap-tagsinput.css";
// import '../../../assets/css/bootstrap.min.css'
const Layout = (props) => {
  return (
    <section id="main" className="main">
      <Loader />
      <div className="container-fluid p-0">
        <div className="row m-0">
          <Sidebar />
          {props.children}
        </div>
      </div>
    </section>
  );
};
export default Layout;
