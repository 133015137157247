import React, { useState, createContext, useEffect } from "react";
import dateFormat, { masks } from "dateformat";
import {
  getDataByVehicleNumber,
  vehicleNumberSuggestion,
  serviceListByVehicleType,
  getVehicleModel,
  getManufacturerLIst,
  getBookingsList,
  createOrUpdateBooking,
  getServiceConfiguration,
} from "../../../../api/services/sap/bookings-management";

export const BookingsContext = createContext();
export const BookingsProvider = (props) => {
  const [BookingsTable, SetBookingsTable] = useState([]);
  const [mappedBookingsData, setMappedBookingsData] = useState([]);
  const [mappedBookingData, setMappedBookingData] = useState([]);
  const [rowActive, setRowActive] = useState(false);
  const [viewReadOnly, setViewReadOnly] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loader, setLoader] = useState(true);
  const [configData, setConfigData] = useState(null);
  // const [viewBookings, setViewBookings] = useState(false);
  // const [BookingsTable, SetBookingsTable] = useState([]);
  // const [mappedBookingsData, SetMappedBookingsData] = useState({});
  const [viewBookings, setViewBookings] = useState(false);
  // const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const [formChange, SetFormChange] = useState(false);
  const [filteredManufacture, setFilteredManufacture] = useState();
  const [serviceOptions, setServiceOptions] = useState([]);
  const [currentDate, setCurrentDate] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [showList, setShowList] = useState(false);
  const [bookDate, setBookDate] = useState([
    dateFormat(new Date(), "yyyy-mm-dd HH:MM"),
    dateFormat(new Date(), "yyyy-mm-dd HH:MM"),
  ]);
  const [cancelData, setCancelData] = useState({});
  const [error, setError] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [showQuoteModal, setShowQuoteModal] = useState(false);
  const [showBookingButton, setShowBookingButton] = useState(true);
  const [selectBookDate, setSelectBookDate] = useState([
    dateFormat(new Date(), "yyyy-mm-dd"),
    dateFormat(new Date(), "yyyy-mm-dd"),
  ]);

  const nextDay = dateFormat(
    new Date(
      new Date(bookDate[0]).setDate(new Date(bookDate[0]).getDate() + 1)
    ),
    "yyyy-mm-dd"
  );

  const prevDay = dateFormat(
    new Date(
      new Date(bookDate[0]).setDate(new Date(bookDate[0]).getDate() - 1)
    ),
    "yyyy-mm-dd"
  );

  const filterBookingshandler = (filterData) => {
    const getBookingsData = getBookingsList();
    getBookingsData &&
      getBookingsData.then((response) => {
        console.log(response, "getBookingsData");
        setFilterValue(
          response &&
            response?.filter((item) =>
              dateFormat(item.service_date, "yyyy-mmd-dd").includes(
                dateFormat(filterData, "yyyy-mmd-dd")
              )
            )
        );
        // SetBookingsTable(response);
      });
  };

  const currentDateHandler = () => {
    setBookDate([
      dateFormat(new Date(), "yyyy-mm-dd"),
      dateFormat(new Date(), "yyyy-mm-dd"),
    ]);
    filterBookingshandler(dateFormat(new Date(), "yyyy-mm-dd"));
  };
  const getBookedDateList = (date) => {
    setBookDate([
      dateFormat(new Date(date), "yyyy-mm-dd"),
      dateFormat(new Date(date), "yyyy-mm-dd"),
    ]);
    filterBookingshandler(dateFormat(new Date(date), "yyyy-mm-dd"));
  };

  const chooseDate = (dateValue) => {
    let dateVal = dateFormat(new Date(dateValue), "yyyy-mm-dd");
    setBookDate([dateVal, dateVal]);
    filterBookingshandler(dateVal);
  };

  useEffect(() => {
    const getBookingsData = getBookingsList();
    getBookingsData &&
      getBookingsData.then((response) => {
        console.log(response, "getBookingsData");
        SetBookingsTable(response);
        setRowActive(true);
      });
  }, []);

  useEffect(() => {
    const fetchVatOrGstData = async (data) => {
      try {
        let data = {
          table_reference: ["tax_types", "payment_methods", "discount_type"],
        };
        const response = await getServiceConfiguration(data);
        setConfigData(response);
        console.log(response, "response......");
      } catch (error) {
        console.error("Error fetching :", error);
      }
    };
    fetchVatOrGstData();
  }, []);

  // console.log(manufacturer, "manufacturerList");
  let initialValues = {
    BookingsTable,
    SetBookingsTable,
    mappedBookingsData,
    setMappedBookingsData,
    viewReadOnly,
    setViewReadOnly,
    viewBookings,
    setViewBookings,
    viewBookings,
    setViewBookings,
    filterValue,
    setFilterValue,
    bookDate,
    setBookDate,
    filterBookingshandler,
    currentDate,
    setCurrentDate,
    editMode,
    setEditMode,
    // handleBookingsEdit,
    cancelData,
    setCancelData,
    formChange,
    SetFormChange,
    loader,
    setLoader,
    serviceOptions,
    setServiceOptions,
    // manufacturer, setManufacturer,
    mappedBookingData,
    setMappedBookingData,
    setRowActive,
    rowActive,
    filteredManufacture,
    setFilteredManufacture,
    showList,
    setShowList,
    configData,
    setConfigData,
    error,
    setError,
    lgShow,
    setLgShow,
    showQuoteModal,
    setShowQuoteModal,
    showBookingButton,
    setShowBookingButton,
    currentDateHandler,
    selectBookDate,
    setSelectBookDate,
    chooseDate,
    nextDay,
    prevDay,
    getBookedDateList,
  };

  return (
    <BookingsContext.Provider value={initialValues}>
      {props.children}
    </BookingsContext.Provider>
  );
};
