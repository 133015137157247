import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { BookingsContext } from "../BookingsContext";
import deleteFile from "../../../../../assets/img/icon/upload/recycle-bin.svg";
import { cancelBookings, getBookingsList } from "../../../../../api/services/sap/bookings-management";
import { toast } from "react-toastify";

const CancelModal = (props) => {
  const [cancelled, setCancelled] = useState("");
  const [reason, setReason] = useState("");
  // const [cancelData, setCancelData] = useState([{}]);
  let id = props.mappedData;
  const {
    BookingsTable,
    SetBookingsTable,
    mappedBookingsData,
    setMappedBookingsData,
    setViewReadOnly,
    setEditMode,
    cancelData,
    setCancelData,
  } = useContext(BookingsContext);


  const cancelSubmit = (e) => {
    e.preventDefault();
    
    let reqData = {
      order_id: mappedBookingsData.order_id_pk,
      cancelled_by: cancelled,
      reason: reason,
    }
    cancelBooking(reqData);
  };



  const cancelBooking =(data)=>{     // to be optimised;
    const cancelledBooking = cancelBookings(data);
    cancelledBooking.then((response) => {
      toast.success("Booking Cancelled Successfully", {
        position: "bottom-right",
      });
      const getBookingsData = getBookingsList();
      getBookingsData && getBookingsData.then((response) => {
        console.log(response, "getBookingsData");
        SetBookingsTable(response);
        props.setCancelModal(false);
        props.bookingformClose(false);
        setViewReadOnly(false);
        setEditMode(false);
        setMappedBookingsData({});
      });

    })
    .catch((err) => {
      toast.error(err, {
        position: "bottom-right",
      });
    });
  }

  //   console.log(BookingsTable, "BookingsTable");
  return (
    <div className="modal-main" data-id={id}>
      <div className="custom-modal" data-id={id}>
        {/* <h1>{mappedBookingsData.customer_name}</h1> */}
        <div className="modal-dialog modal-dialog-centered">


          <div className="modal-content">
            <div className="modal-header"><button className="btn border-none" type="button"  onClick={() => props.setCancelModal(false)} aria-label="Close"><i class="fas fa-times"></i></button></div>
            <div className="modal-body">
              <div className="modal-info-img">
                <img src={deleteFile} className="delete-file" alt="" />
              </div>
              <h5>Are you sure to cancel this booking?</h5>
              <form onSubmit={cancelSubmit}>
                <div className="cancelled-block">
                  <div className="input-group input-group-custom">
                    <label htmlFor="" className="mb-0">
                      Cancelled By
                    </label>
                    <div className="col-12 d-flex">
                      <div className="radio-title mr-4">
                        <input
                          type="radio"
                          value="sap"
                          name="vehicleType"
                          onChange={(e) => setCancelled(e.target.value)}
                          checked={cancelled === "sap"}
                        />
                        Service Admin
                      </div>
                      <div className="radio-title mr-4">
                        <input
                          type="radio"
                          value="customer"
                          name="vehicleType"
                          onChange={(e) => setCancelled(e.target.value)}
                          checked={cancelled === "customer"}
                        />
                        Customer
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-group input-group-custom reason-group">
                  {/* 
                  <label htmlFor="" className="mb-0 mt-3 ml-3">
              Reason
            </label> */}
                  <div className="col-12">
                    <div
                      className="form-group"
                    >
                      <textarea
                        placeholder="Reason for cancellation"
                        className="form-control"
                        value={reason}
                        onChange={(e) => setReason(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="modal-footer"><button  className="btn btn-light" onClick={() => props.setCancelModal(false)}>Close</button>
                
                <button  className="btn btn-danger" disabled={cancelled.length && reason.length ? false : true }>Confirm</button></div>
                {/* <button
              className="btn btn-light"
              onClick={() => props.setCancelModal(false)}
            >
              Close
            </button>
            <button className="btn btn-danger">Confirm</button> */}
              </form>
            </div>


          </div>
        </div>
      </div>
    </div>
  );
};

export default CancelModal;
