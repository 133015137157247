import React, { useState, useContext, useEffect } from "react";
import { Table } from "react-bootstrap";
import { OrdersContext } from "../OrdersContext";
import qrImage from "../../../../../assets/img/qr-image/1.webp";
import upiImage from "../../../../../assets/img/qr-image/2.jpg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../Ui/InvoiceModal.css";
import { getSendTaxInvoice } from "../../../../../api/services/sap/order-management";
import {
  viewMappedBooking,
  updatePayments,
} from "../../../../../api/services/sap/bookings-management";
import { toast } from "react-toastify";
import styled from "styled-components";
import img from "../../../../../assets/img/motorque/mtlogo.svg";
import { getProfileInfo } from "../../../../../api/services/sap/profile-management";

const PaymentDetailstab = () => {
  const { orderConfigData } = useContext(OrdersContext);
  const { innertab, mappedOrderData } = useContext(OrdersContext);
  const [paymentForm, setPaymentForm] = useState([]);
  const [payView, setPayView] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [checkedValues, setCheckedValues] = useState({
    payment_type: "",
    payment_notes: "",
  });
  const [error, setError] = useState(false);
  const [admininfo, setAdmininfo] = useState();
  //useEffect for payment details
  useEffect(() => {
    const mapData = viewMappedBooking(mappedOrderData.order_id_pk);
    mapData.then((res) => {
      setPaymentForm(res);
    });
  }, []);

  //grandTotal
  let grandTotal =
    (paymentForm &&
      paymentForm.rate_total &&
      parseInt(paymentForm.rate_total)) +
    (paymentForm && paymentForm.additional_charges
      ? parseInt(paymentForm.additional_charges)
      : 0) -
    (paymentForm && paymentForm.additional_discount
      ? parseInt(paymentForm.additional_discount)
      : 0);

  // Tax amount
  const taxRate = orderConfigData?.serviceadmin?.tax_percentage; // 5%
  let tax = parseFloat(((grandTotal * taxRate) / 100).toFixed(2));
  // grand total
  const taxAddedTotal = parseFloat(grandTotal) + tax;

  //payable amount
  let payableAmt =
    taxAddedTotal -
    (paymentForm && paymentForm.paid_amount
      ? parseInt(paymentForm.paid_amount)
      : 0);

  //toggle payment fn
  const togglePaymenthandler = () => {
    setPayView(!payView);
  };
  //Checkbox handler
  const handleCheckHandler = (e) => {
    setError(false);
    const value = e.target.value;
    const checked = e.target.checked;
    if (checked) {
      setCheckedValues({ ...checkedValues, payment_type: value });
    }
    // setPaymentForm({ ...paymentForm, orderPayments, payment_type: value });
    setPaymentForm?.({
      ...paymentForm,
      orderPayments: {
        ...paymentForm.orderPayments,
        payment_type: e.target.value,
      },
    });
    {
      paymentForm?.orderPayments?.[0]?.payment_type == 3 && (
        <div className="qr-block-block">
          <div className="qr-img-block">
            <h6>
              Pay <span>{payableAmt}</span>
            </h6>
            <img className="img-fluid qr-img" src={qrImage} />
          </div>
          <div className="qr-img-block">
            <img className="img-fluid qr-img" src={upiImage} />
          </div>
        </div>
      );
    }
  };

  const paymentSubmithandler = (e) => {
    e.preventDefault();
    // setMappedOrderData();
    if (checkedValues?.payment_type === "" || undefined) {
      setError(true);
    } else {
      setError(false);

      updatePayments();
      viewMappedBooking();
      setPaymentForm({ ...paymentForm });
      const data = updatePayments({
        service_admin_id: paymentForm.service_admin_id_fk,
        order_id: paymentForm.order_id_pk,
        service_date: paymentForm.service_date,
        grand_total: parseInt(grandTotal),
        additional_charges: parseInt(paymentForm.additional_charges),
        additional_discount: parseInt(paymentForm.additional_discount),
        paid_amount: parseInt(payableAmt),
        payment_type: checkedValues.payment_type,
        payment_notes: checkedValues.payment_notes,
        payment_status: 0,
      });
      //  getOrderInspection();
      data.then(() => {
        const mapData = viewMappedBooking(mappedOrderData.order_id_pk);
        mapData.then((res) => {
          setPaymentForm(res);
        });
      });
      toast.success("Payment Successful!", {
        position: "bottom-right",
      });
      setPayView(!payView);
    }
    //  const id = localStorage.getItem("userid");

    // Call getProfileInfo with the user id
    //  getProfileInfo(id)
    //    .then((response) => {

    //      setPaymentForm(response);
    //    })
    //    .catch((error) => {

    //      console.error("Error fetching profile information:", error);
    //    });

    // console.log(payableAmt, "payableAmt  on submit -------------");
    // console.log(paymentForm, "paymentForm on submit -------------");
  };

  const filteredPaymentMethods = orderConfigData?.payment_methods?.find(
    (paymentMethod) =>
      paymentMethod?.payment_method_id_pk ==
      paymentForm?.orderPayments?.[0]?.payment_type
  );

  const InvoiceClick = () => {
    setLgShow(true);
  };

  const SendingMail = () => {
    if (!paymentForm.order_id_pk) {
      toast.error("Error: No order ID found", {
        position: "bottom-right",
      });
      return;
    }
    getSendTaxInvoice(paymentForm.order_id_pk)
      .then(() => {
        toast.success("Mail sent successfully", {
          position: "bottom-right",
        });
      })
      .catch((error) => {
        console.error("Error sending mail:", error);
        toast.error("Error sending mail. Please try again later.", {
          position: "bottom-right",
        });
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const id = localStorage.getItem("userid");
        const response = await getProfileInfo(id);
        console.log(response, "resss");
        setAdmininfo(response);
      } catch (error) {
        console.error("Error fetching profile information:", error);
      }
    };
    fetchData();

    // Cleanup function
    return () => {
      // Perform any cleanup if needed
    };
  }, []);
  const formatPrice = (price) => {
    const formattedPrice = Number(price).toFixed(2);
    return ` ${orderConfigData?.serviceadmin?.currency_code} ${formattedPrice}`;
  };

  const formatPrices = (price) => {
    const formattedPrice = Number(price).toFixed(2);
    return formattedPrice;
  };
  //advance paid amount
  const filteredAdvanceAmt =
    paymentForm?.orderPayments?.length &&
    paymentForm?.orderPayments?.filter((item) => {
      return item?.type === "bookings";
    })?.[0];

  return (
    <div
      className={innertab === 4 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <div className="inner-block">
        <div className="inner-sub-block">
          <form className="" onSubmit={paymentSubmithandler}>
            <div>
              {/* Show when not in payment view */}
              {!payView && (
                <div>
                  <div className="pre-sub-block">
                    <Table className="mb-3" striped bordered hover={false}>
                      <thead>
                        <tr>
                          <th>Service Details</th>
                          <th>Rate</th>
                        </tr>
                      </thead>
                      <tbody>
                        {paymentForm && paymentForm.orderServices ? (
                          paymentForm.orderServices.map((item, index) => {
                            return (
                              <tr key={index} className="text-left">
                                <td>{item.service.service_name}</td>
                                <td>{item.actual_price}</td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan="2">No services found</td>
                          </tr>
                        )}
                        <tr className="text-left">
                          <td>{"RATE TOTAL"}</td>
                          <td>{paymentForm.rate_total}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>

                  {paymentForm && paymentForm.grand_total !== 0 && (
                    <div className="inner-payment-block">
                      <div className="pt-3">
                        <div className="input-group input-group-custom">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              Add Coupon Code :
                            </span>
                          </div>
                          <div className="input-block">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Add Coupon Code"
                              onChange={(e) => {
                                setPaymentForm({
                                  ...paymentForm,
                                  coupon_discount: e.target.value,
                                });
                              }}
                              value={
                                paymentForm && paymentForm.coupon_discount
                                  ? paymentForm.coupon_discount
                                  : ""
                              }
                            />
                          </div>
                        </div>

                        <div className="input-group input-group-custom">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              Additional Discounts :
                            </span>
                          </div>
                          <div className="input-block">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Add Discount"
                              onChange={(e) => {
                                const value = e.target.value;

                                setPaymentForm({
                                  ...paymentForm,
                                  additional_discount: value,
                                });
                              }}
                              value={
                                paymentForm.payment_status === 2
                                  ? paymentForm.additional_discount
                                    ? formatPrices(
                                        paymentForm.additional_discount
                                      )
                                    : ""
                                  : paymentForm.additional_discount
                                  ? paymentForm.additional_discount
                                  : ""
                              }
                              disabled={paymentForm.payment_status === 2}
                            />
                          </div>
                        </div>

                        <div className="input-group input-group-custom">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              Additional Charges :
                            </span>
                          </div>
                          <div className="input-block">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Add Additional charges"
                              onChange={(e) => {
                                const value = e.target.value;

                                setPaymentForm({
                                  ...paymentForm,
                                  additional_charges: value,
                                });
                              }}
                              value={
                                paymentForm.payment_status === 2
                                  ? paymentForm.additional_charges
                                    ? formatPrices(
                                        paymentForm.additional_charges
                                      )
                                    : ""
                                  : paymentForm.additional_charges
                                  ? paymentForm.additional_charges
                                  : ""
                              }
                              disabled={paymentForm.payment_status === 2}
                            />
                          </div>
                        </div>

                        <div className="input-group input-group-custom">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              {orderConfigData?.tax_type?.title} (
                              {orderConfigData?.serviceadmin?.tax_percentage}{" "}
                              %):
                            </span>
                          </div>
                          <div className="input-block">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="VAT Amount"
                              onChange={(e) => {
                                setPaymentForm({
                                  ...paymentForm,
                                  coupon_discount: e.target.value,
                                });
                              }}
                              value={tax ? formatPrices(tax) : ""}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="input-group input-group-custom">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              Grand Total :
                            </span>
                          </div>
                          <div className="input-block">
                            <input
                              readOnly
                              type="text"
                              className="form-control"
                              value={taxAddedTotal ? taxAddedTotal : ""}
                              disabled
                            />
                          </div>
                        </div>

                        <div className="input-group input-group-custom">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              Advance Amount :
                            </span>
                          </div>
                          <div className="input-block">
                            <input
                              readOnly
                              type="text"
                              className="form-control"
                              value={
                                filteredAdvanceAmt?.paid_amount
                                  ? formatPrices(
                                      filteredAdvanceAmt?.paid_amount ?? 0
                                    )
                                  : ""
                              }
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12 payment-footer">
                        <div>
                          <span>Amount to be paid:</span>
                          <span>
                            {!payableAmt
                              ? formatPrices(paymentForm?.paid_amount)
                              : formatPrices(payableAmt)}
                          </span>
                        </div>
                        {!(payableAmt <= 0) ? (
                          <button
                            className="btn btn-update"
                            onClick={() => setPayView(!payView)}
                            disabled={payableAmt <= 0}
                          >
                            Make Payment
                          </button>
                        ) : (
                          ""
                        )}
                        {/* Toggle payment view */}
                        {payableAmt <= 0 ? (
                          <ButtonWrappper>
                            <>
                              <Button
                                style={{ marginRight: "15px" }}
                                className="mr-3"
                                onClick={InvoiceClick}
                              >
                                TAX INVOICE
                              </Button>
                              <Button
                                style={{ marginRight: "15px" }}
                                className="btn-send"
                                onClick={SendingMail}
                                disabled={paymentForm?.payment_status !== 2}
                              >
                                SEND INVOICE
                              </Button>
                            </>
                            <button
                              className="btn btn-update"
                              onClick={() => setPayView(!payView)}
                              disabled={payableAmt <= 0}
                            >
                              Payment Completed
                            </button>
                          </ButtonWrappper>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  )}
                  {payableAmt <= 0 ? (
                    <div className="col-12 payment-footer justify-content-end">
                      <div className="d-flex justify-content-end mb-4 mt-4">
                        <div className="mr-3">
                          <>
                            <Modal
                              size="lg"
                              show={lgShow}
                              onHide={() => setLgShow(false)}
                              aria-labelledby="example-modal-sizes-title-lg"
                              className="invoice-total-style"
                            >
                              <Modal.Header closeButton>
                                <Modal.Title id="example-modal-sizes-title-lg ">
                                  <div className="header-div-style  m-0">
                                    <div className="owner-style">
                                      {" "}
                                      <h6>
                                        <b>From</b>
                                      </h6>
                                      <h6>{admininfo.service_center_name}</h6>
                                      <h6>{admininfo.owner_name}</h6>
                                      <h6>{admininfo.address}</h6>
                                      <h6>{admininfo.phone}</h6>
                                      <h6>{admininfo.email}</h6>
                                      {orderConfigData?.serviceadmin
                                        ?.tr_number && (
                                        <h6>
                                          TRN:
                                          {
                                            orderConfigData?.serviceadmin
                                              ?.tr_number
                                          }
                                        </h6>
                                      )}
                                    </div>
                                    <div className="Tax-head-style">
                                      <h3> Tax Invoice</h3>
                                    </div>
                                    <img
                                      src={img}
                                      alt="Logo"
                                      className="mr-2 image-styles"
                                      style={{
                                        background: "",
                                        width: "100px",
                                        height: "100px",
                                        marginLeft: "140px",
                                      }}
                                    />
                                  </div>
                                </Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <div className="main-wrapper p-1">
                                  <div className="row m-0">
                                    <div
                                      className="col-lg-6"
                                      style={{ fontFamily: "Times New Roman" }}
                                    >
                                      <h6>
                                        <b>To</b>
                                      </h6>
                                      <h6>
                                        {paymentForm.customer.customer_name}
                                      </h6>
                                      <h6>
                                        {paymentForm.customer.customer_address}
                                      </h6>
                                      <h6>{paymentForm.customer.email}</h6>
                                      <h6>
                                        {paymentForm.customer.phone_number}
                                      </h6>
                                      {paymentForm?.customer?.gst_number && (
                                        <h6>
                                          TRN:{" "}
                                          {paymentForm?.customer?.gst_number}
                                        </h6>
                                      )}
                                    </div>
                                    <div
                                      className="col-lg-6"
                                      style={{ fontFamily: "Times New Roman" }}
                                    >
                                      <Table>
                                        <thead>
                                          <tr className="border_style border-top-style">
                                            <td className="border-top-style default-cursor">
                                              {" "}
                                              Invoice date
                                            </td>
                                            <td className="border-top-style default-cursor">
                                              {new Date(
                                                paymentForm?.service_date
                                              ).toLocaleDateString()}
                                            </td>
                                          </tr>
                                          <tr className="border_style">
                                            <td className="default-cursor">
                                              Order #
                                            </td>
                                            <td className="default-cursor">
                                              {paymentForm.order_unique_id}
                                            </td>
                                          </tr>
                                          <tr className="border_style default-cursor">
                                            <td className="default-cursor">
                                              {" "}
                                              Model
                                            </td>
                                            <td className="default-cursor">
                                              {
                                                paymentForm.customerVehicle
                                                  .vehicleModel.model
                                              }
                                            </td>
                                          </tr>

                                          <tr className="border_style default-cursor">
                                            <td className="default-cursor">
                                              {" "}
                                              Vehicle #{" "}
                                            </td>
                                            <td className="default-cursor">
                                              {paymentForm.customerVehicle.vehicle_registration_number.toUpperCase()}
                                            </td>
                                          </tr>
                                          <tr className="border_style">
                                            <td className="default-cursor">
                                              {" "}
                                              Payment method
                                            </td>
                                            <td className="default-cursor">
                                              {filteredPaymentMethods?.title}
                                            </td>
                                          </tr>
                                        </thead>
                                      </Table>
                                    </div>
                                  </div>
                                  <div className="pre-sub-block">
                                    <Table className="mb-4" hover={false}>
                                      <thead>
                                        <tr className="heading-styles">
                                          <th className="sl-no-styles text-center">
                                            SL.NO
                                          </th>
                                          <th>Service Details</th>
                                          <th className="rate-styles">
                                            Rate (
                                            {
                                              orderConfigData?.serviceadmin
                                                ?.currency_code
                                            }
                                            )
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {paymentForm &&
                                        paymentForm.orderServices ? (
                                          paymentForm.orderServices.map(
                                            (item, index) => {
                                              return (
                                                <tr
                                                  key={index}
                                                  className={
                                                    index ===
                                                    paymentForm.orderServices
                                                      .length -
                                                      1
                                                      ? "service-rows border-bottom"
                                                      : "service-rows"
                                                  }
                                                >
                                                  <td className="default-cursor">
                                                    {index + 1}
                                                  </td>
                                                  <td className="default-cursor">
                                                    {item.service.service_name}
                                                  </td>
                                                  <td className="default-cursor">
                                                    {item.actual_price}
                                                  </td>
                                                </tr>
                                              );
                                            }
                                          )
                                        ) : (
                                          <tr>
                                            <td colSpan="2">
                                              No services found
                                            </td>
                                          </tr>
                                        )}
                                        <tr
                                          className="text-right"
                                          style={{
                                            fontFamily: "Times New Roman",
                                          }}
                                        >
                                          <td></td>
                                          <td>{"Sub total"}</td>
                                          <td className="text-left">
                                            {formatPrice(
                                              paymentForm.rate_total
                                            )}
                                          </td>
                                        </tr>
                                        <tr
                                          className="text-right"
                                          style={{
                                            fontFamily: "Times New Roman",
                                          }}
                                        >
                                          <td></td>
                                          <td>{" Additional charges"}</td>
                                          <td className="text-left">
                                            {formatPrice(
                                              paymentForm.additional_charges
                                            )}
                                          </td>
                                        </tr>

                                        <tr
                                          className="text-right"
                                          style={{
                                            fontFamily: "Times New Roman",
                                          }}
                                        >
                                          <td></td>
                                          <td>{" Additional discount"}</td>
                                          <td className="text-left">
                                            {formatPrice(
                                              paymentForm.additional_discount
                                            )}
                                          </td>
                                        </tr>

                                        <tr
                                          className="text-right vat-rows"
                                          style={{
                                            fontFamily: "Times New Roman",
                                          }}
                                        >
                                          <td></td>
                                          <td>
                                            {orderConfigData?.tax_type?.title}{" "}
                                            {
                                              orderConfigData?.serviceadmin
                                                ?.tax_percentage
                                            }
                                            (%)
                                          </td>
                                          <td
                                            className="text-left"
                                            style={{
                                              fontFamily: "Times New Roman",
                                            }}
                                          >
                                            {formatPrice(tax)}
                                          </td>
                                        </tr>
                                        <tr
                                          className="text-right grand-total-rows "
                                          style={{
                                            fontFamily: "Times New Roman",
                                          }}
                                        >
                                          <td></td>
                                          <td>
                                            <b>{"Grand Total"}</b>
                                          </td>
                                          <td
                                            className="text-left "
                                            style={{
                                              fontFamily: "Times New Roman",
                                            }}
                                          >
                                            {formatPrice(
                                              paymentForm.grand_total
                                            )}
                                          </td>
                                        </tr>
                                        <tr
                                          className="text-right advace-paid-amount"
                                          style={{
                                            fontFamily: "Times New Roman",
                                          }}
                                        >
                                          <td></td>
                                          <td>{"Advance paid"}</td>
                                          <td className="text-left">
                                            {formatPrice(
                                              paymentForm.paid_amount
                                            )}
                                          </td>
                                        </tr>
                                        <tr
                                          className="text-right total-due"
                                          style={{
                                            fontFamily: "Times New Roman",
                                          }}
                                        >
                                          <td></td>
                                          <td>{"Total due"}</td>
                                          <td className="text-left">
                                            {formatPrice(
                                              paymentForm.balance_amount
                                            )}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </Table>
                                  </div>
                                </div>
                              </Modal.Body>
                            </Modal>
                          </>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}

              {/* Show when in payment view */}
              {payView && (
                <div className="inner-sub-block">
                  <div className="input-group input-group-custom">
                    <TitleWrapper className="input-group-prepend">
                      <span className="input-group-text">Payment Mode</span>
                    </TitleWrapper>
                    <PaymentModeWrapper>
                      <div className="mode-payment">
                        {orderConfigData?.payment_methods?.map(
                          (paymentMethod) => (
                            <div>
                              <div
                                className="radio-title mr-4 "
                                key={paymentMethod.payment_method_id_pk}
                              >
                                <input
                                  id={paymentMethod.payment_method_id_pk}
                                  type="radio"
                                  value={paymentMethod.payment_method_id_pk}
                                  name="paymentradio"
                                  onClick={handleCheckHandler}
                                />
                                <LabelWrapper>
                                  <label
                                    className="label-radio"
                                    htmlFor={paymentMethod.title}
                                  >
                                    {paymentMethod.title}
                                  </label>
                                </LabelWrapper>
                              </div>
                            </div>
                          )
                        )}
                      </div>
                      <div className="input-block">
                        {error ? (
                          <span className="form-error">
                            Payment type is required
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </PaymentModeWrapper>
                  </div>

                  {/* Payment Notes */}
                  <div className="input-group input-group-custom">
                    <div className="input-group-prepend">
                      <span className="input-group-text">
                        <i className="fal fa-pen"></i>Notes
                      </span>
                    </div>
                    <div className="input-block">
                      <textarea
                        className="form-control"
                        placeholder="Notes"
                        onChange={(e) => {
                          setCheckedValues({
                            ...checkedValues,
                            payment_notes: e.target.value,
                          });
                        }}
                      ></textarea>
                    </div>
                  </div>

                  <div className="col-12 payment-footer justify-content-end">
                    <div className="d-flex justify-content-end mb-4 mt-4">
                      <div className="mr-3">
                        <>
                          <div>
                            <Button className="mr-3" onClick={InvoiceClick}>
                              TAX INVOICE
                            </Button>
                          </div>
                          <Modal
                            size="lg"
                            show={lgShow}
                            onHide={() => setLgShow(false)}
                            aria-labelledby="example-modal-sizes-title-lg"
                            className="invoice-total-style"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title id="example-modal-sizes-title-lg ">
                                <div className="header-div-style  m-0">
                                  <div className="owner-style">
                                    {" "}
                                    <h6>
                                      <b>From</b>
                                    </h6>
                                    <h6>{admininfo.service_center_name}</h6>
                                    <h6>{admininfo.owner_name}</h6>
                                    <h6>{admininfo.address}</h6>
                                    <h6>{admininfo.phone}</h6>
                                    <h6>{admininfo.email}</h6>
                                    {orderConfigData?.serviceadmin
                                      ?.tr_number && (
                                      <h6>
                                        TRN:
                                        {
                                          orderConfigData?.serviceadmin
                                            ?.tr_number
                                        }
                                      </h6>
                                    )}
                                  </div>
                                  <div className="Tax-head-style">
                                    <h3> Tax Invoice</h3>
                                  </div>
                                  <img
                                    src={img}
                                    alt="Logo"
                                    className="mr-2 image-styles"
                                    style={{
                                      background: "",
                                      width: "100px",
                                      height: "100px",
                                      marginLeft: "140px",
                                    }}
                                  />
                                </div>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="main-wrapper p-1">
                                <div className="row m-0">
                                  <div
                                    className="col-lg-6"
                                    style={{ fontFamily: "Times New Roman" }}
                                  >
                                    <h6>
                                      <b>To</b>
                                    </h6>
                                    <h6>
                                      {paymentForm.customer.customer_name}
                                    </h6>
                                    <h6>
                                      {paymentForm.customer.customer_address}
                                    </h6>
                                    <h6>{paymentForm.customer.email}</h6>
                                    <h6>{paymentForm.customer.phone_number}</h6>
                                    {paymentForm?.customer?.gst_number && (
                                      <h6>
                                        TRN: {paymentForm?.customer?.gst_number}
                                      </h6>
                                    )}
                                  </div>
                                  <div
                                    className="col-lg-6"
                                    style={{ fontFamily: "Times New Roman" }}
                                  >
                                    <Table>
                                      <thead>
                                        <tr className="border_style border-top-style">
                                          <td className="border-top-style default-cursor">
                                            {" "}
                                            Invoice date
                                          </td>
                                          <td className="border-top-style default-cursor">
                                            {new Date(
                                              paymentForm?.service_date
                                            ).toLocaleDateString()}
                                          </td>
                                        </tr>
                                        <tr className="border_style">
                                          <td className="default-cursor">
                                            Order #
                                          </td>
                                          <td className="default-cursor">
                                            {paymentForm.order_unique_id}
                                          </td>
                                        </tr>
                                        <tr className="border_style default-cursor">
                                          <td className="default-cursor">
                                            {" "}
                                            Model
                                          </td>
                                          <td className="default-cursor">
                                            {
                                              paymentForm.customerVehicle
                                                .vehicleModel.model
                                            }
                                          </td>
                                        </tr>

                                        <tr className="border_style default-cursor">
                                          <td className="default-cursor">
                                            {" "}
                                            Vehicle #{" "}
                                          </td>
                                          <td className="default-cursor">
                                            {paymentForm.customerVehicle.vehicle_registration_number.toUpperCase()}
                                          </td>
                                        </tr>
                                        <tr className="border_style">
                                          <td className="default-cursor">
                                            {" "}
                                            Payment method
                                          </td>
                                          <td className="default-cursor">
                                            {filteredPaymentMethods?.title}
                                          </td>
                                        </tr>
                                      </thead>
                                    </Table>
                                  </div>
                                </div>
                                <div className="pre-sub-block">
                                  <Table className="mb-4" hover={false}>
                                    <thead>
                                      <tr className="heading-styles">
                                        <th className="sl-no-styles text-center">
                                          SL.NO
                                        </th>
                                        <th>Service Details</th>
                                        <th className="rate-styles">
                                          Rate (
                                          {
                                            orderConfigData?.serviceadmin
                                              ?.currency_code
                                          }
                                          )
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {paymentForm &&
                                      paymentForm.orderServices ? (
                                        paymentForm.orderServices.map(
                                          (item, index) => {
                                            return (
                                              <tr
                                                key={index}
                                                className={
                                                  index ===
                                                  paymentForm.orderServices
                                                    .length -
                                                    1
                                                    ? "service-rows border-bottom"
                                                    : "service-rows"
                                                }
                                              >
                                                <td className="default-cursor">
                                                  {index + 1}
                                                </td>
                                                <td className="default-cursor">
                                                  {item.service.service_name}
                                                </td>
                                                <td className="default-cursor">
                                                  {item.actual_price}
                                                </td>
                                              </tr>
                                            );
                                          }
                                        )
                                      ) : (
                                        <tr>
                                          <td colSpan="2">No services found</td>
                                        </tr>
                                      )}
                                      <tr
                                        className="text-right"
                                        style={{
                                          fontFamily: "Times New Roman",
                                        }}
                                      >
                                        <td></td>
                                        <td>{"Sub total"}</td>
                                        <td className="text-left">
                                          {formatPrice(paymentForm.rate_total)}
                                        </td>
                                      </tr>
                                      <tr
                                        className="text-right"
                                        style={{
                                          fontFamily: "Times New Roman",
                                        }}
                                      >
                                        <td></td>
                                        <td>{" Additional charges"}</td>
                                        <td className="text-left">
                                          {formatPrice(
                                            paymentForm.additional_charges
                                          )}
                                        </td>
                                      </tr>

                                      <tr
                                        className="text-right"
                                        style={{
                                          fontFamily: "Times New Roman",
                                        }}
                                      >
                                        <td></td>
                                        <td>{" Additional discount"}</td>
                                        <td className="text-left">
                                          {formatPrice(
                                            paymentForm.additional_discount
                                          )}
                                        </td>
                                      </tr>

                                      <tr
                                        className="text-right vat-rows"
                                        style={{
                                          fontFamily: "Times New Roman",
                                        }}
                                      >
                                        <td></td>
                                        <td>
                                          {orderConfigData?.tax_type?.title}{" "}
                                          {
                                            orderConfigData?.serviceadmin
                                              ?.tax_percentage
                                          }
                                          (%)
                                        </td>
                                        <td
                                          className="text-left"
                                          style={{
                                            fontFamily: "Times New Roman",
                                          }}
                                        >
                                          {formatPrice(tax)}
                                        </td>
                                      </tr>
                                      <tr
                                        className="text-right grand-total-rows "
                                        style={{
                                          fontFamily: "Times New Roman",
                                        }}
                                      >
                                        <td></td>
                                        <td>
                                          <b>{"Grand Total"}</b>
                                        </td>
                                        <td
                                          className="text-left "
                                          style={{
                                            fontFamily: "Times New Roman",
                                          }}
                                        >
                                          {formatPrice(paymentForm.grand_total)}
                                        </td>
                                      </tr>
                                      <tr
                                        className="text-right advace-paid-amount"
                                        style={{
                                          fontFamily: "Times New Roman",
                                        }}
                                      >
                                        <td></td>
                                        <td>{"Advance paid"}</td>
                                        <td className="text-left">
                                          {formatPrice(paymentForm.paid_amount)}
                                        </td>
                                      </tr>
                                      <tr
                                        className="text-right total-due"
                                        style={{
                                          fontFamily: "Times New Roman",
                                        }}
                                      >
                                        <td></td>
                                        <td>{"Total due"}</td>
                                        <td className="text-left">
                                          {formatPrice(
                                            paymentForm.balance_amount
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </Modal.Body>
                          </Modal>
                        </>
                      </div>

                      <div className="submit-block">
                        <Button
                          style={{ marginRight: "30px" }}
                          className="btn-send"
                          onClick={SendingMail}
                          disabled={paymentForm?.payment_status !== 2}
                        >
                          SEND INVOICE
                        </Button>
                        <button
                          className="btn btn-update"
                          onClick={paymentSubmithandler}
                        >
                          MARK AS PAID
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default PaymentDetailstab;

const LabelWrapper = styled.div`
  .label-radio {
    margin-bottom: 0px !important;
  }
`;
const PaymentModeWrapper = styled.div`
  .mode-payment {
    display: flex;
  }
  .input-block {
    width: unset !important;
  }
`;
const TitleWrapper = styled.div`
  display: unset !important;
`;
const ButtonWrappper = styled.div`
  @media (max-width: 1200px) {
    display: grid;
    button {
      margin-right: 0px !important;
      margin-top: 10px !important;
    }
  }
`;
