import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import TickIcon from "./assets/img/tick.svg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Link } from "react-router-dom";
// import { ReactComponent as Added } from './assets/img/icon/added.svg';

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void; // to handle onClick functions
  children?: React.ReactNode; // make the component able to receive children elements
  buttonType?: "primary" | "secondary" | "icon"; // two styling options (you can create as many as you want)
  disabled?: boolean; // make the button disabled or not
  rounded?: boolean; // make the button disabled or not
  type?: "submit" | "button";
  variant?: "contained" | "outlined" | "text";
  color?: "success" | "warning";
  href?: string;
  target?: React.HTMLAttributeAnchorTarget;
  download?: boolean;
};

const SupFilePreviewButton = ({
  onClick,
  children,
  variant,
  color,
  type,
  rounded,
  href,
  target,
  download,
}: ButtonProps) => {
  return (
    <Wrapper className={`button-wrapper ${rounded ? "rounded" : ""}`}>
      <Anchor
        onClick={onClick}
        href={href}
        rel="noopener noreferrer"
        target={target}
        type={type}
        download={download}
      >
        <FileDownloadOutlinedIcon />
        <TextWrapper className="TextWrapper">{children}</TextWrapper>
      </Anchor>
    </Wrapper>
  );
};

export default SupFilePreviewButton;
const Wrapper = styled.div`
  padding: 0px 0px;
  width: fit-content;
  &.button-wrapper {
    /* .MuiButtonBase-root {
      font-size: 12px;
      font-weight: 400;
      color: rgb(255 255 255);
      max-height: 36px;
      border: 1px solid #01579b;
      text-transform: none !important;
      letter-spacing: 1px !important;
      background: #3178af !important;
      min-width: 200px !important;
      max-width: 200px !important;
      border-radius: 20px !important;
      box-shadow: none !important;
      padding: 7px 12px !important;
      line-height: 1;
      align-items: center;
      -webkit-transition: border-radius 0.25s;
      transition: border-radius 0.25s;
      justify-content: flex-start;
      svg {
        margin: 0 10px 0 0;
        width: 22px;
        path {
        }
      }

      &:hover {
        background: #01579b !important;
      }
    } */
    &.rounded {
      .MuiButtonBase-root {
        background: transparent !important;
        color: #009970;
        border-radius: 20px !important;
        svg {
          /* margin: 0 0 10px 0; */
          path {
            fill: #009970;
          }
        }
      }
    }
  }

  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 11px;
    a {
      font-size: 11px;
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 12px;
    a {
      font-size: 11px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 13px;
    a {
    }
  }
  @media (max-width: 1365px) {
    font-size: 13px;
    a {
      font-size: 11px;
      min-width: 150px !important;
      max-width: 150px !important;
    }
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    font-size: 13px;
    /* a {
      font-size: 11px;
      min-width: 170px !important;
      max-width: 170px !important;
    } */
  }
  @media (min-width: 1366px) and (max-width: 4000px) {
    font-size: 14px;
    a {
    }
  }
`;
const Anchor = styled.a`
  cursor: pointer !important;
  font-size: 12px;
  font-weight: 400;
  color: rgb(255 255 255);
  max-height: 36px;
  color: #ffffff !important;
  border: 1px solid #01579b;
  text-transform: none !important;
  letter-spacing: 1px !important;
  background: #3178af !important;
  min-width: 200px !important;
  max-width: 200px !important;
  border-radius: 20px !important;
  box-shadow: none !important;
  padding: 7px 14px !important;
  line-height: 1;
  text-decoration: none !important;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  -webkit-transition: border-radius 0.25s;
  transition: border-radius 0.25s;
  justify-content: flex-start;
  svg {
    margin: 0 10px 0 0;
    width: 22px;
    path {
      /* fill: #009970; */
    }
  }

  &:hover {
    background: #01579b !important;
    color: #ffffff !important;
  }
`;
const TextWrapper = styled.div`
  /* max-width: 130px; */
  /* min-height: 15px; */
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;
