import React, { useState, useContext, useEffect, useRef } from "react";
import { BookingsContext } from "./BookingsContext";
import BookingsFileUploadModal from "./BookingsFileUploadModal";
import DatePicker from "react-date-picker";
import Select from "react-select";
// import { MultiSelect } from "react-multi-select-component";
import Multiselect from "multiselect-react-dropdown";
import TimePicker from "react-time-picker";
import dateFormat, { masks } from "dateformat";
import { v4 as uuidv4 } from "uuid";
// import Autosuggest from "react-autosuggest";
import ReactTooltip from "react-tooltip-rc";
import { Validation } from "./Ui/Validation";
import { toast } from "react-toastify";
import CancelModal from "./Ui/CancelModal";
import uploadImageFile from "../../../../assets/img/icon/upload/photos.svg";

import { submitValidation } from "./Ui/submitValidation";

import { ReactSearchAutocomplete } from "react-search-autocomplete";

let initialValue = {
  vehicle_number: "",
  customer_name: "",
  phone_number: "",
  date: new Date(),
  time: dateFormat(new Date(), "hh:MM"),

  vehicle_manufacturer: "",
  vehicle_manufacture_year: "",
  vehicle_model: "",
  vehicle_color: "",
  cancelBook: [],

  service_booked: "",
  coupon: "",
  additional_charges: "",
  discount: "",
  total: "",
  remarks: "",

  select_service: "",
  total_rate: "",
  grand_total: "",
  pending_payment: "",
  comments: "",
  partiallyPaidPrice: "",
};

const BookingsForm = (props) => {
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [bookFilter, setBookFilter] = useState([
    {
      name: "",
      customer_id: "",
    },
  ]);
  const [final, setFinal] = useState([]);

  const vehicleContext = [
    {
      name: "KL19 L 8588",
      year: 1972,
      customer_id: "33",
    },
    {
      name: "KL01 BB 5588",
      year: 2012,
      customer_id: "12",
    },
    {
      name: "KL05 X 6370",
      year: 2012,
      customer_id: "22",
    },
  ];

  const customerContext = [
    {
      name: "Sangi",
      customer_id: 12,
    },
    {
      name: "rTn",
      customer_id: 33,
    },
    {
      name: "D",
      customer_id: 22,
    },
  ];

  const multiselectRef = useRef();
  const {
    BookingsTable,
    SetBookingsTable,
    mappedBookingsData,
    setMappedBookingsData,
    viewReadOnly,
    setViewReadOnly,
    editMode,
    handleBookingsEdit,
    formChange,
    SetFormChange,
  } = useContext(BookingsContext);
  // const [bookTime, setBookTime] = useState(dateFormat(new Date(), "hh:MM"));
  // const [newBookDate, setNewBookDate] = useState(new Date());
  const [formBookings, setFormBookings] = useState(initialValue);
  // console.log(typeof (formBookings), "formBookings-formBookings");

  // const [value, onChange] = useState();
  // console.log(value);
  //Image Modal

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [vehicleType, isvehicleType] = useState("");
  const [partiallyPaid, setPartiallyPaid] = useState(false);
  const [payMethod, setPayMethod] = useState("");
  const [couponBtn, setCouponBtn] = useState("Apply Coupon");
  const [servicePrice, setServicePrice] = useState("");
  const [couponAmount, setCouponAmount] = useState("");
  const [couponDisplay, setCouponDisplay] = useState(false);
  const [selectService, setSelectService] = useState([]);
  const [formErrors, SetFormErrors] = useState(errorFields);
  const [error, setError] = useState(false);
  // let service = selectService[0];

  let couponCode = "coupon";
  let couponValue = 50;
  const couponClick = (e) => {
    e.preventDefault();
    // if (formBookings.coupon == couponCode) {
    //   setCouponAmount(`You Saved ${couponValue} INR`);
    //   setCouponBtn("Coupon Accepted");
    //   setCouponDisplay(true);
    // } else if (formBookings.coupon.value != couponCode) {
    //   setCouponAmount("");
    //   setCouponDisplay(false);
    //   setCouponBtn("Apply Coupon");
    // } else if (formBookings.coupon.length == null) {
    //   setCouponAmount("");
    //   // setCouponDisplay(false);
    //   setCouponBtn("Apply Coupon Code");
    // }
  };
  const errorFields = {
    name: "",
    email: "",
    phone: "",
    address: "",
    date_of_birth: "",
    blood_group_id: "",
    staff_id_proof_type: "",
    emergency_details_name: "",
    emergency_details_phone: "",
    emergency_details_relation_type: "",
    pic: [{}],
    idproof: [{}],
  };

  const [editBookingsId, setEditBookingsId] = useState("");
  const onChangeValidate = (field) => {
    console.log(field, "fieldfieldfieldfieldfield");
    const errors = Validation(BookingsTable, true, field);

    if (Object.keys(errors).length != 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      setError(false);
      SetFormErrors(errorFields);
    }
  };

  const BookingsSubmitHandler = (e) => {
    // console.log(formBookings, "------------on submit formBookings------------");
    e.preventDefault();
    let errors = Validation(formBookings);
    if (editMode) {
      handleBookingsEdit(
        {
          ...formBookings,
          vehicleType,
          partiallyPaid,
          payMethod,
          servicePrice,
          selectService,
        },
        editBookingsId
      );
    } else {
      SetBookingsTable((prevBookings) => [
        ...prevBookings,
        {
          ...formBookings,
          date: formBookings.date ? formBookings.date : new Date(),
          time: formBookings.time
            ? formBookings.time
            : dateFormat(new Date(), "hh:MM"),
          vehicleType,
          partiallyPaid,
          payMethod,
          servicePrice,
          selectService,
        },
      ]);
    }
    props.bookingformClose(false);

    const updateToast = () => {
      return editMode ? "Updated Successfully" : "Added Successfully";
    };
    toast.success(updateToast, {
      position: "bottom-right",
    });
  };
  // console.log(formBookings, "------------ after submit formBookings------------");
  const suggestions = [
    "KL-19-G-9927",
    "KL-01-S-1234",
    "KL-07-L-2255",
    "KL-01-M-369",
  ];

  const serviceOptions = [
    {
      id: 1,
      value: "CarWash",
      label: "CarWash",
      price: "250",
    },
    {
      id: 2,
      value: "Ceramic Coating",
      label: "Ceramic Coating",
      price: "10000",
    },
    {
      id: 3,
      value: "Ceramic Coating - 1",
      label: "Ceramic Coating - 1 ",
      price: "10000",
    },
    {
      id: 4,
      value: "Ceramic Coating - 2",
      label: "Ceramic Coating - 2",
      price: "10000",
    },
    {
      id: 5,
      value: "Ceramic Coating - 3",
      label: "Ceramic Coating - 3",
      price: "10000",
    },
  ];

  // console.log(newBookDate, "first ")
  useEffect(() => {
    let {
      partiallyPaid,
      vehicleType,
      payMethod,
      servicePrice,
      selectService,
      id,
      ...rest
    } = mappedBookingsData;

    console.log(mappedBookingsData, "-----mappedBookingsData-----");
    console.log(
      mappedBookingsData.customer_name,
      "mappedBookingsData.customer_name"
    );

    setEditBookingsId(mappedBookingsData.customer_name);
    setPartiallyPaid(false);
    isvehicleType(vehicleType ? vehicleType : []);
    setServicePrice(servicePrice ? servicePrice : "");
    setPayMethod(payMethod ? payMethod : []);
    setSelectService(selectService ? selectService : []);

    setFormBookings(rest);
    // SetFormData(mappedServiceData);

    // Unmount
    return () => {
      // console.log("un mounted,,,,");
      setFormBookings(initialValue);
      setPartiallyPaid(false);
      isvehicleType([]);
      setPayMethod([]);
      setServicePrice([]);
      setSelectService([]);

      console.log(partiallyPaid, "partiallyPaid-------");
    };
  }, [props.addBookings, mappedBookingsData]);
  let a = props.addBookings;
  // for Select Service states
  const handleSelectService = (selectedList, selectedItem) => {
    setSelectService(selectedList);

    // setFormBookings({ ...formBookings, select_service: e.id });
  };
  // const handleClear = () => {
  //   setServiceOptions((currentOptions) =>
  //     currentOptions.filter(
  //       (currentOption) => !formBookings.select_service.includes(currentOption)
  //     )
  //   );
  //   setFormBookings({ ...formBookings, select_service: [] });
  // };

  const removeService = (e) => {
    let deleteLabel = e.target.getAttribute("data-id");
    let filteredService = selectService.filter((el) => el.id != deleteLabel);
    setSelectService(filteredService);
    if (filteredService == 0) {
      multiselectRef.current.resetSelectedValues();
    }
  };
  // const onRemove = (selectedList, removedItem) => {
  //   console.log(selectedList, "onRemove selectedList");
  //   console.log(removedItem, "onRemove removedItem");
  //   setSelectService(selectedList)
  // }

  const priceFunc = (serviceId, price) => {
    let newVar = selectService.filter((e) => {
      if (e.id === serviceId) {
        e.price = price;
      }
      return e;
    });
    setSelectService(newVar);
  };
  let totalPrice = selectService.reduce(
    (total, selectService) => parseInt(selectService.price) + total,
    0
  );
  // let grandTotal =
  //   parseInt(totalPrice) +
  //   parseInt(formBookings.additional_charges) -
  //   parseInt(formBookings.discount);

  let grandTotal =
    parseInt(totalPrice) +
    (formBookings.additional_charges
      ? parseInt(formBookings.additional_charges)
      : 0) -
    (formBookings.discount ? parseInt(formBookings.discount) : 0);
  let pendingPay =
    parseInt(grandTotal) -
    parseInt(
      partiallyPaid && formBookings.partiallyPaidPrice
        ? formBookings.partiallyPaidPrice
        : 0
    );
  // arr.reduce((total, obj) => obj.credit + total,0)

  // console.log(totalPrice, "grandTotal");
  const handleKeyPress = (e) => {
    var key = e.key;
    var regex = /[0-9]|\./;
    // var regex = [0-9] ;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };
  const selectHandler = () => {
    console.log("event triggered");
  };
  console.log(
    mappedBookingsData,
    "mappedBookingsData ------ mappedBookingsData"
  );

  const handleOnSelect = (item) => {
    setBookFilter(
      customerContext.filter(
        (customerValue) => customerValue.customer_id == item.customer_id
      )
    );
  };
  const formatResult = (item) => {
    return (
      <>
        <span>name{item.name}</span>
      </>
    );
  };

  return (
    <form className="" onSubmit={BookingsSubmitHandler}>
      <div className={viewReadOnly ? "col-12 view-mode" : "col-12"}>
        {/* Service Center Name Block */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-phone"></i>Name
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <input
            readOnly={viewReadOnly}
            type="text"
            className="form-control"
            value={formBookings.name}
            onChange={(e) =>
              setFormBookings({
                ...formBookings,
                phone_number: e.target.value,
              })
            }
            onBlur={(e) => onChangeValidate("phone_number")}
          />
          {error && formErrors.phone_number ? (
            <span className="form-error">{formErrors.phone_number}</span>
          ) : (
            ""
          )}
        </div>
        {/* Phone Number Block */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-phone"></i>Phone Number
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <input
            readOnly={viewReadOnly}
            type="text"
            className="form-control"
            value={formBookings.phone_number}
            onChange={(e) =>
              setFormBookings({
                ...formBookings,
                phone_number: e.target.value,
              })
            }
            onBlur={(e) => onChangeValidate("phone_number")}
          />
          {error && formErrors.phone_number ? (
            <span className="form-error">{formErrors.phone_number}</span>
          ) : (
            ""
          )}
        </div>
        {/* Total Bookings Block */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-phone"></i>Total Bookings
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <input
            readOnly={viewReadOnly}
            type="text"
            className="form-control"
            value={formBookings.total_bookings}
            onChange={(e) =>
              setFormBookings({
                ...formBookings,
                phone_number: e.target.value,
              })
            }
            onBlur={(e) => onChangeValidate("phone_number")}
          />
        </div>
              {/* Total Bookings Block */}
              <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-phone"></i>Date
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <input
            readOnly={viewReadOnly}
            type="text"
            className="form-control"
            value={formBookings.date}
            onChange={(e) =>
              setFormBookings({
                ...formBookings,
                phone_number: e.target.value,
              })
            }
            onBlur={(e) => onChangeValidate("phone_number")}
          />
        </div>
        {mappedBookingsData.cancelData ? (
          <div className="">
            <div className="col-12 block-header main-title mt-4">
              <h6>
                <span className="header-title">Reason for Cancelling</span>
              </h6>
            </div>
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i class="fal fa-car"></i>Cancelled by
                </span>
              </div>
              <h6>
                {mappedBookingsData.cancelData.cancelled == "Service Admin"
                  ? "Service Admin"
                  : "Customer"}
              </h6>
            </div>
            <div className="input-group input-group-custom">
              <div className="input-group-prepend">
                <span className="input-group-text">
                  <i class="fal fa-car"></i>Reason
                </span>
              </div>
              <h6>
                {mappedBookingsData.cancelData
                  ? mappedBookingsData.cancelData.reason
                  : ""}
              </h6>
            </div>
          </div>
        ) : (
          ""
        )}

        {viewReadOnly ? (
          ""
        ) : (
          <div className="d-flex justify-content-end mb-4 mt-4">
            <button className="btn btn-secondary">Cancel</button>
            <button className="btn btn-primary ml-3">
              {editMode ? "Update Booking" : "Create Booking"}
            </button>
          </div>
        )}

        {/* {cancelModal && <CancelModal setCancelModal={setCancelModal} />} */}
      </div>
      <BookingsFileUploadModal
        setShow={setShow}
        showfilemodal={show}
        hidefilemodal={handleClose}
      />
    </form>
  );
};

export default BookingsForm;
