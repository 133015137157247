import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  MuiEvent,
} from "@mui/x-data-grid";
import styled from "styled-components";
import images from "../../../Layout/assets/img/fynlab.jpg";
import { Box, Pagination } from "@mui/material";
import { EditButton } from "../../../../EditButton";
import { PurchaseButton } from "../../../../PurchaseButton";
import MuiProductCard from "../../../../MuiProductCard";
import configData from "../../../../../config.json";
import Grid from "@mui/material/Grid";
import { ProductListModel } from "../../../../../api/services/sap/vap/models/productListModel";
import { getProductMeasurement } from "../../../../../api/services/sap/product-management";
import RightViewProductCard from "../../../../RightViewProductCard";
import Scrollbars from "react-custom-scrollbars-2";
import mainLoader from "../../../../../assets/img/loader/loader.gif";

type ProductData = {
  id: number;
  thumbnail: string;
  name: string;
  brand: string;
  type: string;
  source: string;
  pricePerUnit: number;
  stocks: number;
  out?: string;
};

type Props = {
  gridViewClick?: () => void;
  showGrid: boolean;
  tableDatas?: ProductListModel[];
  rightView: boolean;
  setRightView?: boolean;
  IsSelected?: boolean;
  // SetViewProduct: boolean;
  viewProduct: boolean;
  ProductViewClick: (
    e: GridRowParams,
    event: any,
    details?: GridCallbackDetails
  ) => void;
  handleCarddetails: (id?: number | undefined) => void;
  mappedProductData: any;
  setEditButton?: React.Dispatch<React.SetStateAction<boolean>>;
  setEditMode?: (value: React.SetStateAction<boolean>) => void;
  pageLoader: boolean;
  setPageLoader: React.Dispatch<React.SetStateAction<boolean>>;
};
const WindowLeft = ({
  gridViewClick,
  showGrid,
  rightView,
  tableDatas,
  setRightView,
  ProductViewClick,
  // SetViewProduct,
  viewProduct,
  handleCarddetails,
  IsSelected,
  mappedProductData,
  setEditButton,
  setEditMode,
  pageLoader,
  setPageLoader,
}: Props) => {
  //  table data
  // const [tableData, setTableData] = useState<ProductListModel[]>([]);
  //  const [productGrid, setProductGrid] = useState<ProductListModel[]>([]);
  // Columns Data
  const columns: GridColDef[] = [
    { field: "index", headerName: "ID", width: 80 },
    {
      field: "thumbnail",
      headerName: "Thumbnail",
      width: 130,
      renderCell: (params: GridRenderCellParams) => (
        <img
          src={
            params.row?.thumbnail
              ? `${configData.SERVER_URL}/${params.row?.thumbnail?.attachment}`
              : "/static/media/photos.012563c49cb4503fc4028085b7283e02.svg"
          }
          alt="thumbnail"
          style={{ width: "50px", height: "50px" }}
        />
      ),
    },
    { field: "product_name", headerName: "Name", width: 150 },

    {
      field: "brand_name",
      headerName: "Brand",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return <div>{params.row?.brand?.brand_name}</div>;
      },
    },

    {
      field: "type",
      headerName: "Type",
      width: 150,
      renderCell: (params: GridRenderCellParams) => {
        return <div>{params.row.type.title}</div>;
      },
    },

    {
      field: "source",
      headerName: "Source",
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <div>{params.row.source === 0 ? "Own stock" : "Via e-commerce"}</div>
      ),
    },
    {
      field: "price_per_unit",
      headerName: "Price Per Unit",
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{ textAlign: "right", width: "67%", display: "inline-block" }}
        >
          $ {params.row.price_per_unit}
        </span>
      ),
    },

    {
      field: "stock",
      headerName: "Stock Count",
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <span
          style={{
            textAlign: "center",
            width: "100%",
            display: "inline-block",
          }}
        >
          {params.row.stock}
        </span>
      ),
    },
    {
      field: "action",
      headerName: "Actions",
      width: 150,
      renderCell: (params: GridRenderCellParams) => (
        <StyledButtonWrapper>
          <EditButton className="edit-button" />
          <PurchaseButton className="purchase-button" />
        </StyledButtonWrapper>
      ),
    },
  ];

  const handleRightCardClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    item: ProductListModel
  ) => {
    handleCarddetails(item.id);
    setEditButton?.(false);
    setEditMode?.(false);
    getProductMeasurement();
    // setSelectedItem(item);
  };
  const itemsPerPage = 10; // You can adjust this as per your requirement
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedTableData = tableDatas?.slice(startIndex, endIndex);

  const totalPages = Math.ceil((tableDatas?.length || 0) / itemsPerPage);

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setCurrentPage(value);
  };
  console.log(tableDatas, "tableDatas");
  return (
    <>
      {pageLoader ? (
        <div className="loader-block">
          <img className="img-fluid" src={mainLoader} alt="" />
        </div>
      ) : (
        <div className="content-window-left">
          <div className="content-table-block">
            <div className="content-table table-responsive single-column-view">
              {rightView ? (
                <CardWrapper>
                  <Scrollbars style={{ height: `calc(100vh - 160px)` }}>
                    {paginatedTableData?.map((item) => (
                      <div
                        key={item.id}
                        onClick={(e) => handleRightCardClick(e, item)}
                      >
                        <RightViewProductCard
                          isSelected={
                            mappedProductData?.id === item.id ? true : false
                          }
                          item={item}
                          
                        />
                      </div>
                    ))}
                  </Scrollbars>
                  <PaginationWrapper>
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </PaginationWrapper>
                </CardWrapper>
              ) : (
                <Box
                  sx={{
                    maxWidth: "100%",
                    minHeight: "600px",
                    maxHeight: "650px",
                    height: "625px",
                  }}
                >
                  {showGrid ? (
                    <div style={{ display: "flex", width: "100%" }}>
                      <Grid container spacing={2}>
                        {tableDatas!.map((item) => {
                          return (
                            <Grid item xs={12} md={4} lg={3} xl={2}>
                              <div
                                onClick={(e) => handleRightCardClick(e, item)}
                              >
                                <MuiProductCard item={item} />
                              </div>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </div>
                  ) : (
                    <StyledDataGrid
                      onRowClick={ProductViewClick}
                      rows={tableDatas ?? []}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: { pageSize: 10, page: 0 },
                        },
                      }}
                    />
                  )}
                </Box>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WindowLeft;
const CardWrapper = styled.div`
  .MuiPaper-root.MuiCard-root {
    width: 240px;
  }
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const StyledDataGrid = styled(DataGrid)`
  background: white !important;
  border: none !important;

  &.content-window-left &.content-window &.change {
    background-color: hsl(0deg 0% 89.02%);
  }
  &.MuiDataGrid-main .css-204u17-MuiDataGrid-main {
    min-height: 500px !important;
    max-height: 550px !important;
    height: 525px !important;
  }
  .MuiDataGrid-virtualScroller {
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar-track {
      background: transparent !important;
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
      width: 3px;
      background: transparent !important;
      border-radius: 10px;
    }
  }
  &.MuiDataGrid-root {
    .MuiDataGrid-columnHeaders {
      background-color: #cbe8ff;
      min-height: 45px !important;
      max-height: 45px !important;
      line-height: 56px !important;

      .MuiDataGrid-columnHeader {
        min-height: 45px !important;
        max-height: 45px !important;
        line-height: 56px !important;
        color: darkblue;
        :nth-last-child(1) {
          width: 200px !important;
          max-width: 200px !important;
        }
      }
    }
    .MuiDataGrid-cell:focus-within {
      outline: none;
    }
    .MuiDataGrid-columnHeader:focus-within {
      outline: none;
    }
  }
  .MuiDataGrid-cell:focus-within {
    outline: none;
  }
  .MuiDataGrid-selectedRowCount {
    visibility: hidden;
    width: auto;
    height: auto;
  }
  .content-product-card-wrapper {
    flex-direction: row;
  }

  .MuiDataGrid-row {
    max-height: 100px !important;
  }
  .MuiDataGrid-cell {
    max-width: 193px !important;
    max-height: 100px !important;
    padding: 15px 10px !important;
    :nth-last-child(1) {
      padding: 0 1px !important;
    }
  }
  .MuiButtonBase-root {
    min-width: 50px !important;
    padding: 6px 10px !important;
    font-weight: 100;
    max-height: 25px;
  }
`;
const StyledButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
