import { sapAxios } from "../../axios";


const createService = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: 'services',
            data
        });
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        return Promise.reject(err);
    }
}
const getVehicleTypes = async () => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'GET',
            url: 'vehicle-types',
        });
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

const getVehicleVariants = async () => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'GET',
            url: 'vehicle-variants',
        });
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

const getServicesList = async () => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: 'get-service-list',
            data: { 'request_from': 'web_app' },
        });
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

/**
 * 
 * @param {Object} data { service_id: INTEGER } 
 * @returns Object[]
 */
const getService = async (data = {}) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: 'get-service',
            data,
        });
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

// const getServicesList = async (id = undefined) => {
//     const axiosInst = sapAxios();
//     const url = id ? ('services/' + id) : 'services';
//     try {
//         const res = await axiosInst({
//             method: 'GET',
//             url,
//         });
//         if (res.status) {
//             return res.data;
//         }
//         return Promise.reject(res);
//     } catch (err) {
//         console.log(err);
//         return Promise.reject(err);
//     }
// }

const deleteService = async (id) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'GET',
            url: `/services/delete/${id}`,
        });
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

const changeActiveStatus = async (data = {}) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: `/services/toggle-active-status`,
            data,
        });
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

const changeServiceCenterActiveStatus= async (data ={})=>{
   const axiosInst = sapAxios();
   try {
         const res = await axiosInst({
            method: 'POST',
            url: `/toggle-status`,
            data,
            });
        if (res.status) {
            return res.data;
            }
            return Promise.reject(res);
        } catch (err) {
    console.log(err);
    return Promise.reject(err);
    }
}

const getServiceCenterStatus = async (data)=>{
    const axiosInst = sapAxios();
    try {
          const res = await axiosInst({
             method: 'POST',
             url: `/get-center-status`,
             data,
             });
         if (res.status) {
             return res.data;
             }
             return Promise.reject(res);
         } catch (err) {
     console.log(err);
     return Promise.reject(err);
     }
}

export { createService, deleteService, getServicesList, getVehicleTypes, getVehicleVariants, changeActiveStatus, getService,changeServiceCenterActiveStatus,getServiceCenterStatus };