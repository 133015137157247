import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import RoundedWrapperCard from "../../../../../RoundedWrapperCard";
import Grid from "@mui/material/Grid";
import { CardHeadingLabel } from "../../../../../label";
import { Popover } from "@mui/material";
import SupApproveButton from "../../../../../SupApproveButton";
import SupRejectButton from "../../../../../SupRejectButton";
import SupFilePreviewButton from "../../../../../SupFilePreviewButton";
import MuiStyledTextArea from "../../../../../MuiStyledTextArea";
import configData from "../../../../../../config.json";
import MTooltip from "../../../../../MTooltip";
import {
  CloseSvgIcon,
  EditButtonIcon,
  EnterRightArrowIcon,
  InfoRejectButtonIcon,
} from "../../../../../icons";
import { VendorContext } from "../../context/VendorContext";

type Props = {
  onNextClick?: () => void;
  vendorId?: number | string;
  setSaveChangesClicked: React.Dispatch<React.SetStateAction<boolean>>;
  dataStored?: any;
};

const VendorDocumentation = ({
  onNextClick,
  vendorId,
  setSaveChangesClicked,
  dataStored,
}: Props) => {
  const { documentsData, setSaveChangesData } = useContext(VendorContext); //useStates
  console.log(documentsData, "documentsData");
  const [filteredOwnerData, setFilteredOwnerData] = React.useState<any>([]);
  // const [filteredCOOwnerData, setFilteredCOOwnerData] = React.useState<any>([]);
  const [filteredCoOwnerData, setFilteredCoOwnerData] = React.useState<any>([]);
  type detailsModel = {
    id?: number;
    name: string;
    approved: boolean;
    rejected: boolean;
    reason: string;
    file: string;
  };

  const primaryOwnerDefaultDetails: Array<detailsModel> = [
    {
      id: 1,
      name: "Passport",
      approved: false,
      rejected: false,
      reason: "",
      file: "passport.pdf",
    },
    {
      id: 2,
      name: "Emirates National Id",
      approved: false,
      rejected: false,
      reason: "",
      file: "nationalId.pdf",
    },
    {
      id: 3,
      name: "Visa",
      approved: false,
      rejected: false,
      file: "visa.pdf",
      reason: "",
    },
  ];

  const coOwnerDefaultDetails: Array<detailsModel> = [
    {
      id: 1,
      name: "Passport",
      approved: false,
      rejected: false,
      reason: "",
      file: "passport.pdf",
    },
    {
      id: 2,
      name: "Emirates National Id",
      approved: false,
      rejected: false,
      reason: "",
      file: "nationalId.pdf",
    },
    {
      id: 3,
      name: "Visa",
      approved: false,
      rejected: false,
      file: "visa.pdf",
      reason: "",
    },
  ];

  const [coOwnerDetails, setCoOwnerDetails] = React.useState<any[]>(
    coOwnerDefaultDetails
  );
  const [bussinessDetails, setBussinessDetails] = React.useState<any[]>([]);
  console.log(bussinessDetails, "bussinessDetails");
  const [reasonText, setReasonText] = React.useState<string>("");
  const [approved, setApproved] = React.useState(false);
  const [approveda, setApproveda] = React.useState(false);
  const [rejected, setRejected] = React.useState(false);
  const [edited, setEdited] = React.useState<boolean>(false);
  const [showRejectButton, setShowRejectButton] = React.useState(false);
  const [showApprove, setShowApprove] = React.useState<boolean>(true);
  const [showReject, setShowReject] = React.useState<boolean>(true);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [rejectedIndex, setRejectedIndex] = React.useState<number | undefined>(
    undefined
  );
  const [approvedIndex, setApprovedIndex] = React.useState<number | undefined>(
    undefined
  );
  const [rejectedType, setRejectedType] = React.useState<string>("");
  const [approvedType, setApprovedType] = React.useState<string>("");

  const handleChangeReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReasonText(event.target.value);
  };
  function handleSubmitClick() {
    onNextClick?.();
  }
  const handleTradingLicenseUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const handleEcommerceLicenseUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const handleServiceAgreementUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const handleNationalIdUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const handlePassportUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const handlevisaUpload = (file: File) => {
    console.log("Selected file:", file);
  };
  const [CoOwnerData, setCoOwnerData] = React.useState<{} | null>();
  const [CoOwnerList, setCoOwner] = React.useState<Array<any>>([
    {
      id: 1,
      coOwnername: "Co-owner1",
    },
  ]);
  const [isCoOwnerAdd, setIsCoOwnerAdd] = React.useState(false);
  const handleAddCoOwner = () => {
    setIsCoOwnerAdd(true);
    if (CoOwnerData) {
      setCoOwner((prevCoOwners) => [...prevCoOwners!, CoOwnerData]);
    } else if (CoOwnerList === null) {
      setCoOwner([{}]);
    } else {
      setCoOwner((prevCoOwners) => [...prevCoOwners!, {}]);
    }
    console.log("Clicked");
  };
  const [selectedModel, setSelectedModel] = React.useState("B2B");
  const handleModelChange = (event: any) => {
    setSelectedModel(event.target.name);
    console.log(event.target.name, "Checkbox");
  };
  console.log(CoOwnerList, "CoOwnerList ");
  React.useEffect(() => {
    console.log(CoOwnerData, "CoOwnerData ", CoOwnerList, "CoOwnerList");
  }, [CoOwnerData]);

  const handleToggleApproved = () => {
    setApproved(!approved);
  };

  const handleToggleApproveda = () => {
    setApproveda(!approveda);
  };

  const handleToggleRejected = () => {
    setRejected(true);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setEdited(false);
    setRejected(true);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleRejectedClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
    Type: string,
    reason: string
  ) => {
    handleClick(event);
    setRejectedIndex(index);
    setRejectedType(Type);
    setReasonText(reason);
  };
  const handleApprovedClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any,
    index: number,
    Type: string
  ) => {
    console.log(item, "itemdoc");
    handleClick(event);
    setApprovedIndex(index);
    setApprovedType(Type);
    handleToggleApproved();
    handleClose();
    setRejected(false);
    setSaveChangesClicked(false);
    if (Type === "bussinessDetails") {
      setReasonText("");
      setBussinessDetails((details) =>
        details.map((data) => {
          if (data.document_id_pk === item.document_id_pk) {
            return { ...data, status: 1, rejected_reason: "" };
          }
          return data;
        })
      );
    } else if (Type === "primaryOwner") {
      setReasonText("");
      setFilteredOwnerData({
        ...filteredOwnerData,
        documents: filteredOwnerData?.documents?.map((data: any) =>
          data.vendor_user_document_id_pk === item.vendor_user_document_id_pk
            ? { ...data, status: 1, rejected_reason: "" }
            : data
        ),
      });
    } else if (Type === "coOwnerDetails") {
      // setReasonText("");
      setFilteredCoOwnerData(
        filteredCoOwnerData?.map((owner: any, i: number) => {
          let tempOwnerDocs = owner?.documents?.map((doc: any) => {
            if (
              doc.vendor_user_document_id_pk === item.vendor_user_document_id_pk
            ) {
              return { ...doc, status: 1, rejected_reason: "" };
            }
            return doc;
          });
          return { ...owner, documents: tempOwnerDocs };
        })
      );
    }
  };

  const handleCloseReason = (e: any) => {
    handleClose();
    setRejected(true);
  };

  const handleSubmitReason = (e: any) => {
    if (rejectedType === "bussinessDetails") {
      setBussinessDetails(
        bussinessDetails?.map((item, i) => {
          if (i === rejectedIndex) {
            item.rejected_reason = reasonText;
            item.status = 2;
          }
          return item;
        })
      );
    } else if (rejectedType === "primaryOwner") {
      setFilteredOwnerData({
        ...filteredOwnerData,
        documents: filteredOwnerData?.documents?.map((data: any) =>
          data.vendor_user_document_id_pk === rejectedIndex
            ? { ...data, status: 2, rejected_reason: reasonText }
            : data
        ),
      });
    } else if (rejectedType === "coOwnerDetails") {
      setFilteredCoOwnerData(
        filteredCoOwnerData?.map((owner: any, i: number) => {
          let tempOwnerDocs = owner?.documents?.map((doc: any) => {
            if (doc.vendor_user_document_id_pk === rejectedIndex) {
              return { ...doc, status: 2, rejected_reason: reasonText };
            }
            return doc;
          });
          return { ...owner, documents: tempOwnerDocs };
        })
      );

      setCoOwnerDetails(
        coOwnerDetails?.map((item, i) => {
          if (i === rejectedIndex) {
            item.rejected_reason = reasonText;
            item.rejected = true;
          }
          return item;
        })
      );
    }
    handleToggleRejected();
    handleClose();
    setReasonText("");
  };

  const handleEnterClick = (e: any) => {
    if (e.key === "Enter" && reasonText) {
      // Do something when Enter key is pressed
      console.log(e, "key event!");
      console.log(reasonText, "reasonText", "Enter key pressed!");
      e.preventDefault();
      handleSubmitReason(e);
    }
  };

  const handleApprovEdit = (id: number, type: string) => {
    if (type === "bussinessDetails") {
      setBussinessDetails((details) =>
        details.map((data) =>
          data.document_id_pk === id ? { ...data, status: 0 } : data
        )
      );
    } else if (type === "primaryOwner") {
      setFilteredOwnerData({
        ...filteredOwnerData,
        documents: filteredOwnerData?.documents?.map((data: any) =>
          data.vendor_user_document_id_pk === id
            ? { ...data, status: 0, rejected_reason: "" }
            : data
        ),
      });
    } else if (type === "coOwnerDetails") {
      setFilteredCoOwnerData(
        filteredCoOwnerData?.map((owner: any, i: number) => {
          let tempOwnerDocs = owner?.documents?.map((doc: any) => {
            if (doc.vendor_user_document_id_pk === id) {
              return { ...doc, status: 0, rejected_reason: "" };
            }
            return doc;
          });
          return { ...owner, documents: tempOwnerDocs };
        })
      );

      setCoOwnerDetails((details) =>
        details.map((data) => (data.id === id ? { ...data, status: 2 } : data))
      );
    }
    // setSaveChangesClicked(false);
  };

  const handleRejectEdit = (id: number, type: string) => {
    if (type === "bussinessDetails") {
      setBussinessDetails((details) =>
        details.map((data) =>
          data.document_id_pk === id
            ? { ...data, status: 0, rejected_reason: "" }
            : data
        )
      );
    } else if (type === "primaryOwner") {
      setFilteredOwnerData({
        ...filteredOwnerData,
        documents: filteredOwnerData?.documents?.map((data: any) =>
          data.vendor_user_document_id_pk === id
            ? { ...data, status: 0, rejected_reason: "" }
            : data
        ),
      });
    } else if (type === "coOwnerDetails") {
      setFilteredCoOwnerData(
        filteredCoOwnerData?.map((owner: any, i: number) => {
          let tempOwnerDocs = owner?.documents?.map((doc: any) => {
            if (doc.vendor_user_document_id_pk === id) {
              return { ...doc, status: 0, rejected_reason: "" };
            }
            return doc;
          });
          return { ...owner, documents: tempOwnerDocs };
        })
      );
    }
  };

  //filter the CoOwner documents

  // setFilteredCoOwnerData(tempCoOwner);
  useEffect(() => {
    setBussinessDetails(
      documentsData?.business
        ?.map((doc: any, i: number) => {
          return { ...doc };
        })
        ?.sort((a: any, b: any) => a.doc_type_id - b.doc_type_id)
    );
    setFilteredOwnerData(
      documentsData?.owners
        ?.filter((item: any) => {
          return item.user_type === 1;
        })
        .map((data: any) => {
          return {
            ...data,
            documents: data?.documents
              ?.map((doc: any, i: number) => {
                return { ...doc };
              })
              .sort((a: any, b: any) => a.doc_type_id - b.doc_type_id),
          };
        })?.[0] ?? {}
    );
    setFilteredCoOwnerData(
      documentsData?.owners
        ?.filter((item: any) => {
          return item.user_type === 2;
        })
        .map((data: any) => {
          return {
            ...data,
            documents: data?.documents
              ?.map((doc: any, i: number) => {
                return { ...doc };
              })
              .sort((a: any, b: any) => a.doc_type_id - b.doc_type_id),
          };
        }) ?? []
    );
  }, [documentsData]);
  let bussinessDocsPayload =
    bussinessDetails &&
    bussinessDetails?.map((doc, i) => {
      return {
        vendor_id: vendorId,
        type_id: 1,
        status: doc.status,
        document_id: doc.document_id_pk,
        rejected_reason: doc.rejected_reason ?? "",
      };
    });
  let filteredOwnerDataDocsPayload =
    filteredOwnerData &&
    filteredOwnerData?.documents?.map((doc: any, i: number) => {
      console.log("doccc", doc);
      return {
        vendor_id: vendorId,
        type_id: 2,
        status: doc.status,
        document_id: doc.vendor_user_document_id_pk,
        rejected_reason: doc.rejected_reason ?? "",
      };
    });
  let filteredCOownerDataDocsPayload =
    filteredCoOwnerData &&
    filteredCoOwnerData?.map((owner: any, i: number) => {
      let temp = owner?.documents?.map((item: any) => {
        let tempDoc;
        tempDoc = {
          vendor_id: vendorId,
          type_id: 2,
          status: item.status,
          document_id: item.vendor_user_document_id_pk,
          rejected_reason: item.rejected_reason ?? "",
        };
        return tempDoc;
      });
      return temp;
    });
  // Using concat and spread to flatten the array
  let flattenedArray = [].concat(...filteredCOownerDataDocsPayload);

  // Resulting single array
  console.log(flattenedArray);
  useEffect(() => {
    setSaveChangesData([
      ...(bussinessDocsPayload ?? []),
      ...(filteredOwnerDataDocsPayload ?? []),
      ...(flattenedArray ?? []),
    ]);
  }, [bussinessDetails, filteredOwnerData, filteredCoOwnerData]);

  // console.log(saveChangesData, "saveChangesData");
  console.log(
    [
      ...(bussinessDocsPayload ?? []),
      ...(filteredOwnerDataDocsPayload ?? []),
      ...(flattenedArray ?? []),
    ],
    "allllll l"
  );
  console.log(filteredOwnerData, "filteredOwnerDatahjgjh");
  console.log(bussinessDocsPayload, "payload 1");
  console.log(filteredOwnerDataDocsPayload, "payload 2");
  console.log(flattenedArray, "payload 3 flattenedArray");
  console.log(filteredCoOwnerData, "filteredCOownerData payload 3");

  return (
    <StyledWrapper className="StyledWrapper">
      {/* Registration Details */}

      <StyledSubWrapper className="StyledSubWrapper">
        <CardHeadingLabel className="CardHeadingLabel">
          Bussiness Details
        </CardHeadingLabel>

        <RoundedWrapperCard className="RoundedWrapperCard sup-view">
          {bussinessDetails?.length > 0 ? (
            bussinessDetails?.map((item, i) => {
              return (
                <StyledGridWrapper
                  className="StyledGridWrapper"
                  key={i}
                  container
                  sx={{
                    paddingBottom: item?.status !== 0 ? "25px !important" : "",
                    padding: "0 16px",
                  }}
                  spacing={2}
                >
                  <StyledTitleWrapper>
                    <FieldLabel className="FieldLabel">
                      {item?.docType?.title}{" "}
                      {item.doc_type_id === 2 ? <span>(if any)</span> : ""}
                    </FieldLabel>
                  </StyledTitleWrapper>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <ButtonGroupWrapper className="ButtonGroupWrapper file-block">
                      <SupFilePreviewButton
                        href={`${configData.SERVER_URL +
                          "/uploads/vendor-business-docs/"
                          }${item?.doc_name}`}
                        download={true}
                      >
                        {item?.doc_name}
                      </SupFilePreviewButton>
                    </ButtonGroupWrapper>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6} className="">
                    <ButtonGroupWrapper className="ButtonGroupWrapper end">
                      {
                        <>
                          {(item.status === 0 || item.status === 1) && (
                            <SupApproveButton
                              rounded={item.status === 1}
                              disabled={
                                item.status === 1 || dataStored.status === 3
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                handleApprovedClick(
                                  e,
                                  item,
                                  i,
                                  "bussinessDetails"
                                )
                              }
                            />
                          )}
                          {item.status === 1 &&
                            dataStored.status !== 3 &&
                            dataStored.status !== 4 ? (
                            <StyledEditButtonwrapper>
                              <StyledEditButton
                                onClick={(e) => {
                                  handleApprovEdit(
                                    item?.document_id_pk,
                                    "bussinessDetails"
                                  );
                                }}
                              >
                                <EditButtonIcon />
                              </StyledEditButton>
                            </StyledEditButtonwrapper>
                          ) : null}
                        </>
                      }
                      {/* {item.approved && ( */}
                      <>
                        {item.status !== 1 && (
                          <RejectButtonWrapper>
                            <SupRejectButton
                              rounded={item.status === 2}
                              disabled={
                                item.status === 2 || dataStored.status === 3
                                  ? true
                                  : false
                              }
                              onClick={(e) =>
                                handleRejectedClick(
                                  e,
                                  i,
                                  "bussinessDetails",
                                  item.rejected_reason
                                )
                              }
                              aria-describedby={id}
                            />
                            <span className="rejected-text">
                              {item.rejected_reason ? item.rejected_reason : ""}
                            </span>
                          </RejectButtonWrapper>
                        )}

                        {item.status === 2 &&
                          item.status !== 1 &&
                          dataStored.status !== 3 &&
                          dataStored.status !== 4 ? (
                          <RejectEditButtonWrapper className="RejectEditButtonWrapper handleEdit">
                            <StyledEditButton
                              onClick={(e) =>
                                handleRejectEdit(
                                  item?.document_id_pk,
                                  "bussinessDetails"
                                )
                              }
                            >
                              <EditButtonIcon />
                            </StyledEditButton>
                            <MTooltip
                              title={item.rejected_reason}
                              reasontitle="Reason"
                            >
                              <StyledInfoRejectButton>
                                <InfoRejectButtonIcon></InfoRejectButtonIcon>
                              </StyledInfoRejectButton>
                            </MTooltip>
                          </RejectEditButtonWrapper>
                        ) : null}
                      </>
                    </ButtonGroupWrapper>
                  </Grid>
                </StyledGridWrapper>
              );
            })
          ) : (
            <h5 className="no-data-text">No data available </h5>
          )}
        </RoundedWrapperCard>
      </StyledSubWrapper>

      <StyledSubWrapper className="StyledSubWrapper">
        <CardHeadingLabel className="CardHeadingLabel">
          Primary Owner Details - {filteredOwnerData.name}
        </CardHeadingLabel>
        <RoundedWrapperCard className="RoundedWrapperCard sup-view">
          {filteredOwnerData?.documents?.length > 0 ? (
            filteredOwnerData?.documents?.map((item: any, i: number) => {
              return (
                <>
                  <StyledGridWrapper
                    className="StyledGridWrapper"
                    container
                    key={i}
                    sx={{
                      paddingBottom:
                        item?.status !== 0 ? "25px !important" : "",
                      padding: "0 16px",
                    }}
                    spacing={2}
                  >
                    <StyledTitleWrapper>
                      <FieldLabel className="FieldLabel">
                        {item?.docType?.title}
                      </FieldLabel>
                    </StyledTitleWrapper>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                      <ButtonGroupWrapper className="ButtonGroupWrapper file-block">
                        <SupFilePreviewButton
                          href={`${configData.SERVER_URL + "/uploads/vendor-user-docs/"
                            }${item?.doc_name}`}
                          download={true}
                        >
                          {item?.doc_name}
                        </SupFilePreviewButton>
                      </ButtonGroupWrapper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className="">
                      <ButtonGroupWrapper className="ButtonGroupWrapper end">
                        {
                          <>
                            {(item.status === 1 || item.status === 0) && (
                              <SupApproveButton
                                rounded={item.status === 1}
                                disabled={
                                  item.status === 1 || dataStored.status === 3
                                    ? true
                                    : false
                                }
                                onClick={(e) =>
                                  handleApprovedClick(
                                    e,
                                    item,
                                    i,
                                    "primaryOwner"
                                  )
                                }
                              />
                            )}

                            {item.status === 1 &&
                              dataStored.status !== 3 &&
                              dataStored.status !== 4 ? (
                              <StyledEditButtonwrapper>
                                <StyledEditButton
                                  onClick={(e) =>
                                    handleApprovEdit(
                                      item?.vendor_user_document_id_pk,
                                      "primaryOwner"
                                    )
                                  }
                                >
                                  <EditButtonIcon />
                                </StyledEditButton>
                              </StyledEditButtonwrapper>
                            ) : null}
                          </>
                        }
                        {/* {item.approved && ( */}
                        <>
                          {item.status !== 1 && (
                            <RejectButtonWrapper>
                              <SupRejectButton
                                rounded={item.status === 2}
                                disabled={
                                  item.status === 2 || dataStored.status === 3
                                    ? true
                                    : false
                                }
                                onClick={(e) =>
                                  handleRejectedClick(
                                    e,
                                    item?.vendor_user_document_id_pk,
                                    "primaryOwner",
                                    item.rejected_reason
                                  )
                                }
                                aria-describedby={id}
                              />
                              <span className="rejected-text">
                                {item.rejected_reason
                                  ? item.rejected_reason
                                  : ""}
                              </span>
                            </RejectButtonWrapper>
                          )}

                          {item.status === 2 &&
                            item.status !== 1 &&
                            dataStored.status !== 3 &&
                            dataStored.status !== 4 ? (
                            <RejectEditButtonWrapper className="RejectEditButtonWrapper handleEdit">
                              <StyledEditButton
                                onClick={(e) =>
                                  handleRejectEdit(
                                    item?.vendor_user_document_id_pk,
                                    "primaryOwner"
                                  )
                                }
                              >
                                <EditButtonIcon />
                              </StyledEditButton>

                              <MTooltip
                                title={item.rejected_reason}
                                reasontitle="Reason"
                              >
                                <StyledInfoRejectButton>
                                  <InfoRejectButtonIcon></InfoRejectButtonIcon>
                                </StyledInfoRejectButton>
                              </MTooltip>
                            </RejectEditButtonWrapper>
                          ) : null}
                        </>
                      </ButtonGroupWrapper>
                    </Grid>
                  </StyledGridWrapper>
                </>
              );
            })
          ) : (
            <h5 className="no-data-text">No data available</h5>
          )}
        </RoundedWrapperCard>
      </StyledSubWrapper>

      <StyledPopover
        className="StyledPopover"
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <FieldLabel className="FieldLabel">
          {edited ? "Edit  Reason" : "Rejected Reason"}
        </FieldLabel>
        <MuiStyledTextArea
          onChange={handleChangeReason}
          placeholder="Write a note"
          onKeyDown={handleEnterClick}
          value={reasonText}
        />

        <PopOverButtonWrapper>
          <CloseSvgIcon onClick={handleCloseReason} />
          <EnterRightArrowIcon onClick={handleSubmitReason} />
        </PopOverButtonWrapper>
      </StyledPopover>

      {filteredCoOwnerData.length > 0
        ? filteredCoOwnerData?.map((item: any, i: number) => {
          console.log("itemssss", item)
          return (
            item.documents.length === 0 ? "" : (
              <StyledSubWrapper className="StyledSubWrapper">
                <CardHeadingLabel className="CardHeadingLabel">
                  Co-Owner's Details - {item.name}
                </CardHeadingLabel>
                <RoundedWrapperCard className="RoundedWrapperCard sup-view">
                  {item?.documents ? (
                    item?.documents?.map((doc: any, i: number) => {
                      return (
                        <>
                          <StyledGridWrapper
                            className="StyledGridWrapper"
                            container
                            key={i}
                            sx={{
                              paddingBottom:
                                doc?.status !== 0 ? "25px !important" : "",
                              padding: "0 16px",
                            }}
                            spacing={2}
                          >
                            <StyledTitleWrapper>
                              <FieldLabel className="FieldLabel">
                                {doc?.docType?.title}
                              </FieldLabel>
                            </StyledTitleWrapper>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <ButtonGroupWrapper className="ButtonGroupWrapper file-block">
                                <SupFilePreviewButton
                                  href={`${configData.SERVER_URL +
                                    "/uploads/vendor-user-docs/"
                                    }${doc?.doc_name}`}
                                  download={true}
                                >
                                  {doc?.doc_name}
                                </SupFilePreviewButton>
                              </ButtonGroupWrapper>
                            </Grid>
                            <Grid
                              item
                              xs={12}
                              sm={12}
                              md={6}
                              lg={6}
                              className=""
                            >
                              <ButtonGroupWrapper className="ButtonGroupWrapper end">
                                {
                                  <>
                                    {(doc.status === 1 || doc.status === 0) && (
                                      <SupApproveButton
                                        rounded={doc.status === 1}
                                        disabled={
                                          doc.status === 1 ||
                                            dataStored.status === 3
                                            ? true
                                            : false
                                        }
                                        onClick={(e) =>
                                          handleApprovedClick(
                                            e,
                                            doc,
                                            i,
                                            "coOwnerDetails"
                                          )
                                        }
                                      />
                                    )}

                                    {doc.status === 1 &&
                                      dataStored.status !== 3 &&
                                      dataStored.status !== 4 ? (
                                      <StyledEditButtonwrapper>
                                        <StyledEditButton
                                          onClick={(e) =>
                                            handleApprovEdit(
                                              doc?.vendor_user_document_id_pk,
                                              "coOwnerDetails"
                                            )
                                          }
                                        >
                                          <EditButtonIcon />
                                        </StyledEditButton>
                                      </StyledEditButtonwrapper>
                                    ) : null}
                                  </>
                                }
                                {/* {item.approved && ( */}
                                <>
                                  {doc.status !== 1 && (
                                    <RejectButtonWrapper>
                                      <SupRejectButton
                                        rounded={doc.status === 2}
                                        disabled={
                                          doc.status === 2 ||
                                            dataStored.status === 3
                                            ? true
                                            : false
                                        }
                                        onClick={(e) =>
                                          handleRejectedClick(
                                            e,
                                            doc.vendor_user_document_id_pk,
                                            "coOwnerDetails",
                                            doc.rejected_reason
                                          )
                                        }
                                        aria-describedby={id}
                                      />
                                      <span className="rejected-text">
                                        {doc.rejected_reason
                                          ? doc.rejected_reason
                                          : ""}
                                      </span>
                                    </RejectButtonWrapper>
                                  )}

                                  {doc.status === 2 &&
                                    doc.status !== 1 &&
                                    dataStored.status !== 3 &&
                                    dataStored.status !== 4 ? (
                                    <RejectEditButtonWrapper className="RejectEditButtonWrapper handleEdit">
                                      <StyledEditButton
                                        onClick={(e) =>
                                          handleRejectEdit(
                                            doc?.vendor_user_document_id_pk,
                                            "coOwnerDetails"
                                          )
                                        }
                                      >
                                        <EditButtonIcon />
                                      </StyledEditButton>
                                      <MTooltip
                                        title={doc.rejected_reason}
                                        reasontitle="Reason"
                                      >
                                        <StyledInfoRejectButton>
                                          <InfoRejectButtonIcon></InfoRejectButtonIcon>
                                        </StyledInfoRejectButton>
                                      </MTooltip>
                                    </RejectEditButtonWrapper>
                                  ) : null}
                                </>
                              </ButtonGroupWrapper>
                            </Grid>
                          </StyledGridWrapper>
                        </>
                      );
                    })
                  ) : (
                    <h5 className="no-data-text">No data available</h5>
                  )}
                </RoundedWrapperCard>
              </StyledSubWrapper>
            )

          );
        })
        : ""}
    </StyledWrapper>
  );
};

export default VendorDocumentation;
const StyledWrapper = styled.div`
  display: "block";
  //Media Query ---->
  @media (min-width: 320px) and (max-width: 575px) {
    .StyledSubWrapper {
      .FieldLabel {
        font-size: 13px !important ;
      }
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    .StyledSubWrapper {
      .FieldLabel {
        font-size: 13px !important ;
      }
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .StyledSubWrapper {
      .FieldLabel {
        font-size: 13px !important ;
      }
    }
  }
  @media (max-width: 992px) {
    .RoundedWrapperCard {
      margin: 0 !important;
      .ButtonGroupWrapper {
        &.file-block {
          width: fit-content !important;
          display: block !important;
        }
      }
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .RoundedWrapperCard {
      .FieldLabel {
        font-size: 13px !important ;
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1365px) {
  }
  @media (min-width: 1366px) and (max-width: 4000px) {
  }
`;
const StyledEditButton = styled.button`
  border: none !important;
  background: transparent;
`;
const StyledEditButtonwrapper = styled.div`
  align-items: center;
`;
const StyledInfoRejectButton = styled.button`
  border: none !important;
  background: transparent;
`;

const StyledPopover = styled(Popover)`
  /* display: "block"; */
  padding: 0 0;
  .MuiPaper-root.MuiPaper-elevation {
    padding: 15px 20px !important;
    right: 156px !important;
    left: unset !important;
    border-radius: 5px !important;
    margin: 5px 0 0 0 !important;
  }
`;
const PopOverButtonWrapper = styled.div`
  /* display: "block"; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0px;
  svg {
    cursor: pointer;
    width: 30px !important;
    height: 30px !important;
  }
`;
const RejectButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  span {
    width: 100%;
    height: 24px;
    min-width: 100px;
    max-width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;

    &.rejected-text {
      color: #f67979;
    }
  }
`;

const RejectEditButtonWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

const ButtonGroupWrapper = styled.div`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  &.file-block {
    /* width: fit-content !important; */
  }
  &.end {
    justify-content: end;
    align-items: flex-start;
    gap: 0px 6px;
  }
`;

const StyledSubWrapper = styled.div`
  display: "block";
  width: 100%;
  padding: 0 0 30px 0;
  .no-data-text {
    padding: 18px 0;
    margin: 18px 0;
  }
  .CardHeadingLabel {
    margin: 15px 0px 15px;
  }
  .RoundedWrapperCard {
    margin: 0px 25px 0px 25px;
    &.sup-view {
      max-height: 410px;
      background: #f2f9ff;
      padding: 25px 10px 5px 25px;
      .StyledGridWrapper {
        margin-bottom: 25px;
        /* &:nth-last-child(1) {
          margin-bottom: 0px !important;
        } */
      }
    }
  }
`;
const StyledTitleWrapper = styled.div`
  display: "block";
  width: 100%;
  text-align: start;
`;
const StyledGridWrapper = styled(Grid)`
  /* display: "block"; */
  .styled-PhoneNumberField {
    .FieldWrapper {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73;
      input {
        background: #e8e8e8 !important;
      }
    }
  }
  .background-gray {
    div {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73 !important;
    }
    .react-tel-input {
      background: #e8e8e8;
    }
  }
  .textfield-wrapper {
    max-width: 100% !important;
  }
`;
const FieldLabel = styled.div`
  text-align: left;
  margin: 2px 0px 0px 0px;
  font-weight: 600;
  font-size: 14px !important;
  letter-spacing: 1px;
  color: black !important;
  &.field-value {
    font-weight: 500;
    font-size: 14px !important;
    color: black !important;
  }
  span {
    font-weight: 600;
    color: #a09d9d;
  }
  .validField {
    color: #ff0000;
  }
`;
