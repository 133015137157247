import React, { useState, useRef } from "react";
import uploadImageFile from "../../assets/img/icon/upload/photos.svg";
import styled from "styled-components";
import ImageUrl from "../ImageUrl";
import { elements } from "../../assets/img/cards/chart";

type Props = {
  multiple?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onRemoveFile?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  setFiles?: React.Dispatch<React.SetStateAction<File[] | null>>;
  defaultValue?: File[] | null;
  thumbnail?: boolean;
};

const FileUploader = ({
  multiple,
  disabled,
  onChange,
  defaultValue,
  setFiles,
  thumbnail,
}: Props) => {
 
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [imageFiles, setImageFiles] = useState<any>([]);
  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (disabled) {
      return;
    } else {
      setIsDragging(true);
    }
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (disabled) {
      return;
    } else {
      setIsDragging(false);
      const files: File[] = Array.from(e.dataTransfer.files);
      // Use URL.createObjectURL to create a temporary URL for each selected image
      mapFilesOnChangeOrDrop(files);
      console.log(files, "drop fileese", e.dataTransfer, "drope");
    }
  };

  const mapFilesOnChangeOrDrop = (filesArray: any) => {
    console.log(filesArray,'filesArray')
    const imageFilesWithPreview = filesArray.map((file: any) => ({
      file,
      previewURL: URL.createObjectURL(file),
    }));
console.log(imageFilesWithPreview,'imageFilesWithPreview')
    // Update state with the array of image files and their preview URLs
    setImageFiles([...imageFiles, ...imageFilesWithPreview]);
  };
  //onChange Function
  const handleImageChange = (event: any) => {
    // Convert FileList to an array
    const filesArray: File[] = Array.from(event.target.files);
    setFiles?.([...defaultValue!, ...filesArray]); //set files for callback
    // Use URL.createObjectURL to create a temporary URL for each selected image
    mapFilesOnChangeOrDrop(filesArray);
  };

  const filterImages = (filterIndex: number) => {
    // debugger;
    const filterItems = imageFiles.filter((e: any, index: number) => {
      return index !== filterIndex;
    });
    console.log("filterItems", filterItems, imageFiles, "imageFiles");
    setFiles?.(
      filterItems?.map((element: any) => {
        return element.file;
      })
    );
    console.log(filterItems, " filterItems");
    setImageFiles(filterItems);
    if (filterItems.length > 0) {
      fileInputRef.current!.value = "";
    }
  };
  console.log(imageFiles, "imageFiles");
  console.log(fileInputRef.current?.files, "fileInputRef.current");

  const thumbnailHolder = thumbnail && imageFiles.length > 0;
  return (
    <Container>
      {!thumbnailHolder && (
      <FileWrapper
        onDragEnter={handleDragEnter}
        onDragOver={(e) => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={() => fileInputRef.current?.click()}
        className={`filewrapper ${isDragging ? "dragging" : ""}`}
        aria-disabled={disabled}
      >
        <input
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => handleImageChange(e)}
          ref={fileInputRef}
          multiple={multiple!}
          disabled={disabled}
        />
        {isDragging ? (
          <DragTextContainer>
            <p>Drop the files here...</p>
          </DragTextContainer>
        ) : (
          <div className="img-wrapper">
            <div className="upload-section justify-content-center">
              <div className="upload-img">
                <img
                  src={uploadImageFile}
                  alt="loading..."
                  style={{ width: "80px" }}
                />
              </div>
            </div>
            Upload
          </div>
        )}
      </FileWrapper>
      )} 
      {imageFiles.map((image: any, index: number) => (
        <ImageUrl
          key={index}
          src={image.previewURL}
          index={index}
          onRemoveClick={filterImages}
        />
      ))}
    </Container>
  );
};
export default FileUploader;
const Container = styled.div`
  display: flex;
  gap: 10px !important;
  flex-wrap: wrap;
  margin: 5px 0px;
`;
const DragTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

const FileWrapper = styled.div`
  background: #e5f0ff !important;
  border: 2px dashed #72baff !important;
  border-radius: 17px !important;
  font-size: 18px !important;
  margin: 0 !important;
  height: 150px !important;
  width: 150px !important;
  color: #0083ff !important;
  text-align: center !important;
  cursor: pointer !important;

  &.dragging {
    background: #f0f0f0 !important;
  }

  .img-wrapper {
    padding: 30px;
  }
`;
