import React, { Fragment, useContext, useEffect, useState } from "react";
import { StaffContext } from "./StaffContext";
import configData from "../../../../config.json";
import { Scrollbars } from "react-custom-scrollbars-2";
import placeholderImg from "../../../../assets/img/user/3.png";
import { v4 as uuidv4 } from "uuid";
import { toast } from "react-toastify";
import { createStaff } from "../../../../api/services/sap/staff-management";
import Select from "react-select";
import uploadImageFile from "../../../../assets/img/icon/upload/photos.svg";
import uploadFileId from "../../../../assets/img/icon/upload/add-file.svg";
import DatePicker from "react-date-picker";
import { getLookupDataAPI } from "../../../../api/services/common/commonAPIs";
import styled from "styled-components";
import { Validation } from "./Ui/Validation";
import { getServiceConfiguration } from "../../../../api/services/sap/bookings-management";
import { usePhoneExtension } from "../../../hooks/usePhoneExtension";

const EditStaffform = (props) => {
  const {
    staffTable,
    setStaffTable,
    pic,
    setPic,
    editpic,
    setEditPic,
    viewStaff,
    SetViewStaff,
    editstaff,
    SetEditStaff,
    id,
    idproof,
    setIdProof,
    setAddonId,
    mappedStaff,
    setMappedStaff,
    handleStaffEdit,
    editImageSubmit,
    setEditImageSubmit,
    editFormSubmit,
    getStaffData,
    setEditFormSubmit,
    formChange,
    SetFormChange,
    editProofSubmit,
    setEditProofSubmit,
    bloodGroups,
    setBloodGroups,
    idproofType,
    setIdproofType,
    relationType,
    setRelationType,
    gender,
    setGender,
    updateOrCreateStaff,
    update,
    addonImages,
    addProofSubmit,
    setProofSubmit,
    addonId,
    setAddonImages,
    staffConfigData,
    setStaffConfigData,
  } = useContext(StaffContext);

  const [editStaffform, SetEditStaffForm] = useState({ ...mappedStaff });
  const [selectedIdProofValue, setSelectedIdProofValue] = useState([]);
  const [removeMappedIdProof, setremoveMappedIdProof] = useState(false);
  const [formErrors, SetFormErrors] = useState({});
  const [error, setError] = useState(false);
  const phoneExtension = usePhoneExtension();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {
          table_reference: ["tax_types", "payment_methods", "id_proof_types"],
        };
        const response = await getServiceConfiguration(data);
        setStaffConfigData(response.id_proof_types);
      } catch (error) {}
    };

    fetchData();
  }, []);

  let BloodOptions = [{}];
  // let IdOptions = [{}];
  let RelationOptions = [{}];
  let GenderOption = [{}];

  BloodOptions = bloodGroups.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  const IdOptions = staffConfigData?.map((item) => ({
    label: item.title,
    value: item.id_proof_type_id_pk.toString(),
  }));

  RelationOptions = relationType.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  GenderOption = gender.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  const onChangeValidate = (field, value) => {
    const errors = Validation(editStaffform, value, true, field);

    if (Object.keys(errors).length != 0) {
      setError(true);
      if (Object.keys(formErrors).length != 0) {
        const updatedErrorObject = { ...formErrors, ...errors };
        SetFormErrors(updatedErrorObject);
      } else {
        SetFormErrors(errors);
      }
    } else {
      if (Object.keys(formErrors).length != 0) {
        // delete formErrors[field];
        const updatedErrorObject = { ...formErrors };
        SetFormErrors(updatedErrorObject);
      } else {
        setError(false);
        // SetFormErrors(errorFields);
      }
    }
  };
  // let a = Array.from(props.pic);
  const sideEffect = () => {
    const bloodGroup = getLookupDataAPI({ type: "blood_group" });
    // const idproof = getLookupDataAPI({ type: "idproof_type" });
    const relationType = getLookupDataAPI({ type: "relation_type" });
    const gender = getLookupDataAPI({ type: "gender" });

    bloodGroup
      .then((res) => {
        setBloodGroups(res.data);
      })
      .catch((e) => {});

    relationType
      .then((res) => {
        setRelationType(res.data);
      })
      .catch((e) => {});

    gender
      .then((res) => {
        setGender(res.data);
      })
      .catch((e) => {
        //error handling
      });
  };

  useEffect(sideEffect, []);

  const StaffEditSubmitHandler = (e) => {
    e.preventDefault();
    const formData = {
      ...editStaffform,
    };

    setEditImageSubmit(false);
    const updatedStaffData = createStaff({
      ...formData,
      profile_image: mappedStaff.profile_image.length
        ? [Object.assign({}, ...mappedStaff.profile_image)]
        : (mappedStaff["profile_image"] = [...addonImages]),
    });

    updatedStaffData
      .then((res) => {
        let index = undefined;
        let newTable = [];
        let existingRecord = staffTable.filter((item, i) => {
          const value = item.id === res.staff_id_pk;
          if (value) index = i;
          return value;
        });
        if (existingRecord.length) {
          newTable = staffTable.map((item, i) => {
            if (i === index) {
              item = {
                id: res.staff_id_pk,
                profile: res.profile_image,
                name: res.name,
                email: res.email,
                phone: res.phone,
                designation: res.designation,
                gender: res.gender,
              };
            }

            return item;
          });
        } else {
          newTable = [
            {
              id: res.staff_id_pk,
              profile: res.profile_image,
              name: res.name,
              email: res.email,
              phone: res.phone,
              designation: res.designation,
              gender: res.gender,
            },
            ...staffTable,
          ];
          setStaffTable(newTable);
          setEditFormSubmit(true);
          setPic([]);
          setIdProof([]);
          setAddonId([]);
          props.closeStaffEdit(false);
          toast.success("Updated successfully", {
            position: "top-right",
          });
        }
        setStaffTable(newTable);
        setEditFormSubmit(true);
        setPic([]);
        setIdProof([]);
        setAddonId([]);
        props.closeStaffEdit(false);
        toast.success("Updated successfully", {
          position: "top-right",
        });
        getStaffData(res.staff_id_pk);
        setProofSubmit(false);
      })
      .catch((err) => {
        if (err.data) {
          toast.error(err.data.errors[0].msg, {
            position: "bottom-right",
          });
        } else {
          toast.error(err.message, {
            position: "bottom-right",
          });
        }
      });
  };
  const today = new Date();
  const maxDateAllowed = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  const minDateAllowed = today;

  const removeImage = () => {
    mappedStaff.profile_image = "";
    SetEditStaffForm(mappedStaff);
  };
  //Remove img
  const handleImgRemove = (image, i) => {
    let newImg =
      addonImages &&
      addonImages.filter((e, newIndex) => {
        if (newIndex === i) {
          return e !== image;
        }
      });
    setAddonImages(newImg);
    setEditImageSubmit(false);
  };

  //check id extension
  function getExtension(filename) {
    return filename && filename.split(".").pop();
  }

  console.log(IdOptions, "IdOptions", "editStaffform", editStaffform);

  return (
    <div className="">
      <form className="" onSubmit={StaffEditSubmitHandler}>
        <Fragment>
          <div className="input-group input-group-custom">
            <div className="input-group-prepend d-flex align-items-center">
              <span className="input-group-text">
                <i class="fal fa-images"></i>Photo
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <div className="small-block">
              <div className="row-img row">
                {(mappedStaff.profile_image &&
                  editImageSubmit &&
                  addonImages) ||
                (!mappedStaff.profile_image &&
                  editImageSubmit &&
                  addonImages) ? (
                  addonImages.map((photo, index) => {
                    return (
                      <div className="col-img-list">
                        <div className="img-list" key={index}>
                          <button
                            className="btn"
                            onClick={(e, index) =>
                              handleImgRemove(photo, index)
                            }
                          >
                            <i class="fal fa-times"></i>
                          </button>{" "}
                          <img
                            src={photo.base64}
                            className="img-fluid"
                            alt=""
                            height="100"
                            width="100"
                          />{" "}
                        </div>
                      </div>
                    );
                  })
                ) : mappedStaff.profile_image ? (
                  <div className="col-img-list">
                    <div className="img-list">
                      <button
                        type="button"
                        className="btn"
                        onClick={(e) => removeImage(e)}
                      >
                        <i class="fal fa-times"></i>
                      </button>
                      <img
                        src={`${configData.SERVER_URL}${mappedStaff.profile_image}`}
                        className="img-fluid"
                        alt=""
                        height="100"
                        width="100"
                      />
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {!addonImages.length &&
              !mappedStaff.profile_image &&
              !editImageSubmit ? (
                <button
                  type="button"
                  className="btn-image"
                  onClick={props.handleShowEditFile}
                >
                  <img src={uploadImageFile} alt="loading..." />
                  <span>Upload</span>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-user"></i>Name
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <input
                type="hidden"
                className="form-control"
                onChange={(e) => {
                  SetEditStaffForm({
                    ...editStaffform,
                    name: e.target.value,
                  });
                  onChangeValidate("name", e.target.value);
                }}
                onBlur={(e) => onChangeValidate("name", e.target.value)}
                minLength={3}
                maxLength={100}
                value={editStaffform.staff_id_pk}
              />
              <input
                type="text"
                className="form-control"
                onChange={(e) => {
                  const newName = e.target.value;
                  SetEditStaffForm({
                    ...editStaffform,
                    name: newName,
                  });
                  onChangeValidate("name", newName);

                  // Update error state based on conditions
                  if (newName.length > 0) {
                    SetFormChange(true);
                  } else {
                    SetFormChange(false);
                  }
                }}
                onBlur={(e) => {
                  onChangeValidate("name", e.target.value);
                }}
                minLength={3}
                maxLength={22}
                value={editStaffform.name}
              />
              {error && formErrors.name && !formChange ? (
                <span className="form-error">{formErrors.name}</span>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i class="fal fa-envelope"></i>E-mail
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <div className="input-block">
              <input
                onChange={(e) =>
                  SetEditStaffForm({
                    ...editStaffform,
                    email: e.target.value,
                  })
                }
                // value={mappedStaff.name ? mappedStaff.name : editStaffform.name ? editStaffform.name : e.name}

                value={editStaffform.email}
                type="text"
                className="form-control"
              />
              {/* <span className="form-error">E-mail is required!</span> */}
            </div>
          </div>
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-phone"></i>Phone Number
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <div className="row m-0">
                <PhoneWrapper className="phone-wrapper">
                  <Select
                    className="phone-extension"
                    options={phoneExtension}
                    value={
                      editStaffform.phone_extension
                        ? {
                            label:
                              phoneExtension.find(
                                (option) =>
                                  option.value === editStaffform.phone_extension
                              )?.value ?? "",
                            value:
                              phoneExtension.find(
                                (option) =>
                                  option.value === editStaffform.phone_extension
                              )?.value ?? "",
                          }
                        : phoneExtension.find(
                            (option) =>
                              option.value === editStaffform.phone_extension
                          )
                    }
                    onChange={(selectedOption) => {
                      SetEditStaffForm({
                        ...editStaffform,
                        phone_extension: selectedOption.value,
                      });
                      onChangeValidate("PhoneExtension", selectedOption.value);
                    }}
                    onBlur={() =>
                      onChangeValidate(
                        "PhoneExtension",
                        editStaffform.phone_extension
                      )
                    }
                  />
                  <input
                    onChange={(e) => {
                      const cleanedPhoneNumber = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      SetEditStaffForm({
                        ...editStaffform,
                        emergency_details_phone: cleanedPhoneNumber,
                      });
                      if (cleanedPhoneNumber.length <= 10) {
                        SetFormChange(true);
                      } else {
                        SetFormChange(false);
                      }
                      onChangeValidate(
                        "emergency_details_phone",
                        cleanedPhoneNumber
                      );
                    }}
                    defaultValue={
                      editStaffform.staffEmergencyDetail &&
                      editStaffform.staffEmergencyDetail.phone
                        ? editStaffform.staffEmergencyDetail.phone
                        : ""
                    }
                    type="text"
                    className="form-control"
                    minLength={3}
                    maxLength={10}
                  />
                </PhoneWrapper>
                {error &&
                formErrors.emergency_details_phone &&
                editStaffform.emergency_details_phone.length < 10 ? (
                  <span className="form-error">
                    {formErrors.emergency_details_phone}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div class="input-group input-group-custom">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-map-marker-alt"></i>Address
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <textarea
                onChange={(e) => {
                  SetEditStaffForm({
                    ...editStaffform,
                    address: e.target.value,
                  });
                  if (e.target.value && e.target.value.length > 0) {
                    SetFormChange(true);
                  } else {
                    SetFormChange(false);
                  }
                  onChangeValidate("address", e.target.value);
                }}
                onBlur={(e) => onChangeValidate("address", e.target.value)}
                value={editStaffform.address}
                class="form-control"
              ></textarea>
              {error && formErrors.address ? (
                <span className="form-error">{formErrors.address}</span>
              ) : (
                ""
              )}
              {/* <span className="form-error">Address is required!</span> */}
            </div>
          </div>
          <div class="input-group input-group-custom">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-tint"></i>Gender
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <DropdownWrapper className="input-block">
              <Select
                // defaultValue={e.gender}
                defaultValue={GenderOption.find(
                  (x) => x.value === editStaffform.gender
                )}
                options={GenderOption}
                onChange={(e) =>
                  SetEditStaffForm(
                    { ...editStaffform, gender: e.value },
                    SetFormChange(true)
                  )
                }
              />
            </DropdownWrapper>
          </div>
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-calendar"></i>Date of Birth
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <DatePicker
                maxDate={maxDateAllowed}
                format="dd/MM/y"
                type="date"
                calendarIcon={null}
                clearIcon={null}
                className="form-control"
                onChange={(e) => {
                  SetEditStaffForm({
                    ...editStaffform,
                    date_of_birth: e,
                  });
                  if (e && e !== editStaffform.date_of_birth) {
                    SetFormChange(true);
                  } else {
                    SetFormChange(false);
                  }
                  onChangeValidate("date_of_birth", e);
                }}
                value={new Date(editStaffform.date_of_birth)}
                onBlur={(e) => onChangeValidate("date_of_birth", e)}
              />
              {error && formErrors.date_of_birth ? (
                <span className="form-error">{formErrors.date_of_birth}</span>
              ) : (
                ""
              )}
              {/* <span className="form-error">Date of Joining is required!</span> */}
            </div>
          </div>

          <div class="input-group input-group-custom">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-tint"></i>Blood Group
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <DropdownWrapper className="input-block">
              <Select
                defaultValue={BloodOptions.find(
                  (x) => x.value === editStaffform.blood_group_id
                )}
                options={BloodOptions}
                onChange={(e) => {
                  SetEditStaffForm(
                    { ...editStaffform, blood_group_id: e.value },
                    SetFormChange(true)
                  );
                  onChangeValidate("blood_group_id", e.value);
                }}
                onBlur={(e) =>
                  onChangeValidate(
                    "blood_group_id",
                    editStaffform.blood_group_id
                  )
                }
              />
              {error && formErrors.blood_group_id ? (
                <span className="form-error">{formErrors.blood_group_id}</span>
              ) : (
                ""
              )}
              {/* <span className="form-error">Blood Group is required!</span> */}
            </DropdownWrapper>
          </div>
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i class="fal fa-user-tag"></i>Designation
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <div className="input-block">
              <input
                onChange={(e) =>
                  SetEditStaffForm({
                    ...editStaffform,
                    designation: e.target.value,
                  })
                }
                value={editStaffform.designation}
                type="text"
                className="form-control"
                maxLength={30}
              />
              {/* <span className="form-error">Designation is required!</span> */}
            </div>
          </div>

          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i class="fal fa-money-bill-alt"></i>Salary
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <div className="input-block">
              <input
                onChange={(e) => {
                  let newSalary = e.target.value.trim();
                  newSalary = newSalary.replace(/[^0-9]/g, "");
                  let [integerPart, decimalPart] = newSalary.split(".");
                  integerPart = integerPart.slice(0, 8);
                  if (decimalPart !== undefined) {
                    decimalPart = decimalPart.slice(0, 3);
                  }
                  newSalary =
                    decimalPart !== undefined
                      ? `${integerPart}.${decimalPart}`
                      : integerPart;
                  SetEditStaffForm({
                    ...editStaffform,
                    salary: newSalary,
                  });
                }}
                value={editStaffform.salary}
                type="text"
                className="form-control"
              />
              {/* <span className="form-error">Salary is required!</span> */}
            </div>
          </div>

          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-calendar"></i>Date of Joining
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <DatePicker
                maxDate={new Date()}
                format="dd/MM/y"
                type="date"
                calendarIcon={null}
                clearIcon={null}
                className="form-control"
                onChange={(e) => {
                  SetEditStaffForm({
                    ...editStaffform,
                    date_of_joining: e,
                  });
                  if (e && e !== editStaffform.date_of_joining) {
                    SetFormChange(true);
                  } else {
                    SetFormChange(false);
                  }
                }}
                value={new Date(editStaffform.date_of_joining)}
              />
              {/* <span className="form-error">Date of Joining is required!</span> */}
            </div>
          </div>
          <div class="col-12 block-header main-title">
            <h6>
              <span class="header-title">ID Proof</span>
            </h6>
          </div>
          <div class="input-group input-group-custom">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-paste"></i>Type
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="upload-group">
              <div className="row m-0">
                <div className="col-md-8 upload-group-col">
                  <DropdownWrapper className="input-block">
                    <Select
                      className="form-field-dropdown"
                      // menuIsOpen
                      defaultValue={IdOptions.find(
                        (x) =>
                          x.value ==
                          (editStaffform?.staffIdProof?.id_proof_type ?? "")
                      )}
                      options={IdOptions}
                      onChange={(e) => {
                        SetEditStaffForm(
                          {
                            ...editStaffform,
                            staff_id_proof_type: e.value,
                          },
                          SetFormChange(true)
                        );
                        setSelectedIdProofValue(
                          IdOptions?.filter((item) => item?.value === e.value)
                        );
                      }}
                    />
                  </DropdownWrapper>
                </div>
                <div className="col-md-4 ">
                  <button
                    type="button"
                    className="btn-image"
                    onClick={props.handleShowEditId}
                  >
                    <img src={uploadFileId} className="pdf-file" alt="" />
                    Upload{" "}
                    {editStaffform.prooftype !== "Choose..." &&
                    selectedIdProofValue &&
                    selectedIdProofValue.length !== 0
                      ? ` ${selectedIdProofValue[0]?.label}`
                      : ""}
                    {editStaffform.prooftype !== "Choose..." &&
                    selectedIdProofValue.length === 0
                      ? IdOptions.find(
                          (x) =>
                            x.value ==
                            (editStaffform?.staffIdProof?.idProof
                              ?.id_proof_type_id_pk ?? "")
                        )?.label
                      : ""}
                  </button>
                </div>
              </div>
            </div>
            <div className="file-upload-view">
              <div className="small-block">
                <div class="row-img row">
                  {addProofSubmit && addonId ? (
                    addonId.map((proof, index) => {
                      return (
                        <Fragment>
                          <div class="img-list-col">
                            <div className="img-list" key={proof}>
                              <button
                                type="button"
                                className="btn"
                                onClick={() =>
                                  setAddonId(addonId.filter((e) => e !== proof))
                                }
                              >
                                <i class="fal fa-times"></i>
                              </button>
                              <div>
                                {getExtension(proof["name"]).toLowerCase() ===
                                "pdf" ? (
                                  <div className="pdf-block ">
                                    <span className="pdf-icon">
                                      <i class="fal fa-file-pdf"></i>
                                    </span>
                                    <h5>{proof.name}</h5>
                                  </div>
                                ) : getExtension(
                                    proof["name"]
                                  ).toLowerCase() === "docx" ? (
                                  <div className="pdf-block ">
                                    <span className="pdf-icon">
                                      <i class="fal fa-file-word word"></i>
                                    </span>
                                    <h5>{proof.name}</h5>
                                  </div>
                                ) : getExtension(
                                    proof["name"]
                                  ).toLowerCase() === "doc" ? (
                                  <div className="pdf-block ">
                                    <span className="pdf-icon">
                                      <i class="fal fa-word word"></i>
                                    </span>
                                    <h5>{proof.name}</h5>
                                  </div>
                                ) : (
                                  <img
                                    src={proof.base64}
                                    className="img-fluid"
                                    alt=""
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })
                  ) : mappedStaff.staffIdProof &&
                    mappedStaff.staffIdProof.id_proof &&
                    !removeMappedIdProof ? (
                    <Fragment className="mr-2 pl-2 pr-2">
                      <div class="img-list-col">
                        <div className="img-list">
                          <button
                            type="button"
                            className="btn"
                            onClick={() => setremoveMappedIdProof(true)}
                          >
                            <i class="fal fa-times"></i>
                          </button>
                          <div>
                            {getExtension(
                              mappedStaff.staffIdProof.id_proof
                            ).toLowerCase() === "pdf" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-file-pdf"></i>
                                </span>
                                <h5>{staffConfigData.title}</h5>
                              </div>
                            ) : getExtension(
                                mappedStaff.staffIdProof.id_proof
                              ).toLowerCase() === "docx" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-file-word word"></i>
                                </span>
                                <h5>{staffConfigData.title}</h5>
                              </div>
                            ) : getExtension(
                                mappedStaff.staffIdProof.id_proof
                              ).toLowerCase() === "doc" ? (
                              <div className="pdf-block ">
                                <span className="pdf-icon">
                                  <i class="fal fa-word word"></i>
                                </span>
                                <h5>{staffConfigData.title}</h5>
                              </div>
                            ) : (
                              <div className="small-img" key="">
                                <img
                                  src={`${configData.SERVER_URL}${mappedStaff.staffIdProof.id_proof}`}
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-calendar"></i>ID proof Expiry
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <DatePicker
                // maxDate={new Date()}
                minDate={minDateAllowed}
                format="dd/MM/y"
                // type="date"
                calendarIcon={null}
                clearIcon={null}
                className="form-control"
                onChange={(e) => {
                  const year = e.getFullYear();
                  const month = String(e.getMonth() + 1).padStart(2, "0");
                  const day = String(e.getDate()).padStart(2, "0");
                  const selectedDate = `${year}-${month}-${day}`;
                  SetEditStaffForm({
                    ...editStaffform,
                    expiry_date: selectedDate,
                  });
                  if (e && e !== editStaffform.expiry_date) {
                    SetFormChange(true);
                  } else {
                    SetFormChange(false);
                  }
                }}
                value={
                  editStaffform.expiry_date
                    ? editStaffform.expiry_date
                    : editStaffform.staffIdProof.expiry_date
                }
              />
              {/* <span className="form-error">Date of Joining is required!</span> */}
            </div>
          </div>
          <div class="col-12 block-header main-title">
            <h6>
              <span class="header-title">Emergency Contact Details</span>
            </h6>
          </div>

          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-user"></i>Name
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <input
                onChange={(e) => {
                  SetEditStaffForm({
                    ...editStaffform,
                    emergency_details_name: e.target.value,
                  });
                  if (e.target.value && e.target.value.length > 0) {
                    SetFormChange(true);
                  } else {
                    SetFormChange(false);
                  }
                  onChangeValidate("emergency_details_name", e.target.value);
                }}
                onBlur={(e) =>
                  onChangeValidate("emergency_details_name", e.target.value)
                }
                defaultValue={
                  editStaffform.staffEmergencyDetail &&
                  editStaffform.staffEmergencyDetail.name
                    ? editStaffform.staffEmergencyDetail.name
                    : ""
                }
                type="text"
                className="form-control"
                minLength={3}
                maxLength={100}
              />
              {error && !editStaffform.emergency_details_name ? (
                <span className="form-error">
                  {formErrors.emergency_details_name}
                </span>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-phone"></i>Phone Number
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <div className="row m-0">
                <PhoneWrapper className="phone-wrapper">
                  <Select
                    className="phone-extension"
                    options={phoneExtension}
                    value={
                      editStaffform.emergency_details_phone_extension
                        ? {
                            label:
                              editStaffform.emergency_details_phone_extension,
                            value:
                              editStaffform.emergency_details_phone_extension,
                          }
                        : {
                            label: phoneExtension.find(
                              (option) =>
                                option.value ==
                                editStaffform.staffEmergencyDetail
                                  .phone_extension
                            )?.phone_extension,
                            value: phoneExtension.find(
                              (option) =>
                                option.value ==
                                editStaffform.staffEmergencyDetail
                                  .phone_extension
                            )?.phone_extension,
                          }
                    }
                    onChange={(selectedOption) => {
                      SetEditStaffForm(
                        {
                          ...editStaffform,
                          emergency_details_phone_extension:
                            selectedOption.value,
                        },
                        SetFormChange(true)
                      );
                      onChangeValidate("PhoneExtension", selectedOption.value);
                    }}
                    onBlur={() =>
                      onChangeValidate(
                        "PhoneExtension",
                        editStaffform.staffEmergencyDetail.phone_extension
                      )
                    }
                  />
                  <input
                    onChange={(e) => {
                      const cleanedPhoneNumber = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      SetEditStaffForm({
                        ...editStaffform,
                        emergency_details_phone: cleanedPhoneNumber,
                      });
                      if (cleanedPhoneNumber.length === 10) {
                        SetFormChange(true);
                      } else {
                        SetFormChange(false);
                      }
                      onChangeValidate(
                        "emergency_details_phone",
                        cleanedPhoneNumber
                      );
                    }}
                    defaultValue={
                      editStaffform.staffEmergencyDetail &&
                      editStaffform.staffEmergencyDetail.phone
                        ? editStaffform.staffEmergencyDetail.phone
                        : ""
                    }
                    type="text"
                    className="form-control"
                    minLength={3}
                    maxLength={10}
                  />
                </PhoneWrapper>
                {error &&
                formErrors.emergency_details_phone &&
                editStaffform.emergency_details_phone.length < 10 ? (
                  <span className="form-error">
                    {formErrors.emergency_details_phone}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>

          <div class="input-group input-group-custom">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-users"></i>Relation
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <DropdownWrapper className="input-block">
              <Select
                disabled
                // defaultValue={e.gender}
                defaultValue={RelationOptions.find((x) =>
                  x.value ===
                  (editStaffform.staffEmergencyDetail &&
                    editStaffform.staffEmergencyDetail.relation_type)
                    ? editStaffform.staffEmergencyDetail.relation_type
                    : 0
                )}
                options={RelationOptions}
                onChange={(e) =>
                  SetEditStaffForm(
                    {
                      ...editStaffform,
                      emergency_details_relation_type: e.value,
                    },
                    SetFormChange(true)
                  )
                }
              />
            </DropdownWrapper>
          </div>
          <div class="input-group input-group-custom">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fal fa-map-marker-alt"></i>Emergency Address
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <div className="input-block">
              <textarea
                class="form-control"
                defaultValue={
                  editStaffform.staffEmergencyDetail &&
                  editStaffform.staffEmergencyDetail.address
                    ? editStaffform.staffEmergencyDetail.address
                    : ""
                }
                onChange={(e) =>
                  SetEditStaffForm(
                    {
                      ...editStaffform,
                      emergency_details_address: e.target.value,
                    },
                    SetFormChange(true)
                  )
                }
              ></textarea>
              {/* <span className="form-error">Address is required!</span> */}
            </div>
          </div>
          <div class="col-12 text-end p-0 mt-3">
            <button class="btn btn-update" type="submit">
              <i class="fal fa-save mr-2"></i>Update
            </button>
          </div>
        </Fragment>
      </form>
    </div>
  );
};

export default EditStaffform;

const NameInputWrapper = styled.div`
  &.input-group,
  &.form-control {
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
`;
const PhoneWrapper = styled.div`
  display: flex;
  padding: 0 0 0 10px;
  .phone-extension {
    min-width: 100px;
    margin-left: -10px;
  }
  .phone-extension {
    > div:nth-of-type(2) {
      min-width: calc(100% + 100px);
      max-width: calc(100% + 100px);
    }
  }
  .phone-extension > div {
    background: #e8e8e8;
    min-height: 38px;
  }

  .form-control {
    margin-left: 5px;
  }

  .phone-extension > div {
    background: #e8e8e8;
    min-height: 38px;
  }

  .form-control {
    margin-left: 5px;
  }
`;
const DropdownWrapper = styled.div`
  .gender > div {
    background: #e8e8e8;
  }
  .blood-group > div {
    background: #e8e8e8;
  }
  .type > div {
    background: #e8e8e8;
  }
  .relation > div {
    background: #e8e8e8;
  }

  .css-b62m3t-container > div {
    border: none !important;
    background: #e8e8e8;
  }
  .css-b62m3t-container > div {
    background: #e8e8e8 !important;
  }
`;
