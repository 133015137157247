

export const BankValidation = (
  values,
  field_value,
  isOnChange,
  field,
  profileConfigData
) => {
  
  const errors = {};
  const isBankNumberTypeIBAN =
  profileConfigData?.bank_type?.bank_number_type_id_pk === 2;

  //Validation For OnBlur
  if (isOnChange) {
    //Service CenterName name
    if (field === "account_holder_name" && isOnChange) {
      if (!field_value) {
        errors.account_holder_name = "Account Holder Name is required.";
      }
      if (field_value.length >= 1) {
        errors.account_holder_name = null;
      }
    }
    //Bank name
    if (field === "bank_name" && isOnChange) {
      if (!field_value) {
        errors.bank_name = "Bank Name is required.";
      }
      if (field_value.length >= 1) {
        errors.bank_name = null;
      }
     
    }
    //Branch name
    if (field === "branch_name" && isOnChange) {
      if (!field_value) {
        errors.branch_name = "Branch Name is required.";
      }
      if (field_value.length >= 1) {
        errors.branch_name = null;
      }
      
    }
    //Account Number
  if (field === "account_number" && isOnChange) {
    if (!field_value) {
      errors.account_number = isBankNumberTypeIBAN
      ? "IBAN number is required"
      : "account number is required";
    }
    if (field_value.length <= 11) {
      errors.account_number = isBankNumberTypeIBAN
      ? "IBAN number is required"
      : "account number is required";
     
    }
    if (field_value.length >= 11) {
      errors.account_number = isBankNumberTypeIBAN
      ? ""
      : "";
    }
    if (field_value.length > 18) {
      errors.account_number = isBankNumberTypeIBAN
      ? "IBAN number is invalid"
      : "account number is invalid";
    }
  } else {
    // Show a generic message for fields other than account_number
    if (!field_value) {
      errors[field] = `${field} is required.`;
    }
  }

    //IFSC code
    if (field === "ifsc_code" && isOnChange && !isBankNumberTypeIBAN) {
      if (
        !field_value &&
        profileConfigData?.bank_type?.bank_number_type_id_pk === 1
      ) {
        errors.ifsc_code = "IFSC code is required.";
      }
      if (field_value.length <= 11) {
        errors.ifsc_code = "IFSC code is required.";
      }
      if (field_value.length === 11) {
        errors.ifsc_code = "";
      }
      if (field_value.length > 11) {
        errors.ifsc_code = "IFSC code is invalid";
      }

    }
  }

  //Validation for OnSubmit
  else {
    //Account holder name
    if (!values.account_holder_name)
      errors.account_holder_name = "Account holder name is required.";

    //Bank name
    if (!values.bank_name) {
      errors.bank_name = "Bank name is required";
    }
    //Branch name
    if (!values.branch_name) {
      errors.branch_name = "Branch name is required";
    }
    //Account number
    if (!values.account_number) {
      errors.account_number = isBankNumberTypeIBAN
        ? "IBAN number is required"
        : "Account number is required";
    }
    //IFSC code
    if (isBankNumberTypeIBAN) {
      // Skip IFSC code validation
      delete errors.ifsc_code;
    } else {
      // IFSC code validation
      if (!values.ifsc_code) {
        errors.ifsc_code = "IFSC code is required.";
      }
    }
  }
  return errors;
};

export default BankValidation;
