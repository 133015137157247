import React, { useContext } from "react";
import { CustomerContext } from "../CustomerContext";
import DataTable from "react-data-table-component";
import styled from "styled-components";

const AdminRating = () => {
  const { innertab, feedbackInnerTab, setFeedbackInnerTab, filterValue,customerOrdersListData,customerRating } =
    useContext(CustomerContext);
  const columns = [
    {
      name: "Order ID",
      selector: (row) => row.order_unique_id,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.service_date,
      sortable: true,
      // cell: (row) => (
      //   <div>
      //     {row.customer_name}
      //     {row.booking_from_app === 1 && (
      //       <span class="ml-1 badge rounded-pill bg-warning text-dark">
      //         APP
      //       </span>
      //     )}
      //   </div>
      // ),
      allowOverflow: false,
    },
    {
      name: "Services",
      selector: (row) => {
        let req =[];
        row.orderServices.forEach((element)=>{
           req.push(element.service_name)
         })

         return req.join(', ')
      },
      // cell: (row) => <div data-tag="allowRowEvents">row.orderService && {row.orderService.length}</div>, // need salman's help
      sortable: true,
    },
    {
      name: "Rating",
      selector: (row) => row.rating,
      sortable: true,
    },
    // {
    //   name: "No of Orders",
    //   selector: (row) => row.no_of_orders,
    //   sortable: true,
    // },
  ];
  //PAGINATION OPTIONS
  const paginationComponentOptions = {
    rowsPerPageText: "rows",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
    noRowsPerPage: 0,
  };
  //Datatable sort icon
  const sortIcon = <i class="fal fa-sort"></i>;
  //custom datatable styles
  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        borderColor: "red",
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };
  return (
    <div
      className={feedbackInnerTab === 2 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <div className="inner-block">
        <div className="mt-3 mb-3">
        {!customerRating ? (
            "There are no records to display"
          ) : (
          <DataTable
            columns={columns}
            data={customerOrdersListData}
            highlightOnHover={true}
            pointerOnHover={true}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            sortIcon={sortIcon}
            customStyles={customStyles}
          />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminRating;
