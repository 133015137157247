import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import TickIcon from "./assets/img/tick.svg";
import { Props } from "react-select";
// import { ReactComponent as Added } from './assets/img/icon/added.svg';

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; // to handle onClick functions
  children?: React.ReactNode; // make the component able to receive children elements
  buttonType?: "primary" | "secondary" | "icon"; // two styling options (you can create as many as you want)
  disabled?: boolean; // make the button disabled or not
  rounded?: boolean; // make the button disabled or not
  type?: "submit" | "button";
  variant?: "contained" | "outlined" | "text";
  color?: "success" | "warning";
};

const SupApproveButton = ({
  onClick,
  children,
  variant,
  color,
  type,
  rounded,
  disabled,
}: ButtonProps) => {
  return (
    <Wrapper className={`button-wrapper ${rounded ? "rounded" : ""}`}>
      <Button
        onClick={onClick}
        disabled={disabled}
        variant={variant}
        color={color}
        type={type}
      >
        {/* <img src={TickIcon} width={20} /> */}
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.334 0C17.723 0 20 2.378 20 5.916V14.084C20 17.622 17.723 20 14.333 20H5.665C2.276 20 0 17.622 0 14.084V5.916C0 2.378 2.276 0 5.665 0H14.334ZM14.334 1.5H5.665C3.135 1.5 1.5 3.233 1.5 5.916V14.084C1.5 16.767 3.135 18.5 5.665 18.5H14.333C16.864 18.5 18.5 16.767 18.5 14.084V5.916C18.5 3.233 16.864 1.5 14.334 1.5ZM14.0895 7.097C14.3825 7.39 14.3825 7.864 14.0895 8.157L9.3435 12.903C9.1975 13.05 9.0055 13.123 8.8135 13.123C8.6225 13.123 8.4295 13.05 8.2835 12.903L5.9095 10.53C5.6165 10.237 5.6165 9.763 5.9095 9.47C6.2025 9.177 6.6765 9.177 6.9695 9.47L8.8135 11.312L13.0295 7.097C13.3225 6.804 13.7965 6.804 14.0895 7.097Z"
            fill="white"
          />
        </svg>
        {rounded ? "Approved" : "Approve"}
      </Button>
    </Wrapper>
  );
};

export default SupApproveButton;
const Wrapper = styled.div`
  padding: 2px 2px;
  width: fit-content;
  max-width: 100%;
  &.button-wrapper {
    .MuiButtonBase-root {
      font-size: 12px;
      font-weight: 400;
      color: rgb(255 255 255);
      max-height: 36px;
      border: 1px solid #009970;
      text-transform: uppercase !important;
      letter-spacing: 1px !important;
      background: #009970 !important;
      min-width: 156px !important;
      max-width: 170px !important;
      border-radius: 5px !important;
      box-shadow: none !important;
      padding: 7px 10px !important;
      line-height: 1;
      align-items: center;
      -webkit-transition: border-radius 0.25s;
      transition: border-radius 0.25s;
      justify-content: flex-start;
      padding-left: 0px;
      margin: 0 0px 0 0;
      svg {
        margin: 0 10px 0 0;
        width: 22px;
        path {
          /* fill: #009970; */
        }
      }

      &:hover {
        background: #0ba77d !important;
      }
    }
    &.rounded {
      .MuiButtonBase-root {
        background: transparent !important;
        color: #009970;
        border-radius: 20px !important;
        margin: 0 0px 0 0;
        svg {
          /* margin: 0 0 10px 0; */
          path {
            fill: #009970;
          }
        }
      }
    }
  }
  //Media Query ---->
  @media (min-width: 320px) and (max-width: 575px) {
    font-size: 11px;
    a {
    }
  }

  @media (min-width: 576px) and (max-width: 767px) {
    font-size: 12px;
    a {
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 12px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    font-size: 13px;
    a {
    }
  }
  @media (max-width: 1365px) {
    &.button-wrapper {
      .MuiButtonBase-root {
        min-width: 112px !important;
        max-width: 112px !important;
        font-size: 11px !important;
        svg {
          width: 16px !important;
        }
      }
    }
  }

  @media (min-width: 1200px) and (max-width: 1365px) {
    font-size: 13px;
    a {
    }
  }
  @media (min-width: 1366px) and (max-width: 4000px) {
    font-size: 14px;
  }
`;
