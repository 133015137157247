import React, { useState } from "react";

type Props = {};

const ProductSortDropdownContent = (props: Props) => {

    

  return (
    <>
      <a className="dropdown-item" href="">
        Recent
      </a>
      <a className="dropdown-item" href="">
        Name
      </a>
      <a className="dropdown-item" href="">
        Price low
      </a>
      <a className="dropdown-item" href="">
        Price high
      </a>
      <a className="dropdown-item" href="">
        oldest
      </a>
      </>
  );
};

export default  ProductSortDropdownContent;