import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import InputLayout from "../../../../SuperAdmin/Pages/components/Layouts/InputLayout";
import CustomFileUpload from "../../../../SuperAdmin/Pages/components/CustomFileUpload";
import CustomDropDown from "../../../../ServiceAdmin/components/CustomDropDown";
import { FeatuerdRequestModel, LookUpType } from "../model/featuredModels";
import { getLookupDataAPI } from "../../../../../api/services/common/commonAPIs";
import SimpleReactValidator from "simple-react-validator";

type SupFeatureFormProps = {
  formData: FeatuerdRequestModel;
  setFormData: React.Dispatch<React.SetStateAction<FeatuerdRequestModel>>;
  isEdit?: boolean;
  isAddNew?: boolean;
  handleCancelClick: () => void;
  setIsRemovedViewImage?: React.Dispatch<React.SetStateAction<boolean>>;
  isRemovedViewImage?: boolean;
  onSave: React.MouseEventHandler<HTMLButtonElement>;
  setImage: React.Dispatch<
    React.SetStateAction<
      (
        | {
            base64: string;
            type: "image";
          }
        | { videoSrc: string; type: "video" }
      )[]
    >
  >;
  image?: (
    | {
        base64: string;
        type: "image";
      }
    | { videoSrc: string; type: "video" }
  )[];
  validator: React.MutableRefObject<SimpleReactValidator>;
};

const SupFeatureForm = ({
  formData,
  setFormData,
  isEdit,
  isAddNew,
  handleCancelClick,
  setIsRemovedViewImage,
  isRemovedViewImage,
  onSave,
  setImage,
  image,
  validator,
}: SupFeatureFormProps) => {
  const [textPositions, setTextPositions] = useState<any>();

  //Lookup Api
  const handleGetLookup = () => {
    getLookupDataAPI({ type: "featured_text_position" })
      .then((res) => {
        if (res) {
          setTextPositions(res.data);
        }
      })
      .catch((err) => {
        console.log(err, "response Featured list");
      });
  };

  const handleRemoveDefaultImage = () => {
    setIsRemovedViewImage?.(true);
    setFormData({
      ...formData,
      image: null,
    });
  };

  const handleImageUpload = (e: any, blob: Blob) => {
    setFormData({
      ...formData,
      image: blob,
    });
  };

  const transformData = (
    data: LookUpType[]
  ): { label: string; value: number }[] =>
    data?.map((item) => ({
      label: item.title,
      value: item.value,
    }));

  const transformedData = transformData(textPositions);

  useEffect(() => {
    handleGetLookup();
  }, []);

  const handleChange = (e: any) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSave = (e: any) => {
    if (validator.current.allValid()) {
      onSave(e);
    } else {
      validator.current.showMessages();
      // Force update to show messages
      setFormData({ ...formData });
    }
  };

  return (
    <>
      <Wrapper className={`col-12 ${!isEdit ? "view-mode" : ""}`}>
        <form className="w-100" id="add-offer">
          <InputLayout
            isImportant
            title="Media Upload"
            icon={<i className="fal fa-images"></i>}
            className="media-upload"
          >
            <CustomFileUpload
              setImage={setImage}
              image={image}
              viewonly={!isEdit}
              showImage={
                isRemovedViewImage
                  ? undefined
                  : formData?.image && "url" in formData.image
                  ? formData.image.url
                  : undefined
              }
              handleRemoveFile={handleRemoveDefaultImage}
              handleRemoveDefaultImage={handleRemoveDefaultImage}
              onImageConfirmClick={handleImageUpload}
            />
            <ValidationWrapper>
              {validator.current.message(
                "media upload",
                formData.image,
                "required"
              )}
            </ValidationWrapper>
          </InputLayout>

          <InputLayout
            isImportant
            title="Title"
            icon={<i className="fal fa-keyboard"></i>}
          >
            <input
              type="text"
              name="name"
              className="form-control"
              value={formData.name}
              onChange={handleChange}
              disabled={!isEdit}
            />
            <ValidationWrapper>
              {validator.current.message("title", formData.name, "required")}
            </ValidationWrapper>
          </InputLayout>
          <InputLayout
            isImportant
            title="Text Position"
            icon={<i className="far fa-th"></i>}
          >
            <CustomDropDown
              data={transformedData}
              disabled={!isEdit}
              className={`${!isEdit ? "disable-select" : ""}`}
              value={
                formData.textPositionId
                  ? {
                      label:
                        textPositions?.find(
                          (option: any) =>
                            option.value === formData.textPositionId
                        )?.title ?? "",
                      value:
                        textPositions?.find(
                          (option: any) =>
                            option.value === formData.textPositionId
                        )?.value ?? "",
                    }
                  : {
                      label: "",
                      value: "",
                    }
              }
              getOptionLabel={(option) => option.label}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  textPositionId: e?.value as number,
                });
              }}
            />
            <ValidationWrapper>
              {validator.current.message(
                "text position",
                formData.textPositionId,
                "required"
              )}
            </ValidationWrapper>
          </InputLayout>
          {/* <InputLayout
            isImportant
            title="Featured Position"
            icon={
              <>
                <i className="far fa-th"></i>
              </>
            }
          >
            <CustomDropDown
              data={featurePositionOptions}
              disabled={!isEdit}
              
            />
          </InputLayout> */}
          <InputLayout
            isImportant
            title="Cross Cell Count"
            icon={<i className="far fa-th"></i>}
          >
            <input
              type="number"
              min={0}
              name="crossCellCount"
              className="form-control"
              value={ formData.crossCellCount}
              onChange={handleChange}
              disabled={!isEdit}
            />
            <ValidationWrapper>
              {validator.current.message(
                "cross cell count",
                formData.crossCellCount,
                "required|integer"
              )}
            </ValidationWrapper>
          </InputLayout>
          <InputLayout
            isImportant
            title="Main Cell Count"
            icon={<i className="far fa-th"></i>}
          >
            <input
              type="number"
              min={0}
              name="mainCellCount"
              className="form-control"
              value={formData.mainCellCount}
              onChange={handleChange}
              disabled={!isEdit}
            />
            <ValidationWrapper>
              {validator.current.message(
                "main cell count",
                formData.mainCellCount,
                "required|integer"
              )}
            </ValidationWrapper>
          </InputLayout>
        </form>
        {isEdit ? (
          <ButtonWrapper>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={handleCancelClick}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary ml-3"
              type="button"
              onClick={handleSave}
            >
              Save
            </button>
          </ButtonWrapper>
        ) : (
          ""
        )}
      </Wrapper>
    </>
  );
};

export default SupFeatureForm;

const Wrapper = styled.div`
  .disable-select {
    .dropdown__control {
      background-color: #e8e8e8;
    }
  }
  &.view-mode {
    input[type="text"] {
      color: #999999 !important;
    }
    input[type="number"] {
      color: #999999 !important;
    }
  }
  .row-img {
    margin-right: 0;
    margin-left: 0;
    padding: 0;
    .col-img-list {
      padding-left: 0 !important;
    }
  }
  .media-upload {
    .input-group-custom {
      margin: 0 0 0 0;
    }
    margin: 0 0 15px 0;
  }
    /* Hide spinners in Chrome, Safari, Edge, Opera */
    input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  /* Hide spinners in Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;
const ValidationWrapper = styled.div`
  color: #f44336;
  font-size: 12px;
  font-family: "Inter Regular";
  display: inline-block;
`;
