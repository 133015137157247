import { Button } from "react-bootstrap";
import React, { useState, useContext } from "react";
import ServiceFilterWrapper from "./ServiceFilterWrapper";
import { ServiceContext } from "../ServiceContext";
import ReactTooltip from "react-tooltip-rc";

const ServiceContentTitle = (props) => {
  const {
    mappedServiceData,
    SetMappedServiceData,
    viewReadOnly,
    SetViewReadOnly,
    setAddMode,
    editMode,
    SetEditMode,
    SetFormChange,
    SetRowActive,
  } = useContext(ServiceContext);

  const handleClick = (e) => {
    props.handleAddService(true);
    SetViewReadOnly(false);
    SetEditMode(false);
    setAddMode(true);
    SetMappedServiceData({});
    SetFormChange(false);
    SetRowActive(false);
    const allTableRows = document.querySelectorAll(".rdt_TableRow");
      allTableRows.forEach((rowElement) => {
        rowElement.classList.remove("current-selected-row");
      });
  };
  return (
    <div class="content-title-action">
      <div class="title-action">
        <div class="title-action-left">
          <h5 class="head">Service</h5>
          <div class="action-block">
            <button
              class="btn add-new"
              id="addBtn"
              // onClick={() => {props.handleAddService(true)}}
              onClick={handleClick}
            >
              <span>
                <i class="fal fa-plus"></i> Add New
              </span>
            </button>
          </div>
        </div>
        <div class="title-action-right d-flex">
          <ServiceFilterWrapper />

          {/* <div class="action-block">
            <button
              class="btn btn-card"
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title="view"
              view-click="service"
            >
              <span>
                <i class="fas fa-table"></i>
              </span>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ServiceContentTitle;
