import React, { useState, useContext, Fragment, useEffect } from "react";
import { OrdersContext } from "../OrdersContext";
import DataTable from "react-data-table-component";
import { getCustomerOrders } from "../../../../../api/services/sap/customer-management";

const PastOrdersTab = () => {
  const { innertab, mappedOrderData, pastOrders, setPastOrders } =
    useContext(OrdersContext);

  useEffect(() => {
    const data = getCustomerOrders(mappedOrderData.customer_id_fk);
    data.then((res) => {
      let customerOrdersList = res.data.map((item) => {
        return item;
      });
      setPastOrders(customerOrdersList);
    });
  }, []);

  const customerColumn = [    
    {
      name: "Order ID",
      selector: (row) => row.order_unique_id,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.service_date,
      sortable: true,
      allowOverflow: false,
    },
    {
      name: "Services",
      selector: (row) => {
        let req =[];
        row.orderServices.forEach((element)=>{
           req.push(element.service_name)
         })

         return req.join(', ')
      },
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Payment Status",
      selector: (row) => row.order_completed,
      cell: (row) => (
        <div className="text-left">
          {row.balance_amount == row.grand_total ? (
            <span>Not Paid</span>
          ) : row.balance_amount == 0 ? (
            <span>Payment Completed</span>
          ) : (
            <span>Partially Paid</span>
          )}
        </div>
      ),
      sortable: true,
    },
  ];
  const vehicleColumn = [
    {
      name: "Order ID",
      selector: (row) => row.order_unique_id,
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => row.service_date,
      sortable: true,
      allowOverflow: false,
    },
    {
      name: "Vehicle Number",
      selector: (row) => row.vehicle_registration_number,
      sortable: true,
    },
    {
      name: "Services Booked",
      selector: (row) => {
        let req =[];
        row.orderServices.forEach((element)=>{
           req.push(element.service_name)
         })

         return req.join(', ')
      },
      sortable: true,
    },
    {
      name: "Order Status",
      selector: (row) => row.status,
      sortable: true,
    },
  ];
  //PAGINATION OPTIONS
  const paginationComponentOptions = {
    rowsPerPageText: "rows",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
    noRowsPerPage: 0,
  };
  //Datatable sort icon
  const sortIcon = <i class="fal fa-sort"></i>;
  //custom datatable styles
  const customStyles = {
    rows: {
      style: {
        minHeight: "50px", // override the row height
      },
    },
    headCells: {
      style: {
        paddingLeft: "8px", // override the cell padding for head cells
        paddingRight: "8px",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
      },
    },
  };

  return (
    <div
      className={innertab === 6 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <div className="inner-block">
        <div className="past-order-block">
          <div className="col-12 mb-4">
            <div className="common-header">
              <h5>Past Orders of Customer</h5>
            </div>
            <Fragment>
              <DataTable
                columns={customerColumn}
                data={pastOrders}
                highlightOnHover={true}
                pointerOnHover={true}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                sortIcon={sortIcon}
                customStyles={customStyles}
              />
            </Fragment>
          </div>
          <div className="col-12 mb-4">
            <div className="common-header">
              <h5>Past Orders of Vehicle</h5>
            </div>
            <Fragment>
              <DataTable
                columns={vehicleColumn}
                data={pastOrders}
                highlightOnHover={true}
                pointerOnHover={true}
                pagination
                paginationComponentOptions={paginationComponentOptions}
                sortIcon={sortIcon}
                customStyles={customStyles}
              />
            </Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PastOrdersTab;
