import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { approveOrRejectVariant } from '../../../../../api/services/sup/variant-management';

const RejectVariantModal = (props) => {
  const [error, setError] = useState('');
  const [rejectedReason, setRejectedReason] = useState('');


    const rejectVariant = () => {
        const req = approveOrRejectVariant({
          type: 'reject',
          variant_id_fk: props.variant.variant_id_fk,
          rejected_reason: rejectedReason,
        });
        req.then((res) => {
         
          props.setRejectModal(false);
          props.reloadPageDetails();
          toast.success(res.message, {
            position: "bottom-right",
          });
        }).catch((e) => {
          setError(e.data ? e.data[0].rejected_reason : '');
          toast.error('Error', {
            position: "bottom-right",
          });
        });
      }


const onCancel = () => {
    setError('');
    setRejectedReason('');
    props.setRejectModal(false);
  }

  return (
<div className="action-modal reject-modal p-2">
      <div className="d-flex justify-content-end">
        <button className="btn close-btn" onClick={() => {props.setRejectModal(false)}}>
          <i className="fal fa-times"></i>
        </button>
      </div>
      <h4 className='m-1' >Are you sure to reject this variant?</h4>
      <textarea
        className='form-control'
        rows={5}
        placeholder='If yes, state the rejection reason.'
        onChange={(e) => {setRejectedReason(e.target.value)}}
      ></textarea>
      <div className="input-block">
      {error && (
        <span className="form-error">{error}</span>
      )}
      </div>
      
      <div className="d-flex justify-content-center pt-3">
        <button className="btn mr-3 btn-cancel " onClick={onCancel}>
          Cancel
        </button>
        <button class="btn btn-danger" onClick={rejectVariant}>
        Reject<i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
        </button>
      </div>
    </div>  )
}

export default RejectVariantModal;