import VariantRow from "./VariantRow";
import React, { useState, useEffect, useContext, Fragment } from "react";
import { VariantContext } from "./VariantContext";
// import { useEffect } from "react";
import { getVehicleTypes, getVehicleVariants } from "../../../../api/services/sup/variant-management";
import RejectVariantModal from "./Ui/RejectVariantModal";

const WindowLeft = (props) => {
  const { table, SetTable, mappedServiceData, mappedVariantData } = useContext(VariantContext);
  const [viewservice, SetViewService] = useState(false);
  const [filterTable, SetFilterTable] = useState([]);
  const [vehicleTypes, setVehicleTypes] = useState([
    {
      title: "",
      value: "",
    },
  ]);
  const [vehicleVariants, setVehicleVariants] = useState([]);
  let toggleClassCheck = viewservice ? " view" : "";
  //Handle Service Json
  const handleViewService = () => {
    SetViewService(!viewservice);
  };
//  const fetchVehicleTypes = () => {
//   const returnedPromise = getVehicleTypes();
//   returnedPromise.then((res) => {
//     setVehicleTypes(
//       res.map((item) => ({
//         title: item.title,
//         value: item.value,
//       }))
//     );
//   });
// };
 const fetchVehicleVariants = () => {
  
  const returnedPromise = getVehicleVariants();
  returnedPromise.then((res) => {
   if(res){
     let tableData = res.map((item) => ({
         id: item.variant_id_pk,
         variant_name: item.variant_name,
         vehicle_type_id_fk: item.vehicle_type_id_fk,
         status:item.status,
         reason:item.rejected_reason,
         vehicleType: item.lookupData.title
       }))
   
     console.log("dd",tableData);
     setVehicleVariants(tableData);
    }
  });
};


useEffect(() => {
//  fetchVehicleTypes();
  fetchVehicleVariants();
}, []);
  return (
    <Fragment>
      <div className="content-window-left">
        <div className="content-table-block">
          <div className="content-table table-responsive">
            <table className="table custom-table clickable-table">
              <thead>
                <tr >
                  <th scope="col">Variant Name</th>
                  <th scope="col">Vehicle Type </th>
                  <th scope="col">Service Center Name</th>
                  <th scope="col" className="text-center">Actions</th>
                  {/* <th scope="col" style={{textAlign:`center`}}>App Status</th> */}
                </tr>
              </thead>
              <tbody>
                {vehicleVariants && vehicleVariants.map((a, index) => ( 
                  <VariantRow
                    handleViewService={handleViewService}
                    fetchVehicleVariants={fetchVehicleVariants}
                    id={a.id}
                    key={a.id}
                    name={a.variant_name}
                    reason={a.reason}
                    // VariantFormClose={props.VariantFormClose}
                    status={a.status}
                    // serviceCenter={a.serviceCenter}
                    vehicleType={a.vehicleType}
                  />
               ))} 
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default WindowLeft;
