import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import giftImage from "./assets/img/gift-box.png";
import coupongiftImage from "./assets/img/coupon.png";
import coupongImage from "./assets/img/discount-coupon.png";
import popperImage from "./assets/img/confetti.png";

type Props = {
  handleShowClick?: (e: any) => void;
  handleViewClick?: (e: any) => void;
};

const CustomOfferCouponCard = ({ handleShowClick, handleViewClick }: Props) => {
  return (
    <ComponentWrapper onClick={handleViewClick}>
      <Card>
        {/* <CardMedia
          sx={{ height: 140 }}
          image="/static/images/cards/contemplative-reptile.jpg"
          title="green iguana"
        /> */}
        {/* <PopperImage src={popperImage} /> */}
        <CardContent>
          <CouponThumbImageWrapper>
            <CouponThumbImageWrap>
              <CouponThumbImage src={coupongImage} alt="coupon-thumb" />
            </CouponThumbImageWrap>
          </CouponThumbImageWrapper>
          <DiscountTitle>70 % OFF</DiscountTitle>
          <CouponDescription>
            Special discount for all the new customers
          </CouponDescription>
          <CouponValidityTitle>
            Valid till : <span>Aug 8 to Sept 8</span>
          </CouponValidityTitle>
          {/* <CouponValueTitle>MOT70NEWMEMBER</CouponValueTitle> */}
        </CardContent>
        <CardActions>
          <ViewCouponButtonWrapper className="showcode">
            <button
              className="show-code btn btn-sm btn-block"
              onClick={handleShowClick}
              tabIndex={0}
            >
              Show Coupon Code
            </button>
            <div className="coupon-hide">X410-17GT-OL57</div>
          </ViewCouponButtonWrapper>
          {/* <Button size="small">Share</Button>
          <Button size="small">Learn More</Button> */}
        </CardActions>
      </Card>
    </ComponentWrapper>
  );
};

export default CustomOfferCouponCard;
const ComponentWrapper = styled.div`
  > .MuiPaper-root {
    position: relative;
    border-radius: 15px;
    padding: 30px 18px 22px 18px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 0px 8px !important;
  }
`;
const CouponDescription = styled.p``;
const DiscountTitle = styled.h3`
  font-family: "Outfit SemiBold";
  font-size: 30px;
  letter-spacing: 0.9px;
  color: #69a4e8;
`;
const CouponValidityTitle = styled.h5`
  font-family: "Inter Medium";
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.9px;
  color: #8e8c90;
  padding: 8px 0;
  span {
    color: #4caf50;
  }
`;
const CouponThumbImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 22px 0;
`;
const CouponThumbImageWrap = styled.div`
  padding: 20px;
  background: #427df81a;
  border-radius: 50%;
`;
const PopperImage = styled.img`
  position: absolute;
  top: -38px;
  right: -19px;
  transform: rotate(220deg);
`;
const CouponThumbImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  object-position: center;
`;
const ViewCouponButtonWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  &:hover .show-code {
    width: 80%;
  }
  .show-code {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 400ms linear 0ms;
    background: rgb(66 148 255);
    color: #ffffff;
    height: 40px;
    padding: 5px 15px;
    font-size: 12px;
    line-height: 20px;
    border-radius: 3px;
  }
  .coupon-hide {
    display: block;
    width: 100%;
    height: 40px;
    line-height: 34px;
    padding: 0 15px;
    font-size: 15px;
    color: #f50;
    border-radius: 3px;
    text-align: right;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 10px,
        rgb(238, 238, 238) 10px,
        rgb(238, 238, 238) 20px
      ),
      linear-gradient(rgb(66 148 255 / 34%), rgb(66 148 255 / 26%));
    /* background: repeating-linear-gradient(
        45deg,
        transparent,
        transparent 10px,
        #eee 10px,
        #eee 20px
      ),
      linear-gradient(to bottom, #c2ead4, #c2ead4); */
  }
`;
