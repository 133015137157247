import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import isSAPAuthenticated from "../../../utils/isSAPAuthenticated";
import isSUPAuthenticated from "../../../utils/isSUPAuthenticated";

const RedirectIfAuthenticatedGuardSUP = () => {
    return (
        isSUPAuthenticated() ? <Navigate to="/sup/dashboard" /> : 
        isSAPAuthenticated() ? <Navigate to="/" /> : <Outlet />
    );
}

export default RedirectIfAuthenticatedGuardSUP;