import { Button } from "react-bootstrap";
import React, { useState,useContext } from "react";
import { VariantContext } from "../VariantContext";

const ServiceContentTitle = (props) => {


  const {
   
    mappedServiceData,
    SetMappedServiceData,
    viewReadOnly,
    SetViewReadOnly,
    editMode,SetEditMode
  } = useContext(VariantContext);


  const handleClick = (e) => {
    
    props.setAddNew(true) 
    // SetViewReadOnly(false);
    // SetEditMode(false);
    // SetMappedServiceData({});
   }


  return (
    <div class="content-title-action">
      <div class="title-action">
        <div class="title-action-left">
        <h5 class="head">Variants</h5> 
        <div class="action-block">
          <button
              class="btn add-new"
              id="addBtn"
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title="Add New"
              // onClick={() => {props.handleAddService(true)}}
              onClick={handleClick}
            >
              <span>
                <i class="fal fa-plus"></i> Add New
              </span>
            </button>
          </div> 
        </div>  
        <div class="title-action-right d-flex">
          

          {/* <div class="action-block">
            <button
              class="btn btn-card"
              rel="tooltip"
              data-toggle="tooltip"
              data-placement="left"
              title="view"
              view-click="service"
            >
              <span>
                <i class="fas fa-table"></i>
              </span>
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default ServiceContentTitle;
