import React, { useState } from "react";
import styled from "styled-components";
import CustomPopup from "../CustomPopup";
import { HexColorPicker } from "react-colorful";

type Props = {
  setColor?: React.Dispatch<React.SetStateAction<string>>;
  color?: string;
  disabled?: boolean;
  onChange?: (newColor: string) => void;
};

const CustomColorPicker = ({ setColor, color, disabled, onChange }: Props) => {
  const [defaultColor, setDefaultColor] = useState<string>("");
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const onClose = () => {
    setIsOpenPopup(false);
    setDefaultColor(color ?? "");
  };
  const onSucess = () => {
    setColor?.(defaultColor);
    setIsOpenPopup(false);
  };

  React.useEffect(() => {
    setDefaultColor(color ?? "");
  }, [color]);
  return (
    <>
      <Wrapper className="col-12">
        <Picker aria-disabled={disabled} onClick={() => setIsOpenPopup(true)}>
          Pick Color
        </Picker>
        <ColorWrapper>
          <div style={{ backgroundColor: defaultColor }}></div>
          <span>{defaultColor}</span>
        </ColorWrapper>
      </Wrapper>
      {isOpenPopup && (
        <CustomPopup isOpen={isOpenPopup} onClose={onClose} onSucess={onSucess}>
          <PickerWrapper>
            <HexColorPicker color={defaultColor} onChange={onChange} />
          </PickerWrapper>
        </CustomPopup>
      )}
    </>
  );
};

export default CustomColorPicker;
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 0;
  padding-right: 0;
`;
const PickerWrapper = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
`;
const ColorWrapper = styled.div`
  padding: 5px;
  display: flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  width: 55%;
  div {
    width: 45px;
    height: 35px;
    max-height: 30px;
  }
`;
const Picker = styled.div`
  background: #b2d6ff;
  white-space: nowrap;
  padding: 5px;
  border-radius: 5px;
  justify-content: center;
  width: 45% !important;
  text-align: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #160b0b;
  &[aria-disabled="true"] {
    pointer-events: none;
  }
`;
