import React from "react";

const StaffSortDropdownContent = () => {
  return (
    <>
      <a className="dropdown-item" href="">
        Recent
      </a>
      <a className="dropdown-item" href="">
        Name
      </a>
      <a className="dropdown-item" href="">
        Price low
      </a>
      <a className="dropdown-item" href="">
        Price high
      </a>
      <a className="dropdown-item" href="">
        oldest
      </a>
      </>
  );
}

export default StaffSortDropdownContent;
