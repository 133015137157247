import React from "react";

export const Validation = (
  values,
  servicetags,
  isPrice,
  priceDetails,
  isOnChange,
  field
) => {
  const errors = {};

  if (isOnChange) {
    if (field === "service_name") {
      if (!values.service_name) errors.service_name = "Service name required";
    } else if (field === "vehicle_select") {
      if (!values.vehicle_select)
        errors.vehicle_select = "Vehichle type required";
    } else if (field === "service_description") {
      if (!values.service_description) {
        errors.service_description = "Service description  required";
      }
    } else if (field === "servicetags") {
      if (!Object.keys(servicetags).length) {
        errors.servicetags = "Click Enter or Tab";
      }
   
           if (
             field === "service_images" &&
             values.service_images.length === 0
           ) {
             errors.service_images = "Service image required";
           }
    } else if (field === "isPrice") {
      if (isPrice.length === 0) errors.isPrice = "isPrice is required";
    } else if (field === "priceDetails") {
      if (!Object.keys(priceDetails).length)
        errors.priceDetails = "priceDetails submit is required";
    }
    if (field === "common_price") {
      if (!values.common_price) errors.common_price = "Common price required";
    }
   
  } else {
    // Service Name
    if (!values.service_name) errors.service_name = "Service name required";
    //Vehichle Select
    if (!values.vehicle_select)
      errors.vehicle_select = "Vehichle type required";
    //Service Description
    if (!values.service_description)
      errors.service_description = "Service description  required";
    //Service image
     if (values.service_images.length === 0) {
      errors.service_images = "Service image required";
    }

    if (!Object.keys(servicetags).length) {
      errors.servicetags = "Service tags is required";
    }
  }
  return errors;
};
