import React from "react";
import "../../../assets/fontawesome/css/fontawesome-all.css";
import "../../../assets/css/admin-custom.css";
import { Scrollbars } from "react-custom-scrollbars-2";
import mainLogo from "../../../assets/img/logo/logo.svg";
import { NavLink, useLocation } from "react-router-dom";
import BikeScooterOutlinedIcon from "@mui/icons-material/BikeScooterOutlined";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import StorefrontOutlinedIcon from "@mui/icons-material/StorefrontOutlined";
import GarageOutlinedIcon from "@mui/icons-material/GarageOutlined";
import GarageIcon from "@mui/icons-material/Garage";
import LocalCarWashIcon from "@mui/icons-material/LocalCarWash";
import EngineeringIcon from "@mui/icons-material/Engineering";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import Abouticon from "@mui/icons-material/Key";
import LogoColor from "./assets/img/motorque-logo.svg";

import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";
import DiscountIcon from "@mui/icons-material/Discount";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import RedeemIcon from "@mui/icons-material/Redeem";
import styled from "styled-components";

const Sidebar = () => {
  const location = useLocation();
  const [expandedIndex, setExpandedIndex] = React.useState(undefined);

  const handleClick = (index) => {
    setExpandedIndex(expandedIndex !== index ? index : undefined);
  };
  React.useEffect(() => {
    const currentItemIndex = menuItems.findIndex((item) =>
      item?.subMenus?.some((subitem) => subitem.path === location.pathname)
    );
    setExpandedIndex(currentItemIndex);
  }, [location.pathname]);

  const menuItems = [
    {
      path: "/sup/dashboard",
      name: "Dashboard",
      icon: <DashboardCustomizeOutlinedIcon />,
    },
    {
      path: "/sup/user",
      name: "Service Centers",
      icon: <LocalCarWashIcon />,
    },
    {
      path: "/sup/services",
      name: "Services",
      icon: <EngineeringIcon />,
    },
    {
      path: "/sup/vendors",
      name: "Vendors",
      icon: <StorefrontOutlinedIcon />,
    },
    {
      path: "/sup/variants",
      name: "Variants",
      icon: <NoCrashIcon />,
    },
    {
      path: "",
      name: "App",
      icon: <AppSettingsAltIcon />,
      subMenus: [
        {
          name: "Offers",
          path: "/sup/app/offer-management",
          icon: <DiscountIcon />,
        },
        {
          name: "Featured",
          path: "/sup/app/featured",
          icon: <NewReleasesIcon />,
        },
        {
          name: "Subscription",
          path: "/sup/app/subscriptions",
          icon: <CardMembershipIcon />,
        },
        {
          name: "Coupons",
          path: "/sup/app/coupons",
          icon: <RedeemIcon />,
        },
      ],
    },
  ];
  return (
    <div className="sidebar-block admin">
      <div className="menu-side">
        <div className="sidebar-min-head text-center">
          <div className="sidebar-logo">
            <span className="logo">
              <img
                className="img-fluid"
                src={LogoColor}
                style={{ height: "120px", objectFit: "cover" }}
                alt="Motorque logo"
              />
            </span>
          </div>
        </div>
        <Scrollbars style={{ height: `calc(100vh - 107px)` }}>
          <div style={{ height: "100%" }} className="menu-scroll">
            <ul className="list-sidebar bg-defoult menu-list">
              {menuItems.map((item, index) => {
                if (!item?.subMenus) {
                  return (
                    <li>
                      <NavLink
                        to={item.path}
                        key={index}
                        className="link "
                        activeClassName="active"
                      >
                        <div className="nav-label">
                          <span className="mr-1 icon-span">{item.icon}</span>
                          <span className="span-name">{item.name}</span>
                        </div>
                      </NavLink>
                    </li>
                  );
                } else {
                  return (
                    <StyledList
                      sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                        padding: 0,
                      }}
                      component="nav"
                      aria-labelledby="nested-list-subheader"
                      key={item?.name}
                      className={`styled-list ${
                        expandedIndex === index ? "expanded" : ""
                      }`}
                    >
                      <ListItemButton onClick={() => handleClick(index)}>
                        <ListItemIcon>{item?.icon}</ListItemIcon>
                        <ListItemText primary={item?.name} />
                        {expandedIndex === index ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )}
                      </ListItemButton>
                      <Collapse
                        in={expandedIndex === index}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {item?.subMenus?.map((subitem, i) => {
                            return (
                              <ListItemButton
                                className={
                                  subitem?.path === location?.pathname
                                    ? "active-navlink"
                                    : ""
                                }
                                sx={{ pl: 4 }}
                                key={subitem?.name}
                              >
                                <NavLink
                                  to={subitem?.path}
                                  key={subitem?.name}
                                  className="link "
                                  activeClassName="active"
                                >
                                  <div className="nav-label">
                                    <span className="mr-1 icon-span">
                                      {subitem?.icon}
                                    </span>
                                    <span className="span-name">
                                      {subitem?.name}
                                    </span>
                                  </div>
                                </NavLink>
                              </ListItemButton>
                            );
                          })}
                        </List>
                      </Collapse>
                    </StyledList>
                  );
                }
              })}
            </ul>
          </div>
        </Scrollbars>
      </div>
    </div>
  );
};

export default Sidebar;

const StyledList = styled(List)`
  width: 100% !important;
  border-top: 1px solid #084b7f;
  border-bottom: 1px solid #084b7f;
  background: #01579b !important;
  color: #ffffff;
  margin: 2px 0px;
  &.expanded {
    padding: 0px 0px 8px 0px !important;
  }
  &.styled-list {
    > .MuiListItemButton-root {
      padding-left: 28px;
      padding-right: 20px;
      .MuiListItemIcon-root {
        min-width: 30px;
        margin-right: 0.25rem !important;
      }
    }
  }
  .MuiListItemButton-root {
    color: #ffffff;
    a {
      color: #ffffff;
      .span-name {
        font-size: 14px;
        padding: 0 0 0 8px;
      }
    }
  }
  .MuiList-root {
    width: 100% !important;
  }
  .MuiSvgIcon-root {
    color: #ffffff;
  }
  .MuiCollapse-wrapperInner {
    .MuiList-root {
      .MuiListItemButton-root {
        padding: 0 8px 0 42px;
        &.active-navlink {
          a {
            border-radius: 6px;
            background: #0277bd;
          }
        }
      }
    }
  }
`;
