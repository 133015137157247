import React, { useState } from "react";
import styled from "styled-components";
import SimpleReactValidator from "simple-react-validator";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export type Props = {
  label?: String;
  name?: string;
  validations?: string;
  validator?: SimpleReactValidator;
  error?: string | null;
  value?: string | number;
  options?: [{ value: string; id: number }];
  data?: { id?: number; name?: string; code?: string }[];
  onSelect?: (e: any) => void;
  onChange?: (event: SelectChangeEvent<string | number>, val?: any) => void; //onChange function returns change event and selected item
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  { id: 1, name: "KSA" },
  { id: 2, name: "UAE" },
  { id: 3, name: "Qatar" },
  { id: 4, name: "Lebanon" },
  { id: 5, name: "Oman" },
  { id: 6, name: "Türkiye" },
  { id: 7, name: "Cyprus" },
  { id: 8, name: "Egypt" },
  { id: 9, name: "Kuwait" },
  { id: 10, name: "Bahrain" },
];

export function CustomDropDown({
  label,
  validations,
  validator,
  error,
  value,
  options,
  data,
  onSelect,
  onChange,
  name,
}: Props) {
  const simpleValidator = React.useRef(validator);
  const [dropdownValidate, setDropDownValidate] = useState(false);
  const [selectvalue, setSelectValue] = React.useState<number | string>(
    value ?? ""
  );
  const [optionsArray, setOptionsArray] = React.useState<
    { id?: number; name?: string; code?: string }[]
  >(data ? data : options ? options : names);

  const handleChange = (event: SelectChangeEvent<string | number>) => {
    //filter and return the chosen object from options
    const value = optionsArray?.filter((item) => {
      if (event.target.value === item.id) {
        return item;
      }
    });
    onChange?.(event, value?.[0]);
    setSelectValue(event.target.value);
  };
  React.useEffect(() => {
    setOptionsArray(data ? data : options ? options : names);
  }, []);
  const handleBlur = (e: any) => {
    e.preventDefault();
    if (!e?.target?.value && validations)
      simpleValidator?.current?.showMessageFor(
        name?.toString()! ? name?.toString()! : "field"
      );
    setDropDownValidate(!dropdownValidate);
  };
  React.useEffect(() => {
    if (value !== selectvalue) setSelectValue(value ?? "");
    return () => {};
  }, [value]);

  // React.useEffect(() => {
  //   simpleValidator?.current?.showMessages();
  // }, [dropdownValidate]);
  return (
    <StyledDropDownWrapper className="StyledDropDownWrapper">
      <InputLabel id="custom-select-label">{label}</InputLabel>
      <FormControl className="FormControl" sx={{ m: 2, width: 370, mt: 2 }}>
        <Select
          className="styled-FormControl"
          value={selectvalue}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="Country"
          MenuProps={MenuProps}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem selected={selectvalue ? false : true} disabled value="">
            <label>Select a country</label>
          </MenuItem>
          {optionsArray &&
            optionsArray.map((item, i) => (
              <MenuItem key={i} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <Errortext>
        {simpleValidator?.current?.message(
          name?.toString()! ? name?.toString()! : "field",
          value?.toString() && value?.toString(),
          validations ?? ""
        )}
      </Errortext>
    </StyledDropDownWrapper>
  );
}

export default CustomDropDown;
const Errortext = styled.span`
  display: block;
  font-size: 14px;
  color: #e91344;
  letter-spacing: 0.5px;
  padding-top: 0px !important;
`;
const StyledDropDownWrapper = styled.div`
  label.MuiFormLabel-root {
    margin: 0 16px 0px 16px;
  }

  .srv-validation-message {
    margin: 0px 0px 10px 16px !important;
  }
  .styled-FormControl {
    border-radius: 12px;
    background: #e8e8e8;
    height: 52px;
  }
  .MuiFormControl-root {
    .MuiInputBase-root {
      .MuiSelect-select {
        label {
          margin: 0px;
          font-family: "Outfit Regular";
          font-weight: 400;
          color: #a09d9d;
          font-size: 14px;
        }
      }
    }
  }
`;
