import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { approveOrRejectService } from '../../../../../api/services/sup/service-management';
import { VariantContext } from '../VariantContext';
import { saveVariants } from '../../../../../api/services/sup/variant-management';

 const AddNewModal = (props) => {
  // const { table, SetTable } = useContext(VariantContext);
const intialValue = {
  variant_name: "",
  vehicle_type_id: "1"
}
  
  const [formData, setFormData] = useState(intialValue);
  const onCancel = () => {
    console.log("close");
    props.setAddNew(false);
    // setError('');
    // setRejectedReason('');
    // props.setRejectModal(false);
  }
  const handleSubmit = () => {
    console.log("submit");
    props.setAddNew(false);
    const response = saveVariants(formData);
    response
      .then((res) => {
        toast.success("Vehicle variant created successfully", {
          position: "top-right",
        });
        props.SetRefreshApiList(!props.refreshApiList);
      })
      .catch((err) => {
        console.log(err, "err");
        toast.error("Something went wrong", {
          position: "top-right",
        });
      });
  };

  return (
 <div className="action-modal p-3">
      <div className="d-flex justify-content-end">
        <button className="btn close-btn" onClick={onCancel}>
          <i className="fal fa-times"></i>
        </button>
      </div>
      <h4 className='mb-3'>Add New Variant</h4>
 
     <form className="w-100" onSubmit={handleSubmit} >
      
     <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-car"></i>Vehicle Type
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="input-block">
            <select
              id="warranty_period"
              className="form-select"
              value={formData.variant_type_id}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  vehicle_type_id: e.target.value
                });
              }}
            >
              <option value="" disabled>
                Select Type
              </option>
              <option value="1">Two wheeler</option>
              <option value="2">Four wheeler</option>
              <option value="3">Others</option>            
            </select>
           
          </div>
        </div>

        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-motorcycle"></i>Variant Name
            </span>
          </div>
          <div className="input-block">
          <input
            type="text"
            className="form-control"
            placeholder=""
            onChange={(e)=>{
              setFormData({
                ...formData,
                variant_name: e.target.value
              });
            }}
          />
        </div>
        </div>



     <div className="d-flex justify-content-center pt-3">
        <button type='button' className="btn mr-3 btn-cancel " onClick={onCancel}>
          Cancel
        </button>
        <button type='submit' class="btn btn-update">
          Save<i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
        </button>
      </div>
     </form>
    </div>
  )
}

export default AddNewModal;
