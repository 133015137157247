import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import isSUPAuthenticated from "../../../utils/isSUPAuthenticated";

const AuthGuardSUP = () => {
    return (
        isSUPAuthenticated() ? <Outlet /> : <Navigate to="/sup" />
    );
}

export default AuthGuardSUP;