import { supAxios } from "../../axios";


export const logout = async (data) => {
    const axiosInst = supAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: 'logout',
        });
        if (res.status) {
            localStorage.removeItem('supAuthToken');
            localStorage.setItem('isSUPAuthenticated', false);
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}
