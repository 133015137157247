import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { OrdersProvider } from "./OrdersContext";
import OrderContentTitle from "./Ui/OrderContentTitle";
import ContentHeader from "../../../ServiceAdmin/Layout/Ui/ContentHeader";
import WindowOrders from "./WindowOrders"
import mainLoader from "../../../../assets/img/loader/loader.gif";

const Orders = () => {
  const [addOrders, SetAddOrders] = useState(false);
  const handleAddOrders = () => SetAddOrders(!addOrders);
  let toggleClassCheck = addOrders ? " change" : "";
  const [pageloader, SetPageLoader] = useState(false);
  //Use Effect Loader
  useEffect(() => {
    SetPageLoader(true);
    const loaderTime = setTimeout(() => {
      SetPageLoader(false);
    }, 1500);
    return () => {
      clearTimeout(loaderTime);
    };
  }, []);
  return (
    <>
      {
        pageloader ? (
          <div className="loader-block" >
            <img className="img-fluid" src={mainLoader} alt="Main Loader" />
          </div >
        ) : (
          <Layout>
            <OrdersProvider>
              <div className="content-block order-main">
                <section className="main-section">
                  <div className="container-fluid p-0">
                    <ContentHeader />
                    <div className="col-12 p-0 page-window">
                      <OrderContentTitle handleAddOrders={handleAddOrders} />
                      <div className="content-section">
                        <div className={`content-window ${toggleClassCheck} `}>
                          <WindowOrders handleAddOrders={handleAddOrders} SetAddOrders={SetAddOrders} addOrders={addOrders} />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </OrdersProvider>
          </Layout>
        )}
    </>
  );
};

export default Orders;
