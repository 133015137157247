import React, { useContext } from 'react'
import { CustomerContext } from '../CustomerContext';

const OrderHistoryDropDownContent = (props) => {
   
    const {
        vehicleFilter,
        setVehicleFilter,
        setSelectedOption,
        vehicleData,
        setSelectedVehicleId
      } = useContext(CustomerContext);

    const vehicleFilterStatusUpdate=(name,vehicle)=>{
        
        props.setOption(name)
            if(name != "All Orders"){
                setVehicleFilter(true);
                setSelectedOption(name);
                setSelectedVehicleId(1)   //static
            }else{
                setVehicleFilter(false);
            }
           props.setOrderView(false);
        }

  return (
    <div className="dropdown-menu d-block p-0" aria-labelledby="dropdownMenuButton">
    <ul className='vehicle-listing-dropdown mb-0 pl-0'>
      <li className="dropdown-item" onClick={()=>{vehicleFilterStatusUpdate("All Orders")}}>All Orders</li>  
       {vehicleData && vehicleData.map((vehicle)=>(
      <li className="dropdown-item" onClick={(e)=>{vehicleFilterStatusUpdate(vehicle.vehicle_registration_number,vehicle)}}>{vehicle.vehicle_registration_number}</li>
      ))}  
      </ul>    
  </div>
  )
}

export default OrderHistoryDropDownContent