import React from "react";
import BookingsSortDropdown from "./BookingsSortDropdown";
import BookingsFilterDropdown from "./BookingsFilterDropdown";

const FilterWrapper = () => {
  return (
    <div className="filter-wrapper">
      <div className="search-sort-filter">
        <div className="header-action-block">
          <form action="" method="">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text search-span-txt">
                  <i className="fal fa-search"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                id="search-id"
                placeholder="Search"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
          </form>
          <div className="sort-filter">
            <BookingsSortDropdown />

            <BookingsFilterDropdown />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterWrapper;
