import { supAxios } from "../../axios";

// const urlPrefix = 'variants';

const getVehicleTypes = async () => {
    const axiosInst = supAxios();
    try {
        const res = await axiosInst({
            method: 'GET',
            url: `approval/vehicle-types`,
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
    }
}

const getVehicleVariants = async (variantType=null, status = null) => {
    const axiosInst = supAxios();
    try {
        const res = await axiosInst({
            method: 'GET',
            url: `variants/vehicle-variants/`+variantType+`/`+status,
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
    }
}

const approveOrRejectVariant = async (data = {}) => {
    const axiosInst = supAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url : `variants/vehicle-variants/approvereject`,
            data,
        });
         console.log(res);
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

const saveVariants = async (data = {}) => {
    const axiosInst = supAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url : `/variants/save-variants`,
            data,
        });
         console.log(res);
        if (res.status) {
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

export { getVehicleTypes, getVehicleVariants, approveOrRejectVariant, saveVariants};