import ApprovalRow from "./ApprovalRow";
import React, { useState, useContext, Fragment, useEffect } from "react";
import { ApprovalContext } from "./ApprovalContext";
import noDataImage from "../../../../assets/img/icon/upload/empty-box.svg";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

const WindowLeft = (props) => {
  const { pageNumber, setPageNumber } = props;
  const { table, mappedServiceData } = useContext(ApprovalContext);
  const [viewservice, SetViewService] = useState(false);
  const [filterTable, SetFilterTable] = useState([]);
  let toggleClassCheck = viewservice ? " view" : "";
  //Handle Service Json
  const handleViewService = () => {
    SetViewService(!viewservice);
  };

  useEffect(() => {
    let tableData = table.map((item, i) => ({
      id: item.id,
      name: item.name,
      vehicle_select: item.vehicle_select,
      status: item.status,
      serviceCenter: item.serviceCenter,
      isNew: item.is_new,
      is_active: item.is_active,
    }));
    SetFilterTable(tableData);
    getPendingServiceCount();
  }, [table]);

  const getPendingServiceCount = () => {
    let tableData = table.map((item, i) => ({
      id: item.id,
      name: item.name,
      vehicle_select: item.vehicle_select,
      status: item.status,
      serviceCenter: item.serviceCenter,
      isNew: item.is_new,
      is_active: item.is_active,
    }));

    let pendingServices = tableData.filter((p) => p.status === 1);
    props.setPendingCount(Object.keys(pendingServices).length);
  };

  useEffect(() => {
    if (props.viewPending) {
      let tableData = filterTable.filter((p) => p.status === 1);
      SetFilterTable(tableData);
    } else {
      let tableData = table.map((item, i) => ({
        id: item.id,
        name: item.name,
        vehicle_select: item.vehicle_select,
        status: item.status,
        serviceCenter: item.serviceCenter,
        isNew: item.is_new,
        is_active: item.is_active,
      }));
      SetFilterTable(tableData);
    }
  }, [props.viewPending]);

  //With React

  const usersPerPage = 10;
  const pagesVisited = pageNumber * usersPerPage;
  const displayUsers = filterTable.slice(
    pagesVisited,
    pagesVisited + usersPerPage
  );
  let pageCount = Math.ceil(filterTable.length / usersPerPage);
  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <Fragment>
      <Wrapper className="content-window-left">
        <div className="content-table-block">
          <div className="content-table table-responsive">
            <table className="table custom-table clickable-table">
              <thead>
                <tr
                  className={`trow single-row          ${
                    Object.keys(mappedServiceData).length &&
                    mappedServiceData.service_id_pk === table.id
                      ? "activeRow"
                      : "test1"
                  }`}
                >
                  <th scope="col">Service Name</th>
                  <th scope="col">Service Center</th>
                  <th scope="col">Vehicle Type</th>
                  <th scope="col">App Status</th>
                  <th scope="col" style={{ textAlign: `center` }}>
                    Approval Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* {filterTable && filterTable.map((a, index) => (
                  <ApprovalRow
                    handleViewService={handleViewService}
                    id={a.id}
                    key={a.id}
                    name={a.name}
                    ApprovalFormClose={props.ApprovalFormClose}
                    status={a.status}
                    serviceCenter={a.serviceCenter}
                    vehicleSelect={a.vehicle_select}
                  />
                ))} */}
                {Object.keys(displayUsers).length ? (
                  displayUsers.map((a, index) => (
                    <ApprovalRow
                      handleViewService={handleViewService}
                      id={a.id}
                      key={a.id}
                      name={a.name}
                      isNew={a.isNew}
                      ApprovalFormClose={props.ApprovalFormClose}
                      status={a.status}
                      serviceCenter={a.serviceCenter}
                      vehicleSelect={a.vehicle_select}
                      isActive={a.is_active}
                    />
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="no-data-td">
                      <div className="no-data-available">
                        <div className="no-data-img">
                          <img src={noDataImage} alt="" />
                          <h5>No Data Available</h5>
                          {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Amet ullam voluptatem quam pariatur?</p> */}
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {Object.keys(displayUsers).length ? (
              <ReactPaginate
                previousLabel={<i className="fal fa-long-arrow-left"></i>}
                nextLabel={<i className="fal fa-long-arrow-right"></i>}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={"paginationBttns"}
                previousLinkClassName={"previousBttn"}
                nextLinkClassName={"nextBttn"}
                disabledClassName={"paginationDisabled"}
                activeClassName={"paginationActive"}
                selectedPage={pageNumber}
                onPageActive={changePage}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </Wrapper>
    </Fragment>
  );
};

export default WindowLeft;
const Wrapper = styled.div`
  .custom-table tr td {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
