import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import isSAPAuthenticated from "../../../utils/isSAPAuthenticated";
import isSUPAuthenticated from "../../../utils/isSUPAuthenticated";

const RedirectIfAuthenticatedGuardSAP = () => {
    return (
        isSAPAuthenticated() ? <Navigate to="/service" /> : 
        isSUPAuthenticated() ? <Navigate to="/sup" /> : <Outlet />
    );
}

export default RedirectIfAuthenticatedGuardSAP;