import React from "react";

function FilterDropdownContent() {
  return (
    <>
      <a className="dropdown-item" href="">
        All vehivles
      </a>
      <a className="dropdown-item" href="">
        Four wheelers only
      </a>
      <a className="dropdown-item" href="">
        Four wheelers only
      </a>
      </>
  );
}

export default FilterDropdownContent;
