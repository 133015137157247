import React, { useState, useContext, useEffect } from "react";
import { SuperContext } from "../../Pages/User/SuperContext";
import { toast } from "react-toastify";
import "../../../../assets/css/tags.css";
import { Validation } from "../../Layout/Ui/Validation";
import styled from "styled-components";
import ConfirmModal from "./Ui/ConfirmModal";
import RejectVariantModal from "./Ui/RejectModal";
import RejectModal from "./Ui/RejectModal";
import ReactTooltip from "react-tooltip-rc";

const initialState = {
  name: "",
  owner_name: "",
  email: "",
  phone_number: "",
  phone_extension: "091",
  website: "",
  address: "",
  password: "",
  confirm_password: "",
  google_location_url: "",
};

const errorFields = {
  name: "",
  owner_name: "",
  email: "",
  phone_number: "",
  address: "",
  password: "",
  confirm_password: "",
  google_location_url: "",
};

function SuperUserForm(props) {
  const [error, setError] = useState(false);
  const [formErrors, SetFormErrors] = useState({});
  const [editServiceId, setEditServiceId] = useState("");
  const [formData, SetFormData] = useState(initialState);
  const [rejectModal, setRejectModal] = useState(false);
  const [approveButtonText, setApproveButtonText] = useState("Approve");
  const [rejectButtonText, setRejectButtonText] = useState("Reject");

  const {
    mappedServiceData,
    SetMappedServiceData,
    viewReadOnly,
    handleServiceEdit,
    editMode,
    SetEditMode,
    isUpdateForm,
    SetFormChange,
    hideLicenseField,
    setHideLicenseField,
  } = useContext(SuperContext);
  //Image Modal

  // useEffect
  useEffect(() => {
    SetFormErrors({});
    let { id, ...rest } = mappedServiceData;
    setEditServiceId(id);
    if (props.addservice && !isUpdateForm) {
      SetFormData(initialState);
    } else {
      SetFormData(rest);
    }

    return () => {
      SetFormData(initialState);
    };
  }, [props.addservice, isUpdateForm, mappedServiceData]);

  // Toastify
  const updateToast = () => {
    return editMode ? "Updated Successfully" : "Added Successfully";
  };

  const onChangeValidate = (field, value) => {
    const errors = Validation(formData, true, field, value);
    if (Object.keys(errors).length !== 0) {
      setError(true);
      if (Object.keys(formErrors).length !== 0) {
        const updatedErrorObject = { ...formErrors, ...errors };
        SetFormErrors(updatedErrorObject);
      } else {
        SetFormErrors(errors);
      }
    } else {
      setError(false);
      SetFormErrors({});
    }
  };
  //Form Submit
  const handleServiceForm = (e) => {
    let errors = Validation(formData);

    if (editMode) {
      if (Object.keys(errors).length !== 0) {
        delete errors["confirm_password"];
        delete errors["password"];
      }
    }

    const hasOtherErrors = Object.keys(errors)
      .filter((key) => key !== "google_location_url")
      .some((key) => errors[key] !== undefined);

    if (hasOtherErrors) {
      e.preventDefault();
      SetFormErrors(errors);
      setError(true);
    } else {
      if (editMode) {
        e.preventDefault();
        handleServiceEdit(formData, editServiceId);
        props.serviceformClose(false);

        SetEditMode(false);
        setEditServiceId("");
        SetMappedServiceData({});
        SetFormChange(false);
        setError(false);
        toast.success(updateToast, {
          position: "bottom-right",
        });
      } else {
        e.preventDefault();
        handleServiceEdit(formData, editServiceId);
        props.serviceformClose(false);

        SetEditMode(false);
        setEditServiceId("");
        SetMappedServiceData({});
        SetFormChange(false);
        setError(false);
        toast.success(updateToast, {
          position: "bottom-right",
        });
      }
    }
  };

  const handleKeyPress = (e) => {
    var key = e.key;
    var regex = /[0-9]|\./;
    if (!regex.test(key)) {
      e.preventDefault();
    }
  };
  const nameFieldRestriction = (event) => {
    if (
      !(
        (event.charCode > 64 && event.charCode < 91) ||
        (event.charCode > 96 && event.charCode < 123) ||
        event.charCode == 8 ||
        event.charCode == 32 ||
        event.charCode == 45
      )
    ) {
      event.preventDefault();
    }
  };
  const deletePopUp = (e) => {
    setRejectModal(true);
    e.stopPropagation();
  };
  const handleApproveClick = () => {
    setApproveButtonText("Approved");
  };

  const handleRejectClick = (e) => {
    deletePopUp(e);
  };
  return (
    <div className="col-12">
      <form className="w-100" id="add-service" onSubmit={handleServiceForm}>
        {/* Serice Name */}
        {/* <Form.Field> */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-user"></i>Name{" "}
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              className="form-control"
              placeholder=""
              readOnly={viewReadOnly}
              value={formData.name}
              onChange={(e) =>
                SetFormData(
                  { ...formData, name: e.target.value },
                  SetFormChange(true),
                )
              }
              onBlur={(e) => onChangeValidate("name")}
            />
            {error && formErrors.name ? (
              <span className="form-error">{formErrors.name}</span>
            ) : (
              ""
            )}
          </div>
          {/* {errors.firstName?.type === 'required' && <p>Please check the First Name</p>}
          {errors.firstName?.type === 'maxLength' && <p>Please check the length</p>} */}
        </div>
        {/* </Form.Field> */}
        {/* Owner Name */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-user"></i>Owner Name
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              className="form-control"
              placeholder=""
              onKeyPress={nameFieldRestriction}
              readOnly={viewReadOnly}
              value={formData.owner_name}
              onChange={(e) =>
                SetFormData(
                  { ...formData, owner_name: e.target.value },
                  SetFormChange(true),
                )
              }
              onBlur={(e) => onChangeValidate("owner_name")}
            />
            {error && formErrors.owner_name ? (
              <span className="form-error">{formErrors.owner_name}</span>
            ) : (
              ""
            )}
          </div>
        </div>

        {/* Email */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-envelope"></i>Email
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              className="form-control"
              placeholder=""
              readOnly={viewReadOnly}
              value={formData.email}
              onChange={(e) =>
                SetFormData(
                  { ...formData, email: e.target.value },
                  SetFormChange(true),
                )
              }
              onBlur={(e) => onChangeValidate("email")}
            />
            {error && formErrors.email ? (
              <span className="form-error">{formErrors.email}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Phone-details-section */}
        <section className="phone-section">
          {/* Phone Number */}
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-phone"></i>Phone Number
                <sup className="theme-text">*</sup>
              </span>
            </div>
            {/* Serice Phone Extension */}
            <div className="input-block">
              <div className="row m-0">
                <input
                  type="text"
                  style={{ maxWidth: "60px", marginRight: "10px" }}
                  className="form-control phone-extension"
                  // readonly
                  placeholder="091"
                  // readOnly={viewReadOnly}
                  value="091"
                  onChange={(e) =>
                    SetFormData(
                      {
                        ...formData,
                        phone_extension: parseInt(e.target.value),
                      },
                      SetFormChange(true),
                    )
                  }
                  onBlur={(e) => onChangeValidate("phone_number")}
                />

                <input
                  type="text"
                  className="form-control"
                  onKeyPress={handleKeyPress}
                  placeholder="9898989898"
                  autoComplete="new-off"
                  readOnly={viewReadOnly}
                  value={formData.phone_number}
                  onChange={(e) =>
                    SetFormData(
                      { ...formData, phone_number: e.target.value },
                      SetFormChange(true),
                    )
                  }
                  onBlur={(e) => onChangeValidate("phone_number")}
                />
                {error && formErrors.phone_number ? (
                  <span className="form-error">{formErrors.phone_number}</span>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </section>
        {/* Password */}
        {!isUpdateForm && (
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-lock"></i>Password
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <input
                type="password"
                autoComplete="new-password"
                className="form-control"
                placeholder=""
                readOnly={viewReadOnly}
                value={formData.password}
                onChange={(e) =>
                  SetFormData(
                    { ...formData, password: e.target.value },
                    SetFormChange(true),
                  )
                }
                onBlur={(e) => onChangeValidate("password")}
              />
              {error && formErrors.password ? (
                <span className="form-error">{formErrors.password}</span>
              ) : (
                ""
              )}
            </div>
          </div>
        )}

        {/* Confirm password */}
        {!isUpdateForm && (
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-lock"></i>Confirm Password
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block">
              <input
                type="password"
                className="form-control"
                placeholder=""
                readOnly={viewReadOnly}
                value={formData.confirm_password}
                onChange={(e) =>
                  SetFormData(
                    { ...formData, confirm_password: e.target.value },
                    SetFormChange(true),
                  )
                }
                onBlur={(e) => onChangeValidate("confirm_password")}
              />
              {error && formErrors.confirm_password ? (
                <span className="form-error">{formErrors.confirm_password}</span>
              ) : (
                ""
              )}
            </div>
          </div>
        )}

        {/* Serice Address */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-map-marker-alt"></i>Address
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <textarea
              type="text"
              className="form-control"
              placeholder=""
              readOnly={viewReadOnly}
              value={formData.address}
              onChange={(e) =>
                SetFormData(
                  { ...formData, address: e.target.value },
                  SetFormChange(true),
                )
              }
              onBlur={(e) => onChangeValidate("address")}
            />
            {error && formErrors.address ? (
              <span className="form-error">{formErrors.address}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* Website*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-globe"></i>Website
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            placeholder=""
            readOnly={viewReadOnly}
            value={formData.website}
            onChange={(e) =>
              SetFormData(
                { ...formData, website: e.target.value },
                SetFormChange(true),
              )
            }
          />
        </div>
        {/* Google location*/}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-map-marker-alt"></i>Google Location
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              className="form-control"
              placeholder=""
              readOnly={viewReadOnly}
              value={formData.google_location_url}
              onChange={(e) =>
                SetFormData(
                  { ...formData, google_location_url: e.target.value },
                  SetFormChange(true),
                )
              }
            />
            {error && formErrors.google_location_url ? (
              <span className="form-error">{formErrors.google_location_url}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        {/* idproof */}
        {!hideLicenseField && (
          <div>
        <div class="col-12 block-header main-title">
          <h6>
            <span class="header-title">License</span>
          </h6>
        </div>
        
          <IdproofWrapper>
            <div className="date">
              <span>Expiry Date:</span>{" "}
              <span style={{ marginLeft: "5px" }}>{"24/05/2024"}</span>
            </div>
            <div className="license" style={{ marginTop: "-5px" }}>
              <span className="license-name">
                <i className="fa fa-file"></i> License Expiry
              </span>
              <ReactTooltip
                id="download"
                place="bottom"
                type="info"
                effect="float"
              />

              <a
                type="button"
                className="btn btn-outline-dark"
                data-tip="Download"
                data-tooltip-place="bottom"
                href={""}
                target="_blank"
                rel="noopener "
                download
              >
                <i className="fal fa-arrow-to-bottom"></i>
              </a>
              <ReactTooltip />
            </div>
            <div className="buttons-container">
              <button className="approve-button" onClick={handleApproveClick}>
                <svg
                  width="15"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.334 0C17.723 0 20 2.378 20 5.916V14.084C20 17.622 17.723 20 14.333 20H5.665C2.276 20 0 17.622 0 14.084V5.916C0 2.378 2.276 0 5.665 0H14.334ZM14.334 1.5H5.665C3.135 1.5 1.5 3.233 1.5 5.916V14.084C1.5 16.767 3.135 18.5 5.665 18.5H14.333C16.864 18.5 18.5 16.767 18.5 14.084V5.916C18.5 3.233 16.864 1.5 14.334 1.5ZM14.0895 7.097C14.3825 7.39 14.3825 7.864 14.0895 8.157L9.3435 12.903C9.1975 13.05 9.0055 13.123 8.8135 13.123C8.6225 13.123 8.4295 13.05 8.2835 12.903L5.9095 10.53C5.6165 10.237 5.6165 9.763 5.9095 9.47C6.2025 9.177 6.6765 9.177 6.9695 9.47L8.8135 11.312L13.0295 7.097C13.3225 6.804 13.7965 6.804 14.0895 7.097Z"
                    fill="white"
                  />
                </svg>
                {approveButtonText}
              </button>
              <button className="reject-button" onClick={handleRejectClick}>
                <svg
                  width="15"
                  height="21"
                  viewBox="0 0 21 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.645 7.84375L7.85303 12.6357"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.6466 12.6388L7.85059 7.8418"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M14.584 1H5.915C2.894 1 1 3.139 1 6.166V14.334C1 17.361 2.885 19.5 5.915 19.5H14.583C17.614 19.5 19.5 17.361 19.5 14.334V6.166C19.5 3.139 17.614 1 14.584 1Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {rejectButtonText}
              </button>
            </div>
          </IdproofWrapper>
          </div>
        )}
        <div className="col-12 text-right p-0 mt-3">
          <button
            className={"btn btn-update" + (viewReadOnly ? " d-none" : "")}
            type="submit"
          >
            <i className="fal fa-save mr-2"></i>
            {editMode ? "Update" : "Save"}
          </button>
        </div>
        {rejectModal && (
          <RejectModal
            setRejectModal={setRejectModal}
            setRejectButtonText={setRejectButtonText}
          />
        )}
      </form>
    </div>
  );
}

export default SuperUserForm;
const IdproofWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  .date {
    width: 100%;
    max-width: 175px;
    text-align: left;
  }
  .license {
    text-overflow: ellipsis;
    white-space: pre-wrap;
    width: 100%;
    max-width: 300px;
    background: #e4f0f0;
    display: flex;
    align-items: center;
    width: auto;
    padding-left: 10px;
    padding-right: 2px;
    
  }
  .license-name{
    white-space: nowrap;
    width: 110px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .btn-outline-dark {
    color: #343a40;
    background-color: transparent;
    background-image: none;
    border-color: unset;
    border: unset;
  }
  .erWIxO .btn-outline-dark:not(:disabled):not(.disabled):active,
  .erWIxO .show > .btn-outline-dark.dropdown-toggle {
    background: unset;
    color: black;
  }
  .__react_component_tooltip {
    background: #337ab7 !important;
  }

  .btn-outline-dark:hover {
    background-color: unset;
    border-color: unset;
    color: #343a40;
  }
  .buttons-container {
    display: flex;
    justify-content: center;
    margin-top: -5px;
  }

  .approve-button,
  .reject-button {
    padding: 8px 20px;
    margin: 0px 5px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    width: 100px;
    min-width: 129px;
    text-transform: uppercase;
  }

  .approve-button {
    background-color: #009970;
    color: white;
    width: 100%;
    gap: 5px;
    height: 35px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }

  .reject-button {
    background-color: #f67979;
    color: white;
    width: 100%;
    gap: 5px;
    height: 35px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
  }

  .approve-button:hover,
  .reject-button:hover {
    background-color: darken(currentColor, 10%);
  }
`;
