import React from "react";
import styled from "styled-components";
import RegisterVendor from "./components/registration";
import VendorDocumentation from "./components/documentation";
import AddRegistorVendor from "./components/addvendorform/addvendorregistration";
import AddVendorDocument from "./components/addvendorform/addvendordocumentation";

type Props = {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  activeStep: number;
  dataStored: any;
  docData?: any;
  addVendor: boolean;
  viewVendor: boolean;
  setAddActiveStep: React.Dispatch<React.SetStateAction<number>>;
  addActiveStep: number;
  setRightView: React.Dispatch<React.SetStateAction<boolean>>;
  setListStatus: React.Dispatch<React.SetStateAction<boolean>>;
  listStatus: boolean;
  SetAddVendor: React.Dispatch<React.SetStateAction<boolean>>;
  setSaveChangesClicked: React.Dispatch<React.SetStateAction<boolean>>;
};
type CredentialData = {
  phone: undefined | string;
  email: string;
};
type countryValue = {
  // id: undefined,
  name: string;
};
const VendorForm = ({
  setActiveStep,
  activeStep,
  dataStored,
  addVendor,
  viewVendor,
  setAddActiveStep,
  addActiveStep,
  setRightView,
  setListStatus,
  listStatus,
  SetAddVendor,
  setSaveChangesClicked,
}: Props) => {
  const [credentialsData, setCredentialsData] = React.useState<CredentialData>({
    phone: undefined,
    email: "",
  });
  const [countryValue, setCountryValue] = React.useState<countryValue>({
    // id: undefined,
    name: "",
  });
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const addHandleNext = () => {
    setAddActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  return (
    <div>
      {viewVendor && (
        // Render content when viewVendor is true
        <div>
          {activeStep === 0 && (
            <ContentWrapper className="ContentWrapper">
              <RegisterVendor
                onNextClick={handleNext}
                dataStored={dataStored}
              />
            </ContentWrapper>
          )}
          {activeStep === 1 && (
            <ContentWrapper className="ContentWrapper">
              <VendorDocumentation
                onNextClick={handleNext}
                vendorId={dataStored?.vendor_id_pk}
                setSaveChangesClicked={ setSaveChangesClicked}
                dataStored={dataStored}
              />
            </ContentWrapper>
          )}
        </div>
      )}
      {addVendor && (
        <div>
          {addActiveStep === 0 && (
            <ContentWrapper className="ContentWrapper">
              <AddRegistorVendor
                onNextClick={addHandleNext}
                credentialsData={credentialsData}
                setCredentialsData={setCredentialsData}
                countryValue={countryValue}
                setCountryValue={setCountryValue}
              />
            </ContentWrapper>
          )}
          {addActiveStep === 1 && (
            <ContentWrapper className="ContentWrapper">
              <AddVendorDocument
                SetAddVendor={SetAddVendor}
                email={credentialsData.email}
                phone={credentialsData.phone}
                countryValue={countryValue.name}
                onNextClick={addHandleNext}
                setRightView={setRightView}
                setListStatus={setListStatus}
                listStatus={listStatus}
              />
            </ContentWrapper>
          )}
        </div>
      )}
    </div>
  );
};

export default VendorForm;
const ContentWrapper = styled.div`
  padding: 15px 15px 15px 15px;
`;
