import React, { useEffect, useState } from "react";
import { Button, Modal, Container, Row, Col } from "react-bootstrap";
import CropModal from "../../../../ImageCropper";
import uploadImageFile from "../../../../../assets/img/icon/upload/photos.svg";

interface ImageMedia {
  base64: string;
  type: "image";
}

interface VideoMedia {
  videoSrc: string;
  type: "video";
}

type Media = ImageMedia | VideoMedia;
interface CustomFileUploadModalProps {
  showFileModal: boolean;
  hideFileModal?: (show: boolean) => void;
  maxPictures?: number;
  setPic: React.Dispatch<
    React.SetStateAction<
      (
        | {
            base64: string;
            type: "image";
          }
        | { videoSrc: string; type: "video" }
      )[]
    >
  >;
  setImage: React.Dispatch<
    React.SetStateAction<
      (
        | {
            base64: string;
            type: "image";
          }
        | { videoSrc: string; type: "video" }
      )[]
    >
  >;
  allowVideo?: boolean;
  onConfirmClick?: (media: Media[], blob: Blob) => void;
}

const CustomFileUploadModal: React.FC<CustomFileUploadModalProps> = ({
  showFileModal,
  hideFileModal,
  maxPictures = 1,
  setPic,
  setImage,
  allowVideo = false,
  onConfirmClick,
}) => {
  const [selectedMedia, setSelectedMedia] = useState<
    (
      | {
          base64: string;
          type: "image";
        }
      | { videoSrc: string; type: "video" }
    )[]
  >([]);
  const [selectedMediaBlob, setSelectedMediaBlob] = useState<Blob>();
  const fileToDataUri = (file: File) => {
    return new Promise<{
      base64: string;
      name: string;
      type: string;
      size: number;
    }>((res) => {
      const reader = new FileReader();
      const { type, name, size } = file;
      reader.addEventListener("load", () => {
        res({
          base64: reader.result as string,
          name,
          type,
          size,
        });
      });
      reader.readAsDataURL(file);
    });
  };

  const [src, setSrc] = useState<string | null>(null);
  const setCroppedImageUrlCallback = (url: string, blob: File) => {
    if (url !== null) {
      setSelectedMediaBlob(blob);
      fileToDataUri(blob).then((newImage) => {
        setSelectedMedia([
          ...(selectedMedia ?? []),
          { ...newImage, type: "image" },
        ]);
      });
    }
  };
  const [blob, setBlob] = React.useState<Blob | null>(null);

  const uploadMedia = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const isVideo = file.type.startsWith("video/");

      if (isVideo) {
        if (allowVideo) {
          const videoSrc = URL.createObjectURL(file);
          setSelectedMedia([...selectedMedia, { videoSrc, type: "video" }]);
        } else {
          console.log("Video uploads are not allowed.");
        }
      } else {
        const reader = new FileReader();
        reader.addEventListener(
          "load",
          () => {
            const blob = new Blob([reader.result as ArrayBuffer], {
              type: file.type,
            });
            setBlob(blob);
            setSrc(reader.result as string);
            setCropModal(true);
          },
          false
        );
        reader.readAsDataURL(file);
      }
    }
  };

  const closeFileUpload = () => {
    hideFileModal?.(false);
    setSelectedMedia([]);
  };

  const MediaSubmit = (e: React.FormEvent) => {
    onConfirmClick?.(selectedMedia, selectedMediaBlob!);
    setPic(selectedMedia);
    setImage?.(selectedMedia);
    hideFileModal?.(false);
    e.preventDefault();
  };

  const [openCropModal, setCropModal] = useState(false);
  useEffect(() => {
    if (showFileModal) {
      setSelectedMedia([]);
    }
  }, [showFileModal]);

  const removeMedia = (index: number) => {
    const newSelectedMedia = [...selectedMedia];
    newSelectedMedia.splice(index, 1);
    setSelectedMedia(newSelectedMedia);
  };

  return (
    <Modal
      className="file-modal"
      show={showFileModal}
      onHide={() => hideFileModal?.(false)}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Container>
          <Row>
            <Col xs={3} className="p-0">
              <Row className="guidelines-info w-100">
                <Col md={12}>
                  <Col md={12}>
                    <h5>Dimensions: </h5>
                    <p>1024 x 768px</p>
                  </Col>
                  <Col md={12}>
                    <h5>Format:</h5>
                    <p>JPG, PNG, or MP4</p>
                  </Col>
                  <Col md={12}>
                    <h5>File Size:</h5>
                    <p>Below 10 MB</p>
                  </Col>
                </Col>
              </Row>
            </Col>
            <Col xs={9} className="upload-block">
              <Modal.Header closeButton />
              <h5 className="title">Upload Media</h5>
              <div className="form-group">
                <div className="custom-file">
                  <input
                    onChange={uploadMedia}
                    type="file"
                    multiple
                    accept={
                      allowVideo
                        ? "image/jpeg, image/png, image/webp, video/mp4"
                        : "image/jpeg, image/png, image/webp"
                    }
                    className="custom-file-input form-control-sm"
                    id="mediaUpload"
                    disabled={selectedMedia.length >= maxPictures}
                  />
                  <label
                    className="custom-file-label col-form-label-sm"
                    htmlFor="mediaUpload"
                  >
                    <div className="text-center">
                      <div className="upload-section">
                        <div className="upload-img">
                          <img src={uploadImageFile} alt="loading..." />
                        </div>
                        <div className="upload-text">
                          <h5>
                            <span>Click to upload</span> or drag and drop
                          </h5>
                          <h6>Maximum file size 10 MB.</h6>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <Row className="row-img">
                <div className="mb-2 text-center">
                  {selectedMedia.length &&
                  selectedMedia.length > maxPictures ? (
                    <p className="text-danger">
                      Maximum {maxPictures} files can be uploaded
                    </p>
                  ) : (
                    ""
                  )}
                </div>
                {selectedMedia.length > maxPictures ? (
                  <div className="col-12 col-img-error">
                    <p>Maximum {maxPictures} files</p>
                  </div>
                ) : (
                  ""
                )}
                {selectedMedia &&
                  selectedMedia.map((media, index) => (
                    <div className="col-md-4 col-img-list" key={index}>
                      <div className="img-list">
                        <button
                          className="btn"
                          onClick={() => removeMedia(index)}
                        >
                          <i className="fal fa-times"></i>
                        </button>
                        {media.type === "image" ? (
                          <img
                            src={media.base64}
                            className="img-fluid"
                            alt=""
                          />
                        ) : (
                          <video
                            src={media.videoSrc}
                            height="90%"
                            width="100%"
                          />
                        )}
                      </div>
                    </div>
                  ))}
              </Row>
              <Modal.Footer>
                <Button variant="secondary" onClick={closeFileUpload}>
                  Close
                </Button>
                {selectedMedia.length && selectedMedia.length <= maxPictures ? (
                  <Button variant="primary" onClick={MediaSubmit}>
                    Confirm
                    <i
                      className="fal fa-angle-right ml-2"
                      aria-hidden="true"
                    ></i>
                  </Button>
                ) : (
                  <Button variant="primary" disabled onClick={MediaSubmit}>
                    Confirm
                    <i
                      className="fal fa-angle-right ml-2"
                      aria-hidden="true"
                    ></i>
                  </Button>
                )}
              </Modal.Footer>
            </Col>
          </Row>
          <CropModal
            src={src}
            setCroppedImageUrl={setCroppedImageUrlCallback}
            openCropModal={openCropModal}
            setCropModal={setCropModal}
            blob={blob}
            aspect={0}
          />
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default CustomFileUploadModal;
