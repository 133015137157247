import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginAPI } from "../../../api/auth/sup/login";
import "../../../assets/css/main-admin.css";
import "../../../assets/css/main-admin.css";
import "../../../assets/css/bootstrap.min.css";
import LoginForm from "./LoginForm";
import isSUPAuthenticated from "../../../utils/isSUPAuthenticated";
import isSAPAuthenticated from "../../../utils/isSAPAuthenticated";
import Logo from "./assets/img/motorque-logo.svg";

const LoginSup = () => {
  const [error, setError] = useState("");

  let navigate = useNavigate();
  const bg = require("../../../assets/img/main-admin-img/logo/sample.png");

  const handleClick = () => {
    if (isSUPAuthenticated()) {
      navigate("/sup/user");
    }
  };

  const loginAction = async (details) => {
    loginAPI(details)
      .then((res) => {
        handleClick();
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  return (
    <div className="Login">
      <section className="">
        <div className="login-admin-block">
          <div className="login-admin-subblock">
            <div className="bg-blur"></div>
            <div className="login-main">
              <div className="login-details">
                <div className="sign-in-form w-100 m-auto">
                  <div className="text-center admin-logo">
                    <img src={Logo} alt="" className="img-fluid" />
                  </div>
                  <h3 className="mb-4 text-center">SUP Sign in</h3>
                  <LoginForm Login={loginAction} error={error} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LoginSup;
