import { supAxios } from "../../axios";

export const loginAPI = async (data) => {
    const axiosInst = supAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: 'login',
            data
        });
        if (res.status) {
            localStorage.setItem('supAuthToken', res.data.token);
            localStorage.setItem('isSUPAuthenticated', true);
        }

        if(res.status) return res;
        return Promise.reject(res);
    } catch (err) {
        return Promise.reject(err);
    }
}
