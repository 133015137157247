import React, { useContext, useState, Fragment, useEffect } from "react";
import { ProfileContext } from "../ProfileContext";
import { Scrollbars } from "react-custom-scrollbars-2";
import uploadImageFile from "../../../../../assets/img/icon/upload/photos.svg";
import LogoUploadModal from "./LogoUploadModal";
import configData from "../../../../../config.json";
import { toast } from "react-toastify";
import {
  getProfileInfo,
  updateProfileInfo,
} from "../../../../../api/services/sap/profile-management";
import { InfoValidation } from "../Ui/InfoValidation";
import styled from "styled-components";
import Select from "react-select";
import { useCountryRegion } from "../../../../hooks/useCountryRegion";
import { getServiceConfiguration } from "../../../../../api/services/sap/bookings-management";

const InfoTab = () => {
  const {
    innertab,
    userProfile,
    setUserProfile,
    sapLogo,
    addImageSubmit,
    handleShow,
    infoviewonly,
    setInfoViewOnly,
    countryList,
    currencyList,
    profileConfigData,
    setProfileConfigData,
    setSapLogo,
    showImage,
    setShowImage,
    previousUserProfileData,
    setPreviousUserProfileData,
    infoTabErrors,
    setInfoTabErrors,
  } = useContext(ProfileContext);

  //Validation State
  const [formErrors, SetFormErrors] = useState({});
  const [error, setError] = useState(false);
  const countryRegion =
    useCountryRegion()?.map((country) => ({
      value: country.country_id_pk,
      label: country.country_name,
    })) ?? [];
  //ON change Validate
  const onChangeValidate = (field, value) => {
    const errors = InfoValidation(userProfile, value, true, field);
    if (Object.keys(errors).length !== 0) {
      setInfoTabErrors(true);
      if (Object.keys(formErrors).length !== 0) {
        const updatedErrorObject = { ...formErrors, ...errors };
        SetFormErrors(updatedErrorObject);
      } else {
        SetFormErrors(errors);
      }
    } else {
      if (Object.keys(formErrors).length !== 0) {
        const updatedErrorObject = { ...formErrors };
        SetFormErrors(updatedErrorObject);
      } else {
        setInfoTabErrors(false);
      }
    }
  };

  const [formsubmit, setFormSubmit] = useState(false);

  const fetchVatOrGstData = async (data) => {
    try {
      let data = {
        table_reference: ["tax_types", "payment_methods", "bank_number_types"],
      };
      const response = await getServiceConfiguration(data);
      setProfileConfigData(response);
    } catch (error) {
      console.error("Error fetching :", error);
    }
  };

  //preFormSubmitHandler
  const infoFormSubmitHandler = (e) => {
    e.preventDefault();
    setFormSubmit(true);
    let errors = InfoValidation(userProfile);
    if (Object.keys(errors).length !== 0) {
      setInfoTabErrors(true);
      SetFormErrors(errors);
    } else {
      setInfoViewOnly(true);
      setUserProfile({
        ...userProfile,
        logo_image: sapLogo ? sapLogo : null,
        logo: showImage ? showImage : null,
      });
      const updatedSapData = updateProfileInfo({
        ...userProfile,
        logo_image: sapLogo[0] ? sapLogo[0] : null,
        logo: showImage ? showImage : null,
      });
      toast.success("Details Updated Successfully", {
        position: "top-right",
      });
      updatedSapData &&
        updatedSapData.then((res) => {
          const id = localStorage.getItem("userid");
          const data = getProfileInfo(id);
          data.then((response) => {
            setUserProfile(response);
            setPreviousUserProfileData(response);
            localStorage.setItem("userName", response?.service_center_name);
            localStorage.setItem(
              "serviceCenterName",
              response?.service_center_name
            );
            fetchVatOrGstData();
          });
        });
    }
  };
  const handleCancelClick = () => {
    setInfoViewOnly(true);
    setUserProfile(previousUserProfileData);
  };
  const handleImgRemove = (image, i) => {
    setSapLogo([]);
  };
  const handleRemoveDefaultImage = (image, i) => {
    setShowImage("");
    setSapLogo([]);
  };
  const handleShowImageModal = () => {
    if (!infoviewonly) {
      handleShow();
    }
  };
  useEffect(() => {
    setShowImage(userProfile?.logo);
  }, [userProfile]);

  useEffect(() => {
    setUserProfile(previousUserProfileData);
  }, [innertab]);

  return (
    <div
      className={innertab === 1 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
        <div className="main-tab-block">
          <div className="inner-block tab-form-block">
            <Fragment>
              <form className="" onSubmit={infoFormSubmitHandler}>
                {/* <h5 className=" mb-3  text-info">Update Service center Details</h5> */}
                <div className="form-box">
                  <ImageWrapper>
                    <div className="input-group input-group-custom">
                      <div className="small-block">
                        {showImage ? (
                          <div className="row-img row">
                            <div className="col-img-list">
                              <div className="img-list">
                                {!infoviewonly && (
                                  <button
                                    className="btn"
                                    onClick={() => handleRemoveDefaultImage()}
                                  >
                                    <i class="fal fa-times"></i>
                                  </button>
                                )}
                                <img
                                  src={
                                    showImage &&
                                    `${configData.SERVER_URL}/uploads/service_admin/${showImage}`
                                  }
                                  className="img-fluid"
                                  alt=""
                                  height="100"
                                  width="100"
                                />{" "}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="row-img row">
                            {sapLogo &&
                              sapLogo.map((photo, index) => {
                                return (
                                  <div className="col-img-list">
                                    <div className="img-list" key={index}>
                                      {!infoviewonly && (
                                        <button
                                          className="btn"
                                          onClick={(e, index) =>
                                            handleImgRemove()
                                          }
                                        >
                                          <i class="fal fa-times"></i>
                                        </button>
                                      )}
                                      <img
                                        src={photo.base64}
                                        className="img-fluid"
                                        alt=""
                                        height="100"
                                        width="100"
                                      />{" "}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}

                        {sapLogo && !sapLogo.length && !showImage ? (
                          <button
                            type="button"
                            className="btn-image"
                            onClick={handleShowImageModal}
                          >
                            <div className="upload-section justify-content-center">
                              <div className="upload-img">
                                <img src={uploadImageFile} alt="loading..." />
                              </div>
                            </div>
                            {!infoviewonly ? "Upload" : ""}
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </ImageWrapper>
                  {/* Service Center Name */}
                  <Wrapper className={`${infoviewonly ? "view" : ""}`}>
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="fal fa-warehouse"></i>
                          <Wrappername>
                            <div className="service-model">
                              Service Center Name{" "}
                            </div>
                            <span className="smalltext-edit-custom">
                              {" "}
                              [as per license]
                            </span>
                          </Wrappername>
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <div className="input-block">
                        <input
                          readOnly={infoviewonly ? true : false}
                          type="text"
                          className="form-control"
                          placeholder="Service center name..."
                          maxLength={100}
                          onChange={(e) => {
                            setUserProfile({
                              ...userProfile,
                              service_center_name: e.target.value,
                            });
                            onChangeValidate(
                              "service_center_name",
                              e.target.value
                            );
                          }}
                          value={
                            userProfile && userProfile?.service_center_name
                              ? userProfile?.service_center_name
                              : ""
                          }
                          onBlur={(e) => {
                            if (!infoviewonly) {
                              onChangeValidate(
                                "service_center_name",
                                e.target.value
                              );
                            }
                          }}
                        />
                        {infoTabErrors && formErrors?.service_center_name ? (
                          <span className="form-error">
                            {formErrors?.service_center_name}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* Phone Number */}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i className="fal fa-phone"></i>Phone Number
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <div className="input-block">
                        <input
                          type="text"
                          readOnly={infoviewonly ? true : false}
                          className="form-control"
                          placeholder="Phone Number..."
                          maxLength={10}
                          onChange={(e) => {
                            setUserProfile({
                              ...userProfile,
                              phone: e.target.value.replace(/[^0-9]/g, ""),
                            });
                            onChangeValidate("phone", e.target.value);
                          }}
                          value={
                            userProfile && userProfile.phone
                              ? userProfile.phone
                              : ""
                          }
                          onBlur={(e) => {
                            if (!infoviewonly) {
                              onChangeValidate("phone", e.target.value);
                            }
                          }}
                        />
                        {infoTabErrors && formErrors.phone ? (
                          <span className="form-error">{formErrors.phone}</span>
                        ) : (
                          ""
                        )}
                        {/* <span className="form-error">Phone Number is required!</span> */}
                      </div>
                    </div>
                    {/* Service Center Mail Id */}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="fal fa-envelope"></i>Email
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <div className="input-block">
                        <input
                          readOnly={infoviewonly ? true : false}
                          type="text"
                          className="form-control"
                          placeholder="Email id..."
                          maxLength={100}
                          onChange={(e) => {
                            setUserProfile({
                              ...userProfile,
                              email: e.target.value,
                            });
                            onChangeValidate("email", e.target.value);
                          }}
                          value={
                            userProfile && userProfile.email
                              ? userProfile.email
                              : ""
                          }
                          onBlur={(e) => {
                            if (!infoviewonly) {
                              onChangeValidate("email", e.target.value);
                            }
                          }}
                        />
                        {infoTabErrors && formErrors.email ? (
                          <span className="form-error">{formErrors.email}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* Service Center Owner Name */}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="fal fa-user-shield"></i>
                          <OwnerWrapper>
                            <div>Owner Name</div>
                            <span className="smalltext-edit-custom">
                              {" "}
                              [as per license]
                            </span>
                          </OwnerWrapper>
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <div className="input-block">
                        <input
                          readOnly={infoviewonly ? true : false}
                          type="text"
                          className="form-control"
                          placeholder="Owner name..."
                          maxLength={100}
                          onChange={(e) => {
                            setUserProfile({
                              ...userProfile,
                              owner_name: e.target.value,
                            });
                            onChangeValidate("owner_name", e.target.value);
                          }}
                          value={
                            userProfile && userProfile.owner_name
                              ? userProfile.owner_name
                              : ""
                          }
                          onBlur={(e) => {
                            if (!infoviewonly) {
                              onChangeValidate("owner_name", e.target.value);
                            }
                          }}
                        />
                        {infoTabErrors && formErrors.owner_name ? (
                          <span className="form-error">
                            {formErrors.owner_name}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* Address */}
                    <div class="input-group input-group-custom">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="fal fa-home"></i>Address
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <div className="input-block">
                        <textarea
                          readOnly={infoviewonly ? true : false}
                          class="form-control"
                          placeholder="Address... "
                          onChange={(e) => {
                            setUserProfile({
                              ...userProfile,
                              address: e.target.value,
                            });
                            onChangeValidate("address", e.target.value);
                          }}
                          onBlur={(e) => {
                            if (!infoviewonly) {
                              onChangeValidate("address", e.target.value);
                            }
                          }}
                          value={
                            userProfile && userProfile.address
                              ? userProfile.address
                              : ""
                          }
                        ></textarea>
                        {infoTabErrors && formErrors.address ? (
                          <span className="form-error">
                            {formErrors.address}
                          </span>
                        ) : (
                          ""
                        )}
                        {/* <span className="form-error">Address is required!</span> */}
                      </div>
                    </div>
                    {/* Select Country */}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="fal fa-globe"></i>
                          Select Country
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <DropdownWrapper className="input-block">
                        <Select
                          className={`country ${
                            infoviewonly ? "disable-select" : ""
                          }`}
                          isDisabled={infoviewonly}
                          options={countryRegion}
                          onChange={(selectedOption) => {
                            setUserProfile({
                              ...userProfile,
                              country_id_fk: selectedOption?.value,
                            });
                            onChangeValidate(
                              "country_id_fk",
                              selectedOption?.value
                            );
                          }}
                          value={
                            countryRegion &&
                            countryRegion.length &&
                            countryRegion.find(
                              (option) =>
                                option.value === userProfile?.country_id_fk
                            )
                          }
                        />
                        {infoTabErrors && formErrors.country_id_fk ? (
                          <span className="form-error">
                            {formErrors?.country_id_fk}
                          </span>
                        ) : (
                          ""
                        )}
                      </DropdownWrapper>
                    </div>
                    {/* Select Currency */}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="far fa-money-bill-alt"></i>
                          Select Currency
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <DropdownWrapper className="input-block">
                        <Select
                          className={`currency ${
                            infoviewonly ? "disable-select" : ""
                          }`}
                          isDisabled={infoviewonly}
                          options={currencyList}
                          onChange={(selectedOption) => {
                            setUserProfile({
                              ...userProfile,
                              amount_in_currency: selectedOption.value,
                            });
                            onChangeValidate(
                              "amount_in_currency",
                              selectedOption.value
                            );
                          }}
                          value={currencyList.find(
                            (option) =>
                              option.value === userProfile?.amount_in_currency
                          )}
                        />

                        {infoTabErrors && formErrors.amount_in_currency ? (
                          <span className="form-error">
                            {formErrors.amount_in_currency}
                          </span>
                        ) : (
                          ""
                        )}
                      </DropdownWrapper>
                    </div>
                    {/* VAT % */}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="far fa-percentage"></i>
                          <div>{profileConfigData?.tax_type?.title} % </div>
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <div className="input-block">
                        <input
                          readOnly={infoviewonly ? true : false}
                          type="text"
                          className="form-control"
                          placeholder="xxx.xx"
                          maxLength={100}
                          onChange={(e) => {
                            setUserProfile({
                              ...userProfile,
                              tax_percentage: e.target.value,
                            });
                            onChangeValidate("VAT", e.target.value);
                          }}
                          value={
                            userProfile && userProfile.tax_percentage
                              ? userProfile.tax_percentage
                              : ""
                          }
                          onBlur={(e) => {
                            if (!infoviewonly) {
                              onChangeValidate("VAT", e.target.value);
                            }
                          }}
                        />
                        {infoTabErrors && formErrors.tax_percentage ? (
                          <span className="form-error">
                            {formErrors.tax_percentage}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    {/* Google location */}
                    <div className="input-group input-group-custom">
                      <div className="input-group-prepend">
                        <span className="input-group-text">
                          <i class="fal fa-map-marker-alt"></i>Google Location
                          <sup className="theme-text">*</sup>
                        </span>
                      </div>
                      <div className="input-block">
                        <input
                          readOnly={infoviewonly ? true : false}
                          type="text"
                          className="form-control"
                          placeholder="Paste google location url here..."
                          onChange={(e) => {
                            onChangeValidate("googleMap", e.target.value);
                            setUserProfile({
                              ...userProfile,
                              google_location_url: e.target.value,
                            });
                          }}
                          value={
                            userProfile && userProfile.google_location_url
                              ? userProfile.google_location_url
                              : ""
                          }
                          onBlur={(e) => {
                            if (!infoviewonly) {
                              onChangeValidate(
                                "google_location_url",
                                e.target.value
                              );
                            }
                          }}
                        />
                        {infoTabErrors && formErrors.google_location_url ? (
                          <span className="form-error">
                            {formErrors.google_location_url}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </Wrapper>
                </div>

                {!infoviewonly && (
                  <div class="col-12 text-end p-0 mt-3">
                    <button
                      class="btn btn-update"
                      onClick={handleCancelClick}
                      style={{ border: "1px solid #e8e8e8" }}
                    >
                      <i class="fal mr-2"></i>Cancel
                    </button>
                    <button
                      class="btn btn-update"
                      type="submit"
                      style={{
                        marginLeft: "10px",
                        border: "1px solid #e8e8e8",
                      }}
                    >
                      <i class="fal fa-save mr-2"></i>Save
                    </button>
                  </div>
                )}
              </form>
            </Fragment>
          </div>
          <LogoUploadModal />
        </div>
      </Scrollbars>
    </div>
  );
};

export default InfoTab;
const Wrapper = styled.div`
  .input-group-prepend {
    padding: 0 15px 0 0;
    min-width: 200px;
    max-width: 200px;
  }
  .input-block {
    width: calc(100% - 200px);
    text-align: left;
    .css-1xc3v61-indicatorContainer {
      color: #666666 !important;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #e8e8e8 inset !important;
  }
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #e8e8e8 inset !important;
  }
  .disable-select {
    .css-1xc3v61-indicatorContainer {
      display: none;
    }
  }
  &.view {
    .input-block {
      input {
        background-color: #f5f5f5 !important;
      }
      textarea {
        background-color: #f5f5f5 !important;
      }
      .country > div {
        background-color: #f5f5f5 !important;
      }
      .currency > div {
        background-color: #f5f5f5 !important;
      }
    }
  }
`;
const Wrappername = styled.div`
  .smalltext-edit-custom {
    color: grey;
    font-size: small;
  }
`;
const OwnerWrapper = styled.div`
  .smalltext-edit-custom {
    color: grey;
    font-size: small;
  }
`;
const DropdownWrapper = styled.div`
  .country > div {
    background: #e8e8e8;
    font-size: 14px;
  }

  .country {
    .css-olqui2-singleValue {
      color: black;
    }
  }

  .currency > div {
    background: #e8e8e8;
    font-size: 14px;
  }
  .currency {
    .css-olqui2-singleValue {
      color: black;
    }
  }
`;
const ImageWrapper = styled.div`
  .small-block .btn-image {
    margin: 10px;
    width: 150px;
  }
`;
