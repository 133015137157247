export const Validation = (values, field_value, isOnChange, field) => {
  

  const errors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const pattern = new RegExp(/^[0-9\b\+\-\(\)]+$/);

  //Validation For OnBlur
  if (isOnChange) {
    //Staff name
    if (field === "name" && isOnChange) {
      if (!field_value) errors.name = "Staff Name is required.";
    }
    else if (field === "phone" && isOnChange) {
      if (!field_value) {
        errors.phone = "Phone number is required";
      } else if (field_value.length > 10) {
        errors.phone = "10 characters req for phone number";
      } else if (!pattern.test(field_value)) {
        errors.phone = "Only Numbers can be entered  as  phone number.";
      }
    }

    //Staff Emergency contact
    else if (field === "emergency_details_phone" && isOnChange) {
      if (!field_value) {
        errors.emergency_details_phone = "Phone Number is required.";
      } else if (!pattern.test(field_value)) {
        errors.emergency_details_phone =
          "Only Numbers can be entered  as  phone number.";
      } else if (field_value.length > 10) {
        errors.emergency_details_phone = "Please enter a valid phone number.";
      }
    }
    //Staff Date of Birth
    else if (field === "date_of_birth" && isOnChange) {
      if (field_value === new Date())
        errors.date_of_birth = "Choose a valid Date of Birth is required";
    }
    //Staff blood_group_id
    else if (field === "blood_group_id" && isOnChange) {
      if (!field_value) errors.blood_group_id = "Blood group is required";
    }

    //Staff address
    else if (field === "address" && isOnChange) {
      if (!field_value) errors.address = "Address is required";
    }

    //Staff id
    else if (field === "id" && isOnChange) {
      if (!field_value) errors.id = "id is required";
    }
  //expiry id
    // else if (field === "expiry_date" && isOnChange) {
    //   if (!field_value) errors.expiry_date = "expiry date is required";
    // }

    //Staff emergency_details_relation_type
    else if (field === "emergency_details_relation_type" && isOnChange) {
      if (!field_value)
        errors.emergency_details_relation_type = "Relation Field is required";
    }
    //Staff Id ProofType
    else if (field === "staff_id_proof_type" && isOnChange) {
      if (!field_value)
        errors.staff_id_proof_type = "Id proof type is required";
    }

    //Staff Emergency contact Name
    else if (field === "emergency_details_name" && isOnChange) {
      if (!field_value)
        errors.emergency_details_name = "Emergency contact Name is required";
    }
    //Staff Emergency contact Relation
    else if (field === "emergency_details_relation_type" && isOnChange) {
      if (!field_value)
        errors.emergency_details_relation_type =
          "Emergency contact Relation is required";
    }
  }

  //Validation for OnSubmit
  else {
    // Name
    if (!values.name) errors.name = "Name is required.";
    
    if (values.email && !emailRegex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    //Staff Phone Number
    if (!values.phone) {
      errors.phone = "Phone Number is required";
    } else if (!pattern.test(values.phone)) {
      errors.phone = "Please check your phone number.";
    }

    //Staff Date of birth
    if (values.date_of_birth) {
      if (values.date_of_birth === new Date()) {
        errors.date_of_birth = "Choose a valid Date of Birth is required";
      }
    }

    //Blood group
    if (!values.blood_group_id)
      errors.blood_group_id = "Blood Group is required";

    //expiry id
    if (!values.expiry_date) errors.expiry_date = "Expiry date is required";
    

    //Id Proof Type
    if (!values.staff_id_proof_type)
      errors.staff_id_proof_type = "Id proof type is required";
    //Id Proof Type
    if (!values.staff_id_proof?.length)
      errors.staff_id_proof = "Id proof file is required";
    //Address
    if (!values.address)
      errors.address = "Address is required";
    //Emergency detail name
    if (!values.emergency_details_name)
      errors.emergency_details_name = "Emergency contact Name is required";

    // Staff Emergency contact Number
    if (!values.emergency_details_phone) {
      errors.emergency_details_phone = "Phone is required";
    } else if (!pattern.test(values.emergency_details_phone)) {
      errors.emergency_details_phone =
        "Only Numbers can be entered  as  phone number.";
    }
    //Emergency detail name
    if (!values.emergency_details_relation_type)
      errors.emergency_details_relation_type =
        "Emergency contact Relation is required";
  }
  return errors;
};
