import React from "react";
import styled from "styled-components";
import { Offer } from "../index";

type OfferListCardProps = {
  offers?: Offer[];
  onViewClick?: (event: React.MouseEvent<HTMLDivElement>, offer: Offer) => void;
  className?: string;
};

const OfferListCard: React.FC<OfferListCardProps> = ({
  offers = [],
  onViewClick,
  className,
}) => {
  return (
    <CardContainer className={className}>
      {offers.map((offer, index) => (
        <Card
          className="offer-card"
          key={index}
          onClick={(e) => onViewClick?.(e, offer)}
        >
          <Image src={offer.image} alt={offer.name} />
          <Content>
            <CardTitle>{offer.name}</CardTitle>
            <Description>{offer.description}</Description>
            {offer.discountType.discountTypeId === 1 ? (
              <Amount> ₹ {offer.discount} Off</Amount>
            ) : (
              <Amount>{offer.discount}% Off</Amount>
            )}
            {/* <OfferTypeTags>
              {offer.tags.map((tag, idx) => (
                <Tag key={idx}>{tag}</Tag>
              ))}
            </OfferTypeTags> */}
            <Terms>*Terms & Conditions apply</Terms>
            <Discount>{offer.catchPhrase}</Discount> use code as{" "}
            <Code>{offer.code}</Code>
          </Content>
        </Card>
      ))}
    </CardContainer>
  );
};

export default OfferListCard;

const CardContainer = styled.div`
  display: grid;
  gap: 25px;
  padding: 10px 30px 20px 30px;
  place-items: center;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 575.98px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1366px) and (max-width: 1439px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Card = styled.div`
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  width: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  overflow: hidden;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }
`;

const Image = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const Content = styled.div`
  padding: 15px;
  color: #000;
`;

const CardTitle = styled.h3`
  margin: 0;
  font-size: 20px;
  color: #000;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
`;

const OfferTypeTags = styled.div`
  margin: 10px 0;
`;

const Tag = styled.span`
  display: inline-block;
  background-color: #333;
  color: #e0e0e0;
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 3px;
  margin: 2px;
`;

const Amount = styled.div`
  font-size: 18px;
  color: #fff;
  background-color: #dc3545;
  width: fit-content;
  margin: 6px auto;
  padding: 4px 10px;
  font-weight: bolder;
  border-radius: 6px;
`;

const Terms = styled.div`
  font-size: 12px;
  color: #858585;
`;

const Description = styled.div`
  font-size: 13px;
  /* color: #cccccc; */
  padding-top: 10px;
  font-style: italic;
`;

const DetailsList = styled.ul`
  margin: 10px 0;
  padding-left: 20px;
  list-style: disc;
  font-size: 14px;
`;

const DetailItem = styled.li`
  margin-bottom: 5px;
`;

const Discount = styled.div`
  font-size: 14px;
  margin-top: 15px;
`;

const Code = styled.span`
  color: #ff8c00;
  font-weight: bold;
`;
