import React, { useState, useContext } from "react";
import { OrdersContext } from "../OrdersContext";
import ReactTooltip from "react-tooltip-rc";
const OrderContentTitle = (props) => {
  return (
    <div className="content-title-action">
      <div className="title-action">
        <div className="title-action-left">
          <h5 className="head">Orders</h5>
          {/* <div className="action-block">
            <button
              className="btn add-new"
              id="addBtn"
              onClick={props.handleAddOrders}
            >
              <span>
                <i className="fal fa-plus"></i> Add New
              </span>
            </button>
            
          </div> */}
        </div>
        <div class="title-action-right d-flex">
          <button
            className="btn "
            id="addBtn"
            rel="tooltip"
            data-toggle="tooltip"
            data-placement="left"
            title="Add New"
          >
            <span>
              <i className="fal fa-list mr-1"></i>ALL ORDERS
            </span>
          </button>

        </div>
      </div>
    </div>
  );
};

export default OrderContentTitle;
