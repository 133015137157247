import React from "react";

type Props = {};

const ProductFilterDropdownContent = (props: Props) => {
  return (
    <>
      <a className="dropdown-item" href="">
        Option1
      </a>
      <a className="dropdown-item" href="">
        Option2
      </a>
      <a className="dropdown-item" href="">
        Option3
      </a>
    </>
  );
};

export default ProductFilterDropdownContent;
