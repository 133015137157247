import SuperUserForm from "../User/SuperUserForm";
import React, { useContext, useState } from "react";
import { SuperContext } from "./SuperContext";
import { Scrollbars } from "react-custom-scrollbars-2";
import ConfirmModal from "../User/Ui/ConfirmModal";
import DeleteModal from "../User/Ui/DeleteModal";
import ReactTooltip from "react-tooltip-rc";
import { useEffect } from "react";

const WindowRight = (props) => {
  const [confirmModal, SetConfirmModal] = useState(false);
  const [deletemodal, SetDeleteModal] = useState(false);
  const [isDeleted, SetIsDeleted] = useState(false);
  const {
    formChange,
    viewReadOnly,
    SetViewReadOnly,
    SetEditMode,
    editMode,
    SetMappedServiceData,
    mappedServiceData,
    isUpdateForm,
  } = useContext(SuperContext);
  // let changeTitle = "My Services";

  const handleCloseView = (e) => {
    if (formChange === false) {
      props.serviceformClose(false);
      SetViewReadOnly(false);
      SetEditMode(false);
      SetMappedServiceData({});
    } else if (formChange === true) {
      SetConfirmModal(true);
      e.stopPropagation();
    }
  };

  const handleDelete = (e) => {
    SetDeleteModal(true);
    e.stopPropagation();
  };

  const handleEditView = (e) => {
    SetViewReadOnly(false);
    SetEditMode(true);
  };

  useEffect(() => {
    if (isDeleted) handleCloseView();
  }, [isDeleted]);

  return (
    <div className={`content-window-right  ${props.text} ${props.class}`}>
      {/* <div className={"content-window-right" + props.text}> */}
      <div class="content-right-block">
        <div class="card-right">
          <div class="header-block">
            <div class="header-sec">
              <div className="content-right-top">
                <button
                  onClick={handleCloseView}
                  class="btn btn-action btn-back"
                  title="Close Window"
                  data-for="close"
                  data-tip="Back to Service Centers"
                >
                  <i class="fal fa-long-arrow-left"></i>
                </button>
                <ReactTooltip
                  id="close"
                  place="bottom"
                  type="info"
                  effect="float"
                />
                <h4>
                  {editMode
                    ? "Edit " + mappedServiceData.name
                    : viewReadOnly
                    ? mappedServiceData.name
                    : "Add Service Center"}
                </h4>
              </div>
              <div class="action-block-right">
                {viewReadOnly && (
                  <button
                    onClick={handleDelete}
                    class="btn btn-action btn-delete"
                    data-for="delete"
                    data-tip="Delete Service Center"
                    title="Delete"
                  >
                    <i class="fal fa-trash"></i>
                  </button>
                )}
                <ReactTooltip
                  id="delete"
                  place="bottom"
                  type="info"
                  effect="float"
                />
                {viewReadOnly && (
                  <button
                    onClick={handleEditView}
                    class="btn btn-action btn-edit"
                    data-for="edit"
                    data-tip="Edit Service Center"
                    title="Edit"
                  >
                    <i class="fal fa-pen"></i>
                  </button>
                )}
                <ReactTooltip
                  id="edit"
                  place="bottom"
                  type="info"
                  effect="float"
                />
              </div>
            </div>
          </div>
        </div>
        <Scrollbars style={{ height: `calc(100vh - 141px)` }}>
          <div class="row m-0 row-right">
            {/* <div class="name-block">
              <div class="sub-block">
                <div class="block-name">
                 {mappedServiceData.name && <h2>{mappedServiceData.name}</h2>}
                </div>
              </div>
            </div> */}
            {/* <!-- add service form --> */}
            <SuperUserForm
              addservice={props.addservice}
              serviceformClose={props.serviceformClose}
            />
            {/* <!-- add service form end--> */}
          </div>
        </Scrollbars>
        {confirmModal && (
          <ConfirmModal
            setConfirmModal={SetConfirmModal}
            id={mappedServiceData.id}
            serviceFormClose={props.serviceformClose}
          />
        )}
        {deletemodal && (
          <DeleteModal
            SetDeleteModal={SetDeleteModal}
            SetIsDeleted={SetIsDeleted}
            id={mappedServiceData.id}
          />
        )}
      </div>
    </div>
  );
};

export default WindowRight;
