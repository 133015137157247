import React from "react";
import img from "../../assets/img/login/bg/1.jpg";
import styled from "styled-components";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

type Props = {
  src?: string;
  onRemoveClick?: (e: number) => void;
  index?: any;
};

const ImageUrl = ({ src, onRemoveClick, index }: Props) => {
  return (
    <ImageWApper>
      <div className="col-img-list">
        <div className="img-list">
          <button
            type="button"
            className="btn"
            onClick={() => onRemoveClick?.(index)}
          >
            <CloseRoundedIcon />
          </button>
          <img src={src} className="img-fluid" alt="" />
        </div>
      </div>
    </ImageWApper>
  );
};

export default ImageUrl;
const ImageWApper = styled.div`
  .col-img-list {
    border: 2px dashed #72baff !important;
    border-radius: 17px !important;
    margin: 0 !important;
    height: 150px !important;
    width: 150px !important;
    margin-top: 20px;
    justify-content: center !important;
    display: flex !important;
    position: relative !important;
  }

  .img-list {
    display: flex !important;
    border-radius: px !important;
    overflow: hidden !important;
    align-items: center !important;
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        font-size: 15px !important;
      }
    }
  }
  .img-list button {
    position: absolute;
    right: -10px;
    top: -13px;
    font-size: 20px !important;
    color: #0083ff !important;
    font-weight: lighter;
    border: none;
    width: 25px;
    height: 25px;
    background: #e5f0ff !important ;
    border-radius: 20px !important;
  }
  .img-fluid {
    /* width: 100px !important;
    height: 100px !important;
    border-radius: 2px !important; */
    width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;
    padding: 2px;
    border-radius: 20px;
  }
  .i4bv87.MuiSvgIcon-root {
    font-size: 1 rem !important;
  }
`;
