import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import isSAPAuthenticated from "../../../utils/isSAPAuthenticated";

const AuthGuardSAP = () => {
    return (
        isSAPAuthenticated() ? <Outlet /> : <Navigate to="/" />
    );
}

export default AuthGuardSAP;