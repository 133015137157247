import React from "react";

export const Validation = (
  values, field_value, isOnChange, field
) => {
  const errors = {};


  console.log(field_value, "--------------field_value--------------");
  //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  // const phoneRegex = /[0-9]|\./;

  if (isOnChange) {
    if (field === "customer_name")
     {
      if (!field_value)
        errors.customer_name = "customer_name is required";
    }
    else if (field === "phone_number") {
      if (!values.phone_number)
        errors.phone_number = "phone_number is required";
    } 
    // else if (field === "servicetags") {
    //   if (!Object.keys(servicetags).length) {
    //     errors.servicetags = "Click Enter or Tab";
    //   }
    // } 
    // else if (field === "isPrice") {
    //   if (isPrice.length === 0) errors.isPrice = "isPrice is required";
    // }
  } else {
    // Customer Name
    if (!values.service_name) errors.service_name = "Service Name is required";
    //Vehichle Select
    if (!values.vehicle_select)
      errors.vehicle_select = "Select Vehichle type is required";
  }
  return errors;
};
