import React from "react";
import ProductSortDropdown from "../Ui/ProductSortDropdown";
import ProductFilterDropdown from "../Ui/ProductFilterDropdown";
import { GridAndListButton } from "../../../../GridAndListButton";

type Props = {
  gridViewClick?: () => void;
  showGrid: boolean;
};

const ProductFilterWrapper = ({ gridViewClick, showGrid }: Props) => {
  return (
    <div className="filter-wrapper">
      <div className="search-sort-filter">
        <div className="header-action-block">
          <form action="" method="">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text search-span-txt">
                  <i className="fal fa-search"></i>
                </span>
              </div>
              <input
                type="text"
                className="form-control"
                id="search-id"
                placeholder="Search"
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
          </form>
          <div className="sort-filter">
            <ProductSortDropdown />

            <ProductFilterDropdown />
            <GridAndListButton
             onClick={gridViewClick} 
             showGrid={showGrid} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFilterWrapper;
