import React from "react";
export const InfoValidation = (values, field_value, isOnChange, field) => {
  const errors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const pattern = new RegExp(/^[0-9\b\+\-\(\)]+$/);
  let regUrl = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\/[^\s]*)?$/;
  //Validation For OnBlur
  if (isOnChange) {
    //Service CenterName name
    if (field === "service_center_name" && isOnChange) {
      if (!field_value){
        errors.service_center_name = "Service Center Name is required.";
      }
      if(field_value.length >= 1){
        errors.service_center_name = null;
      }
    }

    //Sap Phone
    else if (field === "phone" && isOnChange) {
      if (!field_value) {
        errors.phone = "Phone number is required";
      }
      if (field_value && field_value.length == 10) {
        errors.phone = "";
      }
      if (field_value && field_value.length > 10) {
        errors.phone = "Please enter a valid  phone number";
      }
    }

    // Sap email
    else if (field === "email" && isOnChange) {
      if (!field_value) {
        errors.email = "Email is required.";
      }else if (emailRegex.test(field_value)) {
        errors.email = "";
      }
      else if (!emailRegex.test(field_value)) {
        errors.email = "This is not a valid email format!";
      }
      
    }

    //Sap Owner name
    else if (field === "owner_name" && isOnChange) {
      if (!field_value) {
        errors.owner_name = "Owner Name is required.";
      }
      if (field_value) {
        errors.owner_name = "";
      }
    }

    //Sap Address
    else if (field === "address" && isOnChange) {
      if (!field_value) {
        errors.address = "Address is required.";
      }
      if (field_value) {
        errors.address = "";
      }
    }
    else if (field === "country_id_fk" && isOnChange) {
      if (!field_value) {
        errors.country_id_fk = "Country is required.";
      }
      if (field_value) {
        errors.country_id_fk = "";
      }
    }
    else if (field === "amount_in_currency" && isOnChange) {
      if (!field_value) {
        errors.amount_in_currency = "Currency is required.";
      }
      if (field_value) {
        errors.amount_in_currency = "";
      }
    }
    //SAP VAT
    else if (field === "VAT" && isOnChange) {
      if (!field_value) {
        errors.tax_percentage = "VAT% is required.";
      }
      if (field_value) {
        errors.tax_percentage = "";
      }
    } 
    //SAP Google Location
    else if (field === "google_location_url" && isOnChange) {
      if (!field_value) {
        errors.google_location_url = "Google Location is required.";
      }else if (regUrl.test(field_value)) {
        errors.google_location_url = "";
      }
      else if (!regUrl.test(field_value)) {
        errors.google_location_url = "This is not a valid url";
      }
    } 
  }

  //Validation for OnSubmit
  else {
    //Service CenterName name
    if (!values.service_center_name) errors.service_center_name = "Service CenterName is required.";

    //Sap Phone
    if (!values.phone) {
      errors.phone = "Phone Number is required";
    }
    if (values.phone.length != 10) {
      errors.phone = "Please check your phone number.";
    }
    if (!pattern.test(values.phone)) {
      errors.phone = "Please check your phone number.";
    }

    // Sap email
    if (!values.email) {
      errors.email = "Email is required.";
    }
    if (!emailRegex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }

    //Sap Owner name
    if (!values.owner_name) errors.owner_name = "Owner Name is required";

    //Sap Address
    if (!values.address) errors.address = "Address is required";

    //Sap VAT
    if (!values.tax_percentage){
      errors.tax_percentage = "VAT% is required";
    }
    if (!/^\d{0,3}(?:\.\d{1,2})?$/.test(values.tax_percentage)) {
      errors.tax_percentage = "maximum value should be less than 1000";
    }
    if (isNaN(values.tax_percentage)) {
      errors.tax_percentage = "Tax percentage should be a number";
    }
    if (!values.country_id_fk){
      errors.country_id_fk = "Country is required";
    }
    if (!values.amount_in_currency){
      errors.amount_in_currency = "Currency is required";
    }
   //Sap Google location
   if (!values.google_location_url) {
    errors.google_location_url = "Google Location is required.";
  }
    if (values.google_location_url && !regUrl.test(values.google_location_url)) {
      errors.google_location_url = "This is not a valid url";
    }
  }
  return errors;
};

export default InfoValidation;
