import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

type ConfirmationModalProps = {
  show?: boolean;
  onHide?: () => void;
  label?: string;
  Btn1Label?: string;
  Btn2Label?: string;
  onClickCancel?: React.MouseEventHandler<HTMLButtonElement>;
  onClickConfirm?: React.MouseEventHandler<HTMLButtonElement>;
  src?: string;
};
function ConfirmationModal({
  show,
  onHide,
  label,
  onClickCancel,
  onClickConfirm,
  Btn1Label,
  Btn2Label,
  src,
}: ConfirmationModalProps) {
  return (
    <Wrapper>
      <Modal className="custom-modal" show={show} onHide={onHide} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-info-img">
            <img src={src} className="icon-img" alt="" />
          </div>
          <DeleteNamewrapper>
            <h5 className="delete-name-wrapper">
              <span
                style={{
                  whiteSpace: "nowrap", // Prevent line breaks
                  width: "450px", // Set width
                  overflow: "hidden", // Hide overflow content
                  textOverflow: "ellipsis", // Display ellipsis for overflow
                  textAlign: "center", // Align text to the left
                  display: "inline-block", // Ensure block-level styling for width to take effect
                  alignItems: "center",
                }}
              >
                {label}
              </span>{" "}
            </h5>
          </DeleteNamewrapper>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center pt-3">
            <button className="btn mr-3 btn-cancel" onClick={onClickCancel}>
              {Btn1Label}
            </button>
            <button className="btn mr-3 btn-danger" onClick={onClickConfirm}>
              {Btn2Label}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
}

export default ConfirmationModal;
const Wrapper = styled.div`
  .sc-nZgfj heZLUo {
    text-align: center;
    padding: 0 30px;
  }
`;
const DeleteNamewrapper = styled.div`
  .delete-name-wrapper {
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    text-align: left !important;
  }
  .names {
    position: relative;
    overflow-wrap: break-word;
    white-space: pre-wrap;
  }
`;
