import React, { useState } from "react";
import styled from "styled-components";
import RoundedWrapperCard from "../../../../../components/RoundedWrapperCard";
import Grid from "@mui/material/Grid";
import { MuiStyledTextField } from "../../../../../components/MuiStyledTextField";
import { FileUploadButton } from "../../../../../components/FileUploadButton";
import PhoneNumberField from "../../../../../components/PhoneNumberField";
import { CardHeadingLabel } from "../../../../../components/label";
import { Button } from "@mui/material";
import AddIcon from "./assets/icons/AddIcon.svg";
import SimpleReactValidator from "simple-react-validator";
import { toast } from "react-toastify";
import mainLoader from "../../../../../assets/img/loader/loader.gif";

export interface Errors {
  password: string;
  confirm_password: string;
}
type Props = {
  email?: string;
  phone?: string | number;
  countryValue?: string;
  onNextClick?: () => void;
};
type payloadDataType = {
  name?: string;
  website?: string;
  official_phone_extension?: string;
  official_phone?: string | undefined;
  official_email?: string;
  owner_name?: string;
  password?: number | string;
  confirm_password?: string;
  address?: string;
  company_logo: File | null;
  license: File | null;
  google_location: string;
};

const DocumentationServiceCenter = ({ email, phone, onNextClick }: Props) => {
  const [value, setValue] = useState<string>("");
  const [submitClick, setSubmitClick] = React.useState(false);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const simpleValidator = React.useRef(new SimpleReactValidator());
  const [isCompanyLogo, setIsCompanyLogo] = React.useState(false);
  const [islicenseValid, setlicenseValid] = React.useState(false);
  const [errors, setErrors] = useState<Errors>({
    password: "",
    confirm_password: "",
  });
  const [showErrors, setShowErrors] = useState(false);
  const [payloadData, setPayloadData] = React.useState<payloadDataType>({
    name: "",
    website: "",
    official_phone_extension: "",
    official_phone: "",
    official_email: "",
    owner_name: "",
    password: "",
    confirm_password: "",
    address: "",
    company_logo: null,
    license: null,
    google_location: "",
  });

  let defaultformData: any = {
    name: payloadData.name!,
    url: payloadData.website!,
    official_phone: payloadData.official_phone!,
    official_email: payloadData.official_email!,
    owner_name: payloadData.owner_name!,
    password: payloadData.password!,
    confirm_password: payloadData.confirm_password!,
    address: payloadData.address!,
    email: email!,
    google_location: payloadData.google_location,
  };

  const validateFields = (field: string, value: string) => {
    const values = {
      password: payloadData.password,
      confirm_password: payloadData.confirm_password,
    };

    const newErrors = { ...errors };

    if (field === "password") {
      if (!value) {
        newErrors.password = "The password is required.";
      } else if (value.length <= 6) {
        newErrors.password = "The password must be more than 6 characters.";
      } else {
        newErrors.password = "";
      }
    } else if (field === "confirm_password") {
      if (!value) {
        newErrors.confirm_password = "The confirm password is required.";
      } else if (value !== values.password) {
        newErrors.confirm_password =
          "The passwords do not match, please verify.";
      } else {
        newErrors.confirm_password = "";
      }
    }

    setErrors(newErrors);
  };

  function handleSubmitClick() {
    // Validate the form
    setSubmitClick(!submitClick);
    setIsSubmit(true);
    setShowErrors(true);
    const errorMessages = simpleValidator?.current?.getErrorMessages();

    // Check if all values in errorMessages are null

    const hasValidationErrors = Object.values(errorMessages ?? {}).some(
      (value) => value !== null
    );
    validateFields("password", String(payloadData.password || ""));
    validateFields(
      "confirm_password",
      String(payloadData.confirm_password || "")
    );

    if (hasValidationErrors) {
      for (const key in simpleValidator?.current.errorMessages!) {
        if (simpleValidator?.current.errorMessages!.hasOwnProperty(key)) {
          // formData.append(key, defaultformData[key]);
          simpleValidator?.current?.showMessageFor(key);
          console.log(
            simpleValidator?.current?.showMessageFor(key),
            "simpleValidator?.curren key"
          );
        }
      }

      // Proceed with form submission logic
    } else {
      setShowErrors(false);
      let formData = new FormData();
      for (const key in defaultformData!) {
        if (defaultformData!.hasOwnProperty(key)) {
          formData.append(key, defaultformData[key]);
        }
      }
      formData.append("company_logo", payloadData?.company_logo!);
      formData.append("license", payloadData?.license!);

      setIsLoading(true);
      // commented for SAP registration
      // vendorDetails(formData)
      //   .then((res) => {
      //     if (res) {
      //       setIsLoading(false);
      //       if (res.status) {
      //         onNextClick?.();
      //       } else {
      //         toast.error("Enter all required fields", {
      //           position: "top-right",
      //         });
      //       }

      //       // Assuming onNextClick is a function passed as a prop
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err, "error");
      //     setIsLoading(false);
      //   });
      // success of above success of commented code
    }
  }

  const handleCompanyLogo = (file: File | null) => {
    if (file !== null && file instanceof File) {
      // Perform validation logic here
      const isValid = isFileValid(file);

      // Update the state based on validation result
      setPayloadData({ ...payloadData, company_logo: file });
      setIsCompanyLogo(isValid);

      // Reset submitClick to false when a valid file is uploaded
      setSubmitClick(false);

      // Continue with other handling logic if needed
    } else {
      // Handle the case where file is null or not an instance of File
      console.error("Invalid file selected");
      // You might want to set company logo as invalid in this case too
      setIsCompanyLogo(false);
    }
  };

  const removeHandleCompanyLogo = () => {
    setPayloadData({ ...payloadData, company_logo: null });
    setIsCompanyLogo(false);
  };

  const isFileValid = (file: File) => {
    // Add your file validation logic here
    // For example, check file type, size, etc.
    return file.type === "application/pdf" && file.size > 0; // Adjust the conditions as needed
  };

  const handleLicenseUpload = (file: File | null) => {
    if (file !== null && file instanceof File) {
      // Perform validation logic here
      const isValid = isFileValid(file);

      // Update the state based on validation result
      setPayloadData({ ...payloadData, license: file });
      setlicenseValid(isValid);

      // Reset submitClick to false when a valid file is uploaded
      setSubmitClick(false);

      // Continue with other handling logic if needed
    } else {
      // Handle the case where file is null or not an instance of File
      console.error("Invalid file selected");
      // You might want to set trading license as invalid in this case too
      setlicenseValid(false);
    }
  };

  const removeHandleLicenseUpload = () => {
    setPayloadData({ ...payloadData, license: null });
    setlicenseValid(false);
  };

  const handleChangeOfficialContact = (
    value: string,
    data: any,
    event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    console.log(value, data, formattedValue, "values");

    setPayloadData({ ...payloadData, official_phone: formattedValue });
  };

  return (
    <>
      {isLoading ? (
        <div className="loader-block">
          <img className="img-fluid" src={mainLoader} alt="loader" />
        </div>
      ) : (
        <StyledWrapper>
          <StyledSubWrapper className="StyledSubWrapper">
            <CardHeadingLabel className="CardHeadingLabel">
              User Details
            </CardHeadingLabel>
            <RoundedWrapperCard className="RoundedWrapperCard">
              <StyledGridWrapper container spacing={2}>
                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="Service Center Name [as per license]"
                    name="Service center name"
                    placeholder="Enter your service center name"
                    className="background-gray"
                    value={payloadData.name}
                    validations={"required"}
                    validator={simpleValidator.current}
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        name: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="Owner Name [as per license]"
                    name="Owner Name"
                    placeholder="Enter your owner name"
                    className="background-gray"
                    value={payloadData.owner_name}
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        owner_name: e.target.value,
                      })
                    }
                    validations={"required"}
                    validator={simpleValidator.current}
                  />
                </Grid>
                <Grid item xs={6} className="styled-PhoneNumberField">
                  <PhoneNumberField
                    label="Official Contact Number"
                    placeholder="Enter your company’s official mobile number"
                    validations="required"
                    value={payloadData.official_phone}
                    onChange={handleChangeOfficialContact}
                    className="styled-PhoneNumberField"
                    validator={simpleValidator.current}
                    name="Official Contact Number"
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="Official E-mail ID"
                    placeholder="Enter your company’s official e-mail ID"
                    className="background-gray"
                    value={payloadData.official_email}
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        official_email: e.target.value,
                      })
                    }
                    validations={"required|email"}
                    validator={simpleValidator.current}
                    name="email"
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiStyledTextField
                    type="password"
                    label="Password"
                    placeholder="Enter your Password"
                    className="background-gray"
                    value={payloadData.password}
                    validations="required"
                    name="Password"
                    onChange={(e) => {
                      setPayloadData({
                        ...payloadData,
                        password: e.target.value.toString(),
                      });
                      validateFields("password", e.target.value);
                    }}
                    error={showErrors && !!errors.password}
                    helperText={showErrors ? errors.password || "" : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiStyledTextField
                    type="password"
                    label="Confirm Password"
                    placeholder="Confirm your Password"
                    className="background-gray"
                    value={payloadData.confirm_password}
                    validations="required"
                    onChange={(e) => {
                      setPayloadData({
                        ...payloadData,
                        confirm_password: e.target.value,
                      });
                      validateFields("confirm_password", e.target.value);
                    }}
                    error={showErrors && !!errors.confirm_password}
                    helperText={showErrors ? errors.confirm_password || "" : ""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="Address"
                    placeholder="Enter your address"
                    className="background-gray"
                    value={payloadData.address}
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        address: e.target.value,
                      })
                    }
                    validations={"required|min:6"}
                    validator={simpleValidator.current}
                    name="address"
                  />
                </Grid>

                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="Official Website (if any)"
                    placeholder="Enter your website url here"
                    className="background-gray"
                    value={payloadData?.website}
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        website: e.target.value,
                      })
                    }
                    name="Official Website "
                    validations={"url"}
                    validator={simpleValidator.current}
                    hideStar={true}
                  />
                </Grid>

                <Grid item xs={6}>
                  <MuiStyledTextField
                    label="Google Location"
                    placeholder="Enter your google location url here"
                    className="background-gray"
                    value={payloadData?.google_location}
                    onChange={(e) =>
                      setPayloadData({
                        ...payloadData,
                        google_location: e.target.value,
                      })
                    }
                    name="Google Location"
                    validations={"required|url"}
                    validator={simpleValidator.current}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  <FieldLabel>
                    Upload the <span>Company Logo</span>{" "}
                  </FieldLabel>
                  <UploadFieldWrapper>
                    <FileUploadButton
                      onFileUpload={handleCompanyLogo}
                      onRemoveClick={removeHandleCompanyLogo}
                      fieldName="Company Logo"
                      defaultValue={payloadData.company_logo}
                      isSubmit={submitClick}
                    />
                  </UploadFieldWrapper>
                </Grid>
                <Grid item xs={6}>
                  <FieldLabel>
                    Upload the<span> License </span>
                    <span className="validField">*</span>
                  </FieldLabel>
                  <UploadFieldWrapper>
                    <FileUploadButton
                      onFileUpload={handleLicenseUpload}
                      onRemoveClick={removeHandleLicenseUpload}
                      fieldName="License "
                      validations="required"
                      validator={simpleValidator.current}
                      defaultValue={payloadData.license}
                      isSubmit={submitClick}
                    />
                  </UploadFieldWrapper>
                </Grid>
              </StyledGridWrapper>
            </RoundedWrapperCard>
          </StyledSubWrapper>

          <SubmitBtnWrapper className="SubmitBtnWrapper">
            <StyledButton onClick={handleSubmitClick} variant="contained">
              Submit
            </StyledButton>
          </SubmitBtnWrapper>
        </StyledWrapper>
      )}
    </>
  );
};

export default DocumentationServiceCenter;
const StyledWrapper = styled.div`
  display: "block";
  .MuiFormHelperText-root {
    margin-left: 0px;
    font-size: 12px;
    color: #e91344;
    font-family: "Outfit Regular" !important;
  }

  .ijBxGA {
    font-size: 13px;
  }
`;

const StyledSubWrapper = styled.div`
  display: "block";
  width: 100%;
  padding: 0 0 30px 0;
  .CardHeadingLabel {
    margin: 15px 0px 15px;
  }
  .RoundedWrapperCard {
    margin: 0px 25px 0px 25px;
  }
`;

const StyledGridWrapper = styled(Grid)`
  .styled-PhoneNumberField {
    .FieldWrapper {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73;
      height: 41px;
      input {
        background: #e8e8e8 !important;
      }
    }
  }
  .background-gray {
    div {
      background: #e8e8e8 !important;
      border: 1px solid #6e6e73 !important;
    }
    .react-tel-input {
      background: #e8e8e8;
      height: 38px !important;
    }
  }
  .textfield-wrapper {
    max-width: 100% !important;
  }

  .textfield-wrapper .react-tel-input > input {
    min-height: 38px !important;
    max-height: 38px !important;
  }
`;
const FieldLabel = styled.div`
  text-align: left;
  margin: 0 0 10px 0;
  color: #a09d9d;
  font-size: 14px;
  span {
    font-weight: 600;
    color: #000000;
  }
  .validField {
    color: #ff0000;
  }
`;

const UploadFieldWrapper = styled.div`
  padding: 5px 15px;
  color: #e91344 !important;
  text-align: left;
  font-size: 13px;
`;

const SubmitBtnWrapper = styled.div`
  /* display: flex;
  align-items: center;
  justify-content: center; */
  width: 100%;
  text-align: right;
  padding: 5px 24px;
  button {
    max-width: 180px !important;
    color: rgb(255, 255, 255) !important;
    font-size: 12px !important;
    border: none !important;
    padding: 10px 10px !important;
    border-radius: 6px !important;
    text-transform: uppercase !important;
    background: #01579b !important;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 0px !important;
  width: 100%;
  max-width: 200px !important;
  color: #ffffff !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  font-family: "Outfit Regular" !important;
  border: none !important;
  padding: 10px 0px !important;
  border-radius: 6px !important;
  text-transform: capitalize !important;
  background: #6e93f7 !important;
`;
