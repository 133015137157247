import React, { useState, useContext, Fragment } from "react";
import StaffRow from "./StaffRow";
import configData from "../../../../config.json";
import { StaffContext } from "./StaffContext";
import noDataImage from "../../../../assets/img/icon/upload/empty-box.svg";
import "../../Layout/Ui/css/Pagination.css";
import ReactPaginate from "react-paginate";
import DataTable from "react-data-table-component";
import ReactTooltip from "react-tooltip-rc";
import placeholderImg from "../../../../assets/img/user/3.png";
import femaleuserImg from "../../../../assets/img/user/female-profile-.jpg";
import defaultImg from "../../../../assets/img/user/default-avatar-icon.jpg";
import StaffDeleteModal from "./StaffDeleteModal";
import styled from "styled-components";
const StaffWindowLeft = (props) => {
  const {
    staffTable,
    setStaffTable,
    pic,
    setPic,
    mappedStaff,
    setMappedStaff,
    staffcredential,
    SetRowActive,
    getStaffData,
    setDeleteId,
    setDeleteModal,
    deleteModal,
    setAddImageSubmit,
  } = useContext(StaffContext);
  const [selectedRow, setSelectedRow] = useState(null);
  const columns = [
    {
      name: "PROFILE",
      selector: (row) => row?.name ?? '',
      sortable: true,
      cell: (row) => (
        <Namewrapper className="namewrapper">
          <div data-tag="allowRowEvents">
            {row?.profile ? (
              <div className="small-img mr-2" key="">
                <img
                  data-tag="allowRowEvents"
                  src={`${configData.SERVER_URL}/uploads/staff-profiles/${row?.profile}`}
                  className="img-fluid"
                  alt=""
                  height="100"
                  width="100"
                  style={{objectFit: 'unset'}}
                />
              </div>
            ) : (
              <div className="small-img mr-2">
                <img
                  data-tag="allowRowEvents"
                  src={
                    row?.gender === 1
                      ? placeholderImg
                      : row?.gender === 2
                      ? femaleuserImg
                      : defaultImg
                  }
                  className="img-fluid"
                  alt=""
                  height="100"
                  width="100"
                />
              </div>
            )}
            <span data-tag="allowRowEvents">{row?.name}</span>
          </div>
        </Namewrapper>
      ),
      allowOverflow: false,
    },
    {
      name: "E-MAIL ID",
      selector: (row) => row?.email,
      sortable: true,
      cell: (row) => (
        <span data-tag="allowRowEvents">{row?.email ? row?.email : "N/A"}</span>
      ),
    },
    {
      name: "MOBILE NUMBER",
      selector: (row) => row?.phone,
      sortable: true,
    },
    {
      name: "DESIGNATION",
      selector: (row) => row?.designation,
      sortable: true,
      cell: (row) => (
        <Namewrapper className="designationwrapper">
          <span data-tag="allowRowEvents">
            {row?.designation ? row?.designation : "N/A"}
          </span>
        </Namewrapper>
      ),
    },
    {
      name: "ACTIONS",
      // selector: (row) => row.id,
      cell: (row) => (
        <div class="actions">
          <span
            class="action-delete p-3"
            onClick={(e) => handleDeleteModal(e, row?.id)}
            data-for="deletestaff"
            data-tip="Delete"
          >
            <i class="fal fa-trash"></i>
            <ReactTooltip
              id="deletestaff"
              place="bottom"
              type="info"
              effect="float"
            />
          </span>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  //PAGINATION OPTIONS
  const paginationComponentOptions = {
    rowsPerPageText: false,
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    noRowsPerPage: true,
  };
  const staffRowClick = (e, row) => {
    setSelectedRow(e.id);
    // Find the nearest parent element with class "rdt_TableRow"
    const closestTableRow = row?.target.closest(".rdt_TableRow");

    // Check if the closest parent element is found
    if (closestTableRow) {
      // Remove the class from all elements with class "rdt_TableRow"
      const allTableRows = document.querySelectorAll(".rdt_TableRow");
      allTableRows.forEach((rowElement) => {
        rowElement.classList.remove("current-selected-row");
      });

      // Append a new class to the closest parent element
      closestTableRow.classList.add("current-selected-row");
    }

    props?.viewStaffClick();

    getStaffData(e.id);
    if (mappedStaff) {
      props.viewStaffClick();
    }
    setDeleteId(e.id);
    setPic([]);
    setAddImageSubmit(false);
    SetRowActive(true);
   
  };
  const handleDeleteModal = (e, rowId) => {
    setDeleteId(rowId);
    setDeleteModal(true);
    e.stopPropagation();
  };

  // //With React
  // const [pageNumber, setPageNumber] = useState(0);
  // const usersPerPage = 10;
  // const pagesVisited = pageNumber * usersPerPage;
  // const displayUsers = staffTable.slice(
  //   pagesVisited,
  //   pagesVisited + usersPerPage
  // );
  // const pageCount = Math.ceil(staffTable.length / usersPerPage);
  // const changePage = ({ selected }) => {
  //   setPageNumber(selected);
  // };
  return (
    <Fragment>
      <div className="content-window-left">
        <div className="content-table-block">
          <div className="content-table table-responsive single-column-view">
            <WrapperLeft>
              <DataTable
                onRowClicked={(e, row) => staffRowClick(e, row)}
                columns={columns}
                data={staffTable}
                highlightOnHover={true}
                pointerOnHover={true}
                pagination
                paginationComponentOptions={paginationComponentOptions}
              />
              {deleteModal && <StaffDeleteModal />}
            </WrapperLeft>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StaffWindowLeft;
const WrapperLeft = styled.div`
.ixjXmq {
  white-space: nowrap;
}
  .kjHanM {
    margin: 0px 5px;
  }
  .DfDUN {
    width: 30px;
  }
`;
const Namewrapper = styled.div`
  &.namewrapper {
    white-space: nowrap;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
  &.designationwrapper {
    white-space: nowrap;
    width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }
`;
