

export const GstValidation = (values, field_value, isOnChange, field,profileConfigData) => {
  const errors = {};
  const isTaxTypeVAT= profileConfigData?.tax_type?.tax_type_id_pk === 1;

  

  //Validation For OnBlur
  if (isOnChange) {
    //Legal Business name
    if (field === "business_name" && isOnChange) {
      if (!field_value) {
        errors.business_name = "Business name is required.";
      }
      if (field_value.length >= 1) {
        errors.business_name = null;
      }
      
    }
    //GST Number
    if (field === "gst_number" && isOnChange) {
      if (!field_value) {
        errors.gst_number = isTaxTypeVAT
        ?"Vat number is required"
        :"Gst number is required"
      }
      if (field_value.length < 15) {
        errors.gst_number = isTaxTypeVAT
        ?"Invalid vat number"
        :"Invalid gst number"
      }
      if (field_value.length === 15) {
        errors.gst_number = isTaxTypeVAT
        ?""
        :""
      }
      if (field_value.length > 15) {
        errors.gst_number = isTaxTypeVAT
        ?"Invalid vat number"
        :"Invalid gst number"
      }
    }
  }

  //Validation for OnSubmit
  else {
    //Legal Business name
    if (!values.business_name)
      errors.business_name = "Business name is required.";

    //GST Number
    if (!values.gst_number) {
      errors.gst_number = "Phone Number is required";
    }
    if (values.gst_number.length !== 15) {
      errors.gst_number = isTaxTypeVAT
        ?"Vat is required."
        :"Gst is required."
    }
    
  }
  return errors;
};

export default GstValidation;
