import React from "react";
import Switch from "@mui/material/Switch";
import styled from "styled-components";

type Props = {
  className?: string;
  checked?: boolean; //use this for controlling checked values
  disabled?: boolean; //use this for  disabling the switch
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; //onChange func callback
};

const MuiToggleSwitch = ({ className, checked, disabled, onChange }: Props) => {
  //onChange func
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event); //onChange Callback
  };

  return (
    <StyledSwitchWrapper
      className={`styled-switch-wrapper ${className ? className : ""}`}
    >
      <Switch
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
        inputProps={{ "aria-label": "controlled" }}
      />
    </StyledSwitchWrapper>
  );
};

export default MuiToggleSwitch;
const StyledSwitchWrapper = styled.div`
  display: block;
  width: fit-content;
  .MuiSwitch-root {
    .MuiButtonBase-root {
      color: #ffffff !important;
      top: 1px !important;
      &.Mui-checked {
        color: #ffffff !important;
      }
      .MuiSwitch-thumb {
        border: 1px solid #c9c9c9 !important;
      }
      &.Mui-disabled + .MuiSwitch-track {
        opacity: 0.3 !important;
      }
    }
    .Mui-checked + .MuiSwitch-track {
      background: #01b148 !important;
      opacity: 1 !important;
    }
    .MuiSwitch-track {
      height: 17px !important;
      border-radius: 20px !important;
    }
  }
`;
