import React, { useState, useContext, Fragment, useEffect } from "react";
import { OrdersContext } from "../OrdersContext";
import { Rating } from "react-simple-star-rating";
import {
  saveFeedback,
  getFeedbacks
} from "../../../../../api/services/sap/order-management";
import { toast } from "react-toastify";

const FeedBackTab = () => {
  const {
    innertab,
    mappedOrderData,
    viewHandler
  } = useContext(OrdersContext);
  const starQ = [{
    index: 0,
    star: ""
  }];

  const [rating, setRating] = useState(starQ);
  const [comment, setComment] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  const handleRating = (star, index) => {
    let ratingList = rating.map((element) => {
      if (element.index === index) {
        element.star = star;
        return element;
      }
      return element;
    });
    setRating(ratingList);
    setIsChanged(true);
  };

  useEffect(() => {
    if (mappedOrderData.feedbacks[0]) {
      setComment(mappedOrderData.feedbacks[0].description);
      let ratingList = rating.map((element, index) => {
        if (index === 0) {
          element.star = mappedOrderData.feedbacks[0].rating;
          return element;
        }
        return element;
      });
      setRating(ratingList);
    }
  }, []);

  const feedbackSubmitHandler = (e) => {
    e.preventDefault();
    const data = {
      order_id_fk: mappedOrderData.order_id_pk,
      service_admin_id_fk: mappedOrderData.service_admin_id_fk,
      customer_id_fk: mappedOrderData.customer_id_fk,
      rating: rating[0].star,
      description: comment
    }
    const response = saveFeedback(data)
    response.then((res) => {
      viewHandler(mappedOrderData.order_id_pk);
      toast.success("Your feedback has been successfully submitted", {
        position: "bottom-right",
      });
      setIsChanged(false);
    })
  };
  const showSaveButton = () => {
    let condition = (isChanged && mappedOrderData.feedbacks[0]) ||
                     ((comment.length || rating[0].star) && !mappedOrderData.feedbacks[0]);
    return condition;
  }

  return (
    <div
      className={innertab === 5 ? " show active" : "tab-pane fade"}
      id="nav-personal"
      role="tabpanel"
      aria-labelledby="nav-personal-tab"
    >
      <div className="inner-block">
        <div className="feedback-block">
          <h5>Record your experience with the customer:</h5>
          <form onSubmit={feedbackSubmitHandler}>
            <Fragment>
              {rating.map((item, index) => (
                <div key={index} className="row star-row">
                  <div className="col-12 rating-row">
                    <Rating
                      className="feedback-rating"
                      initialValue={item.star}
                      onClick={(star) => handleRating(star, index)}
                    />
                  </div>
                </div>
              ))}
            </Fragment>
            <textarea
              className="form-control"
              placeholder="Comment"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
                setIsChanged(true);
              }}
            ></textarea>
           {showSaveButton() && (
              <div className="col-12 text-end p-0 mt-3">
                <button className="btn btn-update" type="submit">
                  <i className="fal fa-save mr-2"></i>Save
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default FeedBackTab;
