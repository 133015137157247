
import React from "react";
import styled from "styled-components";
import Button from "@mui/material/Button";
import GoToShopIcon from "./assets/img/Vector.svg";

export type ButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  id?: string;
  className?: string;
};

export const GoToShopButton = ({ onClick, id, className }: ButtonProps) => {
  return (
    <Wrapper className={className}>
      <Button onClick={onClick} id={id}>
        <div className="shop-button-icon">
          <svg
            width="12"
            height="15"
            viewBox="0 0 12 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.6667 4.00033H9.33333C9.33333 2.13366 7.86667 0.666992 6 0.666992C4.13333 0.666992 2.66667 2.13366 2.66667 4.00033H1.33333C0.6 4.00033 0 4.60033 0 5.33366V13.3337C0 14.067 0.6 14.667 1.33333 14.667H10.6667C11.4 14.667 12 14.067 12 13.3337V5.33366C12 4.60033 11.4 4.00033 10.6667 4.00033ZM6 2.00033C7.13333 2.00033 8 2.86699 8 4.00033H4C4 2.86699 4.86667 2.00033 6 2.00033ZM10.6667 13.3337H1.33333V5.33366H10.6667V13.3337ZM6 8.00033C4.86667 8.00033 4 7.13366 4 6.00033H2.66667C2.66667 7.86699 4.13333 9.33366 6 9.33366C7.86667 9.33366 9.33333 7.86699 9.33333 6.00033H8C8 7.13366 7.13333 8.00033 6 8.00033Z"
              fill="#F8F8F8"
            />
          </svg>
        </div>
        Go to shop
      </Button>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2px 2px;

  .MuiButtonBase-root {
    font-size: 12px;
    font-weight: 400;
    text-transform: none !important;
    letter-spacing: 1px !important;
    background: #000 !important;
    color: #fff !important;
    max-height: 32px;
    min-width: 150px !important;
    max-width: 150px !important;
    border-radius: 4px !important;
    box-shadow: none !important;
    padding: 6px 11px !important;
    align-items: center;
  
  }
  .shop-button-icon {
    margin-right: 8px;
    margin-top: -5px;
  }
`;


