import React, { useState, useRef } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { Button } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

export default function CropModal({
  openCropModal,
  setCropModal,
  setCroppedImageUrl,
  src,
  blob,
  aspect
}) {
  const [crop, setCrop] = useState({
    unit: "%",
    x: 25,
    y: 25,
  });
  const imageRef = useRef(null);
  const imageElement = document.querySelector(".ReactCrop__child-wrapper img");
  if (imageElement) {
    imageRef.current = imageElement;
  }

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onCropComplete = (newCrop) => {
    setCrop(newCrop);
  };
  const handleDoneClick = () => {
    if (imageRef.current && crop.width && crop.height) {
      getCroppedImg(
        imageRef.current,
        crop,
        "cropped-image-" + new Date().getTime() + ".jpg",
        (e) => {
          setCroppedImageUrl(URL.createObjectURL(e),e);
        }
      );
      setCropModal(false);
    }
  };
  const handleCancelClick = () => {
    setCropModal(false);
    setCroppedImageUrl(null);
  };
  const getCroppedImg = (image, crop, fileName, callback) => {
    // Ensure the image is an instance of HTMLImageElement
    if (!(image instanceof HTMLImageElement)) {
      throw new Error("Provided value is not an HTMLImageElement");
    }

    // Ensure the image is fully loaded
    if (!image.complete) {
      image.onload = () => processCrop();
      image.onerror = (error) => {
        console.error("Image failed to load", error);
        throw new Error("Image failed to load");
      };
    } else {
      processCrop();
    }

    function processCrop() {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob(
        (blob) => {
          blob.name = fileName;
          callback(blob);
        },
        "image/jpeg",
        1
      );
    }
  };

  return (
    <div>
      <Dialog
        open={openCropModal}
        onClose={() => setCropModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Crop Image"}</DialogTitle>
        <DialogContent>
          <ReactCrop
            src={src}
            crop={crop}
            aspect={aspect}
            onComplete={onCropComplete}
            onChange={onCropChange}
          >
            <img src={src} alt="" />
          </ReactCrop>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelClick}
            color="primary"
          >
            Cancel
          </Button>
          <Button onClick={handleDoneClick} color="primary" autoFocus>
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
