import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";
import styled from "styled-components";

interface FixedTagsProps {
  options?: { [key: string]: any }[];
  fixedOptions?: { [key: string]: any }[];
  renderInput?: (params: any) => React.ReactNode;
  placeholder?: string;
  onChange?: (event: any, newValue: { [key: string]: any }[]) => void;
  value?: { [key: string]: any }[];
}

const CustomTextFieldWithChip: React.FC<FixedTagsProps> = ({
  options,
  fixedOptions = [],
  renderInput,
  placeholder,
  onChange,
  value: initialValue,
}) => {
  const [value, setValue] = useState<{ [key: string]: any }[]>(
    initialValue ? [...fixedOptions, ...initialValue] : [...fixedOptions]
  );

  const handleChange = (event: any, newValue: { [key: string]: any }[]) => {
    setValue([
      ...fixedOptions,
      ...newValue.filter((option) => fixedOptions.indexOf(option) === -1),
    ]);
    if (onChange) {
      onChange(event, newValue);
    }
  };
  const availableOptions = options?.filter(
    (option) =>
      !value.some((selectedOption) => selectedOption.id === option.id)
  );
  return (
    <Wrapper>
      <Autocomplete
        multiple
        value={value}
        onChange={handleChange}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.title}
              {...getTagProps({ index })}
              disabled={fixedOptions.indexOf(option) !== -1}
            />
          ))
        }
        options={availableOptions ?? []}
        getOptionLabel={(option) => option.title}
        renderInput={(params) =>
          renderInput ? (
            renderInput(params)
          ) : (
            <TextField {...params} placeholder={placeholder} />
          )
        }
      />
    </Wrapper>
  );
};
export default CustomTextFieldWithChip;
const Wrapper = styled.div`
  .MuiFormControl-root {
    background: #e8e8e8;
    border-radius: 4px;
  }
  .MuiInputBase-root {
    padding: 0;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;
