import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Layout";
import { CustomerProvider } from "./CustomerContext";
import CustomerContentTitle from "./Ui/CustomerContentTitle";
import ContentHeader from "../../../ServiceAdmin/Layout/Ui/ContentHeader";
import WindowCustomer from "./WindowCustomer";
import mainLoader from "../../../../assets/img/loader/loader.gif";

const Orders = () => {
  const [addOrders, SetAddOrders] = useState(false);
  const handleAddOrders = () => SetAddOrders(!addOrders);
  let toggleClassCheck = addOrders ? " change" : "";
  const [pageloader, SetPageLoader] = useState(false);
  //Use Effect Loader
  useEffect(() => {
    SetPageLoader(true);
    const loaderTime = setTimeout(() => {
      SetPageLoader(false);
    }, 1500);
    return () => {
      clearTimeout(loaderTime);
    };
  }, []);
  return (
    <>
      {pageloader ? (
        <div className="loader-block">
          <img className="img-fluid" src={mainLoader} />
        </div>
      ) : (
        <Layout>
          <CustomerProvider>
            <div className="content-block">
              <section className="main-section">
                <div className="container-fluid p-0">
                  <ContentHeader />
                  <div className="col-12 p-0 page-window">
                    <CustomerContentTitle handleAddOrders={handleAddOrders} />
                    <div className="content-section">
                      <div className={`content-window ${toggleClassCheck} `}>
                        <WindowCustomer
                          handleAddOrders={handleAddOrders}
                          SetAddOrders={SetAddOrders}
                          addOrders={addOrders}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </CustomerProvider>
        </Layout>
      )}
    </>
  );
};

export default Orders;
