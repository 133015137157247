import React, { useState, createContext } from "react";
import dateFormat, { masks } from "dateformat";
export const BookingsContext = createContext();
export const BookingsProvider = (props) => {
  const [BookingsTable, SetBookingsTable] = useState([
    {
      name: "Down Town Moto",
      phone_number: 7012245807,
      total_bookings: 30,
      date: "2022-10-31 18:30",
    },
    {
      name: "AutoWise",
      phone_number: 9895491149,
      total_bookings: 12,
      date: "2022-10-29 18:30",
    },
    {
      name: "The Detailing Mafia",
      phone_number: 9895491149,
      total_bookings: 20,
      date: "2022-10-30 18:30",
    },
    {
      name: "South Town Moto",
      phone_number: 4578350989,
      total_bookings: 12,
      date: "2022-10-31 18:30",
    },
  ]);
  const [mappedBookingsData, setMappedBookingsData] = useState({});
  const [viewReadOnly, setViewReadOnly] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  // const [viewBookings, setViewBookings] = useState(false);
  // const [BookingsTable, SetBookingsTable] = useState([]);
  // const [mappedBookingsData, SetMappedBookingsData] = useState({});
  const [viewBookings, setViewBookings] = useState(false);
  // const [dateRange, setDateRange] = useState([new Date(), new Date()]);

  const [formChange, SetFormChange] = useState(false);

  const [currentDate, setCurrentDate] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [bookDate, setBookDate] = useState([
    dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
    dateFormat(new Date(), "UTC:yyyy-mm-dd HH:MM"),
  ]);
  const [cancelData, setCancelData] = useState([{}]);

  const filterBookingshandler = (filterData) => {
    setFilterValue(
      BookingsTable.filter((item) =>
        dateFormat(item.date, "yyyy-mmd-dd").includes(
          dateFormat(filterData, "yyyy-mmd-dd")
        )
      )
    );
  };
  // console.log(BookingsTable, "BookingsTable");
  const handleBookingsEdit = (newData, customer_name) => {
    let editedBookingList = BookingsTable.map((item) => {
      if (item.customer_name === customer_name) {
        return newData;
      }
      return item;
    });
    // console.log(editedBookingList, "editd value");
    SetBookingsTable(editedBookingList);
  };
  // const handleBookingsDelete = (BookingsName) => {
  //   let newList = BookingsTable.filter((item) => item.BookingsName !== BookingsName);
  //   SetBookingsTable(newList);
  // };

  let initialValues = {
    BookingsTable,
    SetBookingsTable,
    mappedBookingsData,
    setMappedBookingsData,
    viewReadOnly,
    setViewReadOnly,
    viewBookings,
    setViewBookings,
    viewBookings,
    setViewBookings,
    filterValue,
    setFilterValue,
    bookDate,
    setBookDate,
    filterBookingshandler,
    currentDate,
    setCurrentDate,
    editMode,
    setEditMode,
    handleBookingsEdit,
    cancelData,
    setCancelData,
    formChange,
    SetFormChange,searchTerm, setSearchTerm
  };

  return (
    <BookingsContext.Provider value={initialValues}>
      {props.children}
    </BookingsContext.Provider>
  );
};
