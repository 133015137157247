import React, { useContext, useEffect, useState } from "react";
import { StaffContext } from "./StaffContext";
import "./Ui/css/style.css";
import { toast } from "react-toastify";
import Select from "react-select";
import DatePicker from "react-date-picker";
import {
  createStaff,
  getStaffList,
} from "../../../../api/services/sap/staff-management";
import { getServiceConfiguration } from "../../../../api/services/sap/bookings-management";
import uploadImageFile from "../../../../assets/img/icon/upload/photos.svg";
import uploadFileId from "../../../../assets/img/icon/upload/add-file.svg";
import { getLookupDataAPI } from "../../../../api/services/common/commonAPIs";
import { Validation } from "./Ui/Validation";
import styled from "styled-components";
import { usePhoneExtension } from "../../../hooks/usePhoneExtension";

const today = new Date();
const dateWithoutTime = today.toLocaleDateString();

let initialstate = {
  name: "",
  email: "",
  emergency_details_phone_extension: "",
  emergency_details_phone: "",
  emergency_details_name: "",
  emergency_details_relation_type: "",
  emergency_details_address: "",
  phone_extension: "",
  phone: "",
  address: "",
  designation: "",
  gender: null,
  date_of_birth: new Date(),
  salary: null,
  blood_group_id: "",
  date_of_joining: new Date(),
  staff_id_proof_type: "",
  staff_id_proof:[],
  employeeid: "",
  id: "",
  expiry_date: "",
};

const errorFields = {
  name: "",
  email: "",
  phone: "",
  address: "",
  date_of_birth: "",
  blood_group_id: "",
  staff_id_proof_type: "",
  emergency_details_name: "",
  emergency_details_phone: "",
  emergency_details_relation_type: "",
  pic: [],
  idproof: [{}],
  expiry_date: "",
};

const AddStaffForm = (props) => {
  const [staffform, SetStaffForm] = useState(initialstate);
  //form validation state
  const [formErrors, SetFormErrors] = useState({});
  const [error, setError] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [selectedIdProofValue, setSelectedIdProofValue] = useState([]);
  const [idProofFileError, setIdProofFileError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Staff Context
  const {
    setStaffTable,
    pic,
    setPic,
    idproof,
    setIdProof,
    addImageSubmit,
    setAddImageSubmit,
    SetFormChange,
    addProofSubmit,
    bloodGroups,
    setBloodGroups,
    idproofType,
    setIdproofType,
    relationType,
    setRelationType,
    gender,
    setGender,
    staffTable,
    staffConfigData,
    setStaffConfigData,
  } = useContext(StaffContext);

  const phoneExtension = usePhoneExtension();

  useEffect(() => {
    const typeofTaxUpdate = async () => {
      try {
        const data = {
          table_reference: ["tax_types", "payment_methods", "id_proof_types"],
        };
        const response = await getServiceConfiguration(data);
        setStaffConfigData(response.id_proof_types); // Assuming response contains id_proof_types array
      } catch (error) {}
    };
    typeofTaxUpdate();
  }, []);
  let BloodOptions = [{}];
  let RelationOptions = [{}];
  let GenderOption = [{}];

  BloodOptions = bloodGroups.map((item) => ({
    label: item.title,
    value: item.value,
  }));
  const IdOptions = staffConfigData?.map((item) => ({
    label: item?.title,
    value: item?.id_proof_type_id_pk.toString(),
  }));

  RelationOptions = relationType?.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  GenderOption = gender.map((item) => ({
    label: item.title,
    value: item.value,
  }));

  const onChangeValidate = (field, value) => {
    const errors = Validation(staffform, value, true, field);
    if (Object.keys(errors).length !== 0) {
      setError(true);
      const updatedErrorObject = { ...formErrors, ...errors };
      SetFormErrors(updatedErrorObject);  
    } else {
      setError(false);
      SetFormErrors(errorFields);
    }
  };

  //Form Submit Handler
  const StaffSubmitHandler = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setFormSubmit(true);
    let errors = Validation({...staffform, staff_id_proof:selectedIdProofValue});
    if (Object.keys(errors).length !== 0) {
      setError(true);
      SetFormErrors(errors);
    } else {
      setIdProofFileError("");
      const formData = {
        ...staffform,
      };
      const createStaffData = createStaff({
        ...formData,
        profile_image: pic.length ? [Object.assign({}, ...pic)] : [],
        staff_id_proof: [...idproof],
      });
      createStaffData
        .then((res) => {
          let index = undefined;
          let newTable = [];
          let existingRecord = staffTable.filter((item, i) => {
            const value = item.id === res.staff_id_pk;
            if (value) {
              index = i;
            }
            return value;
          });
          if (existingRecord.length) {
            newTable = staffTable.map((item, i) => {
              if (i === index) {
                item = {
                  id: res.staff_id_pk,
                  name: res.name,
                  profile: res.profile_image,
                  gender: res.gender,
                };
              }
              setStaffTable(newTable);
              SetStaffForm([]);
              setPic([]);
              setIdProof([]);
              props.closeform(false);
              SetFormChange(false);
              getStaffList(newTable);
              toast.success("Staff Created Successfully", {
                position: "bottom-right",
              });
            });
          } else
            newTable = [
              {
                id: res.staff_id_pk,
                profile: res.profile_image,
                name: res.name,
                email: res.email,
                phone: res.phone,
                designation: res.designation,
                gender: res.gender,
              },
              ...staffTable,
            ];
          setStaffTable(newTable);
          getStaffList(newTable);

          SetStaffForm([]);
          setPic([]);
          setIdProof([]);
          props.closeform(false);
          SetFormChange(false);
          toast.success("Staff Created Successfully", {
            position: "bottom-right",
          });
        })
        .catch((err) => {
          if (err.data) {
            toast.error(err.data.errors[0].msg, {
              position: "bottom-right",
            });
          } else {
            toast.error(err.message, {
              position: "bottom-right",
            });
          }
        });
    }
  };

  const today = new Date();
  const maxDateAllowed = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  const minDateAllowed = today;

  const sideEffect = () => {
    const bloodGroup = getLookupDataAPI({ type: "blood_group" });
    // const idproofType = typeofTaxUpdate({ type: "idproof_type" });
    const relationType = getLookupDataAPI({ type: "relation_type" });
    const gender = getLookupDataAPI({ type: "gender" });

    bloodGroup
      .then((res) => {
        setBloodGroups(res.data);
      })
      .catch((e) => {});

    // idproofType
    //   .then((res) => {

    //     setIdproofType(res.data);
    //   })
    //   .catch((e) => {

    //   });

    relationType
      .then((res) => {
        setRelationType(res.data);
      })
      .catch((e) => {});

    gender
      .then((res) => {
        setGender(res.data);
      })
      .catch((e) => {});
  };

  useEffect(sideEffect, []);
  //check id extension

  const handleImgRemove = (image, i) => {
    let newImg =
      pic &&
      pic.filter((e, newIndex) => {
        if (newIndex === i) {
          return e !== image;
        }
      });
    setPic(newImg);
    setAddImageSubmit(false);
  };
    return (
    <Wrapper>
      <form className="" onSubmit={StaffSubmitHandler}>
        <ImageWrapper>
          <div
            className="input-group input-group-custom"
            style={{ flexWrap: "unset" }}
          >
            <div className="input-group-prepend d-flex align-items-center">
              <span className="input-group-text">
                <i class="fal fa-images"></i>Photo
                {/* <sup className="theme-text">*</sup> */}
              </span>
            </div>
            <div className="small-block">
              <div className="row-img row">
                {addImageSubmit &&
                  pic &&
                  pic.map((photo, index) => {
                    return (
                      <div className="col-img-list">
                        <div className="img-list" key={index}>
                          <button
                            className="btn"
                            onClick={(e, index) =>
                              handleImgRemove(photo, index)
                            }
                          >
                            <i class="fal fa-times"></i>
                          </button>{" "}
                          <img
                            src={photo.base64}
                            className="img-fluid"
                            alt=""
                            height="100"
                            width="100"
                          />{" "}
                        </div>
                      </div>
                    );
                  })}
              </div>
              {props.addstaff && !pic.length ? (
                <button
                  type="button"
                  className="btn-image"
                  onClick={props.handleShow}
                >
                  <div className="upload-section justify-content-center">
                    <div className="upload-img">
                      <img src={uploadImageFile} alt="loading..." />
                    </div>
                  </div>
                  Upload
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </ImageWrapper>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-user"></i>Name
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                SetStaffForm({ ...staffform, name: e.target.value });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
                onChangeValidate("name", e.target.value);
              }}
              value={staffform.name}
              onBlur={(e) => onChangeValidate("name", e.target.value)}
              maxLength={100}
            />
            {error && formErrors.name ? (
              <span className="form-error">{formErrors.name}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-envelope"></i>E-mail
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                SetStaffForm({ ...staffform, email: e.target.value });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
                onChangeValidate("email", e.target.value);
              }}
              onBlur={(e) => onChangeValidate("email", e.target.value)}
              value={staffform.email}
            />
            {error && formErrors.email ? (
              <span className="form-error">{formErrors.email}</span>
            ) : (
              ""
            )}
            {/* <span className="form-error">E-mail is required!</span> */}
          </div>
        </div>

        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-phone"></i>Phone Number
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <div className="row m-0">
              <PhoneWrapper className="phone-wrapper">
                <Select
                  className="phone-extension"
                  options={phoneExtension}
                  value={
                    staffform.phone_extension
                      ? {
                          label:
                            phoneExtension.find(
                              (option) =>
                                option.value === staffform.phone_extension
                            )?.value ?? "",
                          value:
                            phoneExtension.find(
                              (option) =>
                                option.value === staffform.phone_extension
                            )?.value ?? "",
                        }
                      : phoneExtension.find(
                          (option) => option.value === staffform.phone_extension
                        )
                  }
                  onChange={(selectedOption) => {
                    SetStaffForm({
                      ...staffform,
                      phone_extension: selectedOption.value,
                    });
                    onChangeValidate("PhoneExtension", selectedOption.value);
                  }}
                  onBlur={() =>
                    onChangeValidate(
                      "PhoneExtension",
                      staffform.phone_extension
                    )
                  }
                  maxLength="15"
                />
                <input
                  type="text"
                  className="form-control"
                  onChange={(e) => {
                    const phoneValue = e.target.value.replace(/\D/g, "");
                    SetStaffForm({ ...staffform, phone: phoneValue });
                    SetFormChange(phoneValue.length > 0);
                    onChangeValidate("phone", phoneValue);
                  }}
                  onBlur={(e) => onChangeValidate("phone", e.target.value)}
                  value={staffform.phone}
                  maxLength={15}
                />
              </PhoneWrapper>
              {error && formErrors.phone ? (
                <span className="form-error">{formErrors.phone}</span>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-map-marker-alt"></i>Address
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <textarea
              class="form-control"
              onChange={(e) => {
                SetStaffForm({ ...staffform, address: e.target.value });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
                onChangeValidate("address", e.target.value);
              }}
              onBlur={(e) => onChangeValidate("address", e.target.value)}
              value={staffform.address}
            ></textarea>
            {error && formErrors.address ? (
              <span className="form-error">{formErrors.address}</span>
            ) : (
              ""
            )}
          </div>
        </div>
        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-tint"></i>Gender
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <DropdownWrapper className="input-block">
            <Select
              className="gender"
              options={GenderOption}
              onChange={(e) =>
                SetStaffForm(
                  { ...staffform, gender: e.value },
                  SetFormChange(true)
                )
              }
            />
          </DropdownWrapper>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-calendar"></i>Date of Birth
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <DatePicker
              maxDate={maxDateAllowed}
              format="dd/MM/y"
              calendarIcon={null}
              clearIcon={null}
              className="form-control"
              onChange={(e) => {
                // alert(e);

                SetStaffForm({ ...staffform, date_of_birth: e });
                if (e && e !== staffform.date_of_birth) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
                onChangeValidate("date_of_birth", e);
              }}
              onBlur={(e) => onChangeValidate("date_of_birth", e)}
              value={staffform.date_of_birth}
              defaulValue={staffform.date_of_birth}
            />
            {error && formErrors.date_of_birth ? (
              <span className="form-error">{formErrors.date_of_birth}</span>
            ) : (
              ""
            )}
            {/* <span className="form-error">Date of Joining is required!</span> */}
          </div>
        </div>

        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-tint"></i>Blood Group
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <DropdownWrapper className="input-block">
            <Select
              className=" blood-group"
              options={BloodOptions}
              onChange={(e) => {
                SetStaffForm(
                  { ...staffform, blood_group_id: e.value },
                  SetFormChange(true)
                );
                onChangeValidate("blood_group_id", e.value);
              }}
              onBlur={(e) =>
                onChangeValidate("blood_group_id", staffform.blood_group_id)
              }
            />
            {error && formErrors.blood_group_id ? (
              <span className="form-error">{formErrors.blood_group_id}</span>
            ) : (
              ""
            )}
            {/* <span className="form-error">Blood Group is required!</span> */}
          </DropdownWrapper>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-user-tag"></i>Designation
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                SetStaffForm({ ...staffform, designation: e.target.value });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
              }}
              value={staffform.designation}
              maxLength={1000}
            />
            {/* <span className="form-error">Designation is required!</span> */}
          </div>
        </div>

        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i class="fal fa-money-bill-alt"></i>Salary
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="input-block">
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                let newSalary = e.target.value.trim();
                newSalary = newSalary.replace(/[^0-9.]/g, "");
                let [integerPart, decimalPart] = newSalary.split(".");
                integerPart = integerPart.slice(0, 8);
                if (decimalPart !== undefined) {
                  decimalPart = decimalPart.slice(0, 3);
                }
                newSalary =
                  decimalPart !== undefined
                    ? `${integerPart}.${decimalPart}`
                    : integerPart;
                SetStaffForm({ ...staffform, salary: newSalary });
                SetFormChange(newSalary.length > 0);
              }}
              value={staffform.salary}
            />
          </div>
        </div>

        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-calendar"></i>Date of Joining
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="input-block">
            <DatePicker
              format="dd/MM/y"
              calendarIcon={null}
              clearIcon={null}
              className="form-control"
              onChange={(e) => {
                SetStaffForm({ ...staffform, date_of_joining: e });
                if (e && e !== staffform.date_of_joining) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
              }}
              value={staffform.date_of_joining}
            />
            {/* <span className="form-error">Date of Joining is required!</span> */}
          </div>
        </div>
        <div class="col-12 block-header main-title">
          <h6>
            <span class="header-title">ID Proof</span>
          </h6>
        </div>
        {/* ... Your other form elements ... */}
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-paste"></i>Type
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="upload-group">
            <div className="row m-0">
              <div className="col-md-8 upload-group-col">
                <DropdownWrapper className="input-block">
                  <Select
                    className="type"
                    options={IdOptions}
                    onChange={(e) => {
                      SetStaffForm(
                        { ...staffform, staff_id_proof_type: e.value },
                        SetFormChange(true)
                      );
                      setSelectedIdProofValue(
                        IdOptions?.filter((item) => item?.value === e.value)
                      );
                      onChangeValidate("staff_id_proof_type", e.value);
                    }}
                    onBlur={(e) =>
                      onChangeValidate(
                        "staff_id_proof_type",
                        staffform.staff_id_proof_type
                      )
                    }
                  />
                  {error && formErrors.staff_id_proof_type ? (
                    <span className="form-error">
                      {formErrors.staff_id_proof_type}
                    </span>
                  ) : (
                    ""
                  )}
                </DropdownWrapper>
              </div>
              <div className="col-md-4 p-0">
                {staffform.staff_id_proof_type ? (
                  <button
                    type="button"
                    className="btn-image"
                    onClick={props.handleShowID}
                    defaultValue={staffform.id}
                    onChange={(e) => {
                      SetStaffForm({ ...staffform, id: e.target.value });
                      if (e.target.value && e.target.value.length > 0) {
                        SetFormChange(true);
                      } else {
                        SetFormChange(false);
                      }
                      onChangeValidate("id", e.target.value);
                    }}
                    style={{ height: "38px" }}
                  >
                    <img src={uploadFileId} className="pdf-file" alt="" />
                    Upload
                    {staffform.id &&
                    staffform.staff_id_proof_type === "Choose..."
                      ? ""
                      : ` ${selectedIdProofValue?.[0]?.label}`}
                  </button>
                ) : (
                  <button
                    disabled
                    type="button"
                    className="btn-image"
                    onClick={props.handleShowID}
                    style={{ height: "38px" }}
                  >
                    <img src={uploadFileId} className="pdf-file" alt="" />
                    Upload
                  </button>
                )}
                <Idwrapper>
                  {error   && formErrors.staff_id_proof&& (
                    <span className="form-errorr">
                      {formErrors.staff_id_proof}
                    </span>
                  )}
                </Idwrapper>
              </div>

              {addProofSubmit && idproof && !idproof.length  &&selectedIdProofValue==[]&& (
                <div className="file-upload-view"></div>
              )}
            </div>
          </div>
        </div>
        <div class="col-12 block-header main-title">
          <div className="input-group input-group-custom">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i className="fal fa-calendar"></i>Expiry Date
                <sup className="theme-text">*</sup>
              </span>
            </div>
            <div className="input-block date-picker">
              <DatePicker
                minDate={minDateAllowed}
                format="dd/MM/y"
                calendarIcon={null}
                clearIcon={null}
                className="form-control"
                onChange={(e) => {
                  const year = e.getFullYear();
                  const month = String(e.getMonth() + 1).padStart(2, "0");
                  const day = String(e.getDate()).padStart(2, "0");
                  const selectedDate = `${year}-${month}-${day}`;
                  SetStaffForm({ ...staffform, expiry_date: selectedDate });
                  if (e && e !== staffform.expiry_date) {
                    SetFormChange(true);
                  } else {
                    SetFormChange(false);
                  }
                  onChangeValidate("expiry_date", selectedDate);
                }}
                onBlur={(e) => onChangeValidate("expiry_date", e.target.value)}
                value={staffform.expiry_date}
              />
              {error && formErrors.expiry_date ? (
                <span className="form-error">{formErrors.expiry_date}</span>
              ) : (
                ""
              )}
              {/* <span className="form-error">Date of Joining is required!</span> */}
            </div>
          </div>
          <h6>
            <span class="header-title">Emergency Contact Details</span>
          </h6>
        </div>

        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-user"></i>Name
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <NameInputWrapper className="input-block">
            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                SetStaffForm({
                  ...staffform,
                  emergency_details_name: e.target.value,
                });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
                onChangeValidate("emergency_details_name", e.target.value);
              }}
              onBlur={(e) =>
                onChangeValidate("emergency_details_name", e.target.value)
              }
              value={staffform.emergency_details_name}
              maxLength={100}
            />
            {error && formErrors.emergency_details_name ? (
              <span className="form-error">
                {formErrors.emergency_details_name}
              </span>
            ) : (
              ""
            )}
          </NameInputWrapper>
        </div>
        <div className="input-group input-group-custom">
          <div className="input-group-prepend">
            <span className="input-group-text">
              <i className="fal fa-phone"></i>Phone Number
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <div className="input-block">
            <div className="row m-0">
              <PhoneWrapper className="phone-wrapper">
                <Select
                  className="phone-extension"
                  options={phoneExtension}
                  value={
                    staffform.emergency_details_phone_extension
                      ? {
                          label:
                            phoneExtension.find(
                              (option) =>
                                option.value ===
                                staffform.emergency_details_phone_extension
                            )?.value ?? "",
                          value:
                            phoneExtension.find(
                              (option) =>
                                option.value ===
                                staffform.emergency_details_phone_extension
                            )?.value ?? "",
                        }
                      : phoneExtension.find(
                          (option) =>
                            option.value ===
                            staffform.emergency_details_phone_extension
                        )
                  }
                  onChange={(selectedOption) => {
                    SetStaffForm({
                      ...staffform,
                      emergency_details_phone_extension: selectedOption.value,
                    });
                    onChangeValidate("PhoneExtension_2", selectedOption.value);
                  }}
                  onBlur={() =>
                    onChangeValidate(
                      "PhoneExtension_2",
                      staffform.emergency_details_phone_extension
                    )
                  }
                />
                <input
                  type="tel"
                  className="form-control"
                  value={staffform.emergency_details_phone}
                  onChange={(e) => {
                    const phoneValue = e.target.value.replace(/\D/g, "");
                    SetStaffForm({
                      ...staffform,
                      emergency_details_phone: phoneValue,
                    });
                    SetFormChange(phoneValue.length > 0);
                    onChangeValidate("emergency_details_phone", phoneValue);
                  }}
                  onBlur={(e) =>
                    onChangeValidate("emergency_details_phone", e.target.value)
                  }
                  maxLength={15}
                />
              </PhoneWrapper>
              {error && formErrors.emergency_details_phone ? (
                <span className="form-error">
                  {formErrors.emergency_details_phone}
                </span>
              ) : (
                ""
              )}
              {/* <span className="form-error">emergencycontact Number is required!</span> */}
            </div>
          </div>
        </div>

        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-users"></i>Relation
              <sup className="theme-text">*</sup>
            </span>
          </div>
          <DropdownWrapper className="input-block">
            <Select
              className="relation"
              options={RelationOptions}
              onChange={(e) => {
                SetStaffForm(
                  { ...staffform, emergency_details_relation_type: e.value },
                  SetFormChange(true)
                );
                onChangeValidate("emergency_details_relation_type", e.value);
              }}
              onBlur={(e) =>
                onChangeValidate(
                  "emergency_details_relation_type",
                  staffform.emergency_details_relation_type
                )
              }
            />
            {error && formErrors.emergency_details_relation_type ? (
              <span className="form-error">
                {formErrors.emergency_details_relation_type}
              </span>
            ) : (
              ""
            )}
            {/* <span className="form-error">Relation is required!</span> */}
          </DropdownWrapper>
        </div>
        <div class="input-group input-group-custom">
          <div class="input-group-prepend">
            <span class="input-group-text">
              <i class="fal fa-map-marker-alt"></i>Emergency Address
              {/* <sup className="theme-text">*</sup> */}
            </span>
          </div>
          <div className="input-block">
            <textarea
              class="form-control"
              onChange={(e) => {
                SetStaffForm({
                  ...staffform,
                  emergency_details_address: e.target.value,
                });
                if (e.target.value && e.target.value.length > 0) {
                  SetFormChange(true);
                } else {
                  SetFormChange(false);
                }
              }}
              value={staffform.emergency_details_address}
            ></textarea>
            {/* <span className="form-error">Address is required!</span> */}
          </div>
        </div>
        <div class="col-12 text-end p-0 mt-3">
          <button
            class="btn btn-update"
            type="submit"
            disabled={!idproof.length && isSubmitting}
          >
            <i class="fal fa-save mr-2"></i>Save
          </button>
        </div>
      </form>
    </Wrapper>
  );
};

export default AddStaffForm;
const Wrapper = styled.div`
  .date-picker {
    .react-date-picker__inputGroup {
      cursor: pointer;
    }
    .react-date-picker__inputGroup__input {
      background: none !important;
    }
  }
  .css-t3ipsp-control {
    background-color: #e8e8e8;
    border: none !important;
    box-shadow: none;
  }
  .input-block {
    .css-1xc3v61-indicatorContainer {
      color: #666666 !important;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #e8e8e8 inset !important;
  }
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  textarea:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #e8e8e8 inset !important;
  }
`;
const NameInputWrapper = styled.div`
  .form-control {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    text-align: left !important;
  }
`;
const Idwrapper = styled.span`
  display: flex;
  height: max-content;
  width: max-content;
  .form-errorr {
    font-size: 13px;
    color: #f44336;
  }
`;
const PhoneWrapper = styled.div`
  display: flex;
  padding: 0 0 0 10px;
  .phone-extension {
    min-width: max-content;
    margin-left: -13px;
  }
  .phone-extension {
    > div:nth-of-type(2) {
      min-width: calc(100% + 100px);
      max-width: calc(100% + 100px);
    }
  }
  .phone-extension > div {
    background: #e8e8e8;
    min-height: 38px;
  }

  .form-control {
    margin-left: 5px;
  }

  .phone-extension > div {
    background: #e8e8e8;
    min-height: 38px;
    margin-left: 3px;
  }

  .css-1dimb5e-singleValue {
    text-overflow: unset;
  }
`;
const DropdownWrapper = styled.div`
  .gender > div {
    background: #e8e8e8;
  }
  .blood-group > div {
    background: #e8e8e8;
  }
  .type > div {
    background: #e8e8e8;
  }
  .relation > div {
    background: #e8e8e8;
  }
`;
const ImageWrapper = styled.div`
  .small-block .btn-image {
    margin: 10px;
    width: 150px;
  }
`;
