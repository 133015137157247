export const convertUrlToBlob = async (url) => {
  // Fetch the file content from the URL
  const response = await fetch(url);

  // Convert the response to a Blob
  const blob = await response.blob();

  return blob;
};

export const handleDownloadInvoice = (url) => {
  if (url) {
    convertUrlToBlob(url) //covert url to Blob
      .then((blob) => {
        // Extract filename from the URL
        const parts = url.split(/[\\/]/);
        const filename = parts[parts.length - 1];
        // Create a temporary anchor element
        const fileurl = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = fileurl;
        link.setAttribute("download", filename); // Set the filename
        // Append the anchor element to the body and remove after click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {});
  }
};
