import React from "react";
import { ProfileProvider } from "./ProfileContext";
import Layout from "../../Layout/Layout";
import ProfileWindow from "./ProfileWindow";
import ContentHeader from "../../Layout/Ui/ContentHeader";
import ProfileContentTitle from "./Ui/ProfileContentTitle";

const Profile = () => {
  return (
    <Layout>
      <ProfileProvider>
        <div className="content-block">
          <section className="main-section">
            <div className="container-fluid p-0">
              <ContentHeader />
              <div class="col-12 p-0">
                {/* <StaffFilterWrapper /> */}
                <ProfileContentTitle/>

                <div class="content-section" view-content="service">
                  <ProfileWindow
                  //   CloseAddStaff={SetAddStaff}
                  //   closeViewStaff={setViewStaff}
                  //   addstaff={addstaff}
                  //   viewStaff={viewStaff}
                  //   editstaff={editstaff}
                  //   openStaffEdit={handleEditView}
                  //   closeStaffEdit={SetEditStaff}
                  //   // deleteModal={deleteModal}
                  //   handleDeleteClick={handleDeleteClick}
                  />
                </div>
              </div>
            </div>
          </section>
        </div>
      </ProfileProvider>
    </Layout>
  );
};

export default Profile;
