import React  from 'react';
const StaffFilterDropdownContent = () => {
  return (
    <>
      <a className="dropdown-item" href="">
        All vehivles
      </a>
      <a className="dropdown-item" href="">
        Four wheelers only
      </a>
      <a className="dropdown-item" href="">
        Four wheelers only
      </a>
      </>
  );
}

export default StaffFilterDropdownContent;
