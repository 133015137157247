import React  from 'react';
import SuperUserSortDropdown  from "./SuperUserSortDropdown";
// import SuperUserFilterDropdown  from "./SuperUserFilterDropdownContent";

const FilterWrapper = () => {
    return(
        <div class="filter-wrapper">
            <div class="search-sort-filter">
                <div class="header-action-block">
                    <form action="" method="">
                        <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text search-span-txt">
                                <i class="fal fa-search"></i>
                                </span>
                            </div>
                            <input
                                type="text"
                                class="form-control"
                                id="search-id"
                                placeholder="Search"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                            />          
                        </div>
                    </form>
                    <div class="sort-filter">
                        <SuperUserSortDropdown />

                        {/* <SuperUserFilterDropdown />                     */}
                    </div>
                </div>
            </div>
        </div>
    );
}

    export default FilterWrapper;