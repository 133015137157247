import React, { useState } from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import dateFormat from "dateformat";
import styled from "styled-components";

const CustomTimePicker = ({ initialValue, onChange, disabled, label }) => {
  const parseTimeString = (timeString) => {
    const [hours, minutes] = timeString.split(":");
    const date = new Date();
    date.setHours(parseInt(hours, 10));
    date.setMinutes(parseInt(minutes, 10));
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };
  const [time, setTime] = useState(
    initialValue ? parseTimeString(initialValue) : new Date()
  );
  const handleTimeChange = (newTime) => {
    setTime(newTime);
    if (onChange && newTime instanceof Date && !isNaN(newTime.getTime())) {
      const formattedTime = dateFormat(newTime, "HH:MM");
      onChange(formattedTime);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledTimePicker
        label={label || "24 hours"}
        value={time}
        defaultValue={time}
        onChange={handleTimeChange}
        disabled={disabled}
        ampm={false}
      />
    </LocalizationProvider>
  );
};

export default CustomTimePicker;
const StyledTimePicker = styled(TimePicker)`
  &.MuiFormControl-root {
    width: 100% !important;
  }
  .MuiButtonBase-root {
    width: 100%;
    position: absolute;
    left: 0;
    opacity: 0;
  }
  .MuiInputBase-input {
    padding: 7px;
  }
  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  .MuiInputBase-root {
    background-color: #e8e8e8;
    border-radius: 3px !important;
  }
  .MuiFormLabel-root  {
    display: none;
  }
`;
