import React, { useEffect, useState } from "react";
// import StyledTextEditor from "../../../../StyledTextEditor";
import MultiSelectChip from "../../../../MultiSelectChip";
import FileUploader from "../../../../FileUploader";
import MuiPlaceholderDropDown from "../../../../MUiPlaceholderDropDown";
import styled from "styled-components";
// import { ProductListModel } from "../../../../../api/services/sap/vap/models/productListModel";
import SimpleReactValidator from "simple-react-validator";
import { MuiStyledTextField } from "../../../../MuiStyledTextField";
import MuiToggleSwitch from "../../../../MuiToggleSwitch";
import { FormLeftLabel, FormRightLabel } from "../../../../label";
import { productDetailsDataType } from "../../../../../api/services/sap/vap/models/createProductModel";
import { SelectChangeEvent } from "@mui/material";
import { dropDownModel } from "../Products";
import {
  getProductDetails,
  getProductMeasurement,
} from "../../../../../api/services/sap/product-management";
import { SaveButton } from "../../../../SaveButton";
import { toast } from "react-toastify";
export type ProductModel = {
  id: number;
  sku_id: string;
  product_name: string;
  show_product_in_store: boolean;
  stock: number;
  quantity_per_unit: number;
  price_per_unit: number;
  source: string | number;
  in_stock: boolean;
  min_stock_count: number;
  description: string;
  direction_of_use: string;
  brand?: {
    brand_id_pk: number;
    brand_name?: string;
  };
  product_measurement?: {
    lookup_data_id_pk: number;
    title: string;
    value: number;
  };
  attachment?: File;
  thumbnail?: File;
  measurement_unit?: number;
  stock_unit?: number;
};
type measureUnitModel = {
  stock_measure_id_pk: number;
  unit: string;
}[];

type Props = {
  datastored?: ProductModel;
  viewProduct: boolean;
  editButton?: boolean;
  addProduct?: boolean;
  SetMappedProductData?:
  | React.Dispatch<React.SetStateAction<any | undefined>>
  | undefined;
  productDetails?: productDetailsDataType;
  addProductDetails?: productDetailsDataType;
  SetAddProductDetails?: React.Dispatch<
    React.SetStateAction<productDetailsDataType>
  >;
  values?: dropDownModel;
  setFormSubmitted?: React.Dispatch<React.SetStateAction<boolean>>;
  mappedProductData?: ProductModel | undefined;
  validator?: SimpleReactValidator;
  validatorValue?: number;
  stockunit?: measureUnitModel | undefined;
  setMeasurementUnit?: React.Dispatch<React.SetStateAction<measureUnitModel>>
  measurementUnit?: measureUnitModel;
  setSelectedMeasurementUnit?:React.Dispatch<React.SetStateAction<string | number>>;
  selectedMeasurementUnit?:string | number;
};
const ProductForm = ({
  datastored,
  viewProduct,
  editButton,
  addProduct,
  SetMappedProductData,
  addProductDetails,
  SetAddProductDetails,
  values,
  mappedProductData,
  validator,
  stockunit,
  validatorValue,
  setMeasurementUnit,
  measurementUnit,
  setSelectedMeasurementUnit,
  selectedMeasurementUnit
}: Props) => {
  console.log(datastored, 'datastored');

  const [text, setText] = useState("No");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [files, setFiles] = React.useState<File[] | null>([]);

  console.log("fileeeesss", files);

  // const [measurementUnit, setMeasurementUnit] = useState<
  //   measureUnitModel | undefined
  // >([]);
  // const [selectedMeasurementUnit, setSelectedMeasurementUnit] = useState<string | number>("");
  console.log("fileeeesss", files);

  const simpleValidator = React.useRef(validator!);
  console.log(simpleValidator, "simpleValidator");

  // const [selectedMeasurementUnit, setSelectedMeasurementUnit] = useState<
  //   string | number
  // >("");

  const handleFileChange = (files: File[]) => {
    // Ensure only one file is selected
    if (files.length > 0) {
      setSelectedFile(files[0]);
    } else {
      setSelectedFile(null);
    }
  };
  console.log("dddd", datastored);
  const handletoggle = () => {
    // setText((prevText) => (prevText === "Yes" ? "No" : "Yes"));

    if (editButton && SetMappedProductData) {
      SetMappedProductData((prevState: any) => ({
        ...prevState,
        show_product_in_store: !prevState?.show_product_in_store,
      }));
    }
  };
  console.log("okkk", datastored);

  const handleMeasurementUnitChange = (
    event: SelectChangeEvent<string | number>) => {
    console.log(event.target.value, "eventttttttttttttttt");
    setSelectedMeasurementUnit?.(event.target.value ?? null);
    SetMappedProductData?.((prevData: any) => {
      const newData = {
        ...prevData,
        measurement_unit: event.target.value,
      };
      return newData;
    });

    console.log("measureee", selectedMeasurementUnit);
    getProductMeasurement(event.target.value)
      .then((res) => {
        if (res) {
          console.log("testttt", res);
          setMeasurementUnit?.(res);
        }
      })
      .catch((err) => {
        console.log(err, "response addProduct error");
      });
  };

  console.log("meeeee", measurementUnit);
  console.log("mknyfytfy", datastored);
  console.log("filessssss", files);


  // useEffect(() => {
  //   getProductMeasurement();
  // }, [measurementUnit]);
  useEffect(() => {
    setMeasurementUnit?.(stockunit ?? []);
  }, [stockunit, SaveButton]);

  console.log("valueeeeee", values?.brand);
  console.log(datastored?.show_product_in_store, "show_product_in_store");
  console.log("mappedprodata", mappedProductData);
  console.log("ddatta", datastored);

  const isDisabled = viewProduct ? (editButton ? false : true) : false;
  return (
    <div className="col-12">
      <form className="w-100" id="add-service">
        {/* Service Image */}
        <div className="input-group input-group-custom">
          <Textwrapper className="input-group-prepend   text-modifier product-image-div">
            <span className="input-group-text">
              {/* <i className="fal fa-images"></i> */}
              <div>
                <FormLeftLabel>Product images /videos</FormLeftLabel>
              </div>
            </span>
          </Textwrapper>
          <ImageDetailWrapper>
            {/* <div className="input-block"> */}
            {!viewProduct ? (
              <FileUploader
                multiple={true}
                setFiles={setFiles}
                defaultValue={files}

              />
            ) : (
              <div style={{ textAlign: "left" }}>N/A</div>
            )}
            {/* </div> */}

            {!viewProduct ? (
              <div className="right-section">
                <FormRightLabel>Add Product Images</FormRightLabel>
                <Underlinewrapper className="underline"></Underlinewrapper>

                <SmallTextFieldWrapper className="right-small-text">
                  <div className="smalltext-edit-custom">
                    <ul>
                      <li>Upload multiple images or videos</li>
                      <li>Resolution should be 1920 × 1080</li>
                      <li>File types can be jpeg, png, jpg, mp4</li>
                    </ul>
                  </div>
                </SmallTextFieldWrapper>
              </div>
            ) : (
              ""
            )}
          </ImageDetailWrapper>
        </div>

        <div className="input-group input-group-custom">
          <Textwrapper className="input-group-prepend text-modifier">
            <div className="smalltext-custom">
              <span className="input-group-text">
                {/* <i className="fal fa-user"></i> */}
                <ToggleFormLabelWrapper>
                  <FormLeftLabel>Show Product in Service</FormLeftLabel>

                </ToggleFormLabelWrapper>
              </span>
              <SmallTextFieldWrapper>
                <span className="smalltext-edit-custom"> [website]</span>
              </SmallTextFieldWrapper>
            </div>
          </Textwrapper>

          <Togglewrapper>
            <div className="input-block">
              <MuiToggleSwitch
                onChange={handletoggle}
                disabled={!editButton}
                checked={datastored?.show_product_in_store ? true : false}
              />
              <span>{datastored?.show_product_in_store ? "Yes" : "No"}</span>
            </div>
          </Togglewrapper>
        </div>

        <div className="input-group input-group-custom">
          <Textwrapper className="input-group-prepend text-modifier">
            <div className="smalltext-custom">
              <span className="input-group-text">
                {/* <i className="fal fa-user"></i> */}
                <FormLeftLabel className="heading-style">
                  Product Name
                </FormLeftLabel>

                <Starwrapper>
                  <sup className="theme-text">*</sup>
                </Starwrapper>
              </span>
              <SmallTextFieldWrapper>
                <span className="smalltext-edit-custom"> [store view]</span>
              </SmallTextFieldWrapper>
            </div>
          </Textwrapper>
          <TextFieldWrapper className="input-block">
            <MuiStyledTextField
              disabled={isDisabled}
              className="sup"
              value={datastored?.product_name ?? ""}
              onChange={(e) => {
                console.log(e.target.value, "eveee");
                SetMappedProductData?.((prevData: any) => {
                  const newData = {
                    ...prevData,
                    product_name: e.target.value,
                  };
                  return newData;
                });
              }}
              validations={"required"}
              validator={simpleValidator.current}
              name="Product Name"
              hideStar={true}
            />
          </TextFieldWrapper>
        </div>

        <div className="input-group input-group-custom">
          <Textwrapper
            className="input-group-prepend d-flex align-items-center text-modifier"
            style={{ maxHeight: "180px", minHeight: "29px" }}
          >
            <span className="input-group-text">
              {/* <i className="fal fa-images"></i> */}
              <FormLeftLabel className="heading-style">
                {" "}
                Thumbnail
              </FormLeftLabel>

              {/* <Starwrapper>
                <sup className="theme-text">*</sup>
              </Starwrapper> */}
            </span>
          </Textwrapper>
          <ImageDetailWrapper>
            {/* <div className="input-block"> */}

            {!viewProduct ? (
              <FileUploader thumbnail={true} />
            ) : (
              <div style={{ textAlign: "left" }}>N/A</div>
            )}
            {/* </div> */}

            {!viewProduct ? (
              <div className="right-section">
                <FormRightLabel>Add Product Images</FormRightLabel>
                <Underlinewrapper className="underline"></Underlinewrapper>
                <SmallTextFieldWrapper className="right-small-text">
                  <div className="smalltext-edit-custom">
                    <ul>
                      <li> Upload a single </li>
                      <li>image Resolution should be 1920 × 1080</li>
                      <li>File types can be jpeg, png, jpg</li>
                    </ul>
                  </div>
                </SmallTextFieldWrapper>
              </div>
            ) : (
              ""
            )}
          </ImageDetailWrapper>
        </div>

        <div className="input-group input-group-custom">
          <Textwrapper className="input-group-prepend text-modifier">
            <div className="smalltext-custom">
              <span className="input-group-text">
                {/* <i className="fal fa-user"></i> */}
                <FormLeftLabel className="heading-style"> SKU</FormLeftLabel>

                <Starwrapper>
                  <sup className="theme-text">*</sup>
                </Starwrapper>
              </span>
              <SmallTextFieldWrapper>
                <span className="smalltext-edit-custom"> [Global]</span>
              </SmallTextFieldWrapper>
            </div>
          </Textwrapper>
          <TextFieldWrapper className="input-block">
            <MuiStyledTextField
              disabled={viewProduct ? (editButton ? true : true) : false}
              className="sup"
              value={datastored?.sku_id ?? ""}
              onChange={(e) => {
                console.log(e.target.value, "eveee");
                SetMappedProductData?.((prevData: any) => {
                  const newData = {
                    ...prevData,
                    sku_id: e.target.value,
                  };
                  return newData;
                });
              }}
              validations={"required"}
              validator={simpleValidator.current}
              name="SKU"
              hideStar={true}
            />
          </TextFieldWrapper>
        </div>

        <div className="input-group input-group-custom">
          <Textwrapper className="input-group-prepend text-modifier">
            <div className="smalltext-custom">
              <span className="input-group-text">
                {/* <i className="fal fa-user"></i> */}
                <FormLeftLabel className="heading-style"> Price</FormLeftLabel>

                <Starwrapper>
                  <sup className="theme-text">*</sup>
                </Starwrapper>
              </span>
              <SmallTextFieldWrapper>
                <span className="smalltext-edit-custom"> [website]</span>
              </SmallTextFieldWrapper>
            </div>
          </Textwrapper>

          <PriceWrapper>
            <TextFieldWrapper className="input-block">
              <MuiStyledTextField
                disabled={isDisabled}
                className="sup"
                value={datastored?.price_per_unit || ""}
                type="number"
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^\d*\.?\d*$/.test(inputValue)) {
                    console.log(inputValue, "eveee");
                    SetMappedProductData?.((prevData: any) => {
                      const newData = {
                        ...prevData,
                        price_per_unit: inputValue,
                      };
                      return newData;
                    });
                  }
                }}
                validations={"required|numeric"}
                validator={simpleValidator.current}
                name="price"
                hideStar={true}
                onKeyPress={(e: any) => {
                  return console.log("Key ", e);
                }}
              />
            </TextFieldWrapper>
          </PriceWrapper>
        </div>
        {
          <div className="input-group input-group-custom">
            <Textwrapper className="input-group-prepend text-modifier">
              <div className="smalltext-custom">
                <span className="input-group-text">
                  {/* <i className="fal fa-user"></i> */}
                  <FormLeftLabel className="heading-style">
                    {" "}
                    Measurement Unit
                  </FormLeftLabel>
                  <Starwrapper>
                    <sup className="theme-text">*</sup>
                  </Starwrapper>
                </span>
                <SmallTextFieldWrapper>
                  <span className="smalltext-edit-custom"> [store view]</span>
                </SmallTextFieldWrapper>
              </div>
            </Textwrapper>
            <Dropdownwrappwer className="input-block dropdown-wapper">
              <WeightWrapper className="weight-wrapper">
                <MuiPlaceholderDropDown

                  datas={
                    values?.measurement?.map((measurement) => ({
                      id: measurement.value,
                      name: measurement.title,
                    })) ?? []
                  }
                  disabled={isDisabled}
                  value={datastored?.product_measurement?.value ?? ""}
                  onChange={handleMeasurementUnitChange}
                  validations={"required"}
                  validator={simpleValidator.current}
                  hideStar={true}
                  name="measurement unit"
                  unit="Select Measurement Unit"
                  defaultValue={datastored?.product_measurement?.value}
                />
              </WeightWrapper>
            </Dropdownwrappwer>
          </div>
        }

        {selectedMeasurementUnit !== 3 ? (
          <div className="input-group input-group-custom">
            <Textwrapper className="input-group-prepend text-modifier">
              <div className="smalltext-custom">
                <span className="input-group-text">
                  {/* <i className="fal fa-user"></i> */}
                  <FormLeftLabel className="heading-style">
                    {" "}
                    Stock Unit
                  </FormLeftLabel>

                  <Starwrapper>
                    <sup className="theme-text">*</sup>
                  </Starwrapper>
                </span>
                <SmallTextFieldWrapper>
                  <span className="smalltext-edit-custom"> [Global]</span>
                </SmallTextFieldWrapper>
              </div>
            </Textwrapper>

            <WeightWrapper>
              <TextFieldWrapper className="input-block">
                <MuiStyledTextField
                  disabled={isDisabled}
                  className="sup"
                  value={datastored?.quantity_per_unit || ""}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (/^\d*$/.test(inputValue)) {
                      console.log(e.target.value, "eveee");
                      SetMappedProductData?.((prevData: any) => {
                        const newData = {
                          ...prevData,
                          quantity_per_unit: e.target.value,
                        };
                        return newData;
                      });
                    }
                  }}
                  validations={"required|numeric"}
                  validator={simpleValidator.current}
                  name="stock unit"
                  hideStar={true}
                />
              </TextFieldWrapper>
              <MuiPlaceholderDropDown
                datas={
                  measurementUnit?.map((measurement) => ({
                    id: measurement.stock_measure_id_pk,
                    name: measurement.unit,
                  })) ?? []
                }
                value={datastored?.stock_unit ?? ""}
                onChange={(e) => {
                  console.log(e.target.value, datastored, "fhgfhfgvhvbgh");
                  SetMappedProductData?.((prevData: any) => {
                    const newData = {
                      ...prevData,
                      stock_unit: e.target.value,
                    };
                    return newData;
                  });
                }}
                disabled={isDisabled}
                validations={"required"}
                validator={simpleValidator.current}
                hideStar={true}
                name="stock unit"
                unit="Select Stock Unit"
              />
            </WeightWrapper>
          </div>
        ) : (
          ""
        )}

        <div className="input-group input-group-custom">
          <Textwrapper className="input-group-prepend text-modifier">
            <div className="smalltext-custom">
              <span className="input-group-text">
                {/* <i className="fal fa-user"></i> */}
                <FormLeftLabel className="heading-style"> Stock</FormLeftLabel>

                <Starwrapper>
                  <sup className="theme-text">*</sup>
                </Starwrapper>
              </span>
              <SmallTextFieldWrapper>
                <span className="smalltext-edit-custom"> [website]</span>
              </SmallTextFieldWrapper>
            </div>
          </Textwrapper>

          <PriceWrapper>
            <TextFieldWrapper className="input-block">
              <MuiStyledTextField
                disabled={isDisabled}
                className="sup"
                value={datastored?.stock || ""}
                onChange={(e) => {
                  console.log(e.target.value, "eveee");
                  const inputValue = e.target.value;
                  if (/^\d*$/.test(inputValue)) {
                    SetMappedProductData?.((prevData: any) => {
                      const newData = {
                        ...prevData,
                        stock: e.target.value,
                      };
                      return newData;
                    });
                  }
                }}
                validations={"required|numeric "}
                validator={simpleValidator.current}
                name="stock"
                hideStar={true}
              />
            </TextFieldWrapper>
          </PriceWrapper>
        </div>
        <div className="input-group input-group-custom">
          <Textwrapper className="input-group-prepend text-modifier">
            <div className="smalltext-custom">
              <span className="input-group-text">
                {/* <i className="fal fa-user"></i> */}
                <FormLeftLabel className="heading-style"> Brand</FormLeftLabel>

                <Starwrapper>
                  <sup className="theme-text">*</sup>
                </Starwrapper>
              </span>
              <SmallTextFieldWrapper>
                <span className="smalltext-edit-custom"> [store view]</span>
              </SmallTextFieldWrapper>
            </div>
          </Textwrapper>
          <Dropdownwrappwer className="input-block dropdown-wapper">
            <WeightWrapper className="weight-wrapper">
              <MuiPlaceholderDropDown
                datas={
                  values?.brand?.map((brand) => ({
                    id: brand.brand_id_pk,
                    name: brand.brand_name,
                  })) ?? []
                }
                unit="Select Brand"
                disabled={isDisabled}
                value={datastored?.brand?.brand_id_pk ?? ""}
                onChange={(e) => {
                  console.log(e.target.value, "lllljjk");
                  SetMappedProductData?.((prevData: any) => {
                    const newData = {
                      ...prevData, brand: {
                        brand_id_pk: e.target.value
                      }
                    };
                    return newData;

                  });
                }}
                validations={"required"}
                validator={simpleValidator.current}
                hideStar={true}
                name="brand"
              />
            </WeightWrapper>
          </Dropdownwrappwer>
        </div>
        <div className="col-12 block-header main-title mt-4">
          <h6>
            <FormLeftLabel className="text-modifier">
              <span className="header-title">Content</span>
            </FormLeftLabel>
          </h6>
        </div>
        <div className="input-group input-group-custom">
          <Textwrapper className="input-group-prepend text-modifier">
            <span className="input-group-text">
              <FormLeftLabel className="text-modifier">
                Short Description
              </FormLeftLabel>
            </span>
          </Textwrapper>
          <Textareawrapper className="input-block ">
            <textarea
              className="form-control"
              value={datastored?.description ?? ""}
              onChange={(e) => {
                console.log(e.target.value, "hfjfh");
                SetMappedProductData?.((prevData: any) => {
                  const newData = {
                    ...prevData,
                    description: e.target.value,
                  };
                  return newData;
                });
              }}
            // value={warrantyDetails.warranty_description}
            // readOnly={viewReadOnly}
            ></textarea>
          </Textareawrapper>
        </div>

        <div className="col-12 block-header main-title mt-4">
          <FormLeftLabel className="text-modifier">
            <span className="header-title">Attributes</span>
          </FormLeftLabel>
        </div>
        <div className="input-group input-group-custom">
          <Textwrapper className="input-group-prepend text-modifier">
            <span className="input-group-text">
              <FormLeftLabel className="text-modifier ">
                Direction of use
              </FormLeftLabel>
            </span>
          </Textwrapper>
          {/* <SubWrapper>
              <StyledTextEditor />
            </SubWrapper> */}
          <Textareawrapper className="input-block">
            <textarea
              className="form-control"
              value={datastored?.direction_of_use ?? ""}
              onChange={(e) => {
                console.log(e.target.value, "sdsdtrd");
                SetMappedProductData?.((prevData: any) => {
                  const newData = {
                    ...prevData,
                    direction_of_use: e.target.value,
                  };
                  return newData;
                });
              }}
            // value={warrantyDetails.warranty_description}
            // readOnly={viewReadOnly}
            ></textarea>
          </Textareawrapper>
        </div>
      </form>
    </div>
  );
};

export default ProductForm;

const Textwrapper = styled.div`
  &.sc-IqJVf.jsXnwG
    &.input-group-prepend
    &.d-flex
    &.align-items-center
    &.text-modifier {
    max-height: 180px !important;
    min-height: 29px !important;
  }
  &.text-modifier {
    text-align: right !important;
    max-width: 180px !important;
    min-width: 190px !important;
    /* max-height: 180px !important; */
    /* min-height: 180px !important; */
    width: calc(100% - 190px) !important;
    align-items: center;
  }
  &.product-image-div {
    max-height: 180px !important;
    min-height: 29px !important;
  }
  .smalltext-custom {
    width: 100%;
  }
  .heading-style {
    width: 100%;
    text-align: right;
  }
  .input-group-text {
    width: 100%;
  }
`;

const Togglewrapper = styled.div`
  .input-block {
    display: flex;
    align-items: center;
  }

  .yes-text {
    margin-left: 8px; /* Adjust the margin as needed */
  }
`;

const Textareawrapper = styled.div`
  &.input-block {
    width: calc(100% - 200px);
    text-align: left;
    /* max-width: 400px !important; */
    /* min-width: 300px !important; */
  }
`;

const Contenttexteditor = styled.div``;
const SubWrapper = styled.div`
  width: 100%;
  /* display: flex;
  flex-direction: column; */
`;
const PriceWrapper = styled.div`
  max-width: 180px;
  /* display: flex;
  flex-direction: column; */
`;
const WeightWrapper = styled.div`
  max-width: 600px;
  width: calc(100% - 160px);
  display: flex !important;
  align-items: center;
  /* flex-direction: column;  */
  &.weight-wrapper {
    margin: 20px 0px !important;
    max-height: 36px;
    /* max-width: 400px !important;
    min-width: 300px !important; */
    .MuiFormControl-root {
      margin: 0px !important;
    }
  }
  &.dropdown-wapper {
    max-width: 400px !important;
    min-width: 300px !important;
  }
  input {
    width: 250px;
  }
`;
const CatagoryWrapper = styled.div`
  width: 60%;
  display: flex !important;
  align-items: center;
`;
const SmallTextFieldWrapper = styled.div`
  .smalltext-edit-custom {
    color: grey;
    font-size: small;
  }
`;
const Starwrapper = styled.div`
  .theme-text {
    color: red;
  }
`;
const Underlinewrapper = styled.div`
  &.underline {
    max-width: 400px;
  }
`;
const TextFieldWrapper = styled.div`
  &.input-block {
    max-width: 600px !important;
    min-width: 300px !important;
    width: calc(100%-180);
  }
  .MuiInputBase-root {
    background-color: rgb(240, 240, 240) !important;
    padding: 0px !important;
  }
  .MuiTextField-root {
    padding: 0px !important;
    .theme-text {
      color: red !important;
    }
  }
`;
const ImageDetailWrapper = styled.div`
  display: block;
  align-items: center;
  .right-small-text {
    text-align: left;
  }
  width: calc(100% - 200px);
  .right-section {
    /* margin-left: 40px; */
    flex-direction: column;
    margin-top: 20px;
  }
  .input-block {
    /* display: flex; */
    align-items: center;
  }

  .yes-text {
    margin-left: 8px; /* Adjust the margin as needed */
  }

  .underline {
    margin-top: 3px !important;
    margin-bottom: 3px !important;
    background: #c5c5c5 !important;
    height: 1px;
  }
`;
const ToggleFormLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Dropdownwrappwer = styled.div`
  &.dropdown-wapper {
    max-width: 400px !important;
    min-width: 300px !important;
  }
`;
