import React, { useState, useEffect, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../../../../api/auth/sap/logout";
import { getProfileInfo } from "../../../../api/services/sap/profile-management";
import { ProfileContext } from "../../Pages/Profile/ProfileContext";
// import { profiledata } from "../profiledata";
import "./css/Profile.css";

function ProfileBlock(props) {
  const { innertab, SetinnerTab,userProfile, setUserProfile } = useContext(ProfileContext);
 
  const [user, setUser] = useState([]);
  const [newUser, setNewUser] = useState(false);

  const navigate = useNavigate();
  const logoutAction = () => {
    const returnedPromise = logout();
    returnedPromise.then((res) => {
      navigate("/");
    });
  };
  useEffect(() => {
    let userName = localStorage.getItem("username");
    let userId = localStorage.getItem("userid");
    let serviceCenterName = localStorage.getItem("serviceCenterName");
    setUser({
      username: userName,
      userid: userId,
      serviceCentername: serviceCenterName,
    });
  }, []);

  const profiledata = (id) => {
    const data = getProfileInfo(id);
    data.then((response) => {
      setUserProfile(response);
    })
    .catch((err) => {
      console.log(err);
    })
  };

  console.log(userProfile, "setUserProfilesetUserProfile");
  return (
    <div className="profile-main-block">
      <ul>
        <li>
          <NavLink to="/profile" className="link">
            <button className="btn" onClick={() => profiledata((user.userid))}>
              {userProfile && userProfile.service_center_name ? userProfile.service_center_name : user.serviceCentername && user.serviceCentername}
            </button>
          </NavLink>
        </li>
        <li>
          <button className="btn" onClick={logoutAction}>Logout</button>
        </li>
      </ul>
    </div>
  );
}

export default ProfileBlock;
