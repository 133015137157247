import Layout from "../../Layout/Layout";
import StaffInnerLoader from "./Ui/StaffInnerLoader";
import StaffWindowLeft from "./StaffWindowLeft";
import StaffWindowRight from "./StaffWindowRight";
import React, { useState, useContext, Fragment, useEffect } from "react";
import { StaffProvider } from "./StaffContext";
import StaffFilterWrapper from "./Ui/StaffFilterWrapper";
import StaffContentTitle from "./Ui/StaffContentTitle";
import ContentHeader from "../../../ServiceAdmin/Layout/Ui/ContentHeader";
import RiseLoader from "react-spinners/RiseLoader";
import mainLoader from "../../../../assets/img/loader/loader.gif";
const Staff = (props) => {

  //For Add
  const [addstaff, SetAddStaff] = useState(false);
  //For View
  const [viewStaff, setViewStaff] = useState(false);
  // For Edit
  const [editstaff, SetEditStaff] = useState(false);
  //Delete Modal
  // const [deleteModal, SetDeleteModal] = useState(false);
  const [pageloader, SetPageLoader] = useState(false);
 
  //Add staff
  const handleAddStaff = (e) => {
    e?.stopPropagation();

    SetAddStaff(true);

    setViewStaff(false);

    SetEditStaff(false);

   

  };

  //View staff
  const HandleViewStaff = () => {
    setViewStaff(true);
    SetAddStaff(false);
    SetEditStaff(false);

  };

  //Edit staff
  const handleEditView = () => {
    SetEditStaff(true);
    SetAddStaff(false);
    setViewStaff(false);
    
  };
  //Staff delete confirm modal
  const handleDeleteClick = (e) => {
    e.stopPropagation();
    // SetDeleteModal(true);
  };
  

  let toggleClassCheck;
  if (addstaff || viewStaff || editstaff) {
    toggleClassCheck = " change";
  }

 
  useEffect(() => {
    SetPageLoader(true);
    const loaderTime = setTimeout(() => {
      SetPageLoader(false);
    }, 1500);
    return () => {
      clearTimeout(loaderTime);
    };
  }, []);

  return (
    <Fragment>
      {pageloader ? (
        <div className="loader-block">
          <img className="img-fluid" src={mainLoader} alt="" />
        </div>
      ) : (
        <Layout>
          <div className="content-block">
            <StaffInnerLoader />

            <section className="main-section">
              <div className="container-fluid p-0">
                <ContentHeader />
                <div class="col-12 p-0">
                  {/* <StaffFilterWrapper /> */}
                  <StaffProvider>
                    <StaffContentTitle handleAdd={handleAddStaff} />

                    <div class="content-section" view-content="service">
                      <div className={`content-window ${toggleClassCheck}`}>
                        <StaffWindowLeft
                          showDeleteModal={handleDeleteClick}
                          viewStaffClick={HandleViewStaff}
                        />
                        <StaffWindowRight
                          CloseAddStaff={SetAddStaff}
                          closeViewStaff={setViewStaff}
                          addstaff={addstaff}
                          viewStaff={viewStaff}
                          editstaff={editstaff}
                          openStaffEdit={handleEditView}
                          closeStaffEdit={SetEditStaff}
                          handleDeleteClick={handleDeleteClick}
                          handleAdd={handleAddStaff}
                        />
                      </div>
                    </div>
                  </StaffProvider>
                </div>
              </div>
            </section>
          </div>
        </Layout>
      )}
    </Fragment>
  );
};

export default Staff;

