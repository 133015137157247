import React, { FC } from "react";
import { Modal, Container, Row } from "react-bootstrap";
import styled from "styled-components";

interface CustomVideoPlayerPopupProps {
  showVideoPlayer: boolean;
  videoSrc?: string;
  handleClose: () => void;
}

const CustomVideoPlayerPopup: FC<CustomVideoPlayerPopupProps> = ({
  showVideoPlayer,
  videoSrc,
  handleClose,
}) => {
  return (
    <Modal
      className="file-modal"
      show={showVideoPlayer}
      size="lg"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Container>
          <HeadingWrapper>
            <Title>Media Player</Title>
            <CloseButton onClick={handleClose}>
              <i className="fal fa-times"></i>
            </CloseButton>
          </HeadingWrapper>
          <VideoRow>
            {videoSrc && (
              <StyledVideo src={videoSrc} controls height="100%" width="100%" />
            )}
          </VideoRow>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default CustomVideoPlayerPopup;

const HeadingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1rem 0rem 1rem;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: #000;

  &:hover {
    color: red;
  }
`;

const Title = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  flex-grow: 1;
  text-align: start;
`;

const VideoRow = styled(Row)`
  display: flex;
  justify-content: center;
  padding: 1rem 1rem 1.5rem 1rem;
`;

const StyledVideo = styled.video`
  max-width: 100%;
  max-height: 70vh;
  object-fit: contain;
`;
