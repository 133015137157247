// import {ContentHeader,FilterWrapper,ContentTitle,WindowLeft,WindowRight,InnerLoader} from './Ui';
import React  from 'react';
const CustomerInnerLoader = () => {  
  
  return (
        <div className="inner-loader">
            <div className="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        );
    };

export default CustomerInnerLoader;
