import React, { Fragment, useEffect, useRef, useState } from "react";
import Layout from "../../Layout/Layout";
import InnerLoader from "../Service/Ui/ServiceInnerLoader";
import ContentHeader from "../../Layout/Ui/ContentHeader";
import ProductContentTitle from "./ProductContentTitle";
import MuiProductCard from "../../../MuiProductCard";
import WindowLeft from "./ProductWindowLeft";
import WindowRight from "./ProductWindowRight";
import configData from "../../../../config.json";

import {
  createProduct,
  getProductDropdown,
  getProductList,
  getProductMeasurement,
} from "../../../../api/services/sap/product-management";
import { getProductDetails } from "../../../../api/services/sap/product-management";
import { ProductListModel } from "../../../../api/services/sap/vap/models/productListModel";
import { ProductDetailsModel } from "../../../../api/services/sap/vap/models/productDetailsModel";
import { GridCallbackDetails, GridRowParams } from "@mui/x-data-grid";
import { productDetailsDataType } from "../../../../api/services/sap/vap/models/createProductModel";
import { ProductModel } from "./ProductForm";

type Props = {};

const addProductDefaultValue: productDetailsDataType = {
  product_name: "",
  product_attachment: undefined,
  thumbnail: undefined,
  sku_id: "",
  price_per_unit: null,
  brand_id: null,
  description: "",
  direction_of_use: "",
  vendor_sku_id: "",
  type: 1,
  source: null,
  stock: 1,
  quantity_per_unit: 1,
  stock_unit: null,
  measurement_unit: null,
  show_product_in_store: false,
  in_stock: true,
  min_stock_count: 1,
};

export type dropDownModel = {
  [x: string]: any;
  brand: Array<{ brand_id_pk: number; brand_name: string }>;
  measurement: Array<{
    lookup_data_id_pk: number;
    title: string;
    value: number;
  }>;
};

const Products = (props: Props) => {
  const [addProduct, setAddProduct] = useState(false);
  const [rightView, setRightView] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [Product, setProduct] = useState(false);
  const [editButton, setEditButton] = useState<boolean>(false);
  const [isEditMode, setEditMode] = useState(false);
  const [productliststatus, setProductListstatus] = useState(false);
  const [productList, setProductList] = useState<ProductListModel[]>([]);
  const [viewProduct, SetViewProduct] = useState(false);
  const [mappedProductData, SetMappedProductData] = useState<ProductModel>();

  const [addProductDetails, SetAddProductDetails] =
    useState<productDetailsDataType>(addProductDefaultValue);
  const [values, setValues] = useState<dropDownModel>();
  const [PageLoader, setPageLoader] = React.useState(false);
  const [stockunit, setStockUnit] = useState<measureUnitModel>([]);
  const [selectedMeasurementUnit, setSelectedMeasurementUnit] = useState<
    string | number
  >("");


  type measureUnitModel = {
    stock_measure_id_pk: number;
    unit: string;
  }[];

  const handleGridViewClick = () => {
    setShowGrid(!showGrid);
    setEditButton(false);
  };
  const selectedRowRef = useRef<any>(null);
  let toggleClassCheck = rightView ? " change" : "";

  const handleEditClick = () => {
    console.log("first");

    setEditButton(true);
    setEditMode(!isEditMode);
  };

  const handleAddProductClick = () => {
    setRightView(true);
    setAddProduct(true);
    setProduct(false);
    SetViewProduct(false);
    SetMappedProductData?.(undefined);
    setEditButton(true);
    SetAddProductDetails(addProductDefaultValue);

    getProductDropdown()
      .then((res) => {
        if (res) {
          console.log("check...s", res);
          setValues(res);
        }
      })
      .catch((err) => {
        console.log(err, "response addProduct error");
      });
  };
  console.log("vaalll", values);

  const handleCloseRight = () => {
    setRightView(false);
    setAddProduct(true);
    setEditButton(false);

    //  removeSelectedRowAttr();
  };
  const handleViewRight = () => setRightView(true);

  function removeSelectedRowAttr() {
    const allTableRows = document.querySelectorAll(".MuiDataGrid-row");
    allTableRows.forEach((rowElement) => {
      rowElement.removeAttribute("row-selected");
    });
  }
  function handleGetProductList(params?: number | null) {
    console.log("pppp", params);
    getProductList(params)
      .then((res) => {
        if (res) {
          setAddProduct(false);
          setProductList(
            res?.map((item: any, i: number) => {
              if (item.thumbnail?.attachment) {
                item.thumbnail.attachment =
                  "uploads/product-attachments/" + item.thumbnail?.attachment;
              }
              console.log(item, "itmz");
              return { ...item, index: i + 1 };
            })
          );
        }
      })
      .catch((err) => {
        console.log(err, "response product list");
      });
  }

  useEffect(() => {
    handleGetProductList();
  }, [productliststatus]);

  const handleRowClick = (
    e: GridRowParams,
    event: any,
    details?: GridCallbackDetails
  ) => {
    //Set selected row
    if (event) {
      selectedRowRef.current = event.target.closest(".MuiDataGrid-row");

      // Check if the closest parent element is found
      if (selectedRowRef.current) {
        // Remove the "row-selected" Attribute from all row elements
        removeSelectedRowAttr();
        // Append a new row-selected Atribute to the closest parent element
        selectedRowRef.current.setAttribute("row-selected", "true");
      }
    }
    let product_id = e.row.id;
    handleGetProductdetails(product_id);
    setEditButton?.(false);
    setEditMode?.(false);

    // vendor id from  row clicked
    // handleCarddetails(e);
  };

  const handleGetProductdetails = async (product_id: number | undefined) => {
    setAddProduct(false);
    console.log("gyfrhrhhbnf");
    getProductDropdown()
      .then((res) => {
        if (res) {
          setValues(res);
        }
      })
      .catch((err) => {
        console.log(err, "response addProduct error");
      });
    getProductDetails(product_id)
      .then(async (res) => {
        if (res) {
          SetMappedProductData(res);
          setSelectedMeasurementUnit(res?.measurement_unit)
          getProductMeasurement(res?.product_measurement?.value).then(
            (res2) => {
              setStockUnit(res2);
            }
          );
          handleViewRight();
          SetViewProduct(true);
        }
      })
      .catch((err) => {
        console.log(err, "response product list");
      });
  };


  return (
    <Fragment>
      {
        <Layout>
          <div className="content-block">
            <InnerLoader />
            <section className="main-section">
              <div className="container-fluid p-0">
                <ContentHeader />
              </div>
              <div className="col-12 p-0">
                <ProductContentTitle
                  productAddClick={handleAddProductClick}
                  gridViewClick={handleGridViewClick}
                  showGrid={showGrid}
                />
                <div className="content-section" view-content="service">
                  <div className={`content-window ${toggleClassCheck} `}>
                    <WindowLeft
                      gridViewClick={handleGridViewClick}
                      showGrid={showGrid}
                      rightView={rightView}
                      tableDatas={productList}
                      ProductViewClick={handleRowClick}
                      // SetViewProduct={SetViewProduct}
                      viewProduct={viewProduct}
                      handleCarddetails={handleGetProductdetails}
                      mappedProductData={mappedProductData}
                      setEditButton={setEditButton}
                      setEditMode={setEditMode}
                      pageLoader={PageLoader}
                      setPageLoader={setPageLoader}
                    />
                    {rightView && (
                      <WindowRight
                        closeView={handleCloseRight}
                        rightView={rightView}
                        viewProduct={viewProduct}
                        setViewproduct={SetViewProduct}
                        tableDatas={productList}
                        SetMappedProductData={SetMappedProductData}
                        datastored={mappedProductData}
                        editButton={editButton}
                        setEditButton={setEditButton}
                        addProduct={addProduct}
                        onEditClick={handleEditClick}
                        isEditMode={isEditMode}
                        setEdithMode={setEditMode}
                        addProductDetails={addProductDetails}
                        SetAddProductDetails={SetAddProductDetails}
                        values={values}
                        setProductListstatus={setProductListstatus}
                        pageLoader={PageLoader}
                        setPageLoader={setPageLoader}
                        handleCarddetails={handleGetProductdetails}
                        setEditMode={setEditMode}
                        stockunit={stockunit}
                        mappedProductData={mappedProductData}
                        setSelectedMeasurementUnit={setSelectedMeasurementUnit}
                        selectedMeasurementUnit={selectedMeasurementUnit}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Layout>
      }
    </Fragment>
  );
};

export default Products;
