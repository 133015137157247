import React from "react";

type Props = {
  handleAddClick?: () => void;
  buttonTitle?: string;
  heading?: string;
};

const ContentTitleWithButton = ({
  handleAddClick,
  buttonTitle,
  heading,
}: Props) => {
  return (
    <div className="content-title-action">
      <div className="title-action vendor">
        <div className="title-action-left">
          <h5 className="head">{heading}</h5>
          {buttonTitle && (
            <div className="action-block">
              <button
                className="btn add-new"
                id="addBtn"
                rel="tooltip"
                data-toggle="tooltip"
                data-placement="left"
                title="Add New"
                onClick={handleAddClick}
              >
                <span>
                  <i className="fal fa-plus"></i> {buttonTitle}
                </span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContentTitleWithButton;
