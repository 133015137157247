import { sapAxios } from "../../axios";

export const logout = async () => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: 'logout',
            data: {},
        });
        if (res.status) {
            localStorage.removeItem('sapAuthToken');
            localStorage.setItem('isSAPAuthenticated', false);
            return res;
        }
        return Promise.reject(res);
    } catch (err) {
        console.log(err);
        return err;
    }
}
