import React, { useContext } from "react";
import { ServiceContext } from "../ServiceContext";
import "../../../Pages/Service/Ui/css/ServiceModal.css";
import exitConfirmation from "../../../../../assets/img/icon/upload/file-backup.svg";
import { Modal } from "react-bootstrap";

function ConfirmModal(props) {
  const {
    mappedServiceData,
    table,
    SetMappedServiceData,
    SetEditMode,
    SetViewReadOnly,
    setIsUpdateForm,
    SetFormChange,
  } = useContext(ServiceContext);

  const closeConfirm = (e) => {
    props.serviceFormClose(false);
    SetViewReadOnly(false);
    SetEditMode(false);
    setIsUpdateForm(false);
    SetMappedServiceData({});
    SetFormChange(false);
  };

  const onConfirm = (e) => {
    props.setConfirmModal(false);
  };

  return (
    <>
      {/* <div className="action-modal">
        <div className="d-flex justify-content-end">
          <button className="btn close-btn" onClick={onConfirm}>
            <i className="fal fa-times"></i>
          </button>
        </div>
        <div className="modal-info-img">
          <img src={exitConfirmation} className="file-exit" alt="" />
        </div>
        <h5>R you sure you want to exit?</h5>
        <div className="d-flex justify-content-center pt-3">
          <button className="btn mr-3 btn-cancel " onClick={onConfirm}>
            Cancel
          </button>
          <button class="btn btn-danger" onClick={closeConfirm}>
            Confirm<i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
          </button>
        </div>
      </div> */}
      <Modal
        className="custom-modal"
        show={props.setConfirmModal}
        onHide={onConfirm}
        centered
      >
        <Modal.Header closeButton={() => onConfirm}>
          <div className="modal-info-img">
            <img src={exitConfirmation} className="file-exit" alt="" />
          </div>
        </Modal.Header>
        <Modal.Body>
          <h5>Are you sure you want to exit?</h5>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center pt-3">
            <button className="btn mr-3 btn-cancel " onClick={onConfirm}>
              Cancel
            </button>
            <button class="btn btn-danger" onClick={closeConfirm}>
              Confirm<i class="fal fa-angle-right ml-2" aria-hidden="true"></i>
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmModal;
