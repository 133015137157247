import React, { useContext } from "react";
import { ApprovalContext } from "../ApprovalContext";
import "./css/ServiceModal.css";
import { toast } from "react-toastify";
function ServiceModal(props) {
  const {
    deleteServiceAction,
    table,
    SetTable,
    mappedServiceData,
    SetMappedServiceData,
    SetViewReadOnly,
    handleServiceDelete,
  } = useContext(ApprovalContext);

  const deleteConfirm = (e) => {
    toast.error("Deleted Successfully", {
      className: "error-toast",
      position: "bottom-right",
    });
    const obj = table.filter((p) => p.id == props.id);
    handleServiceDelete(obj[0].id);
    // alert("hai");
  };
  return (
    <div className="delete-modal">
      <div className="d-flex justify-content-end">
        <button className="btn" onClick={() => props.SetDeleteModal(false)}>
          <i className="fal fa-times"></i>
        </button>
      </div>
      <div className="">Are you sure you want to delete?</div>

      <div className="d-flex justify-content-center pt-3">
        <button
          className="btn mr-3 btn-cancel"
          onClick={() => props.SetDeleteModal(false)}
        >
          Cancel
        </button>
        <button
          className="btn mr-3 btn-danger"
          onClick={() => {
            deleteServiceAction(props.id);
          }}
        >
          Continue
        </button>
      </div>
    </div>
  );
}

export default ServiceModal;
