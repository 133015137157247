import React, { useState } from "react";
import Popover from "@mui/material/Popover";

const ServiceSortDropdown = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div className="sort-dropdown">
      <div className="dropdown sort-custom-dropdown">
        <button
          className="btn btn-secondary dropdown-toggle"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <span className="service-sort-btn sort-btn">
            Sort <i className="fal fa-sort"></i>
          </span>
        </button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <a className="dropdown-item" href="#">
            Recent
          </a>
          <a className="dropdown-item" href="#">
            Name
          </a>
          <a className="dropdown-item" href="#">
            Price low
          </a>
          <a className="dropdown-item" href="#">
            Price high
          </a>
          <a className="dropdown-item" href="#">
            oldest
          </a>
        </Popover>
      </div>
    </div>
  );
};

export default ServiceSortDropdown;
