import { AxiosResponse } from "axios";
import { vapAxios } from "../../axios";
import { ResponseModel } from "./models/responseModels";

const sendOTPforPhone = async (data?: any) => {
  const axiosInst = vapAxios(); //sup Axios change it to
  const url = "vap/register/phone-otp"; // url
  try {
    const res = await axiosInst({
      method: "POST",
      url,
      data,
    });
    console.log(res);
    if (res?.data?.status) {
      return res.data;
    }
    throw new Error(res?.data?.message);
  } catch (err) {
    console.log(err);
    return err;
  }
};

const verifyPhoneOtp = async (data?: any): Promise<ResponseModel> => {
  const axiosInst = vapAxios(); //sup Axios change it to
  const url = "/vap/register/verify-phone-otp"; // url
  try {
    const res: AxiosResponse<ResponseModel> = await axiosInst({
      method: "POST",
      url,
      data,
    });
    if (res?.data?.status) {
      return Promise.resolve(res.data);
    }
    throw new Error(res?.data?.message);
  } catch (err) {
    console.log(err);
    throw err;
  }
};
const sendOTPforEmail = async (data?: any) => {
  const axiosInst = vapAxios(); //sup Axios change it to
  const url = "/vap/register/email-for-otp"; // url
  try {
    const res = await axiosInst({
      method: "POST",
      url,
      data,
    });
    console.log(res);
    if (res?.data?.status) {
      return res.data;
    }
    throw new Error(res?.data?.message);
  } catch (err) {
    console.log(err);
    return err;
  }
};
const verifyEmailOtp = async (data?: any): Promise<ResponseModel> => {
  const axiosInst = vapAxios(); //sup Axios change it to
  const url = "/vap/register/verify-email-otp"; // url
  try {
    const res: AxiosResponse<ResponseModel> = await axiosInst({
      method: "POST",
      url,
      data,
    });
    console.log(res);
    if (res?.data?.status) {
      return Promise.resolve(res.data);
    }
    throw new Error(res?.data?.message);
  } catch (err) {
    console.log(err);
    throw err;
  }
};

  const vendorDetails = async (data?: any) => {
    console.log(data,"datappp");
    
    const axiosInst = vapAxios(); //sup Axios change it to
    const url = "/vap/registration"; // url
    try {
      const res = await axiosInst({
        method: "POST",
        url,
        data,
      });
      console.log(res);
      if (res.status) {
        return res.data;
      }
      throw new Error(res.statusText);
    } catch (err) {
      console.log(err);
      return err;
    }
  };
  
export {sendOTPforPhone, verifyPhoneOtp, sendOTPforEmail, verifyEmailOtp, vendorDetails};
