import { sapAxios } from "../../axios";


const createStaff = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: 'staff',
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}

const getStaffList = async (id = undefined) => {
    const axiosInst = sapAxios();
    const url = id ? ('get-staff/' + id) : 'get-staff-list';
    try {
        const res = await axiosInst({
            method: 'GET',
            url,
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

const deleteStaff = async (id = undefined) => {
    const axiosInst = sapAxios();
    const url = 'staff/delete/' + id ;
    try {
        const res = await axiosInst({
            method: 'GET',
            url,
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
        return Promise.reject(err);
    }
}

const createStaffLoginCridential = async (data) => {
    const axiosInst = sapAxios();
    try {
        const res = await axiosInst({
            method: 'POST',
            url: 'staffLogin',
            data
        });
        if (res.status) {
            return res.data;
        }
        return Promise.reject(res);
    } catch (err) {
    }
}

export { createStaff,getStaffList,deleteStaff,createStaffLoginCridential };