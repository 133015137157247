import React from "react";
import styled from "styled-components";
// import { FeatureResponseData } from "../index";
import { FeatuerdListModel } from "../model/featuredModels";

type FeatureListProps = {
  features: FeatuerdListModel[];
  onViewClick?: (event: React.MouseEvent<HTMLDivElement>, id: number) => void;
  className?: string;
};

const FeatureListCard: React.FC<FeatureListProps> = ({
  features,
  onViewClick,
  className,
}) => {
  return (
    <CardContainer className={className}>
      {features.map((feature, index) => (
        <Card
          key={index}
          style={{
            background: `url(${feature.imageUrl}) center/cover no-repeat`,
          }}
          onClick={(e) => onViewClick?.(e, feature.featuredId)}
        >
          <CardTitle>{feature.name}</CardTitle>
        </Card>
      ))}
    </CardContainer>
  );
};

export default FeatureListCard;

const CardContainer = styled.div`
  display: grid;
  gap: 25px;
  place-items: center;
  padding: 10px 30px 20px 30px;
  grid-template-columns: repeat(4, 1fr);
  @media (max-width: 575.98px) {
    grid-template-columns: repeat(1, 1fr);
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1200px) and (max-width: 1365px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1366px) and (max-width: 1439px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const Card = styled.div`
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  width: 250px;
  height: 250px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
  }
`;

const CardTitle = styled.h3`
  background: #ffffff91;
  position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
  padding:5px;
  margin: 0;
  font-size: 18px;
  color: #333;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.2em; /* 2 * line-height to show two lines */
  word-wrap: break-word; /* This allows breaking words */
  word-break: break-all; /* This ensures the word breaks if it's too long */
`;
