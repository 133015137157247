import React, { useState } from "react";

const LoginForm = ({ Login, error }) => {
  const [details, setDetails] = useState({ username: "", password: "" });

  const submitHandler = (e) => {
    e.preventDefault();
    Login(details);
  };
 

  return (
    <div className="LoginForm">
      <form onSubmit={submitHandler} className="mt-4" action="">
        <div className="form-group">
          <input
            onChange={e => setDetails({...details , username: e.target.value})}
            value={details.username} 
            type="text"
            className="form-control mb-0"
            id="username"
            name="username"
            placeholder="admin@gmail.com"
            autoComplete="off"
            required=""
          />
        </div>
        <div className="form-group">
          <input
            onChange={e => setDetails({...details , password: e.target.value})}
            value={details.password} 
            type="password"
            className="form-control mb-0"
            id="password"
            name="password"
            placeholder="Password"
            required=""
          />
        </div>

        { (error != "") ? <div className="error text-danger text-center">{error}</div> : ""}

        <div className="sign-info mt-4">
          {/* <button
            onClick={() => { navigate("./home");}}
            type="submit"
            className="btn sign-in-btn"
          >
            Login
          </button> */}
          <button type="submit" className="btn sign-in-btn">Login</button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;