import React, { FC, useEffect, useState } from "react";
import uploadImageFile from "../../../../../assets/img/icon/upload/photos.svg";
import CustomFileUploadModal from "./CustomFileUploadModal";
import styled from "styled-components";
import CustomVideoPlayerPopup from "./CustomVideoPlayerPopup";
interface ImageMedia {
  base64: string;
  type: "image";
}

interface VideoMedia {
  videoSrc: string;
  type: "video";
}

type Media = ImageMedia | VideoMedia;
interface CustomFileUploadProps {
  showImage?: string;
  viewonly?: boolean;
  allowVideo?: boolean;
  handleRemoveDefaultImage?: React.MouseEventHandler<HTMLButtonElement>;
  handleRemoveFile?: React.MouseEventHandler<HTMLButtonElement>;
  setImage: React.Dispatch<
    React.SetStateAction<
    (
      | {
          base64: string;
          type: "image";
        }
      | { videoSrc: string; type: "video" }
    )[]
    >
  >;
  onImageConfirmClick?: (media: Media[], blob: Blob) => void;
  image?:  (
    | {
        base64: string;
        type: "image";
      }
    | { videoSrc: string; type: "video" }
  )[];
}

const CustomFileUpload: FC<CustomFileUploadProps> = ({
  showImage,
  setImage,
  image,
  viewonly,
  allowVideo,
  handleRemoveDefaultImage,
  onImageConfirmClick,
  handleRemoveFile,
}) => {
  const [showFileModal, setShowFileModal] = useState(false);
  const [videoPopUp, setVideoPopUp] = useState(false);
  const [pic, setPic] = useState<
    (
      | {
          base64: string;
          type: "image";
        }
      | { videoSrc: string; type: "video" }
    )[]
  >([]);
console.log();

  const handleShowImageModal = () => {
    setShowFileModal(true);
  };

  const hideFileModal = (show: boolean) => {
    setShowFileModal(show);
  };

  const handleImgRemove = (e: any, index: number) => {
    const newPic = pic.filter((e, i) => i !== index);
    setPic(newPic);
    setImage?.(newPic);
    handleRemoveFile?.(e);
  };
  useEffect(() => {
    setPic(image!);
  }, [image]);
  const handleVideoClick = () => {
    setVideoPopUp(true);
  };

  const handleVideoClose = () => {
    setVideoPopUp(false);
  };

  const getVideoSrc = () => {
    if (pic?.length > 0 && pic[0].type === "video") {
      return pic[0].videoSrc;
    }
    return undefined;
  };

  return (
    <>
      <div className="input-group input-group-custom">
        <div className="small-block">
          {showImage ? (
            <div className="row-img row">
              <div className="col-img-list">
                <div className="img-list">
                  {!viewonly && (
                    <button
                      type="button"
                      className="btn"
                      onClick={handleRemoveDefaultImage}
                    >
                      <i className="fal fa-times"></i>
                    </button>
                  )}
                  <img
                    src={showImage}
                    className="img-fluid"
                    alt=""
                    height="100"
                    width="100"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="row-img row">
              {pic &&
                pic.map((photo, index) => (
                  <div className="col-img-list" key={index}>
                    <div className="img-list">
                      {!viewonly && (
                        <button
                          className="btn"
                          onClick={(e) => handleImgRemove(e, index)}
                        >
                          <i className="fal fa-times"></i>
                        </button>
                      )}
                      {photo.type === "image" ? (
                        <img
                          src={photo.base64}
                          className="img-fluid"
                          alt="asdasdsadasdadadadadadqwer324r34wr55r"
                          height="100"
                          width="100"
                        />
                      ) : (
                        <VideoWrapper>
                          <video
                            src={photo.videoSrc}
                            height="100%"
                            width="100%"
                            className="imported-video"
                          />
                          <div
                            onClick={handleVideoClick}
                            className="play-cover"
                          >
                            <i className="fas fa-play"></i>
                          </div>
                        </VideoWrapper>
                      )}
                    </div>
                  </div>
                ))}
              {!pic || pic.length === 0 ? (
                <button
                  type="button"
                  className="btn-image"
                  onClick={handleShowImageModal}
                  style={{ width: "30%" }}
                  disabled={viewonly}
                >
                  <div className="upload-section justify-content-center">
                    <div className="upload-img">
                      <img src={uploadImageFile} alt="loading..." />
                    </div>
                  </div>
                  {!viewonly ? "Upload" : ""}
                </button>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
      <CustomVideoPlayerPopup
        showVideoPlayer={videoPopUp}
        videoSrc={getVideoSrc()}
        handleClose={handleVideoClose}
      />
      <CustomFileUploadModal
        showFileModal={showFileModal}
        hideFileModal={hideFileModal}
        setPic={setPic}
        setImage={setImage}
        allowVideo={allowVideo}
        onConfirmClick={onImageConfirmClick}
      />
    </>
  );
};

export default CustomFileUpload;

const VideoWrapper = styled.div`
  position: relative;
  .imported-video {
    position: relative;
  }
  .play-cover {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #ffffffa1;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .play-icon {
      font-size: 2rem;
    }
  }
`;
